import React, { Component } from 'react';
import { connect } from 'react-redux';
import { columns } from './columns';
import { Loading, FilterDetailHired } from '../../../../../../../../components';
import { detailCandidate, unmountDetailCandidate } from '../../../../../../../../redux/actions/candidate/candidateAction';
import { Row, Col, Modal, Table, Pagination, Button, message } from 'antd';
import { detailJobActiveApplicant, listApplicant, addApplicantNotes, listApplicantNotes, unmountDetailJobsActiveApplicant, unmountListApplicant, unmountApplicantNotes } from '../../../../../../../../redux/actions/jobs/jobsAction'
import ViewProfile from '../../../../../../../../components/Modal/ApplicantProfile';
import ApplicantNotes from '../../../../../../../../components/Modal/ApplicantNotes';
import ApplicantHistory from '../../../../../../../../components/Modal/ApplicantHistory';
import DownloadFile from '../../../../../../../../components/DownloadFiles';

class DetailHiredAgencyJobs extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      checked: true,
      visible: false,
      documentName: null,
      isModalNotes: false,
      isModalHistory: false
    }
  }

  onPriviewProfile = (id) => {
    const { actionGetProfile, getApplicant } = this.props;
    const findCandidate = getApplicant.data.find(item => item.candidateId === id)
    this.setState({
      documentName: `${findCandidate.applicantNumber}-${findCandidate.name}`
    })
    return actionGetProfile(id, () => {
      this.setState({ visible: true })
    })
  }

  onPriviewNotes = (record) => {
    Modal.info({
      title: 'Notes',
      content: (
        <div dangerouslySetInnerHTML={{ __html: record.notes }}></div>
      ),
      onOk() {},
    });
  }

  showModalNotes = (id) => {
    const { actionListApplicantNotes } = this.props
    actionListApplicantNotes(id)
    this.setState({ isModalNotes: true, idApplicant: id })
  };

  showModalHistory = (id) => {
    this.setState({ isModalHistory: true, idApplicant: id })
  };

  onCancel = () => {
    this.setState({ visible: false, isModalNotes: false, isModalHistory: false })
  }

  onFinishNotes = value => {
    const { idApplicant } = this.state
    const { metaApplicant, url, actionAddApplicantNotes, actionListApplicantNotes, actionGetApplicant } = this.props;
    
    value.jobAppliedId = idApplicant

    return actionAddApplicantNotes(value, url, () => {
      message.success('Successfully update applicant notes')
      actionListApplicantNotes(idApplicant)
      actionGetApplicant(metaApplicant, url)
    }, (err) => message.error(err))
  }

  render() {
    const { visible, documentName, isModalNotes, isModalHistory, idApplicant } = this.state;
    const { getData: { data, loading }, getApplicant, getProfile, onSearchApplicant, onRangePicker, handlePagination } = this.props;

    if(loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Row gutter={[16, 24]}>
          {/* Content */}
          <Col span={24}>
            <FilterDetailHired 
              onSearch={onSearchApplicant} 
              onRangePicker={onRangePicker}
              tooltopRangePicker='Filter by Join Date'
            />
          </Col>
          <Col span={24}>
            <Table 
              style={{ marginBottom: 20 }}
              size="small"
              rowKey={(i) => i.id} 
              dataSource={getApplicant.data} 
              loading={getApplicant.loading} 
              columns={columns(this.onPriviewProfile, this.onPriviewNotes, this.showModalNotes, this.showModalHistory)} 
              pagination={false}
              scroll={{ x: 900 }}
            />
            <Pagination
              total={getApplicant.pagination.total}
              onChange={handlePagination}
              current={getApplicant.pagination.page}
              pageSize={getApplicant.pagination.perpage}
              showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total} Data`}
            />
          </Col>
        </Row>
        <Modal 
          centered
          width={1000}
          visible={visible}
          title="Applicant Profile"
          onCancel={this.onCancel}
          footer={
            <>
              <DownloadFile data={data} getProfile={getProfile} documentName={documentName} />
              <Button key="back" type="primary" onClick={this.onCancel}>Okay</Button>
            </>
          }
        >
          <div style={{ height: 430, overflowY: 'auto', overflowX: 'hidden', padding: 32 }}>
            <ViewProfile {...this.props} />
          </div>
        </Modal>
        <Modal centered width={800} title="Applicant Notes" visible={isModalNotes} onCancel={this.onCancel} footer={null} destroyOnClose>
          <ApplicantNotes {...this.props} onFinishNotes={this.onFinishNotes} />
        </Modal>
        <Modal centered width={800} title="Applicant History" visible={isModalHistory} onCancel={this.onCancel} footer={null} destroyOnClose>
          <ApplicantHistory dataApplicantHistory={getApplicant?.data?.filter(item => item.id === idApplicant)?.pop()?.listHistory} />
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail, unmountGetApplicant, unmountGetProfile, unmountApplicantNotes } = this.props;
    unmountGetApplicant()
    unmountGetDetail()
    unmountGetProfile()
    unmountApplicantNotes()
  }
}

const mapStateToProps = (state) => ({
  getData           : state.jobs.activeApplicant,
  getApplicant      : state.jobs.listApplicant,
  getProfile        : state.candidate.detail,
  getApplicantNotes : state.jobs.listApplicantNotes
})

const mapDispatchToProps = {
  actionGetApplicant        : listApplicant,
  actionGetProfile          : detailCandidate,
  actionGetDetail           : detailJobActiveApplicant,
  actionAddApplicantNotes   : addApplicantNotes,
  actionListApplicantNotes  : listApplicantNotes,
  unmountGetApplicant       : unmountListApplicant,
  unmountGetProfile         : unmountDetailCandidate,
  unmountGetDetail          : unmountDetailJobsActiveApplicant,
  unmountApplicantNotes     : unmountApplicantNotes

}

export default connect(mapStateToProps, mapDispatchToProps)(DetailHiredAgencyJobs)
