import React, { Component } from 'react';
import logo from '../../../assets/img/logo.svg'
import { Link } from 'react-router-dom';
import { Layout, Menu, Typography } from 'antd';
import { DoubleRightOutlined, DoubleLeftOutlined } from '@ant-design/icons';
const { Sider } = Layout;
const { Text } = Typography;

const Icon = ({ type, ...rest }) => {
  const icons = require(`@ant-design/icons`);
  const Component = icons[type];
  return <Component {...rest} />
};

export default class SiderMenu extends Component {
  render() {
    const { permission, collapsed, index } = this.props;
    return (
      <React.Fragment>
        <Sider 
          trigger={null} 
          collapsible 
          collapsed={collapsed} 
          theme="light" 
          collapsedWidth={80} 
          style={{ height: '100vh', position: 'fixed', left: 0, zIndex: 3 }} 
        >
          <div style={{ textAlign: 'center', padding: 12, borderBottom: !collapsed ? '2px solid #dfdfdf' : 'none' }}>
            {
              collapsed ? null : 
              <img src={logo} alt="" style={{ width: '71%', marginRight: 20 }} />
            }
            <Link to="#" onClick={this.props.toggleCollapsed} style={{ fontSize: 20, color: '#747474' }}>
              {React.createElement(collapsed ? DoubleRightOutlined : DoubleLeftOutlined)}
            </Link>
          </div>
          <Menu defaultSelectedKeys={[`${index}`]} mode="inline" style={{ borderRight: 0 }}>
            {
              permission.map((response) => {
                return (
                  response.show === true ?
                  <Menu.Item key={response.index} icon={<Icon type={response.icon} />}>
                    <Link to={response.path}>
                      <Text>{response.title}</Text>
                    </Link>
                  </Menu.Item>
                  :
                  null
                )
              })
            }
          </Menu>
        </Sider>
      </React.Fragment>
    )
  }
}
