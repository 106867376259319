import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading } from '../../../../../components';
import { BankOutlined, UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { Col, Form, Row, Input, Button, Space, Select, PageHeader, message, Card, Breadcrumb, Image, Upload } from 'antd';
// import { detailJobPostingType, editJobPostingType, unmountDetailJobPostingType } from '../../../../../redux/actions/master/jobPostingtype/jobPostingTypeAction';
import { detailBank, editBank, unmountDetailBank } from '../../../../../redux/actions/master/bank/bankAction';
import { checkCodeBank, checkBankName } from '../../../../../redux/actions/check/checkAction';

class EditMasterJobPostingType extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      editAvatar: false,
      onPreviewAvatar: null,
    }
  }

  componentDidMount() {
    const { actionGetDetail, match: { params } } = this.props;
    return actionGetDetail(params.id)
  }

  onFinish = (values) => {
    const { actionUpdate, history, getData: { data } } = this.props;
    const { editAvatar, avatar } = this.state
    if(editAvatar){
      values.logoFile = avatar
    }
    values.id = data.id
    this.setState({ submitLoading: true })
    return actionUpdate(values, () => {
      this.setState({ submitLoading: false }, () => {
        message.success('Data updated successfully')
        history.push('/master/bank')
      })
    }, (err) => {
      this.setState({ submitLoading: false }, () => message.error(err))
    })
  }

  handleUpload(){
    return {
      showUploadList: false,
      withCredentials: true,
      accept:".png",
      beforeUpload: imageFile => {
        const validateSize = imageFile.size >= 1000000;
        if (validateSize) {
          message.error('Max file size is 1 MB!');
          return false
        }
        var reader = new FileReader();
        reader.readAsDataURL(imageFile);
        reader.onload = (e) => {
          this.setState({ 
            avatar: imageFile, 
            editAvatar: true,
            onPreviewAvatar: e.target.result, 
          })
        }
        return false;
      },
    }
  }

  handleDeleteFile = () => {
    this.setState({
      avatar: null,
      editAvatar: false,
      onPreviewAvatar: null
    })
  }

  render() {
    const { submitLoading, editAvatar, onPreviewAvatar } = this.state;
    const { getData: { data, loading }, actionCheckBankName, actionCheckCodeBank } = this.props;

    if(loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Form onFinish={this.onFinish} layout="vertical">
          <Row style={{ position: 'sticky', zIndex: 1, width: '100%', top: 62, right: 0, marginTop: -20, marginBottom: 8, paddingBottom: 12, paddingTop: 16, backgroundColor: '#f2f2f2' }}>
            {/* Breadcrumb */}
            <Col span={24}>
              <Breadcrumb>
                <Breadcrumb.Item><BankOutlined /> Master Data</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link className="link" to="/master/bank">
                    Master Data List Bank
                  </Link> 
                </Breadcrumb.Item>
                <Breadcrumb.Item>Bank</Breadcrumb.Item>
                <Breadcrumb.Item>{data.name}</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            { /* Page Header */}
            <Col span={24}>
              <PageHeader 
                className="site-page-header" 
                title="Update Bank"
                extra={[
                  <Button key="2" className="btn-save-primary" type="default" onClick={() => window.history.back()}>Cancel</Button>,
                  <Button key="1" className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading}>Update</Button>
                ]}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            { /* Form */}
            <Col span={24}>
              <Card>
                <Row gutter={16}>

                  <Col span={12}>
                    <Form.Item 
                      label="Bank Code" 
                      name="code" 
                      validateFirst
                      initialValue={data.code ? data.code : ''}
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { len: 3, message: '3 numbers only' },
                        { pattern: /^[0-9]*$/, message: 'Numbers only' },
                        {
                          validator(rule, value, callback){
                            return actionCheckCodeBank(value, (res) => {
                              if (!res && value !== data.code) {
                                callback('This code already exist!')
                              }else{
                                callback()
                              }
                            })
                          }
                        }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Bank" 
                      name="name" 
                      validateFirst
                      initialValue={data.name ? data.name : ''}
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 3, message: 'Name must be at least 3 characters long' },
                        { max: 50, message: '50 characters only' },
                        {
                          validator(rule, value, callback){
                            return actionCheckBankName(value, (res) => {
                              if (!res && value !== data.name) {
                                callback('This name already exist!')
                              }else{
                                callback()
                              }
                            })
                          }
                        }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Status" 
                      name="enabled" 
                      initialValue={data.enabled}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select>
                        <Select.Option value={true}>Active</Select.Option>
                        <Select.Option value={false}>Inactive</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  {/* <Col span={24} >
                    <Space direction='vertical'>
                        <Form.Item 
                          label="Logo Bank (PNG Only)" 
                          name="logoUrl"
                          initialValue={editAvatar ? this.state.avatar : this.state.logoFile}
                          rules={[
                            { required: true, message: 'This is a required field' },
                          ]}
                         >
                          {
                            editAvatar ?
                            <Form.Item>
                              <Image width={100} src={editAvatar ? onPreviewAvatar : null} />
                            </Form.Item>
                            :
                            null
                          }
                          <Space>
                            <Upload {...this.handleUpload()}>
                              <Button icon={<UploadOutlined />}>{editAvatar ? 'Change Image' : 'Upload a Image'}</Button>
                            </Upload>
                            {
                              editAvatar ?
                                <Button icon={<DeleteOutlined />} type="danger" ghost onClick={this.handleDeleteFile}>Delete</Button>
                              : null
                            }
                          </Space>
                        </Form.Item>
                    </Space>
                  </Col> */}
                  
                  <Col span={12}>
                    <Form.Item label="Logo Bank (PNG Only)"
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      {
                        data.logoUrl ?
                        <Form.Item>
                          <Image width={100} src={editAvatar ? onPreviewAvatar : data.logoUrl ? data.logoUrl : null} />
                        </Form.Item>
                        :
                        editAvatar ?
                        <Form.Item>
                          <Image width={100} src={editAvatar ? onPreviewAvatar : null} />
                        </Form.Item>
                        :
                        null
                      }
                      <Space>
                        <Upload {...this.handleUpload()}>
                          <Button className="btn-radius" icon={<UploadOutlined />}>{data.logoUrl ? 'Change File' : editAvatar ? 'Change File' : 'Upload a File'}</Button>
                        </Upload>
                        {
                          editAvatar ? 
                          <Button className="btn-radius" icon={<DeleteOutlined />} type="danger" ghost onClick={this.handleDeleteFile}>Delete</Button>
                          : null
                        }
                      </Space>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail } = this.props;
    return unmountGetDetail()
  }
}

const mapStateToProps = (state) => ({
  getData: state.bank.detail
})

const mapDispatchToProps = {
  actionUpdate      : editBank,
  actionGetDetail   : detailBank,
  unmountGetDetail  : unmountDetailBank,
  actionCheckCodeBank: checkCodeBank,
  actionCheckBankName: checkBankName
}

export default connect(mapStateToProps, mapDispatchToProps)(EditMasterJobPostingType)
