import React, { Component } from 'react';
import { DeleteTwoTone } from '@ant-design/icons';
import { Form, Row, Col, Input, Select, Button, List } from 'antd';

export default class EditSkill extends Component {
  render() {
    const { 
      name,
      formRef,
      onFinish, 
      onChangeName,
      handleDelete,
      getDetailCandidate: { data }, 
    } = this.props;

    return (
      <React.Fragment>
        <Form onFinish={onFinish} layout="vertical" ref={formRef}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item 
                label="Name"
                name="name"
                validateFirst
                rules={[
                  { max: 25, message: '25 characters only' },
                  { pattern: /^[0-9a-zA-Z ]*$/, message: 'Special characters are not allowed' },
                ]}
              >
                <Input onChange={onChangeName} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Skill Level" name="skillLevel" initialValue="BASIC">
                <Select>
                  <Select.Option key="1" value="BASIC">Basic</Select.Option>
                  <Select.Option key="2" value="INTERMEDIATE">Intermediate</Select.Option>
                  <Select.Option key="3" value="ADVANCE">Advance</Select.Option>
                  <Select.Option key="4" value="EXPERT">Expert</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label=" ">
                <Button 
                  className="btn-save-primary" 
                  htmlType="submit" 
                  type="primary" 
                  disabled={!name}
                >
                  Add
                </Button>
              </Form.Item>
            </Col>
            <Col span={20}>
              <List
                size="small"
                bordered={false}
                dataSource={data.listSkill}
                renderItem={(item, i) => (
                  <List.Item
                    key={i}
                    className="txt-color"
                    actions={[<DeleteTwoTone className="c-pointer" twoToneColor="#ff4646" onClick={() => handleDelete(item.id)} />]}
                  >
                    {`${i+1}. ${item.name}`}
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}
