import React from 'react';
import { Typography, Dropdown, Menu, Tag } from 'antd';
import { EditOutlined, DashOutlined, StopOutlined, CheckCircleOutlined } from '@ant-design/icons';
const { Text } = Typography;

const menu = (record, onClickEdit, handleEnable, handleDisable) => (
  <Menu>
    <Menu.Item key={1} icon={<EditOutlined />} onClick={() => onClickEdit(record.id, record.question, record.answer, record.enabled)}>
      Edit
    </Menu.Item>
    {
      record.enabled ?
      <Menu.Item key={2} icon={<StopOutlined />} onClick={() => handleDisable(record.id)}>
        Set as inactive
      </Menu.Item>
      :
      <Menu.Item key={3} icon={<CheckCircleOutlined />} onClick={() => handleEnable(record.id)}>
        Set as active
      </Menu.Item>
    }
  </Menu>
);

export const columns = (onClickEdit, handleEnable, handleDisable) => {
  return [
    {
      title: 'Question',
      key: 'question',
      render: (record) => (
        <Text>
          {record.question}
        </Text>
      )
    },
    {
      title: 'Answer',
      key: 'answer',
      render: (record) => (
        <Text>
          {record.answer}
        </Text>
      )
    },
    {
      title: 'Status',
      key: 'status',
      render: (record) => (
        <React.Fragment>
          {
            record.enabled ? 
            <Tag color="green">Active</Tag>
            : 
            <Tag color="orange">Inactive</Tag>
          }
        </React.Fragment>
      )
    },
    {
      title: '',
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <Dropdown overlay={menu(record, onClickEdit, handleEnable, handleDisable)} placement="bottomCenter" arrow>
          <DashOutlined style={{ fontSize: 20 }} />
        </Dropdown>
      )
    },
  ]
}