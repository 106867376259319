import { BankOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Card, Col, Form, Image, Input, message, Modal, Typography, PageHeader, Row, Select, Space, Upload } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading } from '../../../../components';
import { checkEmail, checkPhone } from '../../../../redux/actions/check/checkAction';
import { approveEmployerInquiries, detailEmployerInquiries, editEmployerInquiries, masterEmployersInquiries, rejectEmployerInquiries, unmountDetailEmployersInquiries, unmountMasterEmployersInquiries } from '../../../../redux/actions/employersInquiries/employersInquiriesAction';
import { cityByProvince } from '../../../../redux/actions/master/cities/citiesAction';
import { masterCountries, unmountMasterCountries } from '../../../../redux/actions/master/countries/countriesAction';
import { masterJobIndustries, unmountMasterJobIndustries } from '../../../../redux/actions/master/jobIndustries/jobIndustriesAction';
import { provinceByCountry } from '../../../../redux/actions/master/provinces/provincesAction';

class EditEmployerInquiries extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
    
    this.state = {
      submitLoading: false,
      avatar: null,
      editAvatar: false,
      onPreviewAvatar: null,
      callingCode: "62",
      dataProvince: [],
      dataCity: [],
      visible: false,
      submitType: null
    }
  }

  componentDidMount() {
    const { 
      actionGetJobFunction, 
      actionGetCountry, 
      actionGetProvince, 
      actionGetCity, 
      actionGetEmployer, 
      actionGetDetail, 
      match: { params } 
    } = this.props;
    actionGetDetail(params.id, (response) => {
      this.setState({ callingCode: Number(response.data.callingCode) })
      if(response.data.countryId){
        actionGetProvince(response.data.countryId, (response) => {
          this.setState({ dataProvince: response.data })
        })
      }
      if(response.data.provinceId){
        actionGetCity(response.data.provinceId, (response) => {
          this.setState({ dataCity: response.data })
        })
      }
    })
    actionGetJobFunction()
    actionGetEmployer()
    actionGetCountry()
  }

  checkEmail = (value) => {
    const { actionCheckEmail, getData: { data } } = this.props;
    const oldEmail = data.email
    if(oldEmail === value){
      return null
    }else{
      return actionCheckEmail(value, (response) => {
        if(response){
          message.error('Email already exist')
          this.formRef.current.setFieldsValue({
            email: data.email,
          });
        }
      })
    }
  }

  checkPhone = (value) => {
    const { callingCode } = this.state;
    const { actionCheckPhone, getData: { data } } = this.props;
    const valuePhone = `${callingCode}${value.replace(/^0+/, '')}`
    const oldMobilePhone = `${data.callingCode}${data.mobilePhone}`
    this.formRef.current.setFieldsValue({
      mobilePhone: value.replace(/^0+/, ''),
    });
    if(oldMobilePhone === valuePhone){
      return null
    }else{
      return actionCheckPhone(valuePhone.replace(/^0+/, ''), (response) => {
        if(response){
          message.error('Mobile phone already exist')
          this.formRef.current.setFieldsValue({
            mobilePhone: data.mobilePhone,
          });
        }
      })
    }
  }

  handleChangeCallingCode = (_, index) => {
    this.setState({ callingCode: index.children.replace(/^[+]+/, '') })
    this.formRef.current.setFieldsValue({
      mobilePhone: null
    });
  }
  
  handleChangeCountry = (value) => {
    const { actionGetProvince } = this.props;
    this.formRef.current.setFieldsValue({
      provinceId: null,
      cityId: null,
    });
    return actionGetProvince(value, (response) => {
      this.setState({ dataProvince: response.data })
    })
  }

  handleChangeProvince = (value) => {
    const { actionGetCity } = this.props;
    this.formRef.current.setFieldsValue({
      cityId: null
    });
    if(value !== undefined){
      return actionGetCity(value, (response) => {
        this.setState({ dataCity: response.data })
      })
    }else {
      this.setState({ dataCity: [] })
    }
  }

  handleUpload(){
    return {
      showUploadList: false,
      withCredentials: true,
      accept:"image/*",
      beforeUpload: file => {
        const validateSize = file.size >= 500000;
        if (validateSize) {
          message.error('Max file size is 500 KB!');
          return false
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.setState({ 
            avatar: file, 
            editAvatar: true,
            onPreviewAvatar: e.target.result, 
          })
        }
        return false;
      },
    }
  }

  handleDeleteFile = () => {
    this.setState({
      avatar: null,
      editAvatar: false,
      onPreviewAvatar: null
    })
  }

  handleClick = (type) => {
    this.setState({ submitType: type })
  }

  onFinish = (values) => {
    const { callingCode, editAvatar, avatar, submitType } = this.state;
    const { actionUpdate, actionApprove, history, getData: { data } } = this.props;
    values.picMobilePhone = `${callingCode}${values.mobilePhone.replace(/^0+/, '')}`
    values.jobIndustryId = values.jobIndustryId ? values.jobIndustryId : ''
    values.provinceId = values.provinceId ? values.provinceId : ''
    values.cityId = values.cityId ? values.cityId : ''
    values.employersRegisterNumber = data.employersRegisterNumber
    values.id = data.id
    if(editAvatar){
      values.logoFile = avatar
    }
    this.setState({ submitLoading: true })
    if(submitType === 'save'){
      return actionUpdate(values, () => {
        this.setState({ submitLoading: false }, () => {
          message.success('Data updated successfully')
          history.push('/employers-inquiries')
        })
      }, (err) => {
        this.setState({ submitLoading: false }, () => message.error(err))
      })
    }else if(submitType === 'approve'){
      return actionApprove(values, (response) => {
        if(response.code === '1000'){
          this.setState({ submitLoading: false }, () => {
            message.success('Employer has been approve')
            history.push('/employers-inquiries')
          })
        }else{
          this.setState({ submitLoading: false }, () => {
            message.error(response.message)
          })
        }
      }, (err) => {
        this.setState({ submitLoading: false }, () => message.error(err))
      })
    }
  }

  handleReject = () => {
    this.setState({ visible: true })
  }

  handleCancel = () => {
    this.setState({ visible: false })
  }

  handleOk = () => {
    const { actionReject, history, getData: { data } } = this.props;
    this.formRef.current.validateFields().then(values => {
      values.id = data.id
      return actionReject(values, () => {
        message.success('Employer has been rejected')
        this.setState({ visible: false }, () => {
          history.push('/employers-inquiries')
        })
      }, (err) => {
        return message.error(err)
      })
    })
  }
  
  render() {
    const { submitLoading, editAvatar, onPreviewAvatar, dataProvince, dataCity, visible } = this.state;
    const { getJobFunction, getCountry, getEmployer, getData: { data, loading } } = this.props;

    if(loading || getJobFunction?.loading || getCountry?.loading || getEmployer.loading){
      return <Loading />
    }

    const mobileNumber = (
      <Form.Item 
        noStyle
        name="callingCodeId" 
        initialValue={data.callingCodeId ? data.callingCodeId : 99} 
        rules={[ {required: true, message: 'Please input your calling code'} ]}
      >
        <Select 
          showSearch
          className="prefix-selector"
          optionFilterProp="children"
          onChange={this.handleChangeCallingCode}
        >
          {
            getCountry?.data.map(item => (
              <Select.Option key={item.id} value={item.id}>
                {`+${item.callingCode}`}
              </Select.Option>
            ))
          }
        </Select>
      </Form.Item>
    );

    return (
      <React.Fragment>
        <Form layout="vertical" ref={this.formRef} onFinish={this.onFinish} scrollToFirstError={true}>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><BankOutlined /> Employers</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/employers-inquiries">Employers Inquiry List</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{data?.employersRegisterNumber || '-'}</Breadcrumb.Item>
              <Breadcrumb.Item>Approval</Breadcrumb.Item>
            </Breadcrumb>
          </Col>

          {/* Page Header */}
          <Col span={24}>
            <PageHeader 
              title="Employer Inquiry Approval" 
              className="site-page-header"
              extra={[
                <Button key="3" className="btn-cancel-danger" onClick={() => this.handleReject()}>Reject</Button>,
                <Button key="2" className="btn-edit-candidate" htmlType="submit" loading={submitLoading} onClick={() => this.handleClick('save')} >Save</Button>,
                <Button key="1" style={{ width: 'auto' }} className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading} onClick={() => this.handleClick('approve')}>Approve and Create Account</Button>,
              ]}
            />
          </Col>

          {/* Form */}
          <Col span={24}>
            <Card>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item 
                    label="Employer Name" 
                    name="name" 
                    validateFirst
                    initialValue={data?.name ? data.name : ''}
                    rules={[
                      { required: true, message: 'This is a required field' },
                      { min: 3, message: 'Name must be at least 3 characters long' },
                      { max: 50, message: 'Employer Name is too long. Maximum is 50 characters' },
                      // { pattern: new RegExp("^[a-zA-Z ]+$"), message: 'Special Characters and Number are not allowed' },
                    ]}
                    >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item 
                    label="Employer Email" 
                    name="email" 
                    validateFirst
                    initialValue={data?.email ? data.email : ''}
                    rules={[
                      { required: true, message: 'This is a required field' },
                      { type: 'email', message: 'The input is not valid E-mail' }, 
                    ]}
                  >
                    <Input type="email" onBlur={(e) => this.checkEmail(e.target.value)} />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item 
                    label="Contact Person" 
                    name="picName"
                    validateFirst
                    initialValue={data?.picName ? data.picName : ''}
                    rules={[
                      { required: true, message: 'This is a required field' },
                      { min: 3, message: 'Name must be at least 3 characters long' },               
                      { max: 50, message: 'Employer Name is too long. Maximum is 50 characters' },
                      { pattern: new RegExp("^[a-zA-Z ]+$"), message: 'Special Characters and Number are not allowed' },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item 
                    label="Contact Person Mobile Number" 
                    name="mobilePhone"
                    validateFirst
                    initialValue={data?.picMobilePhone ? data.picMobilePhone : ''}
                    rules={[
                      { required: true, message: 'This is a required field' },
                      { min: 4, message: 'Your mobile number is invalid' },
                      { max: 13, message: 'Your mobile number is invalid' },
                      { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers!' },                
                    ]}
                  >
                    <Input addonBefore={mobileNumber} onBlur={(e) => this.checkPhone(e.target.value)} />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item 
                    label="Employer Type" 
                    name="employersTypeId" 
                    initialValue={data?.employersTypeId ? data.employersTypeId : ''}
                    rules={[
                      { required: true, message: 'This is a required field' },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {
                        getEmployer?.data.map(item => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item 
                    label="Industry" 
                    name="jobIndustryId"
                    initialValue={data?.jobIndustryId ? data.jobIndustryId : ''}
                    rules={[
                      { required: true, message: 'This is a required field' },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select an Industry"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {
                        getJobFunction?.data.map(item => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.title}
                          </Select.Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item 
                    label="Contact Person Email" 
                    name="picEmail" 
                    initialValue={data?.picEmail ? data.picEmail : ''}
                    rules={[
                      { type: 'email', message: 'Your email address is invalid' }
                    ]}
                  >
                    <Input type="email" />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item 
                    label="Specialties (niche or specific industry of the employer)" 
                    name="specialties" 
                    initialValue={data?.specialties ? data.specialties : ''}
                  >
                    <Input placeholder="e.g. Recruiting, HR Software" />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item 
                    label="Office Phone Number" 
                    name="officePhone" 
                    validateFirst
                    initialValue={data?.officePhone ? data.officePhone : ''}
                    rules={[
                      { min: 4, message: 'Your mobile number is invalid' },
                      { max: 13, message: 'Your mobile number is invalid' },
                      { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers' },  
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item 
                    label="Country" 
                    name="countryId"
                    initialValue={data?.countryId ? data.countryId : ''}
                  >
                    <Select
                      showSearch
                      placeholder="Select a country"
                      optionFilterProp="children"
                      onChange={this.handleChangeCountry}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {
                        getCountry?.data.map(item => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item 
                    label="Province" 
                    name="provinceId"
                    initialValue={data?.provinceId ? data.provinceId : null}
                  >
                    <Select
                      showSearch
                      allowClear
                      placeholder="Select a province"
                      onChange={this.handleChangeProvince}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {
                        dataProvince.map(item => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item 
                    label="City" 
                    name="cityId"
                    initialValue={data?.cityId ? data.cityId : null}
                  >
                    <Select
                      showSearch
                      allowClear
                      placeholder="Select a city"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {
                        dataCity.map(item => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item 
                    label="Street Address" 
                    name="address"
                    initialValue={data?.address ? data.address : ''}
                    rules={[
                      { max: 100, message: 'Address is too long. Maximum is 100 characters' },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item 
                    label="Postal Code" 
                    name="zipCode"
                    validateFirst
                    initialValue={data?.zipCode ? data.zipCode : ''}
                    rules={[
                      { max: 11, message: 'Maximum postal code is 11 characters' },
                      { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers' },                
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item 
                    label="Company Size" 
                    name="totalEmployee"
                    initialValue={data?.totalEmployee ? data.totalEmployee : ''}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Select.Option value="1 - 20 employees">1 - 20 employees</Select.Option>
                      <Select.Option value="21 - 100 employees">21 - 100 employees</Select.Option>
                      <Select.Option value="100 - 250 employees">100 - 250 employees</Select.Option>
                      <Select.Option value="250 - 500 employees">250 - 500 employees</Select.Option>
                      <Select.Option value="500 - 1000 employees">500 - 1000 employees</Select.Option>
                      <Select.Option value="> 1000 employees">{`> 1000 employees`}</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item 
                    label="Website" 
                    name="website"
                    validateFirst
                    initialValue={data?.website ? data.website : ''}
                    rules={[
                      { max: 100, message: 'Maximum is 100 characters' },
                      { pattern: new RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi), message: 'Invalid website url' },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item 
                    label="About" 
                    name="about"
                    initialValue={data?.about ? data.about : ''}
                    rules={[
                      { max: 2000, message: 'Maximum is 2000 characters' },
                    ]}
                  >
                    <Input.TextArea rows={4} />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item label="Logo">
                    {
                      data?.logoUrl ?
                      <Form.Item>
                        <Image width={100} src={editAvatar ? onPreviewAvatar : data?.logoUrl ? data.logoUrl : null} />
                      </Form.Item>
                      :
                      editAvatar ?
                      <Form.Item>
                        <Image width={100} src={editAvatar ? onPreviewAvatar : null} />
                      </Form.Item>
                      :
                      null
                    }
                    <Space>
                      <Upload {...this.handleUpload()}>
                        <Button icon={<UploadOutlined />}>{data.logoUrl ? 'Change File' : editAvatar ? 'Change File' : 'Upload a File'}</Button>
                      </Upload>
                      {
                        editAvatar ? 
                        <Button icon={<DeleteOutlined />} type="danger" ghost onClick={this.handleDeleteFile}>Delete</Button>
                        : null
                      }
                    </Space>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        </Form>
        <Modal 
          visible={visible} 
          onOk={() => this.handleOk()} 
          onCancel={() => this.handleCancel()}
        >
          <Form 
            onFinish={() => this.handleOk()} 
            layout="vertical"
            ref={this.formRef} 
          >
            <Col span={24} style={{ marginBottom: 30 }}>
              <Typography.Text style={{ fontSize: 18 }}>
                Are you sure to reject this employer?
              </Typography.Text>
            </Col>
            <Col span={24}>
              <Form.Item 
                label="Please state your reason for reject this employer" 
                name="rejectReason"
                rules={[
                  { required: true, message: 'This is a required field' },
                ]}
              >
                <Input.TextArea rows={4} />
              </Form.Item>
            </Col>
          </Form>
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail, unmountGetCountry, unmountGetEmployer, unmountGetJobFunction } = this.props;
    unmountGetDetail()
    unmountGetCountry()
    unmountGetEmployer()
    unmountGetJobFunction()
  }
}

const mapStateToProps = (state) => ({
  getData         : state.employersInquiries.detail,
  getJobFunction  : state.jobIndustries.master,
  getCountry      : state.countries.master,
  getEmployer     : state.employersInquiries.master
})

const mapDispatchToProps = {
  actionUpdate          : editEmployerInquiries,
  actionApprove         : approveEmployerInquiries,
  actionGetDetail       : detailEmployerInquiries,
  actionCheckPhone      : checkPhone,
  actionCheckEmail      : checkEmail,
  actionGetJobFunction  : masterJobIndustries,
  actionGetCountry      : masterCountries,
  actionGetProvince     : provinceByCountry,
  actionGetCity         : cityByProvince,
  actionGetEmployer     : masterEmployersInquiries,
  actionReject          : rejectEmployerInquiries,
  unmountGetDetail      : unmountDetailEmployersInquiries,
  unmountGetCountry     : unmountMasterCountries,
  unmountGetEmployer    : unmountMasterEmployersInquiries,
  unmountGetJobFunction : unmountMasterJobIndustries
}

export default connect(mapStateToProps, mapDispatchToProps)(EditEmployerInquiries)
