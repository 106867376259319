import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BankOutlined } from '@ant-design/icons'
import { Breadcrumb, Col, Modal, PageHeader, Pagination, Row, Table } from 'antd'
import debounce from 'lodash/debounce'
import { HeaderTable } from '../../../components'
import { listCandidateRequest } from '../../../redux/actions/candidateRequest/candidateRequestAction'
import { columns } from './columns'

export class CandidateRequest extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      selectedRowKeys: [],
      sortedInfo: null,
      meta: {
        page: 1,
        perpage: 10,
        field: null,
        sort: null,
        search: null,
        flagStatus: null,
        startDate: null,
        endDate: null
      }
    }
    this.onSearch = debounce(this.onSearch.bind(this), 900)
  }

  componentDidMount() {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    return actionGetData(meta)
  }

  onClickEdit = (id) => {
    this.props.history.push(`/candidate-request/edit/${id}`)
  }

  onClickRow = (record) => {
    this.props.history.push(`/candidate-request/detail/${record.id}`)
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  pagination = (page, perpage) => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = page
    meta.perpage = perpage
    return actionGetData(meta)
  }

  onRegistered = value => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.startDate = value.startDate
    meta.endDate = value.endDate
    return actionGetData(meta)
  }

  onStatus = value => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.status = value
    return actionGetData(meta)
  }

  onSearch = value => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.search = value === '' ? null : value
    return actionGetData(meta)
  }

  onClickReason = (record) => {
    Modal.info({
      title: 'Reject Reason:',
      content: (
        <p>{record}</p>
      ),
      onOk() {},
    });
  }
  
  render() {
    const { selectedRowKeys, sortedInfo } = this.state;
    const { getData: { data, loading, pagination } } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const defaultStatusFilter = [
      { value: '', name: 'All' },
      { value: 'FOLLOW_UP', name: 'To Follow Up' },
      { value: 'APPROVE', name: 'Approved' },
      { value: 'REJECT', name: 'Rejected' },
    ]

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><BankOutlined /> Request</Breadcrumb.Item>
              <Breadcrumb.Item>Request for Candidates list</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          { /* Page Header */}
          <Col span={24}>
            <PageHeader 
              className="site-page-header" 
              title='Request for Candidates List' 
            />
          </Col>
          {/* Filter */}
          <Col span={24}>
            <HeaderTable
              fase1={{ key: 'type', value: defaultStatusFilter}}
              onSearch={this.onSearch} 
              onStatus={this.onStatus} 
              onRegistered={this.onRegistered}
              tooltipDate="Filter by Register Date"
              tooltipStatus="Filter by Status"
              placeholder="Search by Number, Contact Person or Email"
            />
          </Col>
          {/* Table */}
          <Col span={24}>
            <Table 
              size="small" 
              rowKey={(i) => i.id} 
              pagination={false}
              onChange={this.sorter}
              dataSource={data} 
              loading={loading} 
              scroll={{ x: 1500 }}
              rowSelection={rowSelection}
              rowClassName={(record) => record.id ? 'row-pointer' : null}
              columns={columns(sortedInfo, this.onClickEdit, this.onClickRow, this.onClickReason)}  
            />
          </Col>
          {/* Pagination */}
          <Col span={24}>
            <Pagination
              total={pagination.total}
              onChange={this.pagination}
              current={pagination.page}
              pageSize={pagination.perpage}
              showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total} Data`}
            />
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  getData: state.candidateRequest.list
})

const mapDispatchToProps = {
  actionGetData     : listCandidateRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateRequest)
