import { API } from '../../../../config'
import { errorHandler } from '../../auth/errorAction'

export const unmountIndexBusinessSector = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_BUSINESS_SECTOR'})
}

export const indexBusinessSector = () => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_BUSINESS_SECTOR' })
  API.GET('/business_sector/index').then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_BUSINESS_SECTOR_SUCCESS', 
      payload: {
        data: response.data
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err.error || err.message, 
      dispatch({ type: 'LOAD_DATA_MASTER_BUSINESS_SECTOR_FAILED' }), 
    ))
  })
}