import React, { Component } from 'react';
import EditJobPreference from './Edit';
import svg from '../../../../assets/img/profile.svg';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { positionByIndustryGroup } from '../../../../redux/actions/master/jobPositions/jobPositionsAction';
import { masterCountries, unmountMasterCountries } from '../../../../redux/actions/master/countries/countriesAction';
import { detailCandidate, unmountDetailCandidate, updateJobPreference, enableCandidate } from '../../../../redux/actions/candidate/candidateAction';
import { masterJobIndustries, unmountMasterJobIndustries } from '../../../../redux/actions/master/jobIndustries/jobIndustriesAction';
import { Skeleton, Row, Col, Breadcrumb, PageHeader, Card, Button, Space, Avatar, Typography, Descriptions, Modal, Tag, message } from 'antd';
import { listJobDestinationGroup, unmountListJobDestinationsGroup } from '../../../../redux/actions/master/jobDestinations/jobDestinationsAction';
import { EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Menu from '../Menu';
const { confirm } = Modal;
const { Text } = Typography;
const Icon = ({ type, ...rest }) => {
  const icons = require(`@ant-design/icons`);
  const Component = icons[type];
  return <Component {...rest} />
};
class CandidateDetailJobPreferences extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      visible: false,
      isSalary: false,
      salaryFrom: null,
      salaryTo: null,
      dataJobPosition: [],
    }
  }

  componentDidMount() {
    const { 
      actionGetDetail, 
      actionJobIndustry, 
      actionGetJobPosition, 
      actionGetJobDestination, 
      actionGetCountry, 
      match: { params } 
    } = this.props;
    actionGetDetail(params.id, (response) => {
      const idJobIndustry = response.data.listJobIndustry.length !== 0 ? response.data.listJobIndustry.map(item => item.jobIndustryId) : []
      this.setState({ 
        salaryFrom: response.data.salaryFrom,
        salaryTo: response.data.salaryTo
      })
      actionGetJobPosition(idJobIndustry.join(), (res) => {
        this.setState({ dataJobPosition: res })
      })
    })
    actionJobIndustry()
    actionGetJobDestination()
    actionGetCountry()
  }

  showModal = () => {
    return this.setState({ visible: true })
  }

  onCancel = () => {
    const { actionGetDetail, match: { params } } = this.props;
    return this.setState({ visible: false, dataJobPosition: [] }, () => {
      actionGetDetail(params.id)
    })
  }

  handleIndustry = (value) => {
    const { actionGetJobPosition } = this.props;
    const qq = value.join()
    this.formRef.current.setFieldsValue({
      listJobPosition: []
    });
    return actionGetJobPosition(qq, (response) => {
      this.setState({ dataJobPosition: response })
    })
  }

  handleSalaryFrom = (value) => {
    const { salaryTo } = this.state
    this.setState({
      salaryFrom: value,
    })
    if(value){
      if(salaryTo <= value){
        this.setState({ isSalary: true })
      }else{
        this.setState({ isSalary: false })
      }
    }else{
      this.setState({ isSalary: false })
    }
  }

  handleSalaryTo = (value) => {
    const { salaryFrom } = this.state
    this.setState({ salaryTo: value })
    if(salaryFrom){
      if(value <= salaryFrom){
        this.setState({ isSalary: true })
      }else{
        this.setState({ isSalary: false })
      }
    }else{
      this.setState({ isSalary: false })
    }
  }

  onFinish = () => {
    const { actionUpdatePreference, actionGetDetail, match: { params } } = this.props;
    this.formRef.current.validateFields().then(values => {
      values.candidateId = params.id
      return actionUpdatePreference(values, () => {
        return this.setState({ visible: false }, () => {
          message.success('Data updated successfully')
          return actionGetDetail(params.id)
        })
      }, (err) => message.error(err))
    })
  }
  
  showConfirmActive = () => {
    const { actionEnable, actionGetDetail ,match: { params } } = this.props;
    confirm({
      title: 'Unable to edit profile due to candidate status is inactive. Do you want to change candidate status to active?',
      icon: <ExclamationCircleOutlined />,
      width: 700,
      onOk() {
        return actionEnable(params.id, () => {
          message.success('Successfully changed to active')
          return actionGetDetail(params.id)
        }, (err) => message.error(err))
      },
      okText: 'Change to Active',
      onCancel() {},
    });
  };

  render() {
    const { visible } = this.state;
    const { getDetailCandidate: { loading, data } } = this.props;
    if(loading){
      return <Skeleton avatar />
    }
    const dataJobIndustry = data.listJobIndustry ? data.listJobIndustry.map(item => item.jobIndustryName) : []
    const dataJobPosition = data.listJobPosition ? data.listJobPosition.map(item => item.jobPositionName) : []
    const dataJobDestination = data.listJobDestination ? data.listJobDestination.map(item => item.jobDestinationName) : []
    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Icon type={this.props.icon} />
                {
                  this.props.url.split('/')[1] === 'all-candidate' ?
                    ' All Candidates'
                  : this.props.url.split('/')[1] === 'new-candidate' ?
                    ' New Candidates'
                  : this.props.url.split('/')[1] === 'counseling-process' ?
                    ' Counseling Process'
                  : this.props.url.split('/')[1] === 'assigned-candidate' ?
                    ' Assigned Candidates'
                  :
                    ' Candidates'
                }
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to={`/${this.props.url.split('/')[1]}`}>
                  {
                    this.props.url.split('/')[1] === 'all-candidate' ?
                      'All Candidates List'
                    : this.props.url.split('/')[1] === 'new-candidate' ?
                      'New Candidates List'
                    : this.props.url.split('/')[1] === 'counseling-process' ?
                      'Counseling Process List'
                    : this.props.url.split('/')[1] === 'assigned-candidate' ?
                      'Assigned Candidates List'
                    :
                      'Candidates List'
                  }
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Candidates Detail</Breadcrumb.Item>
              <Breadcrumb.Item>
                {data.fullName}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Candidate Detail' />
          </Col>
          {/* Menu */}
          <Col span={7}>
            <Card style={{ border: 'none' }}>
              <Row gutter={[16, 24]}>
                <Col span={24}>
                  <Space direction="horizontal">
                    <Avatar size={100} src={data.profilePicUrl ? data.profilePicUrl : svg} />
                    <Space direction="vertical" size="small">
                      <Text style={{ color: '#4d4d4d', fontSize: 18 }} strong>{data.fullName}</Text>
                      <Text type="secondary">{data.candidateNumber}</Text>
                      {
                        data.enabled ? 
                          <Tag color="success">Active</Tag>
                        :
                          <Tag color="error">Inactive</Tag>
                      }
                    </Space>
                  </Space>
                </Col>
                <Col span={24}>
                  <Menu rowKey="jobPreferences" id={data.id} email={data.email} />
                </Col>
              </Row>
            </Card>
          </Col>
          {/* Content */}
          <Col span={17}>
            <Card 
              style={{ border: 'none' }}
              title={<Text strong>Job Preferences</Text>} 
              extra={<Button key="1" className="btn-edit-candidate" type="primary" icon={<EditOutlined />} onClick={data.enabled ? this.showModal : this.showConfirmActive}>Edit</Button>} 
            >
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Descriptions column={2} layout="vertical" size="small">
                    <Descriptions.Item label={<Text type="secondary">Job Industry</Text>}>
                      {dataJobIndustry.length > 0 ? dataJobIndustry.join(', ') : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Salary Expectation</Text>}>
                      {
                        data.salaryTo ?
                        <React.Fragment>
                          <Text>{data.currencyCode ? data.currencyCode : 'N/A'} {data.salaryFrom ? data.salaryFrom.toLocaleString() : 'N/A'} - {data.salaryTo ? data.salaryTo.toLocaleString() : 'N/A'}</Text>
                        </React.Fragment>
                        :
                        <Text>
                          {data.salaryFrom ? `${data.currencyCode ? data.currencyCode : 'N/A'} ${data.salaryFrom ? data.salaryFrom.toLocaleString() : 'N/A'}` : 'N/A'}
                        </Text>
                      }
                    </Descriptions.Item>
                  </Descriptions>
                  <Descriptions column={1} layout="vertical" size="small">
                    <Descriptions.Item label={<Text type="secondary">Job Position</Text>}>
                      {dataJobPosition.length > 0 ? dataJobPosition.join(', ') : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Job Destination</Text>}>
                      {dataJobDestination.length > 0 ? dataJobDestination.join(', ') : 'N/A'}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal
          width={1000}
          destroyOnClose
          visible={visible}
          onCancel={this.onCancel}
          title={<Text strong>Job Preferences</Text>}
          footer={[
            <React.Fragment key="1">
              <Button key="back" className="btn-save-primary" onClick={this.onCancel}>Cancel</Button>
              <Button key="submit" className="btn-save-primary" type="primary" onClick={this.onFinish}>Save</Button>
            </React.Fragment>
          ]}
        >
          <EditJobPreference 
            {...this.props} 
            {...this.state}
            formRef={this.formRef} 
            onFinish={this.onFinish}
            handleSalaryTo={this.handleSalaryTo}
            handleIndustry={this.handleIndustry}
            handleSalaryFrom={this.handleSalaryFrom}
          />
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail, unmountGetCountry, unmountGetJobIndustry, unmountGetJobDestination } = this.props;
    unmountGetDetail()
    unmountGetCountry()
    unmountGetJobIndustry()
    unmountGetJobDestination()
  }
}

const mapStateToProps = (state) => ({
  getDetailCandidate  : state.candidate.detail,
  getCountry          : state.countries.master,
  getJobIndustry      : state.jobIndustries.master,
  getJobPosition      : state.jobPositions.listByIndustry,
  getJobDestination   : state.jobDestinations.group,
})

const mapDispatchToProps = {
  actionUpdatePreference    : updateJobPreference,
  actionGetDetail           : detailCandidate,
  actionGetCountry          : masterCountries,
  actionJobIndustry         : masterJobIndustries,
  actionGetJobPosition      : positionByIndustryGroup,
  actionGetJobDestination   : listJobDestinationGroup,
  actionEnable              : enableCandidate,
  unmountGetDetail          : unmountDetailCandidate,
  unmountGetCountry         : unmountMasterCountries,
  unmountGetJobIndustry     : unmountMasterJobIndustries,
  unmountGetJobDestination  : unmountListJobDestinationsGroup
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateDetailJobPreferences)
