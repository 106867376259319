import React, { Component } from 'react';
import moment from 'moment';
import { Form, Row, Col, AutoComplete, Input, Select, Spin, DatePicker, Checkbox, Typography } from 'antd';
const { Text } = Typography;

export default class EditEducation extends Component {
  render() {
    const { 
      formRef, 
      isYear,
      current,
      isBinawan,
      onSubmitEdit, 
      getEducation,
      dataEducation,
      getInstitution,
      getFieldOfStudy,
      onChangeEducationLevel,
      onChangeEndYear,
      onChangeStartYear,
      onFocusInstitution, 
      onSearchInstitution,
      onChangeInstitution,
      onFocusFieldOfStudy,
      onSearchFieldOfStudy,
      handleChecked
    } = this.props;

    return (
      <React.Fragment>
        <Form ref={formRef} onFinish={onSubmitEdit} layout="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item 
                label="School/University"
                name="name"
                validateFirst
                initialValue={dataEducation.name ? dataEducation.name : null}
                rules={[
                  { max: 50, message: '50 characters only' },
                  { required: true, message: 'This is a required field' },
                  { min: 5, message: 'School must be at least 5 characters' },
                  { pattern: new RegExp("^[a-zA-Z0-9/() ]+$"), message: 'Special characters are not allowed”' },
                ]}
              >
                <AutoComplete
                  filterOption={false}
                  style={{ width: '100%' }}
                  onFocus={onFocusInstitution}
                  onSearch={onSearchInstitution}
                  onChange={onChangeInstitution}
                  notFoundContent={getInstitution.loading ? <Spin size="small" /> : null}
                >
                  {
                    getInstitution.data && getInstitution.data.map((item, i) => (
                      <AutoComplete.Option key={i} value={item.name}>
                        {item.name}
                      </AutoComplete.Option>
                    ))
                  }
                </AutoComplete>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="Education Level"
                name="educationLevelId"
                rules={[
                  { required: true, message: 'This is a required field' },
                ]}
                initialValue={dataEducation.educationLevelId ? dataEducation.educationLevelId : null}
              >
                <Select onChange={onChangeEducationLevel}>
                  {
                    getEducation?.data?.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="Fields of Study" 
                name="fieldOfStudy"
                validateFirst
                initialValue={dataEducation.fieldOfStudy ? dataEducation.fieldOfStudy : null}
                rules={[
                  { required: isBinawan, message: 'This is a required field' },
                  { max: 100, message: '100 characters only' },
                ]}
              >
                <AutoComplete
                  filterOption={false}
                  style={{ width: '100%' }}
                  onFocus={onFocusFieldOfStudy}
                  onSearch={onSearchFieldOfStudy}
                  notFoundContent={getFieldOfStudy.loading ? <Spin size="small" /> : null}
                >
                  {
                    getFieldOfStudy.data && getFieldOfStudy.data.map((item, i) => (
                      <AutoComplete.Option key={i} value={item.name}>
                        {item.name}
                      </AutoComplete.Option>
                    ))
                  }
                </AutoComplete>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="Score/Grade/GPA"
                name="gpa"
                initialValue={dataEducation.gpa ? dataEducation.gpa : null}
                rules={[
                  { required: isBinawan, message: 'This is a required field' },
                  { max: 4, message: '4 characters only' },
                  { pattern: /^[0-9a-zA-Z.]*$/, message: 'Only dot (.) is allowed, No spaces allowed' },
                ]}
              >
                <Input placeholder="e.g.: 3.50" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item 
                label="Start Year" 
                name="startYear"
                initialValue={dataEducation.startYear ? moment(dataEducation.startYear, 'YYYY') : null}
                rules={[
                  { required: isBinawan, message: 'This is a required field' }
                ]}
              >
                <DatePicker 
                  picker="year" 
                  style={{ width: '100%' }}
                  onChange={onChangeStartYear} 
                  disabledDate={ d => !d || d.isBefore(moment().subtract(30, 'years').format('YYYY').toString()) || d.isAfter(moment().add(1, 'years').format('YYYY').toString()) } 
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item 
                label="End Year"
                name="endYear"
                validateStatus={isYear ? "error" : null}
                initialValue={dataEducation.endYear ? moment(dataEducation.endYear, 'YYYY') : null}
                help={isYear ? "Your end year can't be earlier than your start year" : null}
                rules={[
                  { required: isBinawan && !current, message: 'This is a required field' },
                ]}
              >
                {
                  current ?
                    <Text className="fs-12" type="secondary">Present</Text>
                  :
                    <DatePicker 
                      picker="year" 
                      style={{ width: '100%' }}
                      onChange={onChangeEndYear} 
                      disabledDate={ d => !d || d.isBefore(moment().subtract(30, 'years').format('YYYY').toString()) || d.isAfter(moment().add(1, 'years').format('YYYY').toString()) } 
                    />
                }
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label=" " name="current">
                <Checkbox className="fs-12 dark-color" onChange={handleChecked} checked={current}>Ongoing</Checkbox>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="Website"
                name="website"
                initialValue={dataEducation.website ? dataEducation.website : null}
                rules={[
                  { required: isBinawan, message: 'This is a required field' }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item  
                label="Activities and Organization Experiences"
                name="experience"
                initialValue={dataEducation.experience ? dataEducation.experience : null}
                rules={[
                  { max: 200, message: '200 characters only' }
                ]}
              >
                <Input.TextArea rows={3} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}
