import React from 'react';
import { Link } from 'react-router-dom';
import { FileDoneOutlined, UnorderedListOutlined, ExportOutlined, DoubleRightOutlined, HistoryOutlined } from '@ant-design/icons';
import { Typography, Button, Popover, Space, Badge } from 'antd';

const { Text } = Typography;

const menu = (record, showModalAssignJob, showModalHistory, modalExportData) => (
  <Space direction='vertical'>
    {
      record.jobAppliedStatus === 'Rejected' ?
        <Text onClick={() => showModalAssignJob(record.candidateId, record.candidateNumber, record.fullName)} style={{ cursor: 'pointer' }}>Re-assign to a job <DoubleRightOutlined style={{ color: '#32AB6D' }} /></Text>
      : null
    }
    <Text style={{ cursor: 'pointer' }} onClick={() => showModalHistory(record.listHistory)}>History <HistoryOutlined style={{ color: '#32AB6D' }} /></Text>
    <Text style={{ cursor: 'pointer' }} onClick={() => modalExportData(record.candidateId)}>Export Data <ExportOutlined style={{ color: '#32AB6D' }} /></Text>
  </Space>
)

export const columns = (handleRoute, showModalNotes, showModalAssignJob, showModalHistory, modalExportData) => {
  return [
    {
      title: 'Number',
      key: 'number',
      width: 200,
      fixed: 'left',
      render: (record) => (
        <React.Fragment>
          <Link className="link" to='#' onClick={() => handleRoute(record)}>
          {record.candidateNumber}
          </Link>
        </React.Fragment>
      )
    },
    {
      title: 'Name',
      key: 'name',
      width: 300,
      align: 'center',
      render: (record) => (
        <Text className='row-pointer' onClick={() => handleRoute(record)}>
          {record.email ? record.fullName : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Email',
      key: 'email',
      width: 300,
      align: 'center',
      render: (record) => (
        <Text className='row-pointer' onClick={() => handleRoute(record)}>
          {record.email ? record.email : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Phone',
      key: 'phone',
      width: 180,
      align: 'center',
      render: (record) => (
        <Text className='row-pointer' onClick={() => handleRoute(record)}>
          {`+62${record.mobilePhone}`}
        </Text>
      )
    },
    {
      title: 'Education',
      key: 'education',
      width: 250,
      align: 'center',
      render: (record) => (
        <Text className='row-pointer' onClick={() => handleRoute(record)}>
          {record.lastEducation ? record.lastEducation : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Gender',
      key: 'gender',
      width: 80,
      align: 'center',
      render: (record) => (
        record.gender ? 
          record.gender === 'MALE' ? 
            <Text className='row-pointer' onClick={() => handleRoute(record)} style={{ color: '#4273B9' }}>
              Man 
            </Text>
          : 
            <Text className='row-pointer' onClick={() => handleRoute(record)} style={{ color: '#F7BE8F' }}>
              Woman 
            </Text>
        : 
          'N/A'
      )
    },
    {
      title: 'Age',
      key: 'age',
      width: 50,
      align: 'center',
      render: (record) => (
        <Text className='row-pointer' onClick={() => handleRoute(record)}>
          {record.age ? record.age : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Experience',
      key: 'experience',
      width: 200,
      align: 'center',
      render: (record) => (
        <Text className='row-pointer' onClick={() => handleRoute(record)}>
          {record.lengthOfExperience ? record.lengthOfExperience : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Assigned to',
      key: 'assignTo',
      width: 200,
      align: 'center',
      render: (record) => (
        <Text className='row-pointer' onClick={() => handleRoute(record)}>
          {record.assignedTo ? record.assignedTo : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Candidate Status',
      key: 'candidateStatus',
      width: 140,
      fixed: 'right',
      align: 'center',
      render: (record) => (
        <Text className='row-pointer' onClick={() => handleRoute(record)}>
          {record.jobAppliedStatus ? record.jobAppliedStatus : 'N/A'}
        </Text>
      )
    },
    {
      width: 100,
      fixed: 'right',
      render: (record) => (
        <Badge dot={record.listCandidateNotes?.length > 0}>
          <Button 
            className="btn-create-primary" 
            type="primary" 
            size='small'
            ghost 
            onClick={() => showModalNotes(record.listCandidateNotes, record.candidateId)}
            style={{ float: 'right' }}
          >
            <FileDoneOutlined /> Notes  
          </Button>
        </Badge>
      )
    },
    {
      align: 'right',
      width: 50,
      fixed: 'right',
      render: (record) => (
        <Popover placement="leftTop" content={menu(record, showModalAssignJob, showModalHistory, modalExportData)} trigger="click">
          <UnorderedListOutlined style={{ fontSize: 20 }} />
        </Popover>
      )
    },
  ]
}
