import React from 'react'
import { Row, Col, Avatar, Typography, Space, Descriptions, Image } from 'antd'
import { UserOutlined } from '@ant-design/icons';

const { Text } = Typography

export default function Header(props) {
  const { data } = props
  return (
    <Row>
      <Col span={3} style={{ paddingLeft: 16 }}>
        <Avatar size={100} icon={<UserOutlined />} src={data.profilePicUrl ? <Image src={data.profilePicUrl} /> : null } />
      </Col>
      <Col span={12} style={{ paddingRight: 16, paddingLeft: 40 }}>
        <Space direction="vertical" size="small">
          <Text>{data.fullName ? data.fullName : 'N/A'}</Text>
          <Text>{data.email ? data.email : 'N/A'}</Text>
          <Text>{`+${data.callingCode ? data.callingCode : 'N/A'}${data.mobilePhone ? data.mobilePhone : 'N/A'}`}</Text>
          <Text>Current location: {data.currentCityName ? data.currentCityName : 'N/A'}, {data.currentCountryName ? data.currentCountryName : 'N/A'}</Text>
        </Space>
      </Col>
      <Col span={9}>
        <Descriptions layout="vertical" size="small">
          <Descriptions.Item label="About">
            {data.about ? data.about : 'N/A'}
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  )
}
