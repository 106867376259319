import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { UserOutlined } from '@ant-design/icons';

export default function index(props) {
  return (
    <Menu defaultSelectedKeys={[props.rowKey]} mode="inline">
      <Menu.Item key="bankAccount" icon={<UserOutlined />}>
          <Link to="/master-data">Bank Account</Link>
        </Menu.Item>
    </Menu>
  )
}