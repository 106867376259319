import React from 'react'
import { Typography, Timeline, Empty } from 'antd'
import { ClockCircleOutlined } from '@ant-design/icons'

const { Text } = Typography

export default function ApplicantHistory(props) {
  const { dataApplicantHistory } = props
  return (
    <div style={{ height: 500, overflowY: 'auto', overflowX: 'hidden', padding: 32 }}>
      {
        dataApplicantHistory?.length > 0 ?
          <Timeline mode='right'>
            {
              dataApplicantHistory?.map((item, i) => 
                <Timeline.Item key={i} dot={<ClockCircleOutlined />} label={item.createdDate}>
                  <Text style={{ color: item.phaseName === 'Rejected' ? '#ff3e3e' : '#000' }}>{item.phaseName}</Text>
                </Timeline.Item>
              )
            }
          </Timeline>
        :
          <Empty />
      }
    </div>
  )
}
