import React, { Component } from 'react';
import { Form, Row, Col, Select } from 'antd';

export default class EditUniform extends Component {
  render() {
    const { 
      getDetailCandidate: { data }, 
      formRef, 
      onSubmitEdit, 
    } = this.props;

    return (
      <React.Fragment>
        <Form ref={formRef} onFinish={onSubmitEdit} layout="vertical">
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item 
                label="Shirt Size"
                name="shirt"
                validateFirst
                initialValue={data.shirt ? data.shirt : null}
              >
                <Select>
                  <Select.Option key="XS" >XS</Select.Option>
                  <Select.Option key="S" >S</Select.Option>
                  <Select.Option key="M">M</Select.Option>
                  <Select.Option key="L">L</Select.Option>
                  <Select.Option key="XL">XL</Select.Option>
                  <Select.Option key="XLL">XLL</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item 
                label="Pant Size"
                name="pants"
                validateFirst
                initialValue={data.pants ? data.pants : null}
              >
                <Select>
                  <Select.Option key="25">25</Select.Option>
                  <Select.Option key="26">26</Select.Option>
                  <Select.Option key="27">27</Select.Option>
                  <Select.Option key="28">28</Select.Option>
                  <Select.Option key="29">29</Select.Option>
                  <Select.Option key="30">30</Select.Option>
                  <Select.Option key="31">31</Select.Option>
                  <Select.Option key="32">32</Select.Option>
                  <Select.Option key="33">33</Select.Option>
                  <Select.Option key="34">34</Select.Option>
                  <Select.Option key="35">35</Select.Option>
                  <Select.Option key="36">36</Select.Option>
                  <Select.Option key="37">37</Select.Option>
                  <Select.Option key="38">38</Select.Option>
                  <Select.Option key="39">39</Select.Option>
                  <Select.Option key="40">40</Select.Option>
                  <Select.Option key="41">41</Select.Option>
                  <Select.Option key="42">42</Select.Option>
                  <Select.Option key="42">42</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item 
                label="Shoes Size"
                name="shoes"
                validateFirst
                initialValue={data.shoes ? data.shoes : null}
              >
                <Select>
                  <Select.Option key="34.5" >34.5</Select.Option>
                  <Select.Option key="35" >35</Select.Option>
                  <Select.Option key="35.5" >35.5</Select.Option>
                  <Select.Option key="36" >36</Select.Option>
                  <Select.Option key="36.5">36.5</Select.Option>
                  <Select.Option key="37" >37</Select.Option>
                  <Select.Option key="37.5">37.5</Select.Option>
                  <Select.Option key="38" >38</Select.Option>
                  <Select.Option key="38.5">38.5</Select.Option>
                  <Select.Option key="39" >39</Select.Option>
                  <Select.Option key="39.5">39.5</Select.Option>
                  <Select.Option key="40">40</Select.Option>
                  <Select.Option key="40.5">40.5</Select.Option>
                  <Select.Option key="41">41</Select.Option>
                  <Select.Option key="41.5<">41.5</Select.Option>
                  <Select.Option key="42">42</Select.Option>
                  <Select.Option key="42.5">42.5</Select.Option>
                  <Select.Option key="43">43</Select.Option>
                  <Select.Option key="43.5">43.5</Select.Option>
                  <Select.Option key="44">44</Select.Option>
                  <Select.Option key="44.5">44.5</Select.Option>
                  <Select.Option key="45">45</Select.Option>
                  <Select.Option key="46">46</Select.Option>
                  <Select.Option key="47">47</Select.Option>
                  <Select.Option key="48">48</Select.Option>
                  <Select.Option key="49">49</Select.Option>
                  <Select.Option key="50">50</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}
