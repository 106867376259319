import React, { Component } from 'react';
import EditSocial from './EditSocial';
import EditInformation from './EditInformation';
import svg from '../../../../../assets/img/profile.svg';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { checkEmail, checkPhone } from '../../../../../redux/actions/check/checkAction';
import { cityByProvince } from '../../../../../redux/actions/master/cities/citiesAction';
import { provinceByCountry } from '../../../../../redux/actions/master/provinces/provincesAction';
import { villageBySubDistrict } from '../../../../../redux/actions/master/villages/villagesAction';
import { subDistrictByCity } from '../../../../../redux/actions/master/subDistricts/subDistrictsAction';
import { masterCountries, unmountMasterCountries } from '../../../../../redux/actions/master/countries/countriesAction';
import { addSocial, deleteSocial, detailCandidate, unmountDetailCandidate, updateContact } from '../../../../../redux/actions/candidate/candidateAction';
import { Skeleton, Row, Col, Breadcrumb, PageHeader, Card, Menu, Space, Avatar, Typography, Descriptions, Button, Divider, Modal, message } from 'antd';
import { UserOutlined, PhoneOutlined, LikeOutlined, ProjectOutlined, BulbOutlined, IdcardOutlined, FormatPainterOutlined, FolderOutlined, TrophyOutlined, LockOutlined, EditOutlined } from '@ant-design/icons';
const { Text } = Typography;

class CandidateBinawanDetailContactInformation extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      visibleInformation: false,
      visibleSocial: false,
      callingCode: "62",
      dataProvince: [],
      dataCity: [],
      dataSubDistrict: [],
      dataVillage: [],
      name: null,
      socialMedia: null,
      url: null
    }
  }

  componentDidMount() {
    const { 
      actionGetDetail, 
      actionGetCountry, 
      actionGetProvince, 
      actionGetCity, 
      actionGetSubDistrict, 
      actionGetVillage, 
      match: { params } 
    } = this.props;
    actionGetDetail(params.id, (response) => {
      this.setState({ 
        callingCode: Number(response.data.callingCode) 
      })
      actionGetProvince(response.data.countryId, (response) => {
        this.setState({ dataProvince: response.data })
      })
      if(response.data.provinceId){
        actionGetCity(response.data.provinceId, (response) => {
          this.setState({ dataCity: response.data })
        })
      }
      if(response.data.cityId){
        actionGetSubDistrict(response.data.cityId, (response) => {
          this.setState({ dataSubDistrict: response.data })
        })
      }
      if(response.data.subDistrictId){
        actionGetVillage(response.data.subDistrictId, (response) => {
          this.setState({ dataVillage: response.data })
        })
      }
    })
    actionGetCountry()
  }

  showModal = (type) => {
    if(type === "information"){
      return this.setState({ visibleInformation: true })
    }
    if(type === "social"){
      return this.setState({ visibleSocial: true })
    }
  }

  onCancel = (type) => {
    if(type === "information"){
      return this.setState({ visibleInformation: false })
    }
    if(type === "social"){
      return this.setState({ 
        visibleSocial: false, 
        name: null,
        socialMedia: null,
        url: null
      })
    }
  }

  checkEmail = (value) => {
    const { actionCheckEmail, getDetailCandidate: { data } } = this.props;
    const oldEmail = data.email
    if(oldEmail === value){
      return null
    }else{
      return actionCheckEmail(value, (response) => {
        if(response){
          message.error('Email already exist')
          this.formRef.current.setFieldsValue({
            email: data.email,
          });
        }
      })
    }
  }

  checkPhone = (value) => {
    const { actionCheckPhone, getDetailCandidate: { data } } = this.props;
    const valuePhone = `${this.state.callingCode}${value.replace(/^0+/, '')}`
    const oldMobilePhone = `${data.callingCode}${data.mobilePhone}`
    this.formRef.current.setFieldsValue({
      mobilePhone: value.replace(/^0+/, ''),
    });
    if(oldMobilePhone === valuePhone){
      return null
    }else{
      return actionCheckPhone(valuePhone.replace(/^0+/, ''), (response) => {
        if(response){
          message.error('Mobile phone already exist')
          this.formRef.current.setFieldsValue({
            mobilePhone: data.mobilePhone,
          });
        }
      })
    }
  }

  handleChangeCallingCode = (_, index) => {
    this.setState({ callingCode: index.children.replace(/^[+]+/, '') })
    this.formRef.current.setFieldsValue({
      mobilePhone: null
    });
  }

  handleChangeCountry = (value) => {
    const { actionGetProvince } = this.props;
    this.formRef.current.setFieldsValue({
      cityId: null,
      provinceId: null,
      subDistrictId: null,
      villageId: null
    });
    return actionGetProvince(value, (response) => {
      this.setState({ 
        dataProvince: response.data, 
        dataCity: [], 
        dataSubDistrict: [], 
        dataVillage: [] 
      })
    })
  }

  handleChangeProvince = (value) => {
    const { actionGetCity } = this.props;
    this.formRef.current.setFieldsValue({
      cityId: null,
      subDistrictId: null,
      villageId: null
    });
    return actionGetCity(value, (response) => {
      this.setState({ 
        dataCity: response.data,
        dataSubDistrict: [], 
        dataVillage: []
      })
    })
  }

  handleChangeCity = (value) => {
    const { actionGetSubDistrict } = this.props;
    this.formRef.current.setFieldsValue({
      subDistrictId: null,
      villageId: null
    });
    return actionGetSubDistrict(value, (response) => {
      this.setState({ 
        dataSubDistrict: response.data,
        dataVillage: []
      })
    })
  }

  handleChangeSubDistrict = (value) => {
    const { actionGetVillage } = this.props;
    this.formRef.current.setFieldsValue({
      villageId: null
    });
    return actionGetVillage(value, (response) => {
      this.setState({ dataVillage: response.data })
    })
  }

  onChangeSelect = (e) => {
    const socialMedia =
      e === 'linkedin' ? 
        'www.linkedin.com/in/'
      : e === 'facebook' ? 
        'www.facebook.com/'
      : e === 'twitter' ? 
        'www.twitter.com/'
      : e === 'instagram' ? 
        'www.instagram.com/'
      :
        'medium.com'
    this.setState({ name: e, socialMedia: socialMedia })
  }

  onChangeInput = (e) => {
    this.setState({ url: e })
  }

  handleDelete = (id) => {
    const { actionDeleteSocial, actionGetDetail, match: { params } } = this.props;
    return actionDeleteSocial(id, () => {
      message.success('Data deleted successfully')
      return actionGetDetail(params.id)
    }, (err) => message.error(err))
  }

  onSubmitInformation = () => {
    const { actionUpdateInformation, actionGetDetail, match: { params } } = this.props;
    this.formRef.current.validateFields().then(values => {
      values.id = Number(params.id)
      return actionUpdateInformation(values, () => {
        return this.setState({ visibleInformation: false }, () => {
          message.success('Data updated successfully')
          return actionGetDetail(params.id)
        })
      })
    })
  }

  onSubmitSocial = () => {
    const { socialMedia } = this.state;
    const { actionAddSocial, actionGetDetail, match: { params } } = this.props
    this.formRef.current.validateFields().then(values => {
      values.candidateId = params.id
      values.url = values.name === 'medium' ? `${values.url}.${socialMedia}` : `${socialMedia}${values.url}`
      return actionAddSocial(values, () => {
        this.formRef.current.setFieldsValue({
          name: null,
          url: null,
        });
        this.setState({ name: null, socialMedia: null }, () => {
          message.success('Data updated successfully')
          return actionGetDetail(params.id)
        })
      }, (err) => message.error(err))
    })
  }
  
  render() {
    const { visibleInformation, visibleSocial } = this.state;
    const { getDetailCandidate: { loading, data } } = this.props;
    if(loading){
      return <Skeleton avatar />
    }
    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><UserOutlined /> Candidates</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/binawan/candidate" style={{ color: '#0076de' }}>
                  Candidates List
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Candidates Detail</Breadcrumb.Item>
              <Breadcrumb.Item>
                {data.fullName}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Candidate Detail' />
          </Col>
          {/* Menu */}
          <Col span={7}>
            <Card style={{ border: 'none' }}>
              <Row gutter={[16, 24]}>
                <Col span={24}>
                  <Space direction="horizontal">
                    <Avatar size={100} src={data.profilePicUrl ? data.profilePicUrl : svg} />
                    <Space direction="vertical" size="small">
                      <Text style={{ color: '#4d4d4d', fontSize: 18 }} strong>{data.fullName}</Text>
                      <Text type="secondary">{data.candidateNumber}</Text>
                    </Space>
                  </Space>
                </Col>
                <Col span={24}>
                  <Menu defaultSelectedKeys={['2']} mode="inline">
                    <Menu.Item key="1" icon={<UserOutlined />}>
                      <Link to={`/binawan/candidate/detail/${data.id}/biodata`}>
                        Biodata
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="2" icon={<PhoneOutlined />}>
                      <Link to={`/binawan/candidate/detail/${data.id}/contact-information`}>
                        Contact Information
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="3" icon={<LikeOutlined />}>
                      <Link to={`/binawan/candidate/detail/${data.id}/job-preferences`}>
                        Job Preferences
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="4" icon={<ProjectOutlined />}>
                      <Link to={`/binawan/candidate/detail/${data.id}/work-experience`}>
                        Work Experience
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="5" icon={<BulbOutlined />}>
                      <Link to={`/binawan/candidate/detail/${data.id}/education`}>
                        Education
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="6" icon={<IdcardOutlined />}>
                      <Link to={`/binawan/candidate/detail/${data.id}/training-and-certification`}>
                        Training and Certification
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="7" icon={<FormatPainterOutlined />} >
                      <Link to={`/binawan/candidate/detail/${data.id}/skills`}>
                        Skills
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="8" icon={<FolderOutlined />}>
                      <Link to={`/binawan/candidate/detail/${data.id}/portfolios`}>
                        Portfolios
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="9" icon={<TrophyOutlined />}>
                      <Link to={`/binawan/candidate/detail/${data.id}/achievements`}>
                        Achievements
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="10" icon={<LockOutlined />}>
                      <Link to={`/binawan/candidate/detail/${data.id}/reset-password`}>
                        Reset Password
                      </Link>
                    </Menu.Item>
                  </Menu>
                </Col>
              </Row>
            </Card>
          </Col>
          {/* Content */}
          <Col span={17}>
            <Card title={<Text strong>Contact Information</Text>} style={{ border: 'none' }}>
              <Row gutter={[16, 16]}>
                <Col span={21}>
                  <Descriptions column={2} layout="vertical" size="small">
                    <Descriptions.Item label={<Text type="secondary">Email</Text>}>
                      {data.email ? data.email : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Sub-district</Text>}>
                      {data.subDistrictName ? data.subDistrictName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Mobile Number</Text>}>
                      {data.mobilePhone ? `+${data.callingCode}${data.mobilePhone}` : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Village</Text>}>
                      {data.villageName ? data.villageName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Country</Text>}>
                      {data.countryName ? data.countryName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Postal Code</Text>}>
                      {data.zipCode ? data.zipCode : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Province</Text>}>
                      {data.provinceName ? data.provinceName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Street Address</Text>}>
                      {data.address ? data.address : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">City</Text>}>
                      {data.cityName ? data.cityName : 'N/A'}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                <Col span={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button className="btn-edit-candidate" type="primary" icon={<EditOutlined />} onClick={() => this.showModal('information')}>Edit</Button>
                </Col>
                <Divider dashed />
                <Col span={21}>
                  <Descriptions title="Social Media" column={2} layout="vertical" size="small">
                    {
                      data.listSocialMedia.length > 0 ?
                      <React.Fragment>
                        {
                          data.listSocialMedia.map((item, i) => (
                            <Descriptions.Item key={i} label={<Text type="secondary">{item.name}</Text>}>
                              <a href={`https://${item.url}`} target="_blank" rel='noreferrer' style={{ color: '#0076de' }}>
                                {item.url}
                              </a>
                            </Descriptions.Item>
                          ))
                        }
                      </React.Fragment>
                      :
                      <Descriptions.Item>
                        N/A
                      </Descriptions.Item>
                    }
                  </Descriptions>
                </Col>
                <Col span={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button className="btn-edit-candidate" type="primary" icon={<EditOutlined />} onClick={() => this.showModal('social')}>Edit</Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal
          key="1"
          centered
          width={1000}
          destroyOnClose
          visible={visibleInformation}
          onCancel={() => this.onCancel('information')}
          title={<Text strong>Contact Information</Text>}
          footer={[
            <React.Fragment key="1">
              <Button className="btn-save-primary" key="back" onClick={() => this.onCancel('information')}>Cancel</Button>
              <Button className="btn-save-primary" key="submit" type="primary" onClick={this.onSubmitInformation}>Save</Button>
            </React.Fragment>
          ]}
        >
          <EditInformation 
            {...this.props} 
            {...this.state}
            formRef={this.formRef} 
            checkEmail={this.checkEmail}
            checkPhone={this.checkPhone}
            handleChangeCountry={this.handleChangeCountry}
            handleChangeProvince={this.handleChangeProvince}
            handleChangeCity={this.handleChangeCity}
            handleChangeSubDistrict={this.handleChangeSubDistrict}
            onSubmitInformation={this.onSubmitInformation}
            handleChangeCallingCode={this.handleChangeCallingCode}
          />
        </Modal>
        <Modal
          key="2"
          width={1000}
          destroyOnClose
          footer={false}
          visible={visibleSocial}
          onCancel={() => this.onCancel('social')}
          title={<Text strong>Social Media</Text>}
        >
          <EditSocial 
            {...this.props}
            {...this.state}
            formRef={this.formRef} 
            onChangeSelect={this.onChangeSelect}
            onChangeInput={this.onChangeInput}
            handleDelete={this.handleDelete}
            onSubmitSocial={this.onSubmitSocial}
          />
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail, unmountGetCountry } = this.props;
    unmountGetDetail()
    unmountGetCountry()
  }
}

const mapStateToProps = (state) => ({
  getDetailCandidate  : state.candidate.detail,
  getCountry          : state.countries.master,
})

const mapDispatchToProps = {
  actionUpdateInformation : updateContact,
  actionAddSocial         : addSocial,
  actionDeleteSocial      : deleteSocial,
  actionCheckEmail        : checkEmail,
  actionCheckPhone        : checkPhone,
  actionGetCountry        : masterCountries,
  actionGetProvince       : provinceByCountry,
  actionGetCity           : cityByProvince,
  actionGetSubDistrict    : subDistrictByCity,
  actionGetVillage        : villageBySubDistrict,
  actionGetDetail         : detailCandidate,
  unmountGetDetail        : unmountDetailCandidate,
  unmountGetCountry       : unmountMasterCountries
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateBinawanDetailContactInformation)
