const LOAD_DATA_JOBS_CANDIDATE_BY_PHONE           = 'LOAD_DATA_JOBS_CANDIDATE_BY_PHONE'
const LOAD_DATA_JOBS_CANDIDATE_BY_PHONE_SUCCESS   = 'LOAD_DATA_JOBS_CANDIDATE_BY_PHONE_SUCCESS'
const LOAD_DATA_JOBS_CANDIDATE_BY_PHONE_FAILED    = 'LOAD_DATA_JOBS_CANDIDATE_BY_PHONE_FAILED'
const UNMOUNT_DATA_JOBS_CANDIDATE_BY_PHONE        = 'UNMOUNT_DATA_JOBS_CANDIDATE_BY_PHONE'

const initialState = {
  loading: true,
  data: null,
  message: null
}

const jobsCandidateByPhoneReducer = (state = initialState, action) => {
  switch(action.type){
    case LOAD_DATA_JOBS_CANDIDATE_BY_PHONE:
      return {
        ...state,
        loading: true,
        data: null
      }
    case LOAD_DATA_JOBS_CANDIDATE_BY_PHONE_SUCCESS:
      return { 
        ...state, 
        loading: false,
        data: action.payload.data,
        message: 'SUCCESS'
      }
    case LOAD_DATA_JOBS_CANDIDATE_BY_PHONE_FAILED:
      return { 
        ...state, 
        loading: true,
        data: null,
        message: 'FAILED'
      }
    case UNMOUNT_DATA_JOBS_CANDIDATE_BY_PHONE:
      return { 
        ...state, 
        loading: true,
        data: null
      }
    default:
      return state
  }
}

export default jobsCandidateByPhoneReducer;