import React, { Component } from 'react';
// import moment from 'moment';
import { Form, Row, Col, Input, Select, Spin, DatePicker, Typography, Checkbox, Space,  Tooltip, } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import InfoPhotoVisa from '../../../../components/Document/infoVisa';
import InfoKTP from '../../../../components/Document/infoKTP';
import InfoPaspor from '../../../../components/Document/infoPaspor';
import InfoKk from '../../../../components/Document/infoKK';
const { Text } = Typography;

export default class AddDocument extends Component {

  render() {
    const { 
      formRef, 
      getDocumentTypes,
      // isDate,
      current, 
      onSubmitAdd, 
      handleChecked,
      onChangeEndDate,
      onChangeStartDate,
      uploadDocument,
      onChangeInput,
      selectedOption,
      disabledDatePassport,
      startDate,
      disabledNextMonth
    } = this.props;


    return (
      <React.Fragment>
        <Form ref={formRef} onFinish={onSubmitAdd} layout="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <Text className="fs-12" type="danger">* </Text>
              <Text className="fs-12" type="secondary">Document Type</Text>
              <Form.Item 
                // label="Document Type"
                name="documentTypeId"
                validateFirst
                rules={[
                  { required: true, message: 'This is a required field' },
                ]}
              >
                <Select
                  loading={getDocumentTypes.loading}
                  notFoundContent={getDocumentTypes.loading ? <Spin size="small" /> : null}
                  value={selectedOption}
                  onChange={(value) => onChangeInput(value)}
                >
                  {
                    getDocumentTypes.data && getDocumentTypes.data.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              {
                selectedOption !== 29 ?
                  <>
                    { selectedOption === 4 || selectedOption === 8 || selectedOption === 1 ? <Text className="fs-12" type="danger">* </Text> : null }
                    <Text className="fs-12" type="secondary">
                      {
                        selectedOption === 4 ? 
                          'NIK' 
                        : selectedOption === 8 ? 
                          'Paspor Number' 
                        : selectedOption === 1 ?
                          'Family Card Number'
                        : 'Document Number' }
                    </Text>
                    <Form.Item 
                      name="documentNumber"
                      validateFirst
                      rules={
                        selectedOption === 4 || selectedOption === 1 ?
                          [
                            { pattern: /^[0-9]*$/, message: 'Numbers Only' },
                            { required: selectedOption === 4 || selectedOption === 1 , message: "This is a required field" },
                            { len: 16, message: 'It must consist of 16 digit numbers' }
                          ]
                          : selectedOption === 8 ? 
                            [
                              { required: selectedOption === 8 , message: "This is a required field" },
                              { min: 5, message: 'Minimum 5 characters' },
                              { max: 20, message: '20 characters only' }
                            ]
                          :
                            [
                              { max: 50, message: 'Max 50 characters only' }
                            ]
                      }
                    >
                      <Input />
                    </Form.Item>
                  </>
                : null
              }
            </Col>
            {
              selectedOption === 29  || selectedOption === 8 || selectedOption === 1  || selectedOption === 4 ?
                null
              : 
                <Col span={24}>
                  <Form.Item name="current">
                    <Checkbox onChange={handleChecked} checked={current}>This document does not expire</Checkbox>
                  </Form.Item>
                </Col>
            }
            {
              selectedOption !== 29 ?
                <Col span={12}>
                  { selectedOption === 8 || selectedOption === 4 ? <Text className="fs-12" type="danger">* </Text> : null }
                  <Text className="fs-12" type="secondary">Issue Date</Text>
                  <Form.Item 
                    // label="Issue Date" 
                    name="issueDate"
                    rules={[
                      { required: selectedOption === 8 || selectedOption === 4 || selectedOption === 1 , message: 'This is a required field' },
                    ]}
                  >
                    <DatePicker 
                      picker="month" 
                      placeholder="YYYY-MM" 
                      style={{ width: '100%' }}
                      onChange={onChangeStartDate} 
                      disabledDate={ selectedOption === 8 || selectedOption === 4 || selectedOption === 1 ? disabledNextMonth : null }
                    />
                  </Form.Item>
                </Col>
              : null
            }
            {
              selectedOption === 29 || selectedOption === 1 || selectedOption === 4  ?
                null
              : 
                <Col span={12}>
                  { selectedOption === 4 || selectedOption === 8 ? <Text className="fs-12" type="danger">* </Text> : null }
                  <Text className="fs-12" type="secondary">Expiration Date</Text>
                  <Form.Item 
                    // label="Expiration Date" 
                    name="expirationDate"
                    rules={[
                      { required: selectedOption === 8, message: 'This is a required field' },
                    ]}
                    // validateStatus={isDate && !current ? "error" : null}
                    // help={isDate && !current ? "Your end date can't be earlier than your start date" : null}
                  >
                    {
                      current ?
                      <Text className="fs-12" type="secondary">No Expiration Date</Text>
                      :
                      <DatePicker 
                        picker="month" 
                        placeholder="YYYY-MM" 
                        style={{ width: '100%' }}
                        onChange={onChangeEndDate} 
                        disabledDate={startDate ? disabledDatePassport : null}
                      />
                    }
                  </Form.Item>
                </Col>
            }
            {
              selectedOption === 29 ?
              <Col className='mb-32' span={24}>
                <InfoPhotoVisa />
              </Col>
              : null
            }

            {
              selectedOption === 8 ?
                <Col className='mb-32' span={24}>
                  <InfoPaspor />
                </Col>
              : null
            } 

            {
              selectedOption === 4 ?
                <Col className='mb-32' span={24}>
                  <InfoKTP />
                </Col>
              : null
            }
            {
              selectedOption === 1 ?
                <Col className='mb-32' span={24}>
                  <InfoKk />
                </Col>
              : null
            }
            <Col span={24} style={{paddingTop: 10}}>
              <Form.Item 
                name="documentFile"
                rules={[
                  { required: true, message: 'This is a required field' },
                ]}
              >
                <Space>
                  {uploadDocument}
                  <Tooltip placement="right" title="Max. File 3 MB">
                    <InfoCircleOutlined className='primary-color' />
                  </Tooltip>
                </Space>
              </Form.Item>
            </Col>
              
            
            {
              selectedOption === 29 || selectedOption === 8 || selectedOption === 1 || selectedOption === 4 ?
              null
            : 
              <Col span={24}>
                <Form.Item 
                  label="Remarks"
                  name="remark"
                  validateFirst
                  rules={[
                    { max: 200, message: '200 characters only' },
                  ]}
                >
                  <Input.TextArea rows={3} />
                </Form.Item>
              </Col>
            }
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}
