import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { columns } from './columns';
import { connect } from 'react-redux';
import { SearchDetailJob, FilterSiderDetailJob } from '../../../../../../components';
import { detailCandidate, unmountDetailCandidate } from '../../../../../../redux/actions/candidate/candidateAction';
import { Row, Col, Card, Typography, Modal, Table, Pagination, Space, Button } from 'antd';
import { listInjectCandidate, nextPhase, unmountListInjectCandidate } from '../../../../../../redux/actions/jobs/jobsAction';
import ViewProfile from '../../../../../../components/Modal/ApplicantProfile';
import ModalInjectCandidate from './Form'

const { Text } = Typography;

class DetailInjectCandidatesEmployerJobs extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      url: 'job_employers',
      checked: true,
      visible: false,
      selectedRowKeys: [],
      idApplicant: null,
      meta: {
        page: 1,
        perpage: 10,
        id: '',
        sort: '',
        field: '',
        search: '',
        minAge: '',
        maxAge: '',
        gender: ''
      }
    }
    this.onSearch = debounce(this.onSearch.bind(this), 900)
  }

  componentDidMount() {
    const { meta } = this.state;
    const { match: { params } } = this.props;
    meta.id = Number(params.id)
  }
  
  // modalNextStage = (id) => {
  //   const self = this;
  //   const { meta, url, selectedRowKeys } = this.state;
  //   const { actionNextPhase, actionListInjectCandidate } = this.props;
  //   const idApplicant = selectedRowKeys.length > 0 ? selectedRowKeys.join() : id
  //   confirm({
  //     title: 'The application will be processed to the next stage.',
  //     content: 'Are you sure?',
  //     okText: 'Yes',
  //     cancelText: 'No',
  //     onOk() {
  //       return actionNextPhase(idApplicant, url, () => {
  //         message.success('Successfully moved')
  //         actionListInjectCandidate(meta, url)
  //         self.setState({ selectedRowKeys: [] })
  //       }, (err) => message.error(err))
  //     },
  //   });
  // }

  onSearch = value => {
    const { meta, url } = this.state;
    const { actionListInjectCandidate } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.search = value === '' ? null : value
    return actionListInjectCandidate(meta, url)
  }

  onFilter = value => {
    const { meta, url } = this.state;
    const { actionListInjectCandidate } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.gender = value.gender
    meta.maxAge = value.maxAge
    meta.minAge = value.minAge
    return actionListInjectCandidate(meta, url)
  }

  pagination = (page, perpage) => {
    const { meta, url } = this.state;
    const { actionListInjectCandidate } = this.props;
    meta.page = page
    meta.perpage = perpage
    return actionListInjectCandidate(meta, url)
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };
  
  onPriviewProfile = (id) => {
    const { actionGetProfile, getInjectCandidate } = this.props;
    const findCandidate = getInjectCandidate.data.find(item => item.candidateId === id)
    this.setState({
      documentName: `${findCandidate.applicantNumber}-${findCandidate.name}`
    })
    return actionGetProfile(id, () => {
      this.setState({ visibleProfile: true })
    })
  }

  handleCancel = () => {
    this.setState({ visibleProfile: false })
    const { unmountGetProfile } = this.props;
    unmountGetProfile()
  };

  render() {
    const { selectedRowKeys, visibleProfile } = this.state;
    const { getInjectCandidate } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;
    
    return (
      <React.Fragment>
        <Row gutter={[16, 8]}>
          {/* Content */}
          <Col span={24}>
            <Card title="Inject Candidates">
              <Row gutter={[16, 24]}>
                <Col span={20}>
                  <SearchDetailJob onSearch={this.onSearch} />
                </Col>
                <Col span={4}>
                  {/* MODAL INJECT CANDIDATE */}
                  <ModalInjectCandidate {...this.props} />
                  {/* END MODAL INJECT CANDIDATE */}
                </Col>
                {
                  hasSelected ?
                  <React.Fragment>
                    <Col span={24}>
                      <Space direction="vertical">
                        <Text>Selected applicants count: <Text strong>{selectedRowKeys.length}</Text> </Text>
                        {/* <Space>
                          <Text>Move To:</Text>
                          <Button onClick={this.showModal}>Rejected</Button>
                          <Button type="primary" onClick={this.modalNextStage}>Qualified</Button>
                        </Space> */}
                      </Space>
                    </Col>
                  </React.Fragment>
                  :
                  null
                }
                {/* Filter */}
                <Col span={6}>
                  <FilterSiderDetailJob onFilter={this.onFilter} />
                </Col>
                {/* Table */}
                <Col span={18}>
                  <Table 
                    size="small"
                    style={{ marginBottom: 20 }}
                    rowKey={(i) => i.id} 
                    dataSource={getInjectCandidate.data} 
                    loading={getInjectCandidate.loading} 
                    columns={columns(this.onPriviewProfile)} 
                    pagination={false}
                    scroll={{ x: 900 }}
                    rowSelection={rowSelection}
                  />
                  {/* Pagination */}
                  <Pagination
                    total={getInjectCandidate.pagination.total}
                    onChange={this.pagination}
                    current={getInjectCandidate.pagination.page}
                    pageSize={getInjectCandidate.pagination.perpage}
                    showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total} Data`}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal
          centered
          width={1000}
          visible={visibleProfile}
          title="Applicant Profile"
          onCancel={this.handleCancel}
          footer={
            <Button key="back" type="primary" onClick={this.handleCancel}>Okay</Button>
          }
        >
          <div style={{ height: 600, overflowY: 'auto', overflowX: 'hidden' }}>
            <ViewProfile {...this.props} />
          </div>
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountListInjectCandidate, unmountGetProfile } = this.props;
    unmountListInjectCandidate()
    unmountGetProfile()
  }
}

const mapStateToProps = (state) => ({
  getInjectCandidate  : state.jobs.listInjectCandidate,
  getProfile          : state.candidate.detail
})

const mapDispatchToProps = {
  actionNextPhase             : nextPhase,
  actionGetProfile            : detailCandidate,
  actionListInjectCandidate   : listInjectCandidate,
  unmountListInjectCandidate  : unmountListInjectCandidate,
  unmountGetProfile           : unmountDetailCandidate
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailInjectCandidatesEmployerJobs)
