import React, { Component } from 'react';
import SiderMenu from './SiderMenu';
import HeaderMenu from './HeaderMenu';
import FooterMenu from './FooterMenu';
import ContentMenu from './ContentMenu';
import ReactGA from "react-ga";
import { Layout, Modal } from 'antd';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { checkAuth, checkToken, setLogout } from '../../redux/actions/auth/authAction';
import { countNotification, listNotification, readNotification, unmountListNotification, unmountCountNotification } from '../../redux/actions/notification/notificationAction';
const { confirm } = Modal;

if(process.env.REACT_APP_ENV === "production"){
  ReactGA.initialize("G-YQK491RBH8");
}

class Main extends Component {
  constructor(props) {
    super(props)

    this.state = {
      _newMenu: null,
      meta: {
        page: 1,
        perpage: 100,
      }
    }
  }

  componentDidMount() {
    const { meta } = this.state;
    const { 
      permission, 
      actionCheckToken, 
      actionGetNotification, 
      actionGetCountNotification 
    } = this.props;
    actionCheckToken(() => {
      actionGetCountNotification()
      actionGetNotification(meta)
    })
    let array = [];
    permission.forEach(res => {
      if (res.show) {
        let hh = {
          key: res.path.replace('/', ''),
          title: res.title,
          url: res.url,
          titleGA: res.titleGA
        }
        array.push(hh);
      }
    });
    this.setState({ _newMenu: array })
    if(process.env.REACT_APP_ENV === "production"){
      ReactGA.pageview(this.props.url, [], this.props.titleGA);
    }
  }

  setLogout = () => {
    const { actionSetLogout } = this.props;
    confirm({
      title: 'Are you sure you want to logout?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        return new Promise((resolve, reject) => {
          actionSetLogout(() => { resolve() }, () => reject())
        });
      }
    });
  }

  render() {
    const { collapsed, private: privatePage } = this.props;
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Helmet>
          <title>Jobshub - {this.props.titleGA}</title>
        </Helmet>
        {
          privatePage &&
          <React.Fragment>
            <SiderMenu {...this.props} />
            <Layout className="site-layout" style={{ marginLeft: collapsed ? 80 : 200 }}>
              <HeaderMenu {...this.props} setLogout={this.setLogout} />
              <ContentMenu {...this.props} />
              <FooterMenu />
            </Layout>
          </React.Fragment>
        }
      </Layout>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    permission            : state.authReducer.permission,
    dataUser              : state.authReducer,
    dataNotification      : state.notification.list,
    dataCounNotification  : state.notification.count
  }
}

const mapActionsToProps = {
  actionCheckToken            : checkToken,
  actioncheckAuth             : checkAuth,
  actionSetLogout             : setLogout,
  actionGetNotification       : listNotification,
  actionGetCountNotification  : countNotification,
  actionReadNotification      : readNotification,
  unmountListNotification     : unmountListNotification,
  unmountCountNotification    : unmountCountNotification
}
export default connect(mapStateToProps, mapActionsToProps)(Main)
