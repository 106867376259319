import React from 'react'
import { Row, Col, Typography, Descriptions, Divider } from 'antd'

const { Text } = Typography

export default function Achievement(props) {
  const { data } = props
  return (
    <Row gutter={16}>
      <Col span={24}>
        <Descriptions title="Achievements" />
        {
          data.listAchievement.length > 0 ?
            <React.Fragment>
              {
                data.listAchievement.map((item, i) => (
                  <React.Fragment key={i}>
                    <div style={{ marginBottom: 11 }}>
                      <Text style={{ color: '#32ab6d', fontSize: 14 }}>
                        {item.year ? item.year : 'N/A'}
                      </Text>
                    </div>
                    <Descriptions layout="vertical" size="small" column={3}>
                      <Descriptions.Item label="Name">
                        {item.name}
                      </Descriptions.Item>
                      <Descriptions.Item label="Issuing Organization">
                        {item.organization}
                      </Descriptions.Item>
                    </Descriptions>
                    <Divider />
                  </React.Fragment>
                ))
              }
            </React.Fragment>
          :
            <React.Fragment>
              <Text>N/A</Text>
              <Divider />
            </React.Fragment>
        }
      </Col>
    </Row>
  )
}
