import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading } from '../../../../components';
import { InfoCircleOutlined, DatabaseOutlined } from '@ant-design/icons';
import { createBankAccount } from '../../../../redux/actions/bankAccount/bankAccountAction';
import { masterBank, unmountMasterBank } from '../../../../redux/actions/master/bank/bankAction';
import { Col, Form, Row, Input, Button, Select, PageHeader, message, Card, Breadcrumb } from 'antd';
// import { masterCountries, unmountMasterCountries } from '../../../../redux/actions/master/countries/countriesAction';

class CreateBankAccount extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false
    }
  }

  componentDidMount() {
    const { actionGetBank } = this.props;
    actionGetBank()
  }

  onFinish = (values) => {
    const { actionCreate, history } = this.props;
    this.setState({ submitLoading: true })
    actionCreate(values, () => {
      this.setState({ submitLoading: false }, () => {
        message.success('Data created successfully')
        history.push('/master-data')
      })
    }, (err) => {
      message.error(err.message)
      this.setState({ submitLoading: false })
    })
  }

  render() {
    const { submitLoading } = this.state;
    const { getBankAccount: { loading, data } } = this.props;

    if(loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Form onFinish={this.onFinish} ref={this.formRef} layout="vertical">
          <Row style={{ position: 'sticky', zIndex: 1, width: '100%', top: 62, right: 0, marginTop: -20, marginBottom: 8, paddingBottom: 12, paddingTop: 16, backgroundColor: '#f2f2f2' }}>
            {/* Breadcrumb */}
            <Col span={24}>
              <Breadcrumb>
                <Breadcrumb.Item><DatabaseOutlined /> Master Data</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link className="link" to="/master-data">
                    Master Data Bank Account
                  </Link> 
                </Breadcrumb.Item>
                <Breadcrumb.Item>Bank Account</Breadcrumb.Item>
                <Breadcrumb.Item>Create</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            { /* Page Header */}
            <Col span={24}>
              <PageHeader
                className="site-page-header"
                title='Create a Bank Account'
                extra={[
                  <Button key="2" className="btn-save-primary" type="default" onClick={() => window.history.back()}>Cancel</Button>,
                  <Button key="1" className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading}>Create</Button>
                ]}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            { /* Form */}
            <Col span={24}>
              <Card>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item 
                      label="Account Name" 
                      name="name" 
                      validateFirst
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 3, message: 'At least 3 characters ' },
                        { max: 200, message: '200 characters only' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Bank" 
                      name="bankId" 
                      validateFirst
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        // onChange={this.handleChangeCountry}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          data?.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                        </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Account Number" 
                      name="number" 
                      validateFirst
                      rules={[
                        { pattern: /^[0-9]*$/, message: 'Number are not allowed' },
                        { required: true, message: 'This is a required field' },
                        { min: 10, message: 'At least 10 digits' },
                        { max: 20, message: 'C20 digits only' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Status" 
                      name="enabled" 
                      initialValue={true}
                      tooltip={{ 
                        title: 'Inactive means this city isn’t used in master data', 
                        icon: <InfoCircleOutlined style={{ color: '#2586eb' }} />
                      }}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select>
                        <Select.Option value={true}>Active</Select.Option>
                        <Select.Option value={false}>Inactive</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetBank } = this.props;
    return unmountGetBank()
  }
}

const mapStateToProps = (state) => ({
  getBankAccount: state.bank.master
})

const mapDispatchToProps = {
  actionCreate      : createBankAccount,
  actionGetBank  : masterBank,
  unmountGetBank : unmountMasterBank,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateBankAccount)