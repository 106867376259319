import React, { Component } from 'react';
import { Form, Row, Col, Input, DatePicker } from 'antd';
import moment from 'moment';
// const { Text } = Typography;

export default class EditResultTest extends Component {
  render() {
    const { 
      dataCandidateResultTest,
      formRef, 
      onSubmitEdit, 
    } = this.props;


    return (
      <React.Fragment>
        <Form ref={formRef} onFinish={onSubmitEdit} layout="vertical">
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item 
                label="Test Category Name"
                name="name"
                validateFirst
                initialValue={dataCandidateResultTest.name}
                rules={[
                  { max: 200, message: '200 characters only' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item 
                label="score"
                name="score"
                validateFirst
                initialValue={dataCandidateResultTest.score}
                rules={[
                  { max: 200, message: '200 characters only' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item 
              label="Date" 
              name="testDate" 
              initialValue={dataCandidateResultTest.testDate ? moment(dataCandidateResultTest.testDate, 'DD/MM/YYYY') : null}
              >
                <DatePicker 
                  format="DD/MM/YYYY" 
                  placeholder="DD/MM/YYYY" 
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}
