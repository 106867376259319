import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import svg from '../../assets/img/EmptyNotification.svg';
import { List, Badge, Image, Row, Col, Typography } from 'antd';
const { Text } = Typography;

export default class Notification extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      loading: false
    }
  }

  handleRedirect = (data) => {
    const { dataUser: { role }, actionReadNotification } = this.props;
    this.setState({ loading: true })
    if(data.type === "NEW_REQUEST"){
      return window.location = `/jobs/detail/${data.jobId}`;
    }
    if(data.type === "UPDATE_JOB"){
      return window.location = `/jobs/detail/${data.jobId}`;
    }
    if(data.status === "NEW"){
      return actionReadNotification(data.id, () => {
        switch (role) {
          case "ROLE_EMPLOYERS":
            if(data.type === "APPROVED"){
              return window.location = `/employer/jobs/detail/${data.jobId}/information`;
            }
            if(data.type === "REJECTED"){
              return window.location = `/employer/jobs/detail/${data.jobId}/information`;
            }
            if(data.type === "INACTIVE"){
              return window.location = `/employer/jobs/detail/${data.jobId}/information`;
            }
            if(data.type === "NEW_APPLICANT"){
              return window.location = `/employer/jobs/detail/${data.jobId}/active-applicants/PT00`;
            }
            if(data.type === "CONFIRMED_INTERVIEW"){
              return window.location = `/employer/jobs/detail/${data.jobId}/active-applicants/PT02/2/${data.jobAppliedId}`;
            }
            if(data.type === "REJECT_INTERVIEW"){
              return window.location = `/employer/jobs/detail/${data.jobId}/rejected-applicants`;
            }
            if(data.type === "RESCHEDULE_INTERVIEW"){
              return window.location = `/employer/jobs/detail/${data.jobId}/active-applicants/PT02/2/${data.jobAppliedId}`;
            }
            break;
          case "ROLE_AGENCY":
              if(data.type === "APPROVED"){
                return window.location = `/agency/jobs/detail/${data.jobId}/information`;
              }
              if(data.type === "REJECTED"){
                return window.location = `/agency/jobs/detail/${data.jobId}/information`;
              }
              if(data.type === "INACTIVE"){
                return window.location = `/agency/jobs/detail/${data.jobId}/information`;
              }
              if(data.type === "NEW_APPLICANT"){
                return window.location = `/agency/jobs/detail/${data.jobId}/active-applicants/PT00`;
              }
              if(data.type === "CONFIRMED_INTERVIEW"){
                return window.location = `/agency/jobs/detail/${data.jobId}/active-applicants/PT02/2/${data.jobAppliedId}`;
              }
              if(data.type === "REJECT_INTERVIEW"){
                return window.location = `/agency/jobs/detail/${data.jobId}/rejected-applicants`;
              }
              if(data.type === "RESCHEDULE_INTERVIEW"){
                return window.location = `/agency/jobs/detail/${data.jobId}/active-applicants/PT02/2/${data.jobAppliedId}`;
              }
              break;
            case "ROLE_ADMIN_JOBSHUB":
              if(data.type === "INACTIVE"){
                return window.location = `/jobs/detail/${data.jobId}`;
              }
              if(data.type === "NEW_JOB"){
                return window.location = `/jobs/detail/${data.jobId}`;
              }
              break;
          default:
            return null;
        }
      })
    }else{
      switch (role) {
        case "ROLE_EMPLOYERS":
          if(data.type === "APPROVED"){
            return window.location = `/employer/jobs/detail/${data.jobId}/information`;
          }
          if(data.type === "REJECTED"){
            return window.location = `/employer/jobs/detail/${data.jobId}/information`;
          }
          if(data.type === "INACTIVE"){
            return window.location = `/employer/jobs/detail/${data.jobId}/information`;
          }
          if(data.type === "NEW_APPLICANT"){
            return window.location = `/employer/jobs/detail/${data.jobId}/active-applicants/PT00`;
          }
          if(data.type === "CONFIRMED_INTERVIEW"){
            return window.location = `/employer/jobs/detail/${data.jobId}/active-applicants/PT02/2/${data.jobAppliedId}`;
          }
          if(data.type === "REJECT_INTERVIEW"){
            return window.location = `/employer/jobs/detail/${data.jobId}/rejected-applicants`;
          }
          if(data.type === "RESCHEDULE_INTERVIEW"){
            return window.location = `/employer/jobs/detail/${data.jobId}/active-applicants/PT02/2/${data.jobAppliedId}`;
          }
          break;
        case "ROLE_AGENCY":
            if(data.type === "APPROVED"){
              return window.location = `/agency/jobs/detail/${data.jobId}/information`;
            }
            if(data.type === "REJECTED"){
              return window.location = `/agency/jobs/detail/${data.jobId}/information`;
            }
            if(data.type === "INACTIVE"){
              return window.location = `/agency/jobs/detail/${data.jobId}/information`;
            }
            if(data.type === "NEW_APPLICANT"){
              return window.location = `/agency/jobs/detail/${data.jobId}/active-applicants/PT00`;
            }
            if(data.type === "CONFIRMED_INTERVIEW"){
              return window.location = `/agency/jobs/detail/${data.jobId}/active-applicants/PT02/2/${data.jobAppliedId}`;
            }
            if(data.type === "REJECT_INTERVIEW"){
              return window.location = `/agency/jobs/detail/${data.jobId}/rejected-applicants`;
            }
            if(data.type === "RESCHEDULE_INTERVIEW"){
              return window.location = `/agency/jobs/detail/${data.jobId}/active-applicants/PT02/2/${data.jobAppliedId}`;
            }
            break;
          case "ROLE_ADMIN_JOBSHUB":
            if(data.type === "INACTIVE"){
              return window.location = `/jobs/detail/${data.jobId}`;
            }
            if(data.type === "NEW_JOB"){
              return window.location = `/jobs/detail/${data.jobId}`;
            }
            break;
        default:
          return null;
      }
    }
  }

  handleInfiniteOnLoad = () => {
    ('scroll');
  };

  render() {
    const { loading } = this.state;
    const { dataNotification: { data } } = this.props;
    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {
            data.length > 0 ?
            <React.Fragment>
              <Col span={24}>
                <div className="demo-infinite-notifcation">
                  <InfiniteScroll hasMore={false} loadMore={this.handleInfiniteOnLoad}>
                    <List
                      dataSource={data}
                      loading={loading}
                      renderItem={item => (
                        <List.Item 
                          actions={[item.status === "NEW" ? <Badge color="green" /> : null]}
                          style={{ cursor: 'pointer' }}
                        >
                          <List.Item.Meta
                            onClick={() => this.handleRedirect(item)}
                            title={<Text strong={item.status === "NEW" ? true : false}>{item.title}</Text>}
                            description={item.createdDate}
                          />
                        </List.Item>
                      )}
                    />
                  </InfiniteScroll>
                </div>
              </Col>
            </React.Fragment>
            :
            <React.Fragment>
              <Col span={24} style={{ textAlign: 'center' }}>
                <Image src={svg} preview={false} />
              </Col>
              <Col span={24} style={{ textAlign: 'center' }}>
                <Text>You have no notifications</Text>
              </Col>
            </React.Fragment>
          }
        </Row>
      </React.Fragment>
    )
  }
}
