import { API } from '../../../config';
import { errorHandler } from '../auth/errorAction';

export const unmountListAssignedCandidate = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_ASSIGNED_CANDIDATE'})
}

export const listAssignedCandidate = (meta) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_ASSIGNED_CANDIDATE' })
  return API.GET(`/candidate/assign_job/list?page=1&perpage=20`, meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_ASSIGNED_CANDIDATE_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_ASSIGNED_CANDIDATE_FAILED' }), 
    ))
  })
}

export const createNotes = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS(`/candidate/notes/add`, value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const exportData = (meta, successCB, failedCB) => () => {
  return API.GET_DOWNLOAD(`/candidate/assign_job/report/print`, meta).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const reassignToJobByFilter = (body, params, successCB, failedCB) => () => {
  API.POST_BODY_PARAMS('/candidate/assign_job/reassign_filter', body, params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}
