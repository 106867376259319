import { API } from '../../../config';
import { errorHandler } from '../auth/errorAction';

export const unmountListNotification = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_NOTIFICATION'})
}

export const unmountCountNotification = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_COUNT_NOTIFICATION'})
}

export const listNotification = (meta, successCB, failedCB) => async dispatch => {
  API.GET(`/notification/list`, meta).then((response) => {
    dispatch({ 
      type: 'LOAD_DATA_NOTIFICATION_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DATA_NOTIFICATION_FAILED' }), 
      ))
    )
  })
}

export const countNotification = (successCB, failedCB) => async dispatch => {
  API.GET(`/notification/count`).then((response) => {
    dispatch({ 
      type: 'LOAD_DATA_COUNT_NOTIFICATION_SUCCESS', 
      payload : { 
        data: response.count 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DATA_COUNT_NOTIFICATION_FAILED' }), 
      ))
    )
  })
}

export const readNotification = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/notification/read', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

