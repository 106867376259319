import React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { Button, Form } from 'antd';

export default function ApplicationRejection(props) {
  const { notes, onFinishReject, submitLoading } = props
  return (
    <Form
      layout="vertical"
      onFinish={onFinishReject}
    >
      <Form.Item
        label="Reason (internal notes)"
        name={notes}
        initialValue={null}
        rules={[{ required: true, message: 'Please input your reject notes!' }]}
      >
        <ReactQuill />
      </Form.Item>

      <Form.Item>
        <Button type="danger" htmlType="submit" loading={submitLoading} style={{ float: 'right' }}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}
