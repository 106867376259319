import moment from 'moment';
import { BankOutlined, UploadOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Card, Col, Form, Input, message, Divider, Modal, Typography, PageHeader, Row, Select, Upload, Descriptions } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';
import { Loading } from '../.././../../../components';
import { approveCandidatePathway, detailCandidatePathway, editCandidatePathway, rejectCandidatePathway } from '../../../../../redux/actions/candidatePathway/candidatePathwayAction';
import { checkEmail, checkPhone } from '../../../../../redux/actions/check/checkAction';
import { masterEmployersInquiries, unmountDetailEmployersInquiries, unmountMasterEmployersInquiries } from '../../../../../redux/actions/employersInquiries/employersInquiriesAction';
import { cityByProvince } from '../../../../../redux/actions/master/cities/citiesAction';
import { masterCountries, unmountMasterCountries } from '../../../../../redux/actions/master/countries/countriesAction';
import { masterJobIndustries, unmountMasterJobIndustries } from '../../../../../redux/actions/master/jobIndustries/jobIndustriesAction';
import { provinceByCountry } from '../../../../../redux/actions/master/provinces/provincesAction';
import ViewProfile from '../../../../../components/Modal/ApplicantProfile';
import { detailCandidate, unmountDetailCandidate } from '../../../../../redux/actions/candidate/candidateAction';
import { pathwayDescription } from '../../../../../utils/constant/pathwayProgram';
const { Text } = Typography;

class EditCandidatePathway extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
    
    this.state = {
      submitLoading: false,
      saveLoading: false,
      avatar: null,
      editAvatar: false,
      startDate: moment(),
      onPreviewAvatar: null,
      callingCode: "62",
      dataProvince: [],
      dataCity: [],
      visible: false,
      statusSubmit: null,
      isFixSalary: true,
      candidateForm: [],
      loadingAdd: false
    }
  }

  componentDidMount() {
    const { 
      actionGetDetail, 
      match: { params }
    } = this.props;
    actionGetDetail(params.id, (response) => {
      const { data } = response
      if(data.listPathwayForm.length > 0){
        data.listPathwayForm.map(item => {
          item.idEdit = true
          return item
        })
        this.setState({ 
          candidateForm: [...data.listPathwayForm]
        })
      }
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.candidateForm.length !== this.state.candidateForm.length){
      this.setState({
        loadingAdd: true
      }, () => {
        this.setState({ loadingAdd: false })
      })
    }
  }
  

  checkEmail = (value) => {
    const { actionCheckEmail, getData: { data } } = this.props;
    const oldEmail = data.email
    if(oldEmail === value){
      return null
    }else{
      return actionCheckEmail(value, (response) => {
        if(response){
          message.error('Email already exist')
          this.formRef.current.setFieldsValue({
            email: data.email,
          });
        }
      })
    }
  }

  checkPhone = (value) => {
    const { callingCode } = this.state;
    const { actionCheckPhone, getData: { data } } = this.props;
    const valuePhone = `${callingCode}${value.replace(/^0+/, '')}`
    const oldMobilePhone = `${data.callingCode}${data.mobilePhone}`
    this.formRef.current.setFieldsValue({
      mobilePhone: value.replace(/^0+/, ''),
    });
    if(oldMobilePhone === valuePhone){
      return null
    }else{
      return actionCheckPhone(valuePhone.replace(/^0+/, ''), (response) => {
        if(response){
          message.error('Mobile phone already exist')
          this.formRef.current.setFieldsValue({
            mobilePhone: data.mobilePhone,
          });
        }
      })
    }
  }

  handleChangeFixSalary = (value) => {
    this.setState({ isFixSalary: value })
    this.formRef.current.setFieldsValue({
      salary: null,
      maxSalary: null
    });
  }

  handleChangeCallingCode = (_, index) => {
    this.setState({ callingCode: index.children.replace(/^[+]+/, '') })
    this.formRef.current.setFieldsValue({
      mobilePhone: null
    });
  }
  
  handleChangeCountry = (value) => {
    const { actionGetProvince } = this.props;
    this.formRef.current.setFieldsValue({
      provinceId: null,
      cityId: null,
    });
    return actionGetProvince(value, (response) => {
      this.setState({ dataProvince: response.data })
    })
  }

  handleChangeProvince = (value) => {
    const { actionGetCity } = this.props;
    this.formRef.current.setFieldsValue({
      cityId: null
    });
    if(value !== undefined){
      return actionGetCity(value, (response) => {
        this.setState({ dataCity: response.data })
      })
    }else {
      this.setState({ dataCity: [] })
    }
  }

  handleClick = (type) => {
    this.setState({ statusSubmit: type })
  }

  disabledDate = (current) => {
    let yesterday = moment().subtract(1, 'days');
    return !current || current.isBefore(yesterday);
  }

  disabledEndDate = (current) => {
    const { startDate } = this.state;
    return current < startDate
  }

  onChangeStartDate = (current) => {
    this.formRef.current.setFieldsValue({
      targetDate: null,
    });
    this.setState({ startDate: current })
  }

  onFinish = (values) => {
    const { statusSubmit, candidateForm } = this.state;
    const { actionUpdate, history, getData: { data } } = this.props;
    let objId = {
      id: data.id,
      isId: true,
      status: null,
      isStatus: true
    }
    let typeSubmit = null
    if(candidateForm.length > 0 && candidateForm[0]['id']){
      typeSubmit = 'edit'
    }else{
      typeSubmit = 'add'
    }

    if(statusSubmit === 'SUBMIT'){
      objId.status = statusSubmit
    }

    candidateForm.push(objId)

    return this.setState({ submitLoading: true }, () => {
      return actionUpdate(candidateForm, typeSubmit, (response) => {
        if(response.code === '1000'){
          this.setState({ submitLoading: false }, () => {
            message.success('Data updated successfully')
            history.push('/candidate-pathway')
          })
        }else{
          this.setState({ submitLoading: false }, () => {
            message.error(response.message)
          })
        }
      }, (err) => {
        this.setState({ submitLoading: false }, () => message.error(err))
      })
    })
  }

  handleAddPathway = () => {
    const { candidateForm } = this.state
    const {  getData: { data } } = this.props
    this.setState({ loadingAdd: true }, () => {
      const pathway = {
        program: null,
        title: null,
        note: null,
        attachmentFile: null,
        idForm: uuidv4(),
        candidatePathwayId: data.id
      }
      this.setState({
        candidateForm: [...candidateForm, pathway],
        loadingAdd: false
      })
    })
  }

  handleReject = () => {
    this.setState({ visible: true })
  }

  handleCancel = () => {
    this.setState({ visible: false })
  }

  handleOk = () => {
    const { actionReject, history, getData: { data } } = this.props;
    this.formRef.current.validateFields().then(values => {
      values.id = data.id
      return actionReject(values, () => {
        message.success('Candidate Pathway has been rejected')
        this.setState({ visible: false }, () => {
          history.push('/candidate-pathway')
        })
      }, (err) => {
        return message.error(err)
      })
    })
  }

  handleUpload(index){
    const { candidateForm } = this.state
    return {
      withCredentials: true,
      accept:".jpg, .jpeg, .pdf, .png",
      beforeUpload: file => {
        const validateSize = file.size >= 500000;
        if (validateSize) {
          message.error('Max file size is 500 KB!');
          return false
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          candidateForm[index]['attachmentFile'] = file
          candidateForm[index]['attachmentUrl'] = e.target.result
          candidateForm.forEach((item, _index) => {
            if(_index === index){
              delete item.attachment
            }
          })
          this.setState({ 
            avatar: file, 
            editAvatar: true,
            onPreviewAvatar: e.target.result,
            candidateForm
          })
        }
        return false;
      },
    }
  }

  handleDeleteFile = () => {
    this.setState({
      avatar: null,
      editAvatar: false,
      onPreviewAvatar: null
    })
  }

  onPriviewProfile = (id) => {
    const { actionGetProfile } = this.props;
    return actionGetProfile(id, () => {
      this.setState({ visible: true })
    })
  }

  handleForm = (data, name, index) => {
    const { candidateForm } = this.state
    candidateForm[index][name] = data
    this.setState({ candidateForm })
  }

  handleDeleteForm = (idForm) => {
    const { candidateForm } = this.state
    this.setState({ loadingAdd: true }, () => {
      const newForm = candidateForm.filter(item => {
        if(item.idForm){
          return item.idForm !== idForm
        }else{
          return item.id !== idForm
        }
      })
      this.setState((state) => ({
        ...state,
        candidateForm: newForm,
        loadingAdd: false
      }))
    })
  }
  
  render() {
    const { submitLoading, saveLoading, visible, loadingAdd, candidateForm } = this.state;
    const { getData: { data, loading } } = this.props;

    if(loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Form layout="vertical" ref={this.formRef} onFinish={this.onFinish} scrollToFirstError={true}>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><BankOutlined /> Pathway</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/candidate-pathway">Candidates Pathway List</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{data?.candidatePathwayNumber || '-'}</Breadcrumb.Item>
              <Breadcrumb.Item>Create</Breadcrumb.Item>
            </Breadcrumb>
          </Col>

          {/* Page Header */}
          <Col span={24}>
            <PageHeader 
              title="Create Candidate Pathway" 
              className="site-page-header"
              extra={[
                <Button key="2" className="btn-edit-candidate" htmlType="submit" loading={saveLoading} onClick={() => this.handleClick('FOLLOW_UP')} >Save</Button>,
                <Button key="1" style={{ width: 'auto' }} className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading} onClick={() => this.handleClick('SUBMIT')}>Send</Button>,
              ]}
            />
          </Col>

          {
            candidateForm.map((item, index) => {
              return(!loadingAdd &&
              <Col span={24} key={item.idForm || item.id}>
                <Card>
                  <Row gutter={16}>
                    <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button className="btn-cancel-danger" onClick={() => this.handleDeleteForm(item.idForm || item.id)}>Remove</Button>
                    </Col>

                    <Col span={24}>
                      <Form.Item 
                        fieldKey={item.idForm || item.id}
                        label="Program" 
                        name={`program${item.idForm || item.id}`}
                        initialValue={item.program}
                        rules={[
                          { required: true, message: 'This is a required field' },
                        ]}
                      >
                        <Select placeholder='Select a type' onChange={(value) => this.handleForm(value, 'program', index)}>
                          <Select.Option value="STUDY">Study Programs</Select.Option>
                          <Select.Option value="BOOTCAMP">Bootcamp Intensive Programs</Select.Option>
                          <Select.Option value="NIGHT_CLASS">Night Class Programs</Select.Option>
                          <Select.Option value="INTERNSHIP">Internship Programs</Select.Option>
                          <Select.Option value="READY_WORK">Getting Ready for work</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Text style={{ paddingBottom: 8 }}>
                        Program Description
                      </Text>
                    </Col>

                    <Col span={24} style={{ marginBottom: 20 }}>
                      <Text style={{ paddingBottom: 8 }}>
                        {pathwayDescription.find(res => res.value === item.program)?.name || 'N/A'}
                      </Text>
                    </Col>

                    <Col span={24}>
                      <Form.Item 
                        label="Title" 
                        name={`title${item.idForm || item.id}`}
                        initialValue={item.title}
                        rules={[
                          { required: true, message: 'This is a required field' },
                          { max: 100, message: '100 characters only' },              
                        ]}
                      >
                        <Input onChange={(value) => this.handleForm(value.target.value, 'title', index)} />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item 
                        label="Notes to Candidate" 
                        name={`note${item.idForm || item.id}`}
                        initialValue={item.note}
                        rules={[
                          // { required: true, message: 'This is a required field' },
                          { max: 1000, message: '1000 characters only' },              
                        ]}
                      >
                        <Input.TextArea rows={3} onChange={(value) => this.handleForm(value.target.value, 'note', index)} />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item label="Attachment File">
                        <Upload 
                          {...this.handleUpload(index)} 
                          listType="picture" 
                          maxCount={1}
                          defaultFileList={item.attachmentUrl ? [{url: item.attachmentUrl, name: item.attachment || item.attachmentFile.name}] : null}
                        >
                          <Button style={{ width: '100%' }} className="btn-radius" icon={<UploadOutlined />}>{item.attachmentFile || item.attachmentUrl ? 'Change File' : 'Upload a File'}</Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
              )
            })
          }

        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24} style={{ justifyContent: 'center', display: 'flex', marginTop: 20, marginBottom: 20 }}>
            <Button style={{ width: '90%' }} className="btn-color-blue" type="primary" loading={loadingAdd} onClick={() => this.handleAddPathway()}>+ Tambah Path</Button>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Card>
              <Row gutter={[16, 16]}>
                <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Text style={{ fontSize: 16 }}>Assessment Result</Text>
                  <Button className="btn-color-blue" type="primary" onClick={() => this.onPriviewProfile(data.candidateId)} >View Profile</Button>
                </Col>
                <Divider />
                <Col span={24}>
                  <Text style={{ fontSize: 16 }}>Biografi</Text>
                </Col>
                <Col span={24}>
                  <Descriptions layout="vertical" column={2} size="small">
                    <Descriptions.Item label={<Text type="secondary">Nama Candidate</Text>}>
                      {data.name ? data.name : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Gender</Text>}>
                      {data.gender ? data.gender : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Tipe Penempatan yang Diminati</Text>}>
                      {
                        data.placementType ?
                          <React.Fragment>
                            {data.placementType === "INTERNATIONAL" ? "International" : null}
                            {data.placementType === "BOTH" ? "Both" : null}
                            {data.placementType === "DOMESTIC" ? "Domestic" : null}
                          </React.Fragment>
                        : 'N/A'
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Bidang Karir yang Diminati</Text>}>
                      {
                        (data.listPathwayIndustry && data.listPathwayIndustry.map(item => item.jobIndustryName).join(', ')) || 'N/A'
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Posisi Pekerjaan</Text>}>
                      {
                        (data.listPathwayPosition && data.listPathwayPosition.map(item => item.name).join(', ')) || 'N/A'
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Kota dan Negara Penempatan</Text>}>
                      {
                        (data.listPathwayDestination && data.listPathwayDestination.map(item => item.jobDestinationName).join(', ')) || 'N/A'
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Alasan Pemilihan Karir</Text>}>
                      {data.reason ? data.reason : 'N/A'}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        </Form>
        <Modal 
          centered
          width={1000}
          visible={visible}
          title="Applicant Profile"
          onCancel={this.handleCancel}
          footer={
            <Button key="back" type="primary" onClick={this.handleCancel}>Okay</Button>
          }
        >
          <div style={{ height: 600, overflowY: 'auto', overflowX: 'hidden' }}>
            <ViewProfile {...this.props} />
          </div>
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail, unmountGetCountry, unmountGetEmployer, unmountGetJobFunction, unmountGetProfile } = this.props;
    unmountGetDetail()
    unmountGetCountry()
    unmountGetEmployer()
    unmountGetJobFunction()
    unmountGetProfile()
    clearInterval()
  }
}

const mapStateToProps = (state) => ({
  getData         : state.candidatePathway.detail,
  getJobFunction  : state.jobIndustries.master,
  getCountry      : state.countries.master,
  getEmployer     : state.employersInquiries.master,
  getProfile      : state.candidate.detail
})

const mapDispatchToProps = {
  actionUpdate          : editCandidatePathway,
  actionApprove         : approveCandidatePathway,
  actionGetDetail       : detailCandidatePathway,
  actionCheckPhone      : checkPhone,
  actionCheckEmail      : checkEmail,
  actionGetJobFunction  : masterJobIndustries,
  actionGetCountry      : masterCountries,
  actionGetProvince     : provinceByCountry,
  actionGetCity         : cityByProvince,
  actionGetEmployer     : masterEmployersInquiries,
  actionReject          : rejectCandidatePathway,
  unmountGetDetail      : unmountDetailEmployersInquiries,
  unmountGetCountry     : unmountMasterCountries,
  unmountGetEmployer    : unmountMasterEmployersInquiries,
  unmountGetJobFunction : unmountMasterJobIndustries,
  actionGetProfile      : detailCandidate,
  unmountGetProfile     : unmountDetailCandidate

}

export default connect(mapStateToProps, mapDispatchToProps)(EditCandidatePathway)
