import { API } from '../../../config';
import { errorHandler } from '../auth/errorAction';

export const unmountListEmployersInquiries = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_EMPLOYERS_INQUIRIES'})
}

export const unmountDetailEmployersInquiries = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_EMPLOYERS_INQUIRIES'})
}

export const unmountMasterEmployersInquiries = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_EMPLOYERS_INQUIRIES'})
}

export const listEmployersInquiries = (meta) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_EMPLOYERS_INQUIRIES' })
  return API.GET('/employers_register/list', meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_EMPLOYERS_INQUIRIES_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_EMPLOYERS_INQUIRIES_FAILED' }), 
    ))
  })
}

export const detailEmployerInquiries = (id, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DETAIL_EMPLOYERS_INQUIRIES' })
  const params = {
    'id': id
  }
  API.GET('/employers_register/getById', params).then((response) => {
    dispatch({ 
      type: 'LOAD_DETAIL_EMPLOYERS_INQUIRIES_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DETAIL_EMPLOYERS_INQUIRIES_FAILED' }), 
      ))
    )
  })
}

export const masterEmployersInquiries = (successCB, failedCB) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_EMPLOYERS_INQUIRIES' })
  return API.GET('/employers_type/index').then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_EMPLOYERS_INQUIRIES_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: null,
          total: null,
          perpage: null
        }
    }})
    return successCB && successCB(response.data)
  }).catch((err) => {
    failedCB && failedCB()
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_MASTER_EMPLOYERS_INQUIRIES_FAILED' }), 
    ))
  })
}

export const approveEmployerInquiries = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA('/employers_register/approve', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const editEmployerInquiries = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA('/employers_register/update', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const rejectEmployerInquiries = (value, successCB, failedCB) => () => {
  const params = {
    id: value.id,
    rejectReason: value.rejectReason
  }
  API.POST_WITH_PARAMS('/employers_register/reject', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}