import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading } from '../../../../../components';
import { masterCountries, unmountMasterCountries } from '../../../../../redux/actions/master/countries/countriesAction';
import { editAboutCountries, listAboutCountries, unmountListAboutCountries } from '../../../../../redux/actions/master/aboutCountry/aboutCountryAction';
import { DatabaseOutlined, UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { Col, Form, Row, Input, Button, Space, Select, PageHeader, message, Card, Breadcrumb, Upload, Image } from 'antd';

class EditMasterAboutCountry extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      avatar: null,
      editAvatar: false,
      onPreviewAvatar: null,
      
    }
  }

  componentDidMount() {
    const { actionGetCountry } = this.props;
    actionGetCountry()
  }

  handleUpload(){
    return {
      showUploadList: false,
      withCredentials: true,
      accept:"image/*",
      beforeUpload: imageFile => {
        const validateSize = imageFile.size >= 500000;
        if (validateSize) {
          message.error('Max file size is 500 KB!');
          return false
        }
        var reader = new FileReader();
        reader.readAsDataURL(imageFile);
        reader.onload = (e) => {
          this.setState({ 
            avatar: imageFile, 
            editAvatar: true,
            onPreviewAvatar: e.target.result, 
          })
        }
        return false;
      },
    }
  }

  handleDeleteFile = () => {
    this.setState({
      avatar: null,
      editAvatar: false,
      onPreviewAvatar: null
    })
  }

  onFinish = (values) => {
    const { actionUpdate, history, location: {state} } = this.props;
    if(this.state.editAvatar){
      values.imageFile = this.state.avatar
    }
    values.enabledAbout = state.enabledAbout ? state.enabledAbout : false
    this.setState({ submitLoading: true })
    return actionUpdate(values, () => {
      this.setState({ submitLoading: false }, () => {
        message.success('Data updated successfully')
        history.push('/master/about-country')
      })
    }, (err) => {
      this.setState({ submitLoading: false }, ( ) => message.error(err.message))
    })
  }

  render() {
    const { submitLoading, editAvatar, onPreviewAvatar } = this.state;
    const { getCountry, location: { state } } = this.props;

    if(getCountry.loading ){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Form onFinish={this.onFinish} layout="vertical">
          <Row style={{ position: 'sticky', zIndex: 1, width: '100%', top: 62, right: 0, marginTop: -20, marginBottom: 8, paddingBottom: 12, paddingTop: 16, backgroundColor: '#f2f2f2' }}>
            {/* Breadcrumb */}
            <Col span={24}>
              <Breadcrumb>
                <Breadcrumb.Item><DatabaseOutlined /> Master Data</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link className="link" to="/master/about-country">
                    Master Data List About Country
                  </Link> 
                </Breadcrumb.Item>
                <Breadcrumb.Item>About Country</Breadcrumb.Item>
                <Breadcrumb.Item>{state.name}</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            { /* Page Header */}
            <Col span={24}>
              <PageHeader 
                className="site-page-header" 
                title="Update About Country"
                extra={[
                  <Button key="2" className="btn-save-primary" type="default" onClick={() => window.history.back()}>Cancel</Button>,
                  <Button key="1" className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading}>Update</Button>
                ]}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            { /* Form */}
            <Col span={24}>
              <Card>
                <Row gutter={16}>
                <Col span={24}>
                    <Form.Item 
                      label="Country" 
                      name='id'
                      initialValue={state.id}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select
                        showSearch
                        allowClear
                        disabled
                        placeholder="Select a location"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          getCountry?.data?.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                <Col span={24}>
                    <Form.Item 
                    label="Image Country" 
                    name="imageUrl"
                    initialValue={editAvatar ? this.state.avatar : state.imageFile}
                    >
                      <Space direction='vertical'>
                        {
                          editAvatar ? 
                            <Image width={100} src={editAvatar ? onPreviewAvatar : null} />
                          : state.imageFile ?
                            <Image width={100} src={state.imageFile} />
                          :
                            null
                        }
                        <Space>
                          <Upload {...this.handleUpload()}>
                            <Button icon={<UploadOutlined />}>{state.imageFile ? 'Change Image' : editAvatar ? 'Change Image' : 'Upload a image'}</Button>
                          </Upload>
                          {
                            editAvatar ? 
                            <Button icon={<DeleteOutlined />} type="danger" ghost onClick={this.handleDeleteFile}>Delete</Button>
                            : null
                          }
                        </Space>
                      </Space>
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item 
                      label="About"
                      name="information"
                      validateFirst
                      initialValue={state.information}
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 10, message: '10 characters only' },
                        { max: 1000, message: '1000 characters only' },
                      ]}
                    >
                      <Input.TextArea rows={3} />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetCountry, unmountGetList } = this.props;
    unmountGetList()
    unmountGetCountry()
  }
}

const mapStateToProps = (state) => ({
  getData: state.aboutCountries.list,
  getCountry  : state.countries.master
})

const mapDispatchToProps = {
  actionUpdate      : editAboutCountries,
  actionGetList   : listAboutCountries,
  actionGetCountry  : masterCountries,
  unmountGetList  : unmountListAboutCountries,
  unmountGetCountry : unmountMasterCountries,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditMasterAboutCountry)
