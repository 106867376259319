const LOAD_EDIT_TRAINING           = 'LOAD_EDIT_TRAINING'
const LOAD_EDIT_TRAINING_SUCCESS   = 'LOAD_EDIT_TRAINING_SUCCESS'
const LOAD_EDIT_TRAINING_FAILED    = 'LOAD_EDIT_TRAINING_FAILED'
const UNMOUNT_EDIT_TRAINING        = 'UNMOUNT_EDIT_TRAINING'

const initialState = {
  loading: true,
  data: null,
  message: null,
}

const trainingEditReducer = (state = initialState, action) => {
  switch(action.type){
    case LOAD_EDIT_TRAINING:
      return {
        ...state,
        loading: true,
        data: null,
      }
    case LOAD_EDIT_TRAINING_SUCCESS:
      return { 
        ...state, 
        loading: false,
        data: action.payload.data,
        message: 'SUCCESS',
      }
    case LOAD_EDIT_TRAINING_FAILED:
      return { 
        ...state, 
        loading: true,
        data: null,
        message: 'FAILED',
      }
    case UNMOUNT_EDIT_TRAINING:
      return { 
        ...state, 
        loading: true,
        data: null,
      }
    default:
      return state
  }
}

export default trainingEditReducer;