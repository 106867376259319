import React, { Component } from 'react';
import { Form, Row, Col, Input, Select, Typography, Space, Button } from 'antd';
const { Text } = Typography;
const { Option } = Select

export default class EditLanguageSkills extends Component {
  render() {
    const { 
      dataCandidateLanguageSkills,
      formRef,
      getLanguage,
      handleCancelOther,
      onSubmitOther,
      submitLoading
    } = this.props;

    return (
      <React.Fragment>
        <Form
          ref={formRef}
          layout="vertical"
        >
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                name="name"
                label="Other Language"
                initialValue={dataCandidateLanguageSkills?.name ? dataCandidateLanguageSkills?.name : null}
                validateFirst
                rules={[
                  { pattern: /^[a-zA-Z ]*$/, message: 'Alphabetical only' },
                  { min: 3, message: '3 characters only' },
                  { max: 75, message: '75 characters only' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Text strong>Understanding</Text>
            </Col>
            <Col span={12}>
              <Form.Item
                name="listeningLevelId"
                label="Listening"
                initialValue={dataCandidateLanguageSkills?.listeningLevelId ? dataCandidateLanguageSkills?.listeningLevelId : null}
              >
                <Select
                  placeholder="Select"
                  className="width-100 border-radius-6"
                >
                  {
                    getLanguage?.data?.map(item =>
                      item.section === 'LISTENING' ?
                        <Option key={item.id} value={item.id} className="language">{item.code} - {item.name} - {item.descriptionEng}</Option>
                        : null
                    )
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="readingLevelId"
                label="Reading"
                initialValue={dataCandidateLanguageSkills?.readingLevelId ? dataCandidateLanguageSkills?.readingLevelId : null}
              >
                <Select
                  placeholder="Select"
                  className="width-100 border-radius-6"
                >
                  {
                    getLanguage?.data?.map(item =>
                      item.section === 'READING' ?
                        <Option key={item.id} value={item.id} className="language">{item.code} - {item.name} - {item.descriptionEng}</Option>
                        : null
                    )
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Text strong>Speaking</Text>
            </Col>
            <Col span={12}>
              <Form.Item
                name="spokenInterLevelId"
                label="Speaking Interaction"
                initialValue={dataCandidateLanguageSkills?.spokenInterLevelId ? dataCandidateLanguageSkills?.spokenInterLevelId : null}
              >
                <Select
                  placeholder="Select"
                  className="width-100 border-radius-6"
                >
                  {
                    getLanguage?.data?.map(item =>
                      item.section === 'SPOKEN_INTERACTION' ?
                        <Option key={item.id} value={item.id} className="language">{item.code} - {item.name} - {item.descriptionEng}</Option>
                        : null
                    )
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="spokenIntroLevelId"
                label="Spoken Introduction"
                initialValue={dataCandidateLanguageSkills?.spokenIntroLevelId ? dataCandidateLanguageSkills?.spokenIntroLevelId : null}
              >
                <Select
                  placeholder="Select"
                  className="width-100 border-radius-6"
                >
                  {
                    getLanguage?.data?.map(item =>
                      item.section === 'SPOKEN_PRODUCTION' ?
                        <Option key={item.id} value={item.id} className="language">{item.code} - {item.name} - {item.descriptionEng}</Option>
                        : null
                    )
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} >
              <Text strong>Writing</Text>
            </Col>
            <Col span={24} style={{ marginTop: 8 }}>
              <Form.Item
                name="writingLevelId"
                initialValue={dataCandidateLanguageSkills?.writingLevelId ? dataCandidateLanguageSkills?.writingLevelId : null}
              >
                <Select
                  placeholder="Select"
                  className="width-100 border-radius-6"
                >
                  {
                    getLanguage?.data?.map(item =>
                      item.section === 'WRITING' ?
                        <Option key={item.id} value={item.id} className="language">{item.code} - {item.name} - {item.descriptionEng}</Option>
                        : null
                    )
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Form.Item>
                <Space>
                  <Button type="default" onClick={handleCancelOther}>Cancel</Button>
                  <Button className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading} onClick={onSubmitOther}>Save</Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}
