import React, { Component } from 'react';
import Notification from '../../Notification';
import { Link } from 'react-router-dom';
import { BellOutlined, ExportOutlined } from '@ant-design/icons';
import { Layout, Drawer, Space, Row, Col, Badge, Divider, Typography } from 'antd';
const { Header } = Layout;
const { Text } = Typography;

export default class HeaderMenu extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      show: true,
      visible: false,
    }
  }

  showDrawer = () => {
    return this.setState({ visible: true })
  }

  onClose = () => {
    return this.setState({ visible: false })
  }

  handleChangeVisible = (e) => {
    this.setState({ visibleDropdown: e })
  }

  render() {
    const { visible } = this.state;
    const { dataCounNotification } = this.props;
    return (
      <React.Fragment>
        <Header style={{ 
          position: 'fixed', 
          zIndex: 2, 
          width: '100%', 
          right: 0, 
          backgroundColor: '#ffff',
          borderBottom: '2px solid #dfdfdf'
        }}>
          <Row gutter={16}>
            <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Space size="large" split={<Divider type="vertical" />}>
                <Link to="#" onClick={this.showDrawer}>
                  {
                    dataCounNotification.data > 0 ?
                    <Badge count={dataCounNotification.data}>
                      <BellOutlined style={{ fontSize: 22, color: '#5395d4' }} />
                    </Badge>
                    :
                    <BellOutlined style={{ fontSize: 22, color: '#5395d4' }} />
                  }
                </Link>
                <Link to="#" onClick={this.props.setLogout} style={{ fontSize: 16, color: '#32ab6d' }}>
                  <ExportOutlined /> Logout
                </Link>
              </Space>
            </Col>
          </Row>
        </Header>
        <Drawer
          title={<Text strong style={{ color: '#32ab6d' }}>Notification</Text>}
          closable
          width={400}
          visible={visible}
          onClose={this.onClose}
        >
          <Notification {...this.props} />
        </Drawer>
      </React.Fragment>
    )
  }
}
