import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { EditOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Row, Col, Space, Typography, Button, Divider, Form, Modal, Checkbox } from 'antd';
import HeaderActiveApplicant from '../../../../../../../../../components/ActiveApplicants/Header'
const { Text } = Typography;

export default class ContentAssessment extends Component {

  showModal = (data) => {
    Modal.info({
      title: 'Reason of request to reschedule:',
      content: (
        <Text>{data.rescheduleReason}</Text>
      ),
    });
  }

  onFinish = (values) => {
    const { onFinish } = this.props;
    return onFinish(values)
  }

  render() {
    const { 
      dataApplicant, 
      isResult, 
      openModalProfile, 
      formRef,
      openModal,
      isAttended,
      handleChecked,
      showModalNotes,
      showModalHistory,
      showModalReject
    } = this.props;
    return (
      <React.Fragment>
        <HeaderActiveApplicant status="assessment" dataApplicant={dataApplicant} openModalProfile={openModalProfile} showModalNotes={showModalNotes} showModalHistory={showModalHistory} />
        <Divider />
        <Form onFinish={this.onFinish} layout="vertical" ref={formRef}>
          <Row gutter={16}>
            <Col span={24} style={{ marginBottom: 16 }}>
              <Text>This applicant has been scheduled for the assessment process.</Text>
            </Col>
            <Col span={24}>
              <Space direction='vertical' size={0}>
                <Space size={4}>
                  <Text>Date/Time</Text>
                  <Text>:</Text>
                  <Text strong>{dataApplicant.assessmentDate && dataApplicant.assessmentTime ? `${dataApplicant.assessmentDate} ${dataApplicant.assessmentTime}` : null}</Text>
                </Space>
                <Space size={4}>
                  <Text>Method</Text>
                  <Text>:</Text>
                  <Text strong>{dataApplicant.method}</Text>
                </Space>
                {
                  dataApplicant.method === "Direct" ?
                    <Space size={4} style={{ marginTop: -16, marginBottom: -16 }}>
                      <Text>Location</Text>
                      <Text>:</Text>
                      <div dangerouslySetInnerHTML={{ __html: dataApplicant.location ? dataApplicant.location : null }} style={{ fontWeight: 'bold', paddingTop: 12 }}></div>
                    </Space>
                  :
                    <Space size={4} style={{ marginTop: -16, marginBottom: -16 }}>
                      <Text>Virtual Link</Text>
                      <Text>:</Text>
                      <div dangerouslySetInnerHTML={{ __html: dataApplicant.virtualLink ? dataApplicant.virtualLink : null }} style={{ fontWeight: 'bold', paddingTop: 12 }}></div>
                    </Space>

                }
              </Space>
            </Col>
            <Col span={24}>
              <Divider dashed />
            </Col>
            <Col span={24} style={{ marginBottom: 32 }}>
              <Space direction='vertical'>
                <Text strong>Confirmation:</Text>
                <Text>Please confirm the applicant's attendance and assessment status.</Text>
                <Checkbox onChange={handleChecked}>Applicant attended the assessment.</Checkbox>
              </Space>
            </Col>
            {
              isAttended ?
                <React.Fragment>
                  <Col span={24}>
                    <Form.Item 
                      name="notes" 
                      label="Notes to Applicant (optional)" 
                      initialValue={null}
                      rules={[
                        { required: !isResult, message: 'This is a required field' },
                      ]}
                    >
                      <ReactQuill />
                    </Form.Item>
                  </Col>
                  {/* <Col span={24}>
                    <Card style={{ background: '#EBF3FF', borderColor: '#EBF3FF' }}>
                      <Space direction='vertical'>
                        <Text strong>Applicant doesn't meet the criteria?</Text>
                        <Text>Give them an opportunity for development. The development process helps applicants enhance their qualifications through comprehensive training. Click the button below to initiate the applicant development process and help them reach their full potential</Text>
                        <Button type='primary' ghost className='btn-radius' onClick={() => showModalDev(dataApplicant.id)}>Process for Development</Button>
                      </Space>
                    </Card>
                  </Col> */}
                  <Col span={24} style={{ marginTop: 16 }}>
                    <Space style={{ float: 'right' }}>
                      <Button className='btn-radius' danger onClick={() => showModalReject(dataApplicant.id)}>Reject Applicant</Button>
                      <Button className='btn-radius' type="primary" htmlType="submit">Proceed to Next Stage</Button>
                    </Space>
                  </Col>
                </React.Fragment>
              :
                <React.Fragment>
                  <Col span={24} style={{ marginBottom: 32 }}>
                    <Space direction='vertical'>
                      <Text strong>Edit Schedule:</Text>
                      <Text>If the applicant is unable to attend or there are any changes, you can reschedule the assessment by clicking the button below.</Text>
                      <Button icon={<EditOutlined />} size='small' onClick={openModal}>Edit Schedule</Button>
                    </Space>
                  </Col>
                  <Col span={24} style={{ marginBottom: 32 }}>
                    <Space direction='vertical'>
                      <Text strong>Remove Applicant:</Text>
                      <Text>If the applicant fails to confirm or does not attend the assessment, you can remove them from the process by clicking the button below.</Text>
                      <Button icon={<CloseCircleOutlined />} size='small' danger onClick={() => showModalReject(dataApplicant.id)}>Remove Applicant</Button>
                    </Space>
                  </Col>
                </React.Fragment>
            }
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}