import React, { Component } from 'react';
import moment from 'moment';
import ContentOffered from './Content';
import { connect } from 'react-redux';
import { Loading } from '../../../../../../../../components';
import { Row, Col, Card, Typography, Modal, Empty, Input, Button, AutoComplete, Skeleton, message } from 'antd';
import { ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { detailCandidate, unmountDetailCandidate } from '../../../../../../../../redux/actions/candidate/candidateAction';
import { detailJobActiveApplicant, addDeparture, addHired, listApplicant, addApplicantNotes, listApplicantNotes, unmountDetailJobsActiveApplicant, unmountListApplicant, unmountApplicantNotes } from '../../../../../../../../redux/actions/jobs/jobsAction';
import ListApplicant from '../../../../../../../../components/ActiveApplicants/ListApplicant';
import ViewProfile from '../../../../../../../../components/Modal/ApplicantProfile';
import ApplicantHistory from '../../../../../../../../components/Modal/ApplicantHistory';
import ApplicantNotes from '../../../../../../../../components/Modal/ApplicantNotes';
import ApplicationRejection from '../../../../../../../../components/Modal/ApplicationRejection';
import DownloadFile from '../../../../../../../../components/DownloadFiles';

const { Text } = Typography;
const { confirm } = Modal;

class DetailOfferedAgencyJobs extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      method: "VIRTUAL",
      checked: true,
      visible: false,
      valueForm: null,
      idApplicant: null,
      dataApplicant: null,
      isResult: null,
      documentName: null,
      isModalNotes: false,
      isModalHistory: false,
      isModalReject: false,
      isDeparture: false,
      submitLoading: false
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.match.params !== this.props.match.params){
      this.setState({ dataApplicant: null })
    }
  }

  selectedApplicant = (value) => {
    const { actionGetProfile, actionListApplicantNotes } = this.props;
    const { dataApplicant } = this.state;
    this.setState({ idApplicant: value.id, dataApplicant: value })
    if(dataApplicant){
      this.formRef.current.setFieldsValue({
        isResult: true,
        notes: null,
      });
    }
    actionGetProfile(value.candidateId)
    actionListApplicantNotes(value.id)
  }

  handleChangeOfferedResult = (e) => {
    this.setState({ isResult: e.target.value })
  }

  onFinish = (values) => {
    const self = this;
    const { dataApplicant, isDeparture } = this.state;
    const { metaApplicant, url, match: { params }, actionAddDeparture, actionAddHired, actionGetApplicant } = this.props;
    values.phaseId = Number(params.phaseId)
    values.jobAppliedId = dataApplicant.id
    values.joinDate = moment(values.joinDate).format('DD/MM/YYYY')
    values.fromDeparture = isDeparture
    values.departureDate = moment(values.departureDate).format('DD/MM/YYYY')
    values.departureTime = moment(values.departureTime).format('HH:mm:ss')
    if(values.isResult){
      if(values.fromDeparture){
        confirm({
          title: 'The application will be processed to the next stage.',
          icon: <ExclamationCircleOutlined />,
          content: 'By doing this, the applicant will be notified of the application status being changed to departure. Are you sure?',
          okText: 'Yes',
          cancelText: 'No',
          onOk() {
            return actionAddDeparture(values, () => {
              message.success('Successfully moved')
              actionGetApplicant(metaApplicant, url, (response) => {
                self.setState({ dataApplicant: null, isResult: null, isDeparture: false })
              })
            })
          },
        });
      }else{
        confirm({
          title: 'This is the last step of the hiring process.',
          icon: <ExclamationCircleOutlined />,
          content: 'By doing this, the applicant will be notified of the application status being changed to hired. Are you sure?',
          okText: 'Yes',
          cancelText: 'No',
          onOk() {
            return actionAddHired(values, () => {
              message.success('Successfully moved')
              actionGetApplicant(metaApplicant, url, (response) => {
                self.setState({ dataApplicant: null, isResult: null, isDeparture: false })
              })
            })
          },
        });
      }
    }else {
      confirm({
        title: 'This application will be moved to rejected.',
        icon: <ExclamationCircleOutlined />,
        content: 'Are you sure?',
        okText: 'Yes',
        cancelText: 'No',
        onOk() {
          return actionAddDeparture(values, () => {
            message.success('Successfully moved')
            actionGetApplicant(metaApplicant, url, (response) => {
              self.setState({ dataApplicant: null, isResult: null, isDeparture: false })
            })
          })
        },
      });
    }
  }

  openModalProfile = (id) => {
    const { getApplicant } = this.props;
    const findCandidate = getApplicant.data.find(item => item.candidateId === id)
    this.setState({
      visible: true,
      documentName: `${findCandidate.applicantNumber}-${findCandidate.name}`
    })
  }

  showModalNotes = (id) => {
    this.setState({ isModalNotes: true, idApplicant: id })
  };
  
  showModalHistory = (id) => {
    this.setState({ isModalHistory: true, idApplicant: id })
  };

  showModalReject = (id) => {
    this.setState({ isModalReject: true, idApplicant: id })
  };

  onCancel = () => {
    this.setState({ visible: false, isModalNotes: false, isModalHistory: false, isModalReject: false })
  }

  handleChecked = (e) => {
    this.setState({ isDeparture: e.target.checked })
  }

  onFinishNotes = value => {
    const { idApplicant } = this.state
    const { metaApplicant, url, actionAddApplicantNotes, actionGetApplicant, actionListApplicantNotes } = this.props;
    
    value.jobAppliedId = idApplicant

    return actionAddApplicantNotes(value, url, () => {
      message.success('Successfully update applicant notes')
      actionListApplicantNotes(idApplicant)
      actionGetApplicant(metaApplicant, url, (response) => {
        this.setState({ dataApplicant: response.data.filter(item => item.id === idApplicant).pop() })
      }, (err) => message.error(err))
    }, (err) => message.error(err))
  }
  
  onFinishReject = values => {
    const { metaApplicant, url, actionAddDeparture, actionGetApplicant, match: { params } } = this.props;
    values.jobAppliedId = this.state.idApplicant
    values.phaseId = Number(params.phaseId)
    values.isResult = false
    this.setState({ submitLoading: true })
    return actionAddDeparture(values, () => {
      message.success('Successfully moved')
      actionGetApplicant(metaApplicant, url, (response) => {
        this.setState({ dataApplicant: null, isModalReject: false, isResult: null, isDeparture: false, submitLoading: false })
      }, (err) => {
        this.setState({ submitLoading: false }, () => {
          message.error(err)
        })
      })
    })
  }
  
  handleInfiniteOnLoad = () => {
    ('scroll');
  };

  render() {
    const { idApplicant, dataApplicant, visible, isResult, documentName, isDeparture, isModalNotes, isModalHistory, isModalReject, submitLoading } = this.state;
    const { getData: { data, loading }, getApplicant, getProfile, onSearchApplicant, handlePagination } = this.props;
    
    if(loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Row gutter={[16, 24]}>
          {/* Content */}
          <Col span={6}>
            <AutoComplete
              onSearch={onSearchApplicant} 
              style={{ width: '100%' }} 
              allowClear
            >
              <Input 
                prefix={<SearchOutlined />} 
                placeholder="Search by Applicant Name" 
              />
            </AutoComplete>
          </Col>
          <Col span={24}>
            <Text>Showing {getApplicant.pagination.total} Data</Text>
          </Col>
          <Col span={6}>
            {
              getApplicant.loading ?
                <Skeleton active />
              :
                <ListApplicant 
                  type="offered"
                  loading={getApplicant?.loading} 
                  pagination={getApplicant?.pagination}
                  dataSource={getApplicant?.data}
                  idApplicant={idApplicant}
                  selectedApplicant={(e) => this.selectedApplicant(e)}
                  // handleInfiniteOnLoad={() => this.handleInfiniteOnLoad()} 
                  handlePagination={(e) => handlePagination(e)}
                />
            }
          </Col>
          <Col span={18}>
            <Card>
              {
                dataApplicant ? 
                  <ContentOffered 
                    dataApplicant={dataApplicant}
                    openModalProfile={this.openModalProfile}
                    onFinish={this.onFinish}
                    isResult={isResult}
                    formRef={this.formRef}
                    handleChangeOfferedResult={(e) => this.handleChangeOfferedResult(e)}
                    handleChecked={this.handleChecked}
                    isDeparture={isDeparture}
                    showModalNotes={this.showModalNotes}
                    showModalHistory={this.showModalHistory}
                    showModalReject={this.showModalReject}
                  />
                : 
                  <Empty description={<Text>Please click an applicant name to start the offered</Text>} />
              }
            </Card>
          </Col>
        </Row>
        <Modal 
          centered
          width={1000}
          visible={visible}
          title="Applicant Profile"
          onCancel={this.onCancel}
          footer={
            <>
              <DownloadFile data={data} getProfile={getProfile} documentName={documentName} />
              <Button key="back" type="primary" onClick={this.onCancel}>Okay</Button>
            </>
          }
        >
          <div style={{ height: 430, overflowY: 'auto', overflowX: 'hidden', padding: 32 }}>
            <ViewProfile {...this.props} />
          </div>
        </Modal>
        <Modal centered width={800} title="Applicant Notes" visible={isModalNotes} onCancel={this.onCancel} footer={null} destroyOnClose>
          <ApplicantNotes {...this.props} onFinishNotes={this.onFinishNotes} />
        </Modal>
        <Modal centered width={800} title="Applicant History" visible={isModalHistory} onCancel={this.onCancel} footer={null} destroyOnClose>
          <ApplicantHistory dataApplicantHistory={dataApplicant?.listHistory} />
        </Modal>
        <Modal title="Application Rejection" visible={isModalReject} onCancel={this.onCancel} footer={null} destroyOnClose>
          <ApplicationRejection {...this.props} submitLoading={submitLoading} notes="notes" onFinishReject={this.onFinishReject} />
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail, unmountGetApplicant, unmountApplicantNotes } = this.props;
    unmountGetApplicant()
    unmountGetDetail()
    unmountApplicantNotes()
  }
}

const mapStateToProps = (state) => ({
  getData           : state.jobs.activeApplicant,
  getApplicant      : state.jobs.listApplicant,
  getProfile        : state.candidate.detail,
  getApplicantNotes : state.jobs.listApplicantNotes
})

const mapDispatchToProps = {
  actionAddDeparture        : addDeparture,
  actionAddHired            : addHired,
  actionGetApplicant        : listApplicant,
  actionGetProfile          : detailCandidate,
  actionGetDetail           : detailJobActiveApplicant,
  actionAddApplicantNotes   : addApplicantNotes,
  actionListApplicantNotes  : listApplicantNotes,
  unmountGetApplicant       : unmountListApplicant,
  unmountGetProfile         : unmountDetailCandidate,
  unmountGetDetail          : unmountDetailJobsActiveApplicant,
  unmountApplicantNotes     : unmountApplicantNotes
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailOfferedAgencyJobs)