let env = "https://stg-api-bo.jobshub.id/api"
// let env = "https://ling.jobshub.id/api"

if(process.env.REACT_APP_ENV === "development"){
  env = "https://stg-api-bo.jobshub.id/api"
}

if(process.env.REACT_APP_ENV === "production"){
  env = "https://ling.jobshub.id/api"
}

export default env;