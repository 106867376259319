export const jobType = [
  {
    "value": "FULL_TIME",
    "name": "Full Time"
  },
  {
    "value": "PART_TIME",
    "name": "Part Time"
  },
  {
    "value": "FREELANCE",
    "name": "Freelance"
  },
  {
    "value": "INTERNSHIP",
    "name": "Internship"
  },
  {
    "value": "VOLUNTEER",
    "name": "Volunteer"
  },
  {
    "value": "TALENT_POOL",
    "name": "Talent Pool"
  }
]