import { Avatar, Card, Col, Empty, Row, Divider, Typography } from "antd"
import moment from "moment";
import React from "react";
import { statusTransaction } from "../../../../../utils/constant/statusTransaction";
import { UserOutlined } from '@ant-design/icons';
const { Text } = Typography;

const LastTransaction = ({ data }) => {
  return(
    <Card 
      style={{ border: 'none' }}
      bordered={false}
      title={<Text strong>Last Transactions</Text>}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          {
            data.listLastTransaction ?
              data.listLastTransaction.map((item, i) => (
                <React.Fragment key={i}>
                  <Row justify="space-around" align="middle" style={{ marginBottom: 16 }}>
                    <Col span={3}>
                      <Avatar size={40} icon={<UserOutlined />} style={{ backgroundColor: '#87d068' }} />
                    </Col>
                    <Col span={9}>
                      <Text>{item.ticketName}</Text>
                    </Col>
                    <Col span={6}>
                      <Text type="secondary">{moment(item.transactionDate, 'DD/MM/YYYY').format('ll')}</Text>
                    </Col>
                    <Col span={6}>
                      <Text type="secondary">{statusTransaction.find(res => res.value === item.status)?.name}</Text>
                    </Col>
                  </Row>
                  <Divider />
                </React.Fragment>
              ))
            :
              <React.Fragment>
                <Empty description={<Text>No Data Transaction</Text>} />
              </React.Fragment>
          }
        </Col>
      </Row>
    </Card>
  )
}

export default LastTransaction;