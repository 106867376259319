import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { columns } from './columns';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PageHeader, Row, Col, Breadcrumb, Table, Pagination, Button, AutoComplete, Input, Modal, Typography, Space, Tag, message } from 'antd';
import { QuestionCircleOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { listFaq, enableFaq, disableFaq, unmountListFaq } from '../../../redux/actions/faq/faqAction';
const { Text } = Typography;

class FaqAgency extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      sortedInfo: null,
      meta: {
        page: 1,
        perpage: 10,
        field: null,
        sort: null,
        search: null,
        flagStatus: null,
      },
      visible: false,
      faq: null
    }
    this.onSearch = debounce(this.onSearch.bind(this), 900)
  }

  componentDidMount() {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    return actionGetData(meta)
  }

  onSearch = value => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.search = value === '' ? null : value
    return actionGetData(meta)
  }

  onStatus = value => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.flagStatus = value
    return actionGetData(meta)
  }

  pagination = (page, perpage) => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = page
    meta.perpage = perpage
    return actionGetData(meta)
  }

  handleEnable = (value) => {
    const { meta } = this.state;
    const { actionEnable, actionGetData } = this.props;
    return actionEnable(value, () => {
      message.success('Successfully changed to active')
      return actionGetData(meta)
    }, (err) => message.error(err))
  }

  handleDisable = (value) => {
    const { meta } = this.state;
    const { actionDisable, actionGetData } = this.props;
    return actionDisable(value, () => {
      message.success('Successfully changed to inactive')
      return actionGetData(meta)
    }, (err) => message.error(err))
  }

  onClickEdit = (id, question, answer, listClient, enabled) => {
    this.props.history.push({
      pathname: `/agency/faq/edit/${id}`,
      state: {
        question: question,
        answer: answer,
        listClient: listClient,
        enabled: enabled,
      }
    })
  }

  showModal = (id) => {
    const { getData:  {data } } = this.props;
    const faq = data.find(item => item.id === id )
    this.setState({ 
      visible: true, 
      faq: faq 
    })
  }

  onCancel = () => {
    return this.setState({ visible: false })
  }
  
  render() {
    const { getData: { loading, data, pagination } } = this.props;
    const { visible, faq } = this.state;
    

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><QuestionCircleOutlined /> FAQ</Breadcrumb.Item>
              <Breadcrumb.Item>FAQ List</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          { /* Page Header */}
          <Col span={24}>
            <PageHeader 
              className="site-page-header" 
              title='FAQ List' 
              extra={[
                <Link key="1" to="/agency/faq/create">
                  <Button className="btn-create-primary" type="primary" ghost icon={<PlusOutlined />}>
                    Create FAQ
                  </Button>
                </Link>,
              ]}
            />
          </Col>
          { /* Filter */}
          <Col span={14}></Col>
          <Col span={10}>
            <AutoComplete 
              onSearch={this.onSearch} 
              style={{ width: '100%' }} 
              allowClear
            >
              <Input prefix={<SearchOutlined />} placeholder="Search"  />
            </AutoComplete>
          </Col>
          { /* Table */}
          <Col span={24}>
            <Row gutter={[16, 24]}>
              <Col span={24}>
                <Table 
                  size="small"
                  loading={loading} 
                  dataSource={data}
                  pagination={false} 
                  rowKey={(i) => i.id} 
                  columns={columns(this.onClickEdit, this.handleEnable, this.handleDisable, this.showModal)}
                />
              </Col>
              {/* Pagination */}
              <Col span={24}>
                <Pagination
                  total={pagination.total}
                  onChange={this.pagination}
                  current={pagination.page}
                  defaultPageSize={pagination.perpage}
                  showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total} Data`}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Modal
          key={1}
          centered
          width={800}
          visible={visible}
          title={<Text strong>Detail Faq</Text>}
          footer={null}
          onCancel={this.onCancel}
        >
          <Row>
            <Col span={24} style={{marginBottom: 10}}>
              <Space direction='vertical'>
                <Text type='secondary'>Question</Text>
                <Text strong>{faq?.question}</Text>
              </Space>
            </Col>
            <Col span={24} style={{marginBottom: 10}}>
              <Space direction='vertical'>
                <Text type='secondary'>Answer</Text>
                <Text strong>{faq?.answer}</Text>
              </Space>
            </Col>
            <Col span={24} style={{marginBottom: 10}}>
              <Space direction='vertical'>
                <Text type='secondary'>Client Name</Text>
                <Text strong>{faq?.listClient?.map((item) => item.clientName.toString()).join(', ')}</Text>
              </Space>
            </Col>
            <Col span={24} style={{marginBottom: 10}}>
              <Space direction='vertical'>
                <Text type='secondary'>Status</Text>
                <Text>{faq?.enabled ? <Tag color="green">Active</Tag> : <Tag color="orange">Inactive</Tag> }</Text>
              </Space>
            </Col>
          </Row>
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountGetData } = this.props;
    return unmountGetData()
  }
}

const mapStateToProps = (state) => ({
  getData: state.faq.list,
})

const mapDispatchToProps = {
  actionGetData       : listFaq,
  actionEnable        : enableFaq,
  actionDisable       : disableFaq,
  unmountGetData      : unmountListFaq,
}

export default connect(mapStateToProps, mapDispatchToProps)(FaqAgency)