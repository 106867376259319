import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading } from '../../../../../components';
import { InfoCircleOutlined, DatabaseOutlined } from '@ant-design/icons';
import { provinceByCountry } from '../../../../../redux/actions/master/provinces/provincesAction';
import { detailCity, editCity, unmountDetailCities } from '../../../../../redux/actions/master/cities/citiesAction';
import { masterCountries, unmountMasterCountries } from '../../../../../redux/actions/master/countries/countriesAction';
import { Col, Form, Row, Input, Button, Select, PageHeader, message, Card, Breadcrumb } from 'antd';

class EditMasterCity extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      dataProvince: [],
    }
  }

  componentDidMount() {
    const { actionGetCountry, actionGetDetail, actionGetProvince, match: { params } } = this.props;
    actionGetDetail(params.id, (response) => {
      actionGetProvince(response.data.countryId, (response) => {
        this.setState({ dataProvince: response.data })
      })
    })
    actionGetCountry()
  }

  handleChangeCountry = (value) => {
    const { actionGetProvince } = this.props;
    this.formRef.current.setFieldsValue({
      provinceId: null,
    });
    this.setState({ callingCode: value })
    return actionGetProvince(value, (response) => {
      this.setState({ dataProvince: response.data })
    })
  }
  
  onFinish = (values) => {
    const { actionUpdate, history, getData: { data } } = this.props;
    values.id = data.id
    this.setState({ submitLoading: true })
    return actionUpdate(values, () => {
      this.setState({ submitLoading: false }, () => {
        message.success('Data updated successfully')
        history.push('/master/cities')
      })
    }, (err) => {
      this.setState({ submitLoading: false }, () => message.error(err))
    })
  }

  render() {
    const { submitLoading, dataProvince } = this.state;
    const { getCountry, getData: { loading, data } } = this.props;

    if(loading || getCountry.loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Form onFinish={this.onFinish} ref={this.formRef} layout="vertical">
          <Row style={{ position: 'sticky', zIndex: 1, width: '100%', top: 62, right: 0, marginTop: -20, marginBottom: 8, paddingBottom: 12, paddingTop: 16, backgroundColor: '#f2f2f2' }}>
            {/* Breadcrumb */}
            <Col span={24}>
              <Breadcrumb>
                <Breadcrumb.Item><DatabaseOutlined /> Master Data</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link className="link" to="/master/cities">
                    Master Data List Cities
                  </Link> 
                </Breadcrumb.Item>
                <Breadcrumb.Item>Cities</Breadcrumb.Item>
                <Breadcrumb.Item>{data.name ? data.name : '-'}</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            { /* Page Header */}
            <Col span={24}>
              <PageHeader className="site-page-header" title="Update City Data" />
              <PageHeader 
                className="site-page-header" 
                title="Update City Data"
                extra={[
                  <Button key="2" className="btn-save-primary" type="default" onClick={() => window.history.back()}>Cancel</Button>,
                  <Button key="1" className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading}>Update</Button>
                ]}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            { /* Form */}
            <Col span={24}>
              <Card>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item 
                      label="Country" 
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        placeholder="Select a country"
                        onChange={this.handleChangeCountry}
                        defaultValue={data.countryId ? data.countryId : ''}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          getCountry?.data?.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Province" 
                      name="provinceId" 
                      initialValue={data.provinceId ? data.provinceId : ''}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select
                        showSearch
                        allowClear
                        placeholder="Select a province"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          dataProvince.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="City Name" 
                      name="name" 
                      validateFirst
                      initialValue={data.name ? data.name : ''}
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 3, message: 'Name must be at least 3 characters long' },
                        { max: 50, message: 'City name is too long. Maximum is 50 characters' },
                        { pattern: new RegExp(/^([^0-9]*)$/), message: 'Number are not allowed' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Will it be displayed in the candidate's side?" 
                      name="isDisplay" 
                      initialValue={data.isDisplay}
                      tooltip={{ 
                        title: 'If you choose yes, this city will be displayed in the Job Preferences option', 
                        icon: <InfoCircleOutlined style={{ color: '#2586eb' }} />
                      }}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select>
                        <Select.Option value={true}>Yes</Select.Option>
                        <Select.Option value={false}>No</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Status" 
                      name="enabled" 
                      initialValue={data.enabled}
                      tooltip={{ 
                        title: 'Inactive means this city isn’t used in master data', 
                        icon: <InfoCircleOutlined style={{ color: '#2586eb' }} />
                      }}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select>
                        <Select.Option value={true}>Active</Select.Option>
                        <Select.Option value={false}>Inactive</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Description" 
                      name="description" 
                      initialValue={data.description ? data.description : ''}
                      rules={[
                        { max: 50, message: 'Description is too long. Maximum is 50 characters' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetCountry, unmountGetDetail } = this.props;
    unmountGetDetail()
    unmountGetCountry()
  }
}

const mapStateToProps = (state) => ({
  getData     : state.cities.detail,
  getCountry  : state.countries.master
})

const mapDispatchToProps = {
  actionUpdate      : editCity,
  actionGetDetail   : detailCity,
  actionGetCountry  : masterCountries,
  actionGetProvince : provinceByCountry,
  unmountGetDetail  : unmountDetailCities,
  unmountGetCountry : unmountMasterCountries,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditMasterCity)