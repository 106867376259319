import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ReloadOutlined } from '@ant-design/icons';
import { Line } from '@reactchartjs/react-chart.js';
import { Skeleton, Row, Col, Space, Typography, Radio } from 'antd';
const { Text } = Typography;

export default class ApplicantSummary extends Component {

  componentDidMount() {
    const { actionGetApplicantSummary, menuLineChart } = this.props;
    return actionGetApplicantSummary('dashboard_agency', menuLineChart)
  }

  render() {
    const { onRefreshLineChart, handleMenuLineChart,  menuLineChart, getApplicantSummary: { loading, data } } = this.props;
    if(loading){
      return <Skeleton />
    }
    const dataLineChart = {
      labels: data ? data.labels : [''],
      datasets: [
        {
          label: 'Applicants',
          data: data ? data.value : [''],
          fill: false,
          borderColor: '#73c6b6',
        },
      ],
    }

    const options = {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              userCallback: function(label, index, labels) {
                if (Math.floor(label) === label) {
                  return label;
                }
              },
            }
          },
        ],
      },
    }
    return (
      <React.Fragment>
        <Row gutter={[16, 8]}>
          <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Space>
              <Text strong style={{ fontSize: 18 }}>Your Applicants Summary</Text>
              <Link to="#" onClick={onRefreshLineChart}>
                <ReloadOutlined style={{ color: '#919191', fontSize: 16 }} />
              </Link>
            </Space>
            <Radio.Group onChange={handleMenuLineChart} defaultValue={menuLineChart} buttonStyle="solid">
              <Radio.Button value="years">Year</Radio.Button>
              <Radio.Button value="month">Month</Radio.Button>
              <Radio.Button value="week">Week</Radio.Button>
              <Radio.Button value="today">Day</Radio.Button>
            </Radio.Group>
          </Col>
          <Col span={24}>
            <Text type="secondary">{data.totalApplicant} Applicants</Text>
          </Col>
          <Col span={24}>
            <Line data={dataLineChart} options={options} height={60} />
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}
