import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { columns } from './columns';
import { connect } from 'react-redux';
import { HeaderTable } from '../../../components';
import { TeamOutlined } from '@ant-design/icons';
import { PageHeader, Row, Col, Table, Breadcrumb, Pagination } from 'antd';
import { listCandidate, unmountListCandidate } from '../../../redux/actions/allCandidate/allCandidateAction';

class AllCandidateList extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      meta: {
        page: 1,
        perpage: 20
      }
    }
    this.onSearch = debounce(this.onSearch.bind(this), 900)
  }

  componentDidMount() {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    return actionGetData(meta)
  }

  onRegistered = value => {
    const { actionGetData } = this.props;
    const { meta } = this.state;
    meta.page = 1
    meta.perpage = 20
    meta.startDate = value.startDate
    meta.endDate = value.endDate
    return actionGetData(meta)
  }

  onStatus = value => {
    const { actionGetData } = this.props;
    const { meta } = this.state;
    meta.page = 1
    meta.perpage = 20
    meta.flagStatus = value
    return actionGetData(meta)
  }

  onSearch = value => {
    const { meta, url } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 20
    meta.search = value === '' ? null : value
    return actionGetData(meta, url)
  }

  pagination = (page, perpage) => {
    const { actionGetData } = this.props;
    const { meta } = this.state;
    meta.page = page
    meta.perpage = perpage
    return actionGetData(meta)
  }

  onClickRow = (record) => {
    this.props.history.push(`/all-candidate/detail/${record.id}/biodata`)
    localStorage.setItem("status", "all-candidate")
  }
  
  render() {
    const { getData: { data, loading , pagination} } = this.props;

    const defaultStatusFilter = [
      { value: null, name: 'All' },
      { value: true, name: 'Active' },
      { value: false, name: 'Inactive' },
    ]

    return (
      <React.Fragment>
        <Row gutter={[16, 0]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><TeamOutlined /> All Candidates</Breadcrumb.Item>
              <Breadcrumb.Item>All Candidates List</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='All Candidate List' />
          </Col>
          {/* Filter */}
          <Col span={24}>
            <HeaderTable 
              fase1={{ key: 'type', value: defaultStatusFilter}}
              onSearch={this.onSearch} 
              onStatus={this.onStatus} 
              onRegistered={this.onRegistered}
              tooltipDate="Filter by Register Date"
              tooltipStatus="Filter by Status"
              placeholder="Search by Name, Email"
            />
          </Col>
          {/* Table */}
          <Col span={24} style={{ marginTop: 8 }}>
            <Table 
              size="small" 
              rowKey={(i) => i.id} 
              pagination={false}
              loading={loading} 
              scroll={{ x: 1500 }}
              dataSource={data} columns={columns(this.onClickRow)}
            />
          </Col>
          {/* Pagination */}
          <Col span={24} style={{ marginTop: 8 }}>
            <Pagination
              total={pagination.total}
              onChange={this.pagination}
              current={pagination.page}
              pageSize={pagination.perpage}
              pageSizeOptions={[20,50,100,200]}
              showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total} Data`}
              size='small'
            />
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  
  componentWillUnmount() {
    const { unmountGetData } = this.props;
    unmountGetData()
  }
}

const mapStateToProps = (state) => ({
  getData: state.candidate.list
})

const mapDispatchToProps = {
  actionGetData               : listCandidate,
  unmountGetData              : unmountListCandidate,
}

export default connect(mapStateToProps, mapDispatchToProps)(AllCandidateList)
