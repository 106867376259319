import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading } from '../../../../components';
import { BookOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { PageHeader, Row, Col, Breadcrumb, Modal, Button, Typography, Form, Input, Descriptions, Space, message } from 'antd';
import { detailTransaction, unmountDetailTransaction, cancelTransaction, verifyTransaction, rejectTransaction } from '../../../../redux/actions/transaction/transactionAction';
import { detailCandidate, unmountDetailCandidate } from '../../../../redux/actions/candidate/candidateAction';
import Unpaid from './Unpaid';
import NeedToVerify from './NeedToVerify';
import Confirmed from './Confirmed';
import Cancelled from './Cancelled';
import Rejected from './Rejected';
import Expired from './Expired';
const { Text } = Typography;
const { TextArea } = Input;
const { confirm } = Modal;
class DetailTransaction extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
    this.state = {
      submitLoading: false,
      visibleCancel: false,
      visibleReject: false,
      dataForm: {}
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData(){
    const { actionGetDetail, match: { params } } = this.props;
    actionGetDetail(params.id)
  }

  showModalCancel = () => {
    this.setState({ visibleCancel: true })
  }

  showModalReject = () => {
    this.setState({ visibleReject: true })
  }

  showModalVerify() {
    const { actionVerifyTransaction, history, match: { params } } = this.props
    const self = this
    confirm({
      title: 'Payment Verification',
      icon: <ExclamationCircleOutlined />,
      content: 'The payment status will be changed to Verified. Are you sure?',
      okText: 'Yes',
      onOk() {
        return actionVerifyTransaction(params.id, () => {
          return self.setState({ submitLoading: false }, () => {
            history.push({
              pathname: '/transaction',
              state: { status: 'PAYMENT_CONFIRM' }
            })
            message.success('Success move to Confirmed')
          })
        }, (err) => {
          return self.setState({ submitLoading: false }, () => message.error(err))
        })
      },
      onCancel() {},
    })
  }

  handleOk = () => {
    this.setState({ visibleCancel: false, visibleReject: false })
  }

  handleCancel = () => {
    this.setState({ visibleCancel:false, visibleReject: false })
  }

  onFinishCancel = (values) => {
    const { actionCancelTransaction, history, match: { params } } = this.props
    values.id = params.id
    return actionCancelTransaction(values, () => {
      return this.setState({ submitLoading: false }, () => {
        history.push({
          pathname: '/transaction',
          state: { status: 'CANCELED' }
        })
        message.success('Success move to Canceled')
      })
    }, (err) => {
      return this.setState({ submitLoading: false }, () => message.error(err))
    })
  }

  onFinishReject = (values) => {
    const { actionRejectTransaction, history, match: { params } } = this.props
    values.id = params.id
    return actionRejectTransaction(values, () => {
      return this.setState({ submitLoading: false }, () => {
        history.push({
          pathname: '/transaction',
          state: { status: 'REJECTED' }
        })
        message.success('Success move to Rejected')
      })
    }, (err) => {
      return this.setState({ submitLoading: false }, () => message.error(err))
    })
  }

  render() {
    const { getData: { data, loading } } = this.props;
    const { visibleCancel, visibleReject, submitLoading } = this.state;

    if(loading){
      return <Loading />
    }

    const initialProps = {
      showModalCancel: this.showModalCancel
    }
    
    return (
      <React.Fragment>
        <Row style={{ position: 'sticky', zIndex: 1, width: '100%', top: 62, right: 0, marginTop: -20, marginBottom: 8, paddingBottom: 12, paddingTop: 16, backgroundColor: '#f2f2f2' }}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><BookOutlined /> Transaction</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/transaction">Transaction List</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Detail</Breadcrumb.Item>
              <Breadcrumb.Item>{ data.xpertTransactionNumber ? data.xpertTransactionNumber : '-' }</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          { /* Page Header */}
          {
            data.status === 'WAITING_FOR_CONFIRM' ?
              <>
                <Col span={12}>
                  <PageHeader 
                    className="site-page-header" 
                    title='Transaction Detail' 
                  />
                </Col>
                <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Space size={'large'}>
                    <Button type="danger" onClick={() => this.showModalReject()} style={{ width: 100 }}>Reject</Button>
                    <Button type="primary" onClick={() => this.showModalVerify()} style={{ width: 100 }}>Verify</Button>
                  </Space>
                </Col>
              </>
            : 
              <Col span={24}>
                <PageHeader 
                  className="site-page-header" 
                  title='Transaction Detail' 
                />
              </Col>
          }
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            {
              data.status === 'WAITING_FOR_PAYMENT' ?
                <Unpaid {...initialProps} {...this.props} />
              : data.status === 'WAITING_FOR_CONFIRM' ?
                <NeedToVerify {...initialProps} {...this.props} />
              : data.status === 'PAYMENT_CONFIRM' ?
                <Confirmed {...initialProps} {...this.props} />
              : data.status === 'CANCELED' ?
                <Cancelled {...initialProps} {...this.props} />
              : data.status === 'REJECTED' ?
                <Rejected {...initialProps} {...this.props} />
              : data.status === 'EXPIRED' ?
                <Expired {...initialProps} {...this.props} />
              : 
                null
            }
          </Col>
        </Row>
        <Modal 
          title="Cancel Transaction" 
          visible={visibleCancel} 
          onOk={this.handleOk} 
          onCancel={this.handleCancel}
          destroyOnClose
          footer={false}
          width={600}
        >
          <Row>
            <Col span={24}>
              <Descriptions layout="vertical" column={2} size="small" style={{ marginBottom: 16 }}>
                <Descriptions.Item label={<Text type='secondary'>Order Number</Text>}>{data.xpertTransactionNumber}</Descriptions.Item>
                <Descriptions.Item label={<Text type='secondary'>Buyer</Text>}>{data.name}</Descriptions.Item>
              </Descriptions>
              <Form
                layout="vertical"
                onFinish={this.onFinishCancel}
              >
                <Form.Item 
                  name="notes" 
                  label="Reason"
                  rules={[
                    { required: true, message: 'This is a required field' },
                    { min: 3, message: 'At least 3 characters long' },
                    { max: 250, message: '250 characters only' },
                  ]}
                >
                  <TextArea rows={3} />
                </Form.Item>

                <Form.Item>
                  <Button type="danger" htmlType="submit" loading={submitLoading} style={{ float: 'right' }}>
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Modal>
        <Modal title="Reject Transaction" visible={visibleReject} onOk={this.handleOk} onCancel={this.handleCancel} footer={false}>
          <Row>
            <Col span={24}>
              <Descriptions layout="vertical" column={2} size="small" style={{ marginBottom: 16 }}>
                <Descriptions.Item label={<Text type='secondary'>Order Number</Text>}>{data.xpertTransactionNumber}</Descriptions.Item>
                <Descriptions.Item label={<Text type='secondary'>Buyer</Text>}>{data.name}</Descriptions.Item>
              </Descriptions>
              <Form
                layout="vertical"
                onFinish={this.onFinishReject}
              >
                <Form.Item 
                  name="notes" 
                  label="Reason"
                  rules={[
                    { required: true, message: 'This is a required field' },
                    { min: 3, message: 'At least 3 characters long' },
                    { max: 250, message: '250 characters only' },
                  ]}
                >
                  <TextArea rows={3} />
                </Form.Item>

                <Form.Item>
                  <Button type="danger" htmlType="submit" style={{ float: 'right' }}>
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail, unmountGetProfile } = this.props;
    unmountGetDetail()
    unmountGetProfile()
  }
}

const mapStateToProps = (state) => ({
  getData: state.transaction.detail,
  getProfile: state.candidate.detail
})

const mapDispatchToProps = {
  actionGetDetail         : detailTransaction,
  actionGetProfile        : detailCandidate,
  actionCancelTransaction : cancelTransaction,
  actionVerifyTransaction : verifyTransaction,
  actionRejectTransaction : rejectTransaction,
  unmountGetDetail        : unmountDetailTransaction,
  unmountGetProfile       : unmountDetailCandidate,
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailTransaction)
