import React from 'react'
import { Row, Col, Typography, Descriptions, Divider } from 'antd'

const { Text } = Typography

export default function Portfolio(props) {
  const { data } = props
  return (
    <Row gutter={16}>
      <Col span={24}>
        <Descriptions title="Portfolios" layout="vertical" size="small" column={3}>
        {
          data.listPortfolio.length > 0 ?
          <React.Fragment>
            {
              data.listPortfolio.map((item, i) => (
              <Descriptions.Item key={i}>
                <a href={`${item.url}`} target="_blank" rel='noreferrer'>
                  {item.url}
                </a> 
              </Descriptions.Item>
              ))
            }
          </React.Fragment>
          :
          <React.Fragment>
            <Text>N/A</Text>
            <Divider />
          </React.Fragment>
        }
        </Descriptions>
      </Col>
    </Row>
  )
}
