import React, { Component } from 'react';
import { Skeleton, Form, Row, Col, Input, Select } from 'antd';

export default class EditContact extends Component {

  render() {
    const { 
      getDetailCandidate: { data },
      formRef, 
      getCountry, 
      checkEmail,
      checkPhone,
      onSubmitInformation,
      handleChangeCallingCode
    } = this.props;

    if(getCountry.loading){
      return <Skeleton />
    }

    const mobileNumber = (
      <Form.Item 
        noStyle
        name="callingCodeId" 
        initialValue={data.callingCodeId ? data.callingCodeId : 99} 
        rules={[ {required: true, message: 'Please input your calling code'} ]}
      >
        <Select 
          showSearch
          className="prefix-selector"
          optionFilterProp="children"
          onChange={handleChangeCallingCode}
        >
          {
            getCountry?.data.map(item => (
              <Select.Option key={item.id} value={item.id}>
                {`+${item.callingCode}`}
              </Select.Option>
            ))
          }
        </Select>
      </Form.Item>
    );
    return (
      <React.Fragment>
        <Form layout="vertical" ref={formRef} onFinish={onSubmitInformation} scrollToFirstError={true}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item 
                label="Email" 
                name="email" 
                validateFirst
                initialValue={data.email ? data.email : null}
                rules={[
                  { required: true, message: 'This is a required field' },
                  { type: 'email', message: 'The input is not valid E-mail' }, 
                ]}
              >
                <Input type="email" onBlur={(e) => checkEmail(e.target.value)} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="Mobile Number" 
                name="mobilePhone"
                validateFirst
                initialValue={data.mobilePhone ? data.mobilePhone : null}
                rules={[
                  { required: true, message: 'This is a required field' },
                  { min: 4, message: 'Your mobile number is invalid' },
                  { max: 13, message: 'Your mobile number is invalid' },
                  { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers' },                
                ]}
              >
                <Input addonBefore={mobileNumber} onBlur={(e) => checkPhone(e.target.value)} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}
