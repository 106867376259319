import React, { Component } from 'react';
import { Row, Col, Descriptions, Skeleton } from 'antd';

export default class DetailContactDetail extends Component {

  componentDidMount() {
    const { actionGetContact } = this.props;
    return actionGetContact('employers')
  }

  render() {
    const { getContact: { loading, data } } = this.props;
    if(loading){
      return <Skeleton />
    }
    return (
      <React.Fragment>
        <Row gutter={16}>
          <Col span={24}>
            <Descriptions layout="vertical" column={2}>
              <Descriptions.Item label="Company Name">
                {data.name ? data.name : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Contact Person">
                {data.picName ? data.picName : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Contact Person Mobile Number">
                {data.mobilePhone ? `+${data.callingCode}${data.mobilePhone}` : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Contact Person Email">
                {data.picEmail ? data.picEmail : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Office Phone Number">
                {data.officePhone ? data.officePhone : 'N/A'}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}
