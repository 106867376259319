import { API } from '../../../config';
import { errorHandler } from '../auth/errorAction';

export const unmountListCourse = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_COURSE'})
}

export const unmountDetailCourse = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_COURSE'})
}

export const unmountEditCourse = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_EDIT_COURSE'})
}

// export const unmountMasterCOURSE = () => (dispatch) => {
//   return dispatch({type: 'UNMOUNT_DATA_MASTER_COURSE'})
// }

export const listCourse = (meta) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_COURSE' })
  return API.GET('/course/list', meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_COURSE_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_COURSE_FAILED' }), 
    ))
  })
}

export const detailCourse = (id, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DETAIL_COURSE' })
  const params = {
    'id': id
  }
  API.GET('/course/getDetailById', params).then((response) => {
    dispatch({ 
      type: 'LOAD_DETAIL_COURSE_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DETAIL_COURSE_FAILED' }), 
      ))
    )
  })
}

export const editCourse = (id, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_EDIT_COURSE' })
  const params = {
    'id': id
  }
  API.GET('/course/getById', params).then((response) => {
    dispatch({ 
      type: 'LOAD_EDIT_COURSE_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_EDIT_COURSE_FAILED' }), 
      ))
    )
  })
}

// export const masterCOURSE = (successCB, failedCB) => async (dispatch) => {
//   await dispatch({ type: 'LOAD_DATA_MASTER_COURSE' })
//   return API.GET('/employers_type/index').then((response) => {
//     dispatch({
//       type: 'LOAD_DATA_MASTER_COURSE_SUCCESS', 
//       payload: {
//         data: response.data,
//         pagination: {
//           page: null,
//           total: null,
//           perpage: null
//         }
//     }})
//     return successCB && successCB(response.data)
//   }).catch((err) => {
//     failedCB && failedCB()
//     return dispatch(errorHandler(
//       err, 
//       dispatch({ type: 'LOAD_DATA_MASTER_COURSE_FAILED' }), 
//     ))
//   })
// }

export const createCourse = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA_ARRAY('/course/add', value, 'createCourse').then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updateCourse = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA_ARRAY('/course/update', value, 'createCourse').then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const enableCourse = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/course/enable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const disableCourse = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/course/disable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const checkTicketCourse = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/xpert/ticket/checkTicket', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}