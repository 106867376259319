import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Loading } from '../../../../../components';
import { BankOutlined, FileTextOutlined } from '@ant-design/icons';
import { PageHeader, Card, Row, Col, Descriptions, Typography, Breadcrumb, Button, Modal, Image, Divider } from 'antd';
import { Link } from 'react-router-dom';
import { detailCandidatePathway, unmountDetailCandidatePathway } from '../../../../../redux/actions/candidatePathway/candidatePathwayAction';
import { detailCandidate } from '../../../../../redux/actions/candidate/candidateAction';
import ViewProfile from '../../../../../components/Modal/ApplicantProfile';
import { pathwayDescription } from '../../../../../utils/constant/pathwayProgram';

const { Text } = Typography;

class DetailCandidatePathway extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      visible: false
    }
  }
  
  componentDidMount() {
    const { actionGetDetail, match: { params } } = this.props;
    return actionGetDetail(params.id)
  }

  onPriviewProfile = (id) => {
    const { actionGetProfile } = this.props;
    return actionGetProfile(id, () => {
      this.setState({ visible: true })
    })
  }

  handleCancel = () => {
    this.setState({ visible: false })
  }

  render() {
    const { getData: { data, loading } } = this.props;
    const { visible } = this.state

    if(loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><BankOutlined /> Pathway</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/candidate-pathway">Candidates Pathway List</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{data.candidatePathwayNumber ? data.candidatePathwayNumber : '-'}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader 
              className="site-page-header" 
              title='Candidate Pathway Details'
              extra={[
                <Button style={{ width: '100%' }} className="btn-color-blue" type="primary" onClick={() => this.props.history.push(`/candidate-pathway/edit/${data.id}`)}>Update</Button>
              ]}
            />
          </Col>
          {/* Descriptions */}
          { data?.listPathwayForm?.length > 0 &&
            <Col span={24}>
              <Card>
                {
                  data?.listPathwayForm?.length > 0 && data?.listPathwayForm?.map((item) => {
                    return(
                      <Row gutter={16} key={item.id}>
                        <Col span={24} key={item.id}>
                          <Descriptions layout="vertical" column={1} size="small" key={item.id}>
                            <Descriptions.Item label={<Text type="secondary">Program</Text>}>
                              {item.program ? item.program : 'N/A'}
                            </Descriptions.Item>
                            <Descriptions.Item label={<Text type="secondary">Program Description</Text>}>
                              {pathwayDescription.find(res => res.value === item.program)?.name || 'N/A'}
                            </Descriptions.Item>
                            <Descriptions.Item label={<Text type="secondary">Title</Text>}>
                              {item.title ? item.title : 'N/A'}
                            </Descriptions.Item>
                            <Descriptions.Item label={<Text type="secondary">Notes to Candidate</Text>}>
                              {item.note ? item.note : 'N/A'}
                            </Descriptions.Item>
                            <Descriptions.Item label={<Text type="secondary">Attachment File</Text>}>
                              {
                                item.attachmentUrl === null ? 'N/A' :                                
                                  item.attachmentUrl.split('.').pop() === 'pdf' ?
                                  <React.Fragment>
                                    <a className="link" href={item.attachmentUrl} target="_blank" rel='noreferrer'>
                                      <FileTextOutlined style={{ fontSize: 50 }} /><br />
                                      {item.attachment}
                                    </a> 
                                  </React.Fragment>
                                  :
                                  <Image width={100} src={item.attachmentUrl} />
                              }
                            </Descriptions.Item>
                            <Descriptions.Item>
                              <Divider />
                            </Descriptions.Item>
                          </Descriptions>
                        </Col>
                      </Row>
                    )
                  })
                }
              </Card>
            </Col>
          }
        </Row>

        <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
          <Col span={24}>
            <Card>
              <Row gutter={[16, 16]}>
                <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Text style={{ fontSize: 16 }}>Assessment Result</Text>
                  <Button className="btn-color-blue" type="primary" onClick={() => this.onPriviewProfile(data.candidateId)} >View Profile</Button>
                </Col>
                <Divider />
                <Col span={24}>
                  <Text style={{ fontSize: 16 }}>Biografi</Text>
                </Col>
                <Col span={24}>
                  <Descriptions layout="vertical" column={2} size="small">
                    <Descriptions.Item label={<Text type="secondary">Nama Candidate</Text>}>
                      {data.name ? data.name : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Gender</Text>}>
                      {
                        data.gender ?
                          <React.Fragment>
                            {data.gender === "MALE" ? "Male" : null}
                            {data.gender === "FEMALE" ? "Female" : null}
                            {data.gender === "BOTH" ? "both" : null}
                          </React.Fragment>
                        : 'N/A'
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Tipe Penempatan yang Diminati</Text>}>
                      {
                        data.placementType ?
                          <React.Fragment>
                            {data.placementType === "INTERNATIONAL" ? "International" : null}
                            {data.placementType === "BOTH" ? "Both" : null}
                            {data.placementType === "DOMESTIC" ? "Domestic" : null}
                          </React.Fragment>
                        : 'N/A'
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Bidang Karir yang Diminati</Text>}>
                      {
                        (data?.listPathwayIndustry && data?.listPathwayIndustry.map(item => item.jobIndustryName).join(', ')) || 'N/A'
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Posisi Pekerjaan</Text>}>
                      {
                        (data?.listPathwayPosition && data?.listPathwayPosition.map(item => item.name).join(', ')) || 'N/A'
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Kota dan Negara Penempatan</Text>}>
                      {
                        (data?.listPathwayDestination && data?.listPathwayDestination.map(item => item.jobDestinationName).join(', ')) || 'N/A'
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Alasan Pemilihan Karir</Text>}>
                      {data?.reason ? data?.reason : 'N/A'}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal
          centered
          width={1000}
          visible={visible}
          title="Applicant Profile"
          onCancel={this.handleCancel}
          footer={
            <Button key="back" type="primary" onClick={this.handleCancel}>Okay</Button>
          }
        >
          <div style={{ height: 600, overflowY: 'auto', overflowX: 'hidden' }}>
            <ViewProfile {...this.props} />
          </div>
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail } = this.props;
    return unmountGetDetail()
  }
}

const mapStateToProps = (state) => ({
  getData         : state.candidatePathway.detail,
  getProfile      : state.candidate.detail
})

const mapDispatchToProps = {
  actionGetDetail       : detailCandidatePathway,
  unmountGetDetail      : unmountDetailCandidatePathway,
  actionGetProfile      : detailCandidate,
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailCandidatePathway)
