import React, { Component } from 'react';
import moment from 'moment';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Form, Row, Col, Radio, DatePicker, TimePicker, Button, Space, Typography, Divider } from 'antd';
import HeaderActiveApplicant from '../../../../../../../../../components/ActiveApplicants/Header'
const { Text } = Typography

export default class ContentShortlisted extends Component {

  disabledDate = (current) => {
    let yesterday = moment().subtract(1, 'days');
    return !current || current.isBefore(yesterday);
  }

  onFinish = (values) => {
    const { onFinish } = this.props;
    return onFinish(values)
  }

  render() {
    const { dataApplicant, openModalProfile, formRef, isResult, isMethod, handleChangeMethod, showModalNotes, showModalReject, showModalHistory } = this.props;
    return (
      <React.Fragment>
        <HeaderActiveApplicant dataApplicant={dataApplicant} openModalProfile={openModalProfile} showModalNotes={showModalNotes} showModalHistory={showModalHistory} />
        <Divider />
        <Form onFinish={this.onFinish} layout="vertical" ref={formRef}>
          <Row gutter={16}>
            <Col span={24} style={{ marginBottom: 16 }}>
              <Text>Please complete the information below to continue with the assessment process.</Text>
            </Col>
            {
              isResult ?
                <React.Fragment>
                  <Col span={12}>
                    <Form.Item 
                      name="isMethod" 
                      label="Method" 
                      initialValue={true}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Radio.Group onChange={handleChangeMethod}>
                        <Radio value={true}>Direct</Radio>
                        <Radio value={false}>Virtual</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item 
                      label="Assessment Date"
                      name="assessmentDate"
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <DatePicker 
                        format="DD/MM/YYYY"
                        style={{ width: '100%' }}
                        disabledDate={this.disabledDate}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item 
                      label="Assessment Time"
                      name="assessmentTime"
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <TimePicker style={{ width: '100%' }} format="HH:mm" />
                    </Form.Item>
                  </Col>
                  {
                    isMethod ?
                      <Col span={24}>
                        <Form.Item 
                          name="location" 
                          label="Location"
                          initialValue={null}
                          rules={[
                            { required: isMethod, message: 'This is a required field' },
                          ]}
                        >
                          <ReactQuill />
                        </Form.Item>
                      </Col>
                    :
                      <Col span={24}>
                        <Form.Item 
                          name="virtualLink" 
                          label="Virtual Link"
                          initialValue={null}
                          rules={[
                            { required: !isMethod, message: 'This is a required field' },
                            { pattern: /^\S*$/, message: 'No spaces allowed' },
                            { pattern: new RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi), message: 'Invalid url' },
                          ]}
                        >
                          <ReactQuill />
                        </Form.Item>
                      </Col>
                  }
                </React.Fragment>
              : null
            }
            
            <Col span={24}>
              <Form.Item 
                name="notes" 
                label="Notes to Applicant (optional)" 
                initialValue={null}
                rules={[
                  { required: !isResult, message: 'This is a required field' },
                ]}
              >
                <ReactQuill />
              </Form.Item>
            </Col>
            
            {/* <Col span={24}>
              <Card style={{ background: '#EBF3FF', borderColor: '#EBF3FF' }}>
                <Space direction='vertical'>
                  <Text strong>Applicant doesn't meet the criteria?</Text>
                  <Text>Give them an opportunity for development. The development process helps applicants enhance their qualifications through comprehensive training. Click the button below to initiate the applicant development process and help them reach their full potential</Text>
                  <Button type='primary' ghost className='btn-radius' onClick={() => showModalDev(dataApplicant.id)}>Process for Development</Button>
                </Space>
              </Card>
            </Col> */}

            <Col span={24} style={{ marginTop: 16 }}>
              <Space style={{ float: 'right' }}>
                <Button className='btn-radius' danger onClick={() => showModalReject(dataApplicant.id)}>Reject Applicant</Button>
                <Button className='btn-radius' type="primary" htmlType="submit">Proceed to Next Stage</Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}