import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UserOutlined, ReadOutlined, HighlightOutlined, SyncOutlined, UserAddOutlined } from '@ant-design/icons';
import { Row, Col, Button, Steps, Modal, Form , Space, Divider, message } from 'antd';
import { createNewCandidate } from '../../../../redux/actions/newCandidate/newCandidateAction';
import { candidateByEmail, candidateByPhone } from '../../../../redux/actions/jobs/jobsAction';
import PersonalInfo from './personalInfo'
import Education from './education'
import Review from './review'
import moment from 'moment';

const { Step } = Steps;

class CreateNewCandidate extends Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)
  
    this.state = {
      url : 'job_agency',
      visible: false,
      submitLoading: false,
      current: 0,
      isCandidate: false,
      dataCandidate: null,
      loadingCheckCandidate: false,
      callingCode: '62',
      callingCodeId: 99,
      birthdate: "",
      nationalityId: 99,
      nationality: 'Indonesian',
      countryName: null,
      countryId: null,
      provinceName: null,
      provinceId: null,
      cityName: null,
      cityId: null,
      subDistrictName: null,
      subDistrictId: null,
      villageName: null,
      villageId: null,

      educationLevelName: "",
      educationLevelId: "",
      isYear: false,
      startYear: "",
      endYear: "",

      valuesContact: null,
      valuesPersonal: null,
      valuesEducation: null,
      valuesNotes: null,
      meta: {
        page: 1,
        perpage: 20
      },
    }
  }

  showModal = () => {
    this.setState({ visible: true, isCandidate: false, current: 0, dataCandidate: null, valuesContact: null, valuesPersonal: null, valuesEducation: null })
  }

  handleCancel = () => {
    this.setState({ visible: false, isCandidate: false, current: 0, dataCandidate: null, showModal: true })
  }

  handleCheckEmail = (e) => {
    const { actionCheckEmail } = this.props
    const { url } = this.state
    this.setState({ loadingCheckCandidate: true })
    return actionCheckEmail(e, url, response => {
      const { code, data } = response
      
      if(code === "1000"){
        this.setState({ 
          callingCodeId: data.callingCodeId, 
          isCandidate: true, 
          dataCandidate: data, 
          loadingCheckCandidate: false, 
          countryName: data.countryName, 
          provinceName: data.provinceName,
          cityName: data.cityName,
          subDistrictName: data.subDistrictName,
          villageName: data.villageName
        })
        this.formRef.current.setFieldsValue({
          callingCodeId: `+${data.callingCode}`,
          mobilePhone: data.mobilePhone,
          firstName: data.firstName,
          lastName: data.lastName,
          birthdate: data.birthdate ? moment(data.birthdate, 'DD-MM-YYYY') : "",
          gender: data.gender,
          placeOfBirth: data.placeOfBirth,
          nationality: data.nationality,
          marital: data.marital,
          bloodType: data.bloodType,
          height: data.height,
          weight: data.weight,
          identificationId: data.identificationId,
          religion: data.religion,
          country: data.countryName,
          province: data.provinceName,
          city: data.cityName,
          subDistrict: data.subDistrictName,
          village: data.villageName,
          zipCode: data.zipCode,
          address: data.address,
          candidateSource : data.candidateSource,
          registerOwner : data.registerOwner
        })
        message.error('Email already exist!')
      }else{
        this.setState({ loadingCheckCandidate: false })
      }
    })
  }

  handleCheckMobile = (e) => {
    const { actionCheckMobile } = this.props
    const { url, callingCode } = this.state
    this.setState({ loadingCheckCandidate: true })
    this.formRef.current.setFieldsValue({
      mobilePhone: e.replace(/^0+/, '')
    })

    e = `${callingCode}${e.replace(/^0+/, '')}`

    return actionCheckMobile(e, url, response => {
      const { code, data } = response
      if(code === "1000"){
        this.setState({ 
          callingCodeId: data.callingCodeId, 
          isCandidate: true, 
          dataCandidate: data, 
          loadingCheckCandidate: false, 
          countryName: data.countryName, 
          provinceName: data.provinceName,
          cityName: data.cityName,
          subDistrictName: data.subDistrictName,
          villageName: data.villageName
        })
        this.formRef.current.setFieldsValue({
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          birthdate: moment(data.birthdate),
          gender: data.gender,
          placeOfBirth: data.placeOfBirth,
          nationality: data.nationality,
          marital: data.marital,
          bloodType: data.bloodType,
          height: data.height,
          weight: data.weight,
          identificationId: data.identificationId,
          religion: data.religion,
          country: data.countryName,
          province: data.provinceName,
          city: data.cityName,
          subDistrict: data.subDistrictName,
          village: data.villageName,
          zipCode: data.zipCode,
          address: data.address,
          candidateSource : data.candidateSource,
          registerOwner : data.registerOwner
        })
        message.error('Mobile Number already exist!')
      }else{
        this.setState({ loadingCheckCandidate: false })
      }
    })
  }

  handleCallingCode = (value, option) => {
    const optionSelected = Number(option.key)
    
    this.setState({
      textCode: value,
      callingCode: option.text,
      callingCodeId: optionSelected
    })
  }

  handleNationality = (value, option) => {
    const optionSelected = Number(option.key)
    
    this.setState({
      nationality: value,
      nationalityId: optionSelected
    })
  }

  handleCountry = (value, option) => {
    const { provinceId } = this.state
    const optionSelected = Number(option.key)
    
    this.setState({
      countryName: value,
      countryId: optionSelected,
      selectedCountry: optionSelected,
    })

    if(provinceId !== 0){
      this.setState({
        provinceId: null,
        cityId: null,
        subDistrictId: null,
        villageId: null
      })

      this.formRef.current.setFieldsValue({
        province: null,
        city: null,
        subDistrict: null,
        village: null
      })
    }
  }

  handleProvince = (value, option) => {
    const { cityId } = this.state
    const optionSelected = Number(option.key)

    this.setState({
      provinceName: value,
      provinceId: optionSelected
    })

    if(cityId !== 0){
      this.setState({
        cityId: null,
        subDistrictId: null,
        villageId: null
      })

      this.formRef.current.setFieldsValue({
        city: null,
        subDistrict: null,
        village: null
      })
    }
  }

  handleCity = (value, option) => {
    const { subDistrictId } = this.state
    const optionSelected = Number(option.key)
    
    this.setState({
      cityName: value,
      cityId: optionSelected
    })

    if(subDistrictId !== 0){
      this.setState({
        subDistrictId: null,
        villageId: null
      })

      this.formRef.current.setFieldsValue({
        subDistrict: null,
        village: null
      })
    }
  }

  handleSubDistrict = (value, option) => {
    const { villageId } = this.state
    const optionSelected = Number(option.key)
    
    this.setState({
      subDistrictName: value,
      subDistrictId: optionSelected
    })

    if(villageId !== 0){
      this.setState({
        villageId: null
      })

      this.formRef.current.setFieldsValue({
        village: null
      })
    }
  }

  handleVillage = (value, option) => {
    const optionSelected = Number(option.key)
    
    this.setState({
      villageName: value,
      villageId: optionSelected
    })
  }

  onNext = async (values) => {
    const { current, dataCandidate, callingCodeId, callingCode, birthdate, nationalityId, countryId, provinceId, cityId, subDistrictId, villageId, educationLevelId, startYear, endYear } = this.state
    if(current === 0){
      values.callingCodeId = callingCodeId
      values.mobilePhone = 
        values.mobilePhone.charAt(0) === '0' ? 
          `${callingCode}${values.mobilePhone.substring(1)}`
        : values.mobilePhone.substr(0, 2) === '62' ?
          `${callingCode}${values.mobilePhone.substring(2)}`
        : 
          `${callingCode}${values.mobilePhone}`
      values.birthdate = birthdate ? birthdate : dataCandidate?.birthdate
      values.nationalityId = nationalityId? nationalityId : dataCandidate?.nationalityId
      values.countryId = countryId ? countryId : dataCandidate?.countryId
      values.provinceId = provinceId ? provinceId : dataCandidate?.provinceId
      values.cityId = cityId ? cityId : dataCandidate?.cityId
      values.subDistrictId = subDistrictId ? subDistrictId : dataCandidate?.subDistrictId
      values.villageId = villageId ? villageId : dataCandidate?.villageId
      this.setState({
        current: current + 1,
        valuesContact: {
          email: values.email,
          callingCodeId: values.callingCodeId,
          mobilePhone: values.mobilePhone,
          firstName: values.firstName,
          lastName: values.lastName
        },
        valuesPersonal: 
          dataCandidate ?
            {
              candidateId: dataCandidate.id,
              birthdate: values.birthdate,
              gender: values.gender,
              placeOfBirth: values.placeOfBirth ? values.placeOfBirth : dataCandidate.placeOfBirth,
              nationalityId: values.nationalityId,
              marital: values.marital,
              bloodType: values.bloodType,
              height: values.height ? values.height : dataCandidate.height,
              weight: values.weight ? values.weight : dataCandidate.weight,
              identificationId: values.identificationId ? values.identificationId : dataCandidate.identificationId,
              religion: values.religion,
              countryId: values.countryId,
              provinceId: values.provinceId,
              cityId: values.cityId,
              subDistrictId: values.subDistrictId,
              villageId: values.villageId,
              zipCode: values.zipCode ? values.zipCode : dataCandidate.zipCode,
              address: values.address ? values.address : dataCandidate.address,
              candidateSource: values.candidateSource,
              registerOwner: values.registerOwner ? values.registerOwner : dataCandidate.registerOwner,
            }
          :
            {
              birthdate: values.birthdate,
              gender: values.gender,
              placeOfBirth: values.placeOfBirth,
              nationalityId: values.nationalityId,
              marital: values.marital,
              bloodType: values.bloodType,
              height: values.height,
              weight: values.weight,
              identificationId: values.identificationId,
              religion: values.religion,
              countryId: values.countryId,
              provinceId: values.provinceId,
              cityId: values.cityId,
              subDistrictId: values.subDistrictId,
              villageId: values.villageId,
              zipCode: values.zipCode,
              address: values.address,
              candidateSource: values.candidateSource,
              registerOwner: values.registerOwner,
            }
      })
    }

    if(current === 1){
      values.educationLevelId = educationLevelId
      values.educationStartYear = startYear
      values.educationEndYear = endYear
      this.setState({
        current: current + 1,
        valuesEducation: dataCandidate?.listEducation?.length > 0 ? null : values,
      })
    }
  }

  onFinish = () => {
    const { actionCreateNewCandidate, actionGetData  } = this.props
    const { valuesContact, valuesPersonal, valuesEducation, meta } = this.state

    const objVal = {...valuesContact, ...valuesPersonal, ...valuesEducation} 
    
    return this.setState({ submitLoading: true }, () => {
      return actionCreateNewCandidate(objVal, response => {
        if(response.code === '1000'){
          setTimeout(() => {
            this.setState({ submitLoading: false, visible: false })
            message.success('New Candidate Success!')
          }, actionGetData(meta), 3000)
        }else{
          return this.setState({ submitLoading: false, visible: false, isCandidate: false, current: 0, dataCandidate: null }, () => message.error(response.message))
        }
      }, (err) => {
        return this.setState({ submitLoading: false, visible: false }, () => message.error(err))
      })
    })
  }

  onPrev = () => {
    const { current } = this.state
    this.setState({
      current: current - 1
    })
  }

  handleResetFields = () => {
    this.formRef.current.resetFields()
    this.setState({ isCandidate: false, current: 0, dataCandidate: null })
    
  }

  onChangeDate = (date, dateString) => {
    this.setState({
      birthdate: dateString
    })
  }

  onChangeEducationLevel = (value, option) => {
    const optionSelected = Number(option.key)

    this.setState({
      educationLevelName: value,
      educationLevelId: optionSelected
    })
  }

  onChangeStartYear = (date, dateString) => {
    const { endYear } = this.state

    this.setState({
      startYear: dateString
    })
    
    if(endYear < dateString){
      this.setState({
        isYear: true
      })
    }else{
      this.setState({
        isYear: false
      })
    }
  }

  onChangeEndYear = (date, dateString) => {
    const { startYear } = this.state

    this.setState({
      endYear: dateString
    })

    if(dateString < startYear){
      this.setState({
        isYear: true
      })
    }else{
      this.setState({
        isYear: false
      })
    }
  }

  handleEdit = (value) => {
    this.setState({
     current: value
    })
  }


  render() {
    const { visible, url, callingCode, submitLoading,current, isCandidate, dataCandidate, valuesContact, valuesPersonal, valuesEducation, nationality, isYear, countryName, provinceName, cityName, subDistrictName, villageName, educationLevelName, loadingCheckCandidate } = this.state

    const initialProps = {
      url: url,
      current : current,
      isCandidate: isCandidate,
      dataCandidate: dataCandidate,
      callingCode: callingCode,
      handleCheckEmail: this.handleCheckEmail,
      handleCheckMobile: this.handleCheckMobile,
      handleCallingCode: this.handleCallingCode,
      onChangeDate: this.onChangeDate,
      handleNationality: this.handleNationality,
      handleCountry: this.handleCountry,
      handleProvince: this.handleProvince,
      handleCity: this.handleCity,
      handleSubDistrict: this.handleSubDistrict,
      handleVillage: this.handleVillage,
      onChangeEducationLevel: this.onChangeEducationLevel,
      onChangeStartYear: this.onChangeStartYear,
      onChangeEndYear: this.onChangeEndYear,
      valuesContact: valuesContact,
      valuesPersonal: valuesPersonal,
      valuesEducation: valuesEducation,
      nationality: nationality,
      isYear: isYear,
      countryName: countryName,
      provinceName: provinceName,
      cityName: cityName,
      subDistrictName: subDistrictName,
      villageName: villageName,
      educationLevelName: educationLevelName,
      loadingCheckCandidate: loadingCheckCandidate,
      handleEdit : this.handleEdit,
      onFinish: this.onFinish
    }

    return (
      <React.Fragment>
        <Button 
          className="btn-create-primary" 
          type="primary" 
          onClick={this.showModal}
          ghost
          style={{ width: '100%' }}
        >
          Add New Candidate <UserAddOutlined />
        </Button>
        <Modal 
          width={1000} 
          title='Create Candidate'
          visible={visible}
          onCancel={this.handleCancel}
          footer={false}
          destroyOnClose
          style={{ top: 20 }}
        >
          <Steps 
            size="small"
            labelPlacement='vertical'
            current={current} 
            style={{ marginBottom: 16 }}>
            <Step key="1" title="Personal Info" icon={<UserOutlined />} />
            <Step key="2" title="Education" icon={<ReadOutlined />} />
            <Step key="3" title="Review" icon={<HighlightOutlined />} />
          </Steps>
          <Form 
            layout="vertical"
            ref={this.formRef}
            onFinish={
              current === 2 ? 
                this.onFinish 
              : 
                this.onNext
            }
          >
            {
              current === 0 ?
                <PersonalInfo {...initialProps} />
              :
                null
            }
            {
              current === 1 ?
                <Education {...initialProps} />
              :
                null
            }
            {
              current === 2 ?
                <Review {...initialProps} />
              :
                null
            }
            <Divider />
            <Row>
              <Col span={12}>
                <Button key='reset' onClick={this.handleResetFields} > 
                {
                  current === 2 ? <SyncOutlined style={{color: '#f5222d'}} /> : null

                }
                  {
                    current  === 2 ? 
                     'Reset Field' 
                    : null
                  }
                  
                </Button>
              </Col>
              <Col span={12}>
                <Space style={{ float: 'right' }}>
                  {
                    current === 1 ?
                      <Button key="back" type="link" onClick={this.onPrev}>
                        Back
                      </Button>
                    :
                      null
                  }
                  <Button key="next" type="primary" htmlType="submit" loading={submitLoading}>
                    {
                      current === 1 ?
                        'Review'
                      :
                      current === 2 ? 
                        'Submit' 
                      :
                        'Next' 
                    }
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
  actionCheckEmail            : candidateByEmail,
  actionCheckMobile           : candidateByPhone,
  actionCreateNewCandidate    : createNewCandidate,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewCandidate)