import React from 'react';
import moment from 'moment';
import { Typography, Tag, Dropdown, Menu } from 'antd';
import { EditOutlined, StopOutlined, UnorderedListOutlined, CheckCircleOutlined } from '@ant-design/icons';
const { Text } = Typography;

const menu = (record, onClickEdit, handleEnable, handleDisable) => (
  <Menu>
    <Menu.Item icon={<EditOutlined />} onClick={(e) => {e.domEvent.stopPropagation(); onClickEdit(record.id)}}>
      Edit                              
    </Menu.Item>
    {
      record.enabled === true ?
      <Menu.Item icon={<StopOutlined />} onClick={(e) => {e.domEvent.stopPropagation(); handleDisable(record.id)}}>
        Set as inactive
      </Menu.Item>
      :
      <Menu.Item icon={<CheckCircleOutlined />} onClick={(e) => {e.domEvent.stopPropagation(); handleEnable(record.id)}}>
        Set as active
      </Menu.Item>
    }
  </Menu>
);

export const columns = (sortedInfo, onClickEdit, handleEnable, handleDisable) => {
  return [
    {
      title: 'Number',
      key: 'number',
      fixed: 'left',
      width: 110,
      render: (record) => (
        <Text className="link">
          {record.number}
        </Text>
      )
    },
    {
      title: 'Created Date',
      key: 'createdDate',
      width: 100,
      render: (record) => (
        <Text>
          {moment(record.createdDate).format('DD MMM YYYY HH:MM')}
        </Text>
      )
    },
    {
      title: 'Name',
      key: 'name',
      width: 150,
      render: (record) => (
        <Text>
          {record.name ? record.name : '-'}
        </Text>
      )
    },
    {
      title: 'Email',
      key: 'email',
      width: 110,
      render: (record) => (
        <Text>
          {record.email ? record.email : '-'}
        </Text>
      )
    },
    {
      title: 'Industry Specialization',
      key: 'industrySpecialization',
      width: 100,
      render: (record) => (
        <Text>
          {record.categoryName ? record.categoryName : '-'}
        </Text>
      )
    },
    {
      title: 'Status',
      key: 'status',
      width: 50,
      render: (record) => (
        <React.Fragment>
          {
            record.enabled === true ? 
            <Tag color="green">Active</Tag>
            : record.enabled === false ?
            <Tag color="orange">Inactive</Tag>
            : <Tag color="blue">Draft</Tag>
          }
        </React.Fragment>
      )
    },
    {
      title: '',
      key: 'action',
      width: 30,
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <React.Fragment>
          {
            record.status === 'DRAFT' ?
            <EditOutlined style={{ fontSize: 20 }} onClick={() => onClickEdit(record.id)}/>
            :
            <Dropdown overlay={menu(record, onClickEdit, handleEnable, handleDisable)} trigger={['click']} placement="bottomCenter" arrow>
              <UnorderedListOutlined style={{ fontSize: 20 }} />
            </Dropdown>
          }
        </React.Fragment>
      )
    },
  ]
}