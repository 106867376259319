import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Typography, Tooltip, Tag } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
const { Text } = Typography;

export const columns = (sortedInfo, onClickEdit, handleRoute) => {
  return [
    {
      title: 'Number',
      key: 'number',
      fixed: 'left',
      width: 110,
      render: (record) => (
        <Link className="link" to={`/employers-inquiries/detail/${record.id}`}>
          {record.employersRegisterNumber}
        </Link>
      )
    },
    {
      title: 'Register Date',
      key: 'createdDate',
      width: 100,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {moment(record.createdDate).format('DD MMM YYYY')}
        </Text>
      )
    },
    {
      title: 'Employers Name',
      key: 'name',
      // fixed: 'left',
      // sorter: (a, b) => a.name - b.name,
      // sortOrder: sortedInfo ? sortedInfo.columnKey === 'name' && sortedInfo.order : null,
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          <Tooltip title={record.name}>
            {record.name ? record.name : '-'}
          </Tooltip>
        </Text>
      )
    },
    {
      title: 'Email',
      key: 'email',
      width: 130,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.email ? record.email : '-'}
        </Text>
      )
    },
    {
      title: 'Contact Person',
      key: 'contactPerson',
      width: 110,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.picName ? record.picName : '-'}
        </Text>
      )
    },
    {
      title: 'Mobile Number',
      key: 'mobileNumber',
      width: 120,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {`+${record.callingCode}${record.picMobilePhone}`}
        </Text>
      )
    },
    {
      title: 'Status',
      key: 'status',
      width: 60,
      render: (record) => (
        <React.Fragment>
          {
            record.status === 'NEED_APPROVAL' ? 
            <Tag color="blue">Need Approval</Tag>
            : record.status === 'ACTIVE' ?
            <Tag color="green">Active</Tag>
            : <Tag color="red">Rejected</Tag>
          }
        </React.Fragment>
      )
    },
    {
      title: '',
      key: 'action',
      width: 30,
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <React.Fragment>
        {
          record.status === 'NEED_APPROVAL' ?
            <Tooltip title="Proccess" arrowPointAtCenter>
              <ArrowRightOutlined style={{ fontSize: 20 }} onClick={() => onClickEdit(record.id)}/>
            </Tooltip>
          : null
        }
        </React.Fragment>
      )
    },
  ]
}