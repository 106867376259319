import jsPDF from 'jspdf';
import moment from 'moment';
import KopSurat from '../assets/img/kop_surat.png';
// import Signature from '../assets/img/ttdis.png';
import { message } from 'antd';

export const downloadSuket = (data) => {
  const dataEducation = data?.listEducation?.find(item => item.name.includes('binawan') || item.name.includes('Binawan') || item.name.includes('BINAWAN'))
  if(dataEducation && dataEducation.fieldOfStudy){
    const arrEducationLevel = dataEducation?.educationLevelName.split(' ')
    const educationLevelName = arrEducationLevel[0]
    const roman = 
      moment().format("M") === "1" ?
        "I"
      : moment().format("M") === "2" ?
        "II"
      : moment().format("M") === "3" ?
        "III"
      : moment().format("M") === "4" ?
        "IV"
      : moment().format("M") === "5" ?
        "V"
      : moment().format("M") === "6" ?
        "VI"
      : moment().format("M") === "7" ?
        "VII"
      : moment().format("M") === "8" ?
        "VIII"
      : moment().format("M") === "9" ?
        "IX"
      : moment().format("M") === "10" ?
        "X"
      : moment().format("M") === "11" ?
        "XI"
      : moment().format("M") === "12" ?
        "XII"
      : null

    const doc = new jsPDF('p','pt','a4', true)
    doc.addImage(KopSurat, 'PNG', 10, 10, 580, 820)
    
    doc.setFontSize(11)
    doc.setFont('helvetica','','normal')
    doc.text(415, 120, `Jakarta, ${moment().format('LL')}`)

    doc.setFont('helvetica','','bold')
    doc.text(250, 160, 'CERTIFICATE')

    doc.setFont('helvetica','','normal')
    doc.text(80, 200, 'No:')
    doc.text(99, 200, `${data.id}/S-Ket/UBN.REK/${roman}/${moment().format('YYYY')}`)

    doc.setFont('helvetica','','normal')
    doc.text(80, 240, 'I am the undersigned:')
    doc.text(80, 270, 'The name is Prof. Henny Suzana Mediani, SKp, M.Ng, PhD')
    doc.text(80, 300, 'Position of On-duty Rector of Binawan University')
    doc.text(80, 330, `Explaining that ${data.fullName} is true as a student of the`)
    doc.text(80, 345, `${educationLevelName} of ${dataEducation?.fieldOfStudy ? dataEducation?.fieldOfStudy : '........................................'}, Binawan University.`)

    doc.text(80, 390, 'This certificate is used properly,')
    doc.text(80, 420, 'On-duty Rector of Binawan University')
    // doc.addImage(Signature, 'PNG', 110, 420, 80, 80)
    doc.text(80, 505, 'Prof. Henny Suzana Mediani, SKp, M.Ng, PhD')

    doc.save(`${data.candidateNumber} - Surat Keterangan Mahasiswa Aktif.pdf`);
    message.success('Download success!')
  }else{
    message.error('Data is not complete. Please contact the candidate!')
  }
}