import React, { Component } from 'react';
import { columns } from './columns';
import { connect } from 'react-redux';
import { Row, Col, Typography, Modal, Table, Pagination, Space, Button, message } from 'antd';
import { detailCandidate, unmountDetailCandidate } from '../../../../../../../../redux/actions/candidate/candidateAction';
import { nextPhase, rejectApplicant, addApplicantNotes, listApplicantNotes, unmountApplicantNotes } from '../../../../../../../../redux/actions/jobs/jobsAction';
import { SearchDetailJob, FilterSiderDetailJob } from '../../../../../../../../components';
import ViewProfile from '../../../../../../../../components/Modal/ApplicantProfile';
import ApplicantNotes from '../../../../../../../../components/Modal/ApplicantNotes';
import ApplicationRejection from '../../../../../../../../components/Modal/ApplicationRejection';
import DownloadFile from '../../../../../../../../components/DownloadFiles';

const { confirm } = Modal;
const { Text } = Typography;

class DetailAppliedEmployerJobs extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      visible: false,
      isModalReject: false,
      selectedRowKeys: [],
      idApplicant: null,
      documentName: null,
      isModalNotes: false,
      submitLoading: false
    }
  }

  modalNextStage = (id) => {
    const self = this;
    const { selectedRowKeys } = this.state;
    const { meta, url, actionNextPhase, actionListApplied } = this.props;
    const idApplicant = selectedRowKeys.length > 0 ? selectedRowKeys.join() : id
    confirm({
      title: 'The application will be processed to the next stage.',
      content: 'Are you sure?',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        return actionNextPhase(idApplicant, url, () => {
          message.success('Successfully moved')
          actionListApplied(meta, url)
          self.setState({ selectedRowKeys: [] })
        }, (err) => message.error(err))
      },
    });
  }

  showModal = (id) => {
    this.setState({ isModalReject: true, idApplicant: id })
  };

  showModalNotes = (id) => {
    const { actionListApplicantNotes } = this.props
    actionListApplicantNotes(id)
    this.setState({ isModalNotes: true, idApplicant: id })
  };
  
  handleCancel = () => {
    this.setState({ visible: false, isModalReject: false, isModalNotes: false })
  };

  onFinishReject = value => {
    const { selectedRowKeys, idApplicant } = this.state;
    const { meta, url, actionReject, actionListApplied } = this.props;
    const _idApplicant = selectedRowKeys.length > 0 ? selectedRowKeys : [idApplicant]

    value.id = _idApplicant

    this.setState({ submitLoading: true })
    return actionReject(value, url, () => {
      message.success('Successfully moved')
      actionListApplied(meta, url)
      this.setState({ selectedRowKeys: [], isModalReject: false, submitLoading: false })
    }, (err) => message.error(err))
  }

  onFinishNotes = value => {
    const { idApplicant } = this.state
    const { meta, url, actionAddApplicantNotes, actionListApplicantNotes, actionListApplied } = this.props;
    
    value.jobAppliedId = idApplicant

    return actionAddApplicantNotes(value, url, () => {
      message.success('Successfully update applicant notes')
      actionListApplicantNotes(idApplicant)
      actionListApplied(meta, url)
    }, (err) => {
      this.setState({ submitLoading: false }, () => {
        message.error(err)
      })
    })
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  onPriviewProfile = (id) => {
    const { actionGetProfile, getApplied } = this.props;
    const findCandidate = getApplied.data.find(item => item.candidateId === id)
    this.setState({
      documentName: `${findCandidate.applicantNumber}-${findCandidate.name}`
    })
    return actionGetProfile(id, () => {
      this.setState({ visible: true })
    })
  }

  render() {
    const { selectedRowKeys, visible, isModalReject, documentName, isModalNotes, submitLoading } = this.state;
    const { getData: { data }, getApplied, getProfile, onSearch, onFilter, pagination } = this.props;
    
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    return (
      <React.Fragment>
        <Row gutter={[16, 24]}>
          {/* Content */}
          <Col span={24}>
            <SearchDetailJob onSearch={onSearch} />
          </Col>
          {
            hasSelected ?
              <React.Fragment>
                <Col span={24}>
                  <Space direction="vertical">
                    <Text>Selected applicants count: <Text strong>{selectedRowKeys.length}</Text></Text>
                    <Space>
                      <Text>Move To:</Text>
                      <Button onClick={this.showModal}>Rejected</Button>
                      <Button type="primary" onClick={this.modalNextStage}>Shortlisted</Button>
                    </Space>
                  </Space>
                </Col>
              </React.Fragment>
            :
              null
          }
          {/* Filter */}
          <Col span={6}>
            <FilterSiderDetailJob onFilter={onFilter} />
          </Col>
          {/* Table */}
          <Col span={18}>
            <Table 
              size="small"
              style={{ marginBottom: 20 }}
              rowKey={(i) => i.id} 
              dataSource={getApplied.data} 
              loading={getApplied.loading} 
              columns={columns(this.onPriviewProfile, this.modalNextStage, this.showModal, this.showModalNotes)} 
              pagination={false}
              scroll={{ x: 900 }}
              rowSelection={rowSelection}
            />
            {/* Pagination */}
            <Pagination
              total={getApplied.pagination.total}
              onChange={pagination}
              current={getApplied.pagination.page}
              pageSize={getApplied.pagination.perpage}
              showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total} Data`}
            />
          </Col>
        </Row>
        <Modal 
          centered
          width={1000}
          visible={visible}
          title="Applicant Profile"
          onCancel={this.handleCancel}
          footer={
            <>
              <DownloadFile data={data} getProfile={getProfile} documentName={documentName} />
              <Button key="back" type="primary" onClick={this.handleCancel}>Okay</Button>
            </>
          }
        >
          <div style={{ height: 430, overflowY: 'auto', overflowX: 'hidden', padding: 32 }}>
            <ViewProfile {...this.props} />
          </div>
        </Modal>
        <Modal title="Application Rejection" visible={isModalReject} onCancel={this.handleCancel} footer={null} destroyOnClose>
          <ApplicationRejection {...this.props} submitLoading={submitLoading} notes="rejectReason" onFinishReject={this.onFinishReject} />
        </Modal>
        <Modal centered width={800} title="Applicant Notes" visible={isModalNotes} onCancel={this.handleCancel} footer={null} destroyOnClose>
          <ApplicantNotes {...this.props} onFinishNotes={this.onFinishNotes} />
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetProfile, unmountApplicantNotes } = this.props;
    unmountGetProfile()
    unmountApplicantNotes()
  }
}

const mapStateToProps = (state) => ({
  getApplied        : state.jobs.applied,
  getProfile        : state.candidate.detail,
  getApplicantNotes : state.jobs.listApplicantNotes
})

const mapDispatchToProps = {
  actionNextPhase           : nextPhase,
  actionReject              : rejectApplicant,
  actionGetProfile          : detailCandidate,
  actionAddApplicantNotes   : addApplicantNotes,
  actionListApplicantNotes  : listApplicantNotes,
  unmountGetProfile         : unmountDetailCandidate,
  unmountApplicantNotes     : unmountApplicantNotes
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailAppliedEmployerJobs)
