import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { UserOutlined, PhoneOutlined, LikeOutlined, ProjectOutlined, BulbOutlined, IdcardOutlined, FormatPainterOutlined, FolderOutlined, TrophyOutlined, FileTextOutlined, LockOutlined, BarChartOutlined, GlobalOutlined, SkinOutlined } from '@ant-design/icons';

export default function index(props) {
  const status = localStorage.getItem("status");
  return (
    
    <Menu defaultSelectedKeys={[props.rowKey]} mode="inline">
      <Menu.Item key="biodata" icon={<UserOutlined />}>
        <Link to={`/${status}/detail/${props.id}/biodata`}>
          Biodata
        </Link>
      </Menu.Item>
      <Menu.Item key="contactInformation" icon={<PhoneOutlined />}>
        <Link to={`/${status}/detail/${props.id}/contact-information`}>
          Contact Information
        </Link>
      </Menu.Item>
      <Menu.Item key="jobPreferences" icon={<LikeOutlined />}>
        <Link to={`/${status}/detail/${props.id}/job-preferences`}>
          Job Preferences
        </Link>
      </Menu.Item>
      <Menu.Item key="documents" icon={<FileTextOutlined />}>
        <Link to={`/${status}/detail/${props.id}/documents`}>
          Documents
        </Link>
      </Menu.Item>
      <Menu.Item key="workExperience" icon={<ProjectOutlined />}>
        <Link to={`/${status}/detail/${props.id}/work-experience`}>
          Work Experience
        </Link>
      </Menu.Item>
      <Menu.Item key="education" icon={<BulbOutlined />}>
        <Link to={`/${status}/detail/${props.id}/education`}>
          Education
        </Link>
      </Menu.Item>
      <Menu.Item key="trainingAndCertification" icon={<IdcardOutlined />}>
        <Link to={`/${status}/detail/${props.id}/training-and-certification`}>
          Training and Certification
        </Link>
      </Menu.Item>
      <Menu.Item key="candidateResultTest" icon={<BarChartOutlined />}>
        <Link to={`/${status}/detail/${props.id}/result-test`}>
          Candidate Result Test
        </Link>
      </Menu.Item>
      <Menu.Item key="languageSkills" icon={<GlobalOutlined />}>
        <Link to={`/${status}/detail/${props.id}/language-skills`}>
          Language Skills
        </Link>
      </Menu.Item>
      <Menu.Item key="skills" icon={<FormatPainterOutlined />}>
        <Link to={`/${status}/detail/${props.id}/skills`}>
          Skills
        </Link>
      </Menu.Item>
      <Menu.Item key="portofolios" icon={<FolderOutlined />}>
        <Link to={`/${status}/detail/${props.id}/portfolios`}>
          Portofolios
        </Link>
      </Menu.Item>
      <Menu.Item key="achievements" icon={<TrophyOutlined />}>
        <Link to={`/${status}/detail/${props.id}/achievements`}>
          Achievements
        </Link>
      </Menu.Item>
      {
        props?.email?.split("@")[1] === 'student.binawan.ac.id' ? 
          <Menu.Item key="uniform" icon={<SkinOutlined />}>
            <Link to={`/${status}/detail/${props.id}/uniform`}>
              Uniform
            </Link>
          </Menu.Item>
        : null
      }
      <Menu.Item key="resetPassword" icon={<LockOutlined />}>
        <Link to={`/${status}/detail/${props.id}/reset-password`}>
          Reset Password
        </Link>
      </Menu.Item>
    </Menu> 
    // : 
    // <Menu defaultSelectedKeys={[props.rowKey]} mode="inline">
    //   <Menu.Item key="biodata" icon={<UserOutlined />}>
    //     <Link to={`/${status}/detail/${props.id}/biodata`}>
    //       Biodata
    //     </Link>
    //   </Menu.Item>
    //   <Menu.Item key="contactInformation" icon={<PhoneOutlined />}>
    //     <Link to={`/${status}/detail/${props.id}/contact-information`}>
    //       Contact Information
    //     </Link>
    //   </Menu.Item>
    //   <Menu.Item key="jobPreferences" icon={<LikeOutlined />}>
    //     <Link to={`/${status}/detail/${props.id}/job-preferences`}>
    //       Job Preferences
    //     </Link>
    //   </Menu.Item>
    //   <Menu.Item key="workExperience" icon={<ProjectOutlined />}>
    //     <Link to={`/${status}/detail/${props.id}/work-experience`}>
    //       Work Experience
    //     </Link>
    //   </Menu.Item>
    //   <Menu.Item key="education" icon={<BulbOutlined />}>
    //     <Link to={`/${status}/detail/${props.id}/education`}>
    //       Education
    //     </Link>
    //   </Menu.Item>
    //   <Menu.Item key="trainingAndCertification" icon={<IdcardOutlined />}>
    //     <Link to={`/${status}/detail/${props.id}/training-and-certification`}>
    //       Training and Certification
    //     </Link>
    //   </Menu.Item>
    //   <Menu.Item key="candidateResultTest" icon={<BarChartOutlined />}>
    //     <Link to={`/${status}/detail/${props.id}/result-test`}>
    //       Candidate Result Test
    //     </Link>
    //   </Menu.Item>
    //   <Menu.Item key="languageSkills" icon={<GlobalOutlined />}>
    //     <Link to={`/${status}/detail/${props.id}/language-skills`}>
    //       Language Skills
    //     </Link>
    //   </Menu.Item>
    //   <Menu.Item key="skills" icon={<FormatPainterOutlined />}>
    //     <Link to={`/${status}/detail/${props.id}/skills`}>
    //       Skills
    //     </Link>
    //   </Menu.Item>
    //   <Menu.Item key="portfolios" icon={<FolderOutlined />}>
    //     <Link to={`/${status}/detail/${props.id}/portfolios`}>
    //       Portfolios
    //     </Link>
    //   </Menu.Item>
    //   <Menu.Item key="achievements" icon={<TrophyOutlined />}>
    //     <Link to={`/${status}/detail/${props.id}/achievements`}>
    //       Achievements
    //     </Link>
    //   </Menu.Item>
    //   <Menu.Item key="documents" icon={<FileTextOutlined />}>
    //     <Link to={`/${status}/detail/${props.id}/documents`}>
    //       Documents
    //     </Link>
    //   </Menu.Item>
    //   <Menu.Item key="resetPassword" icon={<LockOutlined />}>
    //     <Link to={`/${status}/detail/${props.id}/reset-password`}>
    //       Reset Password
    //     </Link>
    //   </Menu.Item>
    //   </Menu>
  )
}