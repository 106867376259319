const LOAD_DATA_LOCATION           = 'LOAD_DATA_LOCATION'
const LOAD_DATA_LOCATION_SUCCESS   = 'LOAD_DATA_LOCATION_SUCCESS'
const LOAD_DATA_LOCATION_FAILED    = 'LOAD_DATA_LOCATION_FAILED'
const UNMOUNT_DATA_LOCATION        = 'UNMOUNT_DATA_LOCATION'

const initialState = {
  loading: true,
  data: null,
  message: null,
}

const locationReducer = (state = initialState, action) => {
  switch(action.type){
    case LOAD_DATA_LOCATION:
      return {
        ...state,
        loading: true,
        data: null,
      }
    case LOAD_DATA_LOCATION_SUCCESS:
      return { 
        ...state, 
        loading: false,
        data: action.payload.data,
        message: 'SUCCESS',
      }
    case LOAD_DATA_LOCATION_FAILED:
      return { 
        ...state, 
        loading: true,
        data: null,
        message: 'FAILED',
      }
    case UNMOUNT_DATA_LOCATION:
      return { 
        ...state, 
        loading: true,
        data: null,
      }
    default:
      return state
  }
}

export default locationReducer;