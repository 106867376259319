import { API } from '../../../config';
import { errorHandler } from '../auth/errorAction';

export const unmountListCandidate = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_CANDIDATE'})
}

export const unmountDetailCandidate = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_CANDIDATE'})
}

export const unmountListCandidateTracerStudy = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_CANDIDATE_TRACER_STUDY'})
}

export const unmountDetailCandidateTracerStudy = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_CANDIDATE_TRACER_STUDY'})
}

export const listCandidate = (meta) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_CANDIDATE' })
  return API.GET(`/candidate/list`, meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_CANDIDATE_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_CANDIDATE_FAILED' }), 
    ))
  })
}

export const detailCandidate = (id, successCB, failedCB) => async dispatch => {
  const params = {
    'id': id
  }
  API.GET(`/candidate/getById`, params).then((response) => {
    dispatch({ 
      type: 'LOAD_DETAIL_CANDIDATE_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DETAIL_CANDIDATE_FAILED' }), 
      ))
    )
  })
}

export const updateAvatar = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA('/candidate/update/avatar', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updateBiodata = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/candidate/update/biodata', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updateContact = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/candidate/update/contact', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const addSocial = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/candidate/social-media/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const deleteSocial = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/candidate/social-media/delete', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updateJobPreference = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/candidate/update/job-preference', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const addWorkExperience = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/candidate/work-experience/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updateWorkExperience = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/candidate/work-experience/update', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const deleteWorkExperience = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/candidate/work-experience/delete', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const addEducation = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/candidate/education/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updateEducation = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/candidate/education/update', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const deleteEducation = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/candidate/education/delete', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const addTraining = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/candidate/training/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updateTraining = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/candidate/training/update', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const deleteTraining = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/candidate/training/delete', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const addSkill = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/candidate/skill/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const deleteSkill = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/candidate/skill/delete', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const addPortfolio = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/candidate/portfolio/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const deletePortfolio = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/candidate/portfolio/delete', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const addAchievement = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/candidate/achievement/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updateAchievement = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/candidate/achievement/update', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const deleteAchievement = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/candidate/achievement/delete', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const addDocument = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA('/candidate/document/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updateDocument = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA('/candidate/document/update', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const deleteDocument = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/candidate/document/delete', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updatePassword = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/candidate/update/password', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const enableCandidate = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/candidate/enable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const disableCandidate = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/candidate/disable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const listCandidateTracerStudy = (meta) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_CANDIDATE_TRACER_STUDY' })
  return API.GET(`/candidate/tracer_study/list`, meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_CANDIDATE_TRACER_STUDY_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_CANDIDATE_TRACER_STUDY_FAILED' }), 
    ))
  })
}

export const detailCandidateTracerStudy = (id, successCB, failedCB) => async dispatch => {
  const params = {
    'id': id
  }
  API.GET(`/candidate/tracer_study/getById`, params).then((response) => {
    dispatch({ 
      type: 'LOAD_DETAIL_CANDIDATE_TRACER_STUDY_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DETAIL_CANDIDATE_TRACER_STUDY_FAILED' }), 
      ))
    )
  })
}

