import React, { Component } from 'react';
import moment from 'moment';
import { Row, Col, Typography, Divider, Space } from 'antd';
const { Text } = Typography;

export default class ContentModal extends Component {
  render() {
    const { visible, valueForm } = this.props;
    return (
      <React.Fragment>
        <Row gutter={16}>
          <Col span={24} style={{ marginBottom: 16 }}>
            <Text>Assessment schedule detail:</Text>
          </Col>
          <Col span={24} style={{ marginBottom: 4 }}>
            {
              visible ?
                <React.Fragment>
                  <Space direction='vertical' size={0}>
                    <Space size={4}>
                      <Text>Date</Text>
                      <Text>:</Text>
                      <Text strong>{valueForm.assessmentDate ? moment(valueForm.assessmentDate, 'DD/MM/YYYY').format('DD MMM YYYY') : null}</Text>
                    </Space>
                    <Space size={4}>
                      <Text>Time</Text>
                      <Text>:</Text>
                      <Text strong>{valueForm.assessmentTime ? valueForm.assessmentTime : null }</Text>
                    </Space>
                    <Space size={4}>
                      <Text>Method</Text>
                      <Text>:</Text>
                      <Text strong>{valueForm.isMethod ? "Direct" : "Virtual"}</Text>
                    </Space>
                    <Space size={4} style={{ textAlign: 'justify', marginBottom: -16 }}>
                      {
                        valueForm.isMethod && 
                          <Space size={4} align="start" style={{ textAlign: 'justify' }}>
                            Location
                            <Text>:</Text>
                            <div dangerouslySetInnerHTML={{ __html: valueForm.location ? valueForm.location : null }} style={{ fontWeight: 'bold', paddingTop: 2 }}></div>
                          </Space>
                      }
                      {
                        !valueForm.isMethod &&
                          <Space size={4} align="start" style={{ textAlign: 'justify' }}>
                              Virtual Link
                              <Text> : </Text>
                              <div dangerouslySetInnerHTML={{ __html: valueForm.virtualLink ? valueForm.virtualLink : null }} style={{ fontWeight: 'bold', paddingTop: 2 }}></div>
                          </Space>
                      }
                    </Space>
                    <Space size={4} align="start" style={{ textAlign: 'justify', marginBottom: -16, marginTop: -16  }}>
                        Notes
                        <Text> :</Text>
                      <div dangerouslySetInnerHTML={{ __html: valueForm.notes ? valueForm.notes : null }} style={{ fontWeight: 'bold', paddingTop: 2 }}></div>
                    </Space>
                  </Space>
                </React.Fragment>
              : null
            }
          </Col>
          <Divider />
          <Col span={24}>
            <Text strong>
              By doing this, the assessment schedule will be published to the applicant. Are you sure?
            </Text>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}
