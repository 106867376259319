import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Typography, Tag, Dropdown, Menu } from 'antd';
import { EditOutlined, StopOutlined, UnorderedListOutlined, CheckCircleOutlined } from '@ant-design/icons';
const { Text } = Typography;

const menu = (record, onClickEdit, handleEnable, handleDisable) => (
  <Menu>
    <Menu.Item icon={<EditOutlined />} onClick={(e) => {e.domEvent.stopPropagation(); onClickEdit(record.id)}}>
      Edit                              
    </Menu.Item>
    {
      record.status === 'ACTIVE' ?
      <Menu.Item icon={<StopOutlined />} onClick={(e) => {e.domEvent.stopPropagation(); handleDisable(record.id)}}>
        Set as inactive
      </Menu.Item>
      :
      <Menu.Item icon={<CheckCircleOutlined />} onClick={(e) => {e.domEvent.stopPropagation(); handleEnable(record.id)}}>
        Set as active
      </Menu.Item>
    }
  </Menu>
);

export const columns = (sortedInfo, onClickEdit, handleEnable, handleDisable, handleRoute) => {
  return [
    {
      title: 'Number',
      key: 'number',
      fixed: 'left',
      width: 80,
      render: (record) => (
        <Link className="link" to={`/service/detail/${record.id}`}>
          {record.xpertNumber}
        </Link>
      )
    },
    {
      // title: 'Submit Date',
      title: 'End Date',
      // key: 'submitDate',
      key: 'endDate',
      width: 100,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {/* {moment(record.submitDate, 'DD/MM/YYYY').format('DD MMM YYYY')} */}
          {
            record.endDate ? moment(record.endDate, 'DD/MM/YYYY').format('DD MMM YYYY') : '-'
          // {moment(record.endDate, 'DD/MM/YYYY').format('DD MMM YYYY')} ? {moment(record.endDate, 'DD/MM/YYYY').format('DD MMM YYYY')} : '-'
          }
        </Text>
      )
    },
    {
      title: 'Title',
      key: 'title',
      width: 150,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.title ? record.title : '-'}
        </Text>
      )
    },
    {
      title: 'Category',
      key: 'category',
      width: 110,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.categoryName ? record.categoryName : '-'}
        </Text>
      )
    },
    {
      title: 'Provider',
      key: 'provider',
      width: 100,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.provider ? record.provider : '-'}
        </Text>
      )
    },
    {
      title: 'Price',
      key: 'price',
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {
            record.maxPrice ? 
              `IDR ${record.price.toLocaleString()} - ${record.maxPrice.toLocaleString()}` 
            : record?.price ? 
              `IDR ${record.price.toLocaleString()}`
            : record?.price === 0 ?
              'free'
            : 'no ticket'
          }
        </Text>
      )
    },
    {
      title: 'Status',
      key: 'status',
      width: 50,
      render: (record) => (
        <React.Fragment>
          {
            record.status === 'ACTIVE' ? 
            <Tag color="green">Active</Tag>
            : record.status === 'INACTIVE' ?
            <Tag color="orange">Inactive</Tag>
            : <Tag color="blue">Draft</Tag>
          }
        </React.Fragment>
      )
    },
    {
      title: '',
      key: 'action',
      width: 30,
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <React.Fragment>
          {
            record.status === 'DRAFT' ?
            <EditOutlined style={{ fontSize: 20 }} onClick={() => onClickEdit(record.id)}/>
            :
            <Dropdown overlay={menu(record, onClickEdit, handleEnable, handleDisable)} trigger={['click']} placement="bottomCenter" arrow>
              <UnorderedListOutlined style={{ fontSize: 20 }} />
            </Dropdown>
          }
        </React.Fragment>
      )
    },
  ]
}