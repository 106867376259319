import React from 'react'
import { Row, Col, Typography, Descriptions, Divider } from 'antd';

const { Text } = Typography;

export default function Address(props) {
  const { data } = props
  return (
    data.email.split("@")[1] === 'student.binawan.ac.id' ?
      <Row gutter={16}>
        <Col span={24}>
          <Descriptions title="Address" />
          {
            data.listAddress.length > 0 ?
              <React.Fragment>
                {
                  data.listAddress.map((item, i) => (
                    <React.Fragment key={i}>
                      <div style={{ marginBottom: 11 }}>
                        <Text style={{ color: '#32ab6d', fontSize: 14 }}>
                          {item.type === "OTHER" ? item.typeOther : item.type === "HOME" ? "Home" : "Work"}
                        </Text>
                      </div>
                      <Descriptions layout="vertical" size="small" column={3}>
                        <Descriptions.Item label="Country">
                          {item.countryName ? item.countryName : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Province">
                          {item.provinceName ? item.provinceName : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label="City">
                          {item.cityName ? item.cityName : 'N/A'}
                        </Descriptions.Item>
                      </Descriptions>
                      <Descriptions layout="vertical" size="small" column={3}>
                        <Descriptions.Item label="Sub District">
                          {item.subDistrictName ? item.subDistrictName : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Village">
                          {item.villageName ? item.villageName : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Postal Code">
                          {item.zipCode ? item.zipCode : 'N/A'}
                        </Descriptions.Item>
                      </Descriptions>
                      <Descriptions layout="vertical" size="small">
                        <Descriptions.Item label="Street Address">
                          {item.address ? item.address : 'N/A'}
                        </Descriptions.Item>
                      </Descriptions>
                      <Divider />
                    </React.Fragment>
                  ))
                }
              </React.Fragment>
            :
              <React.Fragment>
                <Text>N/A</Text>
                <Divider />
              </React.Fragment>
          }
        </Col>
      </Row>
    : null
  )
}
