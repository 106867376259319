import React, { Component } from 'react'
import moment from 'moment';
import { connect } from 'react-redux'
import { Loading } from '../../../../components';
import { BankOutlined } from '@ant-design/icons';
import { PageHeader, Card, Row, Col, Descriptions, Typography, Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import { detailCandidateRequest, unmountDetailCandidateRequest } from '../../../../redux/actions/candidateRequest/candidateRequestAction';
import { jobType } from '../../../../utils/constant/job';
import { urgencyType } from '../../../../utils/constant/urgency';

const { Text } = Typography;

class DetailCandidateRequest extends Component {
  componentDidMount() {
    const { actionGetDetail, match: { params } } = this.props;
    return actionGetDetail(params.id)
  }

  render() {
    const { getData: { data, loading } } = this.props;

    if(loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><BankOutlined /> Request</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/candidate-request">Request for Candidate List</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{data.candidateRecommendationNumber ? data.candidateRecommendationNumber : '-'}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Request for Candidate Details' />
          </Col>
          {/* Descriptions */}
          <Col span={24}>
            <Card>
              <Row gutter={16}>
                <Col span={24}>
                  <Descriptions layout="vertical" column={2} size="small">
                    <Descriptions.Item label={<Text type="secondary">Contact Person</Text>}>
                      {data.name ? data.name : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Contact Person Email</Text>}>
                      {data.email ? data.email : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Contact Person Mobile Number</Text>}>
                      {data.mobilePhone ? `+${data.callingCode}${data.mobilePhone}` : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Company Name</Text>}>
                      {data.companyName ? data.companyName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Looking for role</Text>}>
                      {data.role ? data.role : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Company Location</Text>}>
                      {data.companyLocation ? data.companyLocation : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Number of hired needed</Text>}>
                      {data.numberHired ? data.numberHired : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Urgency</Text>}>
                      {
                        data.urgentType ?
                          <React.Fragment>
                            {urgencyType.find(item => item.value === data.urgentType)?.name}
                          </React.Fragment>
                        : 'N/A'
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Date Opened</Text>}>
                      {data.dateOpened ? moment(data.dateOpened, 'DD/MM/YYYY').format("DD MMMM YYYY") : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Close Application Date</Text>}>
                      {data.targetDate ? moment(data.targetDate, 'DD/MM/YYYY').format("DD MMMM YYYY") : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Job Type</Text>}>
                      {
                        data.jobType ?
                          <React.Fragment>
                            {jobType.find(item => item.value === data.jobType)?.name}
                          </React.Fragment>
                        : 'N/A'
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Job Placement</Text>}>
                      {data.jobPlacement ? data.jobPlacement : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Is it possible to work from home (remote)?</Text>}>
                      {data.isRemote ? 'Yes' : 'No'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Experience Level</Text>}>
                      {
                        data.experienceLevel ?
                          <React.Fragment>
                            {data.experienceLevel === "ENTRY_LEVEL" ? "Entry Level" : null}
                            {data.experienceLevel === "ASSOCIATE" ? "Associate" : null}
                            {data.experienceLevel === "MID_SENIOR" ? "Mid-Senior Level" : null}
                            {data.experienceLevel === "DIRECTOR" ? "Director" : null}
                            {data.experienceLevel === "EXECUTIVE" ? "Executive" : null}
                          </React.Fragment>
                        : 'N/A'
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Payroll Currency</Text>}>
                      {data.currencyCode ? data.currencyCode : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Payment Type</Text>}>
                      {
                        data.paymentType ?
                          <React.Fragment>
                            {data.paymentType === "DAILY" ? "Per Day" : null}
                            {data.paymentType === "WEEKLY" ? "Per Week" : null}
                            {data.paymentType === "MONTHLY" ? "Per Month" : null}
                            {data.paymentType === "YEARLY" ? "Per Year" : null}
                            {data.paymentType === "PROJECT" ? "Per Project" : null}
                          </React.Fragment>
                        : 'N/A'
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Salary Type</Text>}>
                      {data.isFixSalary ? "Fix Salary" : "Range"}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Salary</Text>}>
                      {
                        data.isFixSalary ? 
                        <React.Fragment>
                          {data.salary ? data.salary.toLocaleString() : 'N/A'}
                        </React.Fragment>
                        : 
                        <React.Fragment>
                          {data.salary ? data.salary.toLocaleString() : 'N/A'} - {data.maxSalary ? data.maxSalary.toLocaleString() : 'N/A'}
                        </React.Fragment>
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Notes</Text>}>
                      {data.note ? data.note : 'N/A'}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail } = this.props;
    return unmountGetDetail()
  }
}

const mapStateToProps = (state) => ({
  getData         : state.candidateRequest.detail,
})

const mapDispatchToProps = {
  actionGetDetail       : detailCandidateRequest,
  unmountGetDetail      : unmountDetailCandidateRequest,
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailCandidateRequest)
