import React from 'react';
import { Link } from 'react-router-dom';
import { FileDoneOutlined, UnorderedListOutlined, ExportOutlined, DoubleRightOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Typography, Tag, Button, Popover, Space, Badge, Tooltip } from 'antd';

const { Text } = Typography;

const menu = (record, modalSetOnProgress, modalSetReadyToAssign, modalSetRejected, showModalAssignJob, modalExportData) => (
  <Space direction='vertical'>
    {
      record.counselingStatus === 'REJECTED' ?
        <Text style={{ cursor: 'pointer' }} onClick={() => modalSetOnProgress(record.candidateId)}>Re-invite to counsel</Text>
      :
       <React.Fragment>
        <Text>Update Candidate Status</Text>
        {
          record.counselingStatus !== 'READY_TO_ASSIGN' ?
            <Space>
              {
                record.counselingStatus === 'ON_PROGRESS' ? 
                  <>
                    <CaretRightOutlined style={{color : '#FFA517'}} />
                    <Text style={{ color: '#FFA517', marginLeft: 0 }}>On Progress</Text>
                  </>
                : 
                  <Text style={{color: '#FFA517', marginLeft: 22, cursor: 'pointer'}} onClick={() => modalSetOnProgress(record.candidateId)}>On Progress</Text>
              }
            </Space>
          : null
        }
        <Space>
          {
            record.counselingStatus === 'READY_TO_ASSIGN' ? 
              <>
                <CaretRightOutlined style={{color : '#389E0C'}} />
                <Text style={{ color: '#389E0C', marginLeft: 0 }}>Ready To Assign</Text>
              </>
            : 
              <Text style={{color: '#389E0C', marginLeft: 22, cursor: 'pointer'}} onClick={() => modalSetReadyToAssign(record.candidateId)}>Ready To Assign</Text>
          }
        </Space>
        <Space>
          {
            record.counselingStatus === 'REJECTED' ? 
              <>
                <CaretRightOutlined style={{color : '#E47676'}} />
                <Text style={{ color: '#E47676', marginLeft: 0 }}>Rejected</Text>
              </>
            : 
              <Text style={{color: '#E47676', marginLeft: 22, cursor: 'pointer'}} onClick={() => modalSetRejected(record.candidateId)}>Rejected</Text>
          }
        </Space>
       </React.Fragment>
    }
    <Text style={{ cursor: 'pointer' }} onClick={() => modalExportData(record.candidateId)}>Export Data <ExportOutlined style={{ color: '#32AB6D' }} /></Text>
    {
      record.counselingStatus === 'READY_TO_ASSIGN' ?
        <Text onClick={() => showModalAssignJob(record.candidateId, record.candidateNumber, record.fullName)} style={{ cursor: 'pointer' }}>Assign to a Job <DoubleRightOutlined style={{ color: '#32AB6D' }} /></Text>
      :
        null
    }
  </Space>
)

export const columns = (handleRoute, showModalNotes, modalSetOnProgress, modalSetReadyToAssign, modalSetRejected, showModalAssignJob, modalExportData, selectedRowKeys) => {
  return [
    {
      title: 'Name',
      key: 'name',
      width: 200,
      fixed: 'left',
      render: (record) => (
        <React.Fragment>
          <Link className="link" to='#' onClick={() => handleRoute(record)}>
            {record.fullName}
          </Link>
        </React.Fragment>
      )
    },
    {
      title: 'Email',
      key: 'email',
      width: 300,
      align: 'center',
      render: (record) => (
        <Text className='row-pointer' onClick={() => handleRoute(record)}>
          {record.email ? record.email : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Phone',
      key: 'phone',
      width: 180,
      align: 'center',
      render: (record) => (
        <Text className='row-pointer' onClick={() => handleRoute(record)}>
          {`+62${record.mobilePhone}`}
        </Text>
      )
    },
    {
      title: 'Education',
      key: 'education',
      width: 250,
      align: 'center',
      render: (record) => (
        <Text className='row-pointer' onClick={() => handleRoute(record)}>
          {record.lastEducation ? record.lastEducation : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Gender',
      key: 'gender',
      width: 80,
      align: 'center',
      render: (record) => (
        record.gender ? 
          record.gender === 'MALE' ? 
            <Text className='row-pointer' onClick={() => handleRoute(record)} style={{ color: '#4273B9' }}>
              Man 
            </Text>
          : 
            <Text className='row-pointer' onClick={() => handleRoute(record)} style={{ color: '#F7BE8F' }}>
              Woman 
            </Text>
        : 
          'N/A'
      )
    },
    {
      title: 'Age',
      key: 'age',
      width: 50,
      align: 'center',
      render: (record) => (
        <Text className='row-pointer' onClick={() => handleRoute(record)}>
          {record.age ? record.age : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Experience',
      key: 'experience',
      width: 100,
      align: 'center',
      render: (record) => (
        <Text className='row-pointer' onClick={() => handleRoute(record)}>
          {record.lengthOfExperience ? record.lengthOfExperience : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Counseling Date',
      key: 'counselingDate',
      width: 180,
      align: 'center',
      render: (record) => (
        <Text className='row-pointer' onClick={() => handleRoute(record)}>
          {record.counselingDate ? record.counselingDate : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Candidate Counseling Status',
      key: 'candidateCounselingStatus',
      width: 150,
      fixed: 'right',
      align: 'center',
      render: (record) => (
        <React.Fragment>
          {
            record.counselingStatus === 'ON_PROGRESS' ? 
              <Tag color="gold">On Progress</Tag>
            : record.counselingStatus === 'READY_TO_ASSIGN' ?
              <Tag color="green">Ready To Assign</Tag>
            : record.counselingStatus === 'REJECTED' ? 
              <Tag color="red">Rejected</Tag>
            : 'N/A'
          }
        </React.Fragment>
      )
    },
    {
      width: 100,
      fixed: 'right',
      render: (record) => (
        <Badge dot={record.listCandidateNotes?.length > 0}>
          <Button 
            className="btn-create-primary" 
            type="primary" 
            size='small'
            ghost 
            onClick={() => showModalNotes(record.listCandidateNotes, record.candidateId)}
            style={{ float: 'right' }}
          >
            <FileDoneOutlined /> Notes  
          </Button>
        </Badge>
      )
    },
    {
      align: 'right',
      width: 50,
      fixed: 'right',
      render: (record) => (
        selectedRowKeys.length > 0 ?
          <Tooltip placement="topLeft" title="Please uncheck the checkbox">
            <UnorderedListOutlined style={{ fontSize: 20, color: 'GrayText', cursor: 'not-allowed' }} />
          </Tooltip>
        :
          <Popover placement="leftTop" content={menu(record, modalSetOnProgress, modalSetReadyToAssign, modalSetRejected, showModalAssignJob, modalExportData)} trigger="click">
            <UnorderedListOutlined style={{ fontSize: 20 }} />
          </Popover>
      )
    },
  ]
}