import { API } from '../../../../config';
import { errorHandler } from '../../auth/errorAction';

export const unmountListBank = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_BANK'})
}

export const unmountDetailBank = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_BANK'})
}

export const unmountMasterBank = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_BANK'})
}

export const listBank = (meta) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_BANK' })
  return API.GET('/bank/list', meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_BANK_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_BANK_FAILED' }), 
    ))
  })
}

export const detailBank = (id, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DETAIL_BANK' })
  const params = {
    'id': id
  }
  API.GET('/bank/getById', params).then((response) => {
    dispatch({ 
      type: 'LOAD_DETAIL_BANK_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DETAIL_BANK_FAILED' }), 
      ))
    )
  })
}

export const masterBank = (successCB, failedCB) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_BANK' })
  return API.GET('/bank/index').then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_BANK_SUCCESS', 
      payload: { data: response.data }
    })
    return successCB && successCB(response.data)
  }).catch((err) => {
    failedCB && failedCB()
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_MASTER_BANK_FAILED' }), 
    ))
  })
}

export const createBank = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA('/bank/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const editBank = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA('/bank/update', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const enableBank = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/bank/enable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const disableBank = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/bank/disable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}