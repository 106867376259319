import React, { Component } from 'react';
import EditSliderPhoto from './Edit';
import DetailSliderPhoto from './Detail';
import { Card, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
const { Text } = Typography;

export default class CompanyGallery extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      isEdit: false
    }
  }

  handleChangeEdit = () => {
    return this.setState({ isEdit: true })
  }

  handleCancel = () => {
    return this.setState({ isEdit: false })
  }
  
  render() {

    const { isEdit } = this.state;
    return (
      <React.Fragment>
        <Card 
          title={<Text style={{ color: '#9a9a9a', fontWeight: 'bold' }}>Company Gallery</Text>}
          style={{ border: 'none' }}
          extra={
            !isEdit ?
            <Link to="#" onClick={this.handleChangeEdit}><EditOutlined /> Edit</Link>
            :
            null
          }
        >
          {
            isEdit ?
            <EditSliderPhoto handleCancel={this.handleCancel} {...this.props} />
            :
            <DetailSliderPhoto {...this.props} />
          }
        </Card>
      </React.Fragment>
    )
  }
}
