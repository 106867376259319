import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading } from '../../../../../components';
import { InfoCircleOutlined, DatabaseOutlined, UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { Col, Form, Row, Input, Button, Space, Select, PageHeader, message, Card, Breadcrumb, Image, Upload } from 'antd';
import { detailCountries, unmountDetailCountries, editCountries } from '../../../../../redux/actions/master/countries/countriesAction';

class EditMasterCountry extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      avatar: null,
      editAvatar: false,
      onPreviewAvatar: null,
    }
  }

  componentDidMount() {
    const { actionGetDetail, match: { params } } = this.props;
    return actionGetDetail(params.id)
  }
  
  handleUpload(){
    return {
      showUploadList: false,
      withCredentials: true,
      accept:"image/*",
      beforeUpload: file => {
        const validateSize = file.size >= 500000;
        if (validateSize) {
          message.error('Max file size is 500 KB!');
          return false
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.setState({ 
            avatar: file, 
            editAvatar: true,
            onPreviewAvatar: e.target.result, 
          })
        }
        return false;
      },
    }
  }

  handleDeleteFile = () => {
    this.setState({
      avatar: null,
      editAvatar: false,
      onPreviewAvatar: null
    })
  }

  onFinish = (values) => {
    const { actionUpdate, history, getData: { data } } = this.props;
    this.setState({ submitLoading: true })
    values.id = data.id
    if(this.state.editAvatar){
      values.flagNameFile = this.state.avatar
    }
    actionUpdate(values, () => {
      this.setState({ submitLoading: false }, () => {
        message.success('Data updated successfully')
        history.push('/master/countries')
      })
    }, (err) => {
      message.error(err.message)
      this.setState({ submitLoading: false })
    })
  }

  render() {
    const { submitLoading, editAvatar, onPreviewAvatar } = this.state;
    const { getData: { loading, data } } = this.props;

    if(loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Form onFinish={this.onFinish} layout="vertical">
          <Row style={{ position: 'sticky', zIndex: 1, width: '100%', top: 62, right: 0, marginTop: -20, marginBottom: 8, paddingBottom: 12, paddingTop: 16, backgroundColor: '#f2f2f2' }}>
            {/* Breadcrumb */}
            <Col span={24}>
              <Breadcrumb>
                <Breadcrumb.Item><DatabaseOutlined /> Master Data</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link className="link" to="/master/countries">
                    Master Data List Countries
                  </Link> 
                </Breadcrumb.Item>
                <Breadcrumb.Item>Countries</Breadcrumb.Item>
                <Breadcrumb.Item>{data.name ? data.name : null}</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            { /* Page Header */}
            <Col span={24}>
              <PageHeader 
                className="site-page-header" 
                title="Update a Country"
                extra={[
                  <Button key="2" className="btn-save-primary" type="default" onClick={() => window.history.back()}>Cancel</Button>,
                  <Button key="1" className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading}>Update</Button>
                ]}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            { /* Form */}
            <Col span={24}>
              <Card>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item 
                      name="name"
                      validateFirst
                      label="Country Name" 
                      initialValue={data.name ? data.name : ''} 
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 3, message: 'Name must be at least 3 characters long' },
                        { max: 60, message: 'Country Name is too long. Maximum is 60 characters' },
                        { pattern: new RegExp(/^([^0-9]*)$/), message: 'Number are not allowed' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Will it be displayed in the candidate's side?" 
                      name="isDisplay" 
                      initialValue={data.isDisplay}
                      tooltip={{ 
                        title: 'If you choose yes, this country will be displayed in the Job Preferences option', 
                        icon: <InfoCircleOutlined style={{ color: '#2586eb' }} />
                      }}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select>
                        <Select.Option value={true}>Yes</Select.Option>
                        <Select.Option value={false}>No</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Status" 
                      name="enabled"
                      initialValue={data.enabled}
                      tooltip={{ 
                        title: 'Inactive means this country isn’t used in master data', 
                        icon: <InfoCircleOutlined style={{ color: '#2586eb' }} />
                      }}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select>
                        <Select.Option value={true}>Active</Select.Option>
                        <Select.Option value={false}>Inactive</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Alpha 2 Code" 
                      name="alpha2Code"
                      validateFirst
                      initialValue={data.alpha2Code ? data.alpha2Code : ''}
                      getValueFromEvent={ e => e.target.value.toUpperCase().trim()}
                      rules={[
                        { max: 2, message: '2 characters only' },
                        { min: 2, message: 'Alpha 2 code must be at least 2 characters long' },
                        { pattern: new RegExp("^[a-zA-Z]+$"), message: 'Please enter on alphabets only' },
                      ]}
                    >
                      <Input style={{ textTransform: 'uppercase' }} />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Alpha 3 Code"
                      name="alpha3Code"
                      validateFirst
                      initialValue={data.alpha3Code ? data.alpha3Code : ''}
                      getValueFromEvent={ e => e.target.value.toUpperCase().trim()}
                      rules={[
                        { max: 3, message: '3 characters only' },
                        { min: 3, message: 'Alpha 3 code must be at least 2 characters long' },
                        { pattern: new RegExp("^[a-zA-Z]+$"), message: 'Please enter on alphabets only' },
                      ]}
                    >
                      <Input style={{ textTransform: 'uppercase' }} />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Nationality" 
                      name="nationality"
                      validateFirst
                      initialValue={data.nationality ? data.nationality : ''}
                      rules={[
                        { max: 50, message: '50 characters only' },
                        { pattern: new RegExp("^[a-zA-Z ]+$"), message: 'Please enter on alphabets only' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Calling Code" 
                      name="callingCode"
                      validateFirst
                      initialValue={data.callingCode ? data.callingCode : ''}
                      rules={[
                        { max: 5, message: '5 characters only' },
                        { pattern: new RegExp("^[0-9]+$"), message: 'Please enter on numerics only' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Currency Name" 
                      name="currencyName"
                      validateFirst
                      initialValue={data.currencyName ? data.currencyName : ''}
                      rules={[
                        { max: 50, message: '50 characters only' },
                        { pattern: new RegExp("^[a-zA-Z ]+$"), message: 'Please enter on alphabets only' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Currency Code" 
                      name="currencyCode"
                      validateFirst
                      initialValue={data.currencyCode ? data.currencyCode : ''}
                      getValueFromEvent={ e => e.target.value.toUpperCase().trim()}
                      rules={[
                        { max: 3, message: '3 characters only' },
                        { pattern: new RegExp("^[a-zA-Z]+$"), message: 'Please enter on alphabets only' },
                      ]}
                    >
                      <Input style={{ textTransform: 'uppercase' }} />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Description" 
                      name="description"
                      initialValue={data.description ? data.description : ''}
                      rules={[
                        { max: 50, message: '50 characters only' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Flag">
                      {
                        data.flagNameUrl ?
                        <Form.Item>
                          <Image width={100} src={editAvatar ? onPreviewAvatar : data.flagNameUrl ? data.flagNameUrl : null} />
                        </Form.Item>
                        :
                        editAvatar ?
                        <Form.Item>
                          <Image width={100} src={editAvatar ? onPreviewAvatar : null} />
                        </Form.Item>
                        :
                        null
                      }
                      <Space>
                        <Upload {...this.handleUpload()}>
                          <Button icon={<UploadOutlined />}>{data.flagNameUrl ? 'Change File' : editAvatar ? 'Change File' : 'Upload a File'}</Button>
                        </Upload>
                        {
                          editAvatar ? 
                          <Button icon={<DeleteOutlined />} type="danger" ghost onClick={this.handleDeleteFile}>Delete</Button>
                          : null
                        }
                      </Space>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail } = this.props;
    return unmountGetDetail()
  }
}

const mapStateToProps = (state) => ({
  getData: state.countries.detail
})

const mapDispatchToProps = {
  actionUpdate      : editCountries,
  actionGetDetail   : detailCountries,
  unmountGetDetail  : unmountDetailCountries
}

export default connect(mapStateToProps, mapDispatchToProps)(EditMasterCountry)
