import React, { Component } from 'react';
import { Layout } from 'antd';
const { Footer } = Layout;

export default class FooterMenu extends Component {
  render() {
    return (
      <React.Fragment>
        <Footer style={{ textAlign: 'center' }}>Admin Jobshub @2021 Created by Binawan</Footer>
      </React.Fragment>
    )
  }
}
