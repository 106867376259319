const LOAD_DETAIL_TRANSACTION           = 'LOAD_DETAIL_TRANSACTION'
const LOAD_DETAIL_TRANSACTION_SUCCESS   = 'LOAD_DETAIL_TRANSACTION_SUCCESS'
const LOAD_DETAIL_TRANSACTION_FAILED    = 'LOAD_DETAIL_TRANSACTION_FAILED'
const UNMOUNT_DETAIL_TRANSACTION        = 'UNMOUNT_DETAIL_TRANSACTION'

const initialState = {
  loading: true,
  data: null,
  message: null,
}

const TransactionDetailReducer = (state = initialState, action) => {
  switch(action.type){
    case LOAD_DETAIL_TRANSACTION:
      return {
        ...state,
        loading: true,
        data: null,
      }
    case LOAD_DETAIL_TRANSACTION_SUCCESS:
      return { 
        ...state, 
        loading: false,
        data: action.payload.data,
        message: 'SUCCESS',
      }
    case LOAD_DETAIL_TRANSACTION_FAILED:
      return { 
        ...state, 
        loading: true,
        data: null,
        message: 'FAILED',
      }
    case UNMOUNT_DETAIL_TRANSACTION:
      return { 
        ...state, 
        loading: true,
        data: null,
      }
    default:
      return state
  }
}

export default TransactionDetailReducer;