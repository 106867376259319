import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Dropdown, Menu, Tag } from 'antd';
import { EditOutlined, StopOutlined, UnorderedListOutlined, CheckCircleOutlined } from '@ant-design/icons';
const { Text } = Typography;

const menu = (record, onClickApproval, onClickEdit, handleActive, handleInactive) => (
  <Menu>
    {
      record.status === "REQUEST" &&
      <Menu.Item icon={<CheckCircleOutlined />} onClick={(e) => {e.domEvent.stopPropagation(); onClickApproval(record.id)}}>
        Approve
      </Menu.Item>
    }
    {
      record.status === "ACTIVE" &&
      <Menu.Item icon={<StopOutlined />} onClick={(e) => {e.domEvent.stopPropagation(); handleInactive(record.id)}}>
        Set as inactive
      </Menu.Item>
    }
    {
      record.status === "INACTIVE" &&
      <Menu.Item icon={<CheckCircleOutlined />} onClick={(e) => {e.domEvent.stopPropagation(); handleActive(record.id)}}>
        Set as active
      </Menu.Item>
    }
    <Menu.Item icon={<EditOutlined />} onClick={(e) => {e.domEvent.stopPropagation(); onClickEdit(record.id)}}>
      Edit                              
    </Menu.Item>
  </Menu>
);

export const columns = (onClickApproval, onClickEdit, handleActive, handleInactive, handleRoute) => {
  return [
    {
      title: 'Number',
      key: 'number',
      fixed: 'left',
      width: 80,
      render: (record) => (
        <Link className="link" to={`/jobs/detail/${record.id}`}>
          {record.jobNumber}
        </Link>
      )
    },
    {
      title: 'Created Date',
      key: 'createdDate',
      width: 100,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.createdDate ? record.createdDate : '-'}
        </Text>
      )
    },
    {
      title: 'Title',
      key: 'title',
      width: 150,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.title}
        </Text>
      )
    },
    {
      title: 'Requestor',
      key: 'requestor',
      width: 100,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.requestor ? record.requestor : '-'}
        </Text>
      )
    },
    {
      title: 'Account Type',
      key: 'accountType',
      width: 100,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.accountType ? record.accountType : '-'}
        </Text>
      )
    },
    {
      title: 'Job Posting Type',
      key: 'jobPostingType',
      width: 100,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.jobPostingType ? record.jobPostingType : '-'}
        </Text>
      )
    },
    {
      title: 'Status',
      key: 'status',
      width: 80,
      render: (record) => (
        <React.Fragment>
          {record.status === 'REQUEST' ?  <Tag color="blue">Waiting for approval</Tag> : null}
          {record.status === 'DRAFT' ? <Tag> Draft</Tag> : null}
          {record.status === 'ACTIVE' ? <Tag color="green"> Active</Tag> : null}
          {record.status === 'INACTIVE' ? <Tag color="orange"> Inactive</Tag> : null}
          {record.status === 'REJECT' ? <Tag color="red"> Rejected</Tag> : null}
        </React.Fragment>
      )
    },
    {
      title: '',
      key: 'action',
      width: 30,
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <Dropdown overlay={menu(record, onClickApproval, onClickEdit, handleActive, handleInactive)} trigger={['click']} placement="bottomCenter" arrow>
          <UnorderedListOutlined style={{ fontSize: 20 }} />
        </Dropdown>
      )
    },
  ]
}