import React, { Component } from 'react';
import update from 'react-addons-update';
import { DeleteOutlined } from '@ant-design/icons';
import { Row, Col, Card, Upload, message, Button, Typography, Space, Image } from 'antd';
const { Dragger } = Upload;
const { Text } = Typography;

export default class EditSliderPhoto extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      avatar: [],
      submitLoading: false,
      editAvatar: false
    }
  }

  componentDidMount() {
    const { actionGetSliderPhoto } = this.props;
    return actionGetSliderPhoto((response) => {
      response.data.map(item => delete item.file)
      return this.setState({ avatar: response.data })
    })
  }

  handleChange = (value, fileUrl) => {
    const values = {
      file: value,
      isCover: this.state.avatar.length === 0 ? true : false,
      fileUrl: fileUrl
    }
    return this.setState({
      avatar: update(this.state.avatar, {
        $push: [values]
      })
    })
  }

  onDeletePhoto = (index, value) => {
    this.setState({
      avatar: update(this.state.avatar, {
        $splice: [[index, 1]]
      })
    }, () => {
      if(value.isCover === true){
        var arr = [];
        this.state.avatar.map((res, i) => {
          if(index === 0){
            res.isCover = i === 0 ? true : false
          }else{
            res.isCover = i === index-1 ? true : false
          }
          return arr.push(res)
        })
        return this.setState({
          avatar: arr
        })
      }
    })
  }

  handleUpload(){
    return {
      multiple: true,
      showUploadList: false,
      withCredentials: true,
      accept:".png,.jpg,.jpeg",
      beforeUpload: file => {
        const validateSize = file.size >= 500000;
        if (validateSize) {
          message.error('Max file size is 500 KB!');
          return false
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.handleChange(file, e.target.result)
          this.setState({ 
            editAvatar: true,
          })
        }
        return false;
      },
    }
  }

  onClickPhoto = (e) => {
    var arr = [];
    this.state.avatar.map((res, i) => {
      res.isCover = e === i ? true : false
      return arr.push(res)
    })
    return this.setState({
      avatar: arr
    })
  }

  onSubmit = () => {
    const { avatar } = this.state;
    const { actionUpdateSlider, handleCancel } = this.props;
    this.setState({ submitLoading: true })
    return actionUpdateSlider(avatar, () => {
      this.setState({ submitLoading: false }, () => {
        message.success('Data updated successfully')
        return handleCancel()
      })
    }, (err) => {
      this.setState({ submitLoading: false }, () => {
        return message.error(err)
      })
    })
  }

  onCancel = () => {
    const { handleCancel } = this.props;
    return handleCancel()
  }

  render() {
    const { avatar, submitLoading } = this.state;
    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Card style={{ border: 'none' }}>
              <Row gutter={[16, 24]}>
                <Col span={24}>
                  <Dragger 
                    {...this.handleUpload()} 
                    disabled={avatar.length < 5 ? false : true}
                    style={{ 
                      backgroundColor: '#fafafa', 
                      boxShadow: ' 0 2px 4px 0 rgba(198, 198, 198, 0.5)',
                      border: 'solid 1px #f3f3f3',
                      padding: 25 
                    }}
                  >
                    <Space direction="vertical">
                      <Button 
                        disabled={avatar.length < 5 ? false : true}
                        style={{ 
                          backgroundColor: '#c7e9d8',
                          width: '499px',
                          height: '38px',
                          margin: '0px 0px 19px',
                          borderRadius: '6px',
                          border: 'none',
                          boxShadow: '0 2px 4px 0 rgba(198, 198, 198, 0.5)',
                          color: '#32ab6d',
                          fontSize: '16px'
                        }}
                      >
                        Select Photos
                      </Button>
                      <Text type="secondary" style={{ fontSize: '16px' }}>
                        or drag photos here (ideally 480 x 1400 pixels)
                        up to 5 photos
                      </Text>
                    </Space>
                  </Dragger>
                </Col>
                <Col span={24}>
                  <div className="list-slider-photo">
                    {
                      avatar.map((item, i) => (
                        <div key={i} style={{ position: 'relative' }}>
                          <Card className={item.isCover ? 'cover-photo' : 'not-cover-photo'} onClick={() => this.onClickPhoto(i)}>
                            {
                              item.isCover ?
                              <div style={{ width: '100%', marginBottom: 15 }}>
                                <Text style={{ color: ' #706f6f', fontSize: 18 }}>COVER</Text>
                              </div>
                              :
                              null
                            }
                            <Row gutter={[16, 24]}>
                              <Col span={24}>
                                <div>
                                  <Image src={item.fileUrl} preview={false} width={300} /> 
                                </div>
                              </Col>
                            </Row>
                          </Card>
                          <div style={{ position: 'absolute', top: 0, right: 15 }}>
                            <Button 
                              shape="circle" 
                              type="danger"
                              icon={<DeleteOutlined style={{ color: 'white' }} />} 
                              onClick={() => this.onDeletePhoto(i, item)}
                            />
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Space>
              <Button className="btn-cancel-danger" onClick={this.onCancel}>Cancel</Button>
              <Button className="btn-save-primary" type="primary" onClick={this.onSubmit} disabled={avatar.length < 1 ? true : false} loading={submitLoading}>Update</Button>
            </Space>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}
