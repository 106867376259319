import React from 'react'
import moment from 'moment'
import { Row, Col, Typography, Modal, Image, Space } from 'antd'

const { Text } = Typography

export default function ModalEventDetail(props) {
  const { visible, handleCancel, dataEvent } = props
  return (
    <Modal
      title="Job Event Detail"
      visible={visible}
      footer={false}
      onCancel={handleCancel}
      destroyOnClose
      width={750}
    >
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Space direction='vertical'>
            <Text>Organizer</Text>
            <Text strong>{dataEvent?.organizerName}</Text>
          </Space>
        </Col>
        <Col span={12}>
          <Space direction='vertical'>
            <Text>Event Type</Text>
            <Text strong>{dataEvent?.jobEventType}</Text>
          </Space>
        </Col>
        <Col span={24}>
          <Space direction='vertical'>
            <Text>Title</Text>
            <Text strong>{dataEvent?.title}</Text>
          </Space>
        </Col>
        <Col span={12}>
          <Space direction='vertical'>
            <Text>Date</Text>
            <Text strong>{moment(dataEvent?.eventDate, 'DD/MM/YYYY').format("DD MMMM YYYY")}</Text>
          </Space>
        </Col>
        <Col span={12}>
          <Space direction='vertical'>
            <Text>Time</Text>
            <Text strong>{dataEvent?.startTime ? `${moment(dataEvent?.startTime, 'HH:mm:ss').format('HH:mm')} - ${moment(dataEvent?.endTime, 'HH:mm:ss').format('HH:mm')}` : 'N/A'}</Text>
          </Space>
        </Col>
        <Col span={12} >
          <Space direction='vertical'>
            <Text>Thumbnail Image</Text>
            {
              dataEvent?.thumbnailImageUrl ?
                <Image
                  width={70}
                  height={70}
                  src={dataEvent?.thumbnailImageUrl ? dataEvent?.thumbnailImageUrl : 'N/A'}
                  style={{ objectFit: 'contain' }}
                />
              : "N/A"
            }
          </Space>
        </Col>
        <Col span={12} >
          <Space direction='vertical'>
            <Text>Banner Image</Text>
            {
              dataEvent?.bannerImageUrl ?
                <Image
                  width={70}
                  height={70}
                  src={dataEvent?.bannerImageUrl ? dataEvent?.bannerImageUrl : 'N/A'}
                  style={{ objectFit: 'contain' }}
                />
              : "N/A"
            }
          </Space>
        </Col>
        <Col span={24}>
          <Space direction='vertical'>
            <Text>Detail</Text>
            <div dangerouslySetInnerHTML={{ __html: dataEvent?.detail }} style={{ fontWeight: 'bold' }}></div>
          </Space>
        </Col>
        {
          dataEvent?.jobEventType === "ONLINE" || dataEvent?.jobEventType === "HYBRID" ?
            <Col span={24}>
              <Space direction='vertical'>
                <Text>Event Link</Text>
                <Text strong>{dataEvent?.eventLink ? dataEvent?.eventLink : 'N/A'}</Text>
              </Space>
            </Col>
          :
            <Col span={24}>
              <Space direction='vertical'>
                <Text>Event Address</Text>
                <Text strong>{dataEvent?.eventAddress ? dataEvent?.eventAddress : 'N/A'}</Text>
              </Space>
            </Col>
        }
      </Row>
    </Modal>
  )
}
