import React, { Component } from 'react';
import { Row, Col, Descriptions, Skeleton } from 'antd';

export default class DetailEmail extends Component {

  componentDidMount() {
    const { actionGetEmail } = this.props;
    return actionGetEmail('agency')
  }
  
  render() {
    const { getEmail: { loading, data } } = this.props;
    if(loading){
      return <Skeleton />
    }
    return (
      <React.Fragment>
        <Row gutter={16}>
          <Col span={24}>
            <Descriptions layout="vertical" column={1}>
              <Descriptions.Item label="Current Email">{data.email}</Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}
