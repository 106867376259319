import jsPDF from 'jspdf';
import moment from 'moment';
import KopSurat from '../assets/img/kop_surat.png';
import TTDHeny from '../assets/img/ttd henny.png';
import { message } from 'antd';

export const downloadGuaranteeLetter = (data) => {
  const dataEducation = data?.listEducation?.find(item => item.name.includes('binawan') || item.name.includes('Binawan') || item.name.includes('BINAWAN'))
  const arrDataPassport = data?.listDocument
  const dataPassport = arrDataPassport?.find(item => item.documentName === 'Paspor')
  if(dataEducation 
    && dataPassport?.documentNumber 
    && dataEducation.startYear 
    && (dataEducation.endYear || dataEducation.current)
    && (dataEducation?.educationLevelName === "Bachelor / S1" || dataEducation?.educationLevelName === "Diploma IV" || dataEducation?.educationLevelName === "Diploma III" || dataEducation?.educationLevelName === "Professional Program") ){
    const estimateEndYear = 
      dataEducation?.educationLevelName === "Bachelor / S1" || dataEducation?.educationLevelName === "Diploma IV" ?
        Number(dataEducation?.startYear)+4
      : dataEducation?.educationLevelName === "Diploma III" ?
        Number(dataEducation?.startYear)+3
      : dataEducation?.educationLevelName === "Professional Program" ?
        Number(dataEducation?.startYear)+1
      : null
    const roman = 
    moment().format("M") === "1" ?
        "I"
      : moment().format("M") === "2" ?
        "II"
      : moment().format("M") === "3" ?
        "III"
      : moment().format("M") === "4" ?
        "IV"
      : moment().format("M") === "5" ?
        "V"
      : moment().format("M") === "6" ?
        "VI"
      : moment().format("M") === "7" ?
        "VII"
      : moment().format("M") === "8" ?
        "VIII"
      : moment().format("M") === "9" ?
        "IX"
      : moment().format("M") === "10" ?
        "X"
      : moment().format("M") === "11" ?
        "XI"
      : moment().format("M") === "12" ?
        "XII"
      : null

    const doc = new jsPDF('p','pt','a4', true)
    doc.addImage(KopSurat, 'PNG', 10, 10, 580, 820)
    
    doc.setFontSize(11)
    doc.setFont('helvetica','','normal')
    doc.text(400, 150,`Jakarta, ${moment().format('LL')}`)


    doc.setFont('helvetica','','normal')
    doc.text(80, 180, 'No.')
    doc.text(160, 180, ':')
    doc.text(166, 180, `${data.id}/S-Ket/UBN.REK/${roman}/${moment().format('YYYY')}`)

    doc.setFont('helvetica','','normal')
    doc.text(80, 195, 'Attachment')
    doc.text(160, 195, ':')
    doc.text(166, 195, '1 Document')

    doc.setFont('helvetica','','normal')
    doc.text(80, 210, 'To:')
    doc.text(160, 210, ':')
    doc.text(166, 210, 'Embassy of the Federal Republic of Germany in Jakarta')

    doc.setFont('helvetica','','bold')
    doc.text(250, 250, 'GUARANTEE LETTER')


    doc.setFont('helvetica','','normal')
    doc.text(80, 280, 'Dear Sir/Madam:')

    doc.setFont('helvetica','','normal')
    doc.text(80, 305, 'With reference to the visa application of:')

    doc.setFont('helvetica','','bold')
    doc.text(100,  330,'Name')
    doc.text(195, 330, ':')
    doc.text(200, 330, data.fullName)

    doc.setFont('helvetica','','bold')
    doc.text(100,  342,'Passport No.')
    doc.text(195, 342, ':')
    doc.text(200, 342, dataPassport?.documentNumber ? dataPassport?.documentNumber : '........................................')

    doc.setFont('helvetica','','normal')
    doc.text(80, 370, 'Who intends to visit Germany for')
    doc.setFont('helvetica','','bold')
    doc.text(242, 370, 'Temporary Employment for Foreign Student')
    doc.setFont('helvetica','','bold')
    doc.text(80, 385, 'Program')
    doc.setFont('helvetica','','normal')
    doc.text(130, 385, 'from October 2nd, 2023 until December 31st, 2023. We hereby declare that:')

    doc.setFont('helvetica','','normal')
    doc.text(100, 415, '1.')
    doc.setFont('helvetica','','normal')
    doc.text(120, 415, `S/He an active student of Binawan University since ${dataEducation.startYear ? `14.09.${dataEducation.startYear}` : '....................'} until ${dataEducation.endYear || dataEducation.current ? `14.07.${dataEducation.current || dataEducation.endYear === null ? estimateEndYear : dataEducation.endYear}` : '....................'}`)

    doc.setFont('helvetica','','normal')
    doc.text(100, 448, '2.')
    doc.setFont('helvetica','','normal')
    doc.text(120, 448, 'We shall be responsible for their return to Indonesia within the period stated')
    doc.setFont('helvetica','','normal')
    doc.text(120, 461,'on their application form.')

    doc.setFont('helvetica','','normal')
    doc.text(100, 477, '3.')
    doc.setFont('helvetica','','normal')
    doc.text(120, 477, 'All the livelihood expenses during the program will be guaranteed by the')
    doc.setFont('helvetica','','normal')
    doc.text(120, 489, 'University')

    doc.setFont('helvetica','','normal')
    doc.text(80, 520, 'Kindly find the attached documents of our University Bank Statement for your')
    doc.setFont('helvetica','','normal')
    doc.text(80, 530, 'reference')

    doc.setFont('helvetica','','normal')
    doc.text(80, 555, 'Thank you for your kind assistance in this matter.')

    doc.setFont('helvetica','','normal')
    doc.text(80, 585, 'Yours faithfully,')

    doc.setFont('helvetica','','normal')
    doc.text(80, 596, 'Binawan University')
    
    doc.addImage(TTDHeny, 'PNG', 95, 598, 65, 65)

    doc.setFont('helvetica','','bold')
    doc.text(80, 665, 'Prof. Henny Suzana Mediani, SKp, M.Ng, PhD')
    doc.setTextColor("black");
    doc.line(80, 666, 315, 666);
    doc.setFont('helvetica','','normal')
    doc.text(80, 676, 'On-duty Rector of Binawan University')

    doc.save(`${data.candidateNumber} - Guarantee Letter.pdf`);
    message.success('Download success!')
  }else{
    message.error('Data is not complete. Please contact the candidate!')
  }
}