const LOAD_DATA_MASTER_CITIES           = 'LOAD_DATA_MASTER_CITIES'
const LOAD_DATA_MASTER_CITIES_SUCCESS   = 'LOAD_DATA_MASTER_CITIES_SUCCESS'
const LOAD_DATA_MASTER_CITIES_FAILED    = 'LOAD_DATA_MASTER_CITIES_FAILED'
const UNMOUNT_DATA_MASTER_CITIES        = 'UNMOUNT_DATA_MASTER_CITIES'

const initialState = {
  loading: true,
  data: null,
  message: null,
}

const masterCitiesReducer = (state = initialState, action) => {
  switch(action.type){
    case LOAD_DATA_MASTER_CITIES:
      return {
        ...state,
        loading: true,
        data: null,
      }
    case LOAD_DATA_MASTER_CITIES_SUCCESS:
      return { 
        ...state, 
        loading: false,
        data: action.payload.data,
        message: 'SUCCESS',
      }
    case LOAD_DATA_MASTER_CITIES_FAILED:
      return { 
        ...state, 
        loading: true,
        data: null,
        message: 'FAILED',
      }
    case UNMOUNT_DATA_MASTER_CITIES:
      return { 
        ...state, 
        loading: true,
        data: null,
      }
    default:
      return state
  }
}

export default masterCitiesReducer;