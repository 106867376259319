import React, { Component } from 'react'
import moment from 'moment'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { Row, Col, Input, Button, Form, DatePicker, Typography } from 'antd'

const { Text } = Typography

export default class ApplicantDevelopment extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      startDate: moment()
    }
  }

  onChangeStartDate = (current) => {
    this.formRef.current.setFieldsValue({
      targetDate: null,
    });
    this.setState({ startDate: current })
  }

  disabledStartDate = (current) => {
    let date = moment();
    return !current || current.isBefore(date);
  }

  disabledEndDate = (current) => {
    const { startDate } = this.state;
    return current < startDate
  }

  render() {
    const { onFinishDev } = this.props
    return (
      <Form
        ref={this.formRef} 
        layout="vertical"
        onFinish={onFinishDev}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="Training Type"
              name="trainingType"
              rules={[
                { required: true, message: 'This is a required field' },
                { min: 3, message: 'At least 3 characters' },
                { max: 200, message: '200 characters only' }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Start Development"
              name="startDate"
              initialValue={null}
              rules={[{ required: true, message: 'This is a required field' }]}
            >
              <DatePicker disabledDate={this.disabledStartDate} onChange={this.onChangeStartDate} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Development Target"
              name="targetDate"
              rules={[{ required: true, message: 'This is a required field' }]}
              initialValue={null}
            >
              <DatePicker disabledDate={this.disabledEndDate} style={{ width: '100%' }}/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Location"
              name="location"
              initialValue={null}
              rules={[
                { required: true, message: 'This is a required field'},
                { min: 3, message: 'At least 3 characters' },
                { max: 200, message: '200 characters only' }
              ]}
            >
              <ReactQuill />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Reason for Participating in the Training"
              name="reason"
              initialValue={null}
              rules={[
                { required: true, message: 'This is a required field'},
                { max: 200, message: '200 characters only' }
              ]}
            >
              <ReactQuill />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <Text style={{ float: 'left', paddingTop: 4 }}>The Application will be proceeded to development stage, Are you sure ?</Text>
              <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
                Process
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}
