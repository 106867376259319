import React from 'react';
import { Link } from 'react-router-dom';
import { UnorderedListOutlined, StopOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Typography, Dropdown, Menu, Tag } from 'antd';

const { Text } = Typography;

const menu = (record, handleEnable, handleDisable) => (
  <Menu>
    {
      record.enabled ?
      <Menu.Item icon={<StopOutlined />} onClick={(e) => {e.domEvent.stopPropagation(); handleDisable(record.id)}}>
        Set as inactive
      </Menu.Item>
      :
      <Menu.Item icon={<CheckCircleOutlined />} onClick={(e) => {e.domEvent.stopPropagation(); handleEnable(record.id)}}>
        Set as active
      </Menu.Item>
    }
  </Menu>
);

export const columns = (handleEnable, handleDisable, handleRoute) => {
  return [
    {
      title: 'Number',
      key: 'number',
      width: 130,
      render: (record) => (
        <React.Fragment>
          <Link className="link" to={`/binawan/candidate/detail/${record.id}/biodata`}>
            {record.candidateNumber}
          </Link>
        </React.Fragment>
      )
    },
    {
      title: 'Name',
      key: 'name',
      width: 150,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.fullName}
        </Text>
      )
    },
    {
      title: 'Register Date',
      key: 'registrationDate',
      width: 150,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.registrationDate ? record.registrationDate : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Email',
      key: 'email',
      width: 280,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.email ? record.email : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Current Location',
      key: 'city',
      width: 130,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.currentCityName ? record.currentCityName : 'N/A'}, {record.currentCountryName ? record.currentCountryName : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Last Update',
      key: 'lastUpdate',
      align: 'center',
      width: 150,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.lastUpdate ? record.lastUpdate : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Status',
      key: 'status',
      width: 50,
      render: (record) => (
        <React.Fragment>
          {
            record.enabled ? 
            <Tag color="green">Active</Tag>
            : 
            <Tag color="orange">Inactive</Tag>
          }
        </React.Fragment>
      )
    },
    {
      title: '',
      key: 'action',
      align: 'right',
      width: 50,
      fixed: 'right',
      render: (record) => (
        <Dropdown overlay={menu(record, handleEnable, handleDisable)} trigger={['click']} placement="bottomCenter" arrow>
          <UnorderedListOutlined style={{ fontSize: 20 }} />
        </Dropdown>
      )
    },
  ]
}