import { API } from '../../../config';
import { errorHandler } from '../auth/errorAction';

export const unmountListCategory = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_CATEGORY'})
}

export const unmountDetailCategory = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_CATEGORY'})
}

export const unmountMasterCategory = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_CATEGORY'})
}

export const listCategory = (meta) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_CATEGORY' })
  return API.GET('/category/list', meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_CATEGORY_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_CATEGORY_FAILED' }), 
    ))
  })
}

export const detailCategory = (id, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DETAIL_CATEGORY' })
  const params = {
    'id': id
  }
  API.GET('/category/getById', params).then((response) => {
    dispatch({ 
      type: 'LOAD_DETAIL_CATEGORY_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DETAIL_CATEGORY_FAILED' }), 
      ))
    )
  })
}

export const masterCategory = (successCB, failedCB) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_CATEGORY' })
  return API.GET('/category/index').then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_CATEGORY_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: null,
          total: null,
          perpage: null
        }
    }})
    return successCB && successCB(response.data)
  }).catch((err) => {
    failedCB && failedCB()
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_MASTER_CATEGORY_FAILED' }), 
    ))
  })
}

export const createCategory = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA('/category/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const editCategory = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA('/category/update', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const enableCategory = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/category/enable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const disableCategory = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/category/disable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}