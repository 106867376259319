import React from 'react';
import { Link } from 'react-router-dom';
import { FileDoneOutlined, UnorderedListOutlined, ExportOutlined, DoubleRightOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Typography, Tag, Button, Popover, Space, Badge, Tooltip } from 'antd';

const { Text } = Typography;

const menu = (record, modalSetNotContacted, modalSetContacted, modalSetRejected, modalMoveToCounseling, modalExportData) => (
  <Space direction='vertical'>
    <Text>Update Candidate Status</Text>
    <Space>
      {
        record.candidateCrmStatus === 'NOT_CONTACTED' ? 
          <>
            <CaretRightOutlined style={{color : '#389E0C'}} />
            <Text style={{ color: '#389E0C', marginLeft: 0 }}>Not Contacted</Text>
          </>
        : 
          <Text style={{color: '#389E0C', marginLeft: 22, cursor: 'pointer'}} onClick={() => modalSetNotContacted(record.id)}>Not Contacted</Text>
      }
    </Space>
    <Space>
      {
        record.candidateCrmStatus === 'CONTACTED' ? 
          <>
            <CaretRightOutlined style={{color : '#4273B9'}} />
            <Text style={{ color: '#4273B9', marginLeft: 0 }}>Contacted</Text>
          </>
        : 
          <Text style={{color: '#4273B9', marginLeft: 22, cursor: 'pointer'}} onClick={() => modalSetContacted(record.id)}>Contacted</Text>
      }
    </Space>
    <Space>
      {
        record.candidateCrmStatus === 'REJECTED' ? 
          <>
            <CaretRightOutlined style={{color : '#E47676'}} />
            <Text style={{ color: '#E47676', marginLeft: 0 }}>Rejected</Text>
          </>
        : 
          <Text style={{color: '#E47676', marginLeft: 22, cursor: 'pointer'}} onClick={() => modalSetRejected(record.id)}>Rejected</Text>
      }
    </Space>
    <Text style={{ cursor: 'pointer' }} onClick={() => modalExportData(record.id)}>Export Data <ExportOutlined style={{ color: '#32AB6D' }} /></Text>
    {
      record.candidateCrmStatus === 'CONTACTED' ?
        <Text onClick={() => modalMoveToCounseling(record.id)} style={{ cursor: 'pointer' }}>Move to Counseling Process <DoubleRightOutlined style={{ color: '#32AB6D' }} /></Text>
      :
        null
    }
  </Space>
)

export const columns = (handleRoute, showModalNotes, modalSetNotContacted, modalSetContacted, modalSetRejected, modalMoveToCounseling, modalExportData, selectedRowKeys) => {
  return [
    {
      title: 'Name',
      key: 'name',
      width: 200,
      fixed: 'left',
      render: (record) => (
        <React.Fragment>
          <Link className="link" to='#' onClick={() => handleRoute(record)}>
            {record.fullName}
          </Link>
        </React.Fragment>
      )
    },
    {
      title: 'Email',
      key: 'email',
      width: 300,
      align: 'center',
      render: (record) => (
        <Text className='row-pointer' onClick={() => handleRoute(record)}>
          {record.email ? record.email : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Phone',
      key: 'phone',
      width: 180,
      align: 'center',
      render: (record) => (
        <Text className='row-pointer' onClick={() => handleRoute(record)}>
          {`+62${record.mobilePhone}`}
        </Text>
      )
    },
    {
      title: 'Education',
      key: 'education',
      width: 250,
      align: 'center',
      render: (record) => (
        <Text className='row-pointer' onClick={() => handleRoute(record)}>
          {record.lastEducation ? record.lastEducation : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Gender',
      key: 'gender',
      width: 80,
      align: 'center',
      render: (record) => (
        record.gender ? 
          record.gender === 'MALE' ? 
            <Text className='row-pointer' onClick={() => handleRoute(record)} style={{ color: '#4273B9' }}>
              Man 
            </Text>
          : 
            <Text className='row-pointer' onClick={() => handleRoute(record)} style={{ color: '#F7BE8F' }}>
              Woman 
            </Text>
        : 
          'N/A'
      )
    },
    {
      title: 'Age',
      key: 'age',
      width: 50,
      align: 'center',
      render: (record) => (
        <Text className='row-pointer' onClick={() => handleRoute(record)}>
          {record.age ? record.age : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Candidates Created',
      key: 'candidatesCreated',
      width: 200,
      align: 'center',
      render: (record) => (
        <Text className='row-pointer' onClick={() => handleRoute(record)}>
          {record.createdDate ? record.createdDate : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Register Owner',
      key: 'registerOwner',
      width: 200,
      align: 'center',
      render: (record) => (
        <Text className='row-pointer' onClick={() => handleRoute(record)}>
          {
            record.candidateSource === 'REFERRAL' ?
              `Referral by: ${record.registerOwner}`
            : record.candidateSource === 'INJECTED' ?
              `Injected by: ${record.registerOwner}`
            : record.candidateSource === 'SELF_REGISTER' ?
              'Self Register'
            : '-'
          }
        </Text>
      )
    },
    {
      title: 'Experience',
      key: 'experience',
      width: 200,
      align: 'center',
      render: (record) => (
        <Text className='row-pointer' onClick={() => handleRoute(record)}>
          {record.lengthOfExperience ? record.lengthOfExperience : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Candidate Status',
      key: 'candidateStatus',
      width: 140,
      fixed: 'right',
      align: 'center',
      render: (record) => (
        <React.Fragment>
          {
            record.candidateCrmStatus === 'NOT_CONTACTED' ? 
              <Tag color="green">Not Contacted</Tag>
            : record.candidateCrmStatus === 'CONTACTED' ?
              <Tag color="blue">Contacted</Tag>
            : record.candidateCrmStatus === 'REJECTED' ? 
              <Tag color="red">Rejected</Tag>
            : 'N/A'
          }
        </React.Fragment>
      )
    },
    {
      width: 100,
      fixed: 'right',
      render: (record) => (
        <Badge dot={record.listCandidateNotes?.length > 0}>
          <Button 
            className="btn-create-primary" 
            type="primary" 
            size='small'
            ghost 
            onClick={() => showModalNotes(record.listCandidateNotes, record.id)}
            style={{ float: 'right' }}
          >
            <FileDoneOutlined /> Notes  
          </Button>
        </Badge>
      )
    },
    {
      align: 'right',
      width: 50,
      fixed: 'right',
      render: (record) => (
        selectedRowKeys.length > 0 ?
          <Tooltip placement="topLeft" title="Please uncheck the checkbox">
            <UnorderedListOutlined style={{ fontSize: 20, color: 'GrayText', cursor: 'not-allowed' }} />
          </Tooltip>
        :
          <Popover placement="leftTop" content={menu(record, modalSetNotContacted, modalSetContacted, modalSetRejected, modalMoveToCounseling, modalExportData)} trigger="click">
            <UnorderedListOutlined style={{ fontSize: 20 }} />
          </Popover>
      )
    },
  ]
}