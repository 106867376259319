import React, { Component } from 'react';
import { Table } from 'antd';
import { columns } from './columns';

export default class JobOverview extends Component {

  componentDidMount() {
    const { actionGetJobOverview, menuOverview } = this.props;
    return actionGetJobOverview('dashboard_agency', menuOverview)
  }
  
  render() {
    const { getJobOverview: { loading, data } } = this.props;
    return (
      <React.Fragment>
        <Table 
          rowKey={(i) => i.id} 
          dataSource={data} 
          columns={columns()} 
          pagination={false} 
          loading={loading}
        />
      </React.Fragment>
    )
  }
}
