import React from 'react';
import moment from 'moment';
import { Form, Row, Col, Input, DatePicker, Checkbox, Radio, InputNumber, TimePicker } from 'antd';

const FormTicket = ({ onSubmit, formRef, onChangeDate, handleLimit, 
    handleChecked, isDate, isFree, data, isSameDate, 
    isSameTime, isLimit, disabledDate , handleCheckedDiscount, isDiscount }) => {
  return(
    <React.Fragment>
      <Form ref={formRef} onFinish={onSubmit} layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item 
              label="Ticket Name"
              name="name"
              validateFirst
              initialValue={data.name ? data.name : null}
              rules={[
                { max: 50, message: '50 characters only' },
                { required: true, message: 'This is a required field' },
                { min: 5, message: 'Name must be at least 5 characters' },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              label="Type of limitation" 
              name="isLimit" 
              initialValue={data.isLimit === true || data.isLimit === false ? data.isLimit : false}
              rules={[
                { required: true, message: 'This is a required field' },
              ]}
            >
              <Radio.Group onChange={(value) => handleLimit(value)}>
                <Radio value={false}>Date Based</Radio>
                <Radio value={true}>Date and Quota</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item 
              label="Due Date" 
              name="date" 
              initialValue={data.date ? moment(data.date, 'DD/MM/YYYY') : null}
              rules={[
                { required: true, message: 'This is a required field' },
              ]}
            >
              <DatePicker 
                placeholder="DD/MM/YYYY" 
                format={'DD/MM/YYYY'}
                style={{ width: '100%' }}
                disabledDate={disabledDate} 
                onChange={onChangeDate} 
                showToday={false}
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item 
              label="Time"
              name="time"
              initialValue={data.time ? moment(data.time, 'HH:mm') : null}
              rules={[
                { required: true, message: 'This is a required field' },
              ]}
            >
              <TimePicker style={{ width: '100%' }} format="HH:mm" />
            </Form.Item>
          </Col>

          <Col span={6} style={{paddingTop: 30}}>
            <Form.Item>
              <Checkbox onChange={(value) => handleChecked(value, 'isSameDate')} checked={isSameDate}>Same as start date</Checkbox>
            </Form.Item>
          </Col>
          
          <Col span={6} style={{paddingTop: 30}}>
            <Form.Item>
              <Checkbox onChange={(value) => handleChecked(value, 'isSameTime')} checked={isSameTime}>2 hours before start time</Checkbox>
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item 
              label="Price (IDR)" 
              name="price" 
              validateFirst
              initialValue={data.price ? data.price : ''}
              rules={[
                { required: !isFree, message: 'This is a required field' },
              ]}
            >
              <InputNumber
                disabled={isFree}
                maxLength={10}
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item 
              label="Discount Price" 
              name="discountedPrice" 
              validateFirst
              initialValue={data.discountedPrice ? data.discountedPrice : ''}
              rules={[
                { required: isDiscount, message: 'This is a required field' },
              ]}
            >
              <InputNumber
                disabled={isFree || !isDiscount}
                maxLength={10}
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
          </Col>

          <Col span={6} style={{ marginTop: 30 }}>
            <Form.Item>
              <Checkbox onChange={handleCheckedDiscount} defaultChecked={isDiscount} disabled={isFree}>This ticket has discount</Checkbox>
            </Form.Item>
          </Col>
          
          <Col span={6} style={{marginTop: 30}}>
            <Form.Item>
              <Checkbox onChange={(value) => handleChecked(value, 'isFree')} defaultChecked={isFree} disabled={isDiscount}>This is a free event</Checkbox>
            </Form.Item>
          </Col>
          
          {
            isLimit ?
              <React.Fragment>
                <Col span={12}>
                  <Form.Item 
                    label="Notes for customers"
                    name="note"
                    initialValue={data.note ? data.note : null }
                    rules={[
                      { max: 50, message: '50 characters only' }
                    ]}
                  >
                    <Input placeholder="e.g.: Include special merchandise" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item 
                    label="Maximum Quota" 
                    name="quota" 
                    validateFirst
                    initialValue={data.totalQuota ? data.totalQuota : ''}
                    rules={[
                      { required: true, message: 'This is a required field' },
                    ]}
                  >
                    <InputNumber
                      disabled={false}
                      maxLength={5}
                      min={data.ticketSold}
                    />
                  </Form.Item>
                </Col>
              </React.Fragment>
            :
              <Col span={24}>
                <Form.Item 
                  label="Notes for customers"
                  name="note"
                  initialValue={data.note ? data.note : null }
                  rules={[
                    { max: 50, message: '50 characters only' }
                  ]}
                >
                  <Input placeholder="e.g.: Include special merchandise" />
                </Form.Item>
              </Col>
          }
        </Row>
      </Form>
    </React.Fragment>
  )
}

export default FormTicket;