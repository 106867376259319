import jwt from 'jsonwebtoken';
import Cookie from "js-cookie";
import { API } from '../../../config';
import { errorHandler } from './errorAction';
import { ADMIN, ADMIN_BINAWAN, AGENCY, XPERT, EMPLOYER, ADMIN_RECRUITMENT, NOT_FOUND } from '../../../permissions';

export const checkAuth = () => async (dispatch) => {
  await dispatch({ type: 'LOAD_AUTH' })
  const getToken = Cookie.get('user')
  if(getToken) {
    const userData = jwt.decode(getToken)
    const permission = getPermission(userData.role)
    if(getToken !== null) {
      return dispatch({ 
        type: 'LOAD_AUTH_SUCCESS', 
        payload: { 
          user: userData,
          role: userData.role,
          permission: permission
        } 
      })
    }
  }else {
    return dispatch({ type: 'LOAD_AUTH_FAILED' })
  }
}

export const checkToken = (successCB, failedCB) => async (dispatch) => {
  const params = {
    'token': Cookie.get('user')
  }
  API.POST_LOGIN_NO_WITH_BEARER('/auth/check', params).then((response) => {
    if(response.code === "1000"){
      return successCB && successCB(response)
    }else {
      failedCB && failedCB()
      dispatch({ type: 'LOAD_AUTH_FAILED' })
    }
  })
}

export const setLogin = (value, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_AUTH' })
  API.POST_LOGIN('/auth/login', value).then((response) => {
    const token = response.token
    const refreshToken = response.refreshToken
    const role = response.role
    const userData = jwt.decode(token)
    const permission = getPermission(userData.role)
    Cookie.set('user', token)
    Cookie.set('userRefreshTkn', refreshToken)
    Cookie.set('role', role)
    dispatch({ type: 'LOAD_AUTH_SUCCESS', payload: { 
      userData: userData,
      role: userData.role,
      permission: permission
    } 
  })
    return successCB && successCB(response)
  })
  .catch((err) => {
    dispatch({ type: 'LOAD_AUTH_FAILED' })
    return failedCB && failedCB(err)
  })
}

export const setLogout = (successCB, failedCB) => async dispatch => {
  const getToken = Cookie.get('user');
  const params = {
    token: getToken
  }
  return API.POST_WITH_PARAMS('/auth/logout', params).then(() => {
    return dispatch(removedata(() => {
      dispatch({ type: 'SET_LOGOUT_SUCCESS'})
      return successCB && successCB();
    }))
  }).catch((err) => {
    return (
      failedCB && failedCB(),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'SET_LOGOUT_FAILED'})
      ))
    )
  })
}

export const getPermission = (type) => {
  switch (type) {
    case 'ROLE_ADMIN_JOBSHUB':
      return ADMIN;
    case 'ROLE_ADMIN_ORGANIZER':
      return XPERT;
    case 'ROLE_ADMIN_BINAWAN':
      return ADMIN_BINAWAN;
    case 'ROLE_EMPLOYERS':
      return EMPLOYER;
    case 'ROLE_AGENCY':
      return AGENCY;
    case 'ROLE_ADMIN_RECRUITMENT':
      return ADMIN_RECRUITMENT;
    default:
      return NOT_FOUND;
  }
}

export const removedata = (cb) => async (dispatch) => {
  await Cookie.remove('user')
  await Cookie.remove('userRefreshTkn')
  await dispatch({ type: 'UNMOUNT_DATA_AGENCIES' })
  await dispatch({ type: 'UNMOUNT_DETAIL_AGENCIES' })
  await dispatch({ type: 'UNMOUNT_DATA_MASTER_AGENCIES' })
  await dispatch({ type: 'UNMOUNT_DATA_CANDIDATE' })
  await dispatch({ type: 'UNMOUNT_DETAIL_CANDIDATE' })
  await dispatch({ type: 'UNMOUNT_DATA_CLIENTS' })
  await dispatch({ type: 'UNMOUNT_DETAIL_CLIENTS' })
  await dispatch({ type: 'UNMOUNT_DATA_MASTER_CLIENTS' })
  await dispatch({ type: 'UNMOUNT_DATA_ABOUT' })
  await dispatch({ type: 'UNMOUNT_DATA_LOCATION' })
  await dispatch({ type: 'UNMOUNT_DATA_SLIDER_PHOTO' })
  await dispatch({ type: 'UNMOUNT_DATA_APPLICANT_SUMMARY' })
  await dispatch({ type: 'UNMOUNT_DATA_JOB_OPENING_STATUS' })
  await dispatch({ type: 'UNMOUNT_DATA_JOB_OVERVIEW' })
  await dispatch({ type: 'UNMOUNT_DATA_PIPELINE_HIRING' })
  await dispatch({ type: 'UNMOUNT_DATA_EMPLOYERS' })
  await dispatch({ type: 'UNMOUNT_DETAIL_EMPLOYERS' })
  await dispatch({ type: 'UNMOUNT_DATA_MASTER_EMPLOYERS' })
  await dispatch({ type: 'UNMOUNT_JOBS_APPLIED_LIST_APPLICANT' })
  await dispatch({ type: 'UNMOUNT_DETAIL_ACTIVE_APPLICANT_JOBS' })
  await dispatch({ type: 'UNMOUNT_DETAIL_INTERVIEW_JOBS' })
  await dispatch({ type: 'UNMOUNT_DETAIL_JOBS' })
  await dispatch({ type: 'UNMOUNT_DATA_JOBS_LIST_APPLICANT' })
  await dispatch({ type: 'UNMOUNT_DATA_JOBS_LIST_REJECTED' })
  await dispatch({ type: 'UNMOUNT_DATA_JOBS' })
  await dispatch({ type: 'UNMOUNT_DATA_MASTER_BENEFIT' })
  await dispatch({ type: 'UNMOUNT_DATA_CITIES' })
  await dispatch({ type: 'UNMOUNT_DETAIL_CITIES' })
  await dispatch({ type: 'UNMOUNT_DATA_MASTER_CITIES' })
  await dispatch({ type: 'UNMOUNT_DATA_COUNTRIES' })
  await dispatch({ type: 'UNMOUNT_DETAIL_COUNTRIES' })
  await dispatch({ type: 'UNMOUNT_DATA_MASTER_COUNTRIES' })
  await dispatch({ type: 'UNMOUNT_DATA_EDUCATION_LEVEL' })
  await dispatch({ type: 'UNMOUNT_DETAIL_EDUCATION_LEVEL' })
  await dispatch({ type: 'UNMOUNT_DATA_MASTER_EDUCATION_LEVEL' })
  return cb()
}
