import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { columns } from './columns';
import { connect } from 'react-redux';
import { HeaderTable } from '../../../components';
import { UserOutlined, ReadOutlined, HighlightOutlined } from '@ant-design/icons';
import { PageHeader, Row, Col, Table, Breadcrumb, Pagination, message, Button, Steps, Modal, Form , Space, Divider } from 'antd';
import { disableCandidate, enableCandidate, listCandidate, unmountListCandidate } from '../../../redux/actions/candidate/candidateAction';
import { candidateByEmail, candidateByPhone, unmountCandidateByEmail, unmountCandidateByPhone } from '../../../redux/actions/jobs/jobsAction';
// import { detailJobActiveApplicant, listInjectCandidate, injectCandidate, inactiveJob, nextPhase, rejectApplicant, addApplicantNotes, unmountDetailJobsActiveApplicant, unmountListInjectCandidate } from '../../../redux/actions/jobs/jobsAction';
// import { listInjectCandidate, unmountListInjectCandidate } from '../../../redux/actions/jobs/jobsAction';
import PersonalInfo from './Form/personalInfo'
import Education from './Form/education'
import Review from './Form/review'
import moment from 'moment';
import { validatePost } from '../../../utils/postValidationFunction';

const { Step } = Steps;

class CandidateList extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      url: 'job_agency',
      checked: true,
      visible: false,
      selectedRowKeys: [],
      idApplicant: null,
      submitLoading: false,
      current: 0,
      isCandidate: false,
      dataCandidate: null,
      loadingCheckCandidate: false,
      callingCode: '62',
      callingCodeId: 99,
      birthdate: "",
      nationalityId: 99,
      nationality: 'Indonesian',
      countryName: null,
      countryId: null,
      provinceName: null,
      provinceId: null,
      cityName: null,
      cityId: null,
      subDistrictName: null,
      subDistrictId: null,
      villageName: null,
      villageId: null,

      educationLevelName: "",
      educationLevelId: "",
      isYear: false,
      startYear: "",
      endYear: "",

      valuesContact: null,
      valuesPersonal: null,
      valuesEducation: null,

      editContact: false,
      editPersonal: false,
      editEducation: false,
      visibleProfile: false,
      meta: {
        page: 1,
        perpage: 10,
        sort: '',
        field: '',
        search: '',
        minAge: '',
        maxAge: '',
        gender: ''
      }
    }
    this.onSearch = debounce(this.onSearch.bind(this), 900)
  }

  componentDidMount() {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    return actionGetData(meta)
  }

  onRegistered = value => {
    const { actionGetData } = this.props;
    const { meta } = this.state;
    meta.page = 1
    meta.perpage = 10
    meta.startDate = value.startDate
    meta.endDate = value.endDate
    return actionGetData(meta)
  }

  onStatus = value => {
    const { actionGetData } = this.props;
    const { meta } = this.state;
    meta.page = 1
    meta.perpage = 10
    meta.flagStatus = value
    return actionGetData(meta)
  }

  onSearch = value => {
    const { meta, url } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.search = value === '' ? null : value
    return actionGetData(meta, url)
  }

  pagination = (page, perpage) => {
    const { actionGetData } = this.props;
    const { meta } = this.state;
    meta.page = page
    meta.perpage = perpage
    return actionGetData(meta)
  }

  handleEnable = (value) => {
    const { actionEnable, actionGetData } = this.props;
    return actionEnable(value, () => {
      message.success('Successfully changed to active')
      return actionGetData(this.state.meta)
    }, (err) => message.error(err))
  }

  handleDisable = (value) => {
    const { actionDisable, actionGetData } = this.props;
    return actionDisable(value, () => {
      message.success('Successfully changed to inactive')
      return actionGetData(this.state.meta)
    }, (err) => message.error(err))
  }

  onClickRow = (record) => {
    this.props.history.push(`/candidate/detail/${record.id}/biodata`)
    localStorage.setItem("status", "candidate")
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  showModal = () => {
    this.setState({ visible: true, isCandidate: false, current: 0, dataCandidate: null, valuesContact: null, valuesPersonal: null, valuesEducation: null })
  };
  
  handleCancel = () => {
    this.setState({ visible: false, visibleProfile: false, isCandidate: false, current: 0, dataCandidate: null })
  };

  handleCheckEmail = (e) => {
    const { actionCheckEmail } = this.props
    const { url } = this.state
    this.setState({ loadingCheckCandidate: true })
    return actionCheckEmail(e, url, response => {
      const { code, data } = response
      
      if(code === "1000"){
        this.setState({ callingCodeId: data.callingCodeId, isCandidate: true, dataCandidate: data, loadingCheckCandidate: false })
        this.formRef.current.setFieldsValue({
          callingCodeId: `+${data.callingCode}`,
          mobilePhone: data.mobilePhone,
          firstName: data.firstName,
          lastName: data.lastName,
          birthdate: data.birthdate ? moment(data.birthdate, 'DD-MM-YYYY') : "",
          gender: data.gender,
          placeOfBirth: data.placeOfBirth,
          nationality: data.nationality,
          marital: data.marital,
          bloodType: data.bloodType,
          height: data.height,
          weight: data.weight,
          identificationId: data.identificationId,
          religion: data.religion,
          country: data.countryName,
          province: data.provinceName,
          city: data.cityName,
          subDistrict: data.subDistrictName,
          village: data.villageName,
          zipCode: data.zipCode,
          address: data.address
        });
        message.info('Email already exist!')
      }else{
        this.setState({ loadingCheckCandidate: false })
      }
    })
  }

  handleCheckMobile = (e) => {
    const { actionCheckMobile } = this.props
    const { url, callingCode } = this.state
    this.setState({ loadingCheckCandidate: true })
    this.formRef.current.setFieldsValue({
      mobilePhone: e.replace(/^0+/, '')
    })

    e = `${callingCode}${e.replace(/^0+/, '')}`

    return actionCheckMobile(e, url, response => {
      const { code, data } = response
      if(code === "1000"){
        this.setState({ isCandidate: true, dataCandidate: data, loadingCheckCandidate: false })
        this.formRef.current.setFieldsValue({
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          birthdate: moment(data.birthdate),
          gender: data.gender,
          placeOfBirth: data.placeOfBirth,
          nationality: data.nationality,
          marital: data.marital,
          bloodType: data.bloodType,
          height: data.height,
          weight: data.weight,
          identificationId: data.identificationId,
          religion: data.religion,
          country: data.countryName,
          province: data.provinceName,
          city: data.cityName,
          subDistrict: data.subDistrictName,
          village: data.villageName,
          zipCode: data.zipCode,
          address: data.address
        });
        message.info('Mobile Number already exist!')
      }else{
        this.setState({ loadingCheckCandidate: false })
      }
    })
  }

  handleCallingCode = (value, option) => {
    const optionSelected = Number(option.key)
    
    this.setState({
      textCode: value,
      callingCode: option.text,
      callingCodeId: optionSelected
    })
  }

  handleNationality = (value, option) => {
    const optionSelected = Number(option.key)
    
    this.setState({
      nationality: value,
      nationalityId: optionSelected
    })
  }

  handleCountry = (value, option) => {
    const { provinceId } = this.state
    const optionSelected = Number(option.key)
    
    this.setState({
      countryName: value,
      countryId: optionSelected,
      selectedCountry: optionSelected,
    })

    if(provinceId !== 0){
      this.setState({
        provinceId: null,
        cityId: null,
        subDistrictId: null,
        villageId: null
      })

      this.formRef.current.setFieldsValue({
        province: null,
        city: null,
        subDistrict: null,
        village: null
      })
    }
  }

  handleProvince = (value, option) => {
    const { cityId } = this.state
    const optionSelected = Number(option.key)

    this.setState({
      provinceName: value,
      provinceId: optionSelected
    })

    if(cityId !== 0){
      this.setState({
        cityId: null,
        subDistrictId: null,
        villageId: null
      })

      this.formRef.current.setFieldsValue({
        city: null,
        subDistrict: null,
        village: null
      })
    }
  }

  handleCity = (value, option) => {
    const { subDistrictId } = this.state
    const optionSelected = Number(option.key)
    
    this.setState({
      cityName: value,
      cityId: optionSelected
    })

    if(subDistrictId !== 0){
      this.setState({
        subDistrictId: null,
        villageId: null
      })

      this.formRef.current.setFieldsValue({
        subDistrict: null,
        village: null
      })
    }
  }

  handleSubDistrict = (value, option) => {
    const { villageId } = this.state
    const optionSelected = Number(option.key)
    
    this.setState({
      subDistrictName: value,
      subDistrictId: optionSelected
    })

    if(villageId !== 0){
      this.setState({
        villageId: null
      })

      this.formRef.current.setFieldsValue({
        village: null
      })
    }
  }

  handleVillage = (value, option) => {
    const optionSelected = Number(option.key)
    
    this.setState({
      villageName: value,
      villageId: optionSelected
    })
  }


  onNext = async (values) => {
    const { current, dataCandidate, callingCodeId, callingCode, birthdate, nationalityId, countryId, provinceId, cityId, subDistrictId, villageId, educationLevelId, startYear, endYear } = this.state
    
    if(current === 0){
      values.callingCodeId = callingCodeId
      values.mobilePhone = 
        values.mobilePhone.charAt(0) === '0' ? 
          `${callingCode}${values.mobilePhone.substring(1)}`
        : values.mobilePhone.substr(0, 2) === '62' ?
          `${callingCode}${values.mobilePhone.substring(2)}`
        : 
          `${callingCode}${values.mobilePhone}`
      
      values.birthdate = dataCandidate?.birthdate ? dataCandidate.birthdate : birthdate
      values.nationalityId = dataCandidate?.nationalityId ? dataCandidate.nationalityId : nationalityId
      values.countryId = dataCandidate?.countryId ? dataCandidate.countryId : countryId
      values.provinceId = dataCandidate?.provinceId ? dataCandidate.provinceId : provinceId
      values.cityId = dataCandidate?.cityId ? dataCandidate.cityId : cityId
      values.subDistrictId = dataCandidate?.subDistrictId ? dataCandidate.subDistrictId : subDistrictId
      values.villageId = dataCandidate?.villageId ? dataCandidate.villageId : villageId
      this.setState({
        current: current + 1,
        valuesContact: {
          email: values.email,
          callingCodeId: values.callingCodeId,
          mobilePhone: values.mobilePhone,
          firstName: values.firstName,
          lastName: values.lastName
        },
        valuesPersonal: {
          birthdate: values.birthdate,
          gender: values.gender,
          placeOfBirth: values.placeOfBirth,
          nationalityId: values.nationalityId,
          marital: values.marital,
          bloodType: values.bloodType,
          height: values.height,
          weight: values.weight,
          identificationId: values.identificationId,
          religion: values.religion,
          countryId: values.countryId,
          provinceId: values.provinceId,
          cityId: values.cityId,
          subDistrictId: values.subDistrictId,
          villageId: values.villageId,
          zipCode: values.zipCode,
          address: values.address
        }
      })
    }

    if(current === 1){
      values.educationLevelId = educationLevelId
      values.educationStartYear = startYear
      values.educationEndYear = endYear
      this.setState({
        current: current + 1,
        valuesEducation: dataCandidate?.listEducation?.length > 0 ? null : values,
      })
    }
  }

  onFinish = () => {
    const { actionInjectCandidate, actionListInjectCandidate, match: { params }  } = this.props
    const { valuesContact, valuesPersonal, valuesEducation, meta, url, dataCandidate } = this.state
    const jobId = params.id

    const objConWithoutEdu = {
      contactDetail: valuesContact,
      personal: valuesPersonal,
      jobId: Number(jobId),
      candidateId: Number(dataCandidate?.id)
    }

    const objConWithData = {
      contactDetail: valuesContact,
      personal: valuesPersonal,
      education: valuesEducation,
      jobId: Number(jobId),
      candidateId: Number(dataCandidate?.id)
    }

    const objCon = {
      contactDetail: valuesContact,
      personal: valuesPersonal,
      education: valuesEducation,
      jobId: Number(jobId),
    }

    const validateObj = 
      dataCandidate?.listEducation > 0 ?
        validatePost(objConWithoutEdu)
      : dataCandidate ? 
        validatePost(objConWithData) 
      : 
        validatePost(objCon)
    
    return this.setState({ submitLoading: true }, () => {
      return actionInjectCandidate(validateObj, url, response => {
        if(response.code === '1000'){
          setTimeout(() => {
            this.setState({ submitLoading: false, visible: false })
            message.info('Inject Candidate Success!')
          }, actionListInjectCandidate(meta, url), 3000)
        }else{
          return this.setState({ submitLoading: false, visible: false, isCandidate: false, current: 0, dataCandidate: null }, () => message.error(response.message))
        }
      }, (err) => {
        return this.setState({ submitLoading: false, visible: false }, () => message.error(err))
      })
    })
  }

  onPrev = () => {
    const { current } = this.state
    this.setState({
      current: current - 1
    })
  }


  
  render() {

    const { selectedRowKeys, visible, url, callingCode, submitLoading, current, isCandidate, dataCandidate } = this.state;
    const { getData: { data, loading , pagination} } = this.props;

    // const { selectedRowKeys, checked, visible, url, callingCode, submitLoading, current, isCandidate, dataCandidate, valuesContact, valuesPersonal, valuesEducation, nationality, visibleProfile, isYear, countryName, provinceName, cityName, subDistrictName, villageName, educationLevelName, loadingCheckCandidate } = this.state;
    // const { getData: { data, loading , pagination}, getInjectCandidate } = this.props;
    // const { selectedRowKeys } = this.state;
    // const { getData: { data, loading, pagination } } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const defaultStatusFilter = [
      { value: null, name: 'All' },
      { value: true, name: 'Active' },
      { value: false, name: 'Inactive' },
    ]

    const initialProps = {
      url: url,
      isCandidate: isCandidate,
      dataCandidate: dataCandidate,
      callingCode: callingCode,
      handleCheckEmail: this.handleCheckEmail,
      handleCheckMobile: this.handleCheckMobile,
      handleCallingCode: this.handleCallingCode,
      onChangeDate: this.onChangeDate,
      handleNationality: this.handleNationality,
      handleCountry: this.handleCountry,
      handleProvince: this.handleProvince,
      handleCity: this.handleCity,
      handleSubDistrict: this.handleSubDistrict,
      handleVillage: this.handleVillage,
      onChangeEducationLevel: this.onChangeEducationLevel,
      onChangeStartYear: this.onChangeStartYear,
      onChangeEndYear: this.onChangeEndYear,
      // valuesContact: valuesContact,
      // valuesPersonal: valuesPersonal,
      // valuesEducation: valuesEducation,
      // nationality: nationality,
      // isYear: isYear,
      // countryName: countryName,
      // provinceName: provinceName,
      // cityName: cityName,
      // subDistrictName: subDistrictName,
      // villageName: villageName,
      // educationLevelName: educationLevelName,
      // loadingCheckCandidate: loadingCheckCandidate
    }
        
    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><UserOutlined /> Candidates</Breadcrumb.Item>
              <Breadcrumb.Item>Candidates List</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Candidate List' />
          </Col>

          {/* Card */}
          {/* <Col span={24}>
            <Space>
              <Card
                style={{bordered: 'false',width:200, border: 'none', textAlign : 'center'}}
              >
                <Space direction='vertical' align='center' >
                  <Text style={{color : 'green', align: 'end'}} >New</Text>
                  <Text style={{color : 'black', align: 'middle'}} strong>43889</Text>
                </Space>
              </Card>
              <Card style={{width:200, border: 'none', textAlign : 'center'}}>
                <Space direction='vertical' align='center'>
                  <Text style={{color : 'blue'}}>Invited</Text>
                  <Text style={{color : 'black'}} strong>89</Text>
                </Space>
              </Card>
              <Card style={{width:200, border: 'none', textAlign : 'center'}}>
                <Space direction='vertical' align='center'>
                  <Text style={{color : 'orange'}}>Counseling</Text>
                  <Text style={{color : 'black'}} strong>43</Text>
                </Space>
              </Card>
              <Card style={{width:200, border: 'none', textAlign : 'center'}}>
                <Space direction='vertical' align='center'>
                  <Text style={{color : 'purple'}}>Assigned</Text>
                  <Text style={{color : 'black'}} strong>123</Text>
                </Space>
              </Card>
              <Card style={{width:200, border: 'none', textAlign : 'center'}}>
                <Space direction='vertical' align='center'>
                  <Text style={{color : 'magenta'}}>Rejected</Text>
                  <Text style={{color : 'black'}} strong>3456</Text>
                </Space>
              </Card>
            </Space>
          </Col> */}

          {/* Filter */}
          <Col span={24}>
            <HeaderTable 
              fase1={{ key: 'type', value: defaultStatusFilter}}
              onSearch={this.onSearch} 
              onStatus={this.onStatus} 
              onRegistered={this.onRegistered}
              tooltipDate="Filter by Register Date"
              tooltipStatus="Filter by Status"
              placeholder="Search by Name, Email"
            />
          </Col>
          {/* <Col span={4}>
            <Button 
              className="btn-create-primary" 
              type="primary" 
              // ghost 
              onClick={this.showModal}
              style={{ marginBottom: 16, float: 'right' }}
            >
              <UsergroupAddOutlined /> Create Candidate
            </Button>
          </Col> */}
          {/* Table */}
          <Col span={24}>
            <Table 
              size="small" 
              rowKey={(i) => i.id} 
              pagination={false}
              loading={loading} 
              scroll={{ x: 1500 }}
              rowSelection={rowSelection}  
              // onRow={(r) => ({ onClick: () => this.onClickRow(r) })}
              rowClassName={(record) => record.id ? 'row-pointer' : null}
              dataSource={data} columns={columns(this.handleEnable, this.handleDisable, this.onClickRow)} 
            />
          </Col>
          {/* Pagination */}
          <Col span={24}>
            <Pagination
              total={pagination.total}
              onChange={this.pagination}
              current={pagination.page}
              pageSize={pagination.perpage}
              showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total} Data`}
            />
          </Col>
        </Row>
        <Modal 
          width={1000} 
          // title={`Inject Candidate to ${data.jobPositionName} - ${data.clientName}`} 
          title={`Inject Candidate`} 
          visible={visible} 
          onCancel={this.handleCancel}
          footer={false}
          destroyOnClose
          style={{ top: 20 }}
        >
          <Steps size="small" current={current} style={{ marginBottom: 16 }}>
            <Step key="1" title="Personal Info" icon={<UserOutlined />} />
            <Step key="2" title="Education" icon={<ReadOutlined />} />
            <Step key="3" title="Review" icon={<HighlightOutlined />} />
          </Steps>
          <Form 
            layout="vertical"
            ref={this.formRef}
            onFinish={
              current === 2 ? 
                this.onFinish 
              : 
                this.onNext
            }
          >
            {
              current === 0 ?
                <PersonalInfo {...initialProps} />
              :
                null
            }
            {
              current === 1 ?
                <Education {...initialProps} />
              :
                null
            }
            {
              current === 2 ?
                <Review {...initialProps} />
              :
                null
            }
            <Divider />
            <Row>
              <Col span={24}>
                <Space style={{ float: 'right' }}>
                  {
                    current > 0 ?
                      <Button key="back" type="link" onClick={this.onPrev}>
                        Back
                      </Button>
                    :
                      null
                  }
                  <Button key="next" type="primary" htmlType="submit" loading={submitLoading}>
                    {
                      current > 1 ?
                        'Submit'
                      :
                        'Next'
                    }
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </Modal>
      </React.Fragment>
    )
  }
  
  componentWillUnmount() {
    const { unmountGetData, unmountCandidateByEmail, unmountCandidateByPhone } = this.props;
    unmountGetData()
    unmountCandidateByEmail()
    unmountCandidateByPhone()
    // unmountListInjectCandidate()
  }
}

const mapStateToProps = (state) => ({
  getData: state.candidate.list,
  // getInjectCandidate  : state.jobs.listInjectCandidate,
  getCandidateByEmail : state.jobs.candidateByEmail,
  getCandidateByPhone : state.jobs.candidateByPhone
})

const mapDispatchToProps = {
  actionGetData               : listCandidate,
  actionEnable                : enableCandidate,
  actionCheckEmail            : candidateByEmail,
  actionCheckMobile           : candidateByPhone,
  // actionListInjectCandidate   : listInjectCandidate,
  actionDisable               : disableCandidate,
  unmountGetData              : unmountListCandidate,
  unmountCandidateByEmail     : unmountCandidateByEmail,
  unmountCandidateByPhone     : unmountCandidateByPhone,
  // unmountListInjectCandidate  : unmountListInjectCandidate,
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateList)
