import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Loading } from '../../../../../../../../components';
import { Row, Col, Card, Typography, Modal, Empty, Input, Button, AutoComplete, Skeleton, message } from 'antd';
import { ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { detailCandidate, unmountDetailCandidate } from '../../../../../../../../redux/actions/candidate/candidateAction';
import { detailJobActiveApplicant, inactiveJob, addInterview, listApplicant, addApplicantNotes, listApplicantNotes, unmountDetailJobsActiveApplicant, unmountListApplicant, unmountApplicantNotes, addDevelopment } from '../../../../../../../../redux/actions/jobs/jobsAction';
import ContentModal from './Confirm';
import ContentQualified from './Content';
import ListApplicant from '../../../../../../../../components/ActiveApplicants/ListApplicant';
import ViewProfile from '../../../../../../../../components/Modal/ApplicantProfile';
import ApplicantHistory from '../../../../../../../../components/Modal/ApplicantHistory';
import ApplicantDevelopment from '../../../../../../../../components/Modal/ApplicantDevelopment';
import ApplicantNotes from '../../../../../../../../components/Modal/ApplicantNotes';
import ApplicationRejection from '../../../../../../../../components/Modal/ApplicationRejection';
import DownloadFile from '../../../../../../../../components/DownloadFiles';

const { Text } = Typography;
const { confirm } = Modal;

class DetailQualifiedEmployerJobs extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      isMethod: true,
      isResult: true,
      checked: true,
      visible: false,
      visibleProfile: false,
      valueForm: null,
      idApplicant: null,
      dataApplicant: null,
      documentName: null,
      isModalNotes: false,
      isModalHistory: false,
      isModalDev: false,
      isModalReject: false,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.match.params !== this.props.match.params){
      this.setState({ dataApplicant: null })
    }
  }

  selectedApplicant = (value) => {
    const { actionGetProfile, actionListApplicantNotes } = this.props;
    const { dataApplicant } = this.state;
    this.setState({ idApplicant: value.id, dataApplicant: value, isResult: true, isMethod: true })
    if(dataApplicant){
      this.formRef.current.setFieldsValue({
        isResult: true,
        isMethod: true,
        interviewDate: null,
        interviewTime: null,
        virtualLink: null,
        location: null,
        notes: null,
      });
    }
    actionGetProfile(value.candidateId)
    actionListApplicantNotes(value.id)
  }

  handleChangeAssessmentResult = (e) => {
    this.formRef.current.setFieldsValue({
      isMethod: true,
      interviewDate: null,
      interviewTime: null,
      virtualLink: null,
      location: null,
      notes: null,
    });
    this.setState({ isResult: e.target.value })
  }

  handleChangeMethod = (e) => {
    this.formRef.current.setFieldsValue({
      virtualLink: null,
      location: null,
    });
    this.setState({ isMethod: e.target.value })
  }

  onFinish = (values) => {
    const { metaApplicant, url, actionAddInterview, actionGetApplicant, match: { params } } = this.props;
    const self = this;
    values.jobAppliedId = this.state.idApplicant
    values.phaseId = Number(params.phaseId)
    values.interviewDate = moment(values.interviewDate).format('DD/MM/YYYY')
    values.interviewTime = moment(values.interviewTime).format('HH:mm:ss')
    values.isResult = true
    if(values.isResult){
      this.setState({
        visible: true,
        valueForm: values
      })
    }else {
      confirm({
        title: 'This application will be moved to rejected.',
        icon: <ExclamationCircleOutlined />,
        content: 'Are you sure?',
        okText: 'Yes',
        cancelText: 'No',
        onOk() {
          return actionAddInterview(values, () => {
            message.success('Successfully moved')
            actionGetApplicant(metaApplicant, url, (response) => {
              self.setState({ dataApplicant: null })
            }, (err) => message.error(err))
          })
        },
      });
    }
  }

  openModalProfile = (id) => {
    const { getApplicant } = this.props;
    const findCandidate = getApplicant.data.find(item => item.candidateId === id)
    this.setState({
      visibleProfile: true,
      documentName: `${findCandidate.applicantNumber}-${findCandidate.name}`
    })
  }

  showModalNotes = (id) => {
    this.setState({ isModalNotes: true, idApplicant: id })
  };

  showModalHistory = (id) => {
    this.setState({ isModalHistory: true, idApplicant: id })
  };

  showModalDev = (id) => {
    this.setState({ isModalDev: true, idApplicant: id })
  };

  showModalReject = (id) => {
    this.setState({ isModalReject: true, idApplicant: id })
  };

  onCancel = () => {
    this.setState({ visible: false, visibleProfile: false, isModalNotes: false, isModalHistory: false, isModalDev: false, isModalReject: false })
  }

  onSubmit = () => {
    const { valueForm } = this.state;
    const { metaApplicant, url, actionAddInterview, actionGetApplicant } = this.props;
    this.setState({ submitLoading: true })
    return actionAddInterview(valueForm, () => {
      this.setState({ visible: false, submitLoading: false })
      message.success('Successfully moved')
      actionGetApplicant(metaApplicant, url, (response) => {
        this.setState({ dataApplicant: null })
      })
    }, (err) => {
      this.setState({ submitLoading: false }, () => {
        message.error(err)
      })
    })
  }

  onFinishNotes = value => {
    const { idApplicant } = this.state
    const { metaApplicant, url, actionAddApplicantNotes, actionGetApplicant, actionListApplicantNotes } = this.props;
    
    value.jobAppliedId = idApplicant

    return actionAddApplicantNotes(value, url, () => {
      message.success('Successfully update applicant notes')
      actionListApplicantNotes(idApplicant)
      actionGetApplicant(metaApplicant, url, (response) => {
        this.setState({ dataApplicant: response.data.filter(item => item.id === idApplicant).pop() })
      }, (err) => message.error(err))
    }, (err) => message.error(err))
  }

  onFinishDev = values => {
    const { metaApplicant, url, actionAddDevelopment, actionGetApplicant } = this.props;
    values.jobAppliedId = this.state.idApplicant
    values.startDate = values.startDate ? moment(values.startDate).format('DD/MM/YYYY') : null
    values.targetDate = values.targetDate ? moment(values.targetDate).format('DD/MM/YYYY') : null
    return actionAddDevelopment(values, () => {
      message.success('Successfully moved')
      actionGetApplicant(metaApplicant, url, (response) => {
        this.setState({ dataApplicant: null, isModalDev: false })
      }, (err) => message.error(err))
    })
  }

  onFinishReject = values => {
    const { metaApplicant, url, actionAddInterview, actionGetApplicant, match: { params } } = this.props;
    values.jobAppliedId = this.state.idApplicant
    values.phaseId = Number(params.phaseId)
    values.isResult = false
    this.setState({ submitLoading: true })
    return actionAddInterview(values, () => {
      message.success('Successfully moved')
      actionGetApplicant(metaApplicant, url, (response) => {
        this.setState({ dataApplicant: null, isModalReject: false, submitLoading: false })
      }, (err) => {
        this.setState({ submitLoading: false }, () => {
          message.error(err)
        })
      })
    })
  }

  // handleInfiniteOnLoad = () => {
  //   ('scroll');
  // }

  render() {
    const { idApplicant, dataApplicant, isMethod, isResult, valueForm, visible, visibleProfile, submitLoading, documentName, isModalNotes, isModalHistory, isModalDev, isModalReject } = this.state;
    const { getData: { data, loading }, getApplicant, getApplicantNotes, getProfile, onSearchApplicant, handlePagination  } = this.props;
    
    if(loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Row gutter={[16, 24]}>
          {/* Content */}
          <Col span={6}>
            <AutoComplete
              onSearch={onSearchApplicant} 
              style={{ width: '100%' }} 
              allowClear
            >
              <Input 
                prefix={<SearchOutlined />} 
                placeholder="Search by Applicant Name" 
              />
            </AutoComplete>
          </Col>
          <Col span={24}>
            <Text>Showing {getApplicant.pagination.total} Data</Text>
          </Col>
          <Col span={6}>
            {
              getApplicant.loading ?
                <Skeleton active />
              :
                <ListApplicant 
                  type="qualified"
                  loading={getApplicant?.loading} 
                  pagination={getApplicant?.pagination}
                  dataSource={getApplicant?.data}
                  idApplicant={idApplicant}
                  selectedApplicant={(value) => this.selectedApplicant(value)}
                  // handleInfiniteOnLoad={() => this.handleInfiniteOnLoad()} 
                  handlePagination={(e) => handlePagination(e)}
                />
            }
          </Col>
          <Col span={18}>
            <Card>
              {
                dataApplicant ? 
                  <ContentQualified 
                    dataApplicant={dataApplicant}
                    formRef={this.formRef} 
                    isResult={isResult} 
                    isMethod={isMethod} 
                    onFinish={this.onFinish} 
                    openModalProfile={this.openModalProfile}
                    handleChangeAssessmentResult={(e) => this.handleChangeAssessmentResult(e)}
                    handleChangeMethod={(e) => this.handleChangeMethod(e)}
                    showModalNotes={this.showModalNotes}
                    showModalHistory={this.showModalHistory}
                    showModalDev={this.showModalDev}
                    showModalReject={this.showModalReject}
                    getApplicantNotes={getApplicantNotes}
                  />
                : 
                  <Empty description={<Text>Please click an applicant name to start the qualified</Text>} />
              }
            </Card>
          </Col>
        </Row>
        <Modal
          key="1"
          width={650}
          onCancel={this.onCancel}
          visible={visible}
          title="The application will be processed to the next stage"
          footer={[
            <Button className="btn-cancel-danger" key="back" onClick={this.onCancel}>No</Button>,
            <Button className="btn-save-primary" key="submit" type="primary" onClick={this.onSubmit} loading={submitLoading}>Yes</Button>
          ]}
        >
          <ContentModal visible={visible} valueForm={valueForm} />
        </Modal>
        <Modal 
          centered
          width={1000}
          title="Applicant Profile"
          visible={visibleProfile}
          onCancel={this.onCancel}
          footer={
            <>
              <DownloadFile data={data} getProfile={getProfile} documentName={documentName} />
              <Button key="back" type="primary" onClick={this.onCancel}>Okay</Button>
            </>
          }
        >
          <div style={{ height: 430, overflowY: 'auto', overflowX: 'hidden', padding: 32 }}>
            <div ref={(response) => (this.componentRef = response)}>
              <ViewProfile {...this.props} />
            </div>
          </div>
        </Modal>
        <Modal centered width={800} title="Applicant Notes" visible={isModalNotes} onCancel={this.onCancel} footer={null} destroyOnClose>
          <ApplicantNotes {...this.props} onFinishNotes={this.onFinishNotes} />
        </Modal>
        <Modal centered width={800} title="Applicant History" visible={isModalHistory} onCancel={this.onCancel} footer={null} destroyOnClose>
          <ApplicantHistory {...this.props} dataApplicantHistory={dataApplicant?.listHistory} />
        </Modal>
        <Modal title="Process for Development" width={650} visible={isModalDev} onCancel={this.onCancel} footer={null} destroyOnClose>
          <ApplicantDevelopment {...this.props} onFinishDev={this.onFinishDev} />   
        </Modal>
        <Modal title="Application Rejection" visible={isModalReject} onCancel={this.onCancel} footer={null} destroyOnClose>
          <ApplicationRejection {...this.props} submitLoading={submitLoading} notes="notes" onFinishReject={this.onFinishReject} />
        </Modal>


      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail, unmountGetApplicant, unmountGetProfile, unmountApplicantNotes } = this.props;
    unmountGetApplicant()
    unmountGetDetail()
    unmountGetProfile()
    unmountApplicantNotes()
  }
}

const mapStateToProps = (state) => ({
  getData           : state.jobs.activeApplicant,
  getApplicant      : state.jobs.listApplicant,
  getProfile        : state.candidate.detail,
  getApplicantNotes : state.jobs.listApplicantNotes
})

const mapDispatchToProps = {
  actionAddInterview        : addInterview,
  actionAddDevelopment      : addDevelopment,
  actionInactive            : inactiveJob,
  actionGetApplicant        : listApplicant,
  actionGetProfile          : detailCandidate,
  actionGetDetail           : detailJobActiveApplicant,
  actionAddApplicantNotes   : addApplicantNotes,
  actionListApplicantNotes  : listApplicantNotes,
  unmountGetApplicant       : unmountListApplicant,
  unmountGetProfile         : unmountDetailCandidate,
  unmountGetDetail          : unmountDetailJobsActiveApplicant,
  unmountApplicantNotes     : unmountApplicantNotes
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailQualifiedEmployerJobs)