import React from 'react'
import { Button, Card, Col, Descriptions, Row, Space, Typography, Divider, Tag } from "antd";
import { paymentOption } from '../../../../../utils/constant/paymentOption'
const { Text } = Typography;

export default function Unpaid(props) {
  const { getData, showModalCancel } = props
  return (
    <React.Fragment>
      <Card bordered={false} style={{ border: 'none' }}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Space size={32}>
              <Text style={{ fontSize: 15, fontWeight: 'bold' }}>Order Information</Text>
              <Tag color="blue" style={{ padding: '4px 32px 4px 32px', fontSize: 14 }}>Unpaid</Tag>
            </Space>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
          <Col span={24}>
            <Descriptions layout="vertical" size='small'>
              <Descriptions.Item label="Order Number">{getData.data.xpertTransactionNumber}</Descriptions.Item>
              <Descriptions.Item label="Order Date and Time">{getData.data.orderDate}</Descriptions.Item>
              <Descriptions.Item label="Expiry Date and Time">{getData.data.expiredDate}</Descriptions.Item>
              <Descriptions.Item label="Buyer">{getData.data.candidateName}</Descriptions.Item>
              <Descriptions.Item label="Buyer Mobile Number">+{getData.data.candidateMobilePhone}</Descriptions.Item>
              <Descriptions.Item label="Buyer Email">{getData.data.candidateEmail}</Descriptions.Item>
              {
                getData?.data?.paymentOption ?
                  <Descriptions.Item label="Payment Scheme">{getData.data.paymentOption ? paymentOption.find(item => item.value === getData.data.paymentOption)?.name : '-'}</Descriptions.Item>
                : null
              }
            </Descriptions>
          </Col>
        </Row>
        <Divider />
        <Row gutter={[16, 16]} style={{ marginTop: 20, background: '#e0e0e0', padding: 16 }}>
          <Col lg={8} xxl={12}>
            <Text>Ticket</Text>
          </Col>
          <Col lg={2} xxl={2}>
            <Text>Qty</Text>
          </Col>
          <Col lg={4} xxl={4}>
            <Text>Price</Text>
          </Col>
          <Col lg={10} xxl={6}>
            <Text>Sub Total</Text>
          </Col>
        </Row>
        {
          getData.data.ticketList.map((item, i) => 
            <Row key={i} gutter={[16, 16]} style={{ border: '1px solid #e0e0e0', padding: 16 }}>
              <Col lg={8} xxl={12}>
                <Text>{item.name}</Text>
              </Col>
              <Col lg={2} xxl={2}>
                <Text>x{item.qty}</Text>
              </Col>
              <Col lg={4} xxl={4}>
                <Text>{`IDR ${item?.price?.toLocaleString()}`}</Text>
              </Col>
              <Col lg={10} xxl={6}>
                <Text>{`IDR ${item.totalPrice.toLocaleString()}`}</Text>
              </Col>
            </Row>
          )
        }
        <Row gutter={[16, 16]} justify="space-around" align="middle" style={{ padding: 16 }}>
          <Col lg={14} xxl={18} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Text type='secondary'>Total</Text>
          </Col>
          <Col lg={5} xxl={3}>
            <Text>{`IDR ${getData.data.totalTransaction.toLocaleString()}`}</Text>
          </Col>
          <Col lg={5} xxl={3}>
            <Button type="danger" block onClick={() => showModalCancel()}>Cancel Transaction</Button>
          </Col>
        </Row>
        <Divider />
        <Row gutter={[16, 16]} >
          <Col span={24}>
            <Descriptions title='Xpert Information' layout="vertical" size='small' column={2}>
              <Descriptions.Item label={<Text type='secondary'>Xpert Title</Text>}>{getData.data.xpertName}</Descriptions.Item>
              <Descriptions.Item label={<Text type='secondary'>Start Date</Text>}>{getData.data.xpertStartDate}</Descriptions.Item>
              {/* <Descriptions.Item label={<Text type='secondary'>Start Time</Text>}>{getData.data.xpertStartTime}</Descriptions.Item> */}
              <Descriptions.Item label={<Text type='secondary'>Organizer</Text>}>{getData.data.provider}</Descriptions.Item>
              <Descriptions.Item label={<Text type='secondary'>End Date</Text>}>{getData.data.xpertEndDate}</Descriptions.Item>
              {/* <Descriptions.Item label={<Text type='secondary'>End Time</Text>}>{getData.data.xpertEndTime}</Descriptions.Item> */}
            </Descriptions>
          </Col>
        </Row>
      </Card>
    </React.Fragment>
  )
}