import React, { Component } from 'react'
import ReactToPrint from 'react-to-print'
import { Space, Button, Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';

// import { downloadImmatrikulation } from '../../utils/downloadImmatrikulation202203';
import { downloadImmatrikulation } from '../../utils/downloadImmatrikulation15062023';
// import { downloadImmatrikulationStamp } from '../../utils/downloadImmatrikulationStamp202203';
import { downloadImmatrikulationStamp } from '../../utils/downloadImmatrikulationStamp15062023';
// import { downloadSuket } from '../../utils/downloadSuket202203';
import { downloadSuket } from '../../utils/downloadSuket15062023';
// import { downloadSuketSignature } from '../../utils/downloadSuketSignature202203';
import { downloadSuketSignature } from '../../utils/downloadSuketSignature15062023';
// import { downloadCV } from '../../utils/downloadCV202203MM';
import { downloadCV } from '../../utils/downloadCV15062023';
import { downloadKTM } from '../../utils/downloadKTM';
import { downloadGuaranteeLetter } from '../../utils/downloadGuaranteeLetter';
import { downloadSuketLibur } from '../../utils/downloadSuketLibur';

import ViewProfile from '../../components/Modal/ApplicantProfile';

export default class DownloadFile extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
      downloadLoading: false
    }
  }

  generateImmatrikulation = (data) => {
    this.setState({ downloadLoading: true })
    setTimeout(
      () => 
        this.setState({ downloadLoading: false }, downloadImmatrikulation(data)), 
      1000
    )
  }

  generateImmatrikulationStamp = (data) => {
    this.setState({ downloadLoading: true })
    setTimeout(
      () => 
        this.setState({ downloadLoading: false }, downloadImmatrikulationStamp(data)), 
      1000
    )
  }

  generateSuket = (data) => {
    this.setState({ downloadLoading: true })
    setTimeout(
      () => 
        this.setState({ downloadLoading: false }, downloadSuket(data)), 
      1000
    )
  }

  // generateGuaranteeLetter = (data) => {
  //   downloadGuaranteeLetter(data)
  // }

  generateSuketSignature = (data) => {
    this.setState({ downloadLoading: true })
    setTimeout(
      () => 
        this.setState({ downloadLoading: false }, downloadSuketSignature(data)), 
      1000
    )
  }

  generateCV = (data) => {
    this.setState({ downloadLoading: true })
    setTimeout(
      () => 
        this.setState({ downloadLoading: false }, downloadCV(data)), 
      1000
    )
  }

  generateKTM = (data) => {
    this.setState({ downloadLoading: true })
    setTimeout(
      () => 
        this.setState({ downloadLoading: false }, downloadKTM(data)), 
      1000
    )
  }

  generateGuaranteeLetter = (data) => {
    this.setState({ downloadLoading: true })
    setTimeout(
      () => 
        this.setState({ downloadLoading: false }, downloadGuaranteeLetter(data)), 
      1000
    )
  }

  generateSuketLibur = (data) => {
    this.setState({ downloadLoading: true })
    setTimeout(
      () => 
        this.setState({ downloadLoading: false }, downloadSuketLibur(data)), 
      1000
    )
  }

  render() {
    const { data, getProfile, documentName } = this.props
    const { downloadLoading } = this.state

    const DropdownMenu = (
      <Menu>
        <Menu.Item key="1" onClick={() => this.generateImmatrikulation(getProfile.data)}>Immatrikulation</Menu.Item>
        <Menu.Item key="2" onClick={() => this.generateImmatrikulationStamp(getProfile.data)}>Immatrikulation Stamp</Menu.Item>
        <Menu.Item key="3" onClick={() => this.generateSuket(getProfile.data)}>Surat Keterangan Mahasiswa (SKM)</Menu.Item>
        <Menu.Item key="4" onClick={() => this.generateSuketSignature(getProfile.data)}>SKM + Tanda Tangan Digital</Menu.Item>
        <Menu.Item key="5" onClick={() => this.generateCV(getProfile.data)}>CV</Menu.Item>
        <Menu.Item key="6" onClick={() => this.generateKTM(getProfile.data)}>Kartu Tanda Mahasiswa</Menu.Item>
        {/* <Menu.Item key="7" onClick={() => this.generateGuaranteeLetter(getProfile.data)}>Guarantee Letter</Menu.Item> */}
        <Menu.Item key="8" onClick={() => this.generateSuketLibur(getProfile.data)}>Surat Keterangan Libur</Menu.Item>
      </Menu>
    )
    
    return (
      data.id === 106 || data.id === 123 ?
        <Dropdown overlay={DropdownMenu} trigger='click'>
          <Button loading={downloadLoading}>
            <Space>
              Download Files
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      :
        <React.Fragment>
          <ReactToPrint
            content={() => this.componentRef}
            trigger={() => <Button type="info" style={{ marginLeft: 8, marginRight: 8 }}>Download CV</Button>}
            documentTitle={documentName}
            pageStyle={{ height: 'auto' }}
          />
          <div style={{ display: 'none'  }}>
            <div ref={(response) => (this.componentRef = response)}>
              <ViewProfile {...this.props} />
            </div>
          </div>
        </React.Fragment>
    )
  }
}