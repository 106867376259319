import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Loading } from '../../../../components';
import { paymentOption } from '../../../../utils/constant/paymentOption';
import { CalendarOutlined } from '@ant-design/icons';
import { PageHeader, Row, Col, Breadcrumb, message, Modal, Button, Typography, Divider, Card, Space, Image } from 'antd';
import { detailService, disableService, enableService, unmountDetailService } from '../../../../redux/actions/service/serviceAction';
import { DetailHeader, DetailTicket, LastTransaction, FormTicket, } from './Partial';
import { createTicket, detailTicket, disableTicket, editTicket, enableTicket, listTicketByXpert, unmountDetailTicket, unmountListTicketByXpert } from '../../../../redux/actions/ticket/ticketAction';
import { masterBankAccount, unmountMasterBankAccount } from '../../../../redux/actions/bankAccount/bankAccountAction';

const { Text } = Typography;

class DetailService extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
    this.state = {
      submitLoading: false,
      visibleAdd: false,
      visibleEdit: false,
      isFree: false,
      isSameDate: false,
      isSameTime: false,
      isLimit: false,
      isDiscount: false,
      dataForm: {},
      visiblePayment: false,
      colIndexBankAccount: 1,
      listBankAccount: [],
      tempListBankAccount: [],
      optionPaymentScheme: [
        { key: 0, value: 'ONE_TIME_PAYMENT', name: 'One Time Payment', disabled: false, col: null },
        { key: 1, value: 'INSTALLMENT', name: 'Installment', disabled: false, col: null },
        { key: 2, value: 'LOAN', name: 'Loan', disabled: false, col: null }
      ],
      colIndexPaymentScheme: 0,
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData(){
    const { actionGetDetail, actionGetListTicket, match: { params } } = this.props;
    actionGetDetail(params.id)
    actionGetListTicket(params.id)
  }

  handleEnable = (value) => {
    const { actionEnable, getDataTicket } = this.props
    
    if(getDataTicket.data.length > 0){
      return this.setState({ submitLoading: true }, () => {
        return actionEnable(value, () => {
          message.success('Successfully changed to active')
          this.setState({ submitLoading: false })
          return this.getData()
        }, (err) => message.error(err))
      })
    }else{
      Modal.warning({
        title: 'Unable to change the status to inactive as there are no available tickets for this event. If you want to change it to active you must create at least one ticket.',
        width: 600
      });
    }
  }

  disabledDate = (current) => {
    let yesterday = moment().subtract(0, 'days');
    return !current || current.isBefore(yesterday);
  }


  handleDisable = (value) => {
    const { actionDisable } = this.props;
    return this.setState({ submitLoading: true }, () => {
      return actionDisable(value, () => {
        message.success('Successfully changed to inactive')
        this.setState({ submitLoading: false })
        return this.getData()
      }, (err) => message.error(err))
    })
  }

  onClickEdit = (id) => {
    this.props.history.push(`/service/edit/${id}`)
  }

  onSubmit = () => {
    const { isFree, isSameDate, isSameTime } = this.state;
    const { actionAddTicket, getData: { data } } = this.props;
    this.setState({ submitLoading: true }, () => {
      this.formRef.current.validateFields().then(values => {
        values.price = values.price ? values.price : 0
        values.discountedPrice = values.discountedPrice ? values.discountedPrice : 0
        values.dpPrice = values.dpPrice ? values.dpPrice : 0
        values.isFree = isFree
        values.isSameDate = isSameDate
        values.isSameTime = isSameTime
        values.date = values.date ? moment(values.date).format('DD/MM/YYYY') : null
        values.time = moment(values.time).format('HH:mm:ss')
        values.xpertId = Number(data.id)
        values.listScheme.map(item => 
          item.listSchemeDetail.map(res => 
            res.dueDate = moment(res.dueDate).format('DD/MM/YYYY')
          )
        )
        actionAddTicket(values, () => {
          this.setState({ submitLoading: false, visibleAdd: false })
          message.success('Data created successfully')
          this.getData()
        }, (err) => {
          this.setState({ submitLoading: false }, () => message.error(err))
        })
      }).catch(() => {
        this.setState({ submitLoading: false })
      })
    })
  }

  onSubmitEdit = () => {
    const { isFree, isSameDate, isSameTime } = this.state;
    const { actionUpdateTicket, getData: { data }, getDetailTicket } = this.props;
    this.setState({ submitLoading: true }, () => {
      this.formRef.current.validateFields().then(values => {
        values.price = values.price ? values.price : 0
        values.discountedPrice = values.discountedPrice ? values.discountedPrice : 0
        values.dpPrice = values.dpPrice ? values.dpPrice : 0
        values.isFree = isFree
        values.isSameDate = isSameDate
        values.isSameTime = isSameTime
        values.date = values.date ? moment(values.date).format('DD/MM/YYYY') : null
        values.time = moment(values.time).format('HH:mm:ss')
        values.xpertId = Number(data.id)
        values.listScheme.map(item => 
          item.listSchemeDetail.map(res => 
            res.dueDate = moment(res.dueDate).format('DD/MM/YYYY')
          )
        )
        values.id = getDetailTicket.data.id
        actionUpdateTicket(values, () => {
          this.setState({ submitLoading: false, visibleEdit: false })
          message.success('Data update successfully')
          this.getData()
        }, (err) => {
          this.setState({ submitLoading: false }, () => message.error(err))
        })
      }).catch(() => {
        this.setState({ submitLoading: false })
      })
    })
  }

  onCancel = (type) => {
    const { unmountGetDetailTicket, unmountGetBankAccount } = this.props;
    unmountGetBankAccount()
    if(type === 'add'){
      this.setState({ 
        visibleAdd: false,
        isFree: false,
        isSameDate: false,
        isSameTime: false,
        isLimit: false,
        isDiscount: false,
        listBankAccount: [],
        tempListBankAccount: [],
        colIndexBankAccount: 1,
        colIndexPaymentScheme: 0,
        optionPaymentScheme: [
          { key: 0, value: 'ONE_TIME_PAYMENT', name: 'One Time Payment', disabled: false, col: null },
          { key: 1, value: 'INSTALLMENT', name: 'Installment', disabled: false, col: null },
          { key: 2, value: 'LOAN', name: 'Loan', disabled: false, col: null }
        ],
      })
    }
    if(type === 'edit'){
      unmountGetDetailTicket()
      this.setState({ 
        visibleEdit: false,
        isFree: false,
        isSameDate: false,
        isSameTime: false,
        isLimit: false,
        isDiscount: false,
        listBankAccount: [],
        tempListBankAccount: [],
        colIndexBankAccount: 1,
        colIndexPaymentScheme: 0,
        optionPaymentScheme: [
          { key: 0, value: 'ONE_TIME_PAYMENT', name: 'One Time Payment', disabled: false, col: null },
          { key: 1, value: 'INSTALLMENT', name: 'Installment', disabled: false, col: null },
          { key: 2, value: 'LOAN', name: 'Loan', disabled: false, col: null }
        ],
      })
    }
     if(type === 'modalPayment'){
      unmountGetDetailTicket()
      this.setState({ 
        visiblePayment: false,
        listBankAccount: [],
      })
    }
  }

  showModal = (type, item) => {
    const { optionPaymentScheme } = this.state
    const { actionGetDetailTicket, actionGetBankAccount, getData } = this.props;
    if(getData.data.status !== 'DRAFT'){
      if(type === 'add'){
        this.setState({ 
          visibleAdd: true, 
          dataForm: {},
          isFree: false,
          isSameDate: false,
          isSameTime: false,
          isLimit: false, 
          isDiscount: false
        })
        actionGetBankAccount((response) => {
          this.setState({ listBankAccount: response })
        })
  
      }
      if(type === 'edit'){
        actionGetDetailTicket(item.id, (response) => {
          response.data.listScheme.map(item => 
            optionPaymentScheme.filter(res => res.value === item.paymentOption)[0].disabled = true
          )
          response.data.listScheme.map((item, i) => 
            optionPaymentScheme[i].col = i
          )
          this.setState({ 
            visibleEdit: true,
            isFree: response.data.isFree,
            isSameDate: response.data.isSameDate,
            isSameTime: response.data.isSameTime,
            isLimit: response.data.isLimit,
            dataForm: response.data,
            isDiscount: response.data.discountedPrice ? true : false,
            colIndexBankAccount: response.data.listBankAccount.length,
            tempListBankAccount: response.data.listBankAccount.map(item => item.bankAccountId),
            colIndexPaymentScheme: response.data.listScheme.length-1,
            optionPaymentScheme
          })
        })
        actionGetBankAccount((response) => {
          this.setState({ listBankAccount: response })
        })
      }
      if(type === 'modalPayment'){
        actionGetDetailTicket(item.id, (response) => {
          this.setState({
            visiblePayment: true,
            dataForm: response.data
          })
        })
      }
    }else{
      Modal.warning({
        title: `Unable to create a ticket because the service status is still 'Draft.' You must complete the service creation first.`,
        onOk() {},
        width: 500
      });
    }
  }

  showModalPayment = () => {
    this.setState ({ visiblePayment: true})
  }

  onCancelPayment = () => {
    this.setState ({ visiblePayment: false})
  }

  enableTicket = (value) => {
    const { actionEnableTicket } = this.props;
    return actionEnableTicket(value, () => {
      message.success('Successfully changed to active')
      return this.getData()
    }, (err) => message.error(err))
  }

  disableTicket = (value) => {
    const { actionDisableTicket } = this.props;
    return actionDisableTicket(value, () => {
      message.success('Successfully changed to inactive')
      return this.getData()
    }, (err) => message.error(err))
  }

  handleSwitch = (value, id) => {
    if(value){
      this.enableTicket(id)
    }else{
      this.disableTicket(id)
    }
  }

  handleChecked = (e, type) => {
    const { getData: { data }} = this.props
    const isSameDate = moment(data.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const isSameTime = moment(data.startTime, 'HH:mm:ss').subtract(2, 'hours').format('HH:mm');
    const timeBefore = moment(isSameTime, 'HH:mm')
    this.setState({ [type]: e.target.checked })
    if(e.target.checked){
      if(type === 'isSameDate'){
        this.formRef.current.setFieldsValue({
          date: moment(isSameDate)
        });
      }
      if(type === 'isSameTime'){
        this.formRef.current.setFieldsValue({
          time: timeBefore
        });
      }
      if(type === 'isFree'){
        this.formRef.current.setFieldsValue({
          price: null
        });
      }
    }else{
      if(type === 'isSameDate'){
        this.formRef.current.setFieldsValue({
          date: null
        });
      }
      if(type === 'isSameTime'){
        this.formRef.current.setFieldsValue({
          time: null
        });
      }
    }
  }

  handleLimit = (e) => {
    this.setState({
      isLimit: e.target.value
    })
  }

  onChangeDate = (date, dateString) => {
    const { expDate } = this.state
    this.setState({ issueDate: dateString })
    if(expDate <= dateString){
      this.setState({ isDate: true })
    }else{
      this.setState({ isDate: false })
    }
  }

  handleCheckedDiscount = (e) => {
    this.setState({ isDiscount: e.target.checked })
    this.formRef.current.setFieldsValue({
      discountedPrice: null
    })
  }

  onChangeBankAccount = (bankAccountId, val) => {
    const { tempListBankAccount } = this.state
    if(tempListBankAccount.filter((res,i) => i === val.key).length > 0){
      tempListBankAccount.splice(val.name, 1, bankAccountId);
    }else{
      tempListBankAccount.push(bankAccountId)
    }
    this.setState({
      tempListBankAccount
    })
  }

  addBankAccount = () => {
    const { colIndexBankAccount } = this.state
    this.setState({ colIndexBankAccount: colIndexBankAccount+1 })
  }

  removeBankAccount = (val) => {
    const { tempListBankAccount, colIndexBankAccount } = this.state
    tempListBankAccount.splice(val.name, 1);
    this.setState({ 
      tempListBankAccount,
      colIndexBankAccount: colIndexBankAccount-1
    })
  }

  onChangePaymentScheme = (_, val, i) => {
    const { optionPaymentScheme } = this.state
    if(optionPaymentScheme.filter(item => item.col === i).length > 0){
      optionPaymentScheme.filter(item => item.col === i)[0].disabled = false
      optionPaymentScheme.filter(item => item.col === i)[0].col = null
    }
    optionPaymentScheme[val.key].disabled = true
    optionPaymentScheme[val.key].col = i
    this.setState({ optionPaymentScheme })
  }

  addPaymentScheme = () => {
    const { colIndexPaymentScheme } = this.state
    this.setState({ colIndexPaymentScheme: colIndexPaymentScheme+1 })
  }

  removePaymentScheme = (val) => {
    const { colIndexPaymentScheme, optionPaymentScheme } = this.state
    optionPaymentScheme.filter(item => item.col === val.key)[0].disabled = false
    optionPaymentScheme.filter(item => item.col === val.key)[0].col = null
    this.setState({ 
      colIndexPaymentScheme: colIndexPaymentScheme-1,
      optionPaymentScheme
    })
  }

  render() {
    const { getData: { data, loading }, getDataTicket, getDetailTicket } = this.props;
    const { visibleAdd, submitLoading, visibleEdit, dataForm, visiblePayment } = this.state;

    if(loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><CalendarOutlined /> Service</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/service">Service List</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{data.xpertNumber ? data.xpertNumber : '-' }</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Service Details' />
          </Col>
          {/* Descriptions */}
          <Col span={24}>
            <DetailHeader 
              data={data}
              handleEnable={this.handleEnable}
              handleDisable={this.handleDisable}
              onClickEdit={this.onClickEdit}
              submitLoading={submitLoading}
            />
          </Col>
          <Col span={12}>
            <DetailTicket 
              dataTicket={getDataTicket}
              showModal={this.showModal}
              handleSwitch={this.handleSwitch}
            />
          </Col>
          <Col span={12}>
            <LastTransaction 
              data={data}
            />
          </Col>
        </Row>
        <Modal
          key="1"
          centered
          width={1000}
          destroyOnClose
          visible={visibleAdd}
          onCancel={() => this.onCancel('add')}
          title={<Text strong>Add Ticket</Text>}
          footer={[
            <React.Fragment key="1">
              <Button key="back" className="btn-save-primary" onClick={() => this.onCancel('add')}>Cancel</Button>
              <Button key="submit" className="btn-save-primary" type="primary" onClick={this.onSubmit} loading={submitLoading}>Submit</Button>
            </React.Fragment>
          ]}
        >
          <FormTicket 
            {...this.props} 
            {...this.state}
            formRef={this.formRef} 
            onSubmit={this.onSubmit}
            data={dataForm} 
            handleChecked={this.handleChecked}
            onChangeDate={this.onChangeDate}
            handleLimit={this.handleLimit}
            disabledDate={this.disabledDate}
            handleCheckedDiscount={this.handleCheckedDiscount}
            onChangeBankAccount={this.onChangeBankAccount}
            addBankAccount={this.addBankAccount}
            removeBankAccount={this.removeBankAccount}
            onChangePaymentScheme={this.onChangePaymentScheme}
            addPaymentScheme={this.addPaymentScheme}
            removePaymentScheme={this.removePaymentScheme}
          />
        </Modal>
        <Modal
          key="2"
          centered
          width={1000}
          destroyOnClose
          visible={visibleEdit}
          onCancel={() => this.onCancel('edit')}
          title={<Text strong>Edit Ticket</Text>}
          footer={[
            <Button key="submit" className="btn-save-primary" type="primary" onClick={this.onSubmitEdit} loading={submitLoading}>Update</Button>
          ]}
        >
          <FormTicket 
            {...this.props} 
            {...this.state}
            formRef={this.formRef} 
            onSubmit={this.onSubmit}
            data={dataForm} 
            handleChecked={this.handleChecked}
            onChangeDate={this.onChangeDate}
            handleLimit={this.handleLimit}
            disabledDate={this.disabledDate}
            handleCheckedDiscount={this.handleCheckedDiscount}
            onChangeBankAccount={this.onChangeBankAccount}
            addBankAccount={this.addBankAccount}
            removeBankAccount={this.removeBankAccount}
            onChangePaymentScheme={this.onChangePaymentScheme}
            addPaymentScheme={this.addPaymentScheme}
            removePaymentScheme={this.removePaymentScheme}
          />
        </Modal>
        <Modal
         centered
         width={1000}
         footer={false}
         visible={visiblePayment}
         onCancel={() => this.onCancel('modalPayment')}
         style={{borderRadius:15, overflow:"auto"}}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Text style={{ color: '#7C7C7C', fontSize: 18 }}>Bank Account</Text>
            </Col>
            {
              getDetailTicket?.data?.listBankAccount?.length > 0 ?
              getDetailTicket?.data?.listBankAccount?.map((item, i) => 
                <Col key={i} span={8}>
                  <Card className='card-border' style={{ border: 'none', padding: 16 }} bodyStyle={{ padding: 0 }}>
                    <Space direction='vertical' size={0}>
                      <Image 
                        preview={false}
                        width={100}
                        height={50}
                        src={item.bankLogoUrl}
                        style={{ objectFit: 'contain' }}
                      />
                      <Space direction='vertical' size={0}>
                        <Text>{item.bankAccountName}</Text>
                        <Text>{item.bankName} - {item.bankAccountNumber}</Text>
                      </Space>
                    </Space>
                  </Card>
                </Col>
              )
              : null
            }
          </Row>
          <Divider/>
          <Row>
            <Col span={24}>
              <Text style={{ padding : 8, fontSize : 18,color: '#7C7C7C' }}>Payment Scheme</Text>
              {
                getDetailTicket?.data?.listScheme?.length > 0 ?
                  getDetailTicket?.data?.listScheme.map((data, i) => 
                    <Card
                      key={i}
                      className='card-border'
                      style={{ border: 'none', padding: 0, marginTop: 25 }}
                      bodyStyle={{ padding: 0 }}
                    >
                      <Text style={{ padding :16, fontWeight : "600" , fontSize : "16px", color : "grey", position : "relative" , display : "inline-block"}}>{data.paymentOption ? paymentOption.find(item => item.value === data.paymentOption)?.name : '-'}</Text>
                      <Row>
                        <Col span={8} style={{ background: '#F2F2F2' }}></Col>
                        <Col span={8} style={{ background: '#F2F2F2', textAlign : "left", paddingTop: 8, paddingBottom: 8, padding: '12px 16px' }}><Text strong>Fee</Text></Col>
                        <Col span={8} style={{ background: '#F2F2F2', textAlign : "left", paddingTop: 8, paddingBottom: 8, padding: '12px 16px' }}><Text strong>Date of Purchase</Text></Col>
                      </Row>
                      {
                        data.listSchemeDetail.map((data, i) => (
                          <Row>
                            <Col span={8} style={{ padding: '12px 16px' }}>{data.subject ? data.subject : 'N/A'}</Col>
                            <Col span={8} style={{ padding: '12px 16px' }}>{`Rp ${data.amount ? data.amount.toLocaleString() : 0}`}</Col>
                            <Col span={8} style={{ padding: '12px 16px' }}>{data.dueDate === moment().format('DD/MM/YYYY') ? 'Make payment now' : `Before ${moment(data.dueDate, 'DD/MM/YYYY').format("DD MMMM YYYY")}`}</Col>
                            <Col span={8}></Col>
                          </Row>
                        ))
                      }
                    </Card>
                  )
                : null
              }
            </Col>
          </Row>
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail, unmountGetListTicket, unmountGetDetailTicket } = this.props;
    unmountGetDetail()
    unmountGetListTicket()
    unmountGetDetailTicket()
  }
}

const mapStateToProps = (state) => ({
  getData: state.service.detail,
  getDataTicket: state.ticket.listByXpert,
  getDetailTicket: state.ticket.detail,
  getDataBankAccount: state.bankAccount.master
})

const mapDispatchToProps = {
  actionGetDetail         : detailService,
  actionGetDetailTicket   : detailTicket,
  actionGetListTicket     : listTicketByXpert,
  actionAddTicket         : createTicket,
  actionUpdateTicket      : editTicket,
  actionEnable            : enableService,
  actionDisable           : disableService,
  actionEnableTicket      : enableTicket,
  actionDisableTicket     : disableTicket,
  actionGetBankAccount    : masterBankAccount,
  unmountGetDetail        : unmountDetailService,
  unmountGetDetailTicket  : unmountDetailTicket,
  unmountGetListTicket    : unmountListTicketByXpert,
  unmountGetBankAccount   : unmountMasterBankAccount,
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailService)
