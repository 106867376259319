import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { columns } from './columns';
import { connect } from 'react-redux';
import { FilterMasterData } from '../../../components';
import { Loading } from '../../../components';
import { Row, Col, Breadcrumb, PageHeader, Table, Pagination, message } from 'antd';
import { disableBankAccount, enableBankAccount, listBankAccount, unmountListBankAccount } from '../../../redux/actions/bankAccount/bankAccountAction';
import { masterBank, unmountMasterBank  } from '../../../redux/actions/master/bank/bankAction';
import { DatabaseOutlined } from '@ant-design/icons';
import Menu from '../Menu';

class BankAccount extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      sortedInfo: null,
      meta: {
        page: 1,
        perpage: 10,
        field: null,
        sort: null,
        search: null,
        flagStatus: null,
        flagDisplay: null
      }
    }
    this.onSearch = debounce(this.onSearch.bind(this), 900)
  }

  componentDidMount() {
    const { meta } = this.state;
    const { actionGetData , actionGetBank} = this.props;
    actionGetData(meta)
    actionGetBank()
    
  }

  onSearch = value => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.search = value === '' ? null : value
    return actionGetData(meta)
  }

  onDisplay = value => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10 
    meta.flagDisplay = value
    return actionGetData(this.state.meta)
  }

  onStatus = value => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.flagStatus = value
    return actionGetData(meta)
  }

  onBank = value => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.bankId = value
    return actionGetData(meta)
  }

  pagination = (page, perpage) => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = page
    meta.perpage = perpage
    return actionGetData(meta)
  }

  handleEnable = (value) => {
    const { meta } = this.state;
    const { actionEnable, actionGetData } = this.props;
    return actionEnable(value, () => {
      message.success('Successfully changed to active')
      return actionGetData(meta)
    }, (err) => message.error(err))
  }

  handleDisable = (value) => {
    const { meta } = this.state;
    const { actionDisable, actionGetData } = this.props;
    return actionDisable(value, () => {
      message.success('Successfully changed to inactive')
      return actionGetData(meta)
    }, (err) => message.error(err))
  }

  onClickEdit = (id) => {
    this.props.history.push(`/master-data/edit/${id}`)
  }

  render() {
    const { getData: { loading, data, pagination }, getBank } = this.props;

    if(getBank.loading ){
      return <Loading />
    }

    const defaultStatusFilter = [
      { value: null, name: 'All Status' },
      { value: true, name: 'Active' },
      { value: false, name: 'Inactive' },
    ]

    const objDefault = {
      value: null, name: 'All Bank'
    }
    const defaultBankFilter = getBank?.data?.map((item) => {
      item.value = item.id
      return item
    })
    defaultBankFilter.unshift(objDefault)



    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><DatabaseOutlined /> Master Data</Breadcrumb.Item>
              <Breadcrumb.Item>Master Data List Bank Account</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          { /* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Master Data List Bank Account' />
          </Col>
          { /* Filter */}
          <Col span={24}>
            <FilterMasterData 
              name="Add Bank Account" 
              link="/master-data/create"
              placeholder="Search by Account Name, Account Number"
              onSearch={this.onSearch} 
              onDisplay={this.onDisplay}
              onStatus={this.onStatus}
              fase2={{ key: 'status', value: defaultStatusFilter}}
              onBank={this.onBank}
              fase7={{ key: 'bank', value: defaultBankFilter}}
            />
          </Col>
          { /* Menu Side Bar */}
          <Col span={6} style={{ marginBottom: 20 }}>
            <Menu rowKey="bankAccount" />
          </Col>
          { /* Table */}
          <Col span={18}>
            <Row gutter={[16, 24]}>
              <Col span={24}>
                <Table 
                  size="small"
                  loading={loading} 
                  dataSource={data}
                  pagination={false} 
                  rowKey={(i) => i.id} 
                  columns={columns(this.onClickEdit, this.handleEnable, this.handleDisable)}
                />
              </Col>
              {/* Pagination */}
              <Col span={24}>
                <Pagination
                  total={pagination.total}
                  onChange={this.pagination}
                  current={pagination.page}
                  defaultPageSize={pagination.perpage}
                  showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total} Data`}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetData, unmountMasterBank } = this.props;
    unmountGetData()
    unmountMasterBank()
  }
}

const mapStateToProps = (state) => ({
  getData: state.bankAccount.list,
  getBank: state.bank.master
})

const mapDispatchToProps = {
  actionGetData     : listBankAccount, 
  actionEnable      : enableBankAccount, 
  actionDisable     : disableBankAccount,
  actionGetBank     : masterBank,
  unmountGetData    : unmountListBankAccount ,
  unmountMasterBank  : unmountMasterBank,
}

export default connect(mapStateToProps, mapDispatchToProps)(BankAccount)

