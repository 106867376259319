import dataBenefit from './benefit.json';

export const unmountMasterBenefit = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_BENEFIT'})
}

export const masterBenefit = () => (dispatch) => {
  dispatch({ type: 'LOAD_DATA_MASTER_BENEFIT' })
  if(dataBenefit !== null){
    dispatch({ type: 'LOAD_DATA_MASTER_BENEFIT_SUCCESS', payload : { data: dataBenefit } })
  }else {
    dispatch({ type: 'LOAD_DATA_MASTER_BENEFIT_FAILED' })
  }
}