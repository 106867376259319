import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Typography, Tooltip, Tag } from 'antd';
import { ArrowRightOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
const { Text } = Typography;

export const columns = (sortedInfo, onClickEdit, handleRoute, onClickReason) => {
  return [
    {
      title: 'Number',
      key: 'number',
      fixed: 'left',
      width: 110,
      render: (record) => (
        <Link className="link" to={`/candidate-request/detail/${record.id}`}>
          {record.candidateRecommendationNumber}
        </Link>
      )
    },
    {
      title: 'Request Date',
      key: 'createdDate',
      width: 100,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {moment(record.createdDate).format('DD MMM YYYY')}
        </Text>
      )
    },
    {
      title: 'Contact Person',
      key: 'contactPerson',
      width: 110,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.name ? record.name : '-'}
        </Text>
      )
    },
    {
      title: 'Email',
      key: 'email',
      width: 130,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.email ? record.email : '-'}
        </Text>
      )
    },
    {
      title: 'Mobile Number',
      key: 'mobileNumber',
      width: 120,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {`+${record.callingCode}${record.mobilePhone}`}
        </Text>
      )
    },
    {
      title: 'Status',
      key: 'status',
      width: 60,
      render: (record) => (
        <React.Fragment>
          {
            record.status === 'FOLLOW_UP' ? 
            <Tag color="blue">To Follow Up</Tag>
            : record.status === 'APPROVE' ?
            <Tag color="green">Approved</Tag>
            :
            <Tooltip title="View Reason" arrowPointAtCenter>
              <Link to="#" onClick={() => onClickReason(record.rejectReason)}>
                <Tag icon={<ExclamationCircleOutlined />} color="red">Rejected</Tag>
              </Link>
            </Tooltip>
          }
        </React.Fragment>
      )
    },
    {
      title: '',
      key: 'action',
      width: 30,
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <React.Fragment>
        {
          record.status === 'FOLLOW_UP' ?
            <Tooltip title="Proccess" arrowPointAtCenter>
              <ArrowRightOutlined style={{ fontSize: 20 }} onClick={() => onClickEdit(record.id)}/>
            </Tooltip>
          : null
        }
        </React.Fragment>
      )
    },
  ]
}