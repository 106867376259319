import { API } from '../../../config';
import { errorHandler } from '../auth/errorAction';

export const unmountListFaq = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_FAQ'})
}

// export const unmountDetailFaq = () => (dispatch) => {
//   return dispatch({type: 'UNMOUNT_DETAIL_FAQ'})
// }

// export const unmountMasterFaq = () => (dispatch) => {
//   return dispatch({type: 'UNMOUNT_DATA_MASTER_FAQ'})
// }

export const listFaq = (meta) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_FAQ' })
    return API.GET('/job_faq/list', meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_FAQ_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_FAQ_FAILED' }), 
    ))
  })
}

// export const detailFaq = (id, successCB, failedCB) => async dispatch => {
//   await dispatch({ type: 'LOAD_DETAIL_FAQ' })
//   const params = {
//     'id': id
//   }
//   API.GET('/country/getById', params).then((response) => {
//     dispatch({ 
//       type: 'LOAD_DETAIL_FAQ_SUCCESS', 
//       payload : { 
//         data: response.data 
//       } 
//     })
//     return successCB && successCB(response)
//   }).catch((err) => {
//     return (
//       failedCB && failedCB(err),
//       dispatch(errorHandler(
//         err, 
//         dispatch({ type: 'LOAD_DETAIL_FAQ_FAILED' }), 
//       ))
//     )
//   })
// }

// export const masterFaq = (successCB, failedCB) => async (dispatch) => {
//   await dispatch({ type: 'LOAD_DATA_MASTER_FAQ' })
//   return API.GET('/country/index').then((response) => {
//     dispatch({
//       type: 'LOAD_DATA_MASTER_FAQ_SUCCESS', 
//       payload: { data: response.data }
//     })
//     return successCB && successCB(response.data)
//   }).catch((err) => {
//     failedCB && failedCB()
//     return dispatch(errorHandler(
//       err, 
//       dispatch({ type: 'LOAD_DATA_MASTER_FAQ_FAILED' }), 
//     ))
//   })
// }

export const createFaq = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/job_faq/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const editFaq = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/job_faq/update', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const enableFaq = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/job_faq/enable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const disableFaq = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/job_faq/disable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}