import React from 'react';
import { Typography, Dropdown, Menu, Tag } from 'antd';
import { EditOutlined, DashOutlined, StopOutlined, CheckCircleOutlined } from '@ant-design/icons';
const { Text } = Typography;

const menu = (record, onClickEdit, handleEnable, handleDisable) => (
  <Menu>
    <Menu.Item key={1} icon={<EditOutlined />} onClick={() => onClickEdit(record.id, record.question, record.answer, record.listClient, record.enabled)}>
      Edit
    </Menu.Item>
    {
      record.enabled ?
      <Menu.Item key={2} icon={<StopOutlined />} onClick={() => handleDisable(record.id)}>
        Set as inactive
      </Menu.Item>
      :
      <Menu.Item key={3} icon={<CheckCircleOutlined />} onClick={() => handleEnable(record.id)}>
        Set as active
      </Menu.Item>
    }
  </Menu>

);

export const columns = ( onClickEdit, handleEnable, handleDisable, showModal ) => {
  return [
    {
      title: 'Question',
      key: 'question',
      ellipsis: {
        showTitle: false,
      },
      width: 300,
      render: (record) => (
        <Text>
          {record.question}
        </Text>
      )
    },
    {
      title: 'Answer',
      key: 'answer',
      width: 500,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Text>
          {record.answer}
        </Text>
      )
    },
    {
      title: 'Client Name',
      key: 'clientName',
      ellipsis: {
        showTitle: false,
      },
      width: 200,
      render: (record) => (
        <Text>
          {
            record?.listClient?.map((item) =>
              item.clientName.toString()).join(', ')
          }
        </Text>
      ) 
    },
    {
      title: 'Detail',
      // key: 'detail',
      width: 70,
      render: (record) => (
        <Text className="link" style={{cursor: 'pointer'}} onClick={() => showModal(record.id)}>
          Detail Faq
        </Text>
      )
    },
    {
      title: 'Status',
      key: 'status',
      width: 50,
      render: (record) => (
        <React.Fragment>
          {
            record.enabled ? 
            <Tag color="green">Active</Tag>
            : 
            <Tag color="orange">Inactive</Tag>
          }
        </React.Fragment>
      )
    },
    {
      title: '',
      key: 'action',
      align: 'right',
      fixed: 'right',
      width: 35,
      render: (record) => (
        <Dropdown overlay={menu(record, onClickEdit, handleEnable, handleDisable)} placement="bottomCenter" arrow>
          <DashOutlined style={{ fontSize: 20 }} />
        </Dropdown>
      )
    },
  ]
}