import { API } from '../../../../config';
import { errorHandler } from '../../auth/errorAction';

export const unmountListDestinationCountry = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_DESTINATION_COUNTRY'})
}

export const unmountMasterDestinationCountry = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_DESTINATION_COUNTRY'})
}

export const listDestinationCountry = (meta) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_DESTINATION_COUNTRY' })
  return API.GET('/country/list/destination', meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_DESTINATION_COUNTRY_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_DESTINATION_COUNTRY_FAILED' }), 
    ))
  })
}

export const masterDestinationCountry = (successCB, failedCB) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_DESTINATION_COUNTRY' })
  return API.GET('/country/index/destination').then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_DESTINATION_COUNTRY_SUCCESS', 
      payload: { data: response.data }
    })
    return successCB && successCB(response.data)
  }).catch((err) => {
    failedCB && failedCB()
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_MASTER_DESTINATION_COUNTRY_FAILED' }), 
    ))
  })
}