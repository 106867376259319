import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Loading } from '../../../../components';
import { ReadOutlined } from '@ant-design/icons';
import { PageHeader, Row, Col, Breadcrumb, message, Modal, Button, Typography } from 'antd';
import { detailCourse, disableCourse, enableCourse, unmountDetailCourse } from '../../../../redux/actions/course/courseAction';
import { DetailHeader, DetailTicket, LastTransaction, FormTicket } from './Partial';
import { createTicket, detailTicket, disableTicket, editTicket, enableTicket, listTicketByXpert, unmountDetailTicket, unmountListTicketByXpert } from '../../../../redux/actions/ticket/ticketAction';
const { Text } = Typography;

class DetailCourse extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
    this.state = {
      submitLoading: false,
      visibleAdd: false,
      visibleEdit: false,
      isFree: false,
      isSameDate: false,
      isSameTime: false,
      isLimit: false,
      dataForm: {},
      isDiscount: false
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData(){
    const { actionGetDetail, actionGetListTicket, match: { params } } = this.props;
    actionGetDetail(params.id)
    actionGetListTicket(params.id)
  }

  handleEnable = (value) => {
    const { actionEnable, getDataTicket } = this.props
    
    if(getDataTicket.data.length > 0){
      return this.setState({ submitLoading: true }, () => {
        return actionEnable(value, () => {
          message.success('Successfully changed to active')
          this.setState({ submitLoading: false })
          return this.getData()
        }, (err) => message.error(err))
      })
    }else{
      Modal.warning({
        title: 'Unable to change the status to inactive as there are no available tickets for this event. If you want to change it to active you must create at least one ticket.',
        width: 600
      });
    }
  }

  disabledDate = (current) => {
    let yesterday = moment().subtract(0, 'days');
    return !current || current.isBefore(yesterday);
  }

  handleDisable = (value) => {
    const { actionDisable } = this.props;
    return this.setState({ submitLoading: true }, () => {
      return actionDisable(value, () => {
        message.success('Successfully changed to inactive')
        this.setState({ submitLoading: false })
        return this.getData()
      }, (err) => message.error(err))
    })
  }

  onClickEdit = (id) => {
    this.props.history.push(`/course/edit/${id}`)
  }

  onSubmit = () => {
    const { isFree, isSameDate, isSameTime } = this.state;
    const { actionAddTicket, getData: { data } } = this.props;
    this.setState({ submitLoading: true }, () => {
      this.formRef.current.validateFields().then(values => {
        values.isFree = isFree
        values.isSameDate = isSameDate
        values.isSameTime = isSameTime
        values.date = values.date ? moment(values.date).format('DD/MM/YYYY') : null
        values.time = moment(values.time).format('HH:mm:ss')
        values.xpertId = Number(data.id)
        return actionAddTicket(values, () => {
          return this.setState({ submitLoading: false }, () => {
            return this.setState({ visibleAdd: false }, () => {
              message.success('Data created successfully')
              return this.getData()
            })
          })
        }, (err) => {
          return this.setState({ submitLoading: false }, () => message.error(err))
        })
      }).catch(() => {
        return this.setState({ submitLoading: false })
      })
    })
  }

  onSubmitEdit = () => {
    const { isFree, isSameDate, isSameTime } = this.state;
    const { actionUpdateTicket, getData: { data }, getDetailTicket } = this.props;
    this.setState({ submitLoading: true }, () => {
      this.formRef.current.validateFields().then(values => {
        values.isFree = isFree
        values.isSameDate = isSameDate
        values.isSameTime = isSameTime
        values.date = values.date ? moment(values.date).format('DD/MM/YYYY') : null
        values.time = moment(values.time).format('HH:mm:ss')
        values.xpertId = Number(data.id)
        values.id = getDetailTicket.data.id
        return actionUpdateTicket(values, () => {
          return this.setState({ submitLoading: false }, () => {
            return this.setState({ visibleEdit: false }, () => {
              message.success('Data update successfully')
              return this.getData()
            })
          })
        }, (err) => {
          return this.setState({ submitLoading: false }, () => message.error(err))
        })
      }).catch(() => {
        return this.setState({ submitLoading: false })
      })
    })
  }

  onCancel = (type) => {
    const { unmountGetDetailTicket } = this.props;
    if(type === 'add'){
      return this.setState({ 
        visibleAdd: false,
        isFree: false,
        isSameDate: false,
        isSameTime: false,
        isLimit: false,
        isDiscount: false
      })
    }
    if(type === 'edit'){
      unmountGetDetailTicket()
      this.setState({ 
        visibleEdit: false,
        isFree: false,
        isSameDate: false,
        isSameTime: false,
        isLimit: false,
        isDiscount: false
      })
    }
  }

  showModal = (type, item) => {
    const { actionGetDetailTicket, getData } = this.props;
    if(getData.data.status !== 'DRAFT'){
      if(type === 'add'){
        return this.setState({ 
          visibleAdd: true, 
          dataForm: {},
          isFree: false,
          isSameDate: false,
          isSameTime: false,
          isLimit: false, 
          isDiscount: false
        })
      }
      if(type === 'edit'){
        actionGetDetailTicket(item.id, (response) => {
          this.setState({ 
            visibleEdit: true,
            isFree: response.data.isFree,
            isSameDate: response.data.isSameDate,
            isSameTime: response.data.isSameTime,
            isLimit: response.data.isLimit,
            isDiscount: response.data.discountedPrice ? true : false,
            dataForm: response.data
          })
        })
      }
    }else{
      Modal.warning({
        title: `Unable to create a ticket because the course status is still 'Draft.' You must complete the course creation first.`,
        onOk() {},
        width: 500
      });
    }
  }

  enableTicket = (value) => {
    const { actionEnableTicket } = this.props;
    return actionEnableTicket(value, () => {
      message.success('Successfully changed to active')
      return this.getData()
    }, (err) => message.error(err))
  }

  disableTicket = (value) => {
    const { actionDisableTicket } = this.props;
    return actionDisableTicket(value, () => {
      message.success('Successfully changed to inactive')
      return this.getData()
    }, (err) => message.error(err))
  }

  handleSwitch = (value, id) => {
    if(value){
      this.enableTicket(id)
    }else{
      this.disableTicket(id)
    }
  }

  handleChecked = (e, type) => {
    const { getData: { data }} = this.props
    const isSameDate = moment(data.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const isSameTime = moment(data.startTime, 'HH:mm:ss').subtract(2, 'hours').format('HH:mm');
    const timeBefore = moment(isSameTime, 'HH:mm')
    this.setState({ [type]: e.target.checked })
    if(e.target.checked){
      if(type === 'isSameDate'){
        this.formRef.current.setFieldsValue({
          date: moment(isSameDate)
        });
      }
      if(type === 'isSameTime'){
        this.formRef.current.setFieldsValue({
          time: timeBefore
        });
      }
      if(type === 'isFree'){
        this.formRef.current.setFieldsValue({
          price: null
        });
      }
    }else{
      if(type === 'isSameDate'){
        this.formRef.current.setFieldsValue({
          date: null
        });
      }
      if(type === 'isSameTime'){
        this.formRef.current.setFieldsValue({
          time: null
        });
      }
    }
  }

  handleLimit = (e) => {
    this.setState({
      isLimit: e.target.value
    })
  }

  onChangeDate = (date, dateString) => {
    const { expDate } = this.state
    this.setState({ issueDate: dateString })
    if(expDate <= dateString){
      this.setState({ isDate: true })
    }else{
      this.setState({ isDate: false })
    }
  }

  handleCheckedDiscount = (e) => {
    this.setState({ isDiscount: e.target.checked })
    this.formRef.current.setFieldsValue({
      discountedPrice: null
    })
  }

  render() {
    const { getData: { data, loading }, getDataTicket } = this.props;
    const { visibleAdd, submitLoading, visibleEdit, dataForm } = this.state;

    if(loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><ReadOutlined /> Course</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/course">Courses List</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{data.xpertNumber ? data.xpertNumber : '-' }</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Course Details' />
          </Col>
          {/* Descriptions */}
          <Col span={24}>
            <DetailHeader 
              data={data}
              handleEnable={this.handleEnable}
              handleDisable={this.handleDisable}
              onClickEdit={this.onClickEdit}
              submitLoading={submitLoading}
            />
          </Col>
          <Col span={12}>
            <DetailTicket 
              dataTicket={getDataTicket}
              showModal={this.showModal}
              handleSwitch={this.handleSwitch}
            />
          </Col>
          <Col span={12}>
            <LastTransaction 
              data={data}
            />
          </Col>
        </Row>
        <Modal
          key="1"
          centered
          width={1000}
          destroyOnClose
          visible={visibleAdd}
          onCancel={() => this.onCancel('add')}
          title={<Text strong>Add Ticket</Text>}
          footer={[
            <React.Fragment key="1">
              <Button key="back" className="btn-save-primary" onClick={() => this.onCancel('add')}>Cancel</Button>
              <Button key="submit" className="btn-save-primary" type="primary" onClick={this.onSubmit} loading={submitLoading}>Submit</Button>
            </React.Fragment>
          ]}
        >
          <FormTicket 
            {...this.props} 
            {...this.state}
            formRef={this.formRef} 
            onSubmit={this.onSubmit}
            data={dataForm} 
            handleChecked={this.handleChecked}
            onChangeDate={this.onChangeDate}
            handleLimit={this.handleLimit}
            handleCheckedDiscount={this.handleCheckedDiscount}
            disabledDate={this.disabledDate}
          />
        </Modal>
        <Modal
          key="2"
          centered
          width={1000}
          destroyOnClose
          visible={visibleEdit}
          onCancel={() => this.onCancel('edit')}
          title={<Text strong>Edit Ticket</Text>}
          footer={[
            <Button key="submit" className="btn-save-primary" type="primary" onClick={this.onSubmitEdit} loading={submitLoading}>Update</Button>
          ]}
        >
          <FormTicket 
            {...this.props} 
            {...this.state}
            formRef={this.formRef} 
            onSubmit={this.onSubmit}
            data={dataForm} 
            handleChecked={this.handleChecked}
            onChangeDate={this.onChangeDate}
            handleLimit={this.handleLimit}
            handleCheckedDiscount={this.handleCheckedDiscount}
            disabledDate={this.disabledDate}
          />
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail, unmountGetListTicket, unmountGetDetailTicket } = this.props;
    unmountGetDetail()
    unmountGetListTicket()
    unmountGetDetailTicket()
  }
}

const mapStateToProps = (state) => ({
  getData: state.course.detail,
  getDataTicket: state.ticket.listByXpert,
  getDetailTicket: state.ticket.detail
})

const mapDispatchToProps = {
  actionGetDetail         : detailCourse,
  actionGetDetailTicket   : detailTicket,
  actionGetListTicket     : listTicketByXpert,
  actionAddTicket         : createTicket,
  actionUpdateTicket      : editTicket,
  actionEnable            : enableCourse,
  actionDisable           : disableCourse,
  actionEnableTicket      : enableTicket,
  actionDisableTicket     : disableTicket,
  unmountGetDetail        : unmountDetailCourse,
  unmountGetDetailTicket  : unmountDetailTicket,
  unmountGetListTicket    : unmountListTicketByXpert
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailCourse)