import React from 'react'
import { Row, Col, Descriptions, Divider } from 'antd';

export default function Uniform(props) {
  const { data } = props
  return (
    data.email.split("@")[1] === 'student.binawan.ac.id' ?
      <Row gutter={16}>
        <Col span={24}>
          <Descriptions title="Uniform" />
            <React.Fragment >
              <Descriptions layout="vertical" size="small" column={3}>
                <Descriptions.Item label="Shirt Size">
                  {data.shirt ? data.shirt : 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Pant Size">
                  {data.pants ? data.pants : 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Shoes Size">
                  {data.shoes ? data.shoes : 'N/A'}
                </Descriptions.Item>
              </Descriptions>
              <Divider />
            </React.Fragment>
        </Col>
      </Row>
    : null  
  )
}
