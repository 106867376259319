import React, { Component } from 'react';
import { Row, Col, Descriptions, Skeleton, Image } from 'antd';

export default class DetailSliderPhoto extends Component {
  componentDidMount() {
    const { actionGetAbout } = this.props;
    return actionGetAbout()
  }
  
  render() {
    const { getDetailAbout: { loading, data } } = this.props;
    if(loading){
      return <Skeleton />
    }
    return (
      <React.Fragment>
        <Row gutter={16}>
          <Col span={24}>
            <Descriptions layout="vertical" column={2}>
              <Descriptions.Item label="Industry">
                {data.jobIndustryTitle ? data.jobIndustryTitle : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Specialties (niche or specific industry of the employer)">
                {data.specialties ? data.specialties : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Company Size">
                {data.totalEmployee ? data.totalEmployee : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Website">
                {data.website ? data.website : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Overview">
                {data.about ? data.about : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Logo">
                {data.logoUrl ? <Image width={100} src={data.logoUrl} /> : 'N/A'}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}
