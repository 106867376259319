import React, { Component } from 'react';
import moment from 'moment';
import { Skeleton, Form, Row, Col, Input, Select, Radio, DatePicker } from 'antd';

export default class EditBiodata extends Component {
  render() {
    const { 
      getDetailCandidate: { data }, 
      getCountry,
      getCity,
      handleCity,
      onFinish, 
      formRef 
    } = this.props;
    if(getCountry.loading || getCity.loading){
      return <Skeleton />
    }
    return (
      <React.Fragment>
        <Form 
          onFinish={onFinish} 
          layout="vertical" 
          ref={formRef}
          initialValues={{
            city: data.currentCityName ? `${data.currentCityName}, ${data.currentCountryName}` : null
          }}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item 
                label="About"
                name="about"
                initialValue={data.about ? data.about : null}
                rules={[
                  { max: 1000, message: '1000 characters only' }
                ]}
              >
                <Input.TextArea rows={3} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item 
                label="First Name"
                name="firstName"
                validateFirst
                initialValue={data.firstName ? data.firstName : null}
                rules={[
                  { pattern: /^[a-zA-Z ]*$/, message: 'Special characters and numbers are not allowed' },
                  { min: 3, message: 'First Name must be at least 3 characters long' },
                  { max: 25, message: 'First Name must not be more than 25 characters long' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item 
                label="Last Name"
                name="lastName"
                validateFirst
                initialValue={data.lastName ? data.lastName : null}
                rules={[
                  { pattern: /^[a-zA-Z ]*$/, message: 'Special characters and numbers are not allowed' },
                  { min: 3, message: 'Last Name must be at least 3 characters long' },
                  { max: 25, message: 'Last Name must not be more than 25 characters long' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="Nationality" 
                name="nationalityId" 
                initialValue={data.nationalityId ? data.nationalityId : null}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="Select a nationality"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    getCountry?.data.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="Gender" 
                name="gender" 
                initialValue={data.gender ? data.gender : "MALE"}
              >
                <Radio.Group>
                  <Radio value="MALE">Male</Radio>
                  <Radio value="FEMALE">Female</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="Marital Status" 
                name="marital" 
                initialValue={data.marital ? data.marital : null}
              >
                <Select>
                  <Select.Option key="1" value="SINGLE">Single</Select.Option>
                  <Select.Option key="2" value="MARRIED">Married</Select.Option>
                  <Select.Option key="3" value="DIVORCE">Divorce</Select.Option>
                  <Select.Option key="4" value="WIDOWED">Widowed</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item 
                label="Place of Birth"
                name="placeOfBirth"
                validateFirst
                initialValue={data.placeOfBirth ? data.placeOfBirth : null}
                rules={[
                  { pattern: /^[a-zA-Z ]*$/, message: 'Alphabetical only' },
                  { max: 50, message: '50 characters only' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item 
                label="Date of Birth"
                name="birthdate"
                initialValue={data.birthdate ? moment(data.birthdate, 'DD/MM/YYYY') : null }
              >
                <DatePicker 
                  style={{ width: '100%' }} 
                  format="DD/MM/YYYY" 
                  defaultPickerValue={data.birthdate === null ? moment('2000-01-01') : null} 
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="Religion"
                name="religion"
                initialValue={data.religion ? data.religion : null}
              >
                <Select>
                  <Select.Option key="1" value="ISLAM">Islam</Select.Option>
                  <Select.Option key="2" value="CATHOLIC">Catholic</Select.Option>
                  <Select.Option key="3" value="PROTESTAN">Protestan</Select.Option>
                  <Select.Option key="4" value="BUDDHA">Buddha</Select.Option>
                  <Select.Option key="5" value="HINDU">Hindu</Select.Option>
                  <Select.Option key="6" value="OTHERS">Others</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="Current Location" 
                name="city"
              >
                <Select 
                  showSearch
                  onChange={handleCity}
                  placeholder="type a city (e.g.: Jakarta Selatan)"
                >
                  {
                    getCity?.data?.map(item => 
                      <Select.Option key={item.id} value={`${item.name}, ${item.countryName}`}>{`${item.name}, ${item.countryName}`}</Select.Option>
                    )
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span ={12}>
              <Form.Item 
                label="Blood Type"
                name="bloodType"
                initialValue={data.bloodType ? data.bloodType : null}
              >
                <Select>
                  <Select.Option key="1" value="A">A</Select.Option>
                  <Select.Option key="2" value="AB">AB</Select.Option>
                  <Select.Option key="3" value="B">B</Select.Option>
                  <Select.Option key="4" value="O">O</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="Height"
                name="height"
                validateFirst
                initialValue={data.height ? data.height.toString() : null}
                rules={[
                  { pattern: /^[0-9]*$/, message: 'Numeric only' },
                  { min: 3, message: '3 digits only' },
                  { max: 3, message: '3 digits only' }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="ID Card Number (KTP)"
                name="identificationId"
                validateFirst
                initialValue={data.identificationId ? data.identificationId : null}
                rules={[
                  { pattern: /^[0-9]*$/, message: 'Numeric only' },
                  { len: 16, message: 'KTP number must be 16 digits' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="Weight"
                name="weight"
                validateFirst
                initialValue={data.weight ? data.weight.toString() : null}
                rules={[
                  { pattern: /^[0-9]*$/, message: 'Numeric only' },
                  { min: 2, message: '2 digits only' },
                  { max: 2, message: '2 digits only' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}
