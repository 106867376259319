import { combineReducers } from 'redux';
import authReducer from './auth/authReducer';
import notificationReducer from './notification/notificationReducer';
import CountNotificationReducer from './notification/countNotificationReducer';
import applicantSummaryReducer from './dashboard/applicantSummaryReducer';
import pipelineHiringReducer from './dashboard/pipelineHiringReducer';
import jobOverviewReducer from './dashboard/jobOverviewReducer';
import jobOpeningStatusReducer from './dashboard/jobOpeningStatusReducer';
import candidateReducer from './candidate/candidateReducer';
import candidateDetailReducer from './candidate/candidateDetailReducer';
import candidateTracerStudyReducer from './candidate/candidateTracerStudyReducer';
import candidateTracerStudyDetailReducer from './candidate/candidateDetailTracerStudyReducer';
import jobsReducer from './jobs/jobsReducer';
import jobsDetailReducer from './jobs/jobsDetailReducer';
import jobsDetailActiveApplicantReducer from './jobs/jobsDetailActiveApplicant';
import jobsListApplicantReducer from './jobs/jobsListApplicantReducer';
import jobsListRejectedReducer from './jobs/jobsListRejectedReducer';
import jobsInjectCandidateReducer from './jobs/jobsInjectCandidateReducer';
import jobsCandidateByEmailReducer from './jobs/jobsCandidateByEmailReducer';
import jobsCandidateByPhoneReducer from './jobs/jobsCandidateByPhoneReducer';
import jobsAppliedReducer from './jobs/jobsAppliedReducer';
import jobsDetailInterviewReducer from './jobs/jobsDetailInterview';
import jobsDetailDepartureReducer from './jobs/jobsDetailDeparture';
import jobsDetailDevelopmentReducer from './jobs/jobDetailDevelopment';
import jobsDetailAssessmentReducer from './jobs/jobsDetailAssessment';
import agenciesReducer from './agencies/agenciesReducer';
import agenciesDetailReducer from './agencies/agenciesDetailReducer';
import masterAgenciesReducer from './agencies/masterAgeciesReducer';
import employersReducer from './employers/employersReducer';
import employersDetailReducer from './employers/employersDetailReducer';
import masterEmployersReducer from './employers/masterEmployersReducer';
import eventReducer from './event/eventReducer';
import eventDetailReducer from './event/eventDetailReducer';
import eventEditReducer from './event/eventEditReducer';
import masterEventReducer from './event/masterEventReducer';
import serviceReducer from './service/serviceReducer';
import serviceDetailReducer from './service/serviceDetailReducer';
import serviceEditReducer from './service/serviceEditReducer';
import masterServiceReducer from './service/masterServiceReducer'
import instructorReducer from './instructor/instructorReducer';
import instructorDetailReducer from './instructor/instructorDetailReducer';
import masterInstructorReducer from './instructor/masterInstructorReducer';
import courseReducer from './course/courseReducer';
import courseDetailReducer from './course/courseDetailReducer';
import courseEditReducer from './course/courseEditReducer';
// import masterCourseReducer from './course/masterCourseReducer';
import trainingReducer from './training/trainingReducer';
import trainingDetailReducer from './training/trainingDetailReducer';
import trainingEditReducer from './training/trainingEditReducer';
// import masterTrainingReducer from './training/masterTrainingReducer';
import transactionReducer from './transaction/transactionReducer';
import transactionDetailReducer from './transaction/transactionDetailReducer';
import masterTransactionReducer from './transaction/masterTransactionReducer';
import ticketReducer from './ticket/ticketReducer';
import ticketDetailReducer from './ticket/ticketDetailReducer';
import masterTicketReducer from './ticket/masterTicketReducer';
import ticketXpertReducer from './ticket/ticketXpertReducer';
import categoryReducer from './category/categoryReducer';
import categoryDetailReducer from './category/categoryDetailReducer';
import masterCategoryReducer from './category/masterCategoryReducer';
import organizerReducer from './organizer/organizerReducer';
import organizerDetailReducer from './organizer/organizerDetailReducer';
import masterOrganizerReducer from './organizer/masterOrganizerReducer';
import partnersReducer from './partners/partnersReducer';
import partnersDetailReducer from './partners/partnersDetailReducer';
import masterPartnersReducer from './partners/masterPartnersReducer';
import candidateRequestReducer from './candidateRequest/candidateRequestReducer';
import candidateRequestDetailReducer from './candidateRequest/candidateRequestDetailReducer';
import masterCandidateRequestReducer from './candidateRequest/masterCandidateRequestReducer';
import candidatePathwayReducer from './candidatePathway/candidatePathwayReducer';
import candidatePathwayDetailReducer from './candidatePathway/candidatePathwayDetailReducer';
import masterCandidatePathwayReducer from './candidatePathway/masterCandidatePathwayReducer';
import basicEnglishReducer from './basicEnglish/basicenglishReducer';
import basicEnglishDetailReducer from './basicEnglish/basicEnglishDetailReducer';
import miniEnglishReducer from './miniEnglish copy/miniEnglishReducer';
import detailMiniEnglishReducer from './miniEnglish copy/miniEnglishDetailReducer';
import countriesReducer from './master/countries/countriesReducer';
import countriesDetailReducer from './master/countries/countriesDetailReducer';
import masterCountriesReducer from './master/countries/masterCountriesReducer';
import bankAccountReducer from './bankAccount/bankAccountReducer';
import bankAccountDetailReducer from './bankAccount/bankAccountDetailReducer';
import masterBankAccountReducer from './bankAccount/masterBankAccountReducer';

import aboutCountriesReducer from './master/aboutCountries/aboutCountriesReducer';
import aboutCountriesDetailReducer from './master/aboutCountries/aboutCountriesDetailReducer';
import masterAboutCountriesReducer from './master/aboutCountries/masterAboutCountriesReducer';

import provincesReducer from './master/provinces/provincesReducer';
import provincesDetailReducer from './master/provinces/provincesDetailReducer';
import masterProvincesReducer from './master/provinces/masterProvincesReducer';
import citiesReducer from './master/cities/citiesReducer';
import citiesDetailReducer from './master/cities/citiesDetailReducer';
import masterCitiesReducer from './master/cities/masterCitiesReducer';
import subDistrictReducer from './master/subDistrict/subDistrictReducer';
import subDistrictDetailReducer from './master/subDistrict/subDistrictDetailReducer';
import masterSubDistrictReducer from './master/subDistrict/masterSubDistrictReducer';
import villageReducer from './master/villages/villageReducer';
import villageDetailReducer from './master/villages/villageDetailReducer';
import masterVillageReducer from './master/villages/masterVillageReducer';
import jobIndustriesReducer from './master/jobIndustries/jobIndustriesReducer';
import jobIndustriesDetailReducer from './master/jobIndustries/jobIndustriesDetailReducer';
import masterJobIndustriesReducer from './master/jobIndustries/masterJobIndustriesReducer';
import jobPositionsReducer from './master/jobPositions/jobPositionsReducer';
import jobPositionsDetailReducer from './master/jobPositions/jobPositionsDetailReducer';
import masterJobPositionsReducer from './master/jobPositions/masterJobPositionsReducer';
import jobPositionByIndustryReducer from './master/jobPositions/jobPositionByIndustryReducer';
import jobDestinationsReducer from './master/jobDestinations/jobDestinationsReducer';
import jobDestinationsDetailReducer from './master/jobDestinations/jobDestinationsDetailReducer';
import jobDestinationGroupReducer from './master/jobDestinations/jobDestinationGroupReducer';
import masterJobDestinationsReducer from './master/jobDestinations/masterJobDestinationsReducer';
import educationLevelReducer from './master/educationLevel/educationLevelReducer';
import educationLevelDetailReducer from './master/educationLevel/educationLevelDetailReducer';
import masterEducationLevelReducer from './master/educationLevel/masterEducationLevelReducer';
import institutionsReducer from './master/institutions/institutionsReducer';
import institutionsDetailReducer from './master/institutions/institutionsDetailReducer';
import masterInstitutionsReducer from './master/institutions/masterInstitutionsReducer';
import fieldOfStudyReducer from './master/fieldOfStudy/fieldOfStudyReducer';
import fieldOfStudyDetailReducer from './master/fieldOfStudy/fieldOfStudyDetailReducer';
import masterFieldOfStudyReducer from './master/fieldOfStudy/masterFieldOfStudyReducer';
import skillReducer from './master/skills/skillReducer';
import skillDetailReducer from './master/skills/skillDetailReducer';
import masterSkillReducer from './master/skills/masterSkillReducer';
import documentTypeReducer from './master/documentTypes/documentTypeReducer';
import documentTypeDetailReducer from './master/documentTypes/documentTypeDetailReducer';
import masterDocumentTypeReducer from './master/documentTypes/masterDocumentTypeReducer';
import languageSkillsReducer from './master/languageSkills/languageSkillsReducer';
import languageSkillsDetailReducer from './master/languageSkills/languageSkillsDetailReducer';
import masterLanguageSkillsReducer from './master/languageSkills/masterLanguageSkillsReducer';
import jobPostingTypeReducer from './master/jobPostingType/jobPostingTypeReducer';
import jobPostingTypeDetailReducer from './master/jobPostingType/jobPostingTypeDetailReducer';
import masterJobPostingTypeReducer from './master/jobPostingType/masterJobPostingTypeReducer';
import bankReducer from './master/bank/bankReducer';
import bankDetailReducer from './master/bank/bankDetailReducer';
import masterBankReducer from './master/bank/masterBankReducer';
import masterBusinessSectorReducer from './master/businessSector/masterBusinessSectorReducer';
import masterBenefitReducer from './master/benefits/masterBenefitReducer';
import clientReducer from './client/clientReducer';
import clientDetailReducer from './client/clientDetailReducer';
import masterClientReducer from './client/masterClientReducer';
import sliderPhotoReducer from './companyProfile/sliderPhotoReducer';
import aboutReducer from './companyProfile/aboutReducer';
import locationReducer from './companyProfile/locationReducer';
import galleryReducer from './companyProfile/galleryReducer';
import reportApplicantSummary from './report/reportApplicantSummaryReducer';
import reportHiringPipeline from './report/reportHiringPipelineReducer';
import reportJobOverview from './report/reportJobOverviewReducer';
import emailReducer from './settings/emailReducer';
import contactReducer from './settings/contactReducer';
import employersInquiriesReducer from './employersInquiries/employersInquiriesReducer';
import employersInquiriesDetailReducer from './employersInquiries/employersInquiriesDetailReducer';
import masterEmployersInquiriesReducer from './employersInquiries/masterEmployersInquiriesReducer';
import jobsApplicantNotesReducer from './jobs/jobsApplicantNotesReducer';
import newCandidateReducer from './newCandidate/newCandidateReducer';
import candidateResultTestReducer from './candidate/candidateResultTestReducer';
import candidateLanguageSkillsReducer from './candidate/candidateLanguageSkillsReducer';
import counselingProcessReducer from './counselingProcess/counselingProcessReducer';
import assignedCandidateReducer from './assignedCandidate/assignedCandidateReducer';

import destinationCountryReducer from './master/destinationCountry/destinationCountryReducer';
import destinationCountryDetailReducer from './master/destinationCountry/destinationCountryDetailReducer';
import masterDestinationCountryReducer from './master/destinationCountry/masterDestinationCountryReducer';
import faqReducer from './faq/faqReducer';
import masterLanguageReducer from './master/languageSkills/languageSkillsMasterReducer';
import jobsListDevelopmentApplicantReducer from './jobs/jobsListDevelopmentApplicantReducer';

export default combineReducers({
  authReducer,
  notification: combineReducers({
    list  : notificationReducer,
    count : CountNotificationReducer
  }),
  dashboard: combineReducers({
    applicantSummary  : applicantSummaryReducer,
    pipelineHiring    : pipelineHiringReducer,
    jobOverview       : jobOverviewReducer,
    jobOpeningStatus  : jobOpeningStatusReducer
  }),
  candidate: combineReducers({
    list    : candidateReducer,
    detail  : candidateDetailReducer
  }),
  candidateTracerStudy: combineReducers({
    list    : candidateTracerStudyReducer,
    detail  : candidateTracerStudyDetailReducer
  }),
  jobs: combineReducers({
    list                : jobsReducer,
    detail              : jobsDetailReducer,
    activeApplicant     : jobsDetailActiveApplicantReducer,
    applied             : jobsAppliedReducer,
    detailInterview     : jobsDetailInterviewReducer,
    detailDeparture     : jobsDetailDepartureReducer,
    detailAssessment    : jobsDetailAssessmentReducer,
    listApplicant       : jobsListApplicantReducer,
    listRejected        : jobsListRejectedReducer  ,
    listInjectCandidate : jobsInjectCandidateReducer,
    candidateByEmail    : jobsCandidateByEmailReducer,
    candidateByPhone    : jobsCandidateByPhoneReducer,
    listApplicantNotes  : jobsApplicantNotesReducer,
    listDevelopmentApplicant : jobsListDevelopmentApplicantReducer,
    detailDevelopment   : jobsDetailDevelopmentReducer
  }),
  agencies: combineReducers({
    list    : agenciesReducer,
    detail  : agenciesDetailReducer,
    master  : masterAgenciesReducer
  }),
  employers: combineReducers({
    list    : employersReducer,
    detail  : employersDetailReducer,
    master  : masterEmployersReducer
  }),
  event: combineReducers({
    list    : eventReducer,
    detail  : eventDetailReducer,
    edit    : eventEditReducer,
    master  : masterEventReducer,
  }),
  service: combineReducers({
    list    : serviceReducer,
    detail  : serviceDetailReducer,
    edit    : serviceEditReducer,
    master  : masterServiceReducer,
  }),
  instructor: combineReducers({
    list    : instructorReducer,
    detail  : instructorDetailReducer,
    master  : masterInstructorReducer
  }),
  course: combineReducers({
    list    : courseReducer,
    detail  : courseDetailReducer,
    edit    : courseEditReducer,
    // master  : masterCourseReducer
  }),
  training: combineReducers({
    list    : trainingReducer,
    detail  : trainingDetailReducer,
    edit    : trainingEditReducer,
    // master  : masterTrainingReducer
  }),
  transaction: combineReducers({
    list    : transactionReducer,
    detail  : transactionDetailReducer,
    master  : masterTransactionReducer
  }),
  bankAccount: combineReducers({
    list    : bankAccountReducer,
    detail  : bankAccountDetailReducer,
    master  : masterBankAccountReducer
  }),
  ticket: combineReducers({
    list    : ticketReducer,
    detail  : ticketDetailReducer,
    master  : masterTicketReducer,
    listByXpert : ticketXpertReducer
  }),
  category: combineReducers({
    list    : categoryReducer,
    detail  : categoryDetailReducer,
    master  : masterCategoryReducer
  }),
  organizer: combineReducers({
    list    : organizerReducer,
    detail  : organizerDetailReducer,
    master  : masterOrganizerReducer
  }),
  employersInquiries: combineReducers({
    list    : employersInquiriesReducer,
    detail  : employersInquiriesDetailReducer,
    master  : masterEmployersInquiriesReducer
  }),
  partners: combineReducers({
    list    : partnersReducer,
    detail  : partnersDetailReducer,
    master  : masterPartnersReducer
  }),
  candidateRequest: combineReducers({
    list    : candidateRequestReducer,
    detail  : candidateRequestDetailReducer,
    master  : masterCandidateRequestReducer
  }),
  candidatePathway: combineReducers({
    list    : candidatePathwayReducer,
    detail  : candidatePathwayDetailReducer,
    master  : masterCandidatePathwayReducer
  }),
  basicEnglish: combineReducers({
    list    : basicEnglishReducer,
    detail  : basicEnglishDetailReducer
  }),
  miniEnglish: combineReducers({
    list    : miniEnglishReducer,
    detail  : detailMiniEnglishReducer
  }),
  countries: combineReducers({
    list    : countriesReducer,
    detail  : countriesDetailReducer,
    master  : masterCountriesReducer
  }),
  aboutCountries: combineReducers({
    list    : aboutCountriesReducer,
    detail  : aboutCountriesDetailReducer,
    master  : masterAboutCountriesReducer
  }),
  provinces: combineReducers({
    list    : provincesReducer,
    detail  : provincesDetailReducer,
    master  : masterProvincesReducer
  }),
  cities: combineReducers({
    list    : citiesReducer,
    detail  : citiesDetailReducer,
    master  : masterCitiesReducer
  }),
  subDistricts: combineReducers({
    list    : subDistrictReducer,
    detail  : subDistrictDetailReducer,
    master  : masterSubDistrictReducer
  }),
  villages: combineReducers({
    list    : villageReducer,
    detail  : villageDetailReducer,
    master  : masterVillageReducer
  }),
  jobIndustries: combineReducers({
    list    : jobIndustriesReducer,
    detail  : jobIndustriesDetailReducer,
    master  : masterJobIndustriesReducer
  }),
  jobPositions: combineReducers({
    list            : jobPositionsReducer,
    detail          : jobPositionsDetailReducer,
    master          : masterJobPositionsReducer,
    listByIndustry  : jobPositionByIndustryReducer,
  }),
  jobDestinations: combineReducers({
    list    : jobDestinationsReducer,
    detail  : jobDestinationsDetailReducer,
    group   : jobDestinationGroupReducer,
    master  : masterJobDestinationsReducer,
  }),
  destinationCountry: combineReducers({
    list    : destinationCountryReducer,
    detail  : destinationCountryDetailReducer,
    master  : masterDestinationCountryReducer,
  }),
  educationLevel: combineReducers({
    list    : educationLevelReducer,
    detail  : educationLevelDetailReducer,
    master  : masterEducationLevelReducer
  }),
  institutions: combineReducers({
    list    : institutionsReducer,
    detail  : institutionsDetailReducer,
    master  : masterInstitutionsReducer
  }),
  fieldOfStudy: combineReducers({
    list    : fieldOfStudyReducer,
    detail  : fieldOfStudyDetailReducer,
    master  : masterFieldOfStudyReducer
  }),
  skills: combineReducers({
    list    : skillReducer,
    detail  : skillDetailReducer,
    master  : masterSkillReducer
  }),
  documentTypes: combineReducers({
    list    : documentTypeReducer,
    detail  : documentTypeDetailReducer,
    master  : masterDocumentTypeReducer
  }),
  languageSkills: combineReducers({
    list    : languageSkillsReducer,
    detail  : languageSkillsDetailReducer,
    master  : masterLanguageSkillsReducer
  }),
  jobPostingType: combineReducers({
    list    : jobPostingTypeReducer,
    detail  : jobPostingTypeDetailReducer,
    master  : masterJobPostingTypeReducer
  }),
  bank: combineReducers({
    list    : bankReducer,
    detail  : bankDetailReducer,
    master  : masterBankReducer
  }),
  businessSector: combineReducers({
    master  : masterBusinessSectorReducer
  }),
  benefits: combineReducers({
    master  : masterBenefitReducer
  }),
  clients: combineReducers({
    list    : clientReducer,
    detail  : clientDetailReducer,
    master  : masterClientReducer
  }),
  companyProfile: combineReducers({
    sliderPhoto : sliderPhotoReducer,
    about       : aboutReducer,
    location    : locationReducer,
    gallery     : galleryReducer
  }),
  report: combineReducers({
    applicantSummary  : reportApplicantSummary,
    hiringPipeline    : reportHiringPipeline,
    jobOverview       : reportJobOverview
  }),
  settings: combineReducers({
    email       : emailReducer,
    contact     : contactReducer,
  }),
  faq: combineReducers({
    list : faqReducer,
  }),
  newCandidate: combineReducers({
    list    : newCandidateReducer,
  }),
  candidateResultTest: combineReducers({
    list    : candidateResultTestReducer,
  }),
  candidateLanguageSkills: combineReducers({
    list    : candidateLanguageSkillsReducer,
  }),
  counselingProcess: combineReducers({
    list    : counselingProcessReducer,
  }),
  assignedCandidate: combineReducers({
    list    : assignedCandidateReducer,
  }),
  masterLanguageSkills: combineReducers({
    list    : masterLanguageReducer,
  })
})