import React from 'react'
import { Row, Col, Typography, Modal, Image } from 'antd'

const { Text } = Typography;

export default function ModalAboutCountry(props) {
  const { visibleCountry, handleCancel, data } = props
  return (
    <Modal
      centered
      title="About Country"
      width={800}
      visible={visibleCountry}
      footer={false}
      onCancel={handleCancel}
    >
      <Row>
        <Col span={24}>
          <Image
            preview={false}
            style={{ marginLeft: '100%' }}
            width={250}
            src={data.jobCountryImageUrl}
          />
        </Col>
        <Col span={24} style={{ fontSize: 17 }}>
          <Text strong>{data.jobCountryName}</Text>
        </Col>
        <Col span={24} style={{ marginTop: 20, fontSize: 15 }} >
          <Text>{data.jobCountryInformation}</Text>
        </Col>
      </Row>
    </Modal>
  )
}
