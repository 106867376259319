const LOAD_DATA_ABOUT           = 'LOAD_DATA_ABOUT'
const LOAD_DATA_ABOUT_SUCCESS   = 'LOAD_DATA_ABOUT_SUCCESS'
const LOAD_DATA_ABOUT_FAILED    = 'LOAD_DATA_ABOUT_FAILED'
const UNMOUNT_DATA_ABOUT        = 'UNMOUNT_DATA_ABOUT'

const initialState = {
  loading: true,
  data: null,
  message: null,
}

const aboutReducer = (state = initialState, action) => {
  switch(action.type){
    case LOAD_DATA_ABOUT:
      return {
        ...state,
        loading: true,
        data: null,
      }
    case LOAD_DATA_ABOUT_SUCCESS:
      return { 
        ...state, 
        loading: false,
        data: action.payload.data,
        message: 'SUCCESS',
      }
    case LOAD_DATA_ABOUT_FAILED:
      return { 
        ...state, 
        loading: true,
        data: null,
        message: 'FAILED',
      }
    case UNMOUNT_DATA_ABOUT:
      return { 
        ...state, 
        loading: true,
        data: null,
      }
    default:
      return state
  }
}

export default aboutReducer;