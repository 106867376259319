import React from 'react';
import { Typography, Tag } from 'antd';
const { Text } = Typography;

export const columns = () => {
  return [
    {
      title: 'Posting Title',
      key: 'postingTitle',
      width: 150,
      render: (record) => (
        <Text>
          {record.postingTitle ? record.postingTitle : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Status',
      key: 'status',
      width: 100,
      render: (record) => (
        <React.Fragment>
          {record.status === 'REQUEST' ?  <Tag color="blue">Waiting for approval</Tag> : null}
          {record.status === 'DRAFT' ? <Tag> Draft</Tag> : null}
          {record.status === 'ACTIVE' ? <Tag color="green"> Active</Tag> : null}
          {record.status === 'INACTIVE' ? <Tag color="orange"> Inactive</Tag> : null}
          {record.status === 'REJECT' ? <Tag color="danger"> Rejected</Tag> : null}
        </React.Fragment>
      )
    },
    {
      title: 'Posted Date',
      key: 'postedDate',
      width: 150,
      render: (record) => (
        <Text>
          {record.postedDate ? record.postedDate : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Needs',
      key: 'needs',
      align: 'center',
      width: 150,
      render: (record) => (
        <Text>
          {record.needs ? record.needs : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Hired',
      key: 'hired',
      align: 'center',
      width: 150,
      render: (record) => (
        <Text>
          {record.hired ? record.hired : 'N/A'}
        </Text>
      )
    },
    {
      title: 'In Progress',
      key: 'inProgress',
      align: 'center',
      width: 150,
      render: (record) => (
        <Text>
          {record.inProgress ? record.inProgress : 'N/A'}
        </Text>
      )
    },
  ]
}