import React, { Component } from 'react';
import moment from 'moment';
import AddEducation from './Add';
import EditEducation from './Edit';
import debounce from 'lodash/debounce';
import svg from '../../../../../assets/img/profile.svg';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { listInstitutions, unmountListInstitutions } from '../../../../../redux/actions/master/institutions/institutionsAction';
import { listFieldOfStudy, unmountListFieldOfStudy } from '../../../../../redux/actions/master/fieldOfStudy/fieldOfStudyAction';
import { masterEducationLevel, unmountMasterEducationLevel } from '../../../../../redux/actions/master/educationLevel/educationLevelAction';
import { addEducation, deleteEducation, detailCandidate, unmountDetailCandidate, updateEducation } from '../../../../../redux/actions/candidate/candidateAction';
import { Skeleton, Row, Col, Breadcrumb, PageHeader, Card, Button, Menu, Space, Avatar, Typography, Descriptions, Divider, Tooltip, Empty, Modal, message } from 'antd';
import { UserOutlined, PhoneOutlined, LikeOutlined, ProjectOutlined, BulbOutlined, IdcardOutlined, FormatPainterOutlined, FolderOutlined, TrophyOutlined, LockOutlined, PlusOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
const { Text } = Typography;
const { confirm } = Modal;

class CandidateBinawanDetailEducation extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      dataEducation: null,
      submitLoading: false,
      visibleAdd: false,
      visibleEdit: false,
      startYear: null,
      endYear: null,
      isYear: false,
      metaInstitution: {
        page: 1,
        perpage: 10,
        search: ''
      },
      metaFieldOfStudy: {
        page: 1,
        perpage: 10,
        search: ''
      }
    }
    this.onSearchInstitution = debounce(this.onSearchInstitution.bind(this), 800)
    this.onSearchFieldOfStudy = debounce(this.onSearchFieldOfStudy.bind(this), 800)
  }
  

  componentDidMount() {
    const { actionGetDetail, actionGetEducation, match: { params } } = this.props;
    actionGetDetail(params.id)
    actionGetEducation()
  }

  showModal = (type, item) => {
    if(type === 'add'){
      return this.setState({ visibleAdd: true })
    }
    if(type === 'edit'){
      const { metaInstitution, metaFieldOfStudy } = this.state;
      const { actionGetInstitution, actionGetFieldOfStudy } = this.props;
      metaInstitution.search = item.name
      metaFieldOfStudy.search = item.fieldOfStudy
      return this.setState({ 
        visibleEdit: true, 
        dataEducation: item,
      }, () => {
        actionGetInstitution(metaInstitution)
        actionGetFieldOfStudy(metaFieldOfStudy)
      })
    }
  }

  onCancel = (type) => {
    const { metaInstitution, metaFieldOfStudy } = this.state;
    if(type === 'add'){
      metaInstitution.page = 1
      metaInstitution.perpage = 10
      metaInstitution.search = ''
      metaFieldOfStudy.page = 1
      metaFieldOfStudy.perpage = 10
      metaFieldOfStudy.search = ''
      return this.setState({ 
        visibleAdd: false,
        startYear: null,
        endYear: null,
        isYear: false,
      })
    }
    if(type === 'edit'){
      metaInstitution.page = 1
      metaInstitution.perpage = 10
      metaInstitution.search = ''
      metaFieldOfStudy.page = 1
      metaFieldOfStudy.perpage = 10
      metaFieldOfStudy.search = ''
      return this.setState({ 
        dataEducation: null,
        visibleEdit: false,
        startYear: null,
        endYear: null,
        isYear: false,
      })
    }
  }

  onSearchInstitution = (value) => {
    const { metaInstitution } = this.state;
    const { actionGetInstitution } = this.props;
    metaInstitution.page = 1
    metaInstitution.perpage = 10
    metaInstitution.search = value
    return actionGetInstitution(metaInstitution)
  }

  onFocusInstitution = () => {
    const { metaInstitution } = this.state;
    const { actionGetInstitution } = this.props;
    return actionGetInstitution(metaInstitution)
  }

  onSearchFieldOfStudy = (value) => {
    const { metaFieldOfStudy } = this.state;
    const { actionGetFieldOfStudy } = this.props;
    metaFieldOfStudy.page = 1
    metaFieldOfStudy.perpage = 10
    metaFieldOfStudy.search = value
    return actionGetFieldOfStudy(metaFieldOfStudy)
  }

  onFocusFieldOfStudy = () => {
    const { metaFieldOfStudy } = this.state;
    const { actionGetFieldOfStudy } = this.props;
    return actionGetFieldOfStudy(metaFieldOfStudy)
  }

  onChangeStartYear = (date, dateString) => {
    const { endYear } = this.state
    this.setState({ startYear: dateString })
    if(endYear <= dateString){
      this.setState({ isYear: true })
    }else{
      this.setState({ isYear: false })
    }
  }

  onChangeEndYear = (date, dateString) => {
    const { startYear } = this.state
    this.setState({ endYear: dateString })
    if(dateString <= startYear){
      this.setState({ isYear: true })
    }else{
      this.setState({ isYear: false })
    }
  }

  handleDelete = (id) => {
    const { actionDelete, actionGetDetail, match: { params } } = this.props;
    confirm({
      title: 'Remove Data',
      okText: 'Yes',
      cancelText: 'No',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to remove this data?',
      onOk() {
        return actionDelete(id, () => {
          message.success('Data deleted successfully')
          return actionGetDetail(params.id)
        }, (err) => message.error(err))
      },
    });
  }

  onSubmitAdd = () => {
    const { actionAdd, actionGetDetail, match: { params } } = this.props;
    this.setState({ submitLoading: true })
    this.formRef.current.validateFields().then(values => {
      values.candidateId = Number(params.id)
      values.startYear = values.startYear ? moment(values.startYear).format('YYYY') : null
      values.endYear = values.endYear ? moment(values.endYear).format('YYYY') : null
      return actionAdd(values, () => {
        return this.setState({ submitLoading: false }, () => {
          return this.setState({ visibleAdd: false }, () => {
            message.success('Data created successfully')
            actionGetDetail(params.id)
          })
        })
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err))
      })
    })
  }

  onSubmitEdit = () => {
    const { dataEducation } = this.state;
    const { actionUpdate, actionGetDetail, match: { params } } = this.props;
    this.setState({ submitLoading: true })
    this.formRef.current.validateFields().then(values => {
      values.id = dataEducation.id
      values.candidateId = Number(params.id)
      values.startYear = values.startYear ? moment(values.startYear).format('YYYY') : null
      values.endYear = values.endYear ? moment(values.endYear).format('YYYY') : null
      return actionUpdate(values, () => {
        return this.setState({ submitLoading: false }, () => {
          return this.setState({ visibleEdit: false }, () => {
            message.success('Data updated successfully')
            return actionGetDetail(params.id)
          })
        })
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err))
      })
    })
  }
  
  render() {
    const { visibleAdd, visibleEdit } = this.state;
    const { getDetailCandidate: { loading, data } } = this.props;
    if(loading){
      return <Skeleton avatar />
    }
    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><UserOutlined /> Candidates</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/binawan/candidate">
                  Candidates List
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Candidates Detail</Breadcrumb.Item>
              <Breadcrumb.Item>
                {data.fullName}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Candidate Detail' />
          </Col>
          {/* Menu */}
          <Col span={7}>
            <Card style={{ border: 'none' }}>
              <Row gutter={[16, 24]}>
                <Col span={24}>
                  <Space direction="horizontal">
                    <Avatar size={100} src={data.profilePicUrl ? data.profilePicUrl : svg} />
                    <Space direction="vertical" size="small">
                      <Text style={{ color: '#4d4d4d', fontSize: 18 }} strong>{data.fullName}</Text>
                      <Text type="secondary">{data.candidateNumber}</Text>
                    </Space>
                  </Space>
                </Col>
                <Col span={24}>
                  <Menu defaultSelectedKeys={['5']} mode="inline">
                    <Menu.Item key="1" icon={<UserOutlined />}>
                      <Link to={`/binawan/candidate/detail/${data.id}/biodata`}>
                        Biodata
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="2" icon={<PhoneOutlined />}>
                      <Link to={`/binawan/candidate/detail/${data.id}/contact-information`}>
                        Contact Information
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="3" icon={<LikeOutlined />}>
                      <Link to={`/binawan/candidate/detail/${data.id}/job-preferences`}>
                        Job Preferences
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="4" icon={<ProjectOutlined />}>
                      <Link to={`/binawan/candidate/detail/${data.id}/work-experience`}>
                        Work Experience
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="5" icon={<BulbOutlined />}>
                      <Link to={`/binawan/candidate/detail/${data.id}/education`}>
                        Education
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="6" icon={<IdcardOutlined />}>
                      <Link to={`/binawan/candidate/detail/${data.id}/training-and-certification`}>
                        Training and Certification
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="7" icon={<FormatPainterOutlined />}>
                      <Link to={`/binawan/candidate/detail/${data.id}/skills`}>
                        Skills
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="8" icon={<FolderOutlined />}>
                      <Link to={`/binawan/candidate/detail/${data.id}/portfolios`}>
                        Portfolios
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="9" icon={<TrophyOutlined />}>
                      <Link to={`/binawan/candidate/detail/${data.id}/achievements`}>
                        Achievements
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="10" icon={<LockOutlined />}>
                      <Link to={`/binawan/candidate/detail/${data.id}/reset-password`}>
                        Reset Password
                      </Link>
                    </Menu.Item>
                  </Menu>
                </Col>
              </Row>
            </Card>
          </Col>
          {/* Content */}
          <Col span={17}>
            <Card 
              style={{ border: 'none' }}
              title={<Text strong>Education</Text>} 
              extra={<Button key="1" className="btn-save-primary" type="primary" icon={<PlusOutlined />} onClick={() => this.showModal('add')}>Add</Button>} 
            >
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  {
                    data.listEducation.length > 0 ?
                    <React.Fragment>
                      {
                        data.listEducation.map((item, i) => (
                          <React.Fragment key={i}>
                            <div style={{ marginBottom: 11, display: 'flex', justifyContent: 'space-between' }}>
                              <Text style={{ color: '#32ab6d', fontSize: 14 }}>
                                {item.startYear} - {item.endYear ? item.endYear : 'Present'}
                              </Text>
                              <Space> 
                                <Tooltip title="Edit">
                                  <EditOutlined style={{ fontSize: 20, cursor: 'pointer', color: '#32ab6d' }} onClick={() => this.showModal('edit', item)} />
                                </Tooltip>
                                <Tooltip title="Remove">
                                  <DeleteOutlined style={{ fontSize: 20, cursor: 'pointer', color: '#d8675a' }} onClick={() => this.handleDelete(item.id)} />
                                </Tooltip>
                              </Space>
                            </div>
                            <Descriptions layout="vertical" size="small" column={2}>
                              <Descriptions.Item label="School / University">
                                {item.name ? item.name : 'N/A'}
                              </Descriptions.Item>
                              <Descriptions.Item label="Field of Study">
                                {item.fieldOfStudy ? item.fieldOfStudy : 'N/A'}
                              </Descriptions.Item>
                              <Descriptions.Item label="Education Level">
                                {item.educationLevelName ? item.educationLevelName : 'N/A'}
                              </Descriptions.Item>
                              <Descriptions.Item label="Score/Grade/GPA">
                                {item.gpa ? item.gpa : 'N/A'}
                              </Descriptions.Item>
                              <Descriptions.Item label="Activity and Organization Experiences">
                                {item.experience ? item.experience : 'N/A'}
                              </Descriptions.Item>
                            </Descriptions>
                            <Divider />
                          </React.Fragment>
                        ))
                      }
                    </React.Fragment>
                    :
                    <React.Fragment>
                      <Empty description={<Text>No Data Education</Text>} />
                    </React.Fragment>
                  }
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal
          key="1"
          centered
          width={1000}
          destroyOnClose
          visible={visibleAdd}
          onCancel={() => this.onCancel('add')}
          title={<Text strong>Add Education</Text>}
          footer={[
            <React.Fragment key="1">
              <Button key="back" className="btn-save-primary" onClick={() => this.onCancel('add')}>Cancel</Button>
              <Button key="submit" className="btn-save-primary" type="primary" onClick={this.onSubmitAdd}>Save</Button>
            </React.Fragment>
          ]}
        >
          <AddEducation 
            {...this.props} 
            {...this.state}
            formRef={this.formRef} 
            onSubmitAdd={this.onSubmitAdd} 
            onChangeEndYear={this.onChangeEndYear}
            onChangeStartYear={this.onChangeStartYear}
            onFocusInstitution={this.onFocusInstitution}
            onFocusFieldOfStudy={this.onFocusFieldOfStudy}
            onSearchInstitution={this.onSearchInstitution}
            onSearchFieldOfStudy={this.onSearchFieldOfStudy}
          />
        </Modal>
        <Modal
          key="2"
          centered
          width={1000}
          destroyOnClose
          visible={visibleEdit}
          onCancel={() => this.onCancel('edit')}
          title={<Text strong>Edit Work Experience</Text>}
          footer={[
            <React.Fragment key="1">
              <Button key="back" className="btn-save-primary" onClick={() => this.onCancel('edit')}>Cancel</Button>
              <Button key="submit" className="btn-save-primary" type="primary" onClick={this.onSubmitEdit}>Save</Button>
            </React.Fragment>
          ]}
        >
          <EditEducation 
            {...this.props} 
            {...this.state}
            formRef={this.formRef} 
            onSubmitAdd={this.onSubmitAdd} 
            onChangeEndYear={this.onChangeEndYear}
            onChangeStartYear={this.onChangeStartYear}
            onFocusInstitution={this.onFocusInstitution}
            onFocusFieldOfStudy={this.onFocusFieldOfStudy}
            onSearchInstitution={this.onSearchInstitution}
            onSearchFieldOfStudy={this.onSearchFieldOfStudy}
          />
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail, unmountGetInstitution, unmounGetEducation, unmountGetFieldOfStudy } = this.props;
    unmountGetDetail()
    unmountGetInstitution()
    unmounGetEducation()
    unmountGetFieldOfStudy()
  }
}

const mapStateToProps = (state) => ({
  getDetailCandidate  : state.candidate.detail,
  getInstitution      : state.institutions.list,
  getEducation        : state.educationLevel.master,
  getFieldOfStudy     : state.fieldOfStudy.list,
})

const mapDispatchToProps = {
  actionAdd               : addEducation,
  actionUpdate            : updateEducation,
  actionDelete            : deleteEducation,
  actionGetDetail         : detailCandidate,
  actionGetInstitution    : listInstitutions,
  actionGetEducation      : masterEducationLevel,
  actionGetFieldOfStudy   : listFieldOfStudy,
  unmountGetDetail        : unmountDetailCandidate,
  unmountGetInstitution   : unmountListInstitutions,
  unmounGetEducation      : unmountMasterEducationLevel,
  unmountGetFieldOfStudy  : unmountListFieldOfStudy
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateBinawanDetailEducation)
