import React, { Component } from 'react';
import EditSkill from './Edit';
import svg from '../../../../../assets/img/profile.svg';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { addSkill, deleteSkill, detailCandidate, unmountDetailCandidate } from '../../../../../redux/actions/candidate/candidateAction';
import { Skeleton, Row, Col, Breadcrumb, PageHeader, Card, Button, Menu, Space, Avatar, Typography, Descriptions, Empty, Modal, message } from 'antd';
import { UserOutlined, PhoneOutlined, LikeOutlined, ProjectOutlined, BulbOutlined, IdcardOutlined, FormatPainterOutlined, FolderOutlined, TrophyOutlined, LockOutlined, EditOutlined } from '@ant-design/icons';
const { Text } = Typography;

class CandidateDetailSkills extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      visible: false,
      name: null
    }
  }

  componentDidMount() {
    const { actionGetDetail, match: { params } } = this.props;
    return actionGetDetail(params.id)
  }

  showModal = () => {
    return this.setState({ visible: true })
  }

  onCancel = () => {
    const { actionGetDetail, match: { params } } = this.props;
    return this.setState({ visible: false }, () => {
      actionGetDetail(params.id)
    })
  }

  onChangeName = (e) => {
    this.setState({ name: e.target.value })
  }

  handleDelete = (id) => {
    const { actionDelete, actionGetDetail, match: { params } } = this.props;
    return actionDelete(id, () => {
      message.success('Data deleted successfully')
      return actionGetDetail(params.id)
    }, (err) => message.error(err))
  }

  onFinish = () => {
    const { actionAdd, actionGetDetail, match: { params } } = this.props
    this.formRef.current.validateFields().then(values => {
      values.candidateId = Number(params.id)
      return actionAdd(values, () => {
        this.formRef.current.setFieldsValue({
          name: null,
        });
        this.setState({ name: null })
        message.success('Data updated successfully')
        return actionGetDetail(params.id)
      }, (err) => message.error(err))
    })
  }
  
  render() {
    const { visible } = this.state;
    const { getDetailCandidate: { loading, data } } = this.props;
    if(loading){
      return <Skeleton avatar />
    }
    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><UserOutlined /> Candidates</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/binawan/candidate" style={{ color: '#0076de' }}>
                  Candidates List
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Candidates Detail</Breadcrumb.Item>
              <Breadcrumb.Item>
                {data.fullName}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Candidate Detail' />
          </Col>
          {/* Menu */}
          <Col span={7}>
            <Card style={{ border: 'none' }}>
              <Row gutter={[16, 24]}>
                <Col span={24}>
                  <Space direction="horizontal">
                    <Avatar size={100} src={data.profilePicUrl ? data.profilePicUrl : svg} />
                    <Space direction="vertical" size="small">
                      <Text style={{ color: '#4d4d4d', fontSize: 18 }} strong>{data.fullName}</Text>
                      <Text type="secondary">{data.candidateNumber}</Text>
                    </Space>
                  </Space>
                </Col>
                <Col span={24}>
                  <Menu defaultSelectedKeys={['7']} mode="inline">
                    <Menu.Item key="1" icon={<UserOutlined />}>
                      <Link to={`/binawan/candidate/detail/${data.id}/biodata`}>
                        Biodata
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="2" icon={<PhoneOutlined />}>
                      <Link to={`/binawan/candidate/detail/${data.id}/contact-information`}>
                        Contact Information
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="3" icon={<LikeOutlined />}>
                      <Link to={`/binawan/candidate/detail/${data.id}/job-preferences`}>
                        Job Preferences
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="4" icon={<ProjectOutlined />}>
                      <Link to={`/binawan/candidate/detail/${data.id}/work-experience`}>
                        Work Experience
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="5" icon={<BulbOutlined />}>
                      <Link to={`/binawan/candidate/detail/${data.id}/education`}>
                        Education
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="6" icon={<IdcardOutlined />}>
                      <Link to={`/binawan/candidate/detail/${data.id}/training-and-certification`}>
                        Training and Certification
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="7" icon={<FormatPainterOutlined />}>
                      <Link to={`/binawan/candidate/detail/${data.id}/skills`}>
                        Skills
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="8" icon={<FolderOutlined />}>
                      <Link to={`/binawan/candidate/detail/${data.id}/portfolios`}>
                        Portfolios
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="9" icon={<TrophyOutlined />}>
                      <Link to={`/binawan/candidate/detail/${data.id}/achievements`}>
                        Achievements
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="10" icon={<LockOutlined />}>
                      <Link to={`/binawan/candidate/detail/${data.id}/reset-password`}>
                        Reset Password
                      </Link>
                    </Menu.Item>
                  </Menu>
                </Col>
              </Row>
            </Card>
          </Col>
          {/* Content */}
          <Col span={17}>
            <Card 
              style={{ border: 'none' }}
              title={<Text strong>Skills</Text>} 
              extra={<Button key="1" className="btn-edit-candidate" type="primary" icon={<EditOutlined />} onClick={this.showModal}>Edit</Button>} 
            >
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  {
                    data.listSkill.length > 0 ?
                    <React.Fragment>
                      <Descriptions layout="vertical" size="small" column={2}>
                        {
                          data.listSkill.map((item, i) => (
                          <Descriptions.Item key={i}>
                            <Space direction="vertical" size="small">
                              <Text>{item.name}</Text>
                              {
                                item.skillLevel ?
                                <Text style={{ color: '#32ab6d', fontSize: 14 }}>
                                  {item.skillLevel === 'BASIC' && 'Basic'}
                                  {item.skillLevel === 'INTERMEDIATE' && 'Intermediate'}
                                  {item.skillLevel === 'ADVANCE' && 'Advance'}
                                  {item.skillLevel === 'EXPERT' && 'Expert'}
                                </Text>
                                :
                                'N/A'
                              }
                            </Space>
                          </Descriptions.Item>
                          ))
                        }
                      </Descriptions>
                    </React.Fragment>
                    :
                    <React.Fragment>
                      <Empty description={<Text>No Data Skills</Text>} />
                    </React.Fragment>
                  }
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal
          width={1000}
          footer={false}
          visible={visible}
          onCancel={this.onCancel}
          title={<Text strong>Edit Skill</Text>}
        >
          <EditSkill 
            {...this.props} 
            {...this.state}
            formRef={this.formRef} 
            onFinish={this.onFinish} 
            onChangeName={this.onChangeName}
            handleDelete={this.handleDelete}
          />
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail } = this.props;
    unmountGetDetail()
  }
}

const mapStateToProps = (state) => ({
  getDetailCandidate  : state.candidate.detail,
})

const mapDispatchToProps = {
  actionAdd         : addSkill,
  actionDelete      : deleteSkill,
  actionGetDetail   : detailCandidate,
  unmountGetDetail  : unmountDetailCandidate,
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateDetailSkills)
