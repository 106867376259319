import React from 'react';
import { Typography, Space, Button, Form, Timeline, Input, Row, Col, Spin } from 'antd';
const { TextArea } = Input;
const { Text } = Typography;

export default function ApplicantNotes(props) {
  const { getApplicantNotes, onFinishNotes } = props
  return (
    <div style={{ height: 500, overflowY: 'auto', overflowX: 'hidden', padding: 32 }}>
      {
        getApplicantNotes.loading ?
          <Row align="middle" style={{ height: '100%', textAlign: 'center' }}>
            <Col sm={24} md={24}>
              <Spin size="large" />
            </Col>
          </Row>
        :
          <Timeline mode='left' style={{ marginLeft: getApplicantNotes.data.length > 0 ? -500 : 0 }}>
            <Timeline.Item color="#0076de">
              <Form
                layout="vertical"
                onFinish={onFinishNotes}
              >
                <Form.Item
                  name="notes"
                  rules={[
                    { required: true, message: 'Please input your notes!' },
                    { max: 200, message: '200 characters only!' },
                  ]}
                >
                  <TextArea rows={2} placeholder="Write a notes.." />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Timeline.Item>
            {
              getApplicantNotes.data.map((item, i) => 
                <Timeline.Item key={i} color="#0076de" label={item.phaseName}>
                  <Space direction='vertical'>
                    <Text>{item.submitDate} | <Text style={{ color: '#0076de' }}>{item.picName}</Text></Text>
                    <Text>{item.notes}</Text>
                  </Space>
                </Timeline.Item>
              )
            }
          </Timeline>
      }
    </div>
  )
}
