import React, { Component } from 'react';
import { DeleteTwoTone } from '@ant-design/icons';
import { Form, Row, Col, Input, List, Button } from 'antd';

export default class EditPortfolios extends Component {
  render() {
    const { 
      url,
      formRef,
      onFinish, 
      onChangeName,
      handleDelete,
      getDetailCandidate: { data }, 
    } = this.props;

    return (
      <React.Fragment>
        <Form 
          ref={formRef}
          layout="vertical"
          onFinish={onFinish}
        >
          <Row gutter={8}>
            <Col span={20}>
              <Form.Item 
                name="url"
                validateFirst
                rules={[
                  { pattern: /^\S*$/, message: 'No spaces allowed' },
                  { pattern: /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/, message: 'Invalid url' },
                  { max: 50, message: '50 characters only' },
                ]}
              >
                <Input addonBefore="http://" placeholder="link portfolio" onChange={(e) => onChangeName(e.target.value)} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item>
                <Button 
                  className="btn-save-primary" 
                  htmlType="submit" 
                  type="primary" 
                  disabled={!url}
                >
                  Add
                </Button>
              </Form.Item>
            </Col>
            <Col span={20}>
              <List
                size="small"
                bordered={false}
                dataSource={data.listPortfolio}
                renderItem={(item, i) => (
                  <List.Item
                    key={i}
                    className="txt-color"
                    actions={[<DeleteTwoTone className="c-pointer" twoToneColor="#ff4646" onClick={() => handleDelete(item.id)} />]}
                  >
                    {`${i+1}. ${item.url}`}
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}
