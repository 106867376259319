import React, { Component } from 'react';
import { Loading } from '../../../components';
import { Divider } from 'antd';
import Header from './Header';
import JobPreference from './JobPreference';
import PersonalInfo from './PersonalInfo';
import Document from './Document';
import Address from './Address';
import WorkExperience from './WorkExperience';
import Education from './Education';
import Training from './Training';
import Language from './Language';
import Skill from './Skill';
import Achievement from './Achievement';
import Uniform from './Uniform';
import Portfolio from './Portfolio';

export class ViewProfile extends Component {
  render() {
    const { getProfile: { data, loading } } = this.props;

    if(loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        {/* Header */}
        <Header data={data} />

        <Divider />

        {/* Job Preference */}
        <JobPreference data={data} />

        <Divider />

        {/* Personal Information */}
        <PersonalInfo data={data} />

        <Divider />
        
        {/* Address */}
        <Address data={data} />

        {/* Documents */}
        <Document {...this.props} />

        {/* Work Experiences */}
        <WorkExperience data={data} />

        {/* Education */}
        <Education data={data} />

        {/* Training and Certifications */}
        <Training data={data} />

        {/* Language Skills */}
        <Language data={data} />
    
        {/* Skills */}
        <Skill data={data} />

        {/* Portfolios */}
        <Portfolio data={data} />

        <Divider />

        {/* Achievements */}
        <Achievement data={data} />

        {/* Uniform */}
        <Uniform data={data} />
        
      </React.Fragment>
    )
  }
}

export default ViewProfile