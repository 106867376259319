import React, { Component } from 'react';
import { Skeleton, Form, Row, Col, Select, Typography, InputNumber } from 'antd';
const { Text } = Typography;

export default class EditJobPreference extends Component {
  render() {
    const { 
      getDetailCandidate: { data },
      isSalary,
      getJobDestination,
      getCountry,
      formRef, 
      onFinish, 
      getJobIndustry, 
      handleIndustry,
      dataJobPosition,
      handleSalaryTo,
      handleSalaryFrom,
    } = this.props;

    if(getJobDestination.loading || getCountry.loading){
      return <Skeleton />
    }
    const initialJobIndustry = data.listJobIndustry ? data.listJobIndustry.map(item => item.jobIndustryId) : []
    const initialJobPostion = data.listJobPosition ? data.listJobPosition.map(item => item.jobPositionId) : []
    const initialJobDestination = data.listJobDestination ? data.listJobDestination.map(item => item.jobDestinationId) : []
    return (
      <React.Fragment>
        <Form ref={formRef} onFinish={onFinish} layout="vertical">
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item 
                label="Job Industry Preferences" 
                name="listJobIndustry"
                initialValue={initialJobIndustry}
                rules={[
                  { 
                    validator: (rule, value, callback) => {
                      if (value) {
                        if (value.length > 3) {
                          callback("You can only pick 3 job industries!");
                        } else if (value.length <= 3) {
                          callback();
                        }
                      }
                      return;
                    }
                  }
                ]}
              >
                <Select allowClear showSearch mode="multiple" onChange={handleIndustry}>
                  {
                    getJobIndustry?.data?.map(item => 
                      <Select.Option key={item.id} value={item.id}>{item.title}</Select.Option>
                    )
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item 
                label="Job Position Preferences"
                name="listJobPosition"
                initialValue={initialJobPostion}
                rules={[
                  { 
                    validator: (rule, value, callback) => {
                      if (value) {
                        if (value.length > 3) {
                          callback("You can only pick 3 job position!");
                        } else if (value.length <= 3) {
                          callback();
                        }
                      }
                      return;
                    }
                  },
                ]}
              >
                <Select allowClear showSearch mode="multiple">
                  {
                    dataJobPosition.map((item, i) => 
                      <Select.OptGroup key={i} label={item.name}>
                        {
                          item.listJobPosition && item.listJobPosition.map(res => (
                            <Select.Option key={res.id} value={res.id}>{res.title}</Select.Option>
                          ))
                        }
                      </Select.OptGroup>
                    )
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item 
                label="Job Destination Preferences"
                name="listJobDestination"
                initialValue={initialJobDestination}
                rules={[
                  { 
                    validator: (rule, value, callback) => {
                      if (value) {
                        if (value.length > 5) {
                          callback("You can only pick 5 job destination!");
                        } else if (value.length <= 5) {
                          callback();
                        }
                      }
                      return;
                    }
                  },
                ]}
              >
                <Select allowClear showSearch mode="multiple">
                  {
                    getJobDestination.data.map((item, i) => 
                      <Select.OptGroup key={i} label={item.name}>
                        {
                          item.listDestination && item.listDestination.map(res => (
                            <Select.Option key={res.id} value={res.id}>{res.name}</Select.Option>
                          ))
                        }
                      </Select.OptGroup>
                    )
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} style={{ marginBottom: 20 }}>
              <Text strong type="secondary">Salary Expectation</Text>
            </Col>
            <Col span={8}>
              <Form.Item label="Currency" name="currencyCodeId" initialValue={data.currencyCodeId ? data.currencyCodeId : null}>
                <Select showSearch optionFilterProp="children">
                  {
                    getCountry?.data?.map(item => 
                      <Select.Option key={item.id} value={item.id}>{item.currencyCode}</Select.Option>
                    )
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item 
                label="Start From:"
                name="salaryFrom"
                initialValue={data.salaryFrom ? data.salaryFrom : null}
              >
                <InputNumber
                  maxLength={12}
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  onChange={handleSalaryFrom}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item 
                label="To:"
                name="salaryTo"
                initialValue={data.salaryTo ? data.salaryTo : null}
                validateStatus={isSalary ? "error" : null}
                help={isSalary ? "Value ‘To’ must be bigger than ’Start From’" : null}
              >
                <InputNumber
                  maxLength={12}
                  onChange={handleSalaryTo}
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}
