import React, { Component } from 'react';
import moment from 'moment';
import JobOverview from './JobOverview';
import OpeningStatus from './OpeningStatus';
import HiringPipeline from './HiringPipeline';
import ApplicantSummary from './ApplicantSummary';
import svg from '../../../assets/img/DashboardEmployer.svg';
import { Link } from 'react-router-dom';
import { ReloadOutlined } from '@ant-design/icons';
import { Typography, Card, Image, Row, Col, Button, Space, Radio, Skeleton } from 'antd';
const { Title, Text } = Typography;

export default class DashboardAgency extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      menuLineChart: 'today',
      menuHiring: 'last_posted',
      menuOverview: 'last_posted'
    }
  }

  componentDidMount() {
    const { actionGetJobOpeningStatus } = this.props;
    return actionGetJobOpeningStatus('dashboard_agency')
  }

  onRefreshLineChart = () => {
    const { menuLineChart } = this.state;
    const { actionGetApplicantSummary } = this.props;
    return actionGetApplicantSummary('dashboard_agency', menuLineChart)
  }

  onRefreshMenuHiring = () => {
    const { menuHiring } = this.state;
    const { actionGetPipelineHiring } = this.props;
    return actionGetPipelineHiring('dashboard_agency', menuHiring)
  }

  onRefreshMenuOverview = () => {
    const { menuOverview } = this.state;
    const { actionGetJobOverview } = this.props;
    return actionGetJobOverview('dashboard_agency', menuOverview)
  }

  handleMenuLineChart = e => {
    const { actionGetApplicantSummary } = this.props;
    this.setState({ menuLineChart: e.target.value }, () => {
      return actionGetApplicantSummary('dashboard_agency', e.target.value)
    });
  };

  handleMenuHiring = e => {
    const { actionGetPipelineHiring } = this.props;
    this.setState({ menuHiring: e.target.value }, () => {
      return actionGetPipelineHiring('dashboard_agency', e.target.value)
    });
  };
  
  handleMenuOverview = e => {
    const { actionGetJobOverview } = this.props;
    this.setState({ menuOverview: e.target.value }, () => {
      return actionGetJobOverview('dashboard_agency', e.target.value)
    });
  };

  render() {
    const { menuLineChart, menuHiring, menuOverview } = this.state;
    const { getJobOpeningStatus: { loading, data } } = this.props;
    if(loading){
      return <Skeleton />
    }
    return (
      <React.Fragment>
        <Row gutter={[16, 24]}>
          <Col span={24}>
            <Title level={4}>Hello Admin Agency</Title> 
            <Text type="secondary">
              {moment().format('LL')}
            </Text>
          </Col>
          {
            data.value.length ?
            <React.Fragment>
             <Col span={24}>
                <Card style={{ border: 'none' }}>
                  <Row gutter={[16, 8]}>
                    <ApplicantSummary 
                      {...this.props} 
                      menuLineChart={menuLineChart} 
                      onRefreshLineChart={this.onRefreshLineChart} 
                      handleMenuLineChart={this.handleMenuLineChart}
                    />
                  </Row>
                </Card>
              </Col>
              <Col span={24}>
                <Card style={{ border: 'none' }}>
                  <Row gutter={[16, 8]}>
                    <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Space>
                        <Text strong style={{ fontSize: 18 }}>Hiring Pipeline</Text>
                        <Link to="#" onClick={this.onRefreshMenuHiring}>
                          <ReloadOutlined style={{ color: '#919191', fontSize: 16 }} />
                        </Link>
                      </Space>
                      <Radio.Group onChange={this.handleMenuHiring} defaultValue={menuHiring} buttonStyle="solid">
                        <Radio.Button value="least_applicant">Least Applied</Radio.Button>
                        <Radio.Button value="most_applicant">Most Applied</Radio.Button>
                        <Radio.Button value="last_posted">Last Posted</Radio.Button>
                      </Radio.Group>
                    </Col>
                    <Col span={24}>
                      <HiringPipeline {...this.props} menuHiring={menuHiring} />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={14}>
                <Card style={{ border: 'none', height: '100%' }}>
                  <Row gutter={[16, 8]}>
                    <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Space>
                        <Text strong style={{ fontSize: 18 }}>Job Overview</Text>
                        <Link to="#" onClick={this.onRefreshMenuOverview}>
                          <ReloadOutlined style={{ color: '#919191', fontSize: 16 }} />
                        </Link>
                      </Space>
                      <Radio.Group onChange={this.handleMenuOverview} defaultValue={menuOverview} buttonStyle="solid">
                        <Radio.Button value="needs">Needs</Radio.Button>
                        <Radio.Button value="on_progress">On Progress</Radio.Button>
                        <Radio.Button value="last_posted">Posted Date</Radio.Button>
                      </Radio.Group>
                    </Col>
                    <Col span={24}>
                      <JobOverview {...this.props} menuOverview={menuOverview} />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={10}>
                <Card style={{ border: 'none', height: '100%' }}>
                  <Row gutter={[16, 24]}>
                    <Col span={24}>
                      <Text strong style={{ fontSize: 18 }}>Job Opening Status</Text>
                    </Col>
                    <Col span={24}>
                      <OpeningStatus {...this.props} />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </React.Fragment>
            :
            <React.Fragment>
              <Col span={24}>
                <Card style={{ border: 'none' }}>
                  <Row gutter={[16, 16]} style={{ textAlign: 'center' }}>
                    <Col span={24}>
                      <Title level={4}>Welcome!</Title>
                    </Col>
                    <Col span={24}>
                      <Text type="secondary">Kickstart your project by posting a job. </Text>
                    </Col>
                    <Col span={24}>
                      <Button type="primary" href="/agency/jobs/create">Post a Job</Button>
                    </Col>
                    <Col span={24}>
                      <Image src={svg} preview={false} />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </React.Fragment>
          }
        </Row>
      </React.Fragment>
    )
  }
}
