export const pathwayDescription = [
  {
    "value": "STUDY",
    "name": "Proses pembelajaran pada universitas mitra yang memiliki platform pembelajaran holistik dalam kerangka akademik berstandar internasional."
  },
  {
    "value": "BOOTCAMP",
    "name": "Pelatihan dengan kurikulum yang dirancang dengan durasi pendek dan berfokus pada skill tertentu."
  },
  {
    "value": "NIGHT_CLASS",
    "name": "Program bagi para professional untuk tetap bisa mendapatkan gelar dan mencapai tujuan akademis. Berguna untuk membangun keterampilan, memperluas jaringan professional dan meningkatkan karier."
  },
  {
    "value": "INTERNSHIP",
    "name": "Program untuk mendapatkan pengalaman kerja terbaik di dunia profesional sehingga menjadi SDM unggul yang siap kerja."
  },
  {
    "value": "READY_WORK",
    "name": "Setelah melakukan berbagai tahapan untuk mempersiapkan diri menuju karir impian, kini kamu telah siap untuk bekerja!"
  }
]