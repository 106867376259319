import React from 'react'
import { Row, Col, Typography, Space, Descriptions, Divider } from 'antd'

const { Text } = Typography

export default function Language(props) {
  const { data } = props
  return (
    <Row gutter={16}>
      <Col span={24}>
        <Descriptions title="Language Skills" />
        {
          data.listLanguage.length > 0 ?
            <React.Fragment>
              {
                // data.listLanguage.map((item, i) => (
                  <Row >
                    <div style={{ marginBottom: 11 }}>
                    <Space direction="vertical">
                      <Text strong>Native Language</Text>
                      <Text style={{ color: '#32ab6d' }}>
                        {
                          data.listLanguage.find(item => item.isNative === true) ? data.listLanguage.find(item => item.isNative === true).name : 'N/A'
                        }
                      </Text>
                      <Divider className='mt-16 mb-16' />
                      <Col span={24} style={{ marginBottom: 15 }}>
                        <Text strong>Others Language</Text>
                      </Col>
                    </Space>
                    </div>
                    {
                        data.listLanguage.map((item, i) =>
                          !item.isNative ?
                            <React.Fragment key={i}>
                              
                              <Col span={24} style={{ marginBottom: 15 }}>
                                <Text style={{ color: '#7B7B7B'}}>Language</Text>
                              </Col>
                              <Col span={24} style={{ marginBottom: 16 }}>
                                <Space className='width-100' direction='vertical' size={8}>
                                  <Text>
                                    {
                                      item.name ?
                                        <Text style={{ color: '#32ab6d' }}>{item.name}</Text>
                                      :
                                        'N/A'
                                    }
                                  </Text>
                                </Space>
                              </Col>
                              <Col span={24} style={{ marginBottom: 8 , color: '#7B7B7B'}}>
                                <Text style={{ color: '#7B7B7B'}}>Understanding</Text>
                              </Col>
                              <Col span={12} style={{ marginBottom: 8 , color: '#7B7B7B'}}>
                                <Space direction='vertical' size={0}>
                                  <Text style={{ color: '#7B7B7B'}}>Listening</Text>
                                  <Text className='ant-descriptions-item-content'>
                                    {
                                      item.listeningLevelId ?
                                        <Text className='ant-descriptions-item-content'>{item.listeningLevelCode} {item.listeningLevelName}</Text>
                                      :
                                        'N/A'
                                    }
                                  </Text>
                                </Space>
                              </Col>
                              <Col span={12} style={{ marginBottom: 8 }}>
                                <Space direction='vertical' size={0}>
                                  <Text style={{ color: '#7B7B7B'}}>Reading</Text>
                                  <Text className='ant-descriptions-item-content'>
                                    {
                                      item.readingLevelId ?
                                        <Text className='ant-descriptions-item-content'> {item.readingLevelCode} {item.readingLevelName}</Text>
                                      :
                                        ' N/A'
                                    }
                                  </Text>
                                </Space>
                              </Col>
                              <Col span={24} style={{ marginBottom: 8 }}>
                                <Text style={{ color: '#7B7B7B'}}>Speaking</Text>
                              </Col>
                              <Col span={12} style={{ marginBottom: 8 }}>
                                <Space direction='vertical' size={0}>
                                  <Text style={{ color: '#7B7B7B'}}>Speaking Interaction</Text>
                                  <Text className='ant-descriptions-item-content'>
                                    {
                                      item.spokenInterLevelId ?
                                        <Text className='ant-descriptions-item-content'> {item.spokenInterLevelCode} {item.spokenInterLevelName}</Text>
                                      :
                                        ' N/A'
                                    }
                                  </Text>
                                </Space>
                              </Col>
                              <Col span={12} style={{ marginBottom: 8 }}>
                                <Space direction='vertical' size={0}>
                                  <Text style={{ color: '#7B7B7B'}}>Spoken Introduction</Text>
                                  <Text className='ant-descriptions-item-content'>
                                    {
                                      item.spokenIntroLevelId ?
                                        <Text className='ant-descriptions-item-content'>{item.spokenIntroLevelCode} {item.spokenIntroLevelName}</Text>
                                      :
                                        ' N/A'
                                    }
                                  </Text>
                                </Space>
                              </Col>
                              <Col span={24}>
                                <Text style={{ color: '#7B7B7B'}}>Writing</Text>
                              </Col>
                              <Col span={24} style={{ marginBottom: 16 }}>
                                <Text className='ant-descriptions-item-content'>
                                  {
                                    item.writingLevelId ?
                                      <Text className='ant-descriptions-item-content'>{item.writingLevelCode} {item.writingLevelName}</Text>
                                    :
                                      ' N/A'
                                  }
                                </Text>
                              </Col>
                            </React.Fragment>
                          : null
                        )
                      } 
                    <Divider />
                  </Row>
              // ))
              }
            </React.Fragment>
          :
            <React.Fragment>
              <Text>N/A</Text>
              <Divider />
            </React.Fragment>
        } 
      </Col>
    </Row>
  )
}
