import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { createFaq } from '../../../../redux/actions/faq/faqAction';
import { masterClients, unmountMasterClients } from '../../../../redux/actions/client/clientAction';
import { Col, Form, Row, Input, Button, PageHeader, message, Card, Breadcrumb, Select, Skeleton } from 'antd';
// const { Text } = Typography;
const { Option } = Select;

class CreateFaqAgency extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      listClient: [],
    }
  }

  componentDidMount() {
    const { actionGetClient } = this.props

    return actionGetClient()
  }

  onFinish = (values) => {
    const { actionCreate, history } = this.props;
    const { listClient } = this.state;
    const arrListClient = listClient.map((item) => {
      return { clientId: item };
    });
    values.listClient = arrListClient
    this.setState({ submitLoading: true })
    actionCreate(values, () => {
      this.setState({ submitLoading: false }, () => {
        message.success('Data created successfully')
        history.push('/agency/faq')
      })
    }, (err) => {
      message.error(err.message)
      this.setState({ submitLoading: false })
    })
  }

  handleClient = (value, option) => {
    const optionSelected = option.map(item => Number(item.key))
    
    this.setState({
      listClient: optionSelected,
    })
  }
 
  render() {
    const { getListClient, } = this.props
    const { submitLoading } = this.state;

    if(getListClient.loading){
      return <Skeleton active />
    }

    return (
      <React.Fragment>
        <Form onFinish={this.onFinish} layout="vertical">
          <Row style={{ position: 'sticky', zIndex: 1, width: '100%', top: 62, right: 0, marginTop: -20, marginBottom: 8, paddingBottom: 12, paddingTop: 16, backgroundColor: '#f2f2f2' }}>
            {/* Breadcrumb */}
            <Col span={24}>
              <Breadcrumb>
                <Breadcrumb.Item><QuestionCircleOutlined /> FAQ</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link className="link" to="/agency/faq">
                    FAQ List
                  </Link> 
                </Breadcrumb.Item>
                <Breadcrumb.Item>Create FAQ</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            { /* Page Header */}
            <Col span={24}>
              <PageHeader 
                className="site-page-header" 
                title="Create an FAQ"
                extra={[
                  <Button key="2" className="btn-save-primary" type="default" onClick={() => window.history.back()}>Cancel</Button>,
                  <Button key="1" className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading}>Create</Button>
                ]}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            { /* Form */}
            <Col span={24}>
              <Card>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item 
                      label="Question" 
                      name="question" 
                      validateFirst
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { max: 1000, message: '1000 characters only' },
                        { min: 10, message: 'At least 10 characters long' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item 
                      label="Answer" 
                      name="answer"
                      validateFirst
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { max: 1000, message: '1000 characters only' },
                        { min: 10, message: 'At least 10 characters long' },
                      ]}
                    >
                      <Input.TextArea rows={3} />
                    </Form.Item>
                  </Col>
                  
                  <Col span={24}>
                    <Form.Item 
                      label="Client Name"
                      name="listClient"
                      validateFirst
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select 
                        className="width-100 border-radius-6"
                        mode="multiple"
                        allowClear 
                        size="large" 
                        showSearch
                        onChange={this.handleClient}
                      >
                        {
                          getListClient?.data?.map((item, i) => 
                            <Option key={item.id} value={item.name}>{item.name}</Option>
                          )
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountListClient } = this.props
    return unmountListClient()
  }
}

const mapStateToProps = (state) => ({
  getListClient : state.clients.master,

})

const mapDispatchToProps = {
  actionCreate: createFaq,
  actionGetClient   : masterClients,
  unmountListClient : unmountMasterClients,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateFaqAgency)
