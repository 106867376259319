import jsPDF from 'jspdf';
import moment from 'moment';
import Logo from '../assets/img/logo-bundesagentur.png';
import Barcode from '../assets/img/barcode.png';
// import Signature from '../assets/img/ttdis.png';
import { message } from 'antd';

export const downloadImmatrikulation = (data) => {
    const dataEducation = data?.listEducation?.find(item => item.name.includes('binawan') || item.name.includes('Binawan') || item.name.includes('BINAWAN'))
    if(dataEducation && dataEducation?.fieldOfStudy && dataEducation.startYear && (dataEducation.endYear || dataEducation.current)){
      const estimateEndYear = 
        dataEducation?.educationLevelName === "Bachelor / S1" || dataEducation?.educationLevelName === "Diploma IV" ?
          Number(dataEducation?.startYear)+4
        : dataEducation?.educationLevelName === "Diploma III" ?
          Number(dataEducation?.startYear)+3
        : dataEducation?.educationLevelName === "Professional Program" ?
          Number(dataEducation?.startYear)+1
        : null
      
      const standardPeroid = 
        dataEducation?.educationLevelName === "Bachelor / S1" || dataEducation?.educationLevelName === "Diploma IV" ?
          '4'
        : dataEducation?.educationLevelName === "Diploma III" ?
          '3'
        : dataEducation?.educationLevelName === "Professional Program" ?
          '2'
        : null

      const doc = new jsPDF('landscape', 'pt', [842,615],true)
      doc.setFontSize(10)
      doc.text(90, 30, 'Unsere datenschutzrechtlichen Hinweise finden Sie unter: //')
      doc.setTextColor(150)
      doc.text(90, 45, 'You can find the data protection guidelines of the')
      doc.text(90, 60, 'German Federal Employment Agency (German-language only) at:')
      doc.setTextColor(0, 118, 222)
      doc.text(90, 75, 'https://www.arbeitsagentur.de/datenerhebung')
      doc.addImage(Logo, 'PNG', 500, 20, 250, 60)

      doc.setTextColor(0)
      doc.setFontSize(17)
      doc.setFont('helvetica','','bold')
      doc.text(40, 123, 'Erklärung zur Immatrikulationsbescheinigung //')
      doc.setTextColor(150)
      doc.setFontSize(17)
      doc.setFont('helvetica','','bold')
      doc.text(427, 123, 'Declaration of Certificate of Enrolment')

      doc.setTextColor(0)
      doc.setFontSize(13)
      doc.setFont('helvetica','','bold')
      doc.text(40, 140, 'Universität/Hochschule //')
      doc.setTextColor(150)
      doc.setFontSize(13)
      doc.setFont('helvetica','','bold')
      doc.text(200, 140, 'University/college')

      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(40, 155, '1 Bezeichnung //')
      doc.setTextColor(150)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(118, 155, 'Name of university/college')
      doc.rect(40, 160, 280, 15)
      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(42, 170, 'Binawan University')

      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(40, 190, '2 Anschrift //')
      doc.setTextColor(150)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(97, 190, 'Address of university/college')
      doc.rect(40, 195, 280, 15)
      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(42, 205, 'Jalan Kalibata Raya No.25-30 Cawang DKI Jakarta 13630')

      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(40, 225, '3 Telefon //')
      doc.setTextColor(150)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(92, 225, 'Telephone')
      doc.rect(40, 230, 140, 15)
      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(42, 240, '021-80880882')

      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(185, 225, '4 E-Mail //')
      doc.setTextColor(150)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(232, 225, 'e-mail')
      doc.rect(185, 230, 135, 15)
      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(187, 240, 'info@binawan.ac.id')

      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(40, 260, '5 Website //')
      doc.setTextColor(150)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(95, 260, 'Website')
      doc.rect(40, 265, 280, 15)
      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(42, 275, 'www.binawan.ac.id')

      doc.setTextColor(0)
      doc.setFontSize(13)
      doc.setFont('helvetica','','bold')
      doc.text(330, 140, 'Studierender //')
      doc.setTextColor(150)
      doc.setFontSize(13)
      doc.setFont('helvetica','','bold')
      doc.text(425, 140, 'Student')

      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(330, 155, '6 Vorname //')
      doc.setTextColor(150)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(389, 155, 'Name')
      doc.rect(330, 160, 190, 15)
      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(332, 170, data.firstName)

      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(330, 190, '7 Nachname //')
      doc.setTextColor(150)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(397, 190, 'Surname')
      doc.rect(330, 195, 190, 15)
      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(332, 205, data.lastName)

      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(330, 225, '8 Geburtsdatum //')
      doc.setTextColor(150)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(413, 225, 'Date of birth')
      doc.rect(330, 230, 190, 15)
      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      if(data.birthdate){
        doc.text(332, 240, moment(data.birthdate, 'DD/MM/YYYY').format('DD.MM.YYYY'))
      }else{
        doc.setTextColor(255, 0, 0);
        doc.setFontSize(16)
        doc.text(332, 240, '-')
        doc.setTextColor(0)
        doc.setFontSize(10)
      }

      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(330, 260, '9 Staatsangehörigkeit //')
      doc.setTextColor(150)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(438, 260, 'Nationality')
      doc.rect(330, 265, 190, 15)
      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      if(data.nationality){
        doc.text(332, 275, data.nationality)
      }else{
        doc.setTextColor(255, 0, 0);
        doc.setFontSize(16)
        doc.text(332, 275, '-')
        doc.setTextColor(0)
        doc.setFontSize(10)
      }

      doc.setTextColor(0)
      doc.setFontSize(13)
      doc.setFont('helvetica','','bold')
      doc.text(530, 140, 'Studiengang //')
      doc.setTextColor(150)
      doc.setFontSize(13)
      doc.setFont('helvetica','','bold')
      doc.text(624, 140, 'Course of study')

      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(527, 155, '10 Studienfach //')
      doc.setTextColor(150)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(605, 155, 'Subject')
      doc.rect(530, 160, 260, 15)
      doc.setTextColor(0)
      doc.setFontSize(8.5)
      doc.setFont('helvetica','','normal')
      if(dataEducation.fieldOfStudy){
        doc.text(532, 170, dataEducation.fieldOfStudy)
      }else{
        doc.setTextColor(255, 0, 0);
        doc.setFontSize(16)
        doc.text(532, 170, '-')
        doc.setTextColor(0)
        doc.setFontSize(10)
      }

      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(529, 190, '11 Regeldauer Studium //')
      doc.setTextColor(150)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(645, 190, 'Standard period of study')
      doc.rect(530, 195, 90, 15)
      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      if(standardPeroid){
        doc.text(532, 205, standardPeroid)
      }else{
        doc.setTextColor(255, 0, 0);
        doc.setFontSize(16)
        doc.text(532, 205, '-')
        doc.setTextColor(0)
        doc.setFontSize(10)
      }
      doc.setTextColor(0)
      doc.text(630, 205, 'Jahre //')
      doc.setTextColor(150)
      doc.text(667, 205, 'Years')

      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(530, 225, '12 Eingeschrieben seit //')
      doc.setTextColor(150)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(641, 225, 'Enrolled since')
      doc.rect(530, 230, 90, 15)
      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      if(dataEducation.startYear){
        doc.text(532, 240, `14.09.${dataEducation.startYear}`)
      }else{
        doc.setTextColor(255, 0, 0);
        doc.setFontSize(16)
        doc.text(532, 240, '-')
        doc.setTextColor(0)
        doc.setFontSize(10)
      }
      
      doc.text(630, 240, '(TT.MM.JJJJ) //')
      doc.setTextColor(150)
      doc.text(702, 240, '(DD.MM.YYYY)')

      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(530, 260, '13 Voraussichtliches Studienende //')
      doc.setTextColor(150)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(691, 260, 'Expected end of study')
      doc.rect(530, 265, 90, 15)
      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      if(dataEducation.endYear || (dataEducation.current && estimateEndYear)){
        doc.text(532, 275, `14.07.${estimateEndYear || dataEducation.endYear}`)
      }else{
        doc.setTextColor(255, 0, 0);
        doc.setFontSize(16)
        doc.text(532, 275, '-')
        doc.setTextColor(0)
        doc.setFontSize(10)
      }
      
      doc.text(630, 275, '(TT.MM.JJJJ) //')
      doc.setTextColor(150)
      doc.text(702, 275, '(DD.MM.YYYY)')

      doc.setTextColor(0)
      doc.setFontSize(13)
      doc.setFont('helvetica','','bold')
      doc.text(40, 300, 'Die Bundesagentur für Arbeit benötigt die folgenden Angaben für die Genehmigung //')
      doc.setTextColor(150)
      doc.setFontSize(13)
      doc.setFont('helvetica','','bold')
      doc.text(40, 320, 'The German Federal Employment Agency requires the following information for the approval of')

      doc.setFillColor(153, 38, 0)
      doc.circle(50, 335, 4, 'FD')
      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(65, 337, 'einer Ferienbeschäftigung //')
      doc.setTextColor(150)
      doc.text(190, 337, 'a vacation job')
      
      doc.setTextColor(0)
      doc.text(65, 355, '14 Offizielle Semesterferien //')
      doc.setTextColor(150)
      doc.text(198, 355, 'Official semester break')
      
      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(310, 355, 'von //')
      doc.setTextColor(150)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(336, 355, 'begin')
      doc.rect(380, 345, 80, 15)
      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(382, 355, '01.10.2023')
      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(490, 355, 'bis //')
      doc.setTextColor(150)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(513, 355, 'end')
      doc.rect(550, 345, 80, 15)
      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(552, 355, '31.12.2023')
      doc.text(640, 355, '(TT.MM.JJJJ) //')
      doc.setTextColor(150)
      doc.text(712, 355, '(DD.MM.YYYY)')
      
      doc.setTextColor(0)
      doc.text(65, 375, '15 Fortsetzung des Studiums nach den Semesterferien //')
      doc.setTextColor(150)
      doc.text(320, 375, 'OfficialContinuation of studies after semester break')
      
      doc.setFillColor(153, 38, 0)
      doc.circle(570, 372, 4, 'FD')
      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.text(580, 375, 'Ja //')
      doc.setTextColor(150)
      doc.text(601, 375, 'Yes')
      
      doc.setFillColor(255, 255, 255)
      doc.circle(650, 372, 4, 'FD')
      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.text(660, 375, 'Nein //')
      doc.setTextColor(150)
      doc.text(691, 375, 'No')
      
      doc.setFillColor(255, 255, 255)
      doc.circle(50, 390, 4, 'FD')
      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(65, 392, 'eines studienfachbezogenen Praktikums //')
      doc.setTextColor(150)
      doc.text(254, 392, 'an internship related to the field of study')

      doc.setTextColor(0)
      doc.text(65, 410, '16 Angestrebter akademischer Grad //')
      doc.setTextColor(150)
      doc.text(236, 410, 'Expected academic degree')
      
      // doc.setFillColor(153, 38, 0)
      doc.setFillColor(255, 255, 255)
      doc.circle(370, 407, 4, 'FD')
      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(380, 410, 'Bachelor //')
      doc.setTextColor(150)
      doc.text(430, 410, 'Undergraduate')
      
      doc.setFillColor(255, 255, 255)
      doc.circle(510, 407, 4, 'FD')
      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(520, 410, 'Master //')
      doc.setTextColor(150)
      doc.text(561, 410, 'Graduate')
      
      doc.setFillColor(255, 255, 255)
      doc.circle(610, 407, 4, 'FD')
      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(620, 410, 'Doktorand //')
      doc.setTextColor(150)
      doc.text(677, 410, 'Postgraduate')

      doc.setFillColor(255, 255, 255)
      doc.circle(370, 422, 4, 'FD')
      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(380, 425, 'andere Hochschulbildung (Mindestlaufzeit 3 Jahre) //')
      doc.setTextColor(150)
      doc.text(615, 425, 'Other higher education (min. duration 3 years)')
      
      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(65, 450, '17 Die Studienordnung beinhaltet ein Pflichtpraktikum von mindestens //')
      doc.setTextColor(150)
      doc.text(78, 465, 'Study regulations include a mandatory internship of at least')
      
      doc.rect(390, 440, 30, 30)
      doc.setTextColor(0)
      doc.text(425, 450, 'Stunden //')
      doc.setTextColor(150)
      doc.text(425, 465, 'hours')
      
      doc.rect(490, 440, 30, 30)
      doc.setTextColor(0)
      doc.text(525, 450, 'Wochen //')
      doc.setTextColor(150)
      doc.text(525, 465, 'weeks')
      
      doc.rect(590, 440, 30, 30)
      doc.setFontSize(14)
      doc.setTextColor(0)
      // doc.text(602, 460, '3')
      doc.setFontSize(10)
      doc.text(625, 450, 'Monate //')
      doc.setTextColor(150)
      doc.text(625, 465, 'months')
      
      doc.rect(690, 440, 30, 30)
      doc.setTextColor(0)
      doc.text(725, 450, 'trifft nicht zu //')
      doc.setTextColor(150)
      doc.text(725, 465, 'not applicable')
      
      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(78, 485, '(Bitte entsprechenden Auszug aus der Studienordnung beilegen //')
      doc.setTextColor(150)
      doc.text(374, 485, 'Please enclose the relevant details from the study regulations)')
      
      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(65, 510, '18 Davon wurden')
      doc.setTextColor(150)
      doc.text(78, 525, 'Of which')
      
      doc.rect(180, 500, 30, 30)
      doc.setTextColor(0)
      doc.text(215, 510, 'Stunden //')
      doc.setTextColor(150)
      doc.text(215, 525, 'hours')
      
      doc.rect(285, 500, 30, 30)
      doc.setTextColor(0)
      doc.text(320, 510, 'Wochen //')
      doc.setTextColor(150)
      doc.text(320, 525, 'weeks')
      
      doc.rect(390, 500, 30, 30)
      doc.setTextColor(0)
      doc.text(425, 510, 'Monate //')
      doc.setTextColor(150)
      doc.text(425, 525, 'months')

      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(40, 540, '19 Datum //')
      doc.setTextColor(150)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(93, 540, 'Date')
      doc.rect(40, 545, 100, 15)
      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(42, 555, '01.04.2023')
      
      doc.setTextColor(0)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(165, 540, '20 Unterschrift und Stempel der Universität/Hochschule //')
      doc.setTextColor(150)
      doc.setFontSize(10)
      doc.setFont('helvetica','','normal')
      doc.text(421, 540, 'Signature and stamp of the university/college')
      doc.rect(165, 545, 620, 15)

      // doc.addImage(Signature, 'PNG', 630, 500, 60, 60)

      doc.addImage(Barcode, 'PNG', 200, 570, 80, 40)
      doc.setFont('italic')
      doc.text(500, 580, 'AMZ - Erklärung_Immatrikulation - 03/2022')
      doc.setFont('helvetica','','normal')
      doc.text(730, 580, 'Seite 1 von 1')

      doc.save(`${data.candidateNumber} - Immatrikulation.pdf`);
      message.success('Download success!')
    }else{
      message.error('Data is not complete. Please contact the candidate!')
    }
  }