import React, { Component } from 'react';
import moment from 'moment';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Row, Col, Space, Typography, Button, Divider, Form, Radio, DatePicker, TimePicker } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import HeaderActiveApplicant from '../../../../../../../../../components/ActiveApplicants/Header'
const { Text } = Typography;

export default class ContentOffered extends Component {

  disabledDate = (current) => {
    let yesterday = moment();
    return !current || current.isBefore(yesterday);
  }

  onFinish = (values) => {
    const { onFinish } = this.props;
    return onFinish(values)
  }

  render() {
    const { dataApplicant, openModalProfile, handleChangeOfferedResult, isResult, formRef, isDeparture, showModalNotes, showModalHistory, showModalReject } = this.props;
    return (
      <React.Fragment>
        <HeaderActiveApplicant dataApplicant={dataApplicant} openModalProfile={openModalProfile} showModalNotes={showModalNotes} showModalHistory={showModalHistory} />
        <Divider />
        <Form onFinish={this.onFinish} layout="vertical" ref={formRef}>
          <Row gutter={16}>
            <Col span={24}>
              <Text>Congratulations! The applicant has successfully passed the assessment and interview stages. Now, it's time to make an offer for the desired position.</Text>
            </Col>
            <Col span={24}>
              <Divider dashed />
            </Col>
            <Col span={24}>
              <Text strong>Applicant's Response:</Text>
            </Col>
            <Col span={24}>
              <Form.Item name="isResult" label="Please confirm the submitted offer." initialValue={isResult}>
                <Radio.Group onChange={handleChangeOfferedResult}>
                  <Radio value={true}>Accept</Radio>
                  <Radio value={false}>Decline</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {
              isResult === null &&
              <Col span={24} style={{ marginBottom: 32 }}>
                <Space direction='vertical'>
                  <Text strong>Remove Applicant:</Text>
                  <Text>If the applicant fails to confirm or does not attend the assessment, you can remove them from the process by clicking the button below.</Text>
                  <Button icon={<CloseCircleOutlined />} size='small' danger onClick={() => showModalReject(dataApplicant.id)}>Remove Applicant</Button>
                </Space>
              </Col>
            }
            {
              isResult === true &&
              <React.Fragment>
                <Col span={12}>
                  <Form.Item 
                    label="Join Date"
                    name="joinDate"
                    rules={[
                      { required: true, message: 'This is a required field' },
                    ]}
                  >
                    <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
                  </Form.Item>
                </Col>
                {/* <Col span={24} style={{ marginBottom: 16 }}>
                  <Checkbox onChange={handleChecked}>Applicant will undergo the departure process.</Checkbox>
                </Col> */}
                {
                  isDeparture ?
                    <React.Fragment>
                      <Col span={24} style={{ marginBottom: 8 }}>
                        <Text strong>Departure:</Text>
                      </Col>
                      <Col span={24} style={{ marginBottom: 8 }}>
                        <Text>Set the departure schedule.</Text>
                      </Col>
                      <Col span={8}>
                        <Form.Item 
                          label="Departure Date"
                          name="departureDate"
                          rules={[
                            { required: true, message: 'This is a required field' },
                          ]}
                        >
                          <DatePicker 
                            format="DD/MM/YYYY"
                            style={{ width: '100%' }}
                            disabledDate={this.disabledDate}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item 
                          label="Departure Time"
                          name="departureTime"
                          rules={[
                            { required: true, message: 'This is a required field' },
                          ]}
                        >
                          <TimePicker style={{ width: '100%' }} format="HH:mm" />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item 
                          name="location" 
                          label="Departure Location"
                          initialValue={null}
                          rules={[
                            { required: true, message: 'This is a required field' },
                          ]}
                        >
                          <ReactQuill />
                        </Form.Item>
                      </Col>
                    </React.Fragment>
                  : null
                }
                <Col span={24}>
                  <Form.Item 
                    name="notes" 
                    label="Notes to Applicant (optional)" 
                    initialValue={null}
                    // rules={[
                    //   { required: !isResult, message: 'This is a required field' },
                    // ]}
                  >
                    <ReactQuill />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Button className='btn-radius' type="primary" htmlType="submit" style={{ float: 'right' }}>{isDeparture ? 'Proceed to Nest Stage' : 'Proceed to Hire'}</Button>
                </Col>
              </React.Fragment>
            }
            {
              isResult === false &&
              <React.Fragment>
                <Col span={24}>
                  <Form.Item 
                    name="notes" 
                    label="Notes to Applicant (optional)" 
                    initialValue={null}
                    // rules={[
                    //   { required: !isResult, message: 'This is a required field' },
                    // ]}
                  >
                    <ReactQuill />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Button className='btn-radius' type="danger" htmlType="submit" style={{ float: 'right' }}>Decline Offering</Button>
                </Col>
              </React.Fragment>
            }
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}