export const scopeLanguage = [
  {
    "value": "UNDERSTANDING",
    "name": "Understanding"
  },
  {
    "value": "SPEAKING",
    "name": "Speaking"
  },
  {
    "value": "WRITING",
    "name": "Writing"
  },
]