import React, { Component } from 'react';
import moment from 'moment';
import { Card, Row, Col, PageHeader, Typography, Tag, Switch, Descriptions } from 'antd';
const { Text } = Typography;

export default class HeaderDetailJob extends Component {
  render() {
    const { data, checked, handleChangeStatus } = this.props
    return (
      <React.Fragment>
        <Card>
          <Row gutter={16}>
            <Col span={24}>
              <PageHeader 
                className="site-page-header" 
                title={<Text style={{ fontWeight: 'normal' }}>{data.title}</Text>} 
                tags={
                  <React.Fragment>
                    {data.status === "DRAFT" ? <Tag>Status: Draft</Tag> : null}
                    {data.status === "REQUEST" ? <Tag color="blue">Status: Need Approval</Tag> : null}
                    {
                      data.status === "ACTIVE" ? 
                      <React.Fragment>
                        <Tag color="green">Status: Active</Tag> 
                        <Switch checked={checked} onChange={handleChangeStatus} />
                      </React.Fragment>
                      : 
                      null
                    }
                    {data.status === "INACTIVE" ? <Tag color="orange">Status: Inactive</Tag> : null}
                    {data.status === "REJECT" ? <Tag color="red">Status: Reject</Tag> : null}
                  </React.Fragment>
                }
              />
            </Col>
            <Col span={24}>
              <Descriptions layout="vertical" column={3} size="small" style={{ marginTop: 20 }}>
                <Descriptions.Item label={<Text type="secondary">Job Position</Text>}>
                  {data.jobPositionName ? data.jobPositionName : 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label={<Text type="secondary">Date Opened</Text>}>
                  {data.dateOpened ? moment(data.dateOpened, 'DD/MM/YYYY').format("DD MMMM YYYY") : 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label={<Text type="secondary">Number of Position</Text>}>
                  {data.numberOfPosition ? data.numberOfPosition : 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label={<Text type="secondary">Job Placement</Text>}>
                  {data.jobDestinationName ? data.jobDestinationName : 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label={<Text type="secondary">Close Application Date</Text>}>
                  {data.targetDate ? moment(data.targetDate, 'DD/MM/YYYY').format("DD MMMM YYYY") : 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label={<Text type="secondary">Active Applicant</Text>}>
                  N/A
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        </Card>
      </React.Fragment>
    )
  }
}
