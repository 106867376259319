import React from 'react'
import { Main } from '../components'
import { Route, Redirect } from 'react-router-dom'

export const PrivateRoute = ({ component: Component, passProps, ...res }) => {
  return (
    <React.Fragment>
      <Route 
        {...res} 
        render = {
          (props) => passProps.authed ? <Main {...res} {...props}> <Component {...res} {...props} /> </Main> : <Redirect to="/login" /> 
        } 
      />
    </React.Fragment>
  )
}

export const AuthRoute = ({ component: Component, passProps, ...res }) => {
  return (
    <Route 
      {...res}
      render = {
        (props) => !passProps.authed ? <Component {...res} {...props} /> : <Redirect to="/"/>
      }
    />
  )
}