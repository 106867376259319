const LOAD_EDIT_SERVICE           = 'LOAD_EDIT_SERVICE'
const LOAD_EDIT_SERVICE_SUCCESS   = 'LOAD_EDIT_SERVICE_SUCCESS'
const LOAD_EDIT_SERVICE_FAILED    = 'LOAD_EDIT_SERVICE_FAILED'
const UNMOUNT_EDIT_SERVICE        = 'UNMOUNT_EDIT_SERVICE'

const initialState = {
  loading: true,
  data: null,
  message: null,
}

const serviceEditReducer = (state = initialState, action) => {
  switch(action.type){
    case LOAD_EDIT_SERVICE:
      return {
        ...state,
        loading: true,
        data: null,
      }
    case LOAD_EDIT_SERVICE_SUCCESS:
      return { 
        ...state, 
        loading: false,
        data: action.payload.data,
        message: 'SUCCESS',
      }
    case LOAD_EDIT_SERVICE_FAILED:
      return { 
        ...state, 
        loading: true,
        data: null,
        message: 'FAILED',
      }
    case UNMOUNT_EDIT_SERVICE:
      return { 
        ...state, 
        loading: true,
        data: null,
      }
    default:
      return state
  }
}

export default serviceEditReducer;