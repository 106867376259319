import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Space, Typography, Tooltip, Dropdown } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
const { Text } = Typography;

export const columns = (onPriviewProfile, onPriviewNotes, showModalNotes, showModalHistory) => {
  const handleMenuClick = (e, record) => {
    if(e.key === 'profile'){
      onPriviewProfile(record.candidateId)
    }
    if(e.key === 'applicantNotes'){
      showModalNotes(record.id, record.listNotes)
    }
    if(e.key === 'applicantHistory'){
      showModalHistory(record.id)
    }
  };
  const items = [
    {
      label: 'View Profile',
      key: 'profile',
    },
    {
      label: 'View Applicant Notes',
      key: 'applicantNotes',
    },
    {
      label: 'View Applicant History',
      key: 'applicantHistory',
    }
  ]
  return [
    {
      title: 'Number',
      key: 'number',
      fixed: 'left',
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Tooltip title={record.applicantNumber ? record.applicantNumber : "N/A"}>
          <Link to="#" onClick={() => onPriviewProfile(record.candidateId)} style={{ color: '#0076de' }}>
            {record.applicantNumber ? record.applicantNumber : "N/A"}
          </Link>
        </Tooltip>
      )
    },
    {
      title: 'Name',
      key: 'name',
      fixed: 'left',
      width: 50,
      render: (record) => (
        <Space direction="vertical">
          <Link to="#" onClick={() => onPriviewProfile(record.candidateId)} style={{ color: '#0076de' }}>
            {record.name ? record.name : "N/A"}
          </Link>
          <Text>{record.age ? record.age : "N/A"}, Male</Text>
        </Space>
      )
    },
    {
      title: 'Join Date',
      key: 'joinDate',
      width: 80,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Text>
          {record.joinDate ? record.joinDate : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Length of Experience',
      key: 'lengthOfExperience',
      width: 80,
      render: (record) => (
        <Text>
          {record.lengthOfExperience ? record.lengthOfExperience : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Last Job Title',
      key: 'lastJobTitle',
      width: 80,
      render: (record) => (
        <Space direction="vertical">
          <Text>{record.lastJobPosition ? record.lastJobPosition : 'N/A'}</Text>
          <Text>{record.lastJobCompany ? record.lastJobCompany : 'N/A'}</Text>
        </Space>
      )
    },
    {
      // title: 'View',
      key: 'view',
      width: 30,
      align: 'right',
      render: (record) => (
        // <Space>
        //   <Button className="btn-radius" type="primary" disabled={!record.notes} onClick={() => onPriviewNotes(record)}>
        //     <FileDoneOutlined /> Candidate Notes
        //   </Button>
        //   {
        //     record.applicantNotes ?
        //       <Badge dot offset={[-2, 2]} style={{ width: 10, height: 10 }}>
        //         <Button 
        //           className="btn-create-default" 
        //           onClick={() => showModalNotes(record.id, record.applicantNotes)}
        //         >
        //           <FileDoneOutlined /> Applicant Notes
        //         </Button>
        //       </Badge>
        //     :
        //       <Button 
        //         className="btn-create-default" 
        //         onClick={() => showModalNotes(record.id)}
        //       >
        //         <FileDoneOutlined /> Applicant Notes
        //       </Button>
        //   }
        // </Space>
        <Dropdown 
          trigger="click" 
          placement="bottomLeft"
          menu={{
            items,
            onClick: (e) => handleMenuClick(e, record)
          }}
        >
          <Button className='btn-more'><MoreOutlined /></Button>
        </Dropdown>
      )
    },
  ]
}