export const sectionLanguage = [
  {
    "value": "LISTENING",
    "name": "Listening"
  },
  {
    "value": "READING",
    "name": "Reading"
  },
  {
    "value": "SPOKEN_INTERACTION",
    "name": "Spoken Interaction"
  },
  {
    "value": "SPOKEN_PRODUCTION",
    "name": "Spoken Production"
  },
  {
    "value": "WRITING",
    "name": "Writing" 
  }
]