import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Typography, Space, Divider, List } from 'antd';
const { Text } = Typography;

export class index extends Component {
  render() {
    const { 
      dataCandidate,
      valuesContact,
      valuesPersonal,
      valuesEducation,
      nationality,
      countryName,
      provinceName,
      cityName,
      subDistrictName,
      villageName,
      educationLevelName
    } = this.props
    
    return (
      <React.Fragment>
        {
          <React.Fragment>
            <Row>
              <Col span={24}>
                <Text type="secondary">Review your application</Text>
              </Col>
            </Row>
            <Divider />
            <Row style={{ marginBottom: 16 }}>
              <Col span={24}>
                <Text type="secondary" style={{ fontSize: 16}}>Personal Information</Text>
              </Col>
            </Row>
            <Row style={{ marginBottom: 16 }}>
              <Col span={12}>
                <Space direction="vertical">
                  <Space direction="vertical" size={0}>
                    <Text type="secondary">Email</Text>
                    <Text strong>
                      {
                        valuesContact && valuesContact.email ? 
                          valuesContact.email 
                        : 
                          '-'
                      }
                    </Text>
                  </Space>
                  <Space direction="vertical" size={0}>
                    <Text type="secondary">First Name</Text>
                    <Text strong>
                      {
                        valuesContact && valuesContact.firstName ? 
                          valuesContact.firstName 
                        : 
                          '-'
                      }
                    </Text>
                  </Space>
                  <Space direction="vertical" size={0}>
                    <Text type="secondary">Place of Birth</Text>
                    <Text strong>
                      {
                        valuesPersonal && valuesPersonal.placeOfBirth ? 
                          valuesPersonal.placeOfBirth 
                        : 
                          '-'
                      }
                    </Text>
                  </Space>
                  <Space direction="vertical" size={0}>
                    <Text type="secondary">Date of Birth</Text>
                    <Text strong>
                      {
                        valuesPersonal && valuesPersonal.birthdate ? 
                          valuesPersonal.birthdate 
                        : 
                          '-'
                      }
                    </Text>
                  </Space>
                  <Space direction="vertical" size={0}>
                    <Text type="secondary">Marital Status</Text>
                    <Text strong>
                      {
                        valuesPersonal && valuesPersonal.marital ? 
                          valuesPersonal.marital 
                        : 
                          '-'
                      }
                    </Text>
                  </Space>
                  <Space direction="vertical" size={0}>
                    <Text type="secondary">Gender</Text>
                    <Text strong>
                      {
                        valuesPersonal && valuesPersonal.gender ? 
                          valuesPersonal.gender 
                        : 
                          '-'
                      }
                    </Text>
                  </Space>
                  <Space direction="vertical" size={0}>
                    <Text type="secondary">Religion</Text>
                    <Text strong>
                      {
                        valuesPersonal && valuesPersonal.religion ? 
                          valuesPersonal.religion 
                        : 
                          '-'
                      }
                    </Text>
                  </Space>
                </Space>
              </Col>
              <Col span={12}>
                <Space direction="vertical">
                  <Space direction="vertical" size={0}>
                    <Text type="secondary">Phone Number</Text>
                    <Text strong>
                      {
                        valuesContact && valuesContact.mobilePhone ? 
                          valuesContact.mobilePhone 
                        : 
                          '-'
                      }
                    </Text>
                  </Space>
                  <Space direction="vertical" size={0}>
                    <Text type="secondary">Last Name</Text>
                    <Text strong>
                      {
                        valuesContact && valuesContact.lastName ? 
                          valuesContact.lastName 
                        : 
                          '-'
                      }
                    </Text>
                  </Space>
                  <Space direction="vertical" size={0}>
                    <Text type="secondary">Nationality</Text>
                    <Text strong>
                      {
                        valuesPersonal && valuesPersonal.nationalityId ? 
                          nationality 
                        : dataCandidate.nationality ?
                          dataCandidate.nationality
                        :
                          '-'
                      }
                    </Text>
                  </Space>
                  <Space direction="vertical" size={0}>
                    <Text type="secondary">Blood Type</Text>
                    <Text strong>
                      {
                        valuesPersonal && valuesPersonal.bloodType ? 
                          valuesPersonal.bloodType 
                        : 
                          '-'
                      }
                    </Text>
                  </Space>
                  <Space direction="vertical" size={0}>
                    <Text type="secondary">Height (cm)</Text>
                    <Text strong>
                      {
                        valuesPersonal && valuesPersonal.height ? 
                          valuesPersonal.height 
                        : 
                          '-'
                      }
                    </Text>
                  </Space>
                  <Space direction="vertical" size={0}>
                    <Text type="secondary">Weight (kg)</Text>
                    <Text strong>
                      {
                        valuesPersonal && valuesPersonal.weight ? 
                          valuesPersonal.weight 
                        : 
                          '-'
                      }
                    </Text>
                  </Space>
                  <Space direction="vertical" size={0}>
                    <Text type="secondary">ID Card Number (KTP)</Text>
                    <Text strong>
                      {
                        valuesPersonal && valuesPersonal.identificationId ? 
                          valuesPersonal.identificationId 
                        : 
                          '-'
                      }
                    </Text>
                  </Space>
                </Space>
              </Col>
            </Row>
            <Text type="secondary">ID Card (KTP) Address</Text>
            <Row style={{ marginTop: 8 }}>
              <Col span={12}>
                <Space direction="vertical">
                  <Space direction="vertical" size={0}>
                    <Text type="secondary">Country</Text>
                    <Text strong>
                      {
                        dataCandidate && dataCandidate.countryName ?
                          dataCandidate.countryName
                        : valuesPersonal && valuesPersonal.countryId ? 
                          countryName
                        :
                          '-'
                      }
                    </Text>
                  </Space>
                  <Space direction="vertical" size={0}>
                    <Text type="secondary">Province</Text>
                    <Text strong>
                      {
                        dataCandidate && dataCandidate.provinceName ?
                          dataCandidate.provinceName
                        : valuesPersonal && valuesPersonal.provinceId ? 
                          provinceName 
                        :
                          '-'
                      }
                    </Text>
                  </Space>
                  <Space direction="vertical" size={0}>
                    <Text type="secondary">City</Text>
                    <Text strong>
                      {
                        dataCandidate && dataCandidate.cityName ?
                          dataCandidate.cityName
                        : valuesPersonal && valuesPersonal.cityId ? 
                          cityName
                        :
                          '-'
                      }
                    </Text>
                  </Space>
                  {
                    valuesPersonal.countryId === 99 ?
                      <Space direction="vertical" size={0}>
                        <Text type="secondary">Sub-district</Text>
                        <Text strong>
                        {
                          dataCandidate && dataCandidate.subDistrictName ?
                            dataCandidate.subDistrictName
                          : valuesPersonal && valuesPersonal.subDistrictId ? 
                            subDistrictName
                          :
                            '-'
                        }
                        </Text>
                      </Space>
                    :
                      null
                  }
                </Space>
              </Col>
              <Col span={12}>
                <Space direction="vertical">
                  {
                    valuesPersonal.countryId === 99 ?
                      <Space direction="vertical" size={0}>
                        <Text type="secondary">Village</Text>
                        <Text strong>
                        {
                          dataCandidate && dataCandidate.villageName ?
                            dataCandidate.villageName
                          : valuesPersonal && valuesPersonal.villageId ? 
                            villageName
                          :
                            '-'
                        }
                        </Text>
                      </Space>
                    :
                      null
                  }
                  <Space direction="vertical" size={0}>
                    <Text type="secondary">Postal Code</Text>
                    <Text strong>
                      {
                        valuesPersonal && valuesPersonal.zipCode ? 
                          valuesPersonal.zipCode 
                        : 
                          '-'
                      }
                    </Text>
                  </Space>
                  <Space direction="vertical" size={0}>
                    <Text type="secondary">Street Address</Text>
                    <Text strong>
                      {
                        valuesPersonal && valuesPersonal.address ? 
                          valuesPersonal.address 
                        : 
                          '-'
                      }
                    </Text>
                  </Space>
                </Space>
              </Col>
            </Row>
            <Divider />
            <Row style={{ marginBottom: 16 }}>
              <Col span={24}>
                <Text type="secondary" style={{ fontSize: 16 }}>Education</Text>
              </Col>
            </Row>
            {
              dataCandidate ?
                <Row>
                  <Col span={24}>
                    <List
                      dataSource={dataCandidate?.listEducation}
                      renderItem={(item, i) => (
                        <List.Item key={i}>
                          <Row>
                            <Col span={24}>
                              <Space direction="vertical" size={0}>
                                <Text>{item.name}</Text>
                                {
                                  item.educationLevelName && item.fieldOfStudy ?
                                    <Text>{`${item.educationLevelName}, ${item.fieldOfStudy}`}</Text>
                                  : item.educationLevelName ?
                                    <Text>{item.educationLevelName}</Text>
                                  : item.fieldOfStudy ?
                                    <Text>{item.fieldOfStudy}</Text>
                                  :
                                    null
                                }
                                {
                                  item.gpa ?
                                    <Text>{`GPA ${item.gpa}`}</Text>
                                  :
                                    null
                                }
                                {
                                  item.startYear || item.endYear ?
                                    <Text>{`${item.startYear ? item.startYear : 'N/A'} - ${item.endYear ? item.endYear : 'N/A'}`}</Text>
                                  :
                                    null
                                }
                                {
                                  item.experience ?
                                    <Text>{item.experience}</Text>
                                  :
                                    null
                                }
                              </Space>
                            </Col>
                          </Row>
                        </List.Item>
                      )}
                    />
                  </Col>
                </Row>
              :
                <Row>
                  <Col span={12}>
                    <Space direction="vertical">
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">School/University</Text>
                        <Text className="dark-color" strong>
                          {
                            valuesEducation && valuesEducation.educationName ? 
                              valuesEducation.educationName 
                            : 
                              '-'
                          }
                        </Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">Field of Study</Text>
                        <Text className="dark-color" strong>
                          {
                            valuesEducation && valuesEducation.educationFieldOfStudy ? 
                              valuesEducation.educationFieldOfStudy 
                            : 
                              '-'
                          }
                        </Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">Start Year</Text>
                        <Text className="dark-color" strong>
                          {
                            valuesEducation && valuesEducation.educationStartYear ? 
                              valuesEducation.educationStartYear 
                            : 
                              '-'
                          }
                        </Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">Activities and Organization Experiences</Text>
                        <Text className="dark-color" strong>
                          {
                            valuesEducation && valuesEducation.educationExperience ? 
                              valuesEducation.educationExperience 
                            : 
                              '-'
                          }
                        </Text>
                      </Space>
                    </Space>
                  </Col>
                  <Col span={12}>
                    <Space direction="vertical">
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">Education Level</Text>
                        <Text className="dark-color" strong>
                          {
                            valuesEducation && valuesEducation.educationLevelId ? 
                              educationLevelName 
                            : 
                              '-'
                          }
                        </Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">Score/Grade/GPA</Text>
                        <Text className="dark-color" strong>
                          {
                            valuesEducation && valuesEducation.educationGpa ? 
                              valuesEducation.educationGpa 
                            : 
                              '-'
                          }
                        </Text>
                      </Space>
                      <Space direction="vertical" size={0}>
                        <Text className="fs-12" type="secondary">End Year (or expected)</Text>
                        <Text className="dark-color" strong>
                          {
                            valuesEducation && valuesEducation.educationEndYear ? 
                              valuesEducation.educationEndYear 
                            : 
                              '-'
                          }
                        </Text>
                      </Space>
                    </Space>
                  </Col>
                </Row>
            }
          </React.Fragment>
        }
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(index)