const LOAD_EDIT_EVENT           = 'LOAD_EDIT_EVENT'
const LOAD_EDIT_EVENT_SUCCESS   = 'LOAD_EDIT_EVENT_SUCCESS'
const LOAD_EDIT_EVENT_FAILED    = 'LOAD_EDIT_EVENT_FAILED'
const UNMOUNT_EDIT_EVENT        = 'UNMOUNT_EDIT_EVENT'

const initialState = {
  loading: true,
  data: null,
  message: null,
}

const eventEditReducer = (state = initialState, action) => {
  switch(action.type){
    case LOAD_EDIT_EVENT:
      return {
        ...state,
        loading: true,
        data: null,
      }
    case LOAD_EDIT_EVENT_SUCCESS:
      return { 
        ...state, 
        loading: false,
        data: action.payload.data,
        message: 'SUCCESS',
      }
    case LOAD_EDIT_EVENT_FAILED:
      return { 
        ...state, 
        loading: true,
        data: null,
        message: 'FAILED',
      }
    case UNMOUNT_EDIT_EVENT:
      return { 
        ...state, 
        loading: true,
        data: null,
      }
    default:
      return state
  }
}

export default eventEditReducer;