import React, { Component } from 'react';
import moment from 'moment';
import AddDocument from './Add';
import EditDocument from './Edit';
import svg from '../../../../assets/img/profile.svg';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { masterDocumentTypes, unmountMasterDocumentType } from '../../../../redux/actions/master/documentTypes/documentTypesAction';
import { Skeleton, Row, Col, Breadcrumb, PageHeader, Card, Button, Space, Avatar, Typography, Divider, Tooltip, Empty, Modal, Upload, Tag, message } from 'antd';
import { addDocument, updateDocument, deleteDocument, detailCandidate, unmountDetailCandidate, enableCandidate } from '../../../../redux/actions/candidate/candidateAction';
import { PlusOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined, UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import Menu from '../Menu';
const { Text } = Typography;
const { confirm } = Modal;
const Icon = ({ type, ...rest }) => {
  const icons = require(`@ant-design/icons`);
  const Component = icons[type];
  return <Component {...rest} />
};
class CandidateDetailDocuments extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      dataDocuments: null,
      submitLoading: false,
      visibleAdd: false,
      visibleEdit: false,
      startDate: null,
      endDate: null,
      current: false,
      isDate: false,
      documentFile: null,
      showFileEdit: false,
      selectedOption : null,
      id: null
    }
  }

  componentDidMount() {
    const { actionGetDetail, actionGetDocumentTypes, match: { params } } = this.props;
    actionGetDetail(params.id)
    actionGetDocumentTypes()
  }

  showModal = (type, item) => {
    // const { id } = this.state
    if(type === 'add'){
      return this.setState({ visibleAdd: true, documentFile: null , selectedOption: null, id: null})
    }
    if(type === 'edit'){
      return this.setState({ 
        visibleEdit: true, 
        dataDocuments: item, 
        current: item.current,
        showFileEdit: true,
        selectedOption: item.documentTypeId,
        id: item.id
      })
    }
  }

  onCancel = (type) => {
    if(type === 'add'){
      return this.setState({ visibleAdd: false, documentFile: null, })
    }
    if(type === 'edit'){
      return this.setState({ 
        visibleEdit: false,
        dataDocuments: null,
        current: false,
        showFileEdit: false
      })
    }
  }

  onChangeStartDate = (date, dateString) => {
    const { endDate } = this.state
    this.setState({ startDate: dateString })
    if(endDate <= dateString){
      this.setState({ isDate: true })
    }else{
      this.setState({ isDate: false })
    }
  }

  onChangeEndDate = (date, dateString) => {
    const { startDate } = this.state
    this.setState({ endDate: dateString })
    if(dateString <= startDate){
      this.setState({ isDate: true })
    }else{
      this.setState({ isDate: false })
    }
  }

  handleChecked = (e) => {
    this.setState({ current: e.target.checked })
    if(e.target.checked){
      this.setState({ endDate: null })
      this.formRef.current.setFieldsValue({
        endDate: null
      })
    }
  }

  handleUpload(){
    const { selectedOption } = this.state
    return {
      withCredentials: true,
      accept: selectedOption === 1 || selectedOption === 4 || selectedOption === 8 ? ".pdf" : selectedOption === 29 ? ".jpg,.jpeg" : ".png,.jpg,.jpeg,.pdf",
      beforeUpload: file => {
        const validateSize = file.size >= (selectedOption === 1 || selectedOption === 4 || selectedOption === 8 || selectedOption === 29 ? 3000000 : 500000);
        if (validateSize) {
          message.error(selectedOption === 1 || selectedOption === 4 || selectedOption === 8 || selectedOption === 29 ? 'Max file size is 3 MB!' : 'Max file size is 500 kb!');
          return false
        }
        if((selectedOption === 1 || selectedOption === 4 || selectedOption === 8) && file.type !== "application/pdf"){
          message.error('Upload Failed, PDF only!');
          return false
        }
        if(selectedOption === 29 && file.type !== "image/jpeg"){
          message.error('Upload Failed, JPG/JPEG Only!')
          return false
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.setState({ 
            documentFile: file
          })
          message.info('Upload Success!');
        }
        return false;
      },
      onChange: (e) => {
        const validateSize = e.file.size >= 3000000;
        if (validateSize) {
          this.formRef.current.setFieldsValue({ documentFile: null })
        }
        if((selectedOption === 1 || selectedOption === 4 || selectedOption === 8) && e.file.type !== "application/pdf"){
          this.formRef.current.setFieldsValue({ documentFile: null })
        }
        if(selectedOption === 29 && e.file.type !== "image/jpeg"){
          this.formRef.current.setFieldsValue({ documentFile: null })
        }
      },
    }
  }

  handleRemove = (file) => {
    this.setState({ 
      documentFile: null,
      showFileEdit: false
    })
    this.formRef.current.setFieldsValue({
      documentFile: null
    })
    message.success(`${file.name} file removed successfully`);
  }

  handleDelete = (id) => {
    const { actionDelete, actionGetDetail, match: { params } } = this.props;
    confirm({
      title: 'Remove Data',
      okText: 'Yes',
      cancelText: 'No',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to remove this data?',
      onOk() {
        return actionDelete(id, () => {
          message.success('Data deleted successfully')
          return actionGetDetail(params.id)
        }, (err) => message.error(err))
      },
    });
  }

  onSubmitAdd = () => {
    const { current, documentFile } = this.state;
    const { actionAdd, actionGetDetail, match: { params } } = this.props;
    this.setState({ submitLoading: true })
    this.formRef.current.validateFields().then(values => {
      values.documentNumber = values.documentNumber ? values.documentNumber : ""
      values.current = current
      values.issueDate = values.issueDate ? moment(values.issueDate).format('MM/YYYY') : ""
      values.expirationDate = values.expirationDate ? moment(values.expirationDate).format('MM/YYYY') : ""
      values.documentFile = documentFile
      values.remark = values.remark ? values.remark : ""
      values.candidateId = Number(params.id)
      return actionAdd(values, () => {
        return this.setState({ submitLoading: false }, () => {
          return this.setState({ visibleAdd: false }, () => {
            message.success('Data created successfully')
            return actionGetDetail(params.id)
          })
        })
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err))
      })
    })
  }

  

  onSubmitEdit = () => {
    const { current, dataDocuments, documentFile } = this.state;
    const { actionUpdate, actionGetDetail, match: { params } } = this.props;
    this.setState({ submitLoading: true })
    this.formRef.current.validateFields().then(values => {
      values.documentNumber = values.documentNumber ? values.documentNumber : ""
      values.current = current 
      values.issueDate = values.issueDate ? moment(values.issueDate).format('MM/YYYY') : dataDocuments.issueDate ? dataDocuments.issueDate : ""
      values.expirationDate = values.expirationDate ? moment(values.expirationDate).format('MM/YYYY') : dataDocuments.expirationDate ? dataDocuments.expirationDate : ""
      if(dataDocuments.id){
        values.id = dataDocuments.id
      if(documentFile){
        values.documentFile = documentFile
      }else{
        delete values.documentFile
      }
      values.candidateId = Number(params.id)
      // values.id = dataDocuments.id
      return actionUpdate(values, () => {
        return this.setState({ submitLoading: false }, () => {
          return this.setState({ visibleEdit: false, showFileEdit: false }, () => {
            message.success('Data updated successfully')
            return actionGetDetail(params.id)
          })
        })
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err))
      })
      }else{
        message.error('Data document cannot updated')
      }
    })
  }

  
  showConfirmActive = () => {
    const { actionEnable, actionGetDetail ,match: { params } } = this.props;
    confirm({
      title: 'Unable to edit profile due to candidate status is inactive. Do you want to change candidate status to active?',
      icon: <ExclamationCircleOutlined />,
      width: 700,
      onOk() {
        return actionEnable(params.id, () => {
          message.success('Successfully changed to active')
          return actionGetDetail(params.id)
        }, (err) => message.error(err))
      },
      okText: 'Change to Active',
      onCancel() {},
    });
  };

  onChangeInput = (id) => {
    this.setState({ selectedOption: id, documentFile: null })
    this.formRef.current.resetFields(['documentNumber', 'issueDate', 'expirationDate', 'documentFile'])
  }

  handlePreview = (info) => {
    const { documentFile } = this.state
    
    if(documentFile){
      const file = new Blob([documentFile], {
        type: documentFile.type,
      });
  
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank', 'fullscreen=yes');
    }else{
      window.open(info.url, '_blank', 'noreferrer, noopener')
    }
  }

  render() {
    const { visibleAdd, visibleEdit, documentFile, dataDocuments, showFileEdit, selectedOption, id } = this.state;
    const { getDetailCandidate: { loading, data } } = this.props;
    if(loading){
      return <Skeleton avatar />
    }

    const addFileList = [
      {
        uid: '-1',
        name: documentFile?.name,
        status: 'done'
      }
    ]

    const editFileList = [
      {
        uid: '-1',
        name: dataDocuments?.documentPic,
        status: 'done',
        url: dataDocuments?.documentUrl,
        thumbUrl: dataDocuments?.documentUrl,
      }
    ]

    const uploadDocumentAdd = (
      <Upload 
        {...this.handleUpload()} 
        onRemove={this.handleRemove}
        onPreview={this.handlePreview}
        listType="picture" 
        maxCount={1} 
        showUploadList={documentFile}
        fileList={documentFile ? addFileList : []}
      >
        <Button icon={<UploadOutlined />}>
          <Text className="fs-12">
            <Text className="fs-12" type="danger">* </Text>
              Upload Document
          </Text>
        </Button>
      </Upload>
    )

    const uploadDocumentEdit = (
      <Upload 
        {...this.handleUpload()} 
        onRemove={this.handleRemove}
        onPreview={this.handlePreview}
        listType="picture" 
        maxCount={1} 
        showUploadList={documentFile ? documentFile : showFileEdit}
        fileList={documentFile ? addFileList : showFileEdit ? editFileList : []}
      >
        <Button icon={<UploadOutlined />}>
          <Text className="fs-12">
            <Text className="fs-12" type="danger">* </Text>
              Upload Document
          </Text>
        </Button>
      </Upload>
    )

    const disabledDatePassport = (current) => {
      const { startDate } = this.state
      return current && current < moment(startDate)
    }

    const disabledNextMonth = (current) => {
      return current && current > moment();
    } 
    
    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Icon type={this.props.icon} />
                {
                  this.props.url.split('/')[1] === 'all-candidate' ?
                    ' All Candidates'
                  : this.props.url.split('/')[1] === 'new-candidate' ?
                    ' New Candidates'
                  : this.props.url.split('/')[1] === 'counseling-process' ?
                    ' Counseling Process'
                  : this.props.url.split('/')[1] === 'assigned-candidate' ?
                    ' Assigned Candidates'
                  :
                    ' Candidates'
                }
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to={`/${this.props.url.split('/')[1]}`}>
                  {
                    this.props.url.split('/')[1] === 'all-candidate' ?
                      'All Candidates List'
                    : this.props.url.split('/')[1] === 'new-candidate' ?
                      'New Candidates List'
                    : this.props.url.split('/')[1] === 'counseling-process' ?
                      'Counseling Process List'
                    : this.props.url.split('/')[1] === 'assigned-candidate' ?
                      'Assigned Candidates List'
                    :
                      'Candidates List'
                  }
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Candidates Detail</Breadcrumb.Item>
              <Breadcrumb.Item>
                {data.fullName}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Candidate Detail' />
          </Col>
          {/* Menu */}
          <Col span={7}>
            <Card style={{ border: 'none' }}>
              <Row gutter={[16, 24]}>
                <Col span={24}>
                  <Space direction="horizontal">
                    <Avatar size={100} src={data.profilePicUrl ? data.profilePicUrl : svg} />
                    <Space direction="vertical" size="small">
                      <Text style={{ color: '#4d4d4d', fontSize: 18 }} strong>{data.fullName}</Text>
                      <Text type="secondary">{data.candidateNumber}</Text>
                      {
                        data.enabled ? 
                          <Tag color="success">Active</Tag>
                        :
                          <Tag color="error">Inactive</Tag>
                      }
                    </Space>
                  </Space>
                </Col>
                <Col span={24}>
                  <Menu rowKey="documents" id={data.id} email={data.email}/>
                </Col>
              </Row>
            </Card>
          </Col>
          {/* Content */}
          <Col span={17}>
            <Card 
              style={{ border: 'none' }}
              title={<Text strong>Documents</Text>} 
              extra={<Button key="1" className="btn-save-primary" type="primary" icon={<PlusOutlined />} onClick={data.enabled ? () => this.showModal('add') : this.showConfirmActive}>Add</Button>} 
            >
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  {
                    data.listDocument.length > 0 ?
                      <React.Fragment>
                        {
                          data.listDocument.map((item, i) => (
                            <React.Fragment key={i}>
                              <div style={{ marginBottom: 11, display: 'flex', justifyContent: 'space-between' }}>
                                <Space direction='vertical' size={0}>
                                  <Text style={{ color: '#32ab6d', fontSize: 14 }}>
                                    {item.documentName}
                                  </Text>
                                  <Text style={{ fontSize: 14 }}>
                                    {item.documentNumber}
                                  </Text>
                                  <Text style={{ fontSize: 14 }}>
                                    {
                                      item.issueDate && item.expirationDate ?
                                        `${moment(item.issueDate, 'MM/YYYY').format('MMM YYYY')} - ${moment(item.expirationDate, 'MM/YYYY').format('MMM YYYY')}`
                                      : item.issueDate && item.current ?
                                        `${moment(item.issueDate, 'MM/YYYY').format('MMM YYYY')} - No Expiration Date`
                                      : null
                                    }
                                  </Text>
                                </Space>
                                <Space>
                                  <a href={item.documentUrl} target="_blank" rel='noreferrer'>
                                    <Tooltip title="Download Document">
                                        <DownloadOutlined style={{ fontSize: 20, cursor: 'pointer', color: '#32ab6d' }} />
                                    </Tooltip>
                                  </a>
                                  <Tooltip title="Edit">
                                    <EditOutlined style={{ fontSize: 20, cursor: 'pointer', color: '#32ab6d' }} onClick={data.enabled ? () => this.showModal('edit', item) : this.showConfirmActive} />
                                  </Tooltip>
                                  <Tooltip title="Remove">
                                    <DeleteOutlined style={{ fontSize: 20, cursor: 'pointer', color: '#d8675a' }} onClick={data.enabled ? () => this.handleDelete(item.id) : this.showConfirmActive} />
                                  </Tooltip>
                                </Space>
                              </div>
                              <Divider />
                            </React.Fragment>
                          ))
                        }
                      </React.Fragment>
                    :
                      <Empty description={<Text>No Data Documents</Text>} />
                  }
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal
          key="1"
          centered
          width={1000}
          destroyOnClose
          visible={visibleAdd}
          onCancel={() => this.onCancel('add')}
          title={<Text strong>Add Document</Text>}
          footer={[
            <React.Fragment key="1">
              <Button key="back" className="btn-save-primary" onClick={() => this.onCancel('add')}>Cancel</Button>
              <Button key="submit" className="btn-save-primary" type="p rimary" onClick={this.onSubmitAdd}>Save</Button>
            </React.Fragment>
          ]}
        >
          <AddDocument 
            {...this.props}  
            {...this.state}
            formRef={this.formRef} 
            onSubmitAdd={this.onSubmitAdd}
            handleChecked={this.handleChecked}
            onChangeEndDate={this.onChangeEndDate}
            onChangeStartDate={this.onChangeStartDate}
            handleUpload={this.handleUpload}
            uploadDocument={uploadDocumentAdd}
            onChangeInput={this.onChangeInput}
            disabledDatePassport={disabledDatePassport}
            selectedOption={selectedOption}
            disabledNextMonth={disabledNextMonth}
          />
        </Modal>
        <Modal
          key="2"
          centered
          width={1000}
          destroyOnClose
          visible={visibleEdit}
          onCancel={() => this.onCancel('edit')}
          title={<Text strong>Edit Document</Text>}
          footer={[
            <React.Fragment key="1">
              <Button key="back" className="btn-save-primary" onClick={() => this.onCancel('edit')}>Cancel</Button>
              <Button key="submit" className="btn-save-primary" type="primary" onClick={this.onSubmitEdit}>Update</Button>
            </React.Fragment>
          ]}
        >
          <EditDocument 
            {...this.props} 
            {...this.state}
            formRef={this.formRef}
            onSubmitEdit={this.onSubmitEdit} 
            handleChecked={this.handleChecked}
            onChangeEndDate={this.onChangeEndDate}
            onChangeStartDate={this.onChangeStartDate}
            handleUpload={this.handleUpload}
            uploadDocument={uploadDocumentEdit}
            onChangeInput={this.onChangeInput}
            disabledDatePassport={disabledDatePassport}
            selectedOption={selectedOption}
            disabledNextMonth={disabledNextMonth}
            id={id}
          />
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail, unmountGetDocumentTypes } = this.props;
    unmountGetDetail()
    unmountGetDocumentTypes()
  }
}

const mapStateToProps = (state) => ({
  getDetailCandidate  : state.candidate.detail,
  getDocumentTypes    : state.documentTypes.master
})

const mapDispatchToProps = {
  actionAdd               : addDocument,
  actionUpdate            : updateDocument,
  actionDelete            : deleteDocument,
  actionGetDetail         : detailCandidate,
  actionGetDocumentTypes  : masterDocumentTypes,
  actionEnable            : enableCandidate,
  unmountGetDetail        : unmountDetailCandidate,
  unmountGetDocumentTypes : unmountMasterDocumentType
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateDetailDocuments)
