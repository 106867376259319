import React, { Component } from 'react';
import moment from 'moment';
import { SearchOutlined, DownOutlined , FileExcelOutlined} from '@ant-design/icons';
import { Col, Input, Row, Select, DatePicker, Tooltip, AutoComplete, Dropdown, Menu, Typography, Button } from 'antd';
const { RangePicker } = DatePicker;
const { Link } = Typography;

export default class HeaderTable extends Component {
  handleRangePicker = (dates, dateStrings) => {
    const { onRegistered } = this.props;
    const date = {
      'startDate': dateStrings[0],
      'endDate': dateStrings[1]
    }
    return onRegistered(date)
  }

  handleChangeStatus = (e) => {
    const { onStatus } = this.props;
    return onStatus(e)
  }

  handleChangeLevel = (e) => {
    const { onChangeLevel } = this.props;
    return onChangeLevel(e)
  }

  handleChangeStatus =(e) => {
    const { onStatus }  = this.props;
    return onStatus(e)
  }

  handleChangeCategory = (e) => {
    const { onCategory } = this.props;
    return onCategory(e)
  }

  handleChangeType = (e) => {
    const { onTypeAccount } = this.props;
    return onTypeAccount(e)
  }

  handleChangeSearch = (value) => {
    const { onSearch } = this.props;
    return onSearch(value)
  }

  handleChangeSearch = (value) => {
    const { onSearch } = this.props;
    return onSearch(value)
  }

  handleExportData = (e) => {
    const { modalExportData } = this.props;
    return modalExportData(e)
  }

  handleWarning = (e) => {
    const { warning } = this.props;
    return warning(e)
  }
  

  render() {
    const { fase1, fase2, tooltipDate, tooltipStatus, placeholder } = this.props;
    const menu = (
      <Menu>
        <Menu.Item key="0">Tracer Study Data</Menu.Item>
      </Menu>
    )
    return (
      <React.Fragment>
        <Row gutter={8} align="middle">
          <Col span={5}>
            <Tooltip title={tooltipDate}>
              <RangePicker
                onChange={this.handleRangePicker}
                format={'DD-MM-YYYY'}
                style={{ width: '100%' }}
                ranges={{
                  Today: [moment(), moment()],
                  'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                  'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                  'This Week': [moment().startOf('week'), moment().endOf('week')],
                  'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                  'This Month': [moment().startOf('month'), moment().endOf('month')],
                  'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                }}
              />
            </Tooltip>
          </Col>
          {
            fase2 &&
            <Col span={4}>
              <Tooltip title="Filter by Acount Type">
                <Select
                  defaultValue={fase2.value[0].value}
                  onChange={this.handleChangeType}
                  placeholder={fase2.key}
                  style={{ width: '100%' }}
                >
                  {fase2.value.map((res, i) => <Select.Option key={i.toString()} value={res.value}>{res.name}</Select.Option>)}
                </Select>
              </Tooltip>
            </Col>
          }
          {
            !this.props.transaction && !this.props.candidatePathway && 
            <Col span={4}>
              <Tooltip title={tooltipStatus}>
                <Select
                  defaultValue={fase1.value[0].value}
                  onChange={this.handleChangeStatus}
                  placeholder={fase1.key}
                  style={{ width: '100%' }}
                >
                  {fase1.value.map((res, i) => <Select.Option key={i.toString()} value={res.value}>{res.name}</Select.Option>)}
                </Select>
              </Tooltip>
            </Col>
          }
          {
            this.props.event &&
            <Col span={4}>
              <Tooltip title={this.props.tooltipCategory}>
                <Select
                  defaultValue={fase1.valueCategory[0].value}
                  onChange={this.handleChangeCategory}
                  placeholder={fase1.key}
                  style={{ width: '100%' }}
                >
                  {fase1.valueCategory.map((res, i) => <Select.Option key={i.toString()} value={res.value}>{res.name}</Select.Option>)}
                </Select>
              </Tooltip>
            </Col>
          }
          {/* {
            this.props.transaction &&
            <Col span={4}>
              <Tooltip title={this.props.tooltipXpertType}>
                <Select
                  defaultValue={fase1.valueXpertType[0].value}
                  onChange={this.handleChangeXpertType}
                  placeholder={fase1.key}
                  style={{ width: '100%' }}
                >
                  {fase1.valueXpertType.map((res, i) => <Select.Option key={i.toString()} value={res.value}>{res.name}</Select.Option>)}
                </Select>
              </Tooltip>
            </Col>
          } */}
          {
            this.props.candidatePathway ?
              <Col span={4}>
                <Tooltip title='Filter by level'>
                  <Select
                    defaultValue={fase1.value[0].value}
                    onChange={this.handleChangeLevel}
                    placeholder={fase1.key}
                    style={{ width: '100%' }}
                  >
                    {fase1.value.map((res, i) => <Select.Option key={i.toString()} value={res.value}>{res.name}</Select.Option>)}
                  </Select>
                </Tooltip>
              </Col>
            : null
          }
          <Col span={this.props.transaction ? 19 : 11}>
            <AutoComplete 
              onSearch={this.handleChangeSearch} 
              style={{ width: '100%' }} 
              allowClear
            >
              <Input prefix={<SearchOutlined />} placeholder={placeholder}  />
            </AutoComplete>
          </Col>
          {
            this.props.adminBinawan ?
            <Col span={4} style={{ textAlign: 'right' }}>
              <Dropdown overlay={menu} trigger={['click']}>
                <Link onClick={e => e.preventDefault()}>
                  Export <DownOutlined />
                </Link>
              </Dropdown>
            </Col>
            : null
          }
          {
            this.props.candidatePathway ?
              // selectedRowKeys.length > 0 ?
                <Col span={4} style={{ textAlign: 'right' }}>
                  <Button 
                    className="btn-create-primary" 
                    type="primary" 
                    ghost
                    // onClick={selectedRowKeys.length > 0 ? () => this.handleExportData(null) : this.handleWarning}
                    onClick={() => this.handleExportData(null)}
                  >
                    Export Data <FileExcelOutlined />
                  </Button>
                </Col>
              // : null
            : null
          }
        </Row>
      </React.Fragment>
    )
  }
}
