import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading } from '../../../../components';
import { CalendarOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Card, Row, Col, PageHeader, Form, Input, Select, Upload, Button, Space, Breadcrumb, Image, message, DatePicker, Typography, InputNumber, Checkbox, Modal} from 'antd';
import ReactQuill from 'react-quill';
import update from 'react-addons-update';
import { masterOrganizer, unmountMasterOrganizer } from '../../../../redux/actions/organizer/organizerAction';
import { masterCategory, unmountMasterCategory } from '../../../../redux/actions/category/categoryAction';
import { createService } from '../../../../redux/actions/service/serviceAction';
const { Dragger } = Upload;
const { Text } = Typography;
const { confirm } = Modal

class CreateService extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
    
    this.state = {
      saveLoadingl: false,
      submitLoading: false,
      editAvatar: false,
      priceType: 'FIX_PRICE',
      audience: false,
      isDiscount: false,
      startDate: moment(),
      avatar: [],
      statusSubmit: 'INACTIVE',
      valueForm: null
    }
  }

  componentDidMount()  {
    const { 
      actionGetCategory,
      actionGetOrganizer
    } = this.props;
    actionGetCategory()
    actionGetOrganizer()
  }

  onChangeStartDate = (current) => {
    this.formRef.current.setFieldsValue({
      endDate: null,
    });
    this.setState({ startDate: current })
  }

  disabledStartDate = (current) => {
    let today = moment()
    return !current || current.isBefore(today)
  }

  disabledEndDate = (current) => {
    let today = moment()
    return !current || current.isBefore(today)
  }

  handleChangeFixPrice = (value) => {
    this.setState({ priceType: value })
    this.formRef.current.setFieldsValue({
      price: null,
      maxPrice: null,
      discountedPrice: null,
      maxDiscountedPrice: null
    });
  }

  handleCheckedAudience = (e) => {
    this.setState({ audience: e.target.checked })
    if(e.target.checked){
      this.formRef.current.setFieldsValue({
        audience: null
      })
    }
  }

  handleCheckedDiscount = (e) => {
    this.setState({ isDiscount: e.target.checked })
    this.formRef.current.setFieldsValue({
      discountedPrice: null,
      maxDiscountedPrice: null
    })
  }

  handleChange = (value, fileUrl) => {
    const values = {
      file: value,
      isCover: this.state.avatar.length === 0 ? true : false,
      fileUrl: fileUrl
    }
    return this.setState({
      avatar: update(this.state.avatar, {
        $push: [values]
      })
    })
  }

  handleUpload(){
    return {
      multiple: true,
      showUploadList: false,
      withCredentials: true,
      accept:".png,.jpg,.jpeg",
      beforeUpload: file => {
        const validateSize = file.size >= 500000;
        if (validateSize) {
          message.error('Max file size is 500 KB!');
          return false
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.handleChange(file, e.target.result)
          this.setState({ 
            editAvatar: true,
          })
        }
        return false;
      },
    }
  }

  onDeletePhoto = (index, value) => {
    this.setState({
      avatar: update(this.state.avatar, {
        $splice: [[index, 1]]
      })
    }, () => {
      if(value.isCover === true){
        var arr = [];
        this.state.avatar.map((res, i) => {
          if(index === 0){
            res.isCover = i === 0 ? true : false
          }else{
            res.isCover = i === index-1 ? true : false
          }
          return arr.push(res)
        })
        return this.setState({
          avatar: arr
        })
      }
    })
  }

  handleClick = (type) => {
    this.setState({ statusSubmit: type })
  }

  onClickPhoto = (e) => {
    var arr = [];
    this.state.avatar.map((res, i) => {
      res.isCover = e === i ? true : false
      return arr.push(res)
    })
    return this.setState({
      avatar: arr
    })
  }

  onSave = (values) => {
    const { avatar, statusSubmit, audience } = this.state;
    const { actionCreate, history } = this.props;
    values = values.getFieldsValue();
    values.startDate = values.startDate ? moment(values.startDate).format('DD/MM/YYYY') : null
    values.endDate = values.endDate ? moment(values.endDate).format('DD/MM/YYYY') : null
    values.startTime = values.startTime ? moment(values.startTime).format('HH:mm:ss') : null
    values.endTime = values.endTime ? moment(values.endTime).format('HH:mm:ss') : null
    // values.slug = values.title
    values.status = 'DRAFT'
    values.isAudience = audience


    avatar.push(values)
    if(statusSubmit === 'INACTIVE'){
      return actionCreate(avatar, (res) => {
        this.setState({ saveLoading: false }, () => {
          history.push(`/service`)
          message.success('Data created successfully')
        })
      }, (err) => {
        this.setState({ saveLoading: false }, () => message.error(err))
      })
    }
  }

  onFinish = (values) => {
    const { avatar, statusSubmit, audience } = this.state;
    const { actionCreate, history } = this.props;
    values.startDate = values.startDate ? moment(values.startDate).format('DD/MM/YYYY') : null
    values.endDate = values.endDate ? moment(values.endDate).format('DD/MM/YYYY') : null
    values.startTime = moment(values.startTime).format('HH:mm:ss')
    values.endTime = moment(values.endTime).format('HH:mm:ss')
    // values.slug = values.title
    values.status = statusSubmit
    values.isAudience = audience

    if(avatar.length < 1){
      return message.error('Please Upload Slider Image at least one')
    }

    avatar.push(values)
    if(statusSubmit === 'INACTIVE'){
      this.setState({ submitLoading: true })
      
      return actionCreate(avatar, (res) => {
        this.setState({ submitLoading: false }, () => {
          confirm({
            title: `This Service status 'Inactive'`,
            icon: <ExclamationCircleOutlined />,
            content: `If you want to change it to active you must create at least one ticket. You can click "Okay" and it will be direct to form create ticket.`,
            onOk() {
              history.push(`/service/detail/${res.data.id}`)
            },
            onCancel() {
              history.push(`/service`)
            },
            width: 500
          });
          // Modal.info({
          //   title: `Service status 'Inactive'`,
          //   content: `If you want to change it to active you must create at least one ticket. You can click "Okay" and it will be direct to form create ticket.`,
          //   onOk() {
          //     history.push(`/service/detail/${res.data.id}`)
          //   },
          //   width: 500
          // })
          // message.success('Data created successfully')
        })
      }, (err) => {
        this.setState({ submitLoading: false }, () => message.error(err))
      })
    }else{
      this.setState({ saveLoading: true })
      
      return actionCreate(avatar, (res) => {
        this.setState({ saveLoading: false }, () => {
          // Modal.info({
          //   title: `This Service status 'Inactive'`,
          //   content: `If want to change it to active you must create at least one ticket. You can click "Okay" and it will be direct to form create ticket.`,
          //   onOk() {
          //     history.push(`/service/detail/${res.data.id}`)
          //   },
          //   width: 500
          // })
          history.push(`/service`)
          message.success('Data created successfully')
        })
      }, (err) => {
        this.setState({ saveLoading: false }, () => message.error(err))
      })
    }
  }

  handleChangeValue = (value) => {
    if (value.title && value.categoryId && value.serviceType ) {
      this.setState({ valueForm: value })
    }
  }

  render() {
    const { saveLoading, submitLoading, audience, avatar, valueForm } = this.state;
    const { getCategory, getOrganizer } = this.props;

    if(getCategory.loading || getOrganizer.loading){
      return <Loading />
    }
    

    return (
      <React.Fragment>
        <Form layout="vertical" ref={this.formRef} onFinish={this.onFinish} scrollToFirstError={true}
          onValuesChange={(_, value) => {
            this.handleChangeValue(value)
          }}>
          <Row style={{ position: 'sticky', zIndex: 1, width: '100%', top: 62, right: 0, marginTop: -20, marginBottom: 8, paddingBottom: 12, paddingTop: 16, backgroundColor: '#f2f2f2' }}>
            {/* Breadcrumb */}
            <Col span={24}>
              <Breadcrumb>
                <Breadcrumb.Item><CalendarOutlined /> Service</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link className="link" to="/service">Service List</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Create</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            {/* Page Header */}
            <Col span={24}>
              <PageHeader 
                title="Create an Service" 
                className="site-page-header"
                extra={[
                  <Button key="3" className="btn-radius" style={{ width: 'auto' }} type="default" onClick={() => window.history.back()}>Cancel</Button>,
                  <Button key="2" className="btn-radius" type='primary' loading={saveLoading} onClick={() => this.onSave(this.formRef.current)} disabled={valueForm !== null ? false : true } ghost>Save as Draft</Button>,
                  <Button key="1" style={{ width: 'auto' }} className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading} >Submit</Button>,
                ]}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            {/* Form */}
            <Col span={24}>
              <Card>
                <Row gutter={16}>
                  <Col span={24} style={{ marginBottom: 30 }}>
                    <Typography.Text style={{ fontSize: 18 }}>General Information</Typography.Text>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Title" 
                      name="title"
                      validateFirst
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 3, message: 'Name must be at least 3 characters long' },
                        // { max: 50, message: 'Title Name is too long. Maximum is 50 characters' },
                        // { pattern: new RegExp("^[a-zA-Z ]+$"), message: 'Special Characters and Number are not allowed' },
                      ]}
                      >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Type" 
                      name="serviceType" 
                      initialValue={undefined}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        <Select.Option value="SERVICE">Service</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Category" 
                      name="categoryId" 
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          getCategory?.data.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Form.Item 
                      label="Start Date" 
                      name="startDate"
                      initialValue={null}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <DatePicker 
                        format={'DD/MM/YYYY'}
                        style={{ width: '100%' }} 
                        disabledDate={this.disabledStartDate} 
                        onChange={this.onChangeStartDate}
                        allowClear={false}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Form.Item 
                      label="End Date" 
                      name="endDate"
                      initialValue={null}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <DatePicker 
                        format={'DD/MM/YYYY'}
                        style={{ width: '100%' }} 
                        disabledDate={this.disabledEndDate} 
                        allowClear={false}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Organizer" 
                      name="organizerId" 
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          getOrganizer?.data.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Form.Item 
                      label="Target Number of Audiences" 
                      name="audience" 
                      validateFirst
                      rules={[
                        { required: !audience, message: 'This is a required field' },
                        { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers' },  
                        // { max: 3, message: '3 digits only' },
                      ]}
                    >
                      <InputNumber maxLength={4} disabled={audience}/>
                    </Form.Item>
                  </Col>

                  <Col span={6} style={{ marginTop: 30 }}>
                    <Form.Item>
                      <Checkbox onChange={(e) => this.handleCheckedAudience(e)}>Unlimited Audience</Checkbox>
                    </Form.Item>
                  </Col>
{/* 
                  <Col span={12} >
                    <Form.Item 
                      label="Price Type" 
                      name="priceType" 
                      initialValue='FIX_PRICE'
                      rules={[
                        { required: priceType, message: 'This is a required field' }
                      ]}
                    >
                      <Radio.Group onChange={(e) => this.handleChangeFixPrice(e.target.value)}>
                        <Radio value='FIX_PRICE'>Fix Price</Radio>
                        <Radio value='RANGE_PRICE'>Range Price</Radio>
                        <Radio value='FREE'>This is free event</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col> */}
                  
                  {/* <Col span={12} style={{ marginTop: 30 }}>
                    <Form.Item>
                      <Checkbox onChange={(e) => this.handleCheckedDiscount(e)}>This ticket has discount</Checkbox>
                    </Form.Item>
                  </Col> */}
                 
                  {/* {
                    priceType === 'FIX_PRICE' || priceType === 'FREE' ?
                    <Col span={12}>
                      <Form.Item 
                        label="Displayed Price" 
                        name="price" 
                        validateFirst
                        initialValue={null}
                        rules={[
                          { required: priceType === 'FIX_PRICE', message: 'This is a required field' },
                        ]}
                      >
                        <InputNumber
                          disabled={priceType === 'FREE'}
                          maxLength={10}
                          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        />
                      </Form.Item>
                    </Col>
                    :
                    <React.Fragment>
                      <Col span={6}>
                        <Form.Item 
                          label="Min Price" 
                          name="price" 
                          initialValue={null}
                          rules={[
                            { required: true, message: 'This is a required field' },
                          ]}
                        >
                          <InputNumber
                            maxLength={12}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={6}>
                        <Form.Item 
                          label="Max Price" 
                          name="maxPrice" 
                          initialValue={null}
                          rules={[
                            { required: true, message: 'This is a required field' },
                          ]}
                        >
                          <InputNumber
                            maxLength={12}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                          />
                        </Form.Item>
                      </Col>
                    </React.Fragment>
                  } */}

                  {/* {
                    priceType === 'FIX_PRICE' || priceType === 'FREE' ?
                    <Col span={12}>
                      <Form.Item 
                        label="Discounted Price" 
                        name="discountedPrice" 
                        validateFirst
                        initialValue={null}
                        rules={[
                          { required: priceType === 'FIX_PRICE' && isDiscount, message: 'This is a required field' },
                        ]}
                      >
                        <InputNumber
                          disabled={priceType === 'FREE'  || !isDiscount}
                          maxLength={10}
                          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        />
                      </Form.Item>
                    </Col>
                    :
                    <React.Fragment>
                      <Col span={6}>
                        <Form.Item 
                          label="Min Discounted Price" 
                          name="discountedPrice" 
                          initialValue={null}
                          rules={[
                            { required: isDiscount, message: 'This is a required field' },
                          ]}
                        >
                          <InputNumber
                            disabled={!isDiscount}
                            maxLength={12}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={6}>
                        <Form.Item 
                          label="Max Discounted Price" 
                          name="maxDiscountedPrice" 
                          initialValue={null}
                          rules={[
                            { required: isDiscount, message: 'This is a required field' },
                          ]}
                        >
                          <InputNumber
                            disabled={!isDiscount}
                            maxLength={12}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                          />
                        </Form.Item>
                      </Col>
                    </React.Fragment>
                  } */}

                  <Col span={24} style={{ marginBottom: 30 }}>
                    <Typography.Text style={{ fontSize: 18 }}>Service Details</Typography.Text>
                  </Col>

                  <Col span={24}>
                    <Form.Item 
                      label="Service Description" 
                      name="xpertDetail" 
                      initialValue={null}
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { max: 8000, message: '8,000 characters only' },
                      ]}
                    >
                      <ReactQuill placeholder="Service Description" />
                    </Form.Item>
                  </Col>

                  <Col span={24} style={{ marginBottom: 30 }}>
                    <Typography.Text style={{ fontSize: 18 }}>Slider Images</Typography.Text>
                  </Col>

                  <Col span={24}>
                    <Card style={{ border: 'none' }}>
                      <Row gutter={[16, 24]}>
                        <Col span={24}>
                          <Dragger 
                            {...this.handleUpload()} 
                            disabled={avatar.length < 5 ? false : true}
                            style={{ 
                              backgroundColor: '#fafafa', 
                              boxShadow: ' 0 2px 4px 0 rgba(198, 198, 198, 0.5)',
                              border: 'solid 1px #f3f3f3',
                              padding: 25 
                            }}
                          >
                            <Space direction="vertical">
                              <Button 
                                disabled={avatar.length < 5 ? false : true}
                                style={{ 
                                  backgroundColor: '#c7e9d8',
                                  width: '499px',
                                  height: '38px',
                                  margin: '0px 0px 19px',
                                  borderRadius: '6px',
                                  border: 'none',
                                  boxShadow: '0 2px 4px 0 rgba(198, 198, 198, 0.5)',
                                  color: '#32ab6d',
                                  fontSize: '16px'
                                }}
                              >
                                Select Photos
                              </Button>
                              <Text type="secondary" style={{ fontSize: '16px' }}>
                                or drag photos here (ideally width= 1200px, height= 500px)
                                up to 5 photos
                              </Text>
                            </Space>
                          </Dragger>
                        </Col>
                        <Col span={24}>
                          <div className="list-slider-photo">
                            {
                              avatar.map((item, i) => (
                                <div key={i} style={{ position: 'relative' }}>
                                  <Card className={item.isCover ? 'cover-photo' : 'not-cover-photo'} onClick={() => this.onClickPhoto(i)}>
                                    {
                                      item.isCover ?
                                      <div style={{ width: '100%', marginBottom: 15 }}>
                                        <Text style={{ color: ' #706f6f', fontSize: 18 }}>COVER</Text>
                                      </div>
                                      :
                                      null
                                    }
                                    <Row gutter={[16, 24]}>
                                      <Col span={24}>
                                        <div>
                                          <Image src={item.fileUrl} preview={false} width={300} /> 
                                        </div>
                                      </Col>
                                    </Row>
                                  </Card>
                                  <div style={{ position: 'absolute', top: 0, right: 15 }}>
                                    <Button 
                                      shape="circle" 
                                      type="danger"
                                      icon={<DeleteOutlined style={{ color: 'white' }} />} 
                                      onClick={() => this.onDeletePhoto(i, item)}
                                    />
                                  </div>
                                </div>
                              ))
                            }
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetOrganizer, unmountGetCategory } = this.props;
    unmountGetOrganizer()
    unmountGetCategory()
  }
}

const mapStateToProps = (state) => ({
  getCategory       : state.category.master,
  getOrganizer      : state.organizer.master
})

const mapDispatchToProps = {
  actionCreate            : createService,
  actionGetOrganizer      : masterOrganizer,
  actionGetCategory       : masterCategory,
  unmountGetOrganizer     : unmountMasterOrganizer,
  unmountGetCategory      : unmountMasterCategory,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateService)
