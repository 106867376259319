import { API } from '../../../config';
import { errorHandler } from '../auth/errorAction';

export const unmountListTraining = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_TRAINING'})
}

export const unmountDetailTraining = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_TRAINING'})
}

export const unmountEditTraining = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_EDIT_TRAINING'})
}

// export const unmountMasterTRAINING = () => (dispatch) => {
//   return dispatch({type: 'UNMOUNT_DATA_MASTER_TRAINING'})
// }

export const listTraining = (meta) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_TRAINING' })
  return API.GET('/training/list', meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_TRAINING_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_TRAINING_FAILED' }), 
    ))
  })
}

export const detailTraining = (id, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DETAIL_TRAINING' })
  const params = {
    'id': id
  }
  API.GET('/training/getDetailById', params).then((response) => {
    dispatch({ 
      type: 'LOAD_DETAIL_TRAINING_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DETAIL_TRAINING_FAILED' }), 
      ))
    )
  })
}

export const editTraining = (id, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_EDIT_TRAINING' })
  const params = {
    'id': id
  }
  API.GET('/training/getById', params).then((response) => {
    dispatch({ 
      type: 'LOAD_EDIT_TRAINING_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_EDIT_TRAINING_FAILED' }), 
      ))
    )
  })
}

// export const masterTraining = (successCB, failedCB) => async (dispatch) => {
//   await dispatch({ type: 'LOAD_DATA_MASTER_TRAINING' })
//   return API.GET('/employers_type/index').then((response) => {
//     dispatch({
//       type: 'LOAD_DATA_MASTER_TRAINING_SUCCESS', 
//       payload: {
//         data: response.data,
//         pagination: {
//           page: null,
//           total: null,
//           perpage: null
//         }
//     }})
//     return successCB && successCB(response.data)
//   }).catch((err) => {
//     failedCB && failedCB()
//     return dispatch(errorHandler(
//       err, 
//       dispatch({ type: 'LOAD_DATA_MASTER_TRAINING_FAILED' }), 
//     ))
//   })
// }

export const createTraining = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA_ARRAY('/training/add', value, 'createTraining').then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updateTraining = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA_ARRAY('/training/update', value, 'createTraining').then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const enableTraining = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/training/enable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const disableTraining = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/training/disable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const checkTicketTraining = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/xpert/ticket/checkTicket', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}