import { API } from '../../../../config';
import { errorHandler } from '../../auth/errorAction';

export const unmountListInstitutions = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_INSTITUTIONS'})
}

export const unmountDetailInstitutions = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_INSTITUTIONS'})
}

export const unmountMasterInstitutions = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_INSTITUTIONS'})
}

export const listInstitutions = (meta) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_INSTITUTIONS' })
  return API.GET('/institution/list', meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_INSTITUTIONS_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_INSTITUTIONS_FAILED' }), 
    ))
  })
}

export const detailInstitution = (id, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DETAIL_INSTITUTIONS' })
  const params = {
    'id': id
  }
  API.GET('/institution/getById', params).then((response) => {
    dispatch({ 
      type: 'LOAD_DETAIL_INSTITUTIONS_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DETAIL_INSTITUTIONS_FAILED' }), 
      ))
    )
  })
}

export const masterInstitutions = (successCB, failedCB) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_INSTITUTIONS' })
  return API.GET('/institution/index').then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_INSTITUTIONS_SUCCESS', 
      payload: { data: response.data }
    })
    return successCB && successCB(response.data)
  }).catch((err) => {
    failedCB && failedCB()
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_MASTER_INSTITUTIONS_FAILED' }), 
    ))
  })
}

export const createInstitution = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/institution/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const editInstitution = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/institution/update', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const enableInstitution = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/institution/enable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const disableInstitution = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/institution/disable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}