import React from 'react'
import { Row, Col, Typography, Space, Descriptions, Divider } from 'antd'

const { Text } = Typography

export default function Skill(props) {
  const { data } = props
  return (
    <Row gutter={16}>
      <Col span={24}>
        <Descriptions title="Skills" />
        {
          data.listSkill.length > 0 ?
          <React.Fragment>
            <Descriptions layout="vertical" size="small" column={3}>
              {
                data.listSkill.map((item, i) => (
                <Descriptions.Item key={i}>
                  <Space direction="vertical" size="small">
                    <Text>{item.name}</Text>
                    {
                      item.skillLevel ?
                      <Text style={{ color: '#32ab6d', fontSize: 14 }}>
                        {item.skillLevel === 'BASIC' && 'Basic'}
                        {item.skillLevel === 'INTERMEDIATE' && 'Intermediate'}
                        {item.skillLevel === 'ADVANCE' && 'Advance'}
                        {item.skillLevel === 'EXPERT' && 'Expert'}
                      </Text>
                      :
                      'N/A'
                    }
                  </Space>
                </Descriptions.Item>
                ))
              }
            </Descriptions>
            <Divider />
          </React.Fragment>
          :
          <React.Fragment>
            <Text>N/A</Text>
            <Divider />
          </React.Fragment>
        }
      </Col>
    </Row>
  )
}
