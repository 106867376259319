import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { columns } from './columns';
import { connect } from 'react-redux';
import { FilterMasterData } from '../../../../components';
import { Row, Col, Breadcrumb, PageHeader, Table, Pagination, message } from 'antd';
import { disableSubDistrict, enableSubDistrict, listSubDistricts, unmountListSubDistricts } from '../../../../redux/actions/master/subDistricts/subDistrictsAction';
import { DatabaseOutlined } from '@ant-design/icons';
import Menu from '../Menu';

class MasterSubDistricts extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      sortedInfo: null,
      meta: {
        page: 1,
        perpage: 10,
        field: null,
        sort: null,
        search: null,
        flagStatus: null,
      }
    }
    this.onSearch = debounce(this.onSearch.bind(this), 900)
  }

  componentDidMount() {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    return actionGetData(meta)
  }

  onSearch = value => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.search = value === '' ? null : value
    return actionGetData(meta)
  }

  onStatus = value => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.flagStatus = value
    return actionGetData(meta)
  }

  pagination = (page, perpage) => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = page
    meta.perpage = perpage
    return actionGetData(meta)
  }

  handleEnable = (value) => {
    const { meta } = this.state;
    const { actionEnable, actionGetData } = this.props;
    return actionEnable(value, () => {
      message.success('Successfully changed to active')
      return actionGetData(meta)
    }, (err) => message.error(err))
  }

  handleDisable = (value) => {
    const { meta } = this.state;
    const { actionDisable, actionGetData } = this.props;
    return actionDisable(value, () => {
      message.success('Successfully changed to inactive')
      return actionGetData(meta)
    }, (err) => message.error(err))
  }

  onClickEdit = (id) => {
    this.props.history.push(`/master/sub-districts/edit/${id}`)
  }

  render() {
    const { getData: { loading, data, pagination } } = this.props;

    const defaultStatusFilter = [
      { value: null, name: 'All' },
      { value: true, name: 'Active' },
      { value: false, name: 'Inactive' },
    ]

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><DatabaseOutlined /> Master Data</Breadcrumb.Item>
              <Breadcrumb.Item>Master Data List Sub Districts</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          { /* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Master Data List Sub Districts' />
          </Col>
          { /* Filter */}
          <Col span={24}>
            <FilterMasterData 
              name="New Sub-District" 
              link="/master/sub-districts/create"
              placeholder="Search by Name"
              onSearch={this.onSearch} 
              onStatus={this.onStatus}
              fase2={{ key: 'status', value: defaultStatusFilter}}
            />
          </Col>
          { /* Menu Side Bar */}
          <Col span={6}>
            <Menu rowKey="subDistricts" />
          </Col>
          { /* Table */}
          <Col span={18}>
            <Row gutter={[16, 24]}>
              <Col span={24}>
                <Table 
                  size="small"
                  loading={loading} 
                  dataSource={data}
                  pagination={false} 
                  rowKey={(i) => i.id} 
                  columns={columns(this.onClickEdit, this.handleEnable, this.handleDisable)}
                />
              </Col>
              {/* Pagination */}
              <Col span={24}>
                <Pagination
                  total={pagination.total}
                  onChange={this.pagination}
                  current={pagination.page}
                  defaultPageSize={pagination.perpage}
                  showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total} Data`}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetData } = this.props;
    return unmountGetData()
  }
}

const mapStateToProps = (state) => ({
  getData: state.subDistricts.list
})

const mapDispatchToProps = {
  actionGetData     : listSubDistricts,
  actionEnable      : enableSubDistrict,
  actionDisable     : disableSubDistrict,
  unmountGetData    : unmountListSubDistricts
}

export default connect(mapStateToProps, mapDispatchToProps)(MasterSubDistricts)

