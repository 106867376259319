import { API } from '../../../../config';
import { errorHandler } from '../../auth/errorAction';

export const unmountListJobPositions = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_JOB_POSITIONS'})
}

export const unmountDetailJobPositions = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_JOB_POSITIONS'})
}

export const unmountMasterJobPositions = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_JOB_POSITIONS'})
}

export const listJobPositions = (meta) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_JOB_POSITIONS' })
  return API.GET('/job-position/list', meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_JOB_POSITIONS_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_JOB_POSITIONS_FAILED' }), 
    ))
  })
}

export const detailJobPosition = (id, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DETAIL_JOB_POSITIONS' })
  const params = {
    'id': id
  }
  API.GET('/job-position/getById', params).then((response) => {
    dispatch({ 
      type: 'LOAD_DETAIL_JOB_POSITIONS_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DETAIL_JOB_POSITIONS_FAILED' }), 
      ))
    )
  })
}

export const masterJobPositions = (successCB, failedCB) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_JOB_POSITIONS' })
  return API.GET('/job-position/index').then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_JOB_POSITIONS_SUCCESS', 
      payload: { data: response.data }
    })
    return successCB && successCB(response.data)
  }).catch((err) => {
    failedCB && failedCB()
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_MASTER_JOB_POSITIONS_FAILED' }), 
    ))
  })
}

export const positionByIndustry = (id, successCB, failedCB) => async (dispatch) => {
  const params = { 'jobIndustry': id }
  await dispatch({ type: 'LOAD_DATA_JOB_POSITION_BY_INDUSTRY' })
  return API.GET('/job-position/getByJobIndustry', params).then((response) => {
    dispatch({
      type: 'LOAD_DATA_JOB_POSITION_BY_INDUSTRY_SUCCESS', 
      payload: { data: response.data }
    })
    return successCB && successCB(response.data)
  }).catch((err) => {
    failedCB && failedCB()
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_JOB_POSITION_BY_INDUSTRY_FAILED' }), 
    ))
  })
}

export const positionByIndustryGroup = (id, successCB, failedCB) => async (dispatch) => {
  const params = { 'jobIndustry': id }
  return API.GET('/job-position/getByJobIndustry/group', params).then((response) => {
    return successCB && successCB(response.data)
  }).catch((err) => {
    failedCB && failedCB()
    return dispatch(errorHandler(
      err, 
    ))
  })
}

export const createJobPosition = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/job-position/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const editJobPosition = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/job-position/update', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const enableJobPosition = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/job-position/enable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const disableJobPosition = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/job-position/disable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}