import React, { Component } from 'react';
import { Result, Button } from 'antd';

export default class DashboardAdminRecruitment extends Component {
  render() {
    return (
      <React.Fragment>
        <Result
          status="404"
          title="Coming Soon"
          subTitle="Dashboard Admin Recruitment"
          extra={<Button type="primary" href="/">Back Dashboard</Button>}
        />
      </React.Fragment>
    )
  }
}
