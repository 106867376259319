import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Select, Tooltip, AutoComplete } from 'antd';

class FilterMasterData extends Component {

  handleChangeLocation = (e) => {
    const { onLocation } = this.props;
    return onLocation(e)
  }

  handleChangeDisplay = (e) => {
    const { onDisplay } = this.props;
    return onDisplay(e)
  }

  handleChangeStatus = (e) => {
    const { onStatus } = this.props;
    return onStatus(e)
  }

  handleChangeSearch = (value) => {
    const { onSearch } = this.props;
    return onSearch(value)
  }

  handleChangeScope = (e) => {
    const { onScope } = this.props;
    return onScope(e)
  }

  handleChangeSection = (e) => {
    const { onSection } = this.props;
    return onSection(e)
  }

  handleChangeCode = (e) => {
    const { onCode } = this.props;
    return onCode(e)
  }

  handleChangeBank = (e) => {
    const { onBank } = this.props;
    return onBank(e)
  }


  render() {
    const { name, placeholder, link, fase1, fase2, fase3, fase4, fase5, fase6, fase7, isLanguageSkill } = this.props;
    return (
      <React.Fragment>
        <Row gutter={16} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {
            !isLanguageSkill &&
            <Col span={6}>
              <AutoComplete 
                onSearch={this.handleChangeSearch} 
                style={{ width: '100%' }}
                allowClear
              >
                <Input prefix={<SearchOutlined />} placeholder={placeholder}  />
              </AutoComplete>
            </Col>
          }
          {
            fase3 &&
            <Col span={4}>
              <Tooltip title="Filter by Location Status">
                <Select
                  defaultValue={fase3.value[0].value}
                  onChange={this.handleChangeLocation}
                  placeholder={fase3.key}
                  style={{ width: '100%' }}
                >
                  {fase3.value.map((res, i) => <Select.Option key={i.toString()} value={res.value}>{res.name}</Select.Option>)}
                </Select>
              </Tooltip>
            </Col>
          }
          {
            fase1 &&
            <Col span={4}>
              <Tooltip title="Filter by Display Status">
                <Select
                  defaultValue={fase1.value[0].value}
                  onChange={this.handleChangeDisplay}
                  placeholder={fase1.key}
                  style={{ width: '100%' }}
                >
                  {fase1.value.map((res, i) => <Select.Option key={i.toString()} value={res.value}>{res.name}</Select.Option>)}
                </Select>
              </Tooltip>
            </Col>
          }
          {
            fase4 && 
            <Col span={4}>
            <Tooltip title="Filter by Scope">
              <Select
                defaultValue={fase4.value[0].value}
                onChange={this.handleChangeScope}
                placeholder={fase4.key}
                style={{ width: '100%' }}
              >
                {fase4.value.map((res, i) => <Select.Option key={i.toString()} value={res.value}>{res.name}</Select.Option>)}
              </Select>
              </Tooltip>
          </Col>
          }
          {
            fase5 && 
            <Col span={4}>
              <Tooltip title="Filter by Section">
                <Select
                  defaultValue={fase5.value[0].value}
                  onChange={this.handleChangeSection}
                  placeholder={fase5.key}
                  style={{ width: '100%' }}
                >
                  {fase5.value.map((res, i) => <Select.Option key={i.toString()} value={res.value}>{res.name}</Select.Option>)}
                </Select>
                </Tooltip>
            </Col>
          }
          {
            fase6 && 
            <Col span={4}>
              <Tooltip title="Filter by Code">
                <Select
                  defaultValue={fase6.value[0].value}
                  onChange={this.handleChangeCode}
                  placeholder={fase6.key}
                  style={{ width: '100%' }}
                >
                  {fase6.value.map((res, i) => <Select.Option key={i.toString()} value={res.value}>{res.name}</Select.Option>)}
                </Select>
                </Tooltip>
            </Col>
          }
          {
            fase7 && 
            <Col span={4}>
              <Tooltip title="Filter by Bank">
                <Select
                  defaultValue={fase7.value[0].value}
                  onChange={this.handleChangeBank}
                  placeholder={fase7.key}
                  style={{ width: '100%' }}
                >
                  {fase7.value.map((res, i) => <Select.Option key={i.toString()} value={res.value}>{res.name}</Select.Option>)}
                </Select>
                </Tooltip>
            </Col>
          }
          <Col span={4}>
            <Tooltip title="Filter by Status">
              <Select
                defaultValue={fase2.value[0].value}
                onChange={this.handleChangeStatus}
                placeholder={fase2.key}
                style={{ width: '100%' }}
              >
                {fase2.value.map((res, i) => <Select.Option key={i.toString()} value={res.value}>{res.name}</Select.Option>)}
              </Select>
              </Tooltip>
          </Col>
          <Col span={3} style={{ display: 'contents' }}>
            <Link to={link}>
              <Button className="btn-create-primary" type="primary" icon={<PlusOutlined />} ghost>{name}</Button>
            </Link>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterMasterData)
