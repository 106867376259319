import React, { Component } from 'react';
import moment from 'moment';
import { Form, Row, Col, Input, DatePicker } from 'antd';

export default class AddAchievement extends Component {
  render() {
    const { 
      formRef, 
      onSubmitAdd, 
    } = this.props;

    return (
      <React.Fragment>
        <Form ref={formRef} onFinish={onSubmitAdd} layout="vertical">
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item 
                label="Name"
                name="name"
                validateFirst
                initialValue={null}
                rules={[
                  { max: 50, message: '50 characters only' },
                  { min: 5, message: 'Name must be at least 5 characters' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item 
                label="Issuing Organization"
                name="organization"
                validateFirst
                initialValue={null}
                rules={[
                  { max: 50, message: '50 characters only' },
                  { min: 5, message: 'Organization must be at least 5 characters' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
            <Form.Item label="Year" name="year">
              <DatePicker 
                picker="year" 
                style={{ width: '100%' }}
                disabledDate={ d => !d || d.isBefore(moment().subtract(20, 'years').format('YYYY').toString()) || d.isAfter(moment().add(1, 'years').format('YYYY').toString()) } 
              />
            </Form.Item>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}
