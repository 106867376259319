import React from "react";
import { CalendarOutlined, GlobalOutlined, BlockOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Card, Col, Descriptions, Image, Progress, Row, Space, Typography, Divider } from "antd"
import moment from "moment";
import ic_ticket from '../../../../../assets/icon/event/ic_ticket.png'
import ic_bucket from '../../../../../assets/icon/event/ic_bucket.png'
import ic_people from '../../../../../assets/icon/event/ic_people.png'
import ic_earning from '../../../../../assets/icon/event/ic_earning.png'
import { courseLevel } from '../../../../../utils/constant/courseLevel'
const { Text } = Typography;

const DetailHeader = ({ data, handleEnable, handleDisable, onClickEdit, submitLoading }) => {
  const startDate = `${data.startDate} ${data.startTime}`
  const endDate = `${data.endDate} ${data.endTime}`
  return(
    <Card bordered={false} style={{ border: 'none' }}>
      <Row gutter={[16, 16]}>
        <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Space size={'large'}>
            {
              data.status === 'ACTIVE' ?
                <Button className="btn-cancel-danger" style={{ width: 'auto' }} onClick={() => handleDisable(data.id)} loading={submitLoading} >Set as inactive</Button>
              :
                <Button className="btn-create-primary" type="primary" ghost style={{ width: 'auto' }} onClick={() => handleEnable(data.id) } loading={submitLoading} >Set as active</Button>
            }
            <Button className="btn-color-blue" style={{ width: 90 }} type="primary" onClick={() => onClickEdit(data.id)} >Edit</Button>
          </Space>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: 16 }}>
        <Col span={6} style={{ textAlign: 'center' }}>
          {data.image ? <Image src={data.image} width={'100%'} /> : 'N/A'}
        </Col>
        <Col span={12}>
          <Descriptions title={data.title} layout="vertical" column={3}>
            <Descriptions.Item>
              <Space size={'small'}>
                <UserOutlined style={{ marginRight: 8, fontSize: 20 }} /> 
                <Text type="secondary">{data.instructorName ? data.instructorName : 'N/A'}</Text>
              </Space>
            </Descriptions.Item>
            <Descriptions.Item>
              <Space size={'small'}>
                <BlockOutlined style={{ marginRight: 8, fontSize: 20 }} />
                <Text type="secondary">{data.courseLevel ? courseLevel.find(item => item.value === data.courseLevel)?.name : 'N/A'}</Text>
              </Space>
            </Descriptions.Item>
            <Descriptions.Item>
              <Space size={'small'}>
                <GlobalOutlined style={{ marginRight: 8, fontSize: 20 }} />
                <Text type="secondary">{data.categoryName ? data.categoryName : 'N/A'}</Text>
              </Space>
            </Descriptions.Item>
          </Descriptions>
          <Descriptions layout="vertical">
            <Descriptions.Item>
              <Space size={'small'} style={{ marginRight: 8, fontSize: 13 }}>
                <CalendarOutlined style={{ marginRight: 8, fontSize: 20 }} />
                <Text type="secondary">{data.startDate ? `${moment(startDate, 'DD/MM/YYYY HH:mm:ss').format("dddd, DD MMMM YYYY HH:mm")} - ${moment(endDate, 'DD/MM/YYYY HH:mm:ss').format("dddd, DD MMMM YYYY HH:mm")}` : 'N/A'}</Text>
              </Space>
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={6} style={{ margin: 'auto' }}>
          <Space direction="vertical">
            <Text type="secondary">Number of Audiences Progress</Text>
            {
              data.isAudience ?
                <Text style={{ color: '#32ab6d' }}>Unlimited</Text>
              :
                <Progress percent={data.progress || 0} />
            }
          </Space>
        </Col>
      </Row>
      <Divider />
      <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
        <Col span={18}>
          <Space size={50} align="center">
            <Space size={16}>
              <Image src={ic_earning} preview={false} height={25} width={30} style={{ objectFit: 'contain' }} />
              <Space size={8} direction="vertical">
                <Text type="secondary">Earnings</Text>
                <Text strong={true}>{`IDR ${data.earnings ? data.earnings.toLocaleString() : 0}`}</Text>
              </Space>
            </Space>
            <Space size={16}>
              <Image src={ic_bucket} preview={false} height={25} width={30} style={{ objectFit: 'contain' }} />
              <Space size={8} direction="vertical">
                <Text type="secondary">Total Orders</Text>
                <Text strong={true}>{data.totalOrders || 0}</Text>
              </Space>
            </Space>
            <Space size={16}>
              <Image src={ic_people} preview={false} height={25} width={30} style={{ objectFit: 'contain' }} />
              <Space size={8} direction="vertical">
                <Text type="secondary">Ticket Sold</Text>
                <Text strong={true}>{data.totalTicketSold || 0}</Text>
              </Space>
            </Space>
            {
              data.remainingTicket ?
                <Space size={16}>
                  <Image src={ic_ticket} preview={false} height={25} width={30} style={{ objectFit: 'contain' }} />
                  <Space size={8} direction="vertical">
                    <Text type="secondary">Remaining Tickets</Text>
                    <Text strong={true}>{data.remainingTicket}</Text>
                  </Space>
                </Space>
              : null
            }
          </Space>
        </Col>
        <Col span={6}>
          {
            data.totalOrder ? 
              <Space>
                <Avatar.Group>
                  <Avatar style={{ backgroundColor: '#f56a00' }} icon={<UserOutlined />} />
                  <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                  <Avatar style={{ backgroundColor: '#1890ff' }} icon={<UserOutlined />} />
                </Avatar.Group>
                <Text type="secondary">and 500 others</Text>
              </Space>
            : null
           }
        </Col>
      </Row>
    </Card>
  )
}

export default DetailHeader;