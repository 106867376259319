import React from 'react'
import moment from 'moment'
import { Row, Col, Typography, Descriptions, Divider } from 'antd'

const { Text } = Typography;

export default function Training(props) {
  const { data } = props
  return (
    <Row gutter={16}>
      <Col span={24}>
        <Descriptions title="Training and Certifications" />
        {
          data.listTraining.length > 0 ?
            <React.Fragment>
              {
                data.listTraining.map((item, i) => (
                  <React.Fragment key={i}>
                    <div style={{ marginBottom: 11 }}>
                      <Text style={{ color: '#32ab6d', fontSize: 14 }}>
                        {`${item.issueDate ? moment(item.issueDate, 'YYYY-MM').format('MMMM YYYY') : 'N/A'}, ${item.expDate ? moment(item.expDate, 'YYYY-MM').format('MMMM YYYY') : 'certificate will not expire'}`}
                      </Text>
                    </div>
                    <Descriptions layout="vertical" size="small" column={3}>
                      <Descriptions.Item label="Name">
                        {item.name}
                      </Descriptions.Item>
                      <Descriptions.Item label="Credential ID">
                        {item.credentialId ? item.credentialId : 'N/A'}
                      </Descriptions.Item>
                    </Descriptions>
                    <Descriptions layout="vertical" size="small" column={3}>
                      <Descriptions.Item label="Issuing Organization">
                        {item.organization ? item.organization : 'N/A'}
                      </Descriptions.Item>
                      <Descriptions.Item label="Credential URL">
                        {
                          item.credentialUrl ? 
                          <a href={`${item.credentialUrl}`} target="_blank" rel='noreferrer'>
                            {item.credentialUrl}
                          </a> 
                          : 
                          'N/A'
                        }
                      </Descriptions.Item>
                    </Descriptions>
                    <Divider />
                  </React.Fragment>
                ))
              }
            </React.Fragment>
          :
            <React.Fragment>
              <Text>N/A</Text>
              <Divider />
            </React.Fragment>
        }
      </Col>
    </Row>
  )
}
