const LOAD_DATA_APPLICANT_SUMMARY           = 'LOAD_DATA_APPLICANT_SUMMARY'
const LOAD_DATA_APPLICANT_SUMMARY_SUCCESS   = 'LOAD_DATA_APPLICANT_SUMMARY_SUCCESS'
const LOAD_DATA_APPLICANT_SUMMARY_FAILED    = 'LOAD_DATA_APPLICANT_SUMMARY_FAILED'
const UNMOUNT_DATA_APPLICANT_SUMMARY        = 'UNMOUNT_DATA_APPLICANT_SUMMARY'

const initialState = {
  loading: true,
  data: null,
  message: null,
}

const applicantSummaryReducer = (state = initialState, action) => {
  switch(action.type){
    case LOAD_DATA_APPLICANT_SUMMARY:
      return {
        ...state,
        loading: true,
        data: null,
      }
    case LOAD_DATA_APPLICANT_SUMMARY_SUCCESS:
      return { 
        ...state, 
        loading: false,
        data: action.payload.data,
        message: 'SUCCESS',
      }
    case LOAD_DATA_APPLICANT_SUMMARY_FAILED:
      return { 
        ...state, 
        loading: true,
        data: null,
        message: 'FAILED',
      }
    case UNMOUNT_DATA_APPLICANT_SUMMARY:
      return { 
        ...state, 
        loading: true,
        data: null,
      }
    default:
      return state
  }
}

export default applicantSummaryReducer;