import React, { Component } from 'react';
import { Row, Col, Card, Typography, Image, Skeleton } from 'antd';
const { Text } = Typography;

export default class DetailSliderPhoto extends Component {

  componentDidMount() {
    const { actionGetSliderPhoto } = this.props;
    return actionGetSliderPhoto()
  }
  
  render() {
    const { getSliderPhoto: { loading, data } } = this.props;
    if(loading){
      return <Skeleton />
    }
    return (
      <React.Fragment>
        <Row gutter={16}>
          {
            data.length > 0 ?
            <Col span={24}>
              <div className="list-slider-photo">
                {
                  data.map((item, i) => (
                    <div key={i} style={{ position: 'relative' }}>
                      <Card className={item.isCover ? 'cover-photo' : 'not-cover-photo'}>
                        {
                          item.isCover ?
                          <div style={{ width: '100%', marginBottom: 15 }}>
                            <Text style={{ color: ' #706f6f', fontSize: 18 }}>COVER</Text>
                          </div>
                          :
                          null
                        }
                        <Row gutter={[16, 24]}>
                          <Col span={24}>
                            <div>
                              <Image src={item.fileUrl} width={300} /> 
                            </div>
                          </Col>
                        </Row>
                      </Card>
                    </div>
                  ))
                }
              </div>
            </Col>
            :
            <Col span={24}>
              <Text type="secondary" style={{ fontSize: 16 }}>No photo displayed</Text>
            </Col>
          }
        </Row>
      </React.Fragment>
    )
  }
}
