import React, { Component } from 'react'
import { UserOutlined, FileDoneOutlined, MoreOutlined, HistoryOutlined } from '@ant-design/icons';
import { Row, Col, Button, Avatar, Space, Typography, Badge, Dropdown, Modal } from 'antd';
const { Title, Text } = Typography

export default class Header extends Component {
  render() {
    const { dataApplicant, openModalProfile, showModalNotes, showModalHistory, status } = this.props

    const showModal = (data) => {
      Modal.info({
        title: 'Reason of request to reschedule:',
        content: (
          <Text>{data.rescheduleReason}</Text>
        ),
      });
    }
  
    const handleMenuClick = (e) => {
      const { dataApplicant } = this.props
      if(e.key === 'applicantNotes'){
        showModalNotes(dataApplicant.id)
      }
      if(e.key === 'applicantHistory'){
        showModalHistory(dataApplicant.id)
      }
    };
    const items = [
      {
        label: 'View Applicant Notes',
        key: 'applicantNotes',
        icon: dataApplicant?.listNotes?.length > 0 ? <Badge dot offset={[0, 0]} style={{ width: 5, height: 5 }}><FileDoneOutlined /></Badge> : <FileDoneOutlined />,
      },
      {
        label: 'View Applicant History',
        key: 'applicantHistory',
        icon: dataApplicant?.listHistory?.length > 0 ? <Badge dot offset={[0, 0]} style={{ width: 5, height: 5 }}><HistoryOutlined /></Badge> : <HistoryOutlined />,
      }
    ]
    const menuProps = {
      items,
      onClick: handleMenuClick,
    };

    return (
      <Row gutter={16}>
        <Col span={17}>
          <Space size="large">
            <Avatar size={64} src={dataApplicant.profilePic ? dataApplicant.profilePic : null } icon={!dataApplicant.profilePic ? <UserOutlined /> : null} />
            <div>
              <Title level={4}>{dataApplicant.name ? dataApplicant.name : 'N/A'}</Title>
              <Text type="secondary">{dataApplicant.applicantNumber ? dataApplicant.applicantNumber : 'N/A'}</Text>
              <br />
              {
                status === 'assessment' ?
                  <React.Fragment>
                    {
                      dataApplicant.status === "Assessment today" &&
                      <Text style={{ color: '#37ac71' }}>Assessment today</Text>
                    }
                    {
                      dataApplicant.status === "Request to schedule" &&
                      <Space>
                        <Text style={{ color: '#f23c3c' }}>Request to reschedule</Text>
                        <Button onClick={() => showModal(dataApplicant)} type="primary" ghost size="small" style={{ border: '1px solid #0076de', color: '#0076de' }}>Reason</Button>
                      </Space>
                    }
                    {
                      dataApplicant.status === "Awaiting confirmation" &&
                      <Text style={{ color: '#979797' }}>Awaiting confirmation</Text>
                    }
                    {
                      dataApplicant.status === "Missed assessment" &&
                      <Text style={{ color: '#ff8a1c' }}>Missed assessment</Text>
                    }
                    {
                      dataApplicant.status === "Confirmed" &&
                      <Text style={{ color: '#14bfbd' }}>Confirmed</Text>
                    }
                    {
                      dataApplicant.status === "Awaiting reschedule confirmation" &&
                      <Text style={{ color: '#979797' }}>Awaiting reschedule confirmation</Text>
                    }
                  </React.Fragment>
                : status === 'interview' ?
                  <React.Fragment>
                    {
                      dataApplicant.status === "Interview today" &&
                      <Text style={{ color: '#37ac71' }}>Interview today</Text>
                    }
                    {
                      dataApplicant.status === "Request to schedule" &&
                      <Space>
                        <Text style={{ color: '#f23c3c' }}>Request to reschedule</Text>
                        <Button onClick={() => showModal(dataApplicant)} type="primary" ghost size="small" style={{ border: '1px solid #0076de', color: '#0076de' }}>Reason</Button>
                      </Space>
                    }
                    {
                      dataApplicant.status === "Awaiting confirmation" &&
                      <Text style={{ color: '#979797' }}>Awaiting confirmation</Text>
                    }
                    {
                      dataApplicant.status === "Missed interview" &&
                      <Text style={{ color: '#ff8a1c' }}>Missed interview</Text>
                    }
                    {
                      dataApplicant.status === "Confirmed" &&
                      <Text style={{ color: '#14bfbd' }}>Confirmed</Text>
                    }
                    {
                      dataApplicant.status === "Awaiting reschedule confirmation" &&
                      <Text style={{ color: '#979797' }}>Awaiting reschedule confirmation</Text>
                    }
                  </React.Fragment>
                :
                  <Text>
                    {dataApplicant.gender ? dataApplicant.gender === 'MALE' ? 'Male' : 'Female' : 'N/A'}, {`${dataApplicant.age ? `${dataApplicant.age} years` : 'N/A'}`} 
                  </Text>
              }
            </div>
          </Space>
        </Col>
        <Col span={7} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Space>
            <Button className="btn-profile" onClick={() => openModalProfile(dataApplicant.candidateId)}>
              View Profile
            </Button>
            <Dropdown trigger="click" menu={menuProps} placement="bottomLeft">
              <Button className='btn-more'><MoreOutlined /></Button>
            </Dropdown>
          </Space>
        </Col>
      </Row>
    )
  }
}
