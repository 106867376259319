import React, { Component } from 'react';
import Email from './Email';
import ContactDetail from './ContactDetail';
import ChangePassword from './ChangePassword';
import { connect } from 'react-redux';
import { Row, Col, PageHeader } from 'antd';
import { checkEmail, checkPhone } from '../../../redux/actions/check/checkAction';
import { masterCountries, unmountMasterCountries } from '../../../redux/actions/master/countries/countriesAction';
import { getContact, getEmail, unmountDataEmail, unmountDataContact, updateEmail, activationEmail, resendEmail, updateContact, updatePassword } from '../../../redux/actions/settings/settingAction';

class SettingEmployer extends Component {
  render() {
    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <PageHeader className="site-page-header" title="Account Settings" />
          </Col>
          <Col span={24}>
            <Email {...this.props} />
          </Col>
          <Col span={24}>
            <ContactDetail {...this.props} />
          </Col>
          <Col span={24}>
            <ChangePassword {...this.props} />
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetEmail, unmountGetContact, unmountGetCountry } = this.props;
    unmountGetEmail()
    unmountGetContact() 
    unmountGetCountry()
  }
}

const mapStateToProps = (state) => ({
  getEmail    : state.settings.email,
  getContact  : state.settings.contact,
  getCountry  : state.countries.master
})

const mapDispatchToProps = {
  actionUpdateEmail     : updateEmail,
  actionUpdateContact   : updateContact,
  actionUpdatePassword  : updatePassword,
  actionActivationEmail : activationEmail,
  actionResendEmail     : resendEmail,
  actionGetEmail        : getEmail,
  actionGetContact      : getContact,
  actionCheckEmail      : checkEmail,
  actionCheckPhone      : checkPhone,
  actionGetCountry      : masterCountries,
  unmountGetEmail       : unmountDataEmail,
  unmountGetContact     : unmountDataContact,
  unmountGetCountry     : unmountMasterCountries,
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingEmployer)
