import axios from 'axios';
import { env } from './index';
import Cookie from 'js-cookie';

export const GET = (path, payload) => {
  const getToken = Cookie.get('user');
  const header = {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'X-Requested-With':' XMLHttpRequest',
    'X-Authorization': `Bearer ${getToken}`,
  }
  return new Promise((resolve ,reject) => {
    axios.get(`${env}${path}`, {
      headers: header,
      params: payload
    }).then((response) => {
      let data = response.data;
      if(response.status === 200){
        return resolve(data);
      }else{
        const error = { message: 'error' }
        return reject(error)
      }
    }).catch((err) => {
      return reject(err.response.data.message)
    })
  })
}

export const GET_DOWNLOAD = (path, payload) => {
  const getToken = Cookie.get('user');
  const header = {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'X-Requested-With':' XMLHttpRequest',
    'X-Authorization': `Bearer ${getToken}`,
  }
  return new Promise((resolve ,reject) => {
    axios.get(`${env}${path}`, {
      headers: header,
      params: payload,
      responseType: 'blob'
    }).then((response) => {
      let data = response.data;
      if(response.status === 200){
        return resolve(data);
      }else{
        const error = { message: 'error' }
        return reject(error)
      }
    }).catch((err) => {
      return reject(err.response.data.message)
    })
  })
}

export const POST_LOGIN = (path, payload) => {
  const header = {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  }
  return new Promise((resolve ,reject) => {
    axios.post(`${env}${path}`,payload, {
      headers: header
    }).then((response) => {
      if(response.status === 200){
        return resolve(response.data);
      }else{
        return reject();
      }
    }).catch((err) => {
      return reject(err.response.data.message);
    })
  })
}

export const POST_LOGIN_NO_WITH_BEARER = (path, payload) => {
  const header = {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  }
  return new Promise((resolve ,reject) => {
    axios.post(`${env}${path}`,payload, {
      headers: header,
      params: payload
    }).then((response) => {
      if(response.status === 200){
        return resolve(response.data);
      }else{
        return reject();
      }
    }).catch((err) => {
      return reject(err.response.data.message);
    })
  })
}

export const POST_WITH_PARAMS = (path, payload) => {
  const getToken = Cookie.get('user');
  const header = {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'X-Authorization': `Bearer ${getToken}`
  }
  return new Promise((resolve ,reject) => {
    axios.post(`${env}${path}`,payload, {
      headers: header,
      params: payload
    }).then((response) => {
      if(response.status === 200){
        return resolve(response.data);
      }else{
        return reject();
      }
    }).catch((err) => {
      return reject(err.response.data.message);
    })
  })
}

export const POST_NO_PARAMS = (path, payload) => {
  const getToken = Cookie.get('user');
  const header = {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'X-Authorization': `Bearer ${getToken}`
  }
  return new Promise((resolve ,reject) => {
    axios.post(`${env}${path}`,payload, {
      headers: header
    }).then((response) => {
      if(response.status === 200){
        return resolve(response.data);
      }else{
        return reject();
      }
    }).catch((err) => {
      return reject(err.response.data.message);
    })
  })
}

export const POST_BODY_PARAMS = (path, body, params) => {
  const getToken = Cookie.get('user');
  const header = {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'X-Authorization': `Bearer ${getToken}`
  }
  return new Promise((resolve ,reject) => {
    axios.post(`${env}${path}`,body, {
      headers: header,
      params: params
    }).then((response) => {
      if(response.status === 200){
        return resolve(response.data);
      }else{
        return reject();
      }
    }).catch((err) => {
      return reject(err.response.data.message);
    })
  })
}

export const POST_FORM_DATA = (path, payload) => {
  const getToken = Cookie.get('user');
  return new Promise((resolve, reject) => {
    const data = new FormData();
    
    if(path === '/job_employers/inject_candidate' || path === '/job_agency/inject_candidate'){
      for (const [val, name] of Object.entries(payload)) {
        if(typeof name === 'object'){
          for (const [key, _val] of Object.entries(name)) {
            data.append(`${val}.${key}`, _val);
          }
        }
  
        if(val === "jobId"){
          data.append(val, name);
        }

        if(val === "candidateId"){
          data.append(val, name);
        }
      }
    }else if(path === '/client/add' || path === '/client/update'){
      for (const [val, name] of Object.entries(payload)) {
        if(name){
          data.append(val, name);
        }
      }
      if(payload?.fileSlider?.length > 0){
        payload?.fileSlider?.forEach((item,i) => {
          if(item.file){
            data.append(`listImage[${i}].file`, item.file)
          }else{
            data.append(`listImage[${i}].fileName`, item.name)
          }
          data.append(`listImage[${i}].isCover`, i === 0 ? true : false)
        })
      }
      if(payload?.fileImage?.length > 0){
        payload?.fileImage?.forEach((item,i) => {
          if(item.file){
            data.append(`listMedia[${i}].file`, item.file)
          }else{
            data.append(`listMedia[${i}].fileName`, item.name)
          }
        })
      }
      if(payload?.fileVideo?.length > 0){
        payload?.fileVideo?.forEach((item,i) => {
          if(item.file){
            data.append(`videoFile`, item.file)
          }else{
            data.append(`video`, item.name)
          }
        })
      }
      data.delete('fileSlider')
      data.delete('fileImage')
      data.delete('fileVideo')
    }else{
      for (const [val, name] of Object.entries(payload)) {
        data.append(val, name);
      }
    }
    axios.post(`${env}${path}`, data, {
      headers: {
        'Content-Type' : 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
        'Cache-Control': 'no-cache',
        'X-Authorization': `Bearer ${getToken}`
      },
    }).then((response) => {
      if(response.status === 200){
        return resolve(response.data);
      }else{
        return reject();
      }
    }).catch((err) => {
      return reject(err.response.data.message);
    })
  }) 
}

export const POST_FORM_DATA_ARRAY = (path, payload, type) => {
  const getToken = Cookie.get('user');
  return new Promise((resolve, reject) => {
    const data = new FormData();
    for (const [index, name] of Object.entries(payload)) {
      if(type === 'pathway'){
        if(name.isStatus){
          data.append('id', name.id);
          if(name.status !== null){
            data.append('status', name.status);
          }
        }else{
          data.append(`listCandidateForm[${index}].program`, name.program );
          data.append(`listCandidateForm[${index}].title`, name.title );
          data.append(`listCandidateForm[${index}].note`, name.note );
          if(name.candidatePathwayId){
            data.append(`listCandidateForm[${index}].candidatePathwayId`, name.candidatePathwayId );
          }
          if(name.attachmentFile){
            data.append(`listCandidateForm[${index}].attachmentFile`, name.attachmentFile );
          }
          if(name.attachment){
            data.append(`listCandidateForm[${index}].attachment`, name.attachment );
          }
          if(name.idEdit){
            data.append(`listCandidateForm[${index}].id`, name.id );
          }
        }
      }else if(type === 'createEvent'){
        if(name.title){
          data.append('title', name.title);
          // data.append('slug', name.slug);
          data.append('categoryId', name.categoryId);
          if(name.organizerId){
            data.append('organizerId', name.organizerId);
          }
          if(name.startDate){
            data.append('startDate', name.startDate);
          }
          if(name.endDate){
            data.append('endDate', name.endDate);
          }
          if(name.startTime){
            data.append('startTime', name.startTime);
          }
          if(name.endTime){
            data.append('endTime', name.endTime);
          }
          if(name.xpertDetail){
            data.append('xpertDetail', name.xpertDetail);
          }
          if(name.eventType){
            data.append('eventType', name.eventType);
          }
          if(name.isAudience){
            data.append('isAudience', name.isAudience);
          }
          if(name.audience){
            data.append('audience', name.audience);
          }
          // if(name.priceType){
          //   data.append('priceType', name.priceType);
          // }
          // if(name.price){
          //   data.append('price', name.price);
          // }
          // if(name.maxPrice){
          //   data.append('maxPrice', name.maxPrice);
          // }
          if(name.tags.length > 0){
            data.append('tags', JSON.stringify(name.tags));
          }
          if(name.status){
            data.append('status', name.status);
          }
          if(name.id){
            data.append('id', name.id);
          }
        }else{
          if(name.fileUrl){
            data.append(`listImage[${index}].isCover`, name.isCover );
            if(name.file !== undefined){
              data.append(`listImage[${index}].file`, name.file );
            }
            if(name.fileName !== undefined){
              data.append(`listImage[${index}].fileName`, name.fileName );
              data.append(`listImage[${index}].fileExtension`, name.fileExtension );
              data.append(`listImage[${index}].fileSize`, name.fileSize );
            }
          }
        }
      }else if(type === 'createCourse'){
        if(name.title){
          data.append('title', name.title);
          // data.append('slug', name.slug);
          data.append('categoryId', name.categoryId);
          if(name.instructorId){
            data.append('instructorId', name.instructorId);
          }
          if(name.startDate){
            data.append('startDate', name.startDate);
          }
          if(name.endDate){
            data.append('endDate', name.endDate);
          }
          if(name.startTime){
            data.append('startTime', name.startTime);
          }
          if(name.endTime){
            data.append('endTime', name.endTime);
          }
          if(name.xpertDetail){
            data.append('xpertDetail', name.xpertDetail);
          }
          if(name.courseLevel){
            data.append('courseLevel', name.courseLevel);
          }
          if(name.isAudience){
            data.append('isAudience', name.isAudience);
          }
          if(name.audience){
            data.append('audience', name.audience);
          }
          // if(name.priceType){
          //   data.append('priceType', name.priceType);
          // }
          // if(name.price){
          //   data.append('price', name.price);
          // }
          // if(name.maxPrice){
          //   data.append('maxPrice', name.maxPrice);
          // }
          if(name.tags.length > 0){
            data.append('tags', JSON.stringify(name.tags));
          }
          if(name.status){
            data.append('status', name.status);
          }
          if(name.id){
            data.append('id', name.id);
          }
          if(name.courseDetail1){
            name.courseDetail1.map((item, i) =>
              data.append(`listCourseDetail[${i}].name`, item )
            )
          }
          if(name.courseDetail2){
            name.courseDetail2.map((item, i) =>
              data.append(`listCourseFor[${i}].name`, item )
            )
          }
          if(name.syllabus[0].title && name.syllabus[0].detail){
            name.syllabus.map((item, i) =>
              data.append(`listCourseSyllabus[${i}].title`, item.title )
            )
            name.syllabus.map((item, i) =>
              data.append(`listCourseSyllabus[${i}].detail`, item.detail )
            )
          }
        }else{
          if(name.fileUrl){
            data.append(`listImage[${index}].isCover`, name.isCover );
            if(name.file !== undefined){
              data.append(`listImage[${index}].file`, name.file );
            }
            if(name.fileName !== undefined){
              data.append(`listImage[${index}].fileName`, name.fileName );
              data.append(`listImage[${index}].fileExtension`, name.fileExtension );
              data.append(`listImage[${index}].fileSize`, name.fileSize );
            }
          }
        }
      }else if(type === 'createTraining'){
        if(name.title){
          data.append('title', name.title);
          // data.append('slug', name.slug);
          data.append('categoryId', name.categoryId);
          if(name.organizerId){
            data.append('organizerId', name.organizerId);
          }
          if(name.startDate){
            data.append('startDate', name.startDate);
          }
          if(name.endDate){
            data.append('endDate', name.endDate);
          }
          if(name.startTime){
            data.append('startTime', name.startTime);
          }
          if(name.endTime){
            data.append('endTime', name.endTime);
          }
          if(name.xpertDetail){
            data.append('xpertDetail', name.xpertDetail);
          }
          if(name.trainingType){
            data.append('trainingType', name.trainingType);
          }
          if(name.isAudience){
            data.append('isAudience', name.isAudience);
          }
          if(name.audience){
            data.append('audience', name.audience);
          }
          // if(name.priceType){
          //   data.append('priceType', name.priceType);
          // }
          // if(name.price){
          //   data.append('price', name.price);
          // }
          // if(name.maxPrice){
          //   data.append('maxPrice', name.maxPrice);
          // }
          if(name.tags.length > 0){
            data.append('tags', JSON.stringify(name.tags));
          }
          if(name.status){
            data.append('status', name.status);
          }
          if(name.trainerName){
            data.append('trainerName', name.trainerName);
          }
          if(name.id){
            data.append('id', name.id);
          }
          if(name.studentBenefit){
            name.studentBenefit.map((item, i) =>
              data.append(`listTrainingBenefit[${i}].name`, item )
            )
          }
          if(name.outcomes){
            name.outcomes.map((item, i) =>
              data.append(`listTrainingOutcome[${i}].name`, item )
            )
          }
          if(name.for){
            name.for.map((item, i) =>
              data.append(`listTrainingFor[${i}].name`, item )
            )
          }
          if(name.syllabus[0].title && name.syllabus[0].detail){
            name.syllabus.map((item, i) =>
              data.append(`listTrainingSyllabus[${i}].title`, item.title )
            )
            name.syllabus.map((item, i) =>
              data.append(`listTrainingSyllabus[${i}].detail`, item.detail )
            )
          }
        }else{
          if(name.fileUrl){
            data.append(`listImage[${index}].isCover`, name.isCover );
            if(name.file !== undefined){
              data.append(`listImage[${index}].file`, name.file );
            }
            if(name.fileName !== undefined){
              data.append(`listImage[${index}].fileName`, name.fileName );
              data.append(`listImage[${index}].fileExtension`, name.fileExtension );
              data.append(`listImage[${index}].fileSize`, name.fileSize );
            }
          }
        }
      }else if(type === 'createService'){
        if(name.title){
          data.append('title', name.title);
          // data.append('slug', name.slug);
          data.append('categoryId', name.categoryId);
          if(name.organizerId){
            data.append('organizerId', name.organizerId);
          }
          if(name.startDate){
            data.append('startDate', name.startDate);
          }
          if(name.endDate){
            data.append('endDate', name.endDate);
          }
          if(name.startTime){
            data.append('startTime', name.startTime);
          }
          if(name.endTime){
            data.append('endTime', name.endTime);
          }
          if(name.xpertDetail){
            data.append('xpertDetail', name.xpertDetail);
          }
          if(name.serviceType){
            data.append('serviceType', name.serviceType);
          }
          if(name.isAudience){
            data.append('isAudience', name.isAudience);
          }
          if(name.audience){
            data.append('audience', name.audience);
          }
          // if(name.priceType){
          //   data.append('priceType', name.priceType);
          // }
          // if(name.price){
          //   data.append('price', name.price);
          // }
          // if(name.maxPrice){
          //   data.append('maxPrice', name.maxPrice);
          // }
          // data.append('discountedPrice', name.discountedPrice);
          // if(name.maxDiscountedPrice){
          //   data.append('maxDiscountedPrice', name.maxDiscountedPrice);
          // }
          if(name.status){
            data.append('status', name.status);
          }
          if(name.id){
            data.append('id', name.id);
          }
        }else{
          if(name.fileUrl){
            data.append(`listImage[${index}].isCover`, name.isCover );
            if(name.file !== undefined){
              data.append(`listImage[${index}].file`, name.file );
            }
            if(name.fileName !== undefined){
              data.append(`listImage[${index}].fileName`, name.fileName );
              data.append(`listImage[${index}].fileExtension`, name.fileExtension );
              data.append(`listImage[${index}].fileSize`, name.fileSize );
            }
          }
        }
      }else if(type === 'galleryCompany'){
        if(name.videoFile.length > 0){
          if(name?.videoFile[0]?.file){
            data.append('videoFile', name.videoFile[0].file);
          }else{
            data.append('video', name.videoFile[0].name);
          }
        }
        if(name.listMedia.length > 0){
          name.listMedia.forEach((item, i) => {
            if(item.file){
              data.append(`listMedia[${i}].file`, item.file)
            }else{
              data.append(`listMedia[${i}].fileName`, item.name)
              data.append(`listMedia[${i}].fileSize`, item.size)
              data.append(`listMedia[${i}].fileExtension`, item.extension)
            }
          })
        }
      }else if(type === 'createJob'){
        if(name.id){
          data.append('id', name.id);
        }
        if(name.clientId){
          data.append('clientId', name.clientId);
        }
        data.append('title', name.title);
        if(name.jobPostingTypeId){
          data.append('jobPostingTypeId', name.jobPostingTypeId);
        }
        if(name.externalLink){
          data.append('externalLink', name.externalLink);
        }
        if(name.jobIndustryId){
          data.append('jobIndustryId', name.jobIndustryId);
        }
        if(name.jobType){
          data.append('jobType', name.jobType);
        }
        if(name.jobPositionId){
          data.append('jobPositionId', name.jobPositionId);
        }
        if(name.placementType){
          data.append('placementType', name.placementType);
        }
        if(name.jobCountryId){
          data.append('jobCountryId', name.jobCountryId);
        }
        if(name.jobDestinationId){
          data.append('jobDestinationId', name.jobDestinationId);
        }
        data.append('isRemote', name.isRemote);
        if(name.experienceLevel){
          data.append('experienceLevel', name.experienceLevel);
        }
        if(name.numberOfPosition){
          data.append('numberOfPosition', name.numberOfPosition);
        }
        data.append('dateOpened', name.dateOpened);
        data.append('isDisplaySalary', name.isDisplaySalary);
        data.append('targetDate', name.targetDate);
        if(name.paymentType){
          data.append('paymentType', name.paymentType);
        }
        data.append('isFixSalary', name.isFixSalary);
        if(name.currencyId){
          data.append('currencyId', name.currencyId);
        }
        if(name.salary){
          data.append('salary', name.salary);
        }
        if(name.maxSalary){
          data.append('maxSalary', name.maxSalary);
        }
        if(name.jobDescription){
          data.append('jobDescription', name.jobDescription);
        }
        if(name.jobRequirement){
          data.append('jobRequirement', name.jobRequirement);
        }
        if(name.benefit){
          data.append('benefit', name.benefit);
        }
        if(name.placementType === 'INTERNATIONAL'){
          if(name.durationOfContract){
            data.append('durationOfContract', name.durationOfContract);
          }
          data.append('isContractExtendable', name.isContractExtendable);
          if(name.annualLeave){
            data.append('annualLeave', name.annualLeave);
          }
          data.append('isPaidAnnualLeave', name.isPaidAnnualLeave);
          if(name.workingHour){
            data.append('workingHour', name.workingHour);
          }
          if(name.workingDay){
            data.append('workingDay', name.workingDay);
          }
          if(name.probationPeriod){
            data.append('probationPeriod', name.probationPeriod);
          }
          data.append('isWorkingOvertime', name.isWorkingOvertime);
          if(name.otherWorkingSystem){
            data.append('otherWorkingSystem', name.otherWorkingSystem);
          }
          if(name?.listBenefit?.length > 0){
            name.listBenefit.forEach((item, i) => {
              data.append(`listBenefit[${i}].name`, item.name)
              data.append(`listBenefit[${i}].status`, item.status)
              if(item.allowance){
                data.append(`listBenefit[${i}].allowance`, item.allowance)
              }
            })
          }
          data.append('gender', name.gender);
          if(name.ageStart){
            data.append('ageStart', name.ageStart);
          }
          if(name.ageEnd){
            data.append('ageEnd', name.ageEnd);
          }
          if(name.educationLevelId){
            data.append('educationLevelId', name.educationLevelId);
          }
          if(name.fieldOfStudy){
            data.append('fieldOfStudy', name.fieldOfStudy);
          }
          if(name?.skill?.length > 0){
            data.append('skill', name.skill.toString());
          }
          if(name?.otherRequirements?.length > 0){
            data.append('otherRequirements', name.otherRequirements);
          }
          if(name.minExperience){
            data.append('minExperience', name.minExperience);
          }
          if(name.certification){
            data.append('certification', name.certification);
          }
          if(name.minHeight){
            data.append('minHeight', name.minHeight);
          }
          if(name.minWeight){
            data.append('minWeight', name.minWeight);
          }
          if(name.notes){
            data.append('notes', name.notes);
          }
        }
        if(name?.listJobStory?.length > 0){
          name.listJobStory.forEach((item, i) => {
            if(item.file){
              data.append(`listJobStory[${i}].file`, item.file)
            }else{
              if(item.name){
                data.append(`listJobStory[${i}].fileName`, item.name)
              }
              if(item.size){
                data.append(`listJobStory[${i}].fileSize`, item.size)
              }
              if(item.extension){
                data.append(`listJobStory[${i}].fileExtension`, item.extension)
              }
            }
          })
        }
        if(name?.listJobEvent?.length > 0){
          name.listJobEvent.forEach((item, i) => {
            data.append(`listJobEvent[${i}].organizerName`, item.organizerName)
            data.append(`listJobEvent[${i}].jobEventType`, item.jobEventType)
            data.append(`listJobEvent[${i}].title`, item.title)
            data.append(`listJobEvent[${i}].eventDate`, item.eventDate)
            data.append(`listJobEvent[${i}].startTime`, item.startTime)
            data.append(`listJobEvent[${i}].endTime`, item.endTime)
            if(item.thumbnailImageFile){
              data.append(`listJobEvent[${i}].thumbnailImageFile`, item.thumbnailImageFile)
            }else{
              if(item.thumbnailImage){
                data.append(`listJobEvent[${i}].thumbnailImage`, item.thumbnailImage)
              }
            }
            if(item.bannerImageFile){
              data.append(`listJobEvent[${i}].bannerImageFile`, item.bannerImageFile)
            }else{
              if(item.bannerImage){
                data.append(`listJobEvent[${i}].bannerImage`, item.bannerImage)
              }
            }
            data.append(`listJobEvent[${i}].detail`, item.detail)
            if(item.eventLink){
              data.append(`listJobEvent[${i}].eventLink`, item.eventLink)
            }
            if(item.eventAddress){
              data.append(`listJobEvent[${i}].eventAddress`, item.eventAddress)
            }
          })
        }
      }else{
        data.append(`listImage[${index}].isCover`, name.isCover );
        if(name.file !== undefined){
          data.append(`listImage[${index}].file`, name.file );
        }
        if(name.fileName !== undefined){
          data.append(`listImage[${index}].fileName`, name.fileName );
          data.append(`listImage[${index}].fileExtension`, name.fileExtension );
          data.append(`listImage[${index}].fileSize`, name.fileSize );
        }
      }
    }
    axios.post(`${env}${path}`, data, {
      headers: {
        'Content-Type' : 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
        'Cache-Control': 'no-cache',
        'X-Authorization': `Bearer ${getToken}`
      },
    }).then((response) => {
      if(response.status === 200){
        return resolve(response.data);
      }else{
        return reject();
      }
    }).catch((err) => {
      return reject(err.response.data.message);
    })
  }) 
}