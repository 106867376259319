import { API } from '../../../config';
import { errorHandler } from '../auth/errorAction';

export const unmountListJobs = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_JOBS'})
}

export const unmountListJobsApplied = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_JOBS_APPLIED_LIST_APPLICANT'})
}

export const unmountListApplicant = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_JOBS_LIST_APPLICANT'})
}

export const unmountListRejected = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_JOBS_LIST_REJECTED'})
}

export const unmountListDevelopmentApplicant = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_JOBS_LIST_DEVELOPMENT_APPLICANT'})
}

export const unmountListInjectCandidate = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_JOBS_INJECT_CANDIDATE'})
}

export const unmountDetailJobs = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_JOBS'})
}

export const unmountDetailJobsActiveApplicant = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_ACTIVE_APPLICANT_JOBS'})
}

export const unmountDetailJobsAssessment = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_ASSESSMENT_JOBS'})
}

export const unmountDetailJobsInterview = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_INTERVIEW_JOBS'})
}

export const unmountDetailJobsDeparture = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_DEPARTURE_JOBS'})
}

export const unmountDetailJobsDevelopment = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_DEVELOPMENT_APPLICANT_JOBS'})
}

export const unmountMasterJobs = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_JOBS'})
}

export const unmountCandidateByEmail = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_JOBS_CANDIDATE_BY_EMAIL'})
}

export const unmountCandidateByPhone = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_JOBS_CANDIDATE_BY_PHONE'})
}

export const unmountApplicantNotes = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_APPLICANT_NOTES'})
}

export const listJobs = (meta, url) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_JOBS' })
  return API.GET(`/${url}/list`, meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_JOBS_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_JOBS_FAILED' }), 
    ))
  })
}

export const listJobsApplied = (meta, url) => async (dispatch) => {
  await dispatch({ type: 'LOAD_JOBS_APPLIED_LIST_APPLICANT' })
  return API.GET(`/${url}/list_applied`, meta).then((response) => {
    dispatch({
      type: 'LOAD_JOBS_APPLIED_LIST_APPLICANT_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_JOBS_APPLIED_LIST_APPLICANT_FAILED' }), 
    ))
  })
}

export const listApplicant = (meta, url, successCB) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_JOBS_LIST_APPLICANT' })
  return API.GET(`/${url}/list_phase_applicant`, meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_JOBS_LIST_APPLICANT_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
    return successCB && successCB(response)
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_JOBS_LIST_APPLICANT_FAILED' }), 
    ))
  })
}

export const listRejected = (meta, url, successCB) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_JOBS_LIST_REJECTED' })
  return API.GET(`/${url}/list_reject`, meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_JOBS_LIST_REJECTED_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
    return successCB && successCB(response)
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_JOBS_LIST_REJECTED_FAILED' }), 
    ))
  })
}

export const listInjectCandidate = (meta, url, successCB) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_JOBS_INJECT_CANDIDATE' })
  return API.GET(`/${url}/list_inject_candidate`, meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_JOBS_INJECT_CANDIDATE_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
    return successCB && successCB(response)
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_JOBS_INJECT_CANDIDATE_FAILED' }), 
    ))
  })
}

export const listDevelopmentApplicant = (meta, url, successCB) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_JOBS_LIST_DEVELOPMENT_APPLICANT' })
  return API.GET(`/${url}/list_development`, meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_JOBS_LIST_DEVELOPMENT_APPLICANT_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
    return successCB && successCB(response)
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_JOBS_LIST_DEVELOPMENT_APPLICANT_FAILED' }), 
    ))
  })
}

export const detailJob = (id, url, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DETAIL_JOBS' })
  const params = {
    'id': id
  }
  API.GET(`/${url}/getById`, params).then((response) => {
    dispatch({ 
      type: 'LOAD_DETAIL_JOBS_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DETAIL_JOBS_FAILED' }), 
      ))
    )
  })
}

export const detailApplicant = (id, phaseId, url, successCB, failedCB) => async dispatch => {
  const params = {
    'id': id,
    'phaseId': phaseId
  }
  API.GET(`/${url}/getApplicantById`, params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const detailJobActiveApplicant = (id, url, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DETAIL_ACTIVE_APPLICANT_JOBS' })
  const params = {
    'id': id
  }
  API.GET(`/${url}/getApplicant`, params).then((response) => {
    dispatch({ 
      type: 'LOAD_DETAIL_ACTIVE_APPLICANT_JOBS_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DETAIL_ACTIVE_APPLICANT_JOBS_FAILED' }), 
      ))
    )
  })
}

export const detailJobAssessment = (id, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DETAIL_ASSESSMENT_JOBS' })
  const params = {
    'id': id
  }
  API.GET('/job_assessment/getById', params).then((response) => {
    dispatch({ 
      type: 'LOAD_DETAIL_ASSESSMENT_JOBS_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DETAIL_ASSESSMENT_JOBS_FAILED' }), 
      ))
    )
  })
}

export const detailJobInterview = (id, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DETAIL_INTERVIEW_JOBS' })
  const params = {
    'id': id
  }
  API.GET('/job-interview/getById', params).then((response) => {
    dispatch({ 
      type: 'LOAD_DETAIL_INTERVIEW_JOBS_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DETAIL_INTERVIEW_JOBS_FAILED' }), 
      ))
    )
  })
}

export const detailJobDeparture = (id, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DETAIL_DEPARTURE_JOBS' })
  const params = {
    'id': id
  }
  API.GET('/job_departure/getById', params).then((response) => {
    dispatch({ 
      type: 'LOAD_DETAIL_DEPARTURE_JOBS_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DETAIL_DEPARTURE_JOBS_FAILED' }), 
      ))
    )
  })
}

export const detailJobDevelopment = (id, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DETAIL_DEVELOPMENT_APPLICANT_JOBS' })
  const params = {
    'id': id
  }
  API.GET('/job_development/getById', params).then((response) => {
    dispatch({ 
      type: 'LOAD_DETAIL_DEVELOPMENT_APPLICANT_JOBS_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DETAIL_DEVELOPMENT_APPLICANT_JOBS_FAILED' }), 
      ))
    )
  })
}

export const masterClient = (id, url, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DATA_MASTER_CLIENTS' })
  const params = {
    'id': id
  }
  API.GET(`/${url}/client`, params).then((response) => {
    dispatch({ 
      type: 'LOAD_DATA_MASTER_CLIENTS_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DATA_MASTER_CLIENTS_FAILED' }), 
      ))
    )
  })
}

export const createJob = (value, url, successCB, failedCB) => () => {
  API.POST_FORM_DATA_ARRAY(`/${url}/add`, value, 'createJob').then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const saveJob = (value, url, successCB, failedCB) => () => {
  API.POST_FORM_DATA_ARRAY(`/${url}/add_draft`, value, 'createJob').then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const editJob = (value, url, successCB, failedCB) => () => {
  API.POST_FORM_DATA_ARRAY(`/${url}/update`, value, 'createJob').then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const editSaveJob = (value, url, successCB, failedCB) => () => {
  API.POST_FORM_DATA_ARRAY(`/${url}/update_draft`, value, 'createJob').then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const activeJob = (value, url, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS(`/${url}/active`, params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const inactiveJob = (value, url, successCB, failedCB) => () => {
  const params = {
    id: value,
  }
  API.POST_WITH_PARAMS(`/${url}/inactive`, params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const nextPhase = (id, url, successCB, failedCB) => () => {
  const params = {
    id: id,
    phaseId: ''
  }
  API.POST_WITH_PARAMS(`/${url}/next_phase`, params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const rejectApplicant = (value, url, successCB, failedCB) => () => {
  API.POST_NO_PARAMS(`/${url}/reject_applicant`, value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const approveJob = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/job_admin/approve', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const rejectJob = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/job_admin/reject', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const addAssessment = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/job_assessment/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const editAssessment = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/job_assessment/update', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const addDevelopmentApplicant = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/job_development/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const editDevelopment = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/job_development/update', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const addQualified = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/job_qualified/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const editQualified = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/job_qualified/update', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const addInterview = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/job-interview/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const editInterview = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/job-interview/update', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const editDeparture = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/job_departure/update', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const addOffer = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/job-offer/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const addHired = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/job-hired/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const addDevelopment = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS(`/job_development/add`, value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const addDeparture = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/job_departure/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const activeApplicant = (value, url, successCB, failedCB) => () => {
  const params = {
    id: [value]
  }
  API.POST_BODY_PARAMS(`/${url}/active_applicant`, params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const cancelApplicant = (value, url, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS(`/${url}/cancel_applicant`, params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const candidateByEmail = (email, url, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DATA_JOBS_CANDIDATE_BY_EMAIL' })
  const params = {
    'email': email
  }
  return API.GET(`/${url}/checkEmail`, params).then((response) => {
    dispatch({ 
      type: 'LOAD_DATA_JOBS_CANDIDATE_BY_EMAIL_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DATA_JOBS_CANDIDATE_BY_EMAIL_FAILED' }), 
      ))
    )
  })
}

export const candidateByPhone = (mobile, url, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DATA_JOBS_CANDIDATE_BY_PHONE' })
  const params = {
    'mobile': mobile
  }
  return API.GET(`/${url}/checkMobile`, params).then((response) => {
    dispatch({ 
      type: 'LOAD_DATA_JOBS_CANDIDATE_BY_PHONE_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DATA_JOBS_CANDIDATE_BY_PHONE_FAILED' }), 
      ))
    )
  })
}

export const injectCandidate = (value, url, successCB, failedCB) => async dispatch => {
  API.POST_FORM_DATA(`/${url}/inject_candidate`, value).then((response) => {
    return successCB && successCB(response)
  })
  .catch((err) => {
    return failedCB && failedCB(err.error || err.message)
  })
}

export const addApplicantNotes = (value, url, successCB, failedCB) => () => {
  API.POST_WITH_PARAMS('/applicant_notes/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const listApplicantNotes = (jobApplied, url, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DATA_APPLICANT_NOTES' })
  const params = {
    'jobApplied': jobApplied
  }
  return API.GET('/applicant_notes/getListByJobApplied', params).then((response) => {
    dispatch({ 
      type: 'LOAD_DATA_APPLICANT_NOTES_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DATA_APPLICANT_NOTES_FAILED' }), 
      ))
    )
  })
}