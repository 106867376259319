import React from 'react'
import { Card, Space, Row, Col, Typography, Image, } from 'antd';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import CorrectPasport from '../../assets/img/paspor benar.jpg';
import IncorrectPasport from '../../assets/img/Paspor salah.jpg';

const { Text } = Typography;


export default function InfoPasport() {
    return (
        <Card className='border-radius-6' >
            <Row>
                <Col span={24}>
                    <Row align="middle">
                        <Col className='text-align-center' span={7}>
                            <Space direction="vertical" size={16} align='center'>
                                <CloseCircleFilled style={{fontSize: 25, color:'red'}} />
                                <Image src={IncorrectPasport} width="100%" height={205} preview={false} style={{ objectFit: 'contain' }} />
                            </Space>
                        </Col>
                        <Col className='text-align-center' span={7}>
                            <Space direction="vertical" size={16} align='center'>
                                <CheckCircleFilled style={{fontSize: 25, color:'green'}} />
                                <Image src={CorrectPasport} width="100%" height={210} preview={false} style={{ objectFit: 'contain' }} />
                            </Space>
                        </Col>
                        <Col span={10} >
                            <Space direction="vertical" size={0}>
                                <Text type="secondary">Persyaratan Dokumen : </Text>
                                <Text type="secondary">• Dokumen dalam format <Text className='dark-color' strong>PDF</Text></Text>
                                <Text type="secondary">• Dokumen tidak terhalang objek, seperti tangan</Text>
                                <Text type="secondary">• Dokumen terlihat jelas</Text>
                            </Space>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    )
}
