import React, { Component } from 'react';
import moment from 'moment';
import AddUniform from './Add';
import EditUniform from './Edit';
import svg from '../../../../assets/img/profile.svg';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { updateCandidateUniform , detailCandidate, unmountDetailCandidate, enableCandidate } from '../../../../redux/actions/candidate/candidateAction';
import { Skeleton, Row, Col, Breadcrumb, PageHeader, Card, Button, Space, Avatar, Typography, Descriptions, Divider, Modal, Tag, message } from 'antd';
import { EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Menu from '../Menu';
const { Text } = Typography;
const { confirm } = Modal;
// const { Option } = Select;
const Icon = ({ type, ...rest }) => {
  const icons = require(`@ant-design/icons`);
  const Component = icons[type];
  return <Component {...rest} />
};
class CandidateDetailUniform extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      dataUniform: null,
      submitLoading: false,
      visibleAdd: false,
      visibleEdit: false,
    }
  }

  componentDidMount() {
    const { actionGetDetail, match: { params } } = this.props;
    return actionGetDetail(params.id)
  }

  showModal = (type, item) => {
    if(type === 'add'){
      return this.setState({ visibleAdd: true })
    }
    if(type === 'edit'){
      return this.setState({ 
        visibleEdit: true, 
        dataUniform: item, 
      })
    }
  }

  onCancel = (type) => {
    if(type === 'add'){
      return this.setState({ visibleAdd: false })
    }
    if(type === 'edit'){
      return this.setState({ 
        dataUniform: null,
        visibleEdit: false,
      })
    }
  }

  handleDelete = (id) => {
    const { actionDelete, actionGetDetail, match: { params } } = this.props;
    confirm({
      title: 'Remove Data',
      okText: 'Yes',
      cancelText: 'No',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to remove this data?',
      onOk() {
        return actionDelete(id, () => {
          message.success('Data deleted successfully')
          return actionGetDetail(params.id)
        }, (err) => message.error(err))
      },
    });
  }

  onSubmitAdd = () => {
    const { actionAdd, actionGetDetail, match: { params } } = this.props;
    this.formRef.current.validateFields().then(values => {
      values.candidateId = Number(params.id)
      values.year = values.year ? moment(values.year, 'YYYY').format('YYYY') : null
      return actionAdd(values, () => {
        return this.setState({ submitLoading: false }, () => {
          return this.setState({ visibleAdd: false }, () => {
            message.success('Data created successfully')
            actionGetDetail(params.id)
          })
        })
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err))
      })
    })
  }

  onSubmitEdit = () => {
    const { actionUpdate, actionGetDetail, match: { params } } = this.props;
    this.setState({ submitLoading: true })
    this.formRef.current.validateFields().then(values => {
      values.id = params.id
      return actionUpdate(values, () => {
        return this.setState({ submitLoading: false }, () => {
          return this.setState({ visibleEdit: false }, () => {
            message.success('Data updated successfully')
            actionGetDetail(params.id)
          })
        })
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err))
      })
    })
  }
  
  showConfirmActive = () => {
    const { actionEnable, actionGetDetail ,match: { params } } = this.props;
    confirm({
      title: 'Unable to edit profile due to candidate status is inactive. Do you want to change candidate status to active?',
      icon: <ExclamationCircleOutlined />,
      width: 700,
      onOk() {
        return actionEnable(params.id, () => {
          message.success('Successfully changed to active')
          return actionGetDetail(params.id)
        }, (err) => message.error(err))
      },
      okText: 'Change to Active',
      onCancel() {},
    });
  };

  render() {
    const { visibleAdd, visibleEdit, submitLoading ,} = this.state;
    const { getDetailCandidate: { loading, data } } = this.props;
    if(loading){
      return <Skeleton avatar />
    }
    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Icon type={this.props.icon} />
                {
                  this.props.url.split('/')[1] === 'all-candidate' ?
                    ' All Candidates'
                  : this.props.url.split('/')[1] === 'new-candidate' ?
                    ' New Candidates'
                  : this.props.url.split('/')[1] === 'counseling-process' ?
                    ' Counseling Process'
                  : this.props.url.split('/')[1] === 'assigned-candidate' ?
                    ' Assigned Candidates'
                  :
                    ' Candidates'
                }
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to={`/${this.props.url.split('/')[1]}`}>
                  {
                    this.props.url.split('/')[1] === 'all-candidate' ?
                      'All Candidates List'
                    : this.props.url.split('/')[1] === 'new-candidate' ?
                      'New Candidates List'
                    : this.props.url.split('/')[1] === 'counseling-process' ?
                      'Counseling Process List'
                    : this.props.url.split('/')[1] === 'assigned-candidate' ?
                      'Assigned Candidates List'
                    :
                      'Candidates List'
                  }
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Candidates Detail</Breadcrumb.Item>
              <Breadcrumb.Item>
                {data.fullName}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Candidate Detail' />
          </Col>
          {/* Menu */}
          <Col span={7}>
            <Card style={{ border: 'none' }}>
              <Row gutter={[16, 24]}>
                <Col span={24}>
                  <Space direction="horizontal">
                    <Avatar size={100} src={data.profilePicUrl ? data.profilePicUrl : svg} />
                    <Space direction="vertical" size="small">
                      <Text style={{ color: '#4d4d4d', fontSize: 18 }} strong>{data.fullName}</Text>
                      <Text type="secondary">{data.candidateNumber}</Text>
                      {
                        data.enabled ? 
                          <Tag color="success">Active</Tag>
                        :
                          <Tag color="error">Inactive</Tag>
                      }
                    </Space>
                  </Space>
                </Col>
                <Col span={24}>
                  <Menu rowKey="uniform" id={data.id} email={data.email} />
                </Col>
              </Row>
            </Card>
          </Col>
          {/* Content */}
          <Col span={17}>
            <Card 
              style={{ border: 'none' }}
              title={<Text strong>Uniform Detail</Text>} 
              extra={<Button key="1" className="btn-save-primary" type="primary" icon={<EditOutlined />} onClick={data.enabled ? () => this.showModal('edit') : this.showConfirmActive}>Edit</Button>} 
            >
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <React.Fragment>
                    <Descriptions layout="vertical" size="small" column={2}>
                    <Descriptions.Item label="Shirt Size">
                      {data.shirt ? data.shirt : 'N/A'} 
                    </Descriptions.Item>
                    <Descriptions.Item label="Pant Size">
                      {data.pants ? data.pants : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Shoes Size">
                      {data.shoes ? data.shoes : 'N/A'}
                    </Descriptions.Item>
                  </Descriptions>
                    <Divider />
                  </React.Fragment>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal
          key="1"
          width={1000}
          destroyOnClose
          visible={visibleAdd}
          onCancel={() => this.onCancel('add')}
          title={<Text strong>Add Uniform</Text>}
          footer={[
            <React.Fragment key="1">
              <Button key="back" className="btn-save-primary" onClick={() => this.onCancel('add')}>Cancel</Button>
              <Button key="submit" className="btn-save-primary" type="primary" onClick={this.onSubmitAdd} loading={submitLoading}>Save</Button>
            </React.Fragment>
          ]}
        >
          <AddUniform 
            {...this.props} 
            {...this.state}
            formRef={this.formRef} 
            onSubmitAdd={this.onSubmitAdd} 
          />
        </Modal>
        <Modal
          key="2"
          centered
          width={1000}
          destroyOnClose
          visible={visibleEdit}
          onCancel={() => this.onCancel('edit')}
          title={<Text strong>Edit Uniform</Text>}
          footer={[
            <React.Fragment key="1">
              <Button key="back" className="btn-save-primary" onClick={() => this.onCancel('edit')}>Cancel</Button>
              <Button key="submit" className="btn-save-primary" type="primary" onClick={this.onSubmitEdit}>Save</Button>
            </React.Fragment>
          ]}
        >
          <EditUniform 
            {...this.props} 
            {...this.state}
            formRef={this.formRef} 
            onSubmitEdit={this.onSubmitEdit} 
          />
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail } = this.props;
    unmountGetDetail()
  }
}

const mapStateToProps = (state) => ({
  getDetailCandidate  : state.candidate.detail,
})

const mapDispatchToProps = {
  actionUpdate      : updateCandidateUniform,
  actionGetDetail   : detailCandidate,
  actionEnable      : enableCandidate,
  unmountGetDetail  : unmountDetailCandidate,
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateDetailUniform)
