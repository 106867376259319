import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Tag, Tooltip } from 'antd';
import { CheckCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import { paymentMethod } from '../../../utils/constant/eventPaymentMethod';
import { statusTransaction } from '../../../utils/constant/statusTransaction';
const { Text } = Typography;

export const columns = (handleRoute, current, showRejectReason) => {
  switch (current) {
    case "PAYMENT_CONFIRM":
      return [
        {
          title: 'Order Number',
          key: 'xpertTransactionNumber',
          fixed: 'left',
          width: 110,
          render: (record) => (
            <Link className="link" to={`/transaction/detail/${record.id}`}>
              {record.xpertTransactionNumber}
            </Link>
          )
        },
        {
          title: 'Order Date',
          key: 'orderDateString',
          width: 100,
          render: (record) => (
            <Text onClick={() => handleRoute(record)}>
              {record.orderDate ? record.orderDate : 'N/A'}
            </Text>
          )
        },
        {
          title: 'Xpert Title',
          key: 'xpertName',
          width: 150,
          render: (record) => (
              <Text onClick={() => handleRoute(record)}>
                {record.xpertName ? record.xpertName : 'N/A'}
              </Text>
          )
        },
        {
          title: 'Provider',
          key: 'provider',
          width: 100,
          render: (record) => (
            <Text onClick={() => handleRoute(record)}>
              {record.provider ? record.provider : 'N/A'}
            </Text>
          )
        },
        {
          title: 'Total',
          key: 'totalTransaction',
          width: 100,
          ellipsis: {
            showTitle: false,
          },
          render: (record) => (
            <Text onClick={() => handleRoute(record)}>
              {
                record.totalTransaction ? 
                  `IDR ${record.totalTransaction.toLocaleString()}`
                : 'FREE'
              }
            </Text>
          )
        },
        {
          title: 'Verification Date',
          key: 'verificationDate',
          width: 100,
          render: (record) => (
            <Text onClick={() => handleRoute(record)}>
              {record.verificationDate ? record.verificationDate : 'N/A'}
            </Text>
          )
        },
        {
          title: 'Status',
          key: 'status',
          width: 50,
          render: (record) => (
            <React.Fragment>
              {
                record.status ?
                  <Tag color={statusTransaction.find(item => item.value === record.status)?.color}>
                    <Text style={{ color: statusTransaction.find(item => item.value === record.status)?.textColor }}>
                      {statusTransaction.find(item => item.value === record.status)?.name}
                    </Text>
                  </Tag>
                : 'N/A'
              }
            </React.Fragment>
          )
        },
        {
          title: '',
          key: 'action',
          width: 30,
          align: 'right',
          fixed: 'right',
          render: (record) => (
            <React.Fragment>
              {
                record.status === 'WAITING_FOR_CONFIRM' ?
                  <Tooltip placement="top" title={'Verify'} onClick={() => handleRoute(record)}>
                    <CheckCircleFilled style={{ fontSize: 20, color: '#32ab6d' }} />
                  </Tooltip>
                :
                  <Tooltip placement="top" title={'Detail'} onClick={() => handleRoute(record)}>
                    <InfoCircleOutlined style={{ fontSize: 20, color: '#0144b0' }} />
                  </Tooltip>
              }
            </React.Fragment>
          )
        }
      ]
    case "CANCELED":
      return [
        {
          title: 'Order Number',
          key: 'xpertTransactionNumber',
          fixed: 'left',
          width: 110,
          render: (record) => (
            <Link className="link" to={`/transaction/detail/${record.id}`}>
              {record.xpertTransactionNumber}
            </Link>
          )
        },
        {
          title: 'Order Date',
          key: 'orderDateString',
          width: 100,
          render: (record) => (
            <Text onClick={() => handleRoute(record)}>
              {record.orderDate ? record.orderDate : 'N/A'}
            </Text>
          )
        },
        {
          title: 'Cancellation Date',
          key: 'cancellationDate',
          width: 100,
          render: (record) => (
            <Text onClick={() => handleRoute(record)}>
              {record.cancelDate ? record.cancelDate : 'N/A'}
            </Text>
          )
        },
        // {
        //   title: 'Cancel By',
        //   key: 'cancelBy',
        //   width: 100,
        //   render: (record) => (
        //     <Text onClick={() => handleRoute(record)}>
        //       N/A
        //     </Text>
        //   )
        // },
        // {
        //   title: 'Last Status',
        //   key: 'lastStatus',
        //   width: 100,
        //   render: (record) => (
        //     <Text onClick={() => handleRoute(record)}>
        //       N/A
        //     </Text>
        //   )
        // },
        {
          title: 'Xpert Title',
          key: 'xpertName',
          width: 150,
          render: (record) => (
              <Text onClick={() => handleRoute(record)}>
                {record.xpertName ? record.xpertName : 'N/A'}
              </Text>
          )
        },
        {
          title: 'Status',
          key: 'status',
          width: 50,
          render: (record) => (
            <React.Fragment>
              {
                record.status ?
                  <Tag color={statusTransaction.find(item => item.value === record.status)?.color}>
                    <Text style={{ color: statusTransaction.find(item => item.value === record.status)?.textColor }}>
                      {statusTransaction.find(item => item.value === record.status)?.name}
                    </Text>
                  </Tag>
                : 'N/A'
              }
            </React.Fragment>
          )
        },
        {
          title: '',
          key: 'action',
          width: 30,
          align: 'right',
          fixed: 'right',
          render: (record) => (
            <React.Fragment>
              {
                record.status === 'WAITING_FOR_CONFIRM' ?
                  <Tooltip placement="top" title={'Verify'} onClick={() => handleRoute(record)}>
                    <CheckCircleFilled style={{ fontSize: 20, color: '#32ab6d' }} />
                  </Tooltip>
                :
                  <Tooltip placement="top" title={'Detail'} onClick={() => handleRoute(record)}>
                    <InfoCircleOutlined style={{ fontSize: 20, color: '#0144b0' }} />
                  </Tooltip>
              }
            </React.Fragment>
          )
        }
      ]
    case "REJECTED":
      return [
        {
          title: 'Order Number',
          key: 'xpertTransactionNumber',
          fixed: 'left',
          width: 110,
          render: (record) => (
            <Link className="link" to={`/transaction/detail/${record.id}`}>
              {record.xpertTransactionNumber ? record.xpertTransactionNumber : 'N/A'}
            </Link>
          )
        },
        {
          title: 'Order Date',
          key: 'orderDate',
          width: 100,
          render: (record) => (
            <Text onClick={() => handleRoute(record)}>
              {record.orderDate ? record.orderDate : 'N/A'}
            </Text>
          )
        },
        {
          title: 'Rejection Date',
          key: 'rejectDate',
          width: 100,
          render: (record) => (
            <Text onClick={() => handleRoute(record)}>
              {record.rejectDate ? record.rejectDate : 'N/A'}
            </Text>
          )
        },
        {
          title: 'Xpert Title',
          key: 'xpertName',
          width: 150,
          render: (record) => (
              <Text onClick={() => handleRoute(record)}>
                {record.xpertName ? record.xpertName : 'N/A'}
              </Text>
          )
        },
        {
          title: 'Provider',
          key: 'provider',
          width: 100,
          render: (record) => (
            <Text onClick={() => handleRoute(record)}>
              {record.provider ? record.provider : 'N/A'}
            </Text>
          )
        },
        {
          title: 'Total',
          key: 'totalTransaction',
          width: 100,
          ellipsis: {
            showTitle: false,
          },
          render: (record) => (
            <Text onClick={() => handleRoute(record)}>
              {
                record.totalTransaction ? 
                  `IDR ${record.totalTransaction.toLocaleString()}`
                : 'FREE'
              }
            </Text>
          )
        },
        {
          title: 'Status',
          key: 'status',
          width: 50,
          render: (record) => (
            <React.Fragment>
              {
                record.status ?
                  <Text onClick={() => showRejectReason(record.rejectNotes)} style={{ color: '#dd515c' }}>
                    {statusTransaction.find(item => item.value === record.status)?.name} <InfoCircleOutlined />
                  </Text>
                : 'N/A'
              }
            </React.Fragment>
          )
        },
        {
          title: '',
          key: 'action',
          width: 30,
          align: 'right',
          fixed: 'right',
          render: (record) => (
            <React.Fragment>
              {
                record.status === 'WAITING_FOR_CONFIRM' ?
                  <Tooltip placement="top" title={'Verify'} onClick={() => handleRoute(record)}>
                    <CheckCircleFilled style={{ fontSize: 20, color: '#32ab6d' }} />
                  </Tooltip>
                :
                  <Tooltip placement="top" title={'Detail'} onClick={() => handleRoute(record)}>
                    <InfoCircleOutlined style={{ fontSize: 20, color: '#0144b0' }} />
                  </Tooltip>
              }
            </React.Fragment>
          )
        }
      ]
    case "EXPIRED":
      return [
        {
          title: 'Order Number',
          key: 'xpertTransactionNumber',
          fixed: 'left',
          width: 110,
          render: (record) => (
            <Link className="link" to={`/transaction/detail/${record.id}`}>
              {record.xpertTransactionNumber}
            </Link>
          )
        },
        {
          title: 'Order Date',
          key: 'orderDateString',
          width: 100,
          render: (record) => (
            <Text onClick={() => handleRoute(record)}>
              {record.orderDate ? record.orderDate : 'N/A'}
            </Text>
          )
        },
        {
          title: 'Expired Date',
          key: 'expiredDate',
          width: 100,
          render: (record) => (
            <Text onClick={() => handleRoute(record)}>
              {record.expiredDate}
            </Text>
          )
        },
        {
          title: 'Xpert Title',
          key: 'xpertName',
          width: 150,
          render: (record) => (
              <Text onClick={() => handleRoute(record)}>
                {record.xpertName ? record.xpertName : 'N/A'}
              </Text>
          )
        },
        {
          title: 'Provider',
          key: 'provider',
          width: 100,
          render: (record) => (
            <Text onClick={() => handleRoute(record)}>
              {record.provider ? record.provider : 'N/A'}
            </Text>
          )
        },
        {
          title: 'Total',
          key: 'totalTransaction',
          width: 100,
          ellipsis: {
            showTitle: false,
          },
          render: (record) => (
            <Text onClick={() => handleRoute(record)}>
              {
                record.totalTransaction ? 
                  `IDR ${record.totalTransaction.toLocaleString()}`
                : 'FREE'
              }
            </Text>
          )
        },
        {
          title: 'Status',
          key: 'status',
          width: 50,
          render: (record) => (
            <React.Fragment>
              {
                record.status ?
                  <Tag color={statusTransaction.find(item => item.value === record.status)?.color}>
                    <Text style={{ color: statusTransaction.find(item => item.value === record.status)?.textColor }}>
                      {statusTransaction.find(item => item.value === record.status)?.name}
                    </Text>
                  </Tag>
                : 'N/A'
              }
            </React.Fragment>
          )
        },
        {
          title: '',
          key: 'action',
          width: 30,
          align: 'right',
          fixed: 'right',
          render: (record) => (
            <React.Fragment>
              {
                record.status === 'WAITING_FOR_CONFIRM' ?
                  <Tooltip placement="top" title={'Verify'} onClick={() => handleRoute(record)}>
                    <CheckCircleFilled style={{ fontSize: 20, color: '#32ab6d' }} />
                  </Tooltip>
                :
                  <Tooltip placement="top" title={'Detail'} onClick={() => handleRoute(record)}>
                    <InfoCircleOutlined style={{ fontSize: 20, color: '#0144b0' }} />
                  </Tooltip>
              }
            </React.Fragment>
          )
        }
      ]
    default:
      return [
        {
          title: 'Order Number',
          key: 'xpertTransactionNumber',
          fixed: 'left',
          width: 110,
          render: (record) => (
            <Link className="link" to={`/transaction/detail/${record.id}`}>
              {record.xpertTransactionNumber}
            </Link>
          )
        },
        {
          title: 'Order Date',
          key: 'orderDateString',
          width: 100,
          render: (record) => (
            <Text onClick={() => handleRoute(record)}>
              {record.orderDate ? record.orderDate : 'N/A'}
            </Text>
          )
        },
        {
          title: 'Xpert Title',
          key: 'xpertName',
          width: 150,
          render: (record) => (
              <Text onClick={() => handleRoute(record)}>
                {record.xpertName ? record.xpertName : 'N/A'}
              </Text>
          )
        },
        {
          title: 'Provider',
          key: 'provider',
          width: 100,
          render: (record) => (
            <Text onClick={() => handleRoute(record)}>
              {record.provider ? record.provider : 'N/A'}
            </Text>
          )
        },
        {
          title: 'Payment Method',
          key: 'paymentMethod',
          width: 100,
          render: (record) => (
            <Text onClick={() => handleRoute(record)}>
              {
                record.paymentType ?
                  <React.Fragment>
                    {paymentMethod.find(item => item.value === record.paymentType)?.name}
                  </React.Fragment>
                : 'N/A'
              }
            </Text>
          )
        },
        {
          title: 'Total',
          key: 'totalTransaction',
          width: 100,
          ellipsis: {
            showTitle: false,
          },
          render: (record) => (
            <Text onClick={() => handleRoute(record)}>
              {
                record.totalTransaction ? 
                  `IDR ${record.totalTransaction.toLocaleString()}`
                : 'FREE'
              }
            </Text>
          )
        },
        {
          title: 'Status',
          key: 'status',
          width: 60,
          render: (record) => (
            <React.Fragment>
              {
                record.status ?
                  <Tag color={statusTransaction.find(item => item.value === record.status)?.color}>
                    <Text style={{ color: statusTransaction.find(item => item.value === record.status)?.textColor }}>
                      {statusTransaction.find(item => item.value === record.status)?.name}
                    </Text>
                  </Tag>
                : 'N/A'
              }
            </React.Fragment>
          )
        },
        {
          title: '',
          key: 'action',
          width: 30,
          align: 'right',
          fixed: 'right',
          render: (record) => (
            <React.Fragment>
              {
                record.status === 'WAITING_FOR_CONFIRM' ?
                  <Tooltip placement="top" title={'Verify'} onClick={() => handleRoute(record)}>
                    <CheckCircleFilled style={{ fontSize: 20, color: '#32ab6d' }} />
                  </Tooltip>
                :
                  <Tooltip placement="top" title={'Detail'} onClick={() => handleRoute(record)}>
                    <InfoCircleOutlined style={{ fontSize: 20, color: '#0144b0' }} />
                  </Tooltip>
              }
            </React.Fragment>
          )
        }
      ]
    }
}