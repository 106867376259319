import React from "react";
import moment from "moment";
import { Loading } from '../../../../../components';
import { CalendarOutlined, EditOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Card, Col, Empty, Row, Space, Switch, Typography } from "antd"
const { Text } = Typography;

const DetailTicket = ({ dataTicket, showModal, handleSwitch }) => {
  if(dataTicket.loading){
    return <Loading />
  }

  return(
    <Card
      style={{ border: 'none' }}
      bordered={false}
      title={<Text strong>Tickets</Text>} 
      extra={<Button key="1" style={{ width: 90 }} className="btn-color-blue" type="primary" onClick={() => showModal('add')}>Add</Button>} 
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          {
            dataTicket.data ?
              dataTicket.data.map((item, i) => (
                <Card key={i} bordered={false} style={{ border: 'none', backgroundColor: '#d4dfef', marginBottom: 20 }}>
                  <Row justify="space-around" align="middle" style={{ marginBottom: 16 }}>
                    <Col span={24}>
                      <Text strong={true} style={{ fontSize: 16 }}>{item.name ? item.name : 'N/A'}</Text>
                    </Col>
                  </Row>
                  <Row gutter={[16, 8]} justify="space-around" align="middle">
                    <Col span={8}>
                      <Space direction="vertical">
                        <Space size={'small'}>
                          <CalendarOutlined />
                          <Text type="secondary" style={{ fontSize: 13 }}>{moment(`${item.date}`, 'DD/MM/YYYY').format("DD MMMM YYYY")}</Text>
                        </Space>
                        {
                          item.totalQuota ? 
                            <Space size={'small'}>
                              <UserOutlined /> 
                              <Text type="secondary" style={{ fontSize: 13 }}>{`${item.totalQuota} People`}</Text>
                            </Space>
                          : null
                        }
                      </Space>
                    </Col>
                    <Col span={6}>
                      <Space direction="vertical">
                        <Text type="secondary">{`Sold : ${item.ticketSold || 0}`}</Text>
                      </Space>
                    </Col>
                    <Col span={6}>
                      <Space direction="vertical" size={0}>
                        {
                          item.discountedPrice ?
                          <>
                            <Text type="secondary" strong delete>{`IDR ${item.price.toLocaleString()}`}</Text>
                            <Text strong>{`IDR ${item.discountedPrice.toLocaleString()}`}</Text>
                          </>
                        : item.price ?
                          <Text strong>{`IDR ${item.price.toLocaleString()}`}</Text>
                        :
                          <Text strong>FREE</Text>
                        }
                      </Space>
                    </Col>
                    <Col span={4} style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Switch size="small" checked={item.enabled} onChange={(check) => handleSwitch(check, item.id)} />
                      <EditOutlined style={{ fontSize: 20, cursor: 'pointer', color: '#32ab6d' }} onClick={() => showModal('edit', item)} />
                    </Col>
                  </Row>
                </Card>
              ))
            :
            <React.Fragment>
              <Empty description={<Text>No Data Ticket</Text>} />
            </React.Fragment>
          }
        </Col>
      </Row>
    </Card>
  )
}

export default DetailTicket;