import { API } from '../../../config';
import { errorHandler } from '../auth/errorAction';

export const unmountListCounselingProcess = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_COUNSELING_PROCESS'})
}

export const unmountDetailCandidate = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_CANDIDATE'})
}

export const listCounselingProcess = (meta) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_COUNSELING_PROCESS' })
  return API.GET(`/candidate/counseling/list`, meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_COUNSELING_PROCESS_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_COUNSELING_PROCESS_FAILED' }), 
    ))
  })
}

export const createNotes = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS(`/candidate/notes/add`, value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updateStatusCounseling = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS(`/candidate/counseling/update_status`, value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const assignToJob = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS(`/candidate/assign_job/add`, value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const exportData = (meta, successCB, failedCB) => () => {
  return API.GET_DOWNLOAD(`/candidate/counseling/report/print`, meta).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updateStatusByFilter = (params, successCB, failedCB) => () => {
  API.POST_WITH_PARAMS('/candidate/counseling/update_status_filter', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const assignToJobByFilter = (body, params, successCB, failedCB) => () => {
  API.POST_BODY_PARAMS('/candidate/assign_job/add_filter', body, params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const exportDataByFilter = (meta, successCB, failedCB) => () => {
  return API.GET_DOWNLOAD('/candidate/counseling/report_filter/print', meta).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}