import React from 'react';
// import InfiniteScroll from 'react-infinite-scroller';
import { UserOutlined } from '@ant-design/icons';
import { List, Row, Col, Typography, Avatar } from 'antd';
const { Text } = Typography;
export default function ListApplicant(props) {
  const { loading, pagination, idApplicant, selectedApplicant, dataSource, type, handlePagination } = props;
  return (
    <React.Fragment>
      {
        type === "shortlisted" &&
        // <div className="demo-infinite-container">
        //   <InfiniteScroll hasMore={false} loadMore={handleInfiniteOnLoad}>
            <List
              dataSource={dataSource}
              loading={loading}
              pagination={{
                onChange: (e) => handlePagination(e),
                style:{ textAlign: 'center' },
                size: 'small',
                showSizeChanger: false,
                pageSize: pagination.perpage,
                total: pagination.total,
                current: pagination.page
              }}
              renderItem={(item, i) => (
                <List.Item key={item.id} style={{ cursor: 'pointer', background: idApplicant === item.id ? "#e6f0ff" : ""  }}>
                  <List.Item.Meta
                    onClick={() => selectedApplicant(item)}
                    avatar={
                      <Avatar src={item.profilePic ? item.profilePic : null} icon={!item.profilePic ? <UserOutlined /> : null} />
                    }
                    title={<Text style={{ color: '#0076de' }}>{item.name ? item.name : 'N/A'}</Text>}
                    description={
                      <Row>
                        {/* <Col span={24}><Text type="secondary">{item.institution ? item.institution : 'N/A'}</Text></Col> */}
                        <Col span={24}><Text style={{ fontSize: '13px' }} type="secondary">{item.applicantNumber ? item.applicantNumber : 'N/A'}</Text></Col>
                        <Col span={24}><Text style={{ color: '#b1b1b1' }} type="secondary">{item.city ? item.city : 'N/A'}, {item.country ? item.country : 'N/A'}</Text></Col>
                      </Row>
                    }
                  />
                </List.Item>
              )}
            />
        //   </InfiniteScroll>
        // </div>
      }
      {
        type === "assessment" &&
        // <div className="demo-infinite-container">
        //   <InfiniteScroll hasMore={false} loadMore={handleInfiniteOnLoad}>
            <List
              dataSource={dataSource}
              loading={loading}
              pagination={{
                onChange: (e) => handlePagination(e),
                style:{ textAlign: 'center' },
                size: 'small',
                showSizeChanger: false,
                pageSize: pagination.perpage,
                total: pagination.total,
                current: pagination.page
              }}
              renderItem={item => (
                <List.Item key={item.id} style={{ cursor: 'pointer', background: idApplicant === item.id ? "#e6f0ff" : ""  }}>
                  <List.Item.Meta
                    onClick={() => selectedApplicant(item)}
                    avatar={
                      <Avatar src={item.profilePic ? item.profilePic : null} icon={!item.profilePic ? <UserOutlined /> : null} />
                    }
                    title={<Text style={{ color: '#0076de' }}>{item.name ? item.name : 'N/A'}</Text>}
                    description={
                      <Row>
                        <Col span={24}><Text style={{ fontSize: '13px' }} type="secondary">{item.applicantNumber ? item.applicantNumber : 'N/A'}</Text></Col>
                        {
                          item.status === "Interview today" &&
                          <Col span={24}><Text style={{ color: '#37ac71' }}>Interview today</Text></Col>
                        }
                        {
                          item.status === "Request to schedule" &&
                          <Col span={24}><Text style={{ color: '#f23c3c' }}>Request to reschedule</Text></Col>
                        }
                        {
                          item.status === "Awaiting confirmation" &&
                          <Col span={24}><Text style={{ color: '#979797' }}>Awaiting confirmation</Text></Col>
                        }
                        {
                          item.status === "Missed interview" &&
                          <Col span={24}><Text style={{ color: '#ff8a1c' }}>Missed interview</Text></Col>
                        }
                        {
                          item.status === "Confirmed" &&
                          <Col span={24}><Text style={{ color: '#14bfbd' }}>Confirmed</Text></Col>
                        }
                        {
                          item.status === "Awaiting reschedule confirmation" &&
                          <Col span={24}><Text style={{ color: '#979797' }}>Awaiting reschedule confirmation</Text></Col>
                        }
                      </Row>
                    }
                  />
                </List.Item>
              )}
            />
        //   </InfiniteScroll>
        // </div>
      }
      {
        type === "qualified" &&
        // <div className="demo-infinite-container">
        //   <InfiniteScroll hasMore={false} loadMore={handleInfiniteOnLoad}>
            <List
              dataSource={dataSource}
              loading={loading}
              pagination={{
                onChange: (e) => handlePagination(e),
                style:{ textAlign: 'center' },
                size: 'small',
                showSizeChanger: false,
                pageSize: pagination.perpage,
                total: pagination.total,
                current: pagination.page
              }}
              renderItem={(item, i) => (
                <List.Item key={item.id} style={{ cursor: 'pointer', background: idApplicant === item.id ? "#e6f0ff" : ""  }}>
                  <List.Item.Meta
                    onClick={() => selectedApplicant(item)}
                    avatar={
                      <Avatar src={item.profilePic ? item.profilePic : null} icon={!item.profilePic ? <UserOutlined /> : null} />
                    }
                    title={<Text style={{ color: '#0076de' }}>{item.name ? item.name : 'N/A'}</Text>}
                    description={
                      <Row>
                        {/* <Col span={24}><Text type="secondary">{item.institution ? item.institution : 'N/A'}</Text></Col> */}
                        <Col span={24}><Text style={{ fontSize: '13px' }} type="secondary">{item.applicantNumber ? item.applicantNumber : 'N/A'}</Text></Col>
                        <Col span={24}><Text style={{ color: '#b1b1b1' }} type="secondary">{item.city ? item.city : 'N/A'}, {item.country ? item.country : 'N/A'}</Text></Col>
                      </Row>
                    }
                  />
                </List.Item>
              )}
            />
        //   </InfiniteScroll>
        // </div>
      }
      {
        type === "interview" &&
        // <div className="demo-infinite-container">
        //   <InfiniteScroll hasMore={false} loadMore={handleInfiniteOnLoad}>
            <List
              dataSource={dataSource}
              loading={loading}
              pagination={{
                onChange: (e) => handlePagination(e),
                style:{ textAlign: 'center' },
                size: 'small',
                showSizeChanger: false,
                pageSize: pagination.perpage,
                total: pagination.total,
                current: pagination.page
              }}
              renderItem={item => (
                <List.Item key={item.id} style={{ cursor: 'pointer', background: idApplicant === item.id ? "#e6f0ff" : ""  }}>
                  <List.Item.Meta
                    onClick={() => selectedApplicant(item)}
                    avatar={
                      <Avatar src={item.profilePic ? item.profilePic : null} icon={!item.profilePic ? <UserOutlined /> : null} />
                    }
                    title={<Text style={{ color: '#0076de' }}>{item.name ? item.name : 'N/A'}</Text>}
                    description={
                      <Row>
                        <Col span={24}><Text style={{ fontSize: '13px' }} type="secondary">{item.applicantNumber ? item.applicantNumber : 'N/A'}</Text></Col>
                        {
                          item.status === "Interview today" &&
                          <Col span={24}><Text style={{ color: '#37ac71' }}>Interview today</Text></Col>
                        }
                        {
                          item.status === "Request to schedule" &&
                          <Col span={24}><Text style={{ color: '#f23c3c' }}>Request to reschedule</Text></Col>
                        }
                        {
                          item.status === "Awaiting confirmation" &&
                          <Col span={24}><Text style={{ color: '#979797' }}>Awaiting confirmation</Text></Col>
                        }
                        {
                          item.status === "Missed interview" &&
                          <Col span={24}><Text style={{ color: '#ff8a1c' }}>Missed interview</Text></Col>
                        }
                        {
                          item.status === "Confirmed" &&
                          <Col span={24}><Text style={{ color: '#14bfbd' }}>Confirmed</Text></Col>
                        }
                        {
                          item.status === "Awaiting reschedule confirmation" &&
                          <Col span={24}><Text style={{ color: '#979797' }}>Awaiting reschedule confirmation</Text></Col>
                        }
                      </Row>
                    }
                  />
                </List.Item>
              )}
            />
        //   </InfiniteScroll>
        // </div>
      }
      {
        type === "departure" &&
        // <div className="demo-infinite-container">
        //   <InfiniteScroll hasMore={false} loadMore={handleInfiniteOnLoad}>
            <List
              dataSource={dataSource}
              loading={loading}
              pagination={{
                onChange: (e) => handlePagination(e),
                style:{ textAlign: 'center' },
                size: 'small',
                showSizeChanger: false,
                pageSize: pagination.perpage,
                total: pagination.total,
                current: pagination.page
              }}
              renderItem={item => (
                <List.Item key={item.id} style={{ cursor: 'pointer', background: idApplicant === item.id ? "#e6f0ff" : ""  }}>
                  <List.Item.Meta
                    onClick={() => selectedApplicant(item)}
                    avatar={
                      <Avatar src={item.profilePic ? item.profilePic : null} icon={!item.profilePic ? <UserOutlined /> : null} />
                    }
                    title={<Text style={{ color: '#0076de' }}>{item.name ? item.name : 'N/A'}</Text>}
                    description={
                      <Row>
                        <Col span={24}><Text style={{ fontSize: '13px' }} type="secondary">{item.applicantNumber ? item.applicantNumber : 'N/A'}</Text></Col>
                        <Col span={24}><Text type="secondary">{item.city ? item.city : 'N/A'}, {item.country ? item.country : 'Indonesia'}</Text></Col>
                      </Row>
                    }
                  />
                </List.Item>
              )}
            />
        //   </InfiniteScroll>
        // </div>
      }
      {
        type === "offered" &&
        // <div className="demo-infinite-container">
        //   <InfiniteScroll hasMore={false} loadMore={handleInfiniteOnLoad}>
            <List
              dataSource={dataSource}
              loading={loading}
              pagination={{
                onChange: (e) => handlePagination(e),
                style:{ textAlign: 'center' },
                size: 'small',
                showSizeChanger: false,
                pageSize: pagination.perpage,
                total: pagination.total,
                current: pagination.page
              }}
              renderItem={item => (
                <List.Item key={item.id} style={{ cursor: 'pointer', background: idApplicant === item.id ? "#e6f0ff" : ""  }}>
                  <List.Item.Meta
                    onClick={() => selectedApplicant(item)}
                    avatar={
                      <Avatar src={item.profilePic ? item.profilePic : null} icon={!item.profilePic ? <UserOutlined /> : null} />
                    }
                    title={<Text style={{ color: '#0076de' }}>{item.name ? item.name : 'N/A'}</Text>}
                    description={
                      <Row>
                        <Col span={24}><Text style={{ fontSize: '13px' }} type="secondary">{item.applicantNumber ? item.applicantNumber : 'N/A'}</Text></Col>
                        <Col span={24}><Text type="secondary">{item.city ? item.city : 'N/A'}, {item.country ? item.country : 'Indonesia'}</Text></Col>
                      </Row>
                    }
                  />
                </List.Item>
              )}
            />
        //   </InfiniteScroll>
        // </div>
      }
      {
        type === "development" &&
        // <div className="demo-infinite-container">
        //   <InfiniteScroll hasMore={false} loadMore={handleInfiniteOnLoad}>
        <List
          dataSource={dataSource}
          loading={loading}
          pagination={{
            onChange: (e) => handlePagination(e),
            style:{ textAlign: 'center' },
            size: 'small',
            showSizeChanger: false,
            pageSize: pagination.perpage,
            total: pagination.total,
            current: pagination.page
          }}
          renderItem={(item, i) => (
            <List.Item key={item.id} style={{ cursor: 'pointer', background: idApplicant === item.id ? "#e6f0ff" : ""  }}>
              <List.Item.Meta
                onClick={() => selectedApplicant(item)}
                avatar={
                  <Avatar src={item.profilePic ? item.profilePic : null} icon={!item.profilePic ? <UserOutlined /> : null} />
                }
                title={<Text style={{ color: '#0076de' }}>{item.name ? item.name : 'N/A'}</Text>}
                description={
                  <Row>
                    <Col span={24}><Text style={{ fontSize: '13px' }} type="secondary">{item.applicantNumber ? item.applicantNumber : 'N/A'}</Text></Col>
                    <Col span={24}><Text style={{ color: '#b1b1b1' }} type="secondary">{item.lastStage ? item.lastStage : 'N/A'}</Text></Col>
                  </Row>
                }
              />
            </List.Item>
          )}
        />
        //   </InfiniteScroll>
        // </div>
      }
    </React.Fragment>
  )
}
