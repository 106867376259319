import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { Loading } from '../../../../components';
import { Row, Col, Breadcrumb, PageHeader, Typography, Modal, Tabs, message } from 'antd';
import { SoundOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { 
  detailJob, 
  inactiveJob, 
  listInjectCandidate, 
  detailJobActiveApplicant, 
  listJobsApplied,
  listApplicant, 
  listRejected, 
  unmountListInjectCandidate, 
  unmountDetailJobsActiveApplicant, 
  unmountListJobsApplied,
  unmountListApplicant,
  unmountListRejected, 
  unmountDetailJobs 
} from '../../../../redux/actions/jobs/jobsAction';
import HeaderDetailJob from '../../../Employer/Jobs/Detail/Header';
import Information from '../Detail/Content/Information';
import InjectCandidates from '../Detail/Content/InjectCandidates';
import ActiveApplicant from '../Detail/Content/ActiveApplicants';
import RejectedApplicant from '../Detail/Content/RejectedApplicant';

const { Text } = Typography;
const { confirm } = Modal;

class DetailEmployerJobs extends Component {
  constructor(props) {
    super(props)

    this.state = {
      url: 'job_employers',
      checked: true,
      // INFORMATION
      visible: false,
      visibleCountry: false,
      dataEvent: null,
      meta: {
        page: 1,
        perpage: 10,
        id: '',
        sort: '',
        field: '',
        search: '',
        minAge: '',
        maxAge: '',
        gender: ''
      },
      metaApplicant: {
        page: 1,
        perpage: 5,
        field: null,
        sort: null,
        search: null,
        id: null,
        phaseId: null
      },
      metaRejectedApplicants: {
        page: 1,
        perpage: 10,
        id: '',
        sort: '',
        field: '',
        search: '',
        startDate: '',
        endDate: '',
        phaseId: -1
      },
    }
    this.onSearch = debounce(this.onSearch.bind(this), 900)
    this.onSearchApplicant = debounce(this.onSearchApplicant.bind(this), 900)
  }

  componentDidMount() {
    const { actionGetDetail, actionListInjectCandidate, actionGetActiveApplicant, actionListApplied, actionGetApplicant, actionGetRejected, match: { params } } = this.props;
    const { meta, metaApplicant, metaRejectedApplicants, url } = this.state

    actionGetDetail(params.id, this.state.url)
    window.scrollTo(0, 0);
    
    if(params.activeTab === 'inject-candidates'){
      meta.id = Number(params.id)
      actionListInjectCandidate(meta, url)
    }

    if(params.activeTab === 'active-applicants'){
      actionGetActiveApplicant(params.id, url)
      if(params.phaseId === '0'){
        meta.id = Number(params.id)
        actionListApplied(meta, url)
      }else{
        metaApplicant.id = Number(params.id)
        metaApplicant.phaseId = params.phaseId
        actionGetApplicant(metaApplicant, url)
      }
    }

    if(params.activeTab === 'rejected-applicants'){
      metaRejectedApplicants.id = Number(params.id)
      actionGetRejected(metaRejectedApplicants, url)
    }
  }

  handleChangeStatus = () => {
    const { actionInactive, actionGetDetail, match: { params } } = this.props;
    let self = this
    confirm({
      title: 'Are you sure to set it as inactive?',
      icon: <ExclamationCircleOutlined />,
      content: 'By doing this, Job ad will go offline and candidates would not be able to apply anymore.',
      onOk() {
        return actionInactive(params.id, self.state.url, () => {
          message.success('Successfully changed to inactive')
          return actionGetDetail(params.id, self.state.url)
        })
      },
    });
  }

  handleTabs = (key) => {
    const { actionListInjectCandidate, actionGetActiveApplicant, actionListApplied, actionGetApplicant, actionGetRejected, match: { params } } = this.props;
    const { meta, metaApplicant, metaRejectedApplicants, url } = this.state
    
    if(key === 'inject-candidates'){
      meta.id = Number(params.id)
      actionListInjectCandidate(meta, url)
    }

    if(key === 'active-applicants'){
      actionGetActiveApplicant(params.id, url)
      if(params.phaseId === '0'){
        meta.id = Number(params.id)
        actionListApplied(meta, url)
      }else{
        metaApplicant.id = Number(params.id)
        metaApplicant.phaseId = params.phaseId
        actionGetApplicant(metaApplicant, url)
      }
      this.props.history.push(`/employer/jobs/detail/${params.id}/${key}/${params.phaseId}`)
    }

    if(key !== 'active-applicants'){
      this.props.history.push(`/employer/jobs/detail/${params.id}/${key}/0`);
    }

    if(key === 'rejected-applicants'){
      metaRejectedApplicants.id = Number(params.id)
      actionGetRejected(metaRejectedApplicants, url)
    }
  }

  // INFORMATION FUNCTION

  showModal = (dataEvent) => {
    this.setState({ visible: true, dataEvent })
  }

  showModalCountry = () => {
    this.setState({ visibleCountry: true })
  }

  handleCancel = () => {
    this.setState({ visible: false, visibleCountry: false })
  }

  // ACTIVE APPLICANT

  handleTabsPhase = (key) => {
    const { meta, metaApplicant, url } = this.state
    const { actionListApplied, actionGetApplicant, match: { params }, history } = this.props
    
    history.push({
      pathname: `/employer/jobs/detail/${params.id}/${params.activeTab}/${key}`,
      state: {
        phaseId: key
      }
    })

    if(key === '0'){
      meta.id = Number(params.id)
      actionListApplied(meta, url)
    }else{
      metaApplicant.id = Number(params.id)
      metaApplicant.phaseId = key
      actionGetApplicant(metaApplicant, url)
    }
  }

  onSearch = value => {
    const { meta, url } = this.state;
    const { actionListApplied } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.search = value === '' ? null : value
    return actionListApplied(meta, url)
  }

  onFilter = value => {
    const { meta, url } = this.state;
    const { actionListApplied } = this.props;
    meta.page = 1
    meta.perpage = 10

    meta.gender = value.gender
    meta.maxAge = value.maxAge
    meta.minAge = value.minAge
    this.setState({ meta, url })
    return actionListApplied(meta, url)
  }

  pagination = (page, perpage) => {
    const { meta, url } = this.state;
    const { actionListApplied } = this.props;
    meta.page = page
    meta.perpage = perpage
    return actionListApplied(meta, url)
  }

  onSearchApplicant = value => {
    const { metaApplicant, url } = this.state;
    const { actionGetApplicant } = this.props;
    metaApplicant.page = 1
    metaApplicant.perpage = 10
    metaApplicant.search = value === '' ? null : value
    return actionGetApplicant(metaApplicant, url, (response) => {
      this.setState({ listData: response.data })
    })
  }

  onRangePicker = value => {
    const { metaApplicant, url } = this.state;
    const { actionGetApplicant } = this.props;
    metaApplicant.page = 1
    metaApplicant.perpage = 10
    metaApplicant.startDate = value.startDate
    metaApplicant.endDate = value.endDate
    return actionGetApplicant(metaApplicant, url)
  }

  handlePagination = (e) => {
    const { metaApplicant, url } = this.state;
    const { actionGetApplicant } = this.props;
    metaApplicant.page = e
    actionGetApplicant(metaApplicant, url, (response) => {
      this.setState({ listData: response.data })
    })
  }

  render() {
    const { checked, visible, visibleCountry, dataEvent } = this.state;
    const { getData: { data, loading }, match: { params } } = this.props;

    if (loading) {
      return <Loading />
    }

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><SoundOutlined /> Job Posting</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/employer/jobs">Job Posting List</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Detail</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Text strong>
                  {data.jobNumber}
                </Text>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Job Posting Detail' />
          </Col>
          {/* Card Header */}
          <Col span={24}>
            <HeaderDetailJob
              data={data}
              checked={checked}
              handleChangeStatus={(e) => this.handleChangeStatus(e)}
            />
          </Col>
          
          <Col span={24}>
            <Tabs onTabClick={this.handleTabs} defaultActiveKey={params.activeTab}>
              {/* Information */}
              <Tabs.TabPane tab="Information" key="information">
                <Information
                  data={data}
                  dataEvent={dataEvent}
                  visible={visible}
                  showModal={this.showModal}
                  visibleCountry={visibleCountry}
                  showModalCountry={this.showModalCountry}
                  handleCancel={this.handleCancel}
                />
              </Tabs.TabPane>
              {/* INJECT CANDIDATE */}
              <Tabs.TabPane tab="Inject Candidates" key="inject-candidates">
                <InjectCandidates
                  {...this.props}
                  {...this.state}
                />
              </Tabs.TabPane>
              {/* ACTIVE APPLICANTS */}
              <Tabs.TabPane tab="Active Applicants" key="active-applicants">
                <ActiveApplicant 
                  {...this.props}
                  {...this.state}
                  handleTabsPhase={this.handleTabsPhase}
                  onSearch={this.onSearch}
                  onFilter={this.onFilter}
                  pagination={this.pagination}
                  onSearchApplicant={this.onSearchApplicant}
                  onRangePicker={this.onRangePicker}
                  handlePagination={this.handlePagination}
                />
              </Tabs.TabPane>
              {/* DEVELOPMENT APPLICANTS */}
              {/* <Tabs.TabPane tab="Development Applicants" key="4">
                Content of Tab Pane Rejected Applicants
              </Tabs.TabPane> */}
              {/* REJECTED APPLICANTS */}
              <Tabs.TabPane tab="Rejected Applicants" key="rejected-applicants">
                <RejectedApplicant 
                  {...this.props}
                />
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail, unmountListInjectCandidate, unmountListRejected } = this.props;
    unmountGetDetail()
    unmountListInjectCandidate()
    unmountListRejected()
  }
}

const mapStateToProps = (state) => ({
  getData             : state.jobs.detail,
  getInjectCandidate  : state.jobs.listInjectCandidate,
  getActiveApplicant  : state.jobs.activeApplicant,
  getApplied          : state.jobs.applied,
  getApplicant        : state.jobs.listApplicant,
  getRejected         : state.jobs.listRejected,
})

const mapDispatchToProps = {
  actionInactive              : inactiveJob,
  actionGetDetail             : detailJob,
  actionListInjectCandidate   : listInjectCandidate,
  actionGetActiveApplicant    : detailJobActiveApplicant,
  actionListApplied           : listJobsApplied,
  actionGetApplicant          : listApplicant,
  actionGetRejected           : listRejected,
  unmountGetDetail            : unmountDetailJobs,
  unmountListInjectCandidate  : unmountListInjectCandidate,
  unmountGetActiveApplicant   : unmountDetailJobsActiveApplicant,
  unmountListApplied          : unmountListJobsApplied,
  unmountGetApplicant         : unmountListApplicant,
  unmountListRejected         : unmountListRejected,
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailEmployerJobs)