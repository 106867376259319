import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Loading } from '../../../../../../../../components';
import { Row, Col, Card, Typography, Modal, Empty, Input, Button, AutoComplete, Skeleton, message } from 'antd';
import { ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { detailCandidate, unmountDetailCandidate } from '../../../../../../../../redux/actions/candidate/candidateAction';
import { addOffer, addDevelopment, cancelApplicant, detailApplicant, detailJobActiveApplicant, detailJobInterview, editInterview, listApplicant, addApplicantNotes, listApplicantNotes, unmountDetailJobsActiveApplicant, unmountDetailJobsInterview, unmountListApplicant, unmountApplicantNotes } from '../../../../../../../../redux/actions/jobs/jobsAction';
import EditInterview from './Edit';
import ContentInterview from './Content';
import ListApplicant from '../../../../../../../../components/ActiveApplicants/ListApplicant';
import ViewProfile from '../../../../../../../../components/Modal/ApplicantProfile';
import ApplicantHistory from '../../../../../../../../components/Modal/ApplicantHistory';
import ApplicantDevelopment from '../../../../../../../../components/Modal/ApplicantDevelopment';
import ApplicantNotes from '../../../../../../../../components/Modal/ApplicantNotes';
import ApplicationRejection from '../../../../../../../../components/Modal/ApplicationRejection';
import DownloadFile from '../../../../../../../../components/DownloadFiles';

const { Text } = Typography;
const { confirm } = Modal;

class DetailInterviewAgencyJobs extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      isMethod: true,
      checked: true,
      visible: false,
      visibleProfile: false,
      valueForm: null,
      idApplicant: null,
      idInterview: null,
      dataApplicant: null,
      isResult: true,
      indexApplicant: null,
      documentName: null,
      isModalNotes: false,
      isModalHistory: false,
      isModalDev: false,
      isModalReject: false,
      isAttended: false,
      submitLoading: false
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.match.params !== this.props.match.params){
      this.setState({ dataApplicant: null })
    }
  }

  selectedApplicant = (value) => {
    const { actionGetDetailInterview, actionGetProfile, actionListApplicantNotes } = this.props;
    const { isResult, dataApplicant } = this.state;
    this.setState({ idApplicant: value.id, dataApplicant: value })
    if(dataApplicant){
      this.formRef.current.setFieldsValue({
        isResult: isResult,
        notes: null,
      });
    }
    actionGetProfile(value.candidateId)
    actionGetDetailInterview(value.jobInterviewId, (response) => {
      this.setState({ isMethod: response.data.isMethod })
    })
    actionListApplicantNotes(value.id)
  }
  
  handleChangeAssessmentResult = (e) => {
    this.setState({ isResult: e.target.value })
  }

  // handleInfiniteOnLoad = () => {
  //   ('scroll');
  // };

  handleChangeMethod = (e) => {
    this.formRef.current.setFieldsValue({
      location: null,
      virtualLink: null,
    });
    this.setState({ isMethod: e.target.value })
  }

  handleChangeValue = (value) => {
    this.setState({ valueForm: value })
  }

  openModal = () => {
    const { dataApplicant } = this.state;
    const { actionGetDetailInterview } = this.props;
    actionGetDetailInterview(dataApplicant.jobInterviewId, (response) => {
      this.setState({ isMethod: response.data.isMethod, visible: true })
    })
  }

  openModalProfile = (id) => {
    const { getApplicant } = this.props;
    const findCandidate = getApplicant.data.find(item => item.candidateId === id)
    this.setState({
      visibleProfile: true,
      documentName: `${findCandidate.applicantNumber}-${findCandidate.name}`
    })
  }

  showModalNotes = (id) => {
    this.setState({ isModalNotes: true, idApplicant: id })
  };

  showModalHistory = (id) => {
    this.setState({ isModalHistory: true, idApplicant: id })
  };

  showModalDev = (id) => {
    this.setState({ isModalDev: true, idApplicant: id })
  };

  showModalReject = (id) => {
    this.setState({ isModalReject: true, idApplicant: id })
  };

  onCancel = () => {
    this.setState({ visible: false, visibleProfile: false, isModalNotes: false, isModalHistory: false, isModalDev: false, isModalReject: false })
  }

  handleChecked = (e) => {
    this.setState({ isAttended: e.target.checked })
  }

  onFinish = (values) => {
    const self = this;
    const { dataApplicant } = this.state;
    const { metaApplicant, url, actionAddOffer, actionGetApplicant, match: { params } } = this.props;
    values.phaseId = Number(params.phaseId)
    values.jobAppliedId = dataApplicant.id
    values.isResult = true
    confirm({
      title: 'The application will be processed to the next stage.',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure?',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        return actionAddOffer(values, () => {
          message.success('Successfully moved')
          actionGetApplicant(metaApplicant, url, (response) => {
            self.setState({ dataApplicant: null })
          })
        })
      },
    });
  }

  onSubmit = () => {
    const { metaApplicant, url, getInterview: { data }, match: { params }, actionUpdateInterview, actionGetApplicant, actionDetailApplicant } = this.props;
    this.formRef.current.validateFields().then(values => {
      values.jobAppliedId = data.jobAppliedId
      values.id = data.id
      values.phaseId = Number(params.phaseId)
      values.interviewDate = moment(values.interviewDate).format('DD/MM/YYYY')
      values.interviewTime = moment(values.interviewTime).format('HH:mm:ss')
      // values.notes = data.notes ? data.notes : null
      return actionUpdateInterview(values, () => {
        message.success('Data updated successfully')
        this.setState({ visible: false }, () => {
          actionDetailApplicant(params.appliedId, params.phaseId, url, (response) => {
            this.selectedApplicant(response.data)
          })
          return actionGetApplicant(metaApplicant, url, (response) => {
            this.setState({ idApplicant: data.jobAppliedId })
          })
        })
      })
    })
  }

  onCancelInterview = (id) => {
    const { metaApplicant, url, actionCancelInterview, actionGetApplicant } = this.props;
    const self = this;
    confirm({
      title: 'By cancelling this scheduled interview, the application will be moved to be rejected.',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure?',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        
        return actionCancelInterview(id, url, () => {
          message.success('Successfully moved')
          return actionGetApplicant(metaApplicant, url, (response) => {
            self.setState({ dataApplicant: null })
          })
        })
      },
    });
  }

  onFinishNotes = value => {
    const { idApplicant } = this.state
    const { metaApplicant, url, actionAddApplicantNotes, actionGetApplicant, actionListApplicantNotes } = this.props;
    
    value.jobAppliedId = idApplicant

    return actionAddApplicantNotes(value, url, () => {
      message.success('Successfully update applicant notes')
      actionListApplicantNotes(idApplicant)
      actionGetApplicant(metaApplicant, url, (response) => {
        this.setState({ dataApplicant: response.data.filter(item => item.id === idApplicant).pop() })
      }, (err) => message.error(err))
    }, (err) => message.error(err))
  }

  onFinishDev = values => {
    const { metaApplicant, url, actionAddDevelopment, actionGetApplicant } = this.props;
    values.jobAppliedId = this.state.idApplicant
    values.startDate = values.startDate ? moment(values.startDate).format('DD/MM/YYYY') : null
    values.targetDate = values.targetDate ? moment(values.targetDate).format('DD/MM/YYYY') : null
    return actionAddDevelopment(values, () => {
      message.success('Successfully moved')
      actionGetApplicant(metaApplicant, url, (response) => {
        this.setState({ dataApplicant: null, isModalDev: false, isAttended: false })
      }, (err) => message.error(err))
    })
  }

  onFinishReject = values => {
    const { metaApplicant, url, actionAddOffer, actionGetApplicant, match: { params } } = this.props;
    values.jobAppliedId = this.state.idApplicant
    values.phaseId = Number(params.phaseId)
    values.isResult = false
    this.setState({ submitLoading: true })
    return actionAddOffer(values, () => {
      message.success('Successfully moved')
      actionGetApplicant(metaApplicant, url, (response) => {
        this.setState({ dataApplicant: null, isModalReject: false, isAttended: false, submitLoading: false })
      }, (err) => {
        this.setState({ submitLoading: false }, () => {
          message.error(err)
        })
      })
    })
  }

  render() {
    const { idApplicant, dataApplicant, isResult, isMethod, visible, visibleProfile, valueForm, documentName, isAttended, isModalNotes, isModalHistory, isModalDev, isModalReject, submitLoading } = this.state;
    const { getData: { data, loading }, getApplicant, getProfile, onSearchApplicant, handlePagination } = this.props;

    if(loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Row gutter={[16, 24]}>
          {/* Content */}
          <Col span={6}>
            <AutoComplete
              onSearch={onSearchApplicant} 
              style={{ width: '100%' }} 
              allowClear
            >
              <Input 
                prefix={<SearchOutlined />} 
                placeholder="Search by Applicant Name" 
              />
            </AutoComplete>
          </Col>
          <Col span={24}>
            <Text>Showing {getApplicant.pagination.total} Data</Text>
          </Col>
          <Col span={6}>
            {
              getApplicant.loading ?
                <Skeleton active />
              :
                <ListApplicant 
                  type="interview"
                  loading={getApplicant?.loading}
                  pagination={getApplicant?.pagination}
                  dataSource={getApplicant?.data}
                  idApplicant={idApplicant}
                  selectedApplicant={(value) => this.selectedApplicant(value)}
                  // handleInfiniteOnLoad={() => this.handleInfiniteOnLoad()}
                  handlePagination={(e) => handlePagination(e)}
                />
            }
          </Col>
          <Col span={18}>
            <Card>
              {
                dataApplicant ? 
                  <ContentInterview 
                    dataApplicant={dataApplicant}
                    isResult={isResult}
                    openModalProfile={this.openModalProfile}
                    onFinish={this.onFinish} 
                    formRef={this.formRef} 
                    openModal={this.openModal}
                    handleChecked={this.handleChecked}
                    isAttended={isAttended}
                    showModalNotes={this.showModalNotes}
                    showModalHistory={this.showModalHistory}
                    showModalDev={this.showModalDev}
                    showModalReject={this.showModalReject}
                  />
                : 
                  <Empty description={<Text>Please click an applicant name to start the interview</Text>} />
              }
            </Card>
          </Col>
        </Row>
        {/* Modal */}
        <Modal
          width={1000}
          destroyOnClose
          visible={visible}
          title="Edit Schedule"
          onCancel={this.onCancel}
          footer={[
            <Button className="btn-save-primary" key="submit" type="primary" onClick={this.onSubmit} disabled={!valueForm}>Update</Button>
          ]}
        >
          <EditInterview 
            {...this.props} 
            isMethod={isMethod} 
            formRef={this.formRef} 
            onSubmit={this.onSubmit}
            handleChangeValue={this.handleChangeValue}
            handleChangeMethod={(e) => this.handleChangeMethod(e)}
          />
        </Modal>
        <Modal 
          centered
          width={1000}
          title="Applicant Profile"
          visible={visibleProfile}
          onCancel={this.onCancel}
          footer={
            <>
              <DownloadFile data={data} getProfile={getProfile} documentName={documentName} />
              <Button key="back" type="primary" onClick={this.onCancel}>Okay</Button>
            </>
          }
        >
          <div style={{ height: 430, overflowY: 'auto', overflowX: 'hidden', padding: 32 }}>
            <ViewProfile {...this.props} />
          </div>
        </Modal>
        <Modal centered width={800} title="Applicant Notes" visible={isModalNotes} onCancel={this.onCancel} footer={null} destroyOnClose>
          <ApplicantNotes {...this.props} onFinishNotes={this.onFinishNotes} />
        </Modal>
        <Modal centered width={800} title="Applicant History" visible={isModalHistory} onCancel={this.onCancel} footer={null} destroyOnClose>
          <ApplicantHistory {...this.props} dataApplicantHistory={dataApplicant?.listHistory} />
        </Modal>
        <Modal title="Process for Development" width={650} visible={isModalDev} onCancel={this.onCancel} footer={null} destroyOnClose>
          <ApplicantDevelopment {...this.props} onFinishDev={this.onFinishDev} />   
        </Modal>
        <Modal title="Application Rejection" visible={isModalReject} onCancel={this.onCancel} footer={null} destroyOnClose>
          <ApplicationRejection {...this.props} submitLoading={submitLoading} notes="notes" onFinishReject={this.onFinishReject} />
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail, unmountGetApplicant, unmountGetProfile, unmountApplicantNotes } = this.props;
    unmountGetApplicant()
    unmountGetDetail()
    unmountGetProfile()
    unmountApplicantNotes()
  }
}

const mapStateToProps = (state) => ({
  getData           : state.jobs.activeApplicant,
  getApplicant      : state.jobs.listApplicant,
  getInterview      : state.jobs.detailInterview,
  getProfile        : state.candidate.detail,
  getApplicantNotes : state.jobs.listApplicantNotes
})

const mapDispatchToProps = {
  actionUpdateInterview     : editInterview,
  actionAddOffer            : addOffer,
  actionAddDevelopment      : addDevelopment,
  actionCancelInterview     : cancelApplicant,
  actionDetailApplicant     : detailApplicant,
  actionGetApplicant        : listApplicant,
  actionGetProfile          : detailCandidate,
  actionGetDetail           : detailJobActiveApplicant,
  actionGetDetailInterview  : detailJobInterview,
  actionAddApplicantNotes   : addApplicantNotes,
  actionListApplicantNotes  : listApplicantNotes,
  unmountGetApplicant       : unmountListApplicant,
  unmountGetProfile         : unmountDetailCandidate,
  unmountGetDetail          : unmountDetailJobsActiveApplicant,
  unmountGetDetailInterview : unmountDetailJobsInterview,
  unmountApplicantNotes     : unmountApplicantNotes
}   

export default connect(mapStateToProps, mapDispatchToProps)(DetailInterviewAgencyJobs)