import React, { Component } from 'react';
import { Row, Col, Typography, Image, Skeleton } from 'antd';
import { Player, BigPlayButton  } from 'video-react';
const { Text } = Typography;

export default class DetailSliderPhoto extends Component {

  componentDidMount() {
    const { actionGetGallery } = this.props;
    return actionGetGallery()
  }
  
  render() {
    const { getGallery: { loading, data } } = this.props;
    if(loading){
      return <Skeleton />
    }
    return (
      <React.Fragment>
        <Row gutter={[16,16]}>
          {
            data.videoUrl || data.listMedia ?
              <React.Fragment>
                {
                  data.videoUrl ? 
                    <Col span={8}>
                      <Player
                        fluid={false}
                        width={'100%'}
                        height={200}
                      >
                        <source src={data.videoUrl}/>
                        <BigPlayButton position='center'/>
                      </Player>
                    </Col>
                  : null
                }
                {
                  data?.listMedia?.map((item,i) =>
                    <Col span={8}>
                      <Image src={item.fileUrl} width={'100%'} height={200} style={{ objectFit: 'cover' }} />
                    </Col>
                  )
                }
              </React.Fragment>
            :
              <Col span={24}>
                <Text type="secondary" style={{ fontSize: 16 }}>No gallery displayed</Text>
              </Col>
          }
        </Row>
      </React.Fragment>
    )
  }
}
