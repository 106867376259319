import React, { Component } from 'react';
import { Form, Row, Col, Input, Space, Button } from 'antd';
// const { Text } = Typography;

export default class EditLanguageSkills extends Component {
  render() {
    const { 
      dataCandidateLanguageSkills,
      formRef,
      handleCancelNative,
      onSubmitNative,
      submitLoading
    } = this.props;


    return (
      <React.Fragment>
        <Form
          ref={formRef}
          layout="vertical"
        >
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                name="name"
                label="Native Language"
                initialValue={dataCandidateLanguageSkills?.name ? dataCandidateLanguageSkills?.name : null}
                validateFirst
                rules={[
                  { pattern: /^[a-zA-Z ]*$/, message: 'Alphabetical only' },
                  { max: 75, message: '75 characters only' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Form.Item>
                <Space>
                  <Button type="default" onClick={handleCancelNative}>Cancel</Button>
                  <Button className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading} onClick={onSubmitNative}>Save</Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}
