import React, { Component } from 'react';
import moment from 'moment';
import AddTraining from './Add';
import EditTraining from './Edit';
import svg from '../../../../../assets/img/profile.svg';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { addTraining, deleteTraining, detailCandidate, unmountDetailCandidate, updateTraining } from '../../../../../redux/actions/candidate/candidateAction';
import { Skeleton, Row, Col, Breadcrumb, PageHeader, Card, Button, Menu, Space, Avatar, Typography, Descriptions, Divider, Tooltip, Empty, Modal, message } from 'antd';
import { UserOutlined, PhoneOutlined, LikeOutlined, ProjectOutlined, BulbOutlined, IdcardOutlined, FormatPainterOutlined, FolderOutlined, TrophyOutlined, LockOutlined, PlusOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
const { Text } = Typography;
const { confirm } = Modal;

class CandidateDetailTrainingAndCertification extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      dataTraining: null,
      submitLoading: false,
      visibleAdd: false,
      visibleEdit: false,
      expDate: null,
      isDate: false,
      isExp: false
    }
  }

  componentDidMount() {
    const { actionGetDetail, match: { params } } = this.props;
    return actionGetDetail(params.id)
  }

  showModal = (type, item) => {
    if(type === 'add'){
      return this.setState({ visibleAdd: true })
    }
    if(type === 'edit'){
      return this.setState({ 
        visibleEdit: true, 
        dataTraining: item, 
        isExp: item.isExp 
      })
    }
  }

  onCancel = (type) => {
    if(type === 'add'){
      return this.setState({ 
        visibleAdd: false,
        expDate: null,
        isDate: false,
        isExp: false
      })
    }
    if(type === 'edit'){
      return this.setState({ 
        dataTraining: null,
        visibleEdit: false,
        expDate: null,
        isDate: false,
        isExp: false
      })
    }
  }

  onChangeIssueDate = (date, dateString) => {
    const { expDate } = this.state
    this.setState({ issueDate: dateString })
    if(expDate <= dateString){
      this.setState({ isDate: true })
    }else{
      this.setState({ isDate: false })
    }
  }
  
  onChangeExpDate = (date, dateString) => {
    const { issueDate } = this.state
    this.setState({ expDate: dateString
    })
    if(dateString <= issueDate){
      this.setState({ isDate: true
      })
    }else{
      this.setState({ isDate: false })
    }
  }
  
  handleChecked = (e) => {
    this.setState({ isExp: e.target.checked })
    if(e.target.checked){
      this.setState({ expDate: null })
      this.formRef.current.setFieldsValue({
        expDate: null
      })
    }
  }

  handleDelete = (id) => {
    const { actionDelete, actionGetDetail, match: { params } } = this.props;
    confirm({
      title: 'Remove Data',
      okText: 'Yes',
      cancelText: 'No',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to remove this data?',
      onOk() {
        return actionDelete(id, () => {
          message.success('Data deleted successfully')
          return actionGetDetail(params.id)
        }, (err) => message.error(err))
      },
    });
  }

  onSubmitAdd = () => {
    const { isExp } = this.state;
    const { actionAdd, actionGetDetail, match: { params } } = this.props;
    this.setState({ submitLoading: true })
    this.formRef.current.validateFields().then(values => {
      values.isExp = isExp
      values.candidateId = Number(params.id)
      values.expDate =  values.expDate ? moment(values.expDate, 'YYYY-MM').format('YYYY-MM') : null
      values.issueDate = values.issueDate ? moment(values.issueDate, 'YYYY-MM').format('YYYY-MM') : null
      return actionAdd(values, () => {
        return this.setState({ submitLoading: false }, () => {
          return this.setState({ visibleAdd: false }, () => {
            message.success('Data created successfully')
            return actionGetDetail(params.id)
          })
        })
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err))
      })
    })
  }

  onSubmitEdit = () => {
    const { isExp, dataTraining } = this.state;
    const { actionUpdate, actionGetDetail, match: { params } } = this.props;
    this.setState({ submitLoading: true })
    this.formRef.current.validateFields().then(values => {
      values.id = dataTraining.id
      values.isExp = isExp
      values.candidateId = Number(params.id)
      values.expDate =  values.expDate ? moment(values.expDate, 'YYYY-MM').format('YYYY-MM') : null
      values.issueDate = values.issueDate ? moment(values.issueDate, 'YYYY-MM').format('YYYY-MM') : null
      return actionUpdate(values, () => {
        return this.setState({ submitLoading: false }, () => {
          return this.setState({ visibleEdit: false }, () => {
            message.success('Data updated successfully')
            return actionGetDetail(params.id)
          })
        })
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err))
      })
    })
  }
  
  render() {
    const { visibleAdd, visibleEdit, submitLoading } = this.state;
    const { getDetailCandidate: { loading, data } } = this.props;
    if(loading){
      return <Skeleton avatar />
    }
    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><UserOutlined /> Candidates</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/binawan/candidate" style={{ color: '#0076de' }}>
                  Candidates List
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Candidates Detail</Breadcrumb.Item>
              <Breadcrumb.Item>
                {data.fullName}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Candidate Detail' />
          </Col>
          {/* Menu */}
          <Col span={7}>
            <Card style={{ border: 'none' }}>
              <Row gutter={[16, 24]}>
                <Col span={24}>
                  <Space direction="horizontal">
                    <Avatar size={100} src={data.profilePicUrl ? data.profilePicUrl : svg} />
                    <Space direction="vertical" size="small">
                      <Text style={{ color: '#4d4d4d', fontSize: 18 }} strong>{data.fullName}</Text>
                      <Text type="secondary">{data.candidateNumber}</Text>
                    </Space>
                  </Space>
                </Col>
                <Col span={24}>
                  <Menu defaultSelectedKeys={['6']} mode="inline">
                  <Menu.Item key="1" icon={<UserOutlined />}>
                      <Link to={`/binawan/candidate/detail/${data.id}/biodata`}>
                        Biodata
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="2" icon={<PhoneOutlined />}>
                      <Link to={`/binawan/candidate/detail/${data.id}/contact-information`}>
                        Contact Information
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="3" icon={<LikeOutlined />}>
                      <Link to={`/binawan/candidate/detail/${data.id}/job-preferences`}>
                        Job Preferences
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="4" icon={<ProjectOutlined />}>
                      <Link to={`/binawan/candidate/detail/${data.id}/work-experience`}>
                        Work Experience
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="5" icon={<BulbOutlined />}>
                      <Link to={`/binawan/candidate/detail/${data.id}/education`}>
                        Education
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="6" icon={<IdcardOutlined />}>
                      <Link to={`/binawan/candidate/detail/${data.id}/training-and-certification`}>
                        Training and Certification
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="7" icon={<FormatPainterOutlined />} >
                      <Link to={`/binawan/candidate/detail/${data.id}/skills`}>
                        Skills
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="8" icon={<FolderOutlined />}>
                      <Link to={`/binawan/candidate/detail/${data.id}/portfolios`}>
                        Portfolios
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="9" icon={<TrophyOutlined />}>
                      <Link to={`/binawan/candidate/detail/${data.id}/achievements`}>
                        Achievements
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="10" icon={<LockOutlined />}>
                      <Link to={`/binawan/candidate/detail/${data.id}/reset-password`}>
                        Reset Password
                      </Link>
                    </Menu.Item>
                  </Menu>
                </Col>
              </Row>
            </Card>
          </Col>
          {/* Content */}
          <Col span={17}>
            <Card 
              style={{ border: 'none' }}
              title={<Text strong>Training and Certification</Text>} 
              extra={<Button key="1" className="btn-save-primary" type="primary" icon={<PlusOutlined />} onClick={() => this.showModal('add')}>Add</Button>} 
            >
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  {
                    data.listTraining.length > 0 ?
                    <React.Fragment>
                      {
                        data.listTraining.map((item, i) => (
                          <React.Fragment key={i}>
                            <div style={{ marginBottom: 11, display: 'flex', justifyContent: 'space-between' }}>
                              <Text style={{ color: '#32ab6d', fontSize: 14 }}>
                                {
                                  item.isExp ?
                                  <React.Fragment>
                                    {`${item.issueDate ? moment(item.issueDate, 'YYYY-MM').format('MMMM YYYY') : 'N/A'}, ${item.expDate ? moment(item.expDate, 'YYYY-MM').format('MMMM YYYY') : 'certificate will not expire'}`}
                                  </React.Fragment>
                                  :
                                  <React.Fragment>
                                    {`${item.issueDate ? moment(item.issueDate, 'YYYY-MM').format('MMMM YYYY') : 'N/A'} - ${item.expDate ? moment(item.expDate, 'YYYY-MM').format('MMMM YYYY') : 'certificate will not expire'}`}
                                  </React.Fragment>
                                }
                              </Text>
                              <Space> 
                                <Tooltip title="Edit">
                                  <EditOutlined style={{ fontSize: 20, cursor: 'pointer', color: '#32ab6d' }} onClick={() => this.showModal('edit', item)} />
                                </Tooltip>
                                <Tooltip title="Remove">
                                  <DeleteOutlined style={{ fontSize: 20, cursor: 'pointer', color: '#d8675a' }} onClick={() => this.handleDelete(item.id)} />
                                </Tooltip>
                              </Space>
                            </div>
                            <Descriptions layout="vertical" size="small" column={2}>
                              <Descriptions.Item label="Name">
                                {item.name}
                              </Descriptions.Item>
                              <Descriptions.Item label="Credential ID">
                                {item.credentialId ? item.credentialId : 'N/A'}
                              </Descriptions.Item>
                              <Descriptions.Item label="Issuing Organization">
                                {item.organization ? item.organization : 'N/A'}
                              </Descriptions.Item>
                              <Descriptions.Item label="Credential URL">
                                {
                                  item.credentialUrl ? 
                                  <a className="link" href={`https://${item.credentialUrl}`} target="_blank" rel='noreferrer'>
                                    {item.credentialUrl}
                                  </a> 
                                  : 
                                  'N/A'
                                }
                              </Descriptions.Item>
                            </Descriptions>
                            <Divider />
                          </React.Fragment>
                        ))
                      }
                    </React.Fragment>
                    :
                    <React.Fragment>
                      <Empty description={<Text>No Data Training and Certification</Text>} />
                    </React.Fragment>
                  }
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal
          key="1"
          centered
          width={1000}
          destroyOnClose
          visible={visibleAdd}
          onCancel={() => this.onCancel('add')}
          title={<Text strong>Add Training and Certification</Text>}
          footer={[
            <React.Fragment key="1">
              <Button key="back" className="btn-save-primary" onClick={() => this.onCancel('add')}>Cancel</Button>
              <Button key="submit" className="btn-save-primary" type="primary" onClick={this.onSubmitAdd} loading={submitLoading}>Save</Button>
            </React.Fragment>
          ]}
        >
          <AddTraining 
            {...this.props} 
            {...this.state}
            formRef={this.formRef} 
            onSubmitAdd={this.onSubmitAdd} 
            handleChecked={this.handleChecked}
            onChangeExpDate={this.onChangeExpDate}
            onChangeIssueDate={this.onChangeIssueDate}
          />
        </Modal>
        <Modal
          key="2"
          centered
          width={1000}
          destroyOnClose
          visible={visibleEdit}
          onCancel={() => this.onCancel('edit')}
          title={<Text strong>Edit Training and Certification</Text>}
          footer={[
            <React.Fragment key="1">
              <Button key="back" className="btn-save-primary" onClick={() => this.onCancel('edit')}>Cancel</Button>
              <Button key="submit" className="btn-save-primary" type="primary" onClick={this.onSubmitEdit}>Save</Button>
            </React.Fragment>
          ]}
        >
          <EditTraining 
            {...this.props} 
            {...this.state}
            formRef={this.formRef} 
            onSubmitEdit={this.onSubmitEdit} 
            handleChecked={this.handleChecked}
            onChangeExpDate={this.onChangeExpDate}
            onChangeIssueDate={this.onChangeIssueDate}
          />
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail } = this.props;
    unmountGetDetail()
  }
}

const mapStateToProps = (state) => ({
  getDetailCandidate  : state.candidate.detail,
})

const mapDispatchToProps = {
  actionAdd         : addTraining,
  actionUpdate      : updateTraining,
  actionDelete      : deleteTraining,
  actionGetDetail   : detailCandidate,
  unmountGetDetail  : unmountDetailCandidate,
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateDetailTrainingAndCertification)
