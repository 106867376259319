import React from 'react'
import { Row, Col, Typography, Descriptions, Divider } from 'antd'

const { Text } = Typography

export default function Education(props) {
  const { data } = props
  return (
    <Row gutter={16}>
      <Col span={24}>
        <Descriptions title="Educations" />
        {
          data.listEducation.length > 0 ?
            <React.Fragment>
              {
                data.listEducation.map((item, i) => (
                  <React.Fragment key={i}>
                    <div style={{ marginBottom: 11 }}>
                      <Text style={{ color: '#32ab6d', fontSize: 14 }}>
                        {item.startYear} - {item.endYear ? item.endYear : 'Present'}
                      </Text>
                    </div>
                    <Descriptions layout="vertical" size="small" column={3}>
                      <Descriptions.Item label="School / University">
                        {item.name ? item.name : 'N/A'}
                      </Descriptions.Item>
                      <Descriptions.Item label="Field of Study">
                        {item.fieldOfStudy ? item.fieldOfStudy : 'N/A'}
                      </Descriptions.Item>
                      <Descriptions.Item label="Education Level">
                        {item.educationLevelName ? item.educationLevelName : 'N/A'}
                      </Descriptions.Item>
                    </Descriptions>
                    <Descriptions layout="vertical" size="small" column={3}>
                      <Descriptions.Item label="Score/Grade/GPA">
                        {item.gpa ? item.gpa : 'N/A'}
                      </Descriptions.Item>
                      <Descriptions.Item label="Activity and Organization Experiences">
                        {item.experience ? item.experience : 'N/A'}
                      </Descriptions.Item>
                    </Descriptions>
                    <Divider />
                  </React.Fragment>
                ))
              }
            </React.Fragment>
          :
            <React.Fragment>
              <Text>N/A</Text>
              <Divider />
            </React.Fragment>
        }
      </Col>
    </Row>
  )
}
