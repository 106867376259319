import React, { Component } from 'react';
import { Form, Row, Col, Input } from 'antd';

export default class AddUniform extends Component {
  render() {
    const { 
      formRef, 
      onSubmitAdd, 
    } = this.props;

    return (
      <React.Fragment>
        <Form ref={formRef} onFinish={onSubmitAdd} layout="vertical">
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item 
                label="Shirt Size"
                name="name"
                validateFirst
                initialValue={null}
                rules={[
                  { max: 50, message: '50 characters only' },
                  { min: 5, message: 'Name must be at least 5 characters' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item 
                label="Pant Size"
                name="organization"
                validateFirst
                initialValue={null}
                rules={[
                  { max: 50, message: '50 characters only' },
                  { min: 5, message: 'Organization must be at least 5 characters' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item 
                label="Shoes Size"
                name="organization"
                validateFirst
                initialValue={null}
                rules={[
                  { max: 50, message: '50 characters only' },
                  { min: 5, message: 'Organization must be at least 5 characters' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}

