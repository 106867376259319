import { API } from '../../../../config';
import { errorHandler } from '../../auth/errorAction';

export const unmountListJobDestinations = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_JOB_DESTINATIONS'})
}

export const unmountDetailJobDestinations = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_JOB_DESTINATIONS'})
}

export const unmountListJobDestinationsGroup = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_JOB_DESTINATIONS_GROUP'})
}

export const unmountMasterJobDestinations = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_JOB_DESTINATIONS'})
}

export const listJobDestinations = (meta, successCB, failedCB) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_JOB_DESTINATIONS' })
  return API.GET('/job-destination/list', meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_JOB_DESTINATIONS_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
    return successCB && successCB(response)
  }).catch((err) => {
    return dispatch(errorHandler(
      failedCB && failedCB(err),
      err, 
      dispatch({ type: 'LOAD_DATA_JOB_DESTINATIONS_FAILED' }), 
    ))
  })
}

export const detailJobDestination = (id, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DETAIL_JOB_DESTINATIONS' })
  const params = {
    'id': id
  }
  API.GET('/job-destination/getById', params).then((response) => {
    dispatch({ 
      type: 'LOAD_DETAIL_JOB_DESTINATIONS_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DETAIL_JOB_DESTINATIONS_FAILED' }), 
      ))
    )
  })
}

export const masterJobDestinations = (successCB, failedCB) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_JOB_DESTINATIONS' })
  return API.GET('/job-destination/index').then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_JOB_DESTINATIONS_SUCCESS', 
      payload: { data: response.data }
    })
    return successCB && successCB(response.data)
  }).catch((err) => {
    failedCB && failedCB()
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_MASTER_JOB_DESTINATIONS_FAILED' }), 
    ))
  })
}

export const listJobDestinationGroup = (successCB, failedCB) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_JOB_DESTINATIONS_GROUP' })
  return API.GET('/job-destination/getDestination').then((response) => {
    dispatch({
      type: 'LOAD_DATA_JOB_DESTINATIONS_GROUP_SUCCESS', 
      payload: { data: response.data }
    })
    return successCB && successCB(response.data)
  }).catch((err) => {
    failedCB && failedCB()
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_JOB_DESTINATIONS_GROUP_FAILED' }), 
    ))
  })
}

export const createJobDestination = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/job-destination/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const editJobDestination = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/job-destination/update', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const enableJobDestination = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/job-destination/enable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const disableJobDestination = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/job-destination/disable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}