import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loading } from '../../../../../../components';
import { Row, Col, Card, Tabs } from 'antd';
import { 
  listJobsApplied,
  listApplicant, 
  unmountListJobsApplied,
  unmountListApplicant 
} from '../../../../../../redux/actions/jobs/jobsAction';

import Applied from './Phase/Applied'
import Shortlisted from './Phase/Shortlisted'
import Assessment from './Phase/Assessment';
import Qualified from './Phase/Qualified';
import Interview from './Phase/Interview';
import Offered from './Phase/Offered';
import Hired from './Phase/Hired';

class ActiveApplicants extends Component {
  
  render() {
    const { getActiveApplicant: { data, loading }, match: { params }, handleTabsPhase } = this.props;

    if(loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Row gutter={[16, 8]}>
          {/* Content */}
          <Col span={24}>
            <Card title="Active Applicants">
              <Row gutter={[16, 24]}>
                <Col span={24}>
                  <Tabs onTabClick={handleTabsPhase} activeKey={params.phaseId}>
                    <Tabs.TabPane tab="Applied" key="0">
                      <Applied 
                        {...this.props}
                      />
                    </Tabs.TabPane>
                    {
                      data.listPhase.map((item, i) => 
                        <Tabs.TabPane tab={item.title} key={item.phaseId}>
                          {
                            item.title === 'Shortlisted' ?
                              <Shortlisted 
                                {...this.props}
                              />
                            : item.title === 'Assessment' ?
                              <Assessment 
                                {...this.props}
                              />
                            : item.title === 'Qualified' ?
                              <Qualified 
                                {...this.props}
                              />
                            : item.title === 'Interview' ?
                              <Interview 
                                {...this.props}
                              />
                            : item.title === 'Offered' ?
                              <Offered 
                                {...this.props}
                              />
                            : item.title === 'Hired' ?
                              <Hired 
                                {...this.props}
                              />
                            : null
                          }
                        </Tabs.TabPane>
                      )
                    }
                  </Tabs>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountListApplied, unmountGetApplicant } = this.props;
    unmountListApplied()
    unmountGetApplicant()
  }
}

const mapStateToProps = (state) => ({
  getApplied          : state.jobs.applied,
  getApplicant        : state.jobs.listApplicant,
})

const mapDispatchToProps = {
  actionListApplied         : listJobsApplied,
  actionGetApplicant        : listApplicant,
  unmountListApplied        : unmountListJobsApplied,
  unmountGetApplicant       : unmountListApplicant,


}

export default connect(mapStateToProps, mapDispatchToProps)(ActiveApplicants)
