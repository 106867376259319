import React, { Component } from 'react';
import { Table } from 'antd';
import { columns } from './columns';

export default class HiringPipeline extends Component {

  componentDidMount() {
    const { actionGetPipelineHiring, menuHiring } = this.props;
    return actionGetPipelineHiring('dashboard_agency', menuHiring)
  }
  
  render() {
    const { getPipelineHiring: { loading, data } } = this.props;
    return (
      <React.Fragment>
        <Table 
          rowKey={(i) => i.id} 
          dataSource={data} 
          columns={columns()} 
          pagination={false} 
          loading={loading} 
        />
      </React.Fragment>
    )
  }
}
