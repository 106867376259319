import { API } from '../../../config';
import { errorHandler } from '../auth/errorAction';

export const unmountListBankAccount = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_BANK_ACCOUNT'})
}

export const unmountDetailBankAccount = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_BANK_ACCOUNT'})
}

export const unmountMasterBankAccount = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_BANK_ACCOUNT'})
}

export const listBankAccount = (meta) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_BANK_ACCOUNT' })
  return API.GET('/bank_account/list', meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_BANK_ACCOUNT_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_BANK_ACCOUNT_FAILED' }), 
    ))
  })
}

export const detailBankAccount = (id, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DETAIL_BANK_ACCOUNT' })
  const params = {
    'id': id
  }
  API.GET('/bank_account/getById', params).then((response) => {
    dispatch({ 
      type: 'LOAD_DETAIL_BANK_ACCOUNT_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DETAIL_BANK_ACCOUNT_FAILED' }), 
      ))
    )
  })
}

export const masterBankAccount = (successCB, failedCB) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_BANK_ACCOUNT' })
  return API.GET('/bank_account/index').then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_BANK_ACCOUNT_SUCCESS', 
      payload: { data: response.data }
    })
    return successCB && successCB(response.data)
  }).catch((err) => {
    failedCB && failedCB()
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_MASTER_BANK_ACCOUNT_FAILED' }), 
    ))
  })
}

export const createBankAccount = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/bank_account/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const editBankAccount = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS('/bank_account/update', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const enableBankAccount = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/bank_account/enable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const disableBankAccount = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/bank_account/disable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}