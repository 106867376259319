import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import { columns } from './columns';
import { Link } from 'react-router-dom';
import { HeaderTable, Loading } from '../../../components';
import { ReadOutlined, PlusOutlined } from '@ant-design/icons';
import { PageHeader, Row, Col, Table, Button, Breadcrumb, Pagination, Modal, message } from 'antd';
import { checkTicketCourse, disableCourse, enableCourse, listCourse, unmountListCourse } from '../../../redux/actions/course/courseAction';
import { masterCategory, unmountMasterCategory } from '../../../redux/actions/category/categoryAction';

const { confirm } = Modal

class Course extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      selectedRowKeys: [],
      sortedInfo: null,
      meta: {
        page: 1,
        perpage: 10,
        field: null,
        sort: null,
        search: null,
        status: null,
        startDate: null,
        endDate: null
      }
    }
    this.onSearch = debounce(this.onSearch.bind(this), 900)
  }
  
  componentDidMount() {
    const { meta } = this.state;
    const { actionGetData, actionGetCategory } = this.props;
    actionGetData(meta)
    actionGetCategory()
  }

  onRegistered = value => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.startDate = value.startDate
    meta.endDate = value.endDate
    return actionGetData(meta)
  }

  onStatus = value => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.status = value
    return actionGetData(meta)
  }

  onCategory = value => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.categoryId = value
    return actionGetData(meta)
  }

  onSearch = value => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.search = value === '' ? null : value
    return actionGetData(meta)
  }

  sorter = (pagination, filters, sorter) => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.field = sorter.columnKey
    meta.sort = sorter.order ? sorter.order === "ascend" ? 'asc' : 'desc' : null
    return this.setState({ sortedInfo: sorter }, () => {
      return actionGetData(meta)
    })
  };

  pagination = (page, perpage) => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = page
    meta.perpage = perpage
    return actionGetData(meta)
  }

  handleEnable = (value) => {
    const { meta } = this.state
    const { actionEnable, actionCheckTicketCourse, actionGetData, history } = this.props

    actionCheckTicketCourse(value, (res) => {
      if(res){
        return this.setState({ submitLoading: true }, () => {
          return actionEnable(value, (res) => {
            message.success('Successfully changed to active')
            this.setState({ submitLoading: false })
            return actionGetData(meta)
          }, (err) => message.error(err))
        })
      }else{
        confirm({
          title: 'Unable to change the status to inactive as there are no available tickets for this course. If you want to change it to active you must create at least one ticket.',
          onOk() {
            history.push(`/course/detail/${value}`)
          },
          width: 600
        });
      }
    })
  }

  // handleEnable = (value) => {
  //   const { meta } = this.state;
  //   const { actionEnable, actionGetData } = this.props;
  //   return actionEnable(value, () => {
  //     message.success('Successfully changed to active')
  //     return actionGetData(meta)
  //   }, (err) => message.error(err))
  // }

  handleDisable = (value) => {
    const { meta } = this.state;
    const { actionDisable, actionGetData } = this.props;
    return actionDisable(value, () => {
      message.success('Successfully changed to inactive')
      return actionGetData(meta)
    }, (err) => message.error(err))
  }

  onClickRow = (record) => {
    this.props.history.push(`/course/detail/${record.id}`)
  }

  onClickEdit = (id) => {
    this.props.history.push(`/course/edit/${id}`)
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  render() {
    const { selectedRowKeys, sortedInfo } = this.state;
    const { getData: { data, loading, pagination }, getCategory } = this.props;

    if(getCategory.loading){
      return <Loading />
    }

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const defaultStatusFilter = [
      { value: null, name: 'All' },
      { value: 'ACTIVE', name: 'Active' },
      { value: 'DRAFT', name: 'Draft' },
      { value: 'INACTIVE', name: 'Inactive' },
    ]

    const objDefault = {
      value: null, name: 'All'
    }

    const defaultCategoryFilter = getCategory.data.map((item) => {
      item.value = item.id
      return item
    })
    defaultCategoryFilter.unshift(objDefault)

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><ReadOutlined /> Courses</Breadcrumb.Item>
              <Breadcrumb.Item>Courses List</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          { /* Page Header */}
          <Col span={24}>
            <PageHeader 
              className="site-page-header" 
              title='Courses List' 
              extra={[
                <Link key="1" to="/course/create">
                  <Button className="btn-create-primary" type="primary" icon={<PlusOutlined />} ghost>
                    Add Course
                  </Button>
                </Link>
                ,
              ]}
            />
          </Col>
          {/* Filter */}
          <Col span={24}>
            <HeaderTable 
              fase1={{ key: 'type', value: defaultStatusFilter, valueCategory: defaultCategoryFilter}}
              onSearch={this.onSearch} 
              onStatus={this.onStatus} 
              onRegistered={this.onRegistered}
              tooltipDate="Filter by Created Date"
              tooltipStatus="Filter by Status"
              placeholder="Search by Number, Name"
              onCategory={this.onCategory}
              tooltipCategory="Filter by Category"
              event={true}
            />
          </Col>
          {/* Table */}
          <Col span={24}>
            <Table 
              size="small" 
              rowKey={(i) => i.id} 
              pagination={false}
              onChange={this.sorter}
              dataSource={data} 
              loading={loading} 
              scroll={{ x: 1500 }}
              rowSelection={rowSelection}
              // onRow={(r) => ({ onClick: () => this.onClickRow(r) })}
              rowClassName={(record) => record.id ? 'row-pointer' : null}
              columns={columns(sortedInfo, this.onClickEdit, this.handleEnable, this.handleDisable, this.onClickRow)}  
            />
          </Col>
          {/* Pagination */}
          <Col span={24}>
            <Pagination
              total={pagination.total}
              onChange={this.pagination}
              current={pagination.page}
              pageSize={pagination.perpage}
              showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total} Data`}
            />
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetData, unmountGetCategory } = this.props;
    unmountGetData()
    unmountGetCategory()
  }
}

const mapStateToProps = (state) => ({
  getData       : state.course.list,
  getCategory   : state.category.master,
})

const mapDispatchToProps = {
  actionGetData       : listCourse,
  actionEnable        : enableCourse,
  actionDisable       : disableCourse,
  actionGetCategory   : masterCategory,
  actionCheckTicketCourse : checkTicketCourse,
  unmountGetCategory  : unmountMasterCategory,
  unmountGetData      : unmountListCourse
}

export default connect(mapStateToProps, mapDispatchToProps)(Course)
