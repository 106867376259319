import React, { Component } from 'react';
import { Layout, Row, Col } from 'antd';
const { Content } = Layout;

export default class ContentMenu extends Component {
  render() {
    const { children } = this.props;
    return (
      <React.Fragment>
        <Content style={{ margin: '80px 20px', overflow: 'initial', zIndex: 1 }}>
          <Row gutter={16}>
            <Col sm={24} md={24}>
              {children}
            </Col>
          </Row>
        </Content>
      </React.Fragment>
    )
  }
}
