import React, { Component } from 'react';
import { Skeleton } from 'antd';
import { Pie } from '@reactchartjs/react-chart.js';

export default class OpeningStatus extends Component {

  render() {
    const { getJobOpeningStatus: { loading, data } } = this.props;
    if(loading){
      return <Skeleton />
    }
    const dataChartPie = {
      labels: data.labels,
      datasets: [
        {
          label: '# of Votes',
          data: data.value,
          backgroundColor: [
            '#d1f2eb',
            '#73c6b6',
            '#138d75',
            '#d6dbdf',
          ],
          borderColor: [
            '#d1f2eb',
            '#73c6b6',
            '#138d75',
            '#d6dbdf',
          ],
          borderWidth: 1,
        },
      ],
    };
    return (
      <React.Fragment>
        <Pie data={dataChartPie} width={200} height={100} />
      </React.Fragment>
    )
  }
}
