export const paymentMethod = [
  {
    "value": "TRANSFER",
    "name": "Bank Transfer"
  },
  {
    "value": "ON_THE_SPOT",
    "name": "On the spot"
  },
  {
    "value": "VIRTUAL_ACCOUNT",
    "name": "Virtual Account"
  },
  {
    "value": "CREDIT_CARD",
    "name": "Kartu Kredit"
  }
]