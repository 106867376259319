import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading } from '../../../../../components';
import { InfoCircleOutlined, DatabaseOutlined } from '@ant-design/icons';
import { Col, Form, Row, Input, Button, Select, PageHeader, message, Card, Breadcrumb } from 'antd';
import { createJobDestination, masterJobDestinations, unmountMasterJobDestinations } from '../../../../../redux/actions/master/jobDestinations/jobDestinationsAction';

class CreateMasterJobDestinations extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false
    }
  }

  componentDidMount() {
    const { actionGetJobDestinations } = this.props;
    return actionGetJobDestinations()
  }

  onFinish = (values) => {
    const { actionCreate, history } = this.props;
    this.setState({ submitLoading: true })
    return actionCreate(values, () => {
      this.setState({ submitLoading: false }, () => {
        message.success('Data created successfully')
        history.push('/master/job-destinations')
      })
    }, (err) => {
      this.setState({ submitLoading: false }, () => message.error(err))
    })
  }

  render() {
    const { submitLoading } = this.state;
    const { getJobDestinations: { data, loading } } = this.props;

    if(loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Form onFinish={this.onFinish} layout="vertical">
          <Row style={{ position: 'sticky', zIndex: 1, width: '100%', top: 62, right: 0, marginTop: -20, marginBottom: 8, paddingBottom: 12, paddingTop: 16, backgroundColor: '#f2f2f2' }}>
            {/* Breadcrumb */}
            <Col span={24}>
              <Breadcrumb>
                <Breadcrumb.Item><DatabaseOutlined /> Master Data</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link className="link" to="/master/job-destinations">
                    Master Data List Job Destinations
                  </Link> 
                </Breadcrumb.Item>
                <Breadcrumb.Item>Job Positions</Breadcrumb.Item>
                <Breadcrumb.Item>Create</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            { /* Page Header */}
            <Col span={24}>
              <PageHeader 
                className="site-page-header" 
                title="Create a Job Destination"
                extra={[
                  <Button key="2" className="btn-save-primary" type="default" onClick={() => window.history.back()}>Cancel</Button>,
                  <Button key="1" className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading}>Create</Button>
                ]}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            { /* Form */}
            <Col span={24}>
              <Card>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item 
                      label="Parent Destinations" 
                      name="parentId"
                      tooltip={{ 
                        title: 'If this job destination is a country, it doesn’t have any parent destination. Choose: No Parent (stand-alone).', 
                        icon: <InfoCircleOutlined style={{ color: '#2586eb' }} />
                      }}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          data?.map((item, i) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Destination Name" 
                      name="name" 
                      validateFirst
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 3, message: 'Name must be at least 3 characters long' },
                        { max: 50, message: '50 characters only' },
                        { pattern: new RegExp("^[a-zA-Z ]+$"), message: 'Number and special character are not allowed' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Location Status" 
                      name="isDomestic" 
                      tooltip={{ 
                        title: 'Inactive means this job destination isn’t used in master data', 
                        icon: <InfoCircleOutlined style={{ color: '#2586eb' }} />
                      }}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select>
                        <Select.Option value={true}>Domestic</Select.Option>
                        <Select.Option value={false}>International</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Will it be displayed in the candidate's side?" 
                      name="isDisplay" 
                      initialValue={false}
                      tooltip={{ 
                        title: 'If you choose yes, this job destinantion will be displayed in the Job Preferences option', 
                        icon: <InfoCircleOutlined style={{ color: '#2586eb' }} />
                      }}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select>
                        <Select.Option value={true}>Yes</Select.Option>
                        <Select.Option value={false}>No</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Status" 
                      name="enabled" 
                      initialValue={true}
                      tooltip={{ 
                        title: 'Inactive means this job destinations isn’t used in master data', 
                        icon: <InfoCircleOutlined style={{ color: '#2586eb' }} />
                      }}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select>
                        <Select.Option key="1" value={true}>Active</Select.Option>
                        <Select.Option key="2" value={false}>Inactive</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Description" 
                      name="description"
                      initialValue=''
                      rules={[
                        { max: 50, message: '50 characters only' },
                      ]}
                    >
                      <Input placeholder="Description" />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetJobDestinations } = this.props;
    return unmountGetJobDestinations()
  }
}

const mapStateToProps = (state) => ({
  getJobDestinations: state.jobDestinations.master
})

const mapDispatchToProps = {
  actionCreate              : createJobDestination,
  actionGetJobDestinations  : masterJobDestinations,
  unmountGetJobDestinations : unmountMasterJobDestinations
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateMasterJobDestinations)
