import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import { columns } from './columns';
import { Link } from 'react-router-dom';
import { HeaderTable, Loading } from '../../../components';
import { BookOutlined } from '@ant-design/icons';
import { PageHeader, Row, Col, Table, Breadcrumb, Pagination, Card, Menu, Modal, Typography } from 'antd';
import { disableEvent, enableEvent } from '../../../redux/actions/event/eventAction';
import { listTransaction, unmountListTransaction } from '../../../redux/actions/transaction/transactionAction';
const { Text } = Typography

class Transaction extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      selectedRowKeys: [],
      sortedInfo: null,
      current: 'all',
      loadingMenu: false,
      meta: {
        page: 1,
        perpage: 10,
        field: 'orderDate',
        sort: 'desc',
        search: null,
        status: null,
        startDate: null,
        endDate: null
      }
    }
    this.onSearch = debounce(this.onSearch.bind(this), 900)
  }
  
  componentDidMount() {
    const { meta } = this.state;
    const { actionGetData, location: { state } } = this.props;
    meta.status = state?.status ? state?.status : null
    this.setState({ current: state?.status ? state?.status : 'all' , loadingMenu: true, meta}, () => {
      this.setState({ loadingMenu: false })
      return actionGetData(meta)
    })
  }

  onRegistered = value => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.startDate = value.startDate
    meta.endDate = value.endDate
    return actionGetData(meta)
  }

  onStatus = value => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.paymentType = value
    return actionGetData(meta)
  }

  onXpertType = value => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    // meta.categoryId = value
    return actionGetData(meta)
  }

  onSearch = value => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.search = value === '' ? null : value
    return actionGetData(meta)
  }

  sorter = (pagination, filters, sorter) => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.field = sorter.columnKey
    meta.sort = sorter.order ? sorter.order === "ascend" ? 'asc' : 'desc' : null
    return this.setState({ sortedInfo: sorter }, () => {
      return actionGetData(meta)
    })
  };

  pagination = (page, perpage) => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = page
    meta.perpage = perpage
    return actionGetData(meta)
  }

  onClickRow = (record) => {
    this.props.history.push(`/transaction/detail/${record.id}`)
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  handleMenu = (key) => {
    const { meta } = this.state
    meta.page = 1
    meta.perpage = 10
    meta.field = 'orderDate'
    meta.sort = 'desc'
    meta.search = null
    meta.status = null
    meta.startDate = null
    meta.endDate = null
    meta.status = key !== 'all' ? key : null
    this.setState({ current: key , loadingMenu: true, meta}, () => {
      const { actionGetData } = this.props;
      this.setState({ loadingMenu: false })
      return actionGetData(meta)
    })
  }

  showRejectReason = (val) => {
    Modal.info({
      title: 'Rejection reason:',
      content: (
        <Text>{val}</Text>
      ),
      onOk() {},
    });
  }

  render() {
    const { selectedRowKeys, current, loadingMenu } = this.state;
    const { getData: { data, loading, pagination } } = this.props;

    if(loadingMenu){
      return <Loading />
    }

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const defaultStatusFilter = [
      { value: null, name: 'All' },
      { value: 'TRANSFER', name: 'Bank Transfer' },
      { value: 'VIRTUAL_ACCOUNT', name: 'Virtual Account' },
      { value: 'ON_THE_SPOT', name: 'On the spot' },
      { value: 'CREDIT_CARD', name: 'Kartu Kredit' },
    ]

    const defaultXpertTypeFilter = [
      { value: null, name: 'All' },
      { value: 'EVENT', name: 'Event' },
      { value: 'COURSE', name: 'Course' },
      { value: 'TRAINING', name: 'Training and Certifications' },
    ]

    const menuTransaction = [
      { title: 'All Transactions', sortOrder: 'all' },
      // { title: 'Free', sortOrder: 'free' },
      { title: 'Unpaid', sortOrder: 'WAITING_FOR_PAYMENT' },
      { title: 'Need to verify', sortOrder: 'WAITING_FOR_CONFIRM' },
      { title: 'Confirmed', sortOrder: 'PAYMENT_CONFIRM' },
      { title: 'Cancelled', sortOrder: 'CANCELED' },
      { title: 'Rejected', sortOrder: 'REJECTED' },
      { title: 'Expired', sortOrder: 'EXPIRED' },
    ]

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><BookOutlined /> Transaction</Breadcrumb.Item>
              <Breadcrumb.Item>Transaction List</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          { /* Page Header */}
          <Col span={24}>
            <PageHeader 
              className="site-page-header" 
              title='Transaction List' 
            />
          </Col>
          
          <Col span={24}>
            <Card
              style={{ border: 'none' }}
              bordered={false}
            >
              <Row gutter={[16, 24]}>
                <Col span={24}>
                  <Menu selectedKeys={[current]} mode="horizontal" style={{ background: 'none'  }}>
                    {
                      menuTransaction.map((item, i) => (
                        <Menu.Item key={`${item.sortOrder}`} onClick={() => this.handleMenu(item.sortOrder)}>
                          <Link key={i} to="#">
                            {item.title} 
                          </Link>
                        </Menu.Item>
                      ))
                    }
                  </Menu>
                </Col>
                {/* Filter */}
                <Col span={24}>
                <HeaderTable 
                  fase1={{ key: 'type', value: defaultStatusFilter, valueXpertType: defaultXpertTypeFilter}}
                  onSearch={this.onSearch} 
                  onStatus={this.onStatus} 
                  onRegistered={this.onRegistered}
                  tooltipDate="Filter by Created Date"
                  tooltipStatus="Filter by Payment Method"
                  placeholder="Search by Order Number, Customer Name, Xpert Title, Provider"
                  onXpertType={this.onXpertType}
                  tooltipXpertType="Filter by Expert Type"
                  transaction={true}
                />
                </Col>
              </Row>
            </Card>
          </Col>
          {/* Table */}
          <Col span={24}>
            <Table 
              size="small" 
              rowKey={(i) => i.id} 
              pagination={false}
              onChange={this.sorter}
              dataSource={data} 
              loading={loading} 
              scroll={{ x: 1500 }}
              rowSelection={rowSelection}
              // onRow={(r) => ({ onClick: () => this.onClickRow(r) })}
              rowClassName={(record) => record.id ? 'row-pointer' : null}
              columns={columns(this.onClickRow, current, this.showRejectReason)}  
            />
            </Col>
            {/* Pagination */}
            <Col span={24}>
            <Pagination
              total={pagination.total}
              onChange={this.pagination}
              current={pagination.page}
              pageSize={pagination.perpage}
              showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total} Data`}
            />
            </Col>
          
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetData } = this.props;
    return unmountGetData()
  }
}

const mapStateToProps = (state) => ({
  getData       : state.transaction.list,
})

const mapDispatchToProps = {
  actionGetData       : listTransaction,
  actionEnable        : enableEvent,
  actionDisable       : disableEvent,
  unmountGetData      : unmountListTransaction
}

export default connect(mapStateToProps, mapDispatchToProps)(Transaction)
