import React from 'react'
import moment from 'moment'
import { Row, Col, Typography, Descriptions } from 'antd'

const { Text } = Typography

export default function PersonalInfo(props) {
  const { data } = props
  return (
    <Row gutter={16}>
      {
        data.isAviation ?
          <Col span={24}>
            <Descriptions title="Personal Information" layout="vertical" size="small" column={3}>
              <Descriptions.Item label="Place of Birth">
                {data.placeOfBirth ? data.placeOfBirth : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Date of Birth">
                {data.birthdate ? moment(data.birthdate, 'DD/MM/YYYY').format('DD MMM YYYY') : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Nationality">
                {data.nationality ? data.nationality : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Marital Status">
                {
                  data.marital ?
                  <Text>
                    {data.marital === 'SINGLE' && 'Single'}
                    {data.marital === 'MARRIED' && 'Married'}
                    {data.marital === 'PROTESTAN' && 'Protestan'}
                    {data.marital === 'DIVORCE' && 'Divorce'}
                    {data.marital === 'WIDOWED' && 'Widowed'}
                  </Text>
                  : 
                  'N/A'
                }
              </Descriptions.Item>

              <Descriptions.Item label="Gender">
                {
                  data.gender ?
                  <Text>
                    {data.gender === 'MALE' && 'Male'}
                    {data.gender === 'FEMALE' && 'Female'}
                  </Text>
                  : 
                  'N/A'
                }
              </Descriptions.Item>
              <Descriptions.Item label="Religion">
                {
                  data.religion ?
                  <Text>
                    {data.religion === 'ISLAM' && 'Islam'}
                    {data.religion === 'CATHOLIC' && 'Catholic'}
                    {data.religion === 'PROTESTAN' && 'Protestan'}
                    {data.religion === 'BUDDHA' && 'Buddha'}
                    {data.religion === 'HINDU' && 'Hindu'}
                    {data.religion === 'OTHERS' && 'Others'}
                  </Text>
                  : 
                  'N/A'
                }
              </Descriptions.Item>
              <Descriptions.Item label="Blood Type">
                {data.bloodType ? data.bloodType : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Height (cm)">
                {data.height ? data.height : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Weight (kg)">
                {data.weight ? data.weight : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Arm Reach (cm)">
                {data.armReach ? data.armReach : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label={<Text type="secondary">Heart Trouble</Text>}>
                {data.listFamilyHistory[0].flagStatus === true ? 'Yes' : 'No'}
              </Descriptions.Item>
              <Descriptions.Item label={<Text type="secondary">Diabetes</Text>}>
                {data.listFamilyHistory[1].flagStatus ? 'Yes' : 'No'}
              </Descriptions.Item>
              <Descriptions.Item label={<Text type="secondary">Convulsion</Text>}>
                {data.listFamilyHistory[2].flagStatus ? 'Yes' : 'No'}
              </Descriptions.Item>
              <Descriptions.Item label={<Text type="secondary">Cancer</Text>}>
                {data.listFamilyHistory[3].flagStatus ? 'Yes' : 'No'}
              </Descriptions.Item>
              <Descriptions.Item label={<Text type="secondary">Tubercolosis</Text>}>
                {data.listFamilyHistory[4].flagStatus ? 'Yes' : 'No'}
              </Descriptions.Item>
              <Descriptions.Item label={<Text type="secondary">Allergies</Text>}>
                {data.listFamilyHistory[5].flagStatus ? 'Yes' : 'No'}
              </Descriptions.Item>
              <Descriptions.Item label={<Text type="secondary">Commited Suicides</Text>}>
                {data.listFamilyHistory[6].flagStatus ? 'Yes' : 'No'}
              </Descriptions.Item>
              <Descriptions.Item label="ID Card Number (KTP)">
                {data.identificationId ? data.identificationId : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Country (ID Card)">
                {data.countryName ? data.countryName : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Province (ID Card)">
                {data.provinceName ? data.provinceName : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="City (ID Card)">
                {data.cityName ? data.cityName : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Sub-district (ID card)">
                {data.subDistrictName ? data.subDistrictName : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Village (ID card)">
                {data.villageName ? data.villageName : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Postal Code (ID card)">
                {data.zipCode ? data.zipCode : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Street Address">
                {data.address ? data.address : 'N/A'}
              </Descriptions.Item>
            
            </Descriptions>
          </Col>
        :
          <Col span={24}>
            {
              data.email.split("@")[1] === 'student.binawan.ac.id' ?
                <Descriptions title="Personal Information" layout="vertical" size="small" column={3}>
                  <Descriptions.Item label="Place of Birth">
                    {data.placeOfBirth ? data.placeOfBirth : 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Date of Birth">
                    {data.birthdate ? moment(data.birthdate, 'DD/MM/YYYY').format('DD MMM YYYY') : 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Gender">
                    {
                      data.gender ?
                      <Text>
                        {data.gender === 'MALE' && 'Male'}
                        {data.gender === 'FEMALE' && 'Female'}
                      </Text>
                      : 
                      'N/A'
                    }
                  </Descriptions.Item>
                  <Descriptions.Item label="Blood Type">
                    {data.bloodType ? data.bloodType : 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Height (cm)">
                    {data.height ? data.height : 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Weight (kg)">
                    {data.weight ? data.weight : 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Marital Status">
                    {
                      data.marital ?
                      <Text>
                        {data.marital === 'SINGLE' && 'Single'}
                        {data.marital === 'MARRIED' && 'Married'}
                        {data.marital === 'PROTESTAN' && 'Protestan'}
                        {data.marital === 'DIVORCE' && 'Divorce'}
                        {data.marital === 'WIDOWED' && 'Widowed'}
                      </Text>
                      : 
                      'N/A'
                    }
                  </Descriptions.Item>
                  <Descriptions.Item label="Religion">
                    {
                      data.religion ?
                      <Text>
                        {data.religion === 'ISLAM' && 'Islam'}
                        {data.religion === 'CATHOLIC' && 'Catholic'}
                        {data.religion === 'PROTESTAN' && 'Protestan'}
                        {data.religion === 'BUDDHA' && 'Buddha'}
                        {data.religion === 'HINDU' && 'Hindu'}
                        {data.religion === 'OTHERS' && 'Others'}
                      </Text>
                      : 
                      'N/A'
                    }
                  </Descriptions.Item>
                  <Descriptions.Item label="Nationality">
                    {data.nationality ? data.nationality : 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label="ID Card Number (KTP)">
                    {data.identificationId ? data.identificationId : 'N/A'}
                  </Descriptions.Item>
                </Descriptions>
              :
                <Descriptions title="Personal Information" layout="vertical" size="small" column={3}>
                  <Descriptions.Item label="Date of Birth">
                    {data.birthdate ? moment(data.birthdate, 'DD/MM/YYYY').format('DD MMM YYYY') : 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Height (cm)">
                    {data.height ? data.height : 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label="City (ID Card)">
                    {data.cityName ? data.cityName : 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Nationality">
                    {data.nationality ? data.nationality : 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Weight (kg)">
                    {data.weight ? data.weight : 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Sub-district (ID card)">
                    {data.subDistrictName ? data.subDistrictName : 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Place of Birth">
                    {data.placeOfBirth ? data.placeOfBirth : 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Religion">
                    {
                      data.religion ?
                      <Text>
                        {data.religion === 'ISLAM' && 'Islam'}
                        {data.religion === 'CATHOLIC' && 'Catholic'}
                        {data.religion === 'PROTESTAN' && 'Protestan'}
                        {data.religion === 'BUDDHA' && 'Buddha'}
                        {data.religion === 'HINDU' && 'Hindu'}
                        {data.religion === 'OTHERS' && 'Others'}
                      </Text>
                      : 
                      'N/A'
                    }
                  </Descriptions.Item>
                  <Descriptions.Item label="Village (ID card)">
                    {data.villageName ? data.villageName : 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Marital Status">
                    {
                      data.marital ?
                      <Text>
                        {data.marital === 'SINGLE' && 'Single'}
                        {data.marital === 'MARRIED' && 'Married'}
                        {data.marital === 'PROTESTAN' && 'Protestan'}
                        {data.marital === 'DIVORCE' && 'Divorce'}
                        {data.marital === 'WIDOWED' && 'Widowed'}
                      </Text>
                      : 
                      'N/A'
                    }
                  </Descriptions.Item>
                  <Descriptions.Item label="ID Card Number (KTP)">
                    {data.identificationId ? data.identificationId : 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Postal Code (ID card)">
                    {data.zipCode ? data.zipCode : 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Gender">
                    {
                      data.gender ?
                      <Text>
                        {data.gender === 'MALE' && 'Male'}
                        {data.gender === 'FEMALE' && 'Female'}
                      </Text>
                      : 
                      'N/A'
                    }
                  </Descriptions.Item>
                  <Descriptions.Item label="Country (ID Card)">
                    {data.countryName ? data.countryName : 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Street Address">
                    {data.address ? data.address : 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Blood Type">
                    {data.bloodType ? data.bloodType : 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Province (ID Card)">
                    {data.provinceName ? data.provinceName : 'N/A'}
                  </Descriptions.Item>
                </Descriptions>
          
            }
          </Col>
      }
    </Row>
  )
}
