import React from 'react';
import { Link } from 'react-router-dom';
import { CloseOutlined, FileDoneOutlined } from '@ant-design/icons';
import { Button, Space, Typography, Tooltip, Badge } from 'antd';
const { Text } = Typography;

export const columns = (onPriviewProfile, modalNextStage, showModal, showModalNotes) => {
  return [
    {
      title: 'Number',
      key: 'number',
      fixed: 'left',
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Tooltip title={record.applicantNumber ? record.applicantNumber : "N/A"}>
          <Link to="#" onClick={() => onPriviewProfile(record.candidateId)} style={{ color: '#0076de' }}>
            {record.applicantNumber ? record.applicantNumber : "N/A"}
          </Link>
        </Tooltip>
      )
    },
    {
      title: 'Name',
      key: 'name',
      fixed: 'left',
      width: 60,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Tooltip title={record.name ? record.name : "N/A"}>
          <Link to="#" onClick={() => onPriviewProfile(record.candidateId)} style={{ color: '#0076de' }}>
            {record.name ? record.name : "N/A"}
          </Link>
          <br />
          <Text>{record.age ? record.age : "N/A"}, {record.gender ? record.gender === "MALE" ? "Male" : "Female" : "N/A"}</Text>
        </Tooltip>
      )
    },
    {
      title: 'Last Education',
      key: 'lastEducation',
      width: 80,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Tooltip title={record.lastEducation ? record.lastEducation : "N/A"}>
          <Text>
            {record.lastEducation ? record.lastEducation : "N/A"}
          </Text>
        </Tooltip>
      )
    },
    {
      title: 'Institutions',
      key: 'institutions',
      width: 80,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Tooltip title={record.institution ? record.institution : "N/A"}>
          <Text>{record.institution ? record.institution : "N/A"}</Text>
        </Tooltip>
      )
    },
    {
      title: 'Length of Experience',
      key: 'lengthOfExperience',
      width: 80,
      render: (record) => (
        <Text>
          {record.lengthOfExperience ? record.lengthOfExperience : "N/A"}
        </Text>
      )
    },
    {
      title: '',
      key: 'action',
      width: 160,
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <Space direction="horizontal">
          {
            record.listNotes ?
              <Badge dot offset={[-2, 2]} style={{ width: 10, height: 10 }}>
                <Button 
                  className="btn-create-default" 
                  onClick={() => showModalNotes(record.id, record.listNotes)}
                >
                  <FileDoneOutlined /> Applicant Notes
                </Button>
              </Badge>
            :
              <Button 
                className="btn-create-default" 
                onClick={() => showModalNotes(record.id)}
              >
                <FileDoneOutlined /> Applicant Notes
              </Button>
          }
          <Button 
            className="btn-create-primary" 
            type="primary" 
            ghost 
            onClick={() => modalNextStage(record.id)}
          >
            Shortlisted
          </Button>
          <Tooltip title="Reject">
            <CloseOutlined 
              className="icon-close-delete"
              onClick={() => showModal(record.id)}
              style={{ color: '#b7b2b2', fontSize: 18, cursor: 'pointer' }}  
            />
          </Tooltip>
        </Space>
      )
    },
  ]
}