import React from 'react';
import { Typography, Dropdown, Menu, Tag } from 'antd';
import { EditOutlined, DashOutlined, StopOutlined, CheckCircleOutlined } from '@ant-design/icons';
const { Text } = Typography;

const menu = (record, onClickEdit, handleEnable, handleDisable) => (
  <Menu>
    <Menu.Item icon={<EditOutlined />} onClick={() => onClickEdit(record.id)}>
      Edit
    </Menu.Item>
    {
      record.enabled ?
      <Menu.Item icon={<StopOutlined />} onClick={() => handleDisable(record.id)}>
        Set as inactive
      </Menu.Item>
      :
      <Menu.Item icon={<CheckCircleOutlined />} onClick={() => handleEnable(record.id)}>
        Set as active
      </Menu.Item>
    }
  </Menu>
);

export const columns = (onClickEdit, handleEnable, handleDisable) => {
  return [
    {
      title: 'Account Name',
      key: 'name',
      width: 80,
      render: (record) => (
        <Text>
          {record.name}
        </Text>
      )
    },
    {
      title: 'Bank',
      key: 'bankName',
      width: 80,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Text>
          {record.bankName}
        </Text>
      )
    },
    {
        title: 'Account Number',
        key: 'number',
        width: 80,
        ellipsis: {
          showTitle: false,
        },
        render: (record) => (
          <Text>
            {record.number}
          </Text>
        )
      },
    {
      title: 'Status',
      key: 'status',
      width: 80,
      render: (record) => (
        <React.Fragment>
          {
            record.enabled ? 
            <Tag color="green">Active</Tag>
            : 
            <Tag color="orange">Inactive</Tag>
          }
        </React.Fragment>
      )
    },
    {
      title: '',
      key: 'action',
      width: 30,
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <Dropdown overlay={menu(record, onClickEdit, handleEnable, handleDisable)} placement="bottomCenter" arrow>
          <DashOutlined style={{ fontSize: 20 }} />
        </Dropdown>
      )
    },
  ]
}