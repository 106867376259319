import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Collapse, Row, Col, Button, DatePicker, Checkbox, Input, Select } from 'antd';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
const { Panel } = Collapse;
const { RangePicker } = DatePicker;

class SiderTable extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      gpaScore: []
    }
  }

  onFinish = (values) => {
    const { onSearch } = this.props;
    return onSearch(values)
  }
  
  render() {
    const { gpaScore } = this.state;

    for(let i = 100; i <= 400; i++){
      let str = i.toString();
      let res = str.slice(0,1) +"."+ str.slice(1,3);
      gpaScore.push(res)
    }

    return (
      <React.Fragment>
        <Row gutter={16}>
          <Form onFinish={this.onFinish} layout="inline" style={{ width: '100%' }}>
            <Col sm={24} md={24} style={{ marginBottom: 20 }}>
              <Collapse
                bordered={false}
                expandIcon={({ isActive }) => isActive ? <MinusCircleOutlined /> : <PlusCircleOutlined /> }
                className="site-collapse-custom-collapse"
              >

                <Panel header="Registration Date" key="1" className="site-collapse-custom-panel">
                  <Form.Item name="date">
                    <RangePicker style={{ width: '100%' }} />
                  </Form.Item>
                </Panel>

                <Panel header="Gender" key="2" className="site-collapse-custom-panel">
                  <Form.Item name="gender">
                    <Checkbox.Group>
                      <Row>
                        <Col sm={24} md={24}>
                          <Checkbox value="male" style={{ lineHeight: '32px' }}>
                            Male
                          </Checkbox>
                        </Col>
                        <Col sm={24} md={24}>
                          <Checkbox value="female" style={{ lineHeight: '32px' }}>
                            Female
                          </Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                </Panel>

                <Panel header="Age" key="3" className="site-collapse-custom-panel">
                  <Row>
                    <Col sm={12} md={12}>
                      <Form.Item 
                        name="min"
                        rules={[
                          { pattern: /^[0-9]*$/, message: 'Can only use numbers' },
                        ]}>
                        <Input placeholder="Min"/>
                      </Form.Item>
                    </Col>
                    <Col sm={12} md={12}>
                      <Form.Item 
                        name="max"
                        rules={[
                          { pattern: /^[0-9]*$/, message: 'Can only use numbers' },
                        ]}>
                        <Input placeholder="Max" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>

                <Panel header="Domicile City" key="4" className="site-collapse-custom-panel">
                  <Form.Item name="city">
                    <Select
                      showSearch
                    
                      mode="multiple"
                      placeholder="Select for cities"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Select.Option value="Jakarta Timur">Jakarta Timur</Select.Option>
                      <Select.Option value="Jakarta Barat">Jakarta Barat</Select.Option>
                      <Select.Option value="Jakarta Selatan">Jakarta Selatan</Select.Option>
                      <Select.Option value="Jakarta Pusat">Jakarta Pusat</Select.Option>
                      <Select.Option value="Bekasi">Bekasi</Select.Option>
                      <Select.Option value="Depok">Depok</Select.Option>
                      <Select.Option value="Bogor">Bogor</Select.Option>
                      <Select.Option value="Tanggerang">Tanggerang</Select.Option>
                      <Select.Option value="Bandung">Bandung</Select.Option>
                    </Select>
                  </Form.Item>
                </Panel>

                <Panel header="Desired Job Positions" key="5" className="site-collapse-custom-panel">
                  <Form.Item name="desiredJob">
                    <Select
                      showSearch
                    
                      mode="multiple"
                      placeholder="Select for job positions"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Select.Option value="Assistant Nurse">Assistant Nurse</Select.Option>
                      <Select.Option value="Nurse">Nurse</Select.Option>
                      <Select.Option value="Caregiver">Caregiver</Select.Option>
                      <Select.Option value="Dental Hygienist">Dental Hygienist</Select.Option>
                      <Select.Option value="Dental Nurse">Dental Nurse</Select.Option>
                      <Select.Option value="Doctor">Doctor</Select.Option>
                      <Select.Option value="Food Lab Technician">Food Lab Technician</Select.Option>
                      <Select.Option value="Medical Lab Technician">Medical Lab Technician</Select.Option>
                      <Select.Option value="Physiotherapist">Physiotherapist</Select.Option>
                    </Select>
                  </Form.Item>
                </Panel>

                <Panel header="Education Level" key="6" className="site-collapse-custom-panel">
                  <Form.Item name="education">
                    <Select
                      showSearch
                    
                      mode="multiple"
                      placeholder="Select for education level"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Select.Option value="Elementary School / SD / MI">Elementary School / SD / MI</Select.Option>
                      <Select.Option value="Intermediate School / SMP / MTs">Intermediate School / SMP / MTs</Select.Option>
                      <Select.Option value="High School / SMA / MA">High School / SMA / MA</Select.Option>
                      <Select.Option value="Vocational School / SMK / MAK">Vocational School / SMK / MAK</Select.Option>
                      <Select.Option value="Diploma I">Diploma I</Select.Option>
                      <Select.Option value="Diploma II">Diploma II</Select.Option>
                      <Select.Option value="Diploma III">Diploma III</Select.Option>
                      <Select.Option value="Diploma IV">Diploma IV</Select.Option>
                      <Select.Option value="Bachelor / S1">Bachelor / S1</Select.Option>
                      <Select.Option value="Professional Program">Professional Program</Select.Option>
                      <Select.Option value="Specialty">Specialty</Select.Option>
                      <Select.Option value="Master / S2">Master / S2</Select.Option>
                      <Select.Option value="Doctorate / S3">Doctorate / S3</Select.Option>
                    </Select>
                  </Form.Item>
                </Panel>

                <Panel header="Dicipline" key="7" className="site-collapse-custom-panel">
                  <Form.Item name="dicipline">
                    <Select
                      showSearch
                    
                      mode="multiple"
                      placeholder="Select for Academic Diciplines"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Select.Option value="Elementary School / SD / MI">Elementary School / SD / MI</Select.Option>
                      <Select.Option value="Intermediate School / SMP / MTs">Intermediate School / SMP / MTs</Select.Option>
                      <Select.Option value="High School / SMA / MA">High School / SMA / MA</Select.Option>
                      <Select.Option value="Vocational School / SMK / MAK">Vocational School / SMK / MAK</Select.Option>
                      <Select.Option value="Language / Bahasa">Language / Bahasa</Select.Option>
                      <Select.Option value="Natural Science / IPA">Natural Science / IPA</Select.Option>
                      <Select.Option value="Social Science / IPS">Social Science / IPS</Select.Option>
                      <Select.Option value="Accounting and Finance / Akuntansi dan Keuangan">Accounting and Finance / Akuntansi dan Keuangan</Select.Option>
                      <Select.Option value="Agribusiness Plants / Agribisnis Tanaman">Agribusiness Plants / Agribisnis Tanaman</Select.Option>
                      <Select.Option value="Agribusiness Processing of Agricultural Products / Agribisnis Pengolahan Hasil Pertanian">Agribusiness Processing of Agricultural Products / Agribisnis Pengolahan Hasil Pertanian</Select.Option>
                      <Select.Option value="Agricultural Engineering / Teknik Pertanian">Agricultural Engineering / Teknik Pertanian</Select.Option>
                      <Select.Option value="Aircraft Technology / Teknologi Pesawat Udara">Aircraft Technology / Teknologi Pesawat Udara</Select.Option>
                      <Select.Option value="Animal Health / Kesehatan Hewan">Animal Health / Kesehatan Hewan</Select.Option>
                    </Select>
                  </Form.Item>
                </Panel>

                <Panel header="GPA" key="8" className="site-collapse-custom-panel">
                  <Form.Item name="gpa">
                  <Select
                      showSearch
                    
                      placeholder="Select for GPA"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                     {
                       gpaScore.map((item, i) => (
                         <Select.Option key={i} value={item}>
                           {item}
                         </Select.Option>
                       ))
                     }
                    </Select>
                  </Form.Item>
                </Panel>

                <Panel header="Length of Experience" key="9" className="site-collapse-custom-panel">
                  <Row gutter={16}>
                    <Col sm={24} md={24} style={{ marginBottom: 20 }}>
                      <Form.Item name="jobPosition">
                        <Select
                          showSearch
                        
                          placeholder="Select for Job Position"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                        <Select.Option value="Assistant Nurse">Assistant Nurse</Select.Option>
                          <Select.Option value="Nurse">Nurse</Select.Option>
                          <Select.Option value="Caregiver">Caregiver</Select.Option>
                          <Select.Option value="Dental Hygienist">Dental Hygienist</Select.Option>
                          <Select.Option value="Dental Nurse">Dental Nurse</Select.Option>
                          <Select.Option value="Doctor">Doctor</Select.Option>
                          <Select.Option value="Food Lab Technician">Food Lab Technician</Select.Option>
                          <Select.Option value="Medical Lab Technician">Medical Lab Technician</Select.Option>
                          <Select.Option value="Physiotherapist">Physiotherapist</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col sm={24} md={24}>
                      <Form.Item 
                        name="monthsExperience"
                        rules={[
                          { pattern: /^[0-9]*$/, message: 'Can only use numbers' },
                        ]}>
                        <Input placeholder="Min. Experience" addonAfter="Months"/>
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>

                <Panel header="Referral" key="10" className="site-collapse-custom-panel">
                  <Form.Item 
                    name="referral">
                    <Input placeholder="Insert your referral here" />
                  </Form.Item>
                </Panel>
              </Collapse>
            </Col>
            <Col sm={24} md={24} style={{ textAlign: 'center' }}>
              <Button type="primary" htmlType="submit">Apply Filter</Button>
            </Col>
          </Form>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(SiderTable)
