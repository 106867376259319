import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Menu } from 'antd';
import { FlagOutlined, ApartmentOutlined, EnvironmentOutlined, PushpinOutlined, NumberOutlined, GroupOutlined, ClusterOutlined, BulbOutlined, ReadOutlined, ToolOutlined, GlobalOutlined, BankOutlined, FileTextOutlined } from '@ant-design/icons';

export default function index(props) {
  return (
    <Card >
      <Menu defaultSelectedKeys={[`${props.rowKey}`]}>
        <Menu.Item key="countries" icon={<FlagOutlined />}>
          <Link to="/master/countries">Countries</Link>
        </Menu.Item>
        <Menu.Item key="aboutCountry" icon={<FlagOutlined />}>
          <Link to="/master/about-country">About Countries</Link>
        </Menu.Item>
        <Menu.Item key="provinces" icon={<ApartmentOutlined />}>
          <Link to="/master/provinces">Provinces</Link>
        </Menu.Item>
        <Menu.Item key="cities" icon={<EnvironmentOutlined />}>
          <Link to="/master/cities">Cities</Link>
        </Menu.Item>
        <Menu.Item key="subDistricts" icon={<PushpinOutlined />}>
          <Link to="/master/sub-districts">Sub-Districts (Kecamatan)</Link>
        </Menu.Item>
        <Menu.Item key="villages" icon={<NumberOutlined />}>
          <Link to="/master/villages">Villages (Kelurahan)</Link>
        </Menu.Item>
        <Menu.Item key="jobIndustry" icon={<GroupOutlined />}>
          <Link to="/master/job-industries">Job Industries</Link>
        </Menu.Item>
        <Menu.Item key="jobPosition" icon={<ClusterOutlined />}>
          <Link to="/master/job-positions">Job Positions</Link>
        </Menu.Item>
        <Menu.Item key="jobDestination" icon={<GlobalOutlined />}>
          <Link to="/master/job-destinations">Job Destinations</Link>
        </Menu.Item>
        <Menu.Item key="educationLevel" icon={<BulbOutlined />}>
          <Link to="/master/education-levels">Education Levels</Link>
        </Menu.Item>
        <Menu.Item key="fieldOfStudy" icon={<ReadOutlined />}>
          <Link to="/master/fields-of-study">Fields Of Study</Link>
        </Menu.Item>
        <Menu.Item key="institution" icon={<BankOutlined />}>
          <Link to="/master/institutions">Institutions</Link>
        </Menu.Item>
        <Menu.Item key="skill" icon={<ToolOutlined />}>
          <Link to="/master/skills">Skills</Link>
        </Menu.Item>
        <Menu.Item key="documentTypes" icon={<FileTextOutlined />}>
          <Link to="/master/document-types">Document Types</Link>
        </Menu.Item>
        <Menu.Item key="languageSkills" icon={<GlobalOutlined />}>
          <Link to="/master/language-skills">Language Skills</Link>
        </Menu.Item>
        <Menu.Item key="jobPostingType" icon={<GroupOutlined />}>
          <Link to="/master/job-posting-type">Job Posting Type</Link>
        </Menu.Item>
        <Menu.Item key="bank" icon={<BankOutlined />}>
          <Link to="/master/bank">Bank</Link>
        </Menu.Item>
      </Menu>
    </Card>
  )
}

