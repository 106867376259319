import React from 'react';
import moment from 'moment';
import { DeleteOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Row, Col, Input, DatePicker, Checkbox, Radio, InputNumber, Select, Button, Typography, message, Divider, Space } from 'antd';

const { Text } = Typography

const FormTicket = ({ onSubmit, formRef, onChangeDate, handleLimit, handleChecked, isDate, isFree, data, isSameDate, isSameTime, isLimit, disabledDate, handleCheckedDiscount, isDiscount, 
  listBankAccount, tempListBankAccount, colIndexBankAccount, onChangeBankAccount, addBankAccount, removeBankAccount,
  optionPaymentScheme, onChangePaymentScheme, colIndexPaymentScheme, addPaymentScheme, removePaymentScheme, visibleEdit }) => {
  
  if(visibleEdit){
    data.listScheme.map(item => 
      item.listSchemeDetail.map(res => 
        res.dueDate = moment(res.dueDate, "DD/MM/YYYY")
      )
    )
  }

  return(
    <React.Fragment>
      <Form ref={formRef} onFinish={onSubmit} layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item 
              label="Ticket Name"
              name="name"
              validateFirst
              initialValue={data.name ? data.name : null}
              rules={[
                { required: true, message: 'This is a required field' },
                // { max: 50, message: '50 characters only' },
                { min: 5, message: 'Name must be at least 5 characters' },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              label="Type of limitation" 
              name="isLimit" 
              initialValue={data.isLimit === true || data.isLimit === false ? data.isLimit : false}
              rules={[
                { required: true, message: 'This is a required field' },
              ]}
            >
              <Radio.Group onChange={(value) => handleLimit(value)}>
                <Radio value={false}>Date Based</Radio>
                <Radio value={true}>Date and Quota</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              label="Price (IDR)" 
              name="price" 
              validateFirst
              initialValue={data.price ? data.price : ''}
              rules={[
                { required: !isFree, message: 'This is a required field' },
              ]}
            >
              <InputNumber
                disabled={isFree}
                maxLength={10}
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item 
              label="Due Date" 
              name="date" 
              initialValue={data.date ? moment(data.date, 'DD/MM/YYYY') : null}
              rules={[
                { required: true, message: 'This is a required field' },
              ]}
            >
              <DatePicker 
                placeholder="DD/MM/YYYY" 
                format={'DD/MM/YYYY'}
                style={{ width: '100%' }}
                disabledDate={disabledDate} 
                onChange={onChangeDate} 
                showToday={false}
              />
            </Form.Item>
          </Col>
          <Col span={6} style={{ marginTop: 30 }}>
            <Form.Item>
              <Checkbox onChange={(value) => handleChecked(value, 'isSameDate')} checked={isSameDate}>Same as start date</Checkbox>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              label="Discount Price" 
              name="discountedPrice" 
              validateFirst
              initialValue={data.discountedPrice ? data.discountedPrice : ''}
              rules={[
                { required: isDiscount, message: 'This is a required field' },
              ]}
            >
              <InputNumber
                disabled={isFree || !isDiscount}
                maxLength={10}
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
          </Col>
          <Col span={6} style={{ marginTop: 30 }}>
            <Form.Item>
              <Checkbox checked={isDiscount} onChange={handleCheckedDiscount} disabled={isFree}>This ticket has discount</Checkbox>
            </Form.Item>
          </Col>
          <Col span={6} style={{ marginTop: 30 }}>
            <Form.Item>
              <Checkbox onChange={(value) => handleChecked(value, 'isFree')} checked={isFree} disabled={isDiscount}>This is a free event</Checkbox>
            </Form.Item>
          </Col>
          
          {
            !isFree ?
              <>
                <Col span={24}>
                  <Form.List
                    name="listBankAccount"
                    style={{ width: '100%' }}
                    initialValue={data.listBankAccount ? data.listBankAccount : [""]}
                  >
                    {(fields, { add, remove }, { errors }) => (
                      <>
                        {fields.map((field, index) => (
                          <Form.Item
                            label={index === 0 ? 'Bank Account' : ''}
                            required={true}
                            key={field.key}
                            style={{ marginBottom: 0 }}
                          >
                            <Form.Item
                              {...field}
                              name={[field.name, 'bankAccountId']}
                              // validateTrigger={['onChange', 'onBlur']}
                              rules={[
                                { required: true, message: "This field is required"}
                              ]}
                            >
                              <Select onChange={(e) => onChangeBankAccount(e, field)} style={{ width: fields.length === 1 ? '100%' : '95%' }}>
                                {
                                  listBankAccount?.map(item => (
                                    <Select.Option key={item.id} value={item.id} disabled={tempListBankAccount.indexOf(item.id) !== -1}>
                                      {item.bankName} - {item.name} - {item.number}
                                    </Select.Option>
                                  ))
                                }
                              </Select>
                            </Form.Item>
                            {fields.length > 1 ? (
                              <MinusCircleOutlined
                                className="ticket-dynamic-delete-button"
                                onClick={() => {remove(field.name); removeBankAccount(field)}}
                              />
                            ) : null}
                          </Form.Item>
                        ))}
                        {
                          colIndexBankAccount !== listBankAccount.length ?
                            <Form.Item>
                              <Button
                                type="dashed"
                                block
                                onClick={() => {
                                  if(colIndexBankAccount === tempListBankAccount.length){
                                    add(); addBankAccount()
                                  }else{
                                    message.error('fill in the blank fields first')
                                  }
                                }}
                                icon={<PlusOutlined />}
                              >
                                Add Bank Account
                              </Button>
                              <Form.ErrorList errors={errors} />
                            </Form.Item>
                          : null
                        }
                      </>
                    )}
                  </Form.List>
                </Col>
                <Col style={{ marginBottom: 8 }} span={24}>
                  <Text>Payment Scheme</Text>
                </Col>
                <Col span={24}>
                  <Form.List
                    name="listScheme"
                    style={{ width: '100%' }}
                    initialValue={data.listScheme ? data.listScheme : []}
                  >
                    {(fields, { add, remove }, { errors }) => (
                      <Row>
                        <Col span={24}>
                          {fields.map((field, index) => (
                            <Form.Item
                              required={true}
                              key={field.key}
                              style={{ marginBottom: 0 }}
                            >
                              <Row gutter={8}>
                                <Col span={6}>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, 'paymentOption']}
                                    // validateTrigger={['onChange', 'onBlur']}
                                    rules={[
                                      { required: true, message: "This field is required"}
                                    ]}
                                  >
                                    <Select placeholder="Select a Scheme" onChange={(_, val) =>onChangePaymentScheme(_, val, field.key)}>
                                      {
                                        optionPaymentScheme.map(item => 
                                          <Select.Option key={item.key} value={item.value} disabled={item.disabled}>{item.name}</Select.Option>
                                        )
                                      }
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={14}>
                                  <Divider style={{ marginTop: 16 }} />
                                </Col>
                                <Col span={4}>
                                  <Space onClick={() => {remove(field.name); removePaymentScheme(field)}} style={{ cursor: 'pointer', marginTop: 4 }}>
                                    <Text type='secondary'>Delete Scheme</Text>
                                    <DeleteOutlined style={{ color: '#f25a5a', fontSize: 18 }} />
                                  </Space>
                                </Col>
                                <Col span={1}></Col>
                                <Col span={22}>
                                  <Form.List
                                    name={[field.name, 'listSchemeDetail']}
                                    style={{ width: '100%' }}
                                    initialValue={['']}
                                  >
                                    {(fields, { add, remove }, { errors }) => (
                                      <>
                                        {fields.map((field, index) => (
                                          <Form.Item
                                            required={true}
                                            key={field.key}
                                            style={{ marginBottom: 0 }}
                                          >
                                            <Row gutter={16}>
                                              <Col span={23}>
                                                <Row gutter={16}>
                                                  <Col span={8}>
                                                    <Form.Item 
                                                      {...field}
                                                      label="Input Subject"
                                                      name={[field.name, 'subject']}
                                                      rules={[
                                                        { required: true, message: "This field is required" }
                                                      ]}
                                                    >
                                                      <Input />
                                                    </Form.Item>
                                                  </Col>
                                                  <Col span={8}>
                                                    <Form.Item 
                                                      {...field}
                                                      label="Billing amount"
                                                      name={[field.name, 'amount']}
                                                      rules={[
                                                        { required: true, message: "This field is required" },
                                                        { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers' }
                                                      ]}
                                                    >
                                                      <InputNumber
                                                        maxLength={10}
                                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                      />
                                                    </Form.Item>
                                                  </Col>
                                                  <Col span={8}>
                                                    <Form.Item 
                                                      {...field}
                                                      label="Due Date"
                                                      name={[field.name, 'dueDate']}
                                                      rules={[
                                                        { 
                                                          required: true, message: "This field is required" }
                                                      ]}
                                                    >
                                                      <DatePicker 
                                                        format={'DD/MM/YYYY'}
                                                        style={{ width: '100%' }} 
                                                        // onChange={this.onChangeStartDate}
                                                        allowClear={false}
                                                      />
                                                    </Form.Item>
                                                  </Col>
                                                </Row>
                                              </Col>
                                              <Col span={1} style={{ marginTop: 33, fontSize: 18 }}>
                                                {fields.length > 1 ? (
                                                  <MinusCircleOutlined
                                                    // className="ticket-dynamic-delete-button"
                                                    onClick={() => {remove(field.name); removeBankAccount(field)}}
                                                    style={{ color: '#f25a5a' }}
                                                  />
                                                ) : null}
                                              </Col>
                                            </Row>
                                          </Form.Item>
                                        ))}
                                        <Form.Item>
                                          <Button
                                            type="link"
                                            onClick={() => add()}
                                            icon={<PlusOutlined />}
                                          >
                                            Add Detail Scheme
                                          </Button>
                                          <Form.ErrorList errors={errors} />
                                        </Form.Item>
                                      </>
                                    )}
                                  </Form.List>
                                </Col>
                                <Col span={1}></Col>
                              </Row>
                            </Form.Item>
                          ))}
                        </Col>
                        <Col span={24}>
                          {
                            colIndexPaymentScheme > 0 ?
                              <Divider style={{ marginTop: 0 }} />
                            : null
                          }
                          {
                            colIndexPaymentScheme < 3 ?
                              <Form.Item>
                                <Button
                                  type="dashed"
                                  block
                                  onClick={() => {
                                    // if(colIndexBankAccount === tempListBankAccount.length){
                                      add(); addPaymentScheme()
                                    // }else{
                                    //   message.error('fill in the blank fields first')
                                    // }
                                  }}
                                  icon={<PlusOutlined />}
                                >
                                  Add Payment Scheme
                                </Button>
                                <Form.ErrorList errors={errors} />
                              </Form.Item>
                            : null
                          }
                        </Col>
                      </Row>
                    )}
                  </Form.List>
                </Col>
              </>
            : null
          }

          {
            isLimit ?
              <React.Fragment>
                <Col span={12}>
                  <Form.Item 
                    label="Notes for customers"
                    name="note"
                    initialValue={data.note ? data.note : null }
                    rules={[
                      { max: 50, message: '50 characters only' }
                    ]}
                  >
                    <Input placeholder="e.g.: Include special merchandise" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item 
                    label="Maximum Quota" 
                    name="quota" 
                    validateFirst
                    initialValue={data.totalQuota ? data.totalQuota : ''}
                    rules={[
                      { required: true, message: 'This is a required field' },
                    ]}
                  >
                    <InputNumber
                      disabled={false}
                      maxLength={5}
                      min={data.ticketSold}
                    />
                  </Form.Item>
                </Col>
              </React.Fragment>
            :
              <Col span={24}>
                <Form.Item 
                  label="Notes for customers"
                  name="note"
                  initialValue={data.note ? data.note : null }
                  rules={[
                    { max: 50, message: '50 characters only' }
                  ]}
                >
                  <Input placeholder="e.g.: Include special merchandise" />
                </Form.Item>
              </Col>
          }
        </Row>
      </Form>
    </React.Fragment>
  )
}

export default FormTicket;