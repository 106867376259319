import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import { Row, Col, Modal, message, Card, Table, Pagination, Button } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { detailCandidate, unmountDetailCandidate } from '../../../../../../redux/actions/candidate/candidateAction';
import { activeApplicant, detailJob, inactiveJob, listRejected, unmountDetailJobs, unmountListRejected } from '../../../../../../redux/actions/jobs/jobsAction';
import { columns } from './columns';
import { FilterDetailHired } from '../../../../../../components';
import ViewProfile from '../../../../../../components/Modal/ApplicantProfile';

const { confirm } = Modal;

class DetailRejectedEmployerJobs extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      url: 'job_employers',
      checked: true,
      visible: false,
      meta: {
        page: 1,
        perpage: 10,
        id: '',
        sort: '',
        field: '',
        search: '',
        startDate: '',
        endDate: '',
        phaseId: -1
      }
    }
    this.onSearch = debounce(this.onSearch.bind(this), 900)
  }

  componentDidMount() {
    const { meta } = this.state; 
    const { match: { params } } = this.props;
    meta.id = params.id
  }

  onRangePicker = value => {
    const { meta, url } = this.state;
    const { actionGetRejected } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.startDate = value.startDate
    meta.endDate = value.endDate
    return actionGetRejected(meta, url)
  }

  onStatus = value => {
    const { meta, url } = this.state;
    const { actionGetRejected } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.phaseId = value
    return actionGetRejected(meta, url)
  }

  onSearch = value => {
    const { meta, url } = this.state;
    const { actionGetRejected } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.search = value
    return actionGetRejected(meta, url)
  }

  pagination = (page, perpage) => {
    const { actionGetRejected } = this.props;
    const { meta, url } = this.state;
    meta.page = page
    meta.perpage = perpage
    return actionGetRejected(meta, url)
  }

  handleActiveApplicant = (id) => {
    const { meta, url } = this.state;
    const { actionActiveApplicant, actionGetRejected } = this.props;
    confirm({
      title: 'This application will be back processed to the last stage.',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure?',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        return actionActiveApplicant(id, url, () => {
          message.success('Successfully moved')
          return actionGetRejected(meta, url)
        })
      },
    });
  }

  onPriviewProfile = (id) => {
    const { actionGetProfile } = this.props;
    return actionGetProfile(id, () => {
      this.setState({ visible: true })
    })
  }

  onCancel = () => {
    this.setState({ visible: false })
  }

  showRejectReason = (val) => {
    Modal.info({
      title: 'Rejection reason:',
      content: (
        <div dangerouslySetInnerHTML={{ __html: val }}></div>
      ),
      onOk() {},
    });
  }

  render() {
    const { visible } = this.state;
    const { getRejected } = this.props;

    const defaultStatusFilter = [
      { value: -1, name: 'All' },
      { value: '', name: 'Applied' },
      { value: 5, name: 'Shortlisted' },
      { value: 6, name: 'Assessment' },
      { value: 7, name: 'Qualified' },
      { value: 8, name: 'Interview' },
      { value: 9, name: 'Offered' },
      // { value: 16, name: 'Departure'},
      { value: 10, name: 'Hired' },
    ]

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Content */}
          <Col span={24}>
            <Card title="Rejected Applicants">
              <Row gutter={[16, 24]}>
                {/* Filter */}
                <Col span={24}>
                  <FilterDetailHired 
                    onSearch={this.onSearch} 
                    onStatus={this.onStatus} 
                    onRangePicker={this.onRangePicker}
                    fase1={{ key: 'type', value: defaultStatusFilter}}
                    tooltopRangePicker='Filter by Rejected Date'
                  />
                  </Col>
                {/* Table */}
                <Col span={24}>
                  <Table 
                    style={{ marginBottom: 20 }}
                    size="small"
                    rowKey={(i) => i.id} 
                    dataSource={getRejected.data} 
                    loading={getRejected.loading} 
                    columns={columns(this.onPriviewProfile, this.handleActiveApplicant, this.showRejectReason)} 
                    pagination={false}
                    scroll={{ x: 900 }}
                  />
                  {/* Pagination */}
                  <Pagination
                    total={getRejected.pagination.total}
                    onChange={this.pagination}
                    current={getRejected.pagination.page}
                    pageSize={getRejected.pagination.perpage}
                    showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total} Data`}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal 
          centered
          width={1200}
          visible={visible}
          title="Applicant Profile"
          onCancel={this.onCancel}
          footer={
            <Button key="back" type="primary" onClick={this.onCancel}>Okay</Button>
          }
        >
          <div style={{ height: 600, overflowY: 'auto', overflowX: 'hidden' }}>
            <ViewProfile {...this.props} />
          </div>
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail, unmountGetRejected, unmountGetProfile } = this.props;
    unmountGetDetail()
    unmountGetRejected()
    unmountGetProfile()
  }
}

const mapStateToProps = (state) => ({
  getData       : state.jobs.detail,
  getRejected   : state.jobs.listRejected,
  getProfile    : state.candidate.detail

})

const mapDispatchToProps = {
  actionInactive        : inactiveJob,
  actionActiveApplicant : activeApplicant,
  actionGetDetail       : detailJob,
  actionGetRejected     : listRejected,
  actionGetProfile      : detailCandidate,
  unmountGetDetail      : unmountDetailJobs,
  unmountGetRejected    : unmountListRejected,
  unmountGetProfile     : unmountDetailCandidate,

}

export default connect(mapStateToProps, mapDispatchToProps)(DetailRejectedEmployerJobs)
