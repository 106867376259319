import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BankOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Breadcrumb, Col, Modal, PageHeader, Pagination, Row, Table, Typography, message } from 'antd'
import debounce from 'lodash/debounce'
import { HeaderTable } from '../../../../components'
import { listMiniEnglish , exportMiniEnglish, restartMiniEnglish} from '../../../../redux/actions/candidatePathway/candidatePathwayAction'
import { columns } from './columns';
import Menu from '../Menu';
import { download } from '../../../../utils/document'
const { confirm } = Modal;
const { Text } = Typography

export class EnglishAssessment extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      selectedRowKeys: [],
      sortedInfo: null,
      meta: {
        page: 1,
        perpage: 10,
        field: null,
        sort: null,
        search: null,
        flagStatus: null,
        startDate: null,
        endDate: null
      }
    }
    this.onSearch = debounce(this.onSearch.bind(this), 900)
  }

  componentDidMount() {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    return actionGetData(meta)
  }

  onClickRow = (record) => {
    this.props.history.push(`/candidate-pathway/mini-assessment/detail/${record.id}`)
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  pagination = (page, perpage) => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = page
    meta.perpage = perpage
    return actionGetData(meta)
  }

  onRegistered = value => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.startDate = value.startDate
    meta.endDate = value.endDate
    return actionGetData(meta)
  }

  onChangeLevel = value => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.level = value
    return actionGetData(meta)
  }

  onSearch = value => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.search = value === '' ? null : value
    return actionGetData(meta)
  }

  onClickFeedback = (record) => {
    Modal.info({
      title: 'Candidate Feedback:',
      content: (
        <p>{record}</p>
      ),
      onOk() {},
    }
    );
  }

  showConfirm = (id) => {
    const { actionRestart, actionGetData } = this.props;
    const { meta } = this.state
    const params = {
      id: id
    }
    confirm({
      // title: 'Do you Want to delete these items?',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to restart the test for this candidate?',
      onOk() {
        return actionRestart(params, () => {
          message.success('Successfully restart')
          return actionGetData(meta)
        }, (err) => message.error(err))
      },
      onCancel() {},
    });
  };

  handleDisable = (value) => {
    const { meta } = this.state;
    const { actionDisable, actionGetData } = this.props;
    return actionDisable(value, () => {
      message.success('Successfully changed to inactive')
      return actionGetData(meta)
    }, (err) => message.error(err))
  }

  modalExportData = (id) => {
    const self = this;
    const { selectedRowKeys, meta } = this.state;
    const { actionGetExport } = this.props;
    confirm({
      title: <Text>Candidates data will be exported to excel</Text>,
      content: 'Are you sure?',
      width: 480,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        if(selectedRowKeys.length > 0){
          const value = {
            candidateId: selectedRowKeys.length > 0 ? selectedRowKeys.toString() : null
          }
          actionGetExport(value, (response) => {
            return download(response, 'Mini English Score Data.xls')
          })
        }else{
          actionGetExport(meta, (response) => {
            return download(response, 'Mini English Score Data.xls')
          })
        }
        self.setState({ selectedRowKeys: [], selectedRows: [] })
      }
    });
  }

  // warningNoSelected = () => {
  //   warning({
  //     title: 'Please select a record',
  //   });
  // };

  
  render() {
    const { selectedRowKeys, sortedInfo } = this.state;
    const { getData: { data, loading, pagination } } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const defaultLevelFilter = [
      { value: null, name: 'All Levels' },
      { value: 'A1', name: 'A1' },
      { value: 'A2', name: 'A2' },
      { value: 'B1', name: 'B1' },
      { value: 'B1+', name: 'B1+' },
    ]

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><BankOutlined /> Pathway</Breadcrumb.Item>
              <Breadcrumb.Item>Mini English Assessment</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          { /* Page Header */}
          <Col span={24}>
            <PageHeader 
              className="site-page-header" 
              title='Candidates Pathway list' 
            />
          </Col>
          {/* Filter */}
          <Col span={6}></Col>
          <Col span={18}>
            <HeaderTable
              fase1={{ key: 'type', value: defaultLevelFilter}}
              onSearch={this.onSearch} 
              onChangeLevel={this.onChangeLevel} 
              placeholder="Search by Name"
              candidatePathway={true}
              selectedRowKeys={selectedRowKeys}
              modalExportData={this.modalExportData}
              // warning={this.warningNoSelected}
              onRegistered={this.onRegistered}
            />
          </Col>
          { /* Menu Side Bar */}
          <Col span={6} style={{ marginBottom: 20 }}>
            <Menu rowKey="miniEnglish" />
          </Col>
          {/* Table */}
          <Col span={18}>
            <Table 
              size="small" 
              rowKey={(i) => i.candidateId} 
              pagination={false}
              onChange={this.sorter}
              dataSource={data} 
              loading={loading} 
              // scroll={{ x: 1500 }}
              rowSelection={rowSelection}
              rowClassName={(record) => record.id ? 'row-pointer' : null}
              // columns={columns(sortedInfo, this.onClickRow, this.onClickFeedback, this.showConfirm)}  
              columns={columns(sortedInfo, this.showConfirm)}  
            />
          </Col>
          {/* Pagination */}
          <Col span={6}></Col>
          <Col span={18}>
            <Pagination
              total={pagination.total}
              onChange={this.pagination}
              current={pagination.page}
              pageSize={pagination.perpage}
              showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total} Data`}
            />
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  getData: state.miniEnglish.list
})

const mapDispatchToProps = {
  actionGetData     : listMiniEnglish,
  actionRestart     : restartMiniEnglish,
  actionGetExport   : exportMiniEnglish
}

export default connect(mapStateToProps, mapDispatchToProps)(EnglishAssessment)
