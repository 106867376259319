import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading } from '../../../../../components';
import { InfoCircleOutlined, DatabaseOutlined } from '@ant-design/icons';
import { Col, Form, Row, Input, Button, Select, PageHeader, message, Card, Breadcrumb } from 'antd';
import { masterJobIndustries, unmountMasterJobIndustries } from '../../../../../redux/actions/master/jobIndustries/jobIndustriesAction';
import { detailJobPosition, editJobPosition, unmountDetailJobPositions } from '../../../../../redux/actions/master/jobPositions/jobPositionsAction';

class EditMasterJobPositions extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      isDisplay: false
    }
  }

  componentDidMount() {
    const { actionGetJobIndustries, actionGetDetail, match: { params } } = this.props;
    actionGetDetail(params.id, (response) => {
      this.setState({ isDisplay: response.data.isDisplay })
    })
    actionGetJobIndustries()
  }

  handleIsDisplay = value => {
    this.setState({ isDisplay: value })
  }

  onFinish = (values) => {
    const { actionUpdate, history, getData: { data } } = this.props;
    values.id = data.id
    values.sort = Number(values.sort)
    this.setState({ submitLoading: true })
    return actionUpdate(values, () => {
      this.setState({ submitLoading: false }, () => {
        message.success('Data updated successfully')
        history.push('/master/job-positions')
      })
    }, (err) => {
      this.setState({ submitLoading: false }, () => message.error(err))
    })
  }

  render() {
    const { submitLoading, isDisplay } = this.state;
    const { getJobIndustries, getData: { data, loading } } = this.props;

    if(getJobIndustries.loading || loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Form onFinish={this.onFinish} layout="vertical">
          <Row style={{ position: 'sticky', zIndex: 1, width: '100%', top: 62, right: 0, marginTop: -20, marginBottom: 8, paddingBottom: 12, paddingTop: 16, backgroundColor: '#f2f2f2' }}>
            {/* Breadcrumb */}
            <Col span={24}>
              <Breadcrumb>
                <Breadcrumb.Item><DatabaseOutlined /> Master Data</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link className="link" to="/master/job-positions">
                    Master Data List Job Positions
                  </Link> 
                </Breadcrumb.Item>
                <Breadcrumb.Item>Job Positions</Breadcrumb.Item>
                <Breadcrumb.Item>{data.title}</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            { /* Page Header */}
            <Col span={24}>
              <PageHeader 
                className="site-page-header" 
                title="Update Job Position Data"
                extra={[
                  <Button key="2" className="btn-save-primary" type="default" onClick={() => window.history.back()}>Cancel</Button>,
                  <Button key="1" className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading}>Update</Button>
                ]}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            { /* Form */}
            <Col span={24}>
              <Card>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item 
                      label="Job Industry" 
                      name="jobIndustryId"
                      initialValue={data.jobIndustryId ? data.jobIndustryId : ''}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select an Job Industry"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          getJobIndustries?.data?.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.title}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Job Position Name" 
                      name="title" 
                      validateFirst
                      initialValue={data.title ? data.title : ''}
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 3, message: 'Name must be at least 3 characters long' },
                        { max: 50, message: '50 characters only' },
                        { pattern: new RegExp("^[a-zA-Z ]+$"), message: 'Number and special character are not allowed' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Will it be displayed in the candidate's side?" 
                      name="isDisplay" 
                      initialValue={data.isDisplay}
                      tooltip={{ 
                        title: 'If you choose yes, this job position will be displayed in the Job Preferences option', 
                        icon: <InfoCircleOutlined style={{ color: '#2586eb' }} />
                      }}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select onChange={this.handleIsDisplay}>
                        <Select.Option value={true}>Yes</Select.Option>
                        <Select.Option value={false}>No</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Status" 
                      name="enabled" 
                      initialValue={data.enabled}
                      tooltip={{ 
                        title: 'Inactive means this job position isn’t used in master data', 
                        icon: <InfoCircleOutlined style={{ color: '#2586eb' }} />
                      }}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select>
                        <Select.Option key="1" value={true}>Active</Select.Option>
                        <Select.Option key="2" value={false}>Inactive</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  {
                    isDisplay ?
                    <Col span={12}>
                      <Form.Item 
                        label="Sort" 
                        name="sort"
                        tooltip={{ 
                          title: 'the sort number will match the order in which it is displayed', 
                          icon: <InfoCircleOutlined style={{ color: '#2586eb' }} />
                        }}
                        initialValue={data.sort ? data.sort : ''}
                        rules={[
                          { pattern: new RegExp("^[0-9]+$"), message: 'Please enter on numerics only' },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    :
                    null
                  }

                  <Col span={12}>
                    <Form.Item 
                      label="Description" 
                      name="description"
                      initialValue={data.description ? data.description : ''}
                      rules={[
                        { max: 50, message: '50 characters only' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetJobIndustries, unmountGetDetail } = this.props;
    unmountGetDetail()
    unmountGetJobIndustries()
  }
}

const mapStateToProps = (state) => ({
  getData           : state.jobPositions.detail,
  getJobIndustries  : state.jobIndustries.master
})  

const mapDispatchToProps = {
  actionUpdate            : editJobPosition,
  actionGetDetail         : detailJobPosition,
  actionGetJobIndustries  : masterJobIndustries,
  unmountGetDetail        : unmountDetailJobPositions,
  unmountGetJobIndustries : unmountMasterJobIndustries
}

export default connect(mapStateToProps, mapDispatchToProps)(EditMasterJobPositions)
