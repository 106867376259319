const LOAD_DETAIL_SKILL           = 'LOAD_DETAIL_SKILL'
const LOAD_DETAIL_SKILL_SUCCESS   = 'LOAD_DETAIL_SKILL_SUCCESS'
const LOAD_DETAIL_SKILL_FAILED    = 'LOAD_DETAIL_SKILL_FAILED'
const UNMOUNT_DETAIL_SKILL        = 'UNMOUNT_DETAIL_SKILL'

const initialState = {
  loading: true,
  data: null,
  message: null,
}

const skillDetailReducer = (state = initialState, action) => {
  switch(action.type){
    case LOAD_DETAIL_SKILL:
      return{
        ...state,
        data: null,
        loading: true,
      }
    case LOAD_DETAIL_SKILL_SUCCESS:
      return{ 
        ...state, 
        data: action.payload.data,
        loading: false,
        message: 'SUCCESS'
      }
    case LOAD_DETAIL_SKILL_FAILED:
      return{ 
        ...state, 
        data: null,
        loading: true,
        message: 'FAILED'
      }
      case UNMOUNT_DETAIL_SKILL:
        return{ 
          ...state, 
          data: null,
          loading: true,
        }
    default:
      return state
  }


}

export default skillDetailReducer;