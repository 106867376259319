import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { Row, Col, Typography, Space, Form, Input, DatePicker, Select, AutoComplete, Spin, Skeleton, List } from 'antd';
import { listInstitutions, unmountListInstitutions } from '../../../../../../../../redux/actions/master/institutions/institutionsAction';
import { masterEducationLevel, unmountMasterEducationLevel } from '../../../../../../../../redux/actions/master/educationLevel/educationLevelAction';
import { listFieldOfStudy, unmountMasterFieldOfStudy } from '../../../../../../../../redux/actions/master/fieldOfStudy/fieldOfStudyAction';
const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

export class index extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      metaInstitution: {
        page: 1,
        perpage: 10,
        search: ''
      },
      metaFieldOfStudy: {
        page: 1,
        perpage: 10,
        search: ''
      },
    }
    this.onSearchInstitution = debounce(this.onSearchInstitution.bind(this), 800)
    this.onSearchFieldOfStudy = debounce(this.onSearchFieldOfStudy.bind(this), 800)
  }
  
  componentDidMount() {
    const { actionGetEducationLevel } = this.props

    return actionGetEducationLevel()
  }

  onSearchInstitution = value => {
    const { metaInstitution } = this.state;
    const { actionListInstitution } = this.props;

    metaInstitution.page = 1
    metaInstitution.perpage = 10
    metaInstitution.search = value

    return actionListInstitution(metaInstitution)
  }

  onFocusInstitution = () => {
    const { metaInstitution } = this.state;
    const { actionListInstitution } = this.props;
    return actionListInstitution(metaInstitution)
  }

  onSearchFieldOfStudy = value => {
    const { metaFieldOfStudy } = this.state;
    const { actionListFieldOfStudy } = this.props;

    metaFieldOfStudy.page = 1
    metaFieldOfStudy.perpage = 10
    metaFieldOfStudy.search = value

    return actionListFieldOfStudy(metaFieldOfStudy)
  }

  onFocusFieldOfStudy = () => {
    const { metaFieldOfStudy } = this.state;
    const { actionListFieldOfStudy } = this.props;
    return actionListFieldOfStudy(metaFieldOfStudy)
  }


  render() {
    const { 
      getInstitution, 
      getEducationLevel, 
      getFieldOfStudy, 
      isYear, 
      onChangeEducationLevel,
      onChangeStartYear,
      onChangeEndYear,
      dataCandidate
    } = this.props
    
    if(getEducationLevel.loading){
      return <Skeleton active />
    }

    return (
      <React.Fragment>
        {
          dataCandidate?.listEducation?.length > 0 ?
            <List
              dataSource={dataCandidate?.listEducation}
              renderItem={(item, i) => (
                <List.Item key={i}>
                  <Row className="width-100">
                    <Col span={24}>
                      <Space direction="vertical" size={0}>
                        <Text className="primary-color">{item.name}</Text>
                        {
                          item.educationLevelName && item.fieldOfStudy ?
                            <Text className="light-color">{`${item.educationLevelName}, ${item.fieldOfStudy}`}</Text>
                          : item.educationLevelName ?
                            <Text className="light-color">{item.educationLevelName}</Text>
                          : item.fieldOfStudy ?
                            <Text className="light-color">{item.fieldOfStudy}</Text>
                          :
                            null
                        }
                        {
                          item.gpa ?
                            <Text className="light-color">{`GPA ${item.gpa}`}</Text>
                          :
                            null
                        }
                        {
                          item.startYear || item.endYear ?
                            <Text className="light-color">{`${item.startYear ? item.startYear : 'N/A'} - ${item.endYear ? item.endYear : 'N/A'}`}</Text>
                          :
                            null
                        }
                        {
                          item.experience ?
                            <Text className="light-color">{item.experience}</Text>
                          :
                            null
                        }
                      </Space>
                    </Col>
                  </Row>
                </List.Item>
              )}
            />
          :
            <>
              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item 
                    name="educationName"
                    label="School/University"
                    validateFirst
                    rules={[
                      { required: true, message: 'School/University is required!' },
                      { max: 100, message: '100 characters only' },
                    ]}
                  >
                    <AutoComplete
                      onFocus={this.onFocusInstitution}
                      onSearch={this.onSearchInstitution}
                      notFoundContent={getInstitution.loading ? <Spin size="small" /> : null}
                      filterOption={false}
                      placeholder="e.g.: Binawan University"
                    >
                      {
                        getInstitution?.data?.map((res) => (
                          <Option key={res.id} value={res.name}>
                            {res.name}
                          </Option>
                        ))
                      }
                    </AutoComplete>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item 
                    name="educationLevelId"
                    label="Education Level"
                    rules={[
                      { required: true, message: 'Education Level is required!' }
                    ]}
                  >
                    <Select onChange={onChangeEducationLevel}>
                      {
                        getEducationLevel?.data?.map(item => 
                          <Option key={item.id} value={item.name}>{item.name}</Option>
                          )
                      }
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item 
                    name="educationFieldOfStudy"
                    label="Field of Study"
                    validateFirst
                    rules={[
                      { max: 100, message: '100 characters only' },
                    ]}
                  >
                    <AutoComplete
                      onFocus={this.onFocusFieldOfStudy}
                      onSearch={this.onSearchFieldOfStudy}
                      notFoundContent={getFieldOfStudy.loading ? <Spin size="small" /> : null}
                      filterOption={false}
                      placeholder="e.g.: Nursing"
                    >
                      {
                        getFieldOfStudy?.data?.map((res) => (
                          <Option key={res.id} value={res.name}>
                            {res.name}
                          </Option>
                        ))
                      }
                    </AutoComplete>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item 
                    name="educationGpa"
                    label="Score/Grade/GPA"
                    rules={[
                      { pattern: /^[0-9a-zA-Z.]*$/, message: 'Only dot (.) is allowed, No spaces allowed' },
                      { max: 4, message: '4 characters only' },
                    ]}
                  >
                    <Input placeholder="e.g.: 3.50" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item 
                    name="educationStartYear"
                    label="Start Year"
                  >
                    <DatePicker inputReadOnly picker="year" onChange={onChangeStartYear} disabledDate={ d => !d || d.isBefore(moment().subtract(30, 'years').format('YYYY').toString()) || d.isAfter(moment().add(1, 'years').format('YYYY').toString()) } style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item 
                    name="educationEndYear"
                    label="End Year (or expected)"
                    validateStatus={isYear ? "error" : null}
                    help={isYear ? "Your end year can’t be earlier than your start year" : null}
                  >
                    <DatePicker 
                      picker="year" 
                      placeholder="Select year" 
                      style={{ width: '100%' }}
                      onChange={onChangeEndYear} 
                    />
                    {/* <DatePicker inputReadOnly picker="year" onChange={onChangeEndYear} disabledDate={ d => !d || d.isBefore(moment().subtract(30, 'years').format('YYYY').toString()) || d.isAfter(moment().add(1, 'years').format('YYYY').toString()) } style={{ width: '100%' }} /> */}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item 
                    name="educationExperience"
                    label="Activities and Organization Experiences"
                    rules={[
                      { max: 200, message: '200 characters only' }
                    ]}
                  >
                    <TextArea rows={3} />
                  </Form.Item>
                </Col>
              </Row>
            </>
        }
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountListInstitutions, unmountMasterEducationLevel, unmountMasterFieldOfStudy } = this.props
    return (unmountListInstitutions(), unmountMasterEducationLevel(), unmountMasterFieldOfStudy())
  }
}

const mapStateToProps = (state) => ({
  getInstitution    : state.institutions.list,
  getEducationLevel : state.educationLevel.master,
  getFieldOfStudy   : state.fieldOfStudy.list
})

const mapDispatchToProps = {
  actionListInstitution       : listInstitutions,
  actionGetEducationLevel     : masterEducationLevel,
  actionListFieldOfStudy      : listFieldOfStudy,
  unmountListInstitutions     : unmountListInstitutions,
  unmountMasterEducationLevel : unmountMasterEducationLevel,
  unmountMasterFieldOfStudy   : unmountMasterFieldOfStudy
}

export default connect(mapStateToProps, mapDispatchToProps)(index)