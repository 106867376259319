import { API } from '../../../config';
import { errorHandler } from '../auth/errorAction';

export const unmountDataEmail = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_EMAIL'})
}

export const unmountDataContact = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_CONTACT'})
}

export const getEmail = (url, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DATA_EMAIL' })
  API.GET(`/${url}/getEmail`).then((response) => {
    dispatch({ 
      type: 'LOAD_DATA_EMAIL_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DATA_EMAIL_FAILED' }), 
      ))
    )
  })
}

export const getContact = (url, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DATA_CONTACT' })
  API.GET(`/${url}/getContact`).then((response) => {
    dispatch({ 
      type: 'LOAD_DATA_CONTACT_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DATA_CONTACT_FAILED' }), 
      ))
    )
  })
}

export const updateEmail = (url, value, successCB, failedCB) => () => {
  const params = {
    'newEmail': value
  }
  API.POST_WITH_PARAMS(`/${url}/email`, params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const activationEmail = (url, value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS(`/${url}/email/activation`, value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const resendEmail = (url, value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS(`/${url}/email/resend`, value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updateContact = (url, value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS(`/${url}/contact`, value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updatePassword = (url, value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS(`/${url}/chpass`, value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}