import React, { Component } from 'react';
import { Form, Row, Col, Input, DatePicker  } from 'antd';

export default class AddResultTest extends Component {
  render() {
    const { 
      formRef, 
      
      onSubmitAdd, 
    } = this.props;

    return (
      <React.Fragment>
        <Form ref={formRef} onFinish={onSubmitAdd} layout="vertical">
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item 
                label="Test Category Name"
                name="name"
                validateFirst
                rules={[
                  { required: true, message: 'This is a required field' },
                  { max: 200, message: '200 characters only' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item 
                label="Score"
                name="score"
                validateFirst
                rules={[
                  { required: true, message: 'This is a required field' },
                  { max: 200, message: '200 characters only' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Date" name="testDate" 
               rules={[
                { required: true, message: 'This is a required field' },
              ]}
              >
                <DatePicker 
                  format="DD/MM/YYYY" 
                  placeholder="DD/MM/YYYY" 
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}
