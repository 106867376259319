import React from 'react';
import { Link } from 'react-router-dom';

export const columns = () => {
  return [
    {
      title: 'Posting Title',
      key: 'title',
      render: (record) => (
        <Link className="link-dashboard" to={`/employer/jobs/detail/${record.id}/information/0`}>
          {record.title}
        </Link>
      )
    },
    {
      title: 'Applied',
      key: 'applied',
      align: 'center',
      render: (record) => (
        <Link className="link-dashboard-phase" to={`/employer/jobs/detail/${record.id}/active-applicants/0`} style={{ color: '#663300' }}>
          {record.applied}
        </Link>
      )
    },
    {
      title: 'Shortlisted',
      key: 'shortlisted',
      align: 'center',
      render: (record) => (
        <Link className="link-dashboard-phase" to={`/employer/jobs/detail/${record.id}/active-applicants/5`} style={{ color: '#cc3300' }}>
          {record.shortlisted}
        </Link>
      )
    },
    {
      title: 'Assessment',
      key: 'assessment',
      align: 'center',
      render: (record) => (
        <Link className="link-dashboard-phase" to={`/employer/jobs/detail/${record.id}/active-applicants/6`} style={{ color: '#1274db' }}>
          {record.assessment}
        </Link>
      )
    },
    {
      title: 'Qualified',
      key: 'qualified',
      align: 'center',
      render: (record) => (
        <Link className="link-dashboard-phase" to={`/employer/jobs/detail/${record.id}/active-applicants/7`} style={{ color: '#9d7bd0' }}>
          {record.qualified}
        </Link>
      )
    },
    {
      title: 'Interview',
      key: 'interview',
      align: 'center',
      render: (record) => (
        <Link className="link-dashboard-phase" to={`/employer/jobs/detail/${record.id}/active-applicants/8`} style={{ color: '#d78f13' }}>
          {record.interview}
        </Link>
      )
    },
    {
      title: 'Offered',
      key: 'offered',
      align: 'center',
      render: (record) => (
        <Link className="link-dashboard-phase" to={`/employer/jobs/detail/${record.id}/active-applicants/9`} style={{ color: '#0c999d' }}>
          {record.offered}
        </Link>
      )
    },
    // {
    //   title: 'Departure',
    //   key: 'departure',
    //   align: 'center',
    //   render: (record) => (
    //     <Link className="link-dashboard-phase" to={`/employer/jobs/detail/${record.id}/active-applicants/11`} style={{ color: '#0c999d' }}>
    //       {record.departure}
    //     </Link>
    //   )
    // },
    {
      title: 'Hired',
      key: 'hired',
      align: 'center',
      render: (record) => (
        <Link className="link-dashboard-phase" to={`/employer/jobs/detail/${record.id}/active-applicants/10`} style={{ color: '#53ac2d' }}>
          {record.hired}
        </Link>
      )
    },
  ]
}