import React from 'react';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';
const { Text } = Typography;

export const columns = () => {
  return [
    {
      title: 'Posting Title',
      key: 'title',
      render: (record) => (
        <Link to={`/employer/jobs/detail/${record.id}/information/0`} className="link-dashboard">
          {record.title}
        </Link>
      )
    },
    {
      title: 'Hired/Needs',
      key: 'needs',
      align: 'center',
      render: (record) => (
        <Text>
          {record.hired} / {record.needs}
        </Text>
      )
    },
    {
      title: 'On Progress',
      key: 'onProgress',
      align: 'center',
      render: (record) => (
        <Text>
          {record.onProgress}
        </Text>
      )
    },
    {
      title: 'Posted Date',
      key: 'postedDate',
      align: 'center',
      render: (record) => (
        <Text>
          {record.postedDate}
        </Text>
      )
    },
  ]
}