export const statusTransaction = [
  {
    "value": "WAITING_FOR_PAYMENT",
    "name": "Unpaid",
    "color": "rgba(66, 114, 185, 0.15)",
    "textColor": "#4272b9"
  },
  {
    "value": "WAITING_FOR_CONFIRM",
    "name": "Need to verify",
    "color": "#fdf4ec",
    "textColor": "#ff8b2a"
  },
  {
    "value": "PAYMENT_CONFIRM",
    "name": "Confirmed",
    "color": "#dceee4",
    "textColor": "#32ab6d"
  },
  {
    "value": "CANCELED",
    "name": "Cancelled",
    "color": "#feedee",
    "textColor": "#f10012"
  },
  {
    "value": "REJECTED",
    "name": "Rejected",
    "color": "#ffffff",
    "textColor": "#dd515c"
  },
  {
    "value": "EXPIRED",
    "name": "Expired",
    "color": "#eeeeee",
    "textColor": "#000000"
  }
]