import { API } from '../../../../config';
import { errorHandler } from '../../auth/errorAction';

export const unmountListAboutCountries = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_ABOUT_COUNTRIES'})
}

export const unmountDetailAboutCountries = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_ABOUT_COUNTRIES'})
}

export const unmountMasterAboutCountries = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_ABOUT_COUNTRIES'})
}

export const listAboutCountries = (meta) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_ABOUT_COUNTRIES' })
  return API.GET('/country/list/about', meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_ABOUT_COUNTRIES_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_ABOUT_COUNTRIES_FAILED' }), 
    ))
  })
}

// export const detailAboutCountries = (id, successCB, failedCB) => async dispatch => {
//   await dispatch({ type: 'LOAD_DETAIL_ABOUT_COUNTRIES' })
//   const params = {
//     'id': id
//   }
//   API.GET('/country/getById', params).then((response) => {
//     dispatch({ 
//       type: 'LOAD_DETAIL_COUNTRIES_SUCCESS', 
//       payload : { 
//         data: response.data 
//       } 
//     })
//     return successCB && successCB(response)
//   }).catch((err) => {
//     return (
//       failedCB && failedCB(err),
//       dispatch(errorHandler(
//         err, 
//         dispatch({ type: 'LOAD_DETAIL_COUNTRIES_FAILED' }), 
//       ))
//     )
//   })
// }

export const masterAboutCountries = (successCB, failedCB) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_ABOUT_COUNTRIES' })
  return API.GET('/country/index/about').then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_ABOUT_COUNTRIES_SUCCESS', 
      payload: { data: response.data }
    })
    return successCB && successCB(response.data)
  }).catch((err) => {
    failedCB && failedCB()
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_MASTER_ABOUT_COUNTRIES_FAILED' }), 
    ))
  })
}

export const createAboutCountries = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA('/country/add/about', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}


export const editAboutCountries = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA('/country/update/about', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const enableAboutCountry = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/country/enable/about', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const disableAboutCountry = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/country/disable/about', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}