import React, { Component } from 'react';
import moment from 'moment';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Form, Row, Col, DatePicker, TimePicker, Radio } from 'antd';

export default class EditAssessment extends Component {

  disabledDate = (current) => {
    let yesterday = moment().subtract(1, 'days');
    return !current || current.isBefore(yesterday);
  }

  render() {
    const { isMethod, handleChangeMethod, handleChangeValue, formRef, onSubmit, getAssessment: { loading, data } } = this.props;

    if(loading){
      return <span>loading</span>
    }

    return (
      <React.Fragment>
        <Form 
          onFinish={onSubmit} 
          layout="vertical" 
          ref={formRef}
          onValuesChange={(_, value) => {
            handleChangeValue(value)
          }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item 
                label="Assessment Date"
                name="assessmentDate"
                initialValue={moment(data.assessmentDate, 'DD/MM/YYYY')}
                rules={[
                  { required: true, message: 'This is a required field' },
                ]}
              >
                <DatePicker 
                  format="DD/MM/YYYY"
                  style={{ width: '100%' }}
                  disabledDate={this.disabledDate}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="Assessment Time"
                name="assessmentTime"
                initialValue={moment(data.assessmentTime, 'HH:mm')}
                rules={[
                  { required: true, message: 'This is a required field' },
                ]}
              >
                <TimePicker style={{ width: '100%' }} format="HH:mm" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                name="isMethod" 
                label="Method" 
                initialValue={data.isMethod}
                rules={[
                  { required: true, message: 'This is a required field' },
                ]}
              >
                <Radio.Group onChange={handleChangeMethod}>
                  <Radio value={true}>Direct</Radio>
                  <Radio value={false}>Virtual</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {
              isMethod ?
              <Col span={24} style={{ marginBottom: 15 }}>
                <Form.Item 
                  name="location" 
                  label="Location"
                  initialValue={data.location}
                  rules={[
                    { required: isMethod, message: 'This is a required field' },
                  ]}
                >
                  <ReactQuill />
                </Form.Item>
              </Col>
              :
              <Col span={24} style={{ marginBottom: 15 }}>
                <Form.Item 
                  name="virtualLink" 
                  label="Virtual Link"
                  initialValue={data.virtualLink}
                  rules={[
                    { required: !isMethod, message: 'This is a required field' },
                  ]}
                >
                  <ReactQuill />
                </Form.Item>
              </Col>
            }
            {/* <Col span={24}>
              <div dangerouslySetInnerHTML={{ __html: `Notes: ${data.notes ? data.notes : 'N/A'}` }}></div>
            </Col> */}
            <Col span={24} style={{ marginBottom: 15}}>
              <Form.Item
                name="notes"
                label="Notes"
                initialValue={data.notes}
              >
                <ReactQuill />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}
