import React from 'react';
import { Typography } from 'antd';
const { Text } = Typography;

export const columns = () => {
  return [
    {
      title: 'Posting Title',
      key: 'postingTitle',
      width: 150,
      render: (record) => (
        <Text>
          {record.postingTitle ? record.postingTitle : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Stage Name',
      key: 'stageName',
      width: 150,
      render: (record) => (
        <Text>
          {record.stageName ? record.stageName : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Processed Applicant',
      key: 'processedApplicant',
      width: 150,
      render: (record) => (
        <Text>
          {record.processedApplicant ? record.processedApplicant : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Date of Processed',
      key: 'dateOfProcessed',
      width: 150,
      render: (record) => (
        <Text>
          {record.dateOfProcessed ? record.dateOfProcessed : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Time of Processed',
      key: 'timeOfProcessed',
      width: 150,
      render: (record) => (
        <Text>
          {record.timeOfProcessed ? record.timeOfProcessed : 'N/A'}
        </Text>
      )
    }
  ]
}