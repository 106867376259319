import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading } from '../../../../components';
import { Player, BigPlayButton  } from 'video-react';
import { BankOutlined } from '@ant-design/icons';
import { detailClient, unmountDetailClients } from '../../../../redux/actions/client/clientAction';
import { PageHeader, Card, Row, Col, Descriptions, Typography, Image, Breadcrumb } from 'antd';
const { Text } = Typography;

class DetailClient extends Component {

  componentDidMount() {
    const { actionGetDetail, match: { params } } = this.props;
    return actionGetDetail(params.id)
  }

  render() {
    const { getData: { data, loading } } = this.props;

    if(loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><BankOutlined /> Clients</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/agency/clients">Clients List</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{data.clientNumber ? data.clientNumber : 'N/A'}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Client Detail' />
          </Col>
          {/* Descriptions */}
          <Col span={24}>
            <Card>
              <Row gutter={16} style={{ marginBottom: 32 }}>
                <Col span={24}>
                  <Descriptions layout="vertical" column={2} size="small">
                    <Descriptions.Item label={<Text type="secondary">Client Name</Text>}>
                      {data.name ? data.name : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Client Email</Text>}>
                      {data.email ? data.email : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Contact Person</Text>}>
                      {data.picName ? data.picName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Contact Person Mobile Number</Text>}>
                      {data.mobilePhone ? `+${data.callingCode}${data.mobilePhone}` : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Industry</Text>}>
                      {data.jobIndustryTitle ? data.jobIndustryTitle : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Contact Person Email</Text>}>
                      {data.picEmail ? data.picEmail : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Office Phone Number</Text>}>
                      {data.officePhone ? data.officePhone : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Country</Text>}>
                      {data.countryName ? data.countryName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Province</Text>}>
                      {data.provinceName ? data.provinceName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">City</Text>}>
                      {data.cityName ? data.cityName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Street Address</Text>}>
                      {data.address ? data.address : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Postal Code</Text>}>
                      {data.zipCode ? data.zipCode : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Company Size</Text>}>
                      {data.totalEmployee ? data.totalEmployee : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Website</Text>}>
                      {data.website ? data.website : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">About</Text>}>
                      {data.about ? data.about : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Logo</Text>}>
                      {data.logoUrl ? <Image width={100} src={data.logoUrl} /> : 'N/A'}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>

              <Row gutter={16} style={{ marginBottom: 32 }}>
                <Col span={24}>
                  <Text type='secondary'>Slider Banner:</Text>
                  <Row style={{ marginTop: 8 }}>
                    {
                      data?.listImage?.length > 0 ?
                      <Col span={24}>
                        <div className="list-slider-photo">
                          {
                            data?.listImage?.map((item, i) => (
                              <div key={i} style={{ position: 'relative' }}>
                                <Card className={item.isCover ? 'cover-photo' : 'not-cover-photo'}>
                                  {
                                    item.isCover ?
                                    <div style={{ width: '100%', marginBottom: 15 }}>
                                      <Text style={{ color: ' #706f6f', fontSize: 18 }}>COVER</Text>
                                    </div>
                                    :
                                    null
                                  }
                                  <Row gutter={[16, 24]}>
                                    <Col span={24}>
                                      <div>
                                        <Image src={item.fileUrl} width={300} /> 
                                      </div>
                                    </Col>
                                  </Row>
                                </Card>
                              </div>
                            ))
                          }
                        </div>
                      </Col>
                      :
                      <Col span={24}>
                        <Text type="secondary" style={{ fontSize: 16 }}>No photo displayed</Text>
                      </Col>
                    }
                  </Row>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <Text type='secondary'>Company Gallery:</Text>
                  <Row gutter={[16,32]} style={{ marginTop: 8 }}>
                    {
                      data.videoUrl || data.listMedia ?
                        <React.Fragment>
                          {
                            data.videoUrl ? 
                              <Col span={8}>
                                <Player
                                  fluid={false}
                                  width={'100%'}
                                  height={200}
                                >
                                  <source src={data.videoUrl}/>
                                  <BigPlayButton position='center'/>
                                </Player>
                              </Col>
                            : null
                          }
                          <Col span={24}>
                            <div className="list-slider-photo">
                              {
                                data?.listMedia?.map((item, i) => (
                                  <div key={i} style={{ position: 'relative' }}>
                                    <Card className={item.isCover ? 'cover-photo' : 'not-cover-photo'}>
                                      <Row gutter={[16, 24]}>
                                        <Col span={24}>
                                          <div>
                                            <Image src={item.fileUrl} width={300} /> 
                                          </div>
                                        </Col>
                                      </Row>
                                    </Card>
                                  </div>
                                ))
                              }
                            </div>
                          </Col>
                        </React.Fragment>
                      :
                        <Col span={24}>
                          <Text type="secondary" style={{ fontSize: 16 }}>No gallery displayed</Text>
                        </Col>
                    }
                  </Row>
                </Col>
              </Row>

            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail } = this.props;
    return unmountGetDetail()
  }
}

const mapStateToProps = (state) => ({
  getData: state.clients.detail
})

const mapDispatchToProps = {
  actionGetDetail   : detailClient,
  unmountGetDetail  : unmountDetailClients
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailClient)
