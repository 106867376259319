import React from 'react';
// import moment from 'moment';
import { Link } from 'react-router-dom';
import { Typography, Dropdown, Menu } from 'antd';
import { DashOutlined, ReloadOutlined } from '@ant-design/icons';
const { Text } = Typography;

const menu = (record, showConfirm) => (
  <Menu>
    {
      record.score === null ?
        <Menu.Item icon={<ReloadOutlined />} onClick={() => showConfirm(record.id)} disabled >
          Restart Test
        </Menu.Item>
      :
        <Menu.Item icon={<ReloadOutlined />} onClick={() => showConfirm(record.id)} >
          Restart Test
        </Menu.Item>
    }
  </Menu>
); 

export const columns = (sortedInfo, showConfirm, handleRoute ) => {
  return [
    {
      title: 'Submit Date',
      key: 'submitDate',
      width: 50,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.submitDate}
        </Text>
      )
    },
    {
      title: 'Submit Time',
      key: 'submitTime',
      width: 50,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.submitTime}
        </Text>
      )
    },
    {
      title: 'Name',
      key: 'candidateName',
      width: 200,
      render: (record) => (
        <Link className="link" to={`/candidate-pathway/english-assessment/detail/${record.id}`}>
          {record.candidateName}
        </Link>
      )
    },
    {
        title: 'Score',
        key: 'score',
        width: 20,
        render: (record) => (
            <Text onClick={() => handleRoute(record)}>
            {record.score ? record.score : '-'}
            </Text>
        )
    },
    {
        title: 'Levels',
        key: 'level',
        width: 30,
        render: (record) => (
            <Text onClick={() => handleRoute(record)}>
            {record.level ? record.level : '-'}
            </Text>
        )
    },
    {
      title: 'Number of tests taken',
      key: 'testTaken',
      width: 100,
      render: (record) => (
          <Text onClick={() => handleRoute(record)}>
          {record.testTaken ? record.testTaken : '-'}
          </Text>
      )
    },
    {
      title: '',
      key: 'action',
      width: 10,
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <Dropdown overlay={menu(record, showConfirm)} placement="left" arrow>
          <DashOutlined style={{ fontSize: 20 }} />
        </Dropdown>
      )
    },
  ]
}