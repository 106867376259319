import jsPDF from 'jspdf';
import moment from 'moment';
import BlankImage from '../assets/img/blank.png';
import IconEmail from '../assets/img/cv-mail.png';
import IconPhone from '../assets/img/cv-phone.png';
import IconEnvironment from '../assets/img/cv-environment.png';
import { message } from 'antd';

export const downloadCV = (data) => {
  const doc = new jsPDF('p','pt','a4',true)
  const strArrFullName = doc.splitTextToSize(data.fullName, 95)
  const strArrEmail = doc.splitTextToSize(data.email, 210)
  
  if(data.gender && data.callingCode && data.mobilePhone && data.listAddress.length > 0 && data.listEducation.length > 0 && data.listLanguage.length > 0 && data.listSkill.length > 0){
    const addressHome = data.listAddress.filter(item => item.type === 'HOME')
    const addressWork = data.listAddress.filter(item => item.type === 'WORK')
    const addressOther = data.listAddress.filter(item => item.type === 'OTHER')
    const strArrAddress = doc.splitTextToSize(
      addressHome.length > 0 ?
        addressHome[0].address ? 
          `${addressHome[0].address}, ${addressHome[0].villageName && addressHome[0].subDistrictName ? `${addressHome[0].villageName}, ${addressHome[0].subDistrictName}, ` : ''}${addressHome[0].cityName ? `${addressHome[0].cityName}, ` : ''}${addressHome[0].provinceName ? `${addressHome[0].provinceName}, ` : ''}${addressHome[0].countryName ? `${addressHome[0].countryName}, ` : ''}${addressHome[0].zipCode ? `${addressHome[0].zipCode}, ` : ''} (${addressHome[0].type === 'HOME' ? 'Home' : addressHome[0].type === 'WORK' ? 'Work' : addressHome[0].typeOther})` 
        : null
      : addressWork.length > 0 ?
        addressWork[0].address ?
          `${addressWork[0].address}, ${addressWork[0].villageName && addressWork[0].subDistrictName ? `${addressWork[0].villageName}, ${addressWork[0].subDistrictName}, ` : ''}${addressWork[0].cityName ? `${addressWork[0].cityName}, ` : ''}${addressWork[0].provinceName ? `${addressWork[0].provinceName}, ` : ''}${addressWork[0].countryName ? `${addressWork[0].countryName}, ` : ''}${addressWork[0].zipCode ? `${addressWork[0].zipCode}, ` : ''} (${addressWork[0].type === 'HOME' ? 'Home' : addressWork[0].type === 'WORK' ? 'Work' : addressWork[0].typeOther})`
        : null
      : 
        addressOther[0].address ? 
          `${addressOther[0].address}, ${addressOther[0].villageName && addressOther[0].subDistrictName ? `${addressOther[0].villageName}, ${addressOther[0].subDistrictName}, ` : ''}${addressOther[0].cityName ? `${addressOther[0].cityName}, ` : ''}${addressOther[0].provinceName ? `${addressOther[0].provinceName}, ` : ''}${addressOther[0].countryName ? `${addressOther[0].countryName}, ` : ''}${addressOther[0].zipCode ? `${addressOther[0].zipCode}, ` : ''} (${addressOther[0].type === 'HOME' ? 'Home' : addressOther[0].type === 'WORK' ? 'Work' : addressOther[0].typeOther})` 
        : null
    , 210)
    // Left Menu
    doc.setDrawColor(0);
    doc.setFillColor(0, 75, 128);
    doc.rect(0, 0, 190, 842, "F");

    // Image
    doc.addImage(data.profilePicUrl ? data.profilePicUrl : BlankImage, "JPEG", 55, 30, 80, 80);
    doc.setDrawColor(0, 75, 128);
    doc.setLineWidth(30);
    doc.circle(95, 70, 55);
    doc.setDrawColor(255, 255, 255);
    doc.setLineWidth(2);
    doc.circle(95, 70, 40);

    // Name
    doc.setTextColor(255, 255, 255);
    doc.setFontSize(17)
    doc.setFont('helvetica','','bold')
    doc.text(strArrFullName, 95, 150, null, null, "center");

    // Date of birth
    doc.setFontSize(10)
    doc.setFont('helvetica','','bold')
    doc.text(15, 240, 'Date of birth')
    doc.text(75, 240, ':')
    doc.setFont('helvetica','','normal')
    doc.text(82, 240, moment(data.birthdate, 'DD/MM/YYYY').format('DD MMM YYYY'))

    // Nationality
    doc.setFontSize(10)
    doc.setFont('helvetica','','bold')
    doc.text(15, 260, 'Nationality')
    doc.text(68, 260, ':')
    doc.setFont('helvetica','','normal')
    doc.text(75, 260, data.nationality)

    // Gender
    doc.setFontSize(10)
    doc.setFont('helvetica','','bold')
    doc.text(15, 280, 'Gender')
    doc.text(52, 280, ':')
    doc.setFont('helvetica','','normal')
    doc.text(59, 280, data.gender === "FEMALE" ? "Female" : "Male")

    // Contact
    doc.setFontSize(14)
    doc.setFont('helvetica','','bold')
    doc.text(15, 340, "CONTACT")

    // Email
    doc.setFontSize(10)
    doc.setFont('helvetica','','bold')
    doc.addImage(IconEmail, "PNG", 15, 350, 20, 20);
    doc.setFont('helvetica','','normal')
    doc.text(strArrEmail, 40, 363);

    // Phone
    doc.setFontSize(10)
    doc.setFont('helvetica','','bold')
    doc.addImage(IconPhone, "PNG", 15, 385, 20, 20);
    doc.setFont('helvetica','','normal')
    doc.text(40, 398, data.callingCode && data.mobilePhone ? `+${data.callingCode}${data.mobilePhone}` : '-');

    // Address
    if((addressHome.length > 0 && addressHome[0].address) || (addressWork.length > 0 && addressWork[0].address) || (addressOther.length > 0 && addressOther[0].address)){
      doc.setFontSize(10)
      doc.setFont('helvetica','','bold')
      doc.addImage(IconEnvironment, "PNG", 15, 410, 20, 20);
      doc.setFont('helvetica','','normal')
      doc.text(strArrAddress, 40, 424);
    }

    // Right Menu
    const height1 = data?.listWorkExperience?.length > 0 ? 95+(data?.listWorkExperience?.length*90)-25 : 45
    const height2 = height1+58+(data?.listEducation?.length*60)+(data?.listTraining?.length*60)-25
    const height3 = height2+50+(data?.listLanguage?.length*50)-25

    doc.setTextColor(0)
    // Work Experience
    if(data.listWorkExperience.length > 0){
      doc.setFontSize(14)
      doc.setFont('helvetica','','normal')
      doc.text(220, 45, "WORK EXPERIENCE")

      data.listWorkExperience.forEach((item,i) => {
        doc.setLineWidth(1.3);
        doc.setTextColor(0, 75, 128);
        doc.setDrawColor(0, 75, 128);
        doc.setFillColor(0, 75, 128);
        doc.line(221, 50+(i*90), 221, 120+(i*90));
        doc.setDrawColor(0, 75, 128);
        doc.setFillColor(0, 75, 128);
        doc.circle(221, 75+(i*90), 3, "FD");
      
        doc.setTextColor(0);
        doc.setFontSize(10)
        doc.setFont('helvetica','','bold')
        doc.text(235, 63+(i*90), `${moment(item.startDate, 'YYYY-MM').format('MMM YYYY')} - ${item.current ? 'CURRENT' : moment(item.endDate, 'YYYY-MM').format('MMM YYYY')}`)
        doc.setFont('helvetica','','normal')
        doc.text(item.current ? 340 : 334, 63+(i*90), item.cityName && item.countryName ? `${item.cityName}, ${item.countryName}` : '')
      
        doc.setTextColor(0);
        doc.setFontSize(10)
        doc.setFont('helvetica','','bold')
        doc.text(235, 79+(i*90), item.department ? `${item.department} (${item.company})` : item.company)
        
        if(item.jobDescription){
          const descExperience = doc.splitTextToSize(item.jobDescription, 332)
          doc.setFont('helvetica','','normal')
          doc.text(235, 95+(i*90), descExperience)
        }
      })
    }

    // Education
    if(data.listEducation.length > 0){
      doc.setFontSize(14)
      doc.setFont('helvetica','','normal')
      doc.text(220, height1, "EDUCATION AND TRAINING")
      
      data.listEducation.forEach((item,i) => {
        doc.setTextColor(0, 75, 128);
        doc.setLineWidth(1.3);
        doc.setDrawColor(0, 75, 128);
        doc.setFillColor(0, 75, 128);
        doc.line(221, 5+height1+(i*60), 221, 55+height1+(i*60));
        doc.setDrawColor(0, 75, 128);
        doc.setFillColor(0, 75, 128);
        // doc.circle(221, 270+(i*60), 3, "FD");
        doc.circle(221, 30+height1+(i*60), 3, "FD");
      
        doc.setTextColor(0);
        doc.setFontSize(10)
        doc.setFont('helvetica','','bold')
        // doc.text(235, 258+(i*60), `${moment(item.startYear, 'YYYY').format('YYYY')} - ${item.current ? 'CURRENT' : moment(item.endYear, 'YYYY').format('YYYY')}`)
        doc.text(235, 18+height1+(i*60), `${item.startYear ? moment(item.startYear, 'YYYY').format('YYYY') : ''}${item.current ? ' - CURRENT' : item.endYear ? ` - ${moment(item.endYear, 'YYYY').format('YYYY')}` : ''}`)
      
        doc.setTextColor(0);
        doc.setFontSize(10)
        doc.setFont('helvetica','','bold')
        // doc.text(235, 273+(i*60), `${item.fieldOfStudy} (${item.name})`)
        const titleEducation = doc.splitTextToSize(item.fieldOfStudy ? `${item.fieldOfStudy} (${item.name})` : item.name, 345)
        doc.text(titleEducation, 235, 34+height1+(i*60))
      
        const web = doc.splitTextToSize(item.website ? item.website : '-', 289)
        // doc.text(235, 288+(i*60), "Website")
        doc.text(235, 56+height1+(i*60), "Website")
        doc.setFont('helvetica','','normal')
        doc.setTextColor(0, 75, 128);
        // doc.text(web, 278, 288+(i*60))
        doc.text(web, 278, 56+height1+(i*60))
      })

      data.listTraining.forEach((item,i) => {
        doc.setTextColor(0, 75, 128);
        doc.setLineWidth(1.3);
        doc.setDrawColor(0, 75, 128);
        doc.setFillColor(0, 75, 128);
        doc.line(221, 5+height1+(i*60)+(data.listEducation.length*60), 221, 55+height1+(i*60)+(data.listEducation.length*60));
        doc.setDrawColor(0, 75, 128);
        doc.setFillColor(0, 75, 128);
        doc.circle(221, 30+height1+(i*60)+(data.listEducation.length*60), 3, "FD");
      
        doc.setTextColor(0);
        doc.setFontSize(10)
        doc.setFont('helvetica','','bold')
        doc.text(235, 18+height1+(i*60)+(data.listEducation.length*60), `${moment(item.issueDate, 'YYYY-MM').format('MMM YYYY')}${item.expDate ? ` - ${moment(item.expDate, 'YYYY-MM').format('MMM YYYY')}` : ''}`)
      
        doc.setTextColor(0);
        doc.setFontSize(10)
        doc.setFont('helvetica','','bold')
        const titleTraining = doc.splitTextToSize(`${item.name} (${item.organization})`, 345)
        doc.text(titleTraining, 235, 35+height1+(i*60)+(data.listEducation.length*60))
      })
    }

    //Language Skill
    if(height3 <= 800){
      if(data.listLanguage.length > 0){
        doc.setTextColor(0)
        doc.setFontSize(14)
        doc.setFont('helvetica','','normal')
        // doc.text(220, 510, "LANGUAGE SKILLS")
        doc.text(220, height2, "LANGUAGE SKILLS")
    
        const nativeLang = data.listLanguage.filter(item => item.isNative === true)
        doc.setTextColor(0)
        doc.setFontSize(10)
        doc.setFont('helvetica','','bold')
        // doc.text(220, 528, 'Mother Tongue(s):')
        doc.text(220, 18+height2, 'Mother Tongue(s):')
        doc.setFont('helvetica','','normal')
        // doc.text(310, 528, nativeLang[0].name)
        doc.text(310, 18+height2, nativeLang[0].name)
    
        const otherLang = data.listLanguage.filter(item => item.isNative === false)
        doc.setFont('helvetica','','bold')
        // doc.text(220, 543, 'Other Language(s):')
        doc.text(220, 33+height2, 'Other Language(s):')
    
        otherLang.forEach((item,i) => {
          doc.setFont('helvetica','','normal')
          // doc.text(220, 558+(i*50), item.name)
          doc.text(220, 48+height2+(i*50), item.name)
          doc.setFont('helvetica','','bold')
          // doc.text(220, 574+(i*50), 'Listening')
          doc.text(220, 64+height2+(i*50), 'Listening')
          doc.setFont('helvetica','','normal')
          // doc.text(235, 590+(i*50), item.listeningLevelCode ? item.listeningLevelCode : '-')
          doc.text(235, 80+height2+(i*50), item.listeningLevelCode ? item.listeningLevelCode : '-')
          doc.setFont('helvetica','','bold')
          doc.text(275, 64+height2+(i*50), 'Reading')
          doc.setFont('helvetica','','normal')
          doc.text(287, 80+height2+(i*50), item.readingLevelCode ? item.readingLevelCode : '-')
          doc.setFont('helvetica','','bold')
          doc.text(335, 64+height2+(i*50), 'Spoken Introduction')
          doc.setFont('helvetica','','normal')
          doc.text(375, 80+height2+(i*50), item.spokenIntroLevelCode ? item.spokenIntroLevelCode : '-')
          doc.setFont('helvetica','','bold')
          doc.text(435, 64+height2+(i*50), 'Spoken Intraction')
          doc.setFont('helvetica','','normal')
          doc.text(467, 80+height2+(i*50), item.spokenInterLevelCode ? item.spokenInterLevelCode : '-')
          doc.setFont('helvetica','','bold')
          doc.text(530, 64+height2+(i*50), 'Writing')
          doc.setFont('helvetica','','normal')
          doc.text(540, 80+height2+(i*50), item.writingLevelCode ? item.writingLevelCode : '-')
    
          doc.setLineWidth(0.2);
          doc.line(220, 85+height2+(i*50), 565, 85+height2+(i*50));
        })
      }
    }

    //Digital Skill
    if(height3 <= 800){
      if(data.listSkill.length > 0){
        const skill = doc.splitTextToSize(data.listSkill.map(item => item.name).toString().replaceAll(',', ' | '), 345)
        doc.setTextColor(0)
        doc.setFontSize(14)
        doc.setFont('helvetica','','normal')
        // doc.text(220, 730, "DIGITAL SKILLS")
        doc.text(220, height3, "DIGITAL SKILLS")
        doc.setFontSize(10)
        doc.text(skill, 220, 15+height3)
      }
    }
    
    if(height3 > 800){
      doc.addPage('p','pt','a4',true);

      const page2Height1 = 45+(data?.listLanguage?.length)
      const page2Height2 = page2Height1+50+(data?.listLanguage?.length*50)-25

      // Left Menu
      doc.setDrawColor(0);
      doc.setFillColor(0, 75, 128);
      doc.rect(0, 0, 190, 842, "F");

      //Language Skill
      if(data.listLanguage.length > 0){
        doc.setTextColor(0)
        doc.setFontSize(14)
        doc.setFont('helvetica','','normal')
        // doc.text(220, 510, "LANGUAGE SKILLS")
        doc.text(220, (page2Height1), "LANGUAGE SKILLS")
    
        const nativeLang = data.listLanguage.filter(item => item.isNative === true)
        doc.setTextColor(0)
        doc.setFontSize(10)
        doc.setFont('helvetica','','bold')
        // doc.text(220, 528, 'Mother Tongue(s):')
        doc.text(220, 18+(page2Height1), 'Mother Tongue(s):')
        doc.setFont('helvetica','','normal')
        // doc.text(310, 528, nativeLang[0].name)
        doc.text(310, 18+(page2Height1), nativeLang[0].name)
    
        const otherLang = data.listLanguage.filter(item => item.isNative === false)
        doc.setFont('helvetica','','bold')
        // doc.text(220, 543, 'Other Language(s):')
        doc.text(220, 33+(page2Height1), 'Other Language(s):')
    
        otherLang.forEach((item,i) => {
          doc.setFont('helvetica','','normal')
          // doc.text(220, 558+(i*50), item.name)
          doc.text(220, 48+(page2Height1)+(i*50), item.name)
          doc.setFont('helvetica','','bold')
          // doc.text(220, 574+(i*50), 'Listening')
          doc.text(220, 64+(page2Height1)+(i*50), 'Listening')
          doc.setFont('helvetica','','normal')
          // doc.text(235, 590+(i*50), item.listeningLevelCode ? item.listeningLevelCode : '-')
          doc.text(235, 80+(page2Height1)+(i*50), item.listeningLevelCode ? item.listeningLevelCode : '-')
          doc.setFont('helvetica','','bold')
          doc.text(275, 64+(page2Height1)+(i*50), 'Reading')
          doc.setFont('helvetica','','normal')
          doc.text(287, 80+(page2Height1)+(i*50), item.readingLevelCode ? item.readingLevelCode : '-')
          doc.setFont('helvetica','','bold')
          doc.text(335, 64+(page2Height1)+(i*50), 'Spoken Introduction')
          doc.setFont('helvetica','','normal')
          doc.text(375, 80+(page2Height1)+(i*50), item.spokenIntroLevelCode ? item.spokenIntroLevelCode : '-')
          doc.setFont('helvetica','','bold')
          doc.text(435, 64+(page2Height1)+(i*50), 'Spoken Intraction')
          doc.setFont('helvetica','','normal')
          doc.text(467, 80+(page2Height1)+(i*50), item.spokenInterLevelCode ? item.spokenInterLevelCode : '-')
          doc.setFont('helvetica','','bold')
          doc.text(530, 64+(page2Height1)+(i*50), 'Writing')
          doc.setFont('helvetica','','normal')
          doc.text(540, 80+(page2Height1)+(i*50), item.writingLevelCode ? item.writingLevelCode : '-')
    
          doc.setLineWidth(0.2);
          doc.line(220, 85+(page2Height1)+(i*50), 565, 85+(page2Height1)+(i*50));
        })

        //Digital Skill
        if(data.listSkill.length > 0){
          const skill = doc.splitTextToSize(data.listSkill.map(item => item.name).toString().replaceAll(',', ' | '), 345)
          doc.setTextColor(0)
          doc.setFontSize(14)
          doc.setFont('helvetica','','normal')
          // doc.text(220, 730, "DIGITAL SKILLS")
          doc.text(220, page2Height2, "DIGITAL SKILLS")
          doc.setFontSize(10)
          doc.text(skill, 220, 15+page2Height2)
        }
      }
    }

    doc.save(`${data.candidateNumber} - CV.pdf`);
    message.success('Download success!')
  }else{
    message.error('Data is not complete. Please contact the candidate!')
  }
}