import jsPDF from 'jspdf';
import moment from 'moment';
import KopSurat from '../assets/img/kop_surat.png';
// import Signature from '../assets/img/ttdis.png';
// import Stamp from '../assets/img/stamp.png';
import StampKebidanan from '../assets/img/stm kebidanan.png';
import StampFKIT from '../assets/img/stm fikt.png';
import StampBisnis from '../assets/img/stm bisnis.png';
import TTDHeny from '../assets/img/ttd henny.png';
import TTDFKK from '../assets/img/ttd fkk.png';
import TTDFBIS from '../assets/img/ttd fbis.png';
import TTDHealth from '../assets/img/ttd mia.png';
import { message } from 'antd';

export const downloadSuketLibur = (data) => {
  const dataEducation = data?.listEducation?.find(item => item.name.includes('binawan') || item.name.includes('Binawan') || item.name.includes('BINAWAN'))
  if(dataEducation 
    && (dataEducation?.educationLevelName === "Bachelor / S1" || dataEducation?.educationLevelName === "Diploma IV" || dataEducation?.educationLevelName === "Diploma III" || dataEducation?.educationLevelName === "Professional Program") 
    && dataEducation?.fieldOfStudy 
    && dataEducation.startYear 
    && (dataEducation.endYear || dataEducation.current) 
    && (dataEducation.fieldOfStudy === 'Nursing' || dataEducation.fieldOfStudy === 'Nursing / Keperawatan' || dataEducation.fieldOfStudy === 'Midwifery' || dataEducation.fieldOfStudy === 'Bachelor of Midwifery (S.Keb.)' || dataEducation.fieldOfStudy === 'Midwifery / Kebidanan' || dataEducation.fieldOfStudy === 'Ners Profession' || dataEducation.fieldOfStudy === 'Nurse profession / Profesi Ners' || dataEducation.fieldOfStudy === 'Bachelor of Nursing (S.Kep.)' || dataEducation.fieldOfStudy === 'Medical Laboratory Technology' || dataEducation.fieldOfStudy === 'Medical Laboratory Technology / Teknologi Laboratorium Medik' || dataEducation.fieldOfStudy === 'Occupational Health and Safety'|| dataEducation.fieldOfStudy === 'Occupational Health and Safety / Keselamatan dan Kesehatan Kerja' || dataEducation.fieldOfStudy === 'Pharmacy' || dataEducation.fieldOfStudy === 'Bachelor of Pharmacy (S.Farm.)' || dataEducation.fieldOfStudy === 'Physiotherapy' || dataEducation.fieldOfStudy === 'Physiotherapy / Fisioterapi' || dataEducation.fieldOfStudy === 'Nutrition' || dataEducation.fieldOfStudy === 'Bachelor of Nutrition (S.Gz.)' || dataEducation.fieldOfStudy === 'Masters in Public Health (M.K.M.)' || dataEducation.fieldOfStudy === 'Digital Business' || dataEducation.fieldOfStudy === 'Management' || dataEducation.fieldOfStudy === 'Psychology' || dataEducation.fieldOfStudy === 'Bachelor of Psychology (S.Psi.)' || dataEducation.fieldOfStudy === 'Social Welfare')){
    const nameKaprodi = 
      dataEducation.fieldOfStudy === 'Nursing' || dataEducation.fieldOfStudy === 'Nursing / Keperawatan' || dataEducation.fieldOfStudy === 'Midwifery' || dataEducation.fieldOfStudy === 'Bachelor of Midwifery (S.Keb.)' || dataEducation.fieldOfStudy === 'Midwifery / Kebidanan' || dataEducation.fieldOfStudy === 'Ners Profession' || dataEducation.fieldOfStudy === 'Nurse profession / Profesi Ners' || dataEducation.fieldOfStudy === 'Bachelor of Nursing (S.Kep.)' ?
        "Dr. Aliana Dewi, S.Kp., M.N  "
      : dataEducation.fieldOfStudy === 'Medical Laboratory Technology' || dataEducation.fieldOfStudy === 'Medical Laboratory Technology / Teknologi Laboratorium Medik' || dataEducation.fieldOfStudy === 'Occupational Health and Safety'|| dataEducation.fieldOfStudy === 'Occupational Health and Safety / Keselamatan dan Kesehatan Kerja' || dataEducation.fieldOfStudy === 'Pharmacy' || dataEducation.fieldOfStudy === 'Bachelor of Pharmacy (S.Farm.)' || dataEducation.fieldOfStudy === 'Physiotherapy' || dataEducation.fieldOfStudy === 'Physiotherapy / Fisioterapi' || dataEducation.fieldOfStudy === 'Nutrition' || dataEducation.fieldOfStudy === 'Bachelor of Nutrition (S.Gz.)' || dataEducation.fieldOfStudy === 'Masters in Public Health (M.K.M.)'?
        "Dr. Mia Srimiati, S.Gz., M,Si"
      : dataEducation.fieldOfStudy === 'Digital Business' || dataEducation.fieldOfStudy === 'Management' || dataEducation.fieldOfStudy === 'Psychology' || dataEducation.fieldOfStudy === 'Bachelor of Psychology (S.Psi.)' || dataEducation.fieldOfStudy === 'Social Welfare'?
        "Dr. Ir. Firdaus Basbeth, MM, PMA CIQAR, CIQNR"
      : null

    const fieldOfStudy = 
      dataEducation.fieldOfStudy === 'Nursing' || dataEducation.fieldOfStudy === 'Nursing / Keperawatan' || dataEducation.fieldOfStudy === 'Midwifery' || dataEducation.fieldOfStudy === 'Bachelor of Midwifery (S.Keb.)' || dataEducation.fieldOfStudy === 'Midwifery / Kebidanan' || dataEducation.fieldOfStudy === 'Ners Profession' || dataEducation.fieldOfStudy === 'Nurse profession / Profesi Ners' || dataEducation.fieldOfStudy === 'Bachelor of Nursing (S.Kep.)' ?
        "Faculty of Nursing and Midwifery"
      : dataEducation.fieldOfStudy === 'Medical Laboratory Technology' || dataEducation.fieldOfStudy === 'Medical Laboratory Technology / Teknologi Laboratorium Medik' || dataEducation.fieldOfStudy === 'Occupational Health and Safety'|| dataEducation.fieldOfStudy === 'Occupational Health and Safety / Keselamatan dan Kesehatan Kerja' || dataEducation.fieldOfStudy === 'Pharmacy' || dataEducation.fieldOfStudy === 'Bachelor of Pharmacy (S.Farm.)' || dataEducation.fieldOfStudy === 'Physiotherapy' || dataEducation.fieldOfStudy === 'Physiotherapy / Fisioterapi' || dataEducation.fieldOfStudy === 'Nutrition' || dataEducation.fieldOfStudy === 'Bachelor of Nutrition (S.Gz.)' || dataEducation.fieldOfStudy === 'Masters in Public Health (M.K.M.)'?
        "Faculty of Health Sciences and Technology"
      : dataEducation.fieldOfStudy === 'Digital Business' || dataEducation.fieldOfStudy === 'Management' || dataEducation.fieldOfStudy === 'Psychology' || dataEducation.fieldOfStudy === 'Bachelor of Psychology (S.Psi.)' || dataEducation.fieldOfStudy === 'Social Welfare'?
        "Faculty of Business and Social Sciences"
      : null
    
    const estimateEndYear = 
      dataEducation?.educationLevelName === "Bachelor / S1" || dataEducation?.educationLevelName === "Diploma IV" ?
        Number(dataEducation?.startYear)+4
      : dataEducation?.educationLevelName === "Diploma III" ?
        Number(dataEducation?.startYear)+3
      : dataEducation?.educationLevelName === "Professional Program" ?
        Number(dataEducation?.startYear)+1
      : null

    const stampFakultas = 
    dataEducation.fieldOfStudy === 'Nursing' || dataEducation.fieldOfStudy === 'Nursing / Keperawatan' || dataEducation.fieldOfStudy === 'Midwifery'|| dataEducation.fieldOfStudy === 'Bachelor of Midwifery (S.Keb.)' || dataEducation.fieldOfStudy === 'Midwifery / Kebidanan' || dataEducation.fieldOfStudy === 'Ners Profession' || dataEducation.fieldOfStudy === 'Nurse profession / Profesi Ners' || dataEducation.fieldOfStudy === 'Bachelor of Nursing (S.Kep.)' ?
        StampKebidanan
      : dataEducation.fieldOfStudy === 'Medical Laboratory Technology' || dataEducation.fieldOfStudy === 'Medical Laboratory Technology / Teknologi Laboratorium Medik' || dataEducation.fieldOfStudy === 'Occupational Health and Safety'|| dataEducation.fieldOfStudy === 'Occupational Health and Safety / Keselamatan dan Kesehatan Kerja' || dataEducation.fieldOfStudy === 'Pharmacy' || dataEducation.fieldOfStudy === 'Bachelor of Pharmacy (S.Farm.)' || dataEducation.fieldOfStudy === 'Physiotherapy' || dataEducation.fieldOfStudy === 'Physiotherapy / Fisioterapi' || dataEducation.fieldOfStudy === 'Nutrition' || dataEducation.fieldOfStudy === 'Bachelor of Nutrition (S.Gz.)' || dataEducation.fieldOfStudy === 'Masters in Public Health (M.K.M.)'?
        StampFKIT
      : dataEducation.fieldOfStudy === 'Digital Business' || dataEducation.fieldOfStudy === 'Management' || dataEducation.fieldOfStudy === 'Psychology' || dataEducation.fieldOfStudy === 'Bachelor of Psychology (S.Psi.)' || dataEducation.fieldOfStudy === 'Social Welfare'?
        StampBisnis
      : null

    const ttdFakultas = 
      dataEducation.fieldOfStudy === 'Nursing' || dataEducation.fieldOfStudy === 'Nursing / Keperawatan' || dataEducation.fieldOfStudy === 'Midwifery'|| dataEducation.fieldOfStudy === 'Bachelor of Midwifery (S.Keb.)' || dataEducation.fieldOfStudy === 'Midwifery / Kebidanan' || dataEducation.fieldOfStudy === 'Ners Profession' || dataEducation.fieldOfStudy === 'Nurse profession / Profesi Ners' || dataEducation.fieldOfStudy === 'Bachelor of Nursing (S.Kep.)' ?
          TTDFKK
        : dataEducation.fieldOfStudy === 'Medical Laboratory Technology' || dataEducation.fieldOfStudy === 'Medical Laboratory Technology / Teknologi Laboratorium Medik' || dataEducation.fieldOfStudy === 'Occupational Health and Safety'|| dataEducation.fieldOfStudy === 'Occupational Health and Safety / Keselamatan dan Kesehatan Kerja' || dataEducation.fieldOfStudy === 'Pharmacy' || dataEducation.fieldOfStudy === 'Bachelor of Pharmacy (S.Farm.)' || dataEducation.fieldOfStudy === 'Physiotherapy' || dataEducation.fieldOfStudy === 'Physiotherapy / Fisioterapi' || dataEducation.fieldOfStudy === 'Nutrition' || dataEducation.fieldOfStudy === 'Bachelor of Nutrition (S.Gz.)' || dataEducation.fieldOfStudy === 'Masters in Public Health (M.K.M.)'?
          TTDHealth
        : dataEducation.fieldOfStudy === 'Digital Business' || dataEducation.fieldOfStudy === 'Management' || dataEducation.fieldOfStudy === 'Psychology' || dataEducation.fieldOfStudy === 'Bachelor of Psychology (S.Psi.)' || dataEducation.fieldOfStudy === 'Social Welfare'?
          TTDFBIS
        : null

    const standardPeroid = 
      dataEducation?.educationLevelName === "Bachelor / S1" || dataEducation?.educationLevelName === "Diploma IV" ?
        '8'
      : dataEducation?.educationLevelName === "Diploma III" ?
        '6'
      : dataEducation?.educationLevelName === "Professional Program" ?
        '2'
      : null

    const doc = new jsPDF('p','pt','a4', true)
    doc.addImage(KopSurat, 'PNG', 10, 10, 580, 820)
    
    doc.setFontSize(11)

    doc.setFont('helvetica','','bold')
    doc.text(250, 100, 'STATEMENT LETTER')

    doc.setFont('helvetica','','normal')
    doc.text(50, 140, 'I am the undersigned:')

    doc.text(50, 160, `Name`)
    doc.text(200, 160, ':')
    doc.text(210, 160, nameKaprodi ? nameKaprodi : '...............................................')
    
    doc.text(50, 180, `Position`)
    doc.text(200, 180, ':')
    doc.text(210, 180, `Dean Of ${fieldOfStudy ? fieldOfStudy : '...............................................'}`)

    doc.setFont('helvetica','','normal')
    doc.text(50, 210, 'Explain that our students below:')

    doc.text(50, 240, `Student Name`)
    doc.text(230, 240, ':')
    doc.text(240, 240, `${data.fullName}`)
    
    doc.text(50, 260, `Study Program`)
    doc.text(230, 260, ':')
    doc.text(240, 260, `${dataEducation.educationLevelName}, ${fieldOfStudy ? fieldOfStudy : '...............................................'}`)

    doc.text(50, 280, `Enrolled Since`)
    doc.text(230, 280, ':')
    doc.text(240, 280, dataEducation.startYear ? `14.09.${dataEducation.startYear}` : '...............................................')

    doc.text(50, 300, `Expected Graduation Date`)
    doc.text(230, 300, ':')
    doc.text(240, 300, dataEducation.endYear || (dataEducation.current && estimateEndYear) ? `14.09.${estimateEndYear || dataEducation.endYear}` : '...............................................')
    
    doc.text(50, 320, `Semester`)
    doc.text(230, 320, ':')
    doc.text(240, 320, dataEducation.startYear ? `${(Number(moment().format('YYYY')) - Number(dataEducation?.startYear))*2+1}` : '...............................................')
    
    doc.text(50, 340, `Standard period Of Study`)
    doc.text(230, 340, ':')
    doc.text(240, 340, standardPeroid ? `${standardPeroid} Semesters` : '...............................................')
    
    doc.text(50, 380, `Is true as student of the ${fieldOfStudy ? fieldOfStudy : '........................................'} at Binawan University and has his/ `)
    doc.text(50, 400, `her Semester holiday period beetwen October 1, 2023 to December 31, 2023.`)

    doc.text(50, 430, 'This letter is to be used properly.')

    doc.text(50, 460, `Jakarta, ${moment().format('LL')}`)
    doc.text(400, 470, 'Acknowledge by,')
    doc.setFont('helvetica','','bold')
    doc.text(50, 560, nameKaprodi ? nameKaprodi : '(............................................................)')
    doc.text(50, 575, `Dean Of ${fieldOfStudy ? fieldOfStudy : '...............................................' }`)
    if(stampFakultas){
      doc.addImage(stampFakultas, 'PNG',120, 455, 110, 105)
    }
    if(ttdFakultas){
      doc.addImage(ttdFakultas, 'PNG', 80, 460, 80, 80)
    }
    doc.addImage(TTDHeny, 'PNG', 360, 480, 80, 80)
    // doc.addImage(Stamp, 'PNG', 370, 420, 140, 180)
    doc.text(340, 560, 'Prof. Henny Suzana Mediani, SKp, M.Ng, PhD' )
    doc.text(340, 575, 'On-duty Rector of Binawan University')
      // doc.addImage(Signature, 'PNG', 110, 420, 80, 80)
    // doc.text(50, 505, 'Prof. Dr. Ir. Illah Sailah, MS.')

    doc.save(`${data.candidateNumber} - Surat Keterangan Libur.pdf`);
    message.success('Download success!')
  }else{
    message.error('Data is not complete. Please contact the candidate!')
  }
}