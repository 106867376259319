export const urgencyType = [
  {
    "value": "URGENT",
    "name": "Need to provide right away"
  },
  {
    "value": "NEXT_MONTH",
    "name": "Next Month"
  },
  {
    "value": "TALENT_POOL",
    "name": "For talent pool only"
  }
]