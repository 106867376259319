import React, { Component } from 'react';
import moment from 'moment';
import { Row, Col, Typography, Divider, Space } from 'antd';
const { Text } = Typography;

export default class ContentModal extends Component {
  render() {
    const { visible, valueForm } = this.props;
    return (
      <React.Fragment>
        {/* <Row gutter={16}> */}
          <Col span={24} style={{ marginBottom: 20 }}>
            <Text>Interview schedule detail:</Text>
          </Col>
            {
              visible ?
                <Row>
                  <Col span={24}>
                    <Space direction='vertical' size={0}>
                      <Space size={4}>
                        <Text>Date</Text>
                        <Text>:</Text>
                        <Text strong>{valueForm.interviewDate ? moment(valueForm.interviewDate, 'DD/MM/YYYY').format('DD MMM YYYY') : null}</Text>
                      </Space>
                      <Space size={4}>
                        <Text>Time</Text>
                        <Text>:</Text>
                        <Text strong>{valueForm.interviewTime ? valueForm.interviewTime : null }</Text>
                      </Space>
                      <Space size={4}>
                        <Text>Method</Text>
                        <Text>:</Text>
                        <Text strong>{valueForm.isMethod ? "Direct" : "Virtual"}</Text>
                      </Space>
                      <Space size={4} style={{ textAlign: 'justify', marginBottom: -16 }}>
                        {
                          valueForm.isMethod && 
                            <Space size={4} align="start" style={{ textAlign: 'justify' }}>
                              Location
                              <Text>:</Text>
                              <div dangerouslySetInnerHTML={{ __html: valueForm.location ? valueForm.location : null }} style={{ fontWeight: 'bold', paddingTop: 2 }}></div>
                            </Space>
                        }
                        {
                          !valueForm.isMethod &&
                            <Space size={4} align="start" style={{ textAlign: 'justify' }}>
                                Virtual Link
                                <Text> : </Text>
                                <div dangerouslySetInnerHTML={{ __html: valueForm.virtualLink ? valueForm.virtualLink : null }} style={{ fontWeight: 'bold', paddingTop: 2 }}></div>
                            </Space>
                        }
                      </Space>
                      <Space size={4} align="start" style={{ textAlign: 'justify', marginBottom: -16, marginTop: -16  }}>
                          Notes
                          <Text> :</Text>
                        <div dangerouslySetInnerHTML={{ __html: valueForm.notes ? valueForm.notes : null }} style={{ fontWeight: 'bold', paddingTop: 2 }}></div>
                      </Space>
                    </Space>
                  </Col>
                </Row>
              : null
            }
          <Divider />
          <Col span={24} style={{ marginBottom: 20 }}>
            <Text strong>
              By doing this, the interview schedule will be published
              to the applicant. Are you sure?
            </Text>
          </Col>
        {/* </Row> */}
      </React.Fragment>
    )
  }
}