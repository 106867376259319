import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loading } from '../../../../components'
import FormDocument from './Form';
import EditDocument from './Form/EditDocument'
import moment from 'moment';
import { UserOutlined, FileAddOutlined, UploadOutlined, DownloadOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Row, Col, Button, Avatar, Space, Descriptions, Divider, Typography, Modal, Upload,Image, message } from 'antd';
import { masterDocumentTypes, unmountMasterDocumentType } from '../../../../redux/actions/master/documentTypes/documentTypesAction';
import { listCounselingProcess, unmountListCounselingProcess } from '../../../../redux/actions/counselingProcess/counselingProcessAction';
import { addDocument, updateDocument, deleteDocument  } from '../../../../redux/actions/candidate/candidateAction';
const { confirm } = Modal;
const { Text } = Typography;

export class Profile extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
    this.state = {
      dataDocuments: null,
      submitLoading: false,
      visibleDocument: false,
      visibleEdit: false,
      visibleAdd: false,
      startDate: null,
      endDate: null,
      current: false,
      isDate: false,
      documentFile: null,
      showFileEdit: false,
      deleteLoading: false,
      idApplicant: null,
    }
  }

  showModalDocument = (type, item) => {
    if(type === 'add'){
     return this.setState({ visibleDocument: true, documentFile: null})
    }
    if(type === 'edit'){
      return this.setState({
        visibleEdit: true,
        dataDocuments: item,
        current: item.current,
        showFileEdit: true
      })

    }
  }

  onCancel = (type) => {
    if(type === 'add'){
      return this.setState({ visibleDocument: false, documentFile: null})
    }
    if(type === 'edit'){
      return this.setState({ 
        visibleEdit: false,
        dataDocuments: null,
        current: false,
        showFileEdit: false
      })
    }
  }

  onSubmitAdd = () => {
    const { current, documentFile } = this.state;
    const { actionAdd, actionGetProfile, getProfile } = this.props;
    this.setState({ submitLoading: true })
    this.formRef.current.validateFields().then(values => {
      values.documentNumber = values.documentNumber ? values.documentNumber : ""
      values.current = current
      values.issueDate = values.issueDate ? moment(values.issueDate).format('MM/YYYY') : ""
      values.expirationDate = values.expirationDate ? moment(values.expirationDate).format('MM/YYYY') : ""
      values.documentFile = documentFile
      values.remark = values.remark ? values.remark : ""
      values.candidateId = getProfile.data.id
      return actionAdd(values, () => {
        return this.setState({ submitLoading: false }, () => {
          return this.setState({ visibleDocument: false }, () => {
            message.success('Data document created successfully')
            return actionGetProfile(getProfile.data.id)
          })
        })
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err))
      })
    })
  }

  handleUpload() {
    return {
      withCredentials: true,
      accept: ".jpg, .jpeg, .pdf, .png",
      beforeUpload: file => {
        const validateSize = file.size >= 500000;
        if (validateSize) {
          message.error('Max file size is 500 KB');
          return false
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.setState({
            documentFile: file
          })
          message.info('Upload Success!');
        }
        return false;
      }
    }
  }

  handleRemove = (file) => {
    this.setState({ 
      documentFile: null,
      showFileEdit: false
    })
    message.success(`${file.name} file removed successfully`);
  }

  handleChecked = (e) => {
    this.setState({ current: e.target.checked })
    if(e.target.checked){
      this.setState({ endDate: null })
      this.formRef.current.setFieldsValue({
        endDate: null
      })
    }
  }

  onChangeStartDate = (date, dateString) => {
    const { endDate } = this.state
    this.setState({ startDate: dateString })
    if(endDate <= dateString){
      this.setState({ isDate: true })
    }else{
      this.setState({ isDate: false })
    }
  }

  onChangeEndDate = (date, dateString) => {
    const { startDate } = this.state
    this.setState({ endDate: dateString })
    if(dateString <= startDate){
      this.setState({ isDate: true })
    }else{
      this.setState({ isDate: false })
    }
  }

  onSubmitEdit = () => {
    const { current, dataDocuments, documentFile } = this.state;
    const { actionUpdate, actionGetProfile, getProfile } = this.props;
    this.setState({ submitLoading: true })
    this.formRef.current.validateFields().then(values => {
      values.current = current 
      values.issueDate = values.issueDate ? moment(values.issueDate).format('MM/YYYY') : dataDocuments.issueDate ? dataDocuments.issueDate : ""
      values.expirationDate = values.expirationDate ? moment(values.expirationDate).format('MM/YYYY') : dataDocuments.expirationDate ? dataDocuments.expirationDate : ""
      if(documentFile){
        values.documentFile = documentFile
      }else{
        delete values.documentFile
      }
      values.candidateId = getProfile.data.id
      values.id = dataDocuments.id
      return actionUpdate(values, () => {
        return this.setState({ submitLoading: false }, () => {
          return this.setState({ visibleEdit: false, showFileEdit: false }, () => {
            message.success('Data document updated successfully')
            return actionGetProfile(getProfile.data.id)
          })
        })
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err))
      })
    })
  }

  handleDelete = () => {
    const { dataDocuments } = this.state;
    const { actionDelete, actionGetProfile, getProfile } = this.props;
    const self = this
    confirm({
      title: 'Remove Data',
      okText: 'Yes',
      cancelText: 'No',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to remove this data?',
      onOk() {
        self.setState({ deleteLoading: true })
        return actionDelete(dataDocuments.id, () => {
          return self.setState({ deleteLoading: false }, () => {
            return self.setState({ visibleEdit: false, showFileEdit: false }, () => {
              message.success('Data deleted successfully')
              return actionGetProfile(getProfile.data.id)
            })
          })
        }, (err) => {
          return self.setState({ deleteLoading: false }, () => message.error(err))
        })
      },
    });
  }


  render() {
    const { visibleDocument , documentFile,deleteLoading,visibleEdit, dataDocuments, showFileEdit} = this.state
    const { getProfile: {data, loading} } = this.props; 
    if(loading){
      return <Loading/>
    }
    const dataJobIndustry = data?.listJobIndustry ? data?.listJobIndustry?.map(item => item.jobIndustryName) : []
    const dataJobDestination = data?.listJobDestination ? data?.listJobDestination?.map(item => item.jobDestinationName) : []
    const dataJobPosition = data?.listJobPosition ? data?.listJobPosition?.map(item => item.jobPositionName) : []

    const addFileList = [
      {
        uid: '-1',
        name: documentFile?.name,
        status: 'done'
      }
    ]
    const editFileList = [
      {
        uid: '-1',
        name: dataDocuments?.documentPic,
        status: 'done',
        url: dataDocuments?.documentUrl,
        thumbUrl: dataDocuments?.documentUrl,
      }
    ]
    const uploadDocumentAdd = (
      <Upload
        {...this.handleUpload()}
        onRemove={this.handleRemove}
        listType="picture"
        maxCount={1}
        showUploadList={documentFile}
        fileList={documentFile ? addFileList : []}
      >
        <Button icon={<UploadOutlined/>}> Upload Document</Button>

      </Upload>
    )

    const uploadDocumentEdit = (
      <Upload 
        {...this.handleUpload()} 
        onRemove={this.handleRemove}
        listType="picture" 
        maxCount={1} 
        showUploadList={documentFile ? documentFile : showFileEdit}
        fileList={documentFile ? addFileList : showFileEdit ? editFileList : []}
      >
        <Button icon={<UploadOutlined />}>Upload Document</Button>
      </Upload>
    )

    return (
      <React.Fragment>
        <Row>
          <Col span={3} style={{ paddingLeft: 16}}>
            <Avatar size={100} icon={<UserOutlined/>} src={data.profilePicUrl ? <Image src={data.profilePicUrl} /> : null }   />
          </Col>

          <Col span={12} style={{ paddingRight: 16, paddingLeft: 40}}>
            <Space direction='vertical' size='small'>
              <Text>{data.fullName ? data.fullName : 'N/A'}</Text>
              <Text>{data.email ? data.email : 'N/A'}</Text>
              <Text>{`+${data.callingCode ? data.callingCode : 'N/A'}${data.mobilePhone ? data.mobilePhone : 'N/A'}`}</Text>
              <Text>Current location: {data.currentCityName ? data.currentCityName : 'N/A'}</Text>
            </Space>
          </Col>

          <Col span={9}>
            <Descriptions layout='vertical' size='small'>
              <Descriptions.Item label="about">
                {data.about ? data.about : 'N/A'}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>

        <Divider/>

        {/* Job Preference */}
        <Row gutter={16}>
          <Col span={24}>
            <Descriptions layout='vertical' size='small' column={3}>
              <Descriptions.Item label="Job Industries Preference">
              {dataJobIndustry.length > 0 ? dataJobIndustry.join(', ') : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Job Destinations Preference">
              {dataJobDestination.length > 0 ? dataJobDestination.join(', ') : 'N/A'}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions layout='vertical' size='small' column={3}>
              <Descriptions.Item label="Job Positions Preference">
                {dataJobPosition.length > 0 ? dataJobPosition.join(', ') : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Salary Expectation">
                {
                  data.salaryTo ?
                  <React.Fragment>
                    <Text>{data.currencyCode ? data.currencyCode : 'N/A'} {data.salaryFrom ? data.salaryFrom.toLocaleString() : 'N/A'} - {data.salaryTo ? data.salaryTo.toLocaleString() : 'N/A'}</Text>
                  </React.Fragment>
                  :
                  <Text>
                    {data.salaryFrom ? `${data.currencyCode ? data.currencyCode : 'N/A'} ${data.salaryFrom ? data.salaryFrom.toLocaleString() : 'N/A'}` : 'N/A'}
                  </Text>
                }
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>

        <Divider/>

        {/* Personal Information */}
        <Row gutter={16}>
          <Descriptions title="Personal Information" layout='vertical' size='small' column={3}>
            <Descriptions.Item label="Date of Birth">
              {data.birthdate ? moment(data.birthdate, 'DD/MM/YYYY').format('DD MMM YYYY') : 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Height (cm)">
              {data.height ? data.height : 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="City (ID Card)">
              {data.cityName ? data.cityName : 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Nationality">
              {data.nationality ? data.nationality : 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Weight (kg)">
              {data.weight ? data.weight : 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Sub-district (ID card)">
              {data.subDistrictName ? data.subDistrictName : 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Place of Birth">
              {data.placeOfBirth ? data.placeOfBirth : 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Religion">
              {
                data.religion ?
                <Text>
                  {data.religion === 'ISLAM' && 'Islam'}
                  {data.religion === 'CATHOLIC' && 'Catholic'}
                  {data.religion === 'PROTESTAN' && 'Protestan'}
                  {data.religion === 'BUDDHA' && 'Buddha'}
                  {data.religion === 'HINDU' && 'Hindu'}
                  {data.religion === 'OTHERS' && 'Others'}
                </Text>
                : 
                'N/A'
              }
            </Descriptions.Item>
            <Descriptions.Item label="Village (ID card)">
              {data.villageName ? data.villageName : 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Marital Status">
              {
                data.marital ?
                <Text>
                  {data.marital === 'SINGLE' && 'Single'}
                  {data.marital === 'MARRIED' && 'Married'}
                  {data.marital === 'PROTESTAN' && 'Protestan'}
                  {data.marital === 'DIVORCE' && 'Divorce'}
                  {data.marital === 'WIDOWED' && 'Widowed'}
                </Text>
                : 
                'N/A'
              }
            </Descriptions.Item>
            <Descriptions.Item label="ID Card Number (KTP)">
              {data.identificationId ? data.identificationId : 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Postal Code (ID card)">
              {data.zipCode ? data.zipCode : 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Gender">
              {
                data.gender ?
                <Text>
                  {data.gender === 'MALE' && 'Male'}
                  {data.gender === 'FEMALE' && 'Female'}
                </Text>
                : 
                'N/A'
              }
            </Descriptions.Item>
            <Descriptions.Item label="Country (ID Card)">
              {data.countryName ? data.countryName : 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Street Address">
              {data.address ? data.address : 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Blood Type">
              {data.bloodType ? data.bloodType : 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Province (ID Card)">
              {data.provinceName ? data.provinceName : 'N/A'}
            </Descriptions.Item>
          </Descriptions>
        </Row>

        <Divider/>

        {/* Work Experiences */}
        <Row gutter={16}>
          <Col span={24}>
            <Descriptions title="Work Experiences" />
            {
              data.listWorkExperience.length > 0 ?
              <React.Fragment>
                {
                  data.listWorkExperience.map((item, i) => (
                    <React.Fragment key={i}>
                      <div style={{ marginBottom: 11 }}>
                        <Text style={{ color: '#32ab6d', fontSize: 14 }}>
                          {moment(item.startDate, 'YYYY-MM').format('MMMM YYYY')} - {item.endDate ? moment(item.endDate, 'YYYY-MM').format('MMMM YYYY') : 'Present'}
                        </Text>
                      </div>
                      <Descriptions layout="vertical" size="small" column={3}>
                        <Descriptions.Item label="Title">
                          {item.title}
                        </Descriptions.Item>
                        <Descriptions.Item label="Employment Type">
                          {
                            item.employmentType ?
                            <Text>
                              {item.employmentType === "FULL_TIME" && 'Full Time'}
                              {item.employmentType === "PART_TIME" && 'Part Time'}
                              {item.employmentType === "FREELANCE" && 'Freelance'}
                              {item.employmentType === "INTERNSHIP" && 'Internship'}
                            </Text>
                            :
                            'N/A'
                          }
                        </Descriptions.Item>
                        <Descriptions.Item label="Company">
                          {item.company ? item.company : 'N/A'}
                        </Descriptions.Item>
                      </Descriptions>
                      <Descriptions layout="vertical" size="small" column={3}>
                        <Descriptions.Item label="Location">
                          {item.provinceName ? item.provinceName : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Job Description">
                          {item.jobDescription ? item.jobDescription : 'N/A'}
                        </Descriptions.Item>
                      </Descriptions>
                      <Divider />
                    </React.Fragment>
                  ))
                }
              </React.Fragment>
              :
              <React.Fragment>
                <Text>N/A</Text>
                <Divider />
              </React.Fragment>
            }
          </Col>
        </Row>

        {/* Education */}
        <Row gutter={16}>
          <Col span={24}>
            <Descriptions title="Educations" />
            {
              data.listEducation.length > 0 ?
              <React.Fragment>
                {
                  data.listEducation.map((item, i) => (
                    <React.Fragment key={i}>
                      <div style={{ marginBottom: 11 }}>
                        <Text style={{ color: '#32ab6d', fontSize: 14 }}>
                          {item.startYear} - {item.endYear ? item.endYear : 'Present'}
                        </Text>
                      </div>
                      <Descriptions layout="vertical" size="small" column={3}>
                        <Descriptions.Item label="School / University">
                          {item.name ? item.name : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Field of Study">
                          {item.fieldOfStudy ? item.fieldOfStudy : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Education Level">
                          {item.educationLevelName ? item.educationLevelName : 'N/A'}
                        </Descriptions.Item>
                      </Descriptions>
                      <Descriptions layout="vertical" size="small" column={3}>
                        <Descriptions.Item label="Score/Grade/GPA">
                          {item.gpa ? item.gpa : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Activity and Organization Experiences">
                          {item.experience ? item.experience : 'N/A'}
                        </Descriptions.Item>
                      </Descriptions>
                      <Divider />
                    </React.Fragment>
                  ))
                }
              </React.Fragment>
              :
              <React.Fragment>
                <Text>N/A</Text>
                <Divider />
              </React.Fragment>
            }
          </Col>
        </Row>

        {/* Training and Certifications */}
        <Row gutter={16}>
          <Col span={24}>
            <Descriptions title="Training and Certifications" />
            {
              data.listTraining.length > 0 ?
              <React.Fragment>
                {
                  data.listTraining.map((item, i) => (
                    <React.Fragment key={i}>
                      <div style={{ marginBottom: 11 }}>
                        <Text style={{ color: '#32ab6d', fontSize: 14 }}>
                          {`${item.issueDate ? moment(item.issueDate, 'YYYY-MM').format('MMMM YYYY') : 'N/A'}, ${item.expDate ? moment(item.expDate, 'YYYY-MM').format('MMMM YYYY') : 'certificate will not expire'}`}
                        </Text>
                      </div>
                      <Descriptions layout="vertical" size="small" column={3}>
                        <Descriptions.Item label="Name">
                          {item.name}
                        </Descriptions.Item>
                        <Descriptions.Item label="Credential ID">
                          {item.credentialId ? item.credentialId : 'N/A'}
                        </Descriptions.Item>
                      </Descriptions>
                      <Descriptions layout="vertical" size="small" column={3}>
                        <Descriptions.Item label="Issuing Organization">
                          {item.organization ? item.organization : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Credential URL">
                          {
                            item.credentialUrl ? 
                            <a href={`${item.credentialUrl}`} target="_blank" rel='noreferrer'>
                              {item.credentialUrl}
                            </a> 
                            : 
                            'N/A'
                          }
                        </Descriptions.Item>
                      </Descriptions>
                      <Divider />
                    </React.Fragment>
                  ))
                }
              </React.Fragment>
              :
              <React.Fragment>
                <Text>N/A</Text>
                <Divider />
              </React.Fragment>
            }
          </Col>
        </Row>

        {/* Skills */}
        <Row gutter={16}>
          <Col span={24}>
            <Descriptions title="Skills" />
            {
              data.listSkill.length > 0 ?
              <React.Fragment>
                <Descriptions layout="vertical" size="small" column={3}>
                  {
                    data.listSkill.map((item, i) => (
                    <Descriptions.Item key={i}>
                      <Space direction="vertical" size="small">
                        <Text>{item.name}</Text>
                        {
                          item.skillLevel ?
                          <Text style={{ color: '#32ab6d', fontSize: 14 }}>
                            {item.skillLevel === 'BASIC' && 'Basic'}
                            {item.skillLevel === 'INTERMEDIATE' && 'Intermediate'}
                            {item.skillLevel === 'ADVANCE' && 'Advance'}
                            {item.skillLevel === 'EXPERT' && 'Expert'}
                          </Text>
                          :
                          'N/A'
                        }
                      </Space>
                    </Descriptions.Item>
                    ))
                  }
                </Descriptions>
                <Divider />
              </React.Fragment>
              :
              <React.Fragment>
                <Text>N/A</Text>
                <Divider />
              </React.Fragment>
            }
          </Col>
        </Row>

        {/* Job Preference */}
        <Row gutter={16}>
          <Col span={24}>
            <Descriptions title="Portfolios" layout="vertical" size="small" column={3}>
            {
              data.listPortfolio.length > 0 ?
              <React.Fragment>
                {
                  data.listPortfolio.map((item, i) => (
                  <Descriptions.Item key={i}>
                    <a href={`${item.url}`} target="_blank" rel='noreferrer'>
                      {item.url}
                    </a> 
                  </Descriptions.Item>
                  ))
                }
              </React.Fragment>
              :
              <React.Fragment>
                <Text>N/A</Text>
                <Divider />
              </React.Fragment>
            }
            </Descriptions>
          </Col>
        </Row>

        <Divider/>

        {/* Achievements */}
        <Row gutter={16}>
          <Col span={24}>
            <Descriptions title="Achievements" />
            {
              data.listAchievement.length > 0 ?
              <React.Fragment>
                {
                  data.listAchievement.map((item, i) => (
                    <React.Fragment key={i}>
                      <div style={{ marginBottom: 11 }}>
                        <Text style={{ color: '#32ab6d', fontSize: 14 }}>
                          {item.year ? item.year : 'N/A'}
                        </Text>
                      </div>
                      <Descriptions layout="vertical" size="small" column={3}>
                        <Descriptions.Item label="Name">
                          {item.name}
                        </Descriptions.Item>
                        <Descriptions.Item label="Issuing Organization">
                          {item.organization}
                        </Descriptions.Item>
                      </Descriptions>
                      <Divider />
                    </React.Fragment>
                  ))
                }
              </React.Fragment>
              :
              <React.Fragment>
                <Text>N/A</Text>
                <Divider />
              </React.Fragment>
            }
          </Col>
        </Row>

        {/* Documents */}

        <Row gutter={16}>
          <Col span={24}>
            <Descriptions title="Documents" />
            {
              data.listDocument.length > 0 ?
              <React.Fragment>
                {
                  data.listDocument.map((item, i) => (
                    <React.Fragment key={i}>
                      <div style={{ marginBottom: 11, display: 'flex', justifyContent: 'space-between' }}>
                        <Space direction='vertical' size={24}>
                          <Row>
                            <Col span={12}>
                              <Text style={{ color: '#32ab6d', fontSize: 14 }}>
                                {item.documentName}
                              </Text>
                            </Col>
                            <Col span={12}>
                              <Space size={16} style={{ float: 'right' }}>
                                <a href={item.documentUrl} target="_blank" rel='noreferrer'>
                                  <DownloadOutlined style={{ fontSize: 20, cursor: 'pointer', color: '#32ab6d' }} /> Download Document
                                </a>
                                <Text onClick={() => this.showModalDocument('edit', item)} style={{ cursor: 'pointer', color: '#32ab6d' }}>
                                  <EditOutlined style={{ fontSize: 20, cursor: 'pointer', color: '#32ab6d' }} /> Edit
                                </Text>
                              </Space>
                            </Col>
                          </Row>
                          <Descriptions layout="vertical" size="small" column={4}>
                            <Descriptions.Item label="Document Number">
                              {item.documentNumber ? item.documentNumber : 'N/A'}
                            </Descriptions.Item>
                            <Descriptions.Item label="Issue Date">
                              {
                                item.issueDate ?
                                  moment(item.issueDate, 'MM/YYYY').format('MMM YYYY')
                                : 'N/A'
                              }
                            </Descriptions.Item>
                            <Descriptions.Item label="Expiration Date">
                              {
                                item.expirationDate ?
                                  moment(item.expirationDate, 'MM/YYYY').format('MMM YYYY')
                                : item.current ?
                                  'No Expiration Date'
                                : 'N/A'
                              }
                            </Descriptions.Item>
                            <Descriptions.Item label="Remarks">
                              {item.remark ? item.remark : 'N/A'}
                            </Descriptions.Item>
                          </Descriptions>
                        </Space>
                      </div>
                      <Divider />
                    </React.Fragment>
                  ))
                }
              </React.Fragment>
              :
              <React.Fragment>
                <Text>N/A</Text>
                <Divider />
              </React.Fragment>
            }
          </Col>
          <Col span={24}>
            <Button type="dashed" icon={<FileAddOutlined />} block onClick={() => this.showModalDocument('add')}>
              Upload New Document
            </Button>
          </Col>
        </Row>

        <Modal
          centered
          width={1000}
          destroyOnClose
          visible={visibleDocument}
          onCancel={() => this.onCancel('add')}
          title={<Text strong>Add Document</Text>}
          footer={[
            <React.Fragment key="addDocument">
              <Button key="back" className='btn-save-primary' onClick={() => this.onCancel('add')}>Cancel</Button>
              <Button key="submit" className='btn-save-primary' type='primary' onClick={this.onSubmitAdd}>Save</Button>
            </React.Fragment>
          ]}
        >
          <FormDocument
            {...this.props}
            {...this.state}
            formRef={this.formRef}
            onSubmitAdd={this.onSubmitAdd}
            handleChecked={this.handleChecked}
            onChangeEndDate={this.onChangeEndDate}
            onChangeStartDate={this.onChangeStartDate}
            handleUpload={this.handleUpload}
            uploadDocument={uploadDocumentAdd}
           />
        </Modal>
        <Modal
          centered
          width={1000}
          destroyOnClose
          visible={visibleEdit}
          onCancel={() => this.onCancel('edit')}
          title={<Text strong>Edit Document</Text>}
          footer={[
            <React.Fragment key="editDocument">
              <Button key="delete" onClick={this.handleDelete} loading={deleteLoading} type="link" danger style={{ float: 'left' }}>Delete</Button>
              <Button key="back" className="btn-save-primary" onClick={() => this.onCancel('edit')}>Cancel</Button>
              <Button key="submit" className="btn-save-primary" type="primary" onClick={this.onSubmitEdit}>Update</Button>
            </React.Fragment>
          ]}
        >
          <EditDocument 
            {...this.props} 
            {...this.state}
            formRef={this.formRef}
            onSubmitEdit={this.onSubmitEdit} 
            handleChecked={this.handleChecked}
            onChangeEndDate={this.onChangeEndDate}
            onChangeStartDate={this.onChangeStartDate}
            handleUpload={this.handleUpload}
            uploadDocument={uploadDocumentEdit}
          />
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetData } = this.props
    unmountGetData()
  }
}

const mapStateToProps = (state) => ({
  getDocumentTypes        : state.documentTypes.master
})

const mapDispatchToProps = {
  actionAdd               : addDocument,
  actionGetData           : listCounselingProcess,
  actionUpdate            : updateDocument,
  actionDelete            : deleteDocument,
  actionGetDocumentTypes  : masterDocumentTypes,
  unmountGetData          : unmountListCounselingProcess,
  unmountGetDocumentTypes : unmountMasterDocumentType
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)