import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { DatabaseOutlined } from '@ant-design/icons';
// import { createSkill } from '../../../../../redux/actions/master/skills/skillsAction';
import { createLanguageSkills } from '../../../../../redux/actions/master/languageSkills/languageSkillsAction';
import { Col, Form, Row, Input, Button, Select, PageHeader, message, Card, Breadcrumb } from 'antd';
const { Option } = Select;


class CreateMasterLanguageSkill extends Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false
    }
  }

  onFinish = (values) => {
    const { actionCreate, history } = this.props;
    this.setState({ submitLoading: true })
    values.enabled = true
    actionCreate(values, () => {
      this.setState({ submitLoading: false }, () => {
        message.success('Data created successfully')
        history.push('/master/language-skills')
      })
    }, (err) => {
      message.error(err.message)
      this.setState({ submitLoading: false })
    })
  }

  handleChangeCode = (_,val) => {
    this.formRef.current.setFieldsValue({
      name: val.levelName
    })
  }

  render() {
    const { submitLoading } = this.state;
    return (
      <React.Fragment>
        <Form ref={this.formRef} onFinish={this.onFinish} layout="vertical">
          <Row style={{ position: 'sticky', zIndex: 1, width: '100%', top: 62, right: 0, marginTop: -20, marginBottom: 8, paddingBottom: 12, paddingTop: 16, backgroundColor: '#f2f2f2' }}>
            {/* Breadcrumb */}
            <Col span={24}>
              <Breadcrumb>
                <Breadcrumb.Item><DatabaseOutlined /> Master Data</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link className="link" to="/master/language-skills">
                    Master Data List Language Skills
                  </Link> 
                </Breadcrumb.Item>
                <Breadcrumb.Item>Language Skills</Breadcrumb.Item>
                <Breadcrumb.Item>Create</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            { /* Page Header */}
            <Col span={24}>
              <PageHeader 
                className="site-page-header" 
                title="Create a Language Skill"
                extra={[
                  <Button key="2" className="btn-save-primary" type="default" onClick={() => window.history.back()}>Cancel</Button>,
                  <Button key="1" className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading}>Create</Button>
                ]}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            { /* Form */}
            <Col span={24}>
              <Card>
                <Row gutter={16}>
                <Col span={12}>
                    <Form.Item 
                      label="Scope" 
                      name="scope" 
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                        <Select placeholder="Select a scope">
                          <Option key="1" value="UNDERSTANDING">Understanding</Option>
                          <Option key="2" value="SPEAKING">Speaking</Option>
                          <Option key="3" value="WRITING">Writing</Option>
                        </Select>
                    </Form.Item>
                  </Col>
                  
                  <Col span={12}>
                    <Form.Item 
                      label="Section" 
                      name="section" 
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                       <Select placeholder="Select a section">
                        <Option key="1" value="LISTENING">Listening</Option>
                        <Option key="2" value="READING">Reading</Option>
                        <Option key="3" value="SPOKEN_INTERACTION">Spoken Interaction</Option>
                        <Option key="4" value="SPOKEN_PRODUCTION">Spoken Production</Option>
                        <Option key="5" value="WRITING">Writing</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Code" 
                      name="code" 
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select onChange={this.handleChangeCode} placeholder="Select a code">
                        <Option key="1" value="A1" levelName='Basic User'>A1</Option>
                        <Option key="2" value="A2" levelName='Basic User'>A2</Option>
                        <Option key="3" value="B1" levelName='Independent User'>B1</Option>
                        <Option key="4" value="B2" levelName='Independent User'>B2</Option>
                        <Option key="5" value="C1" levelName='Proficient User'>C1</Option>
                        <Option key="6" value="C2" levelName='Proficient User'>C2</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Level Name" 
                      name="name"
                    >
                      <Input readOnly/>
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item 
                      label="Description in English" 
                      name="descriptionEng" 
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 10, message: 'At least 10 characters'},
                        { max: 1000, message: '1,000 characters only' },
                      ]}
                    >
                      <Input.TextArea rows={3} />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item 
                      label="Description in Bahasa" 
                      name="descriptionIdn" 
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 10, message: 'At least 10 characters'},
                        { max: 1000, message: '1,000 characters only' },
                      ]}
                    >
                      <Input.TextArea rows={3} />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {
  actionCreate: createLanguageSkills,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateMasterLanguageSkill)
