const LOAD_EDIT_COURSE           = 'LOAD_EDIT_COURSE'
const LOAD_EDIT_COURSE_SUCCESS   = 'LOAD_EDIT_COURSE_SUCCESS'
const LOAD_EDIT_COURSE_FAILED    = 'LOAD_EDIT_COURSE_FAILED'
const UNMOUNT_EDIT_COURSE        = 'UNMOUNT_EDIT_COURSE'

const initialState = {
  loading: true,
  data: null,
  message: null,
}

const courseEditReducer = (state = initialState, action) => {
  switch(action.type){
    case LOAD_EDIT_COURSE:
      return {
        ...state,
        loading: true,
        data: null,
      }
    case LOAD_EDIT_COURSE_SUCCESS:
      return { 
        ...state, 
        loading: false,
        data: action.payload.data,
        message: 'SUCCESS',
      }
    case LOAD_EDIT_COURSE_FAILED:
      return { 
        ...state, 
        loading: true,
        data: null,
        message: 'FAILED',
      }
    case UNMOUNT_EDIT_COURSE:
      return { 
        ...state, 
        loading: true,
        data: null,
      }
    default:
      return state
  }
}

export default courseEditReducer;