import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Typography, Tooltip, Tag } from 'antd';
import { ArrowRightOutlined, FormOutlined } from '@ant-design/icons';
const { Text } = Typography;

export const columns = (sortedInfo, onClickEdit, handleRoute, onClickFeedback) => {
  return [
    {
      title: 'Number',
      key: 'number',
      fixed: 'left',
      width: 110,
      render: (record) => (
        <Link className="link" to={`/candidate-pathway/detail/${record.id}`}>
          {record.candidatePathwayNumber}
        </Link>
      )
    },
    {
      title: 'Submit Date',
      key: 'createdDate',
      width: 100,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {moment(record.createdDate, 'DDMMYYYY').format('DD MMM YYYY')}
        </Text>
      )
    },
    {
      title: 'Name',
      key: 'name',
      width: 110,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.name ? record.name : '-'}
        </Text>
      )
    },
    {
      title: 'Placement Type',
      key: 'placementType',
      width: 110,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.placementType === 'INTERNATIONAL' ? 'International' : record.placementType === 'DOMESTIC' ? 'Domestic' : 'Both'}
        </Text>
      )
    },
    {
      title: 'Interested Industry',
      key: 'jobIndustry',
      width: 130,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.listPathwayIndustry.length > 0 ? record.listPathwayIndustry.map(item => item.jobIndustryName).join(', ') : '-'}
        </Text>
      )
    },
    {
      title: 'Feedback',
      key: 'feedback',
      width: 100,
      render: (record) => (
        <React.Fragment>
          {
            record.feedback ?
            <Link to="#" onClick={() => onClickFeedback(record.feedback)}>
              View
            </Link>
            :
            <Text>
              {record.feedback ? record.feedback : 'N/A'}
            </Text>
          }
        </React.Fragment>
      )
    },
    {
      title: 'Status',
      key: 'status',
      width: 60,
      render: (record) => (
        <React.Fragment>
          {
            record.status === 'WAITING' ? 
            <Tag color="blue">Waiting</Tag>
            :
            <Tag color="green">Submitted</Tag>
          }
        </React.Fragment>
      )
    },
    {
      title: '',
      key: 'action',
      width: 30,
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <React.Fragment>
        {
          record.status === 'WAITING' ?
            <Tooltip title="Assess" arrowPointAtCenter>
              <ArrowRightOutlined style={{ fontSize: 20 }} onClick={() => onClickEdit(record.id)}/>
            </Tooltip>
          :
            <Tooltip title="View Details" arrowPointAtCenter>
              <FormOutlined style={{ fontSize: 20 }} onClick={() => handleRoute(record)}/>
            </Tooltip>
        }
        </React.Fragment>
      )
    },
  ]
}