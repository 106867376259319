import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Menu } from 'antd';
import { FlagOutlined } from '@ant-design/icons';

export default function index(props) {
  return (
    <Card >
      <Menu defaultSelectedKeys={[`${props.rowKey}`]}>
        <Menu.Item key="minatBakat" icon={<FlagOutlined />}>
          <Link to="/candidate-pathway">Minat Bakat</Link>
        </Menu.Item>
        <Menu.Item key="englishAssessment" icon={<FlagOutlined />}>
          <Link to="/candidate-pathway/english-assessment">English Assessment</Link>
        </Menu.Item>
        <Menu.Item key="miniEnglish" icon={<FlagOutlined />}>
          <Link to="/candidate-pathway/mini-assessment">Mini English</Link>
        </Menu.Item>
      </Menu>
    </Card>
  )
}

