import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading } from '../../../../../components';
import { createAboutCountries ,masterAboutCountries, unmountMasterAboutCountries} from '../../../../../redux/actions/master/aboutCountry/aboutCountryAction';
import { DatabaseOutlined, UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { Col, Form, Row, Input, Button, Space, Select, PageHeader, message, Card, Breadcrumb, Upload, Typography, Image } from 'antd';

const { Text } = Typography;

class CreateMasterAboutCountry extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      avatar: null,
      editAvatar: false,  
      onPreviewAvatar: null,
    }
  }

  componentDidMount() {
    const { actionGetAboutCountry } = this.props;
    actionGetAboutCountry()
  }

  handleUpload(){
    return {
      showUploadList: false,
      withCredentials: true,
      accept:"image/*",
      beforeUpload: imageFile => {
        const validateSize = imageFile.size >= 500000;
        if (validateSize) {
          message.error('Max file size is 500 KB!');
          return false
        }
        var reader = new FileReader();
        reader.readAsDataURL(imageFile);
        reader.onload = (e) => {
          this.setState({ 
            avatar: imageFile, 
            editAvatar: true,
            onPreviewAvatar: e.target.result, 
          })
        }
        return false;
      },
    }
  }

  handleDeleteFile = () => {
    this.setState({
      avatar: null,
      editAvatar: false,
      onPreviewAvatar: null
    })
  }
  
  onFinish = (values) => {
    const { actionCreate, history } = this.props;
    if(this.state.editAvatar){
      values.imageFile = this.state.avatar
    }
    this.setState({ submitLoading: true })
    return actionCreate(values, () => {
      this.setState({ submitLoading: false }, () => {
        message.success('Data created successfully')
        history.push('/master/about-country')
      })
    }, (err) => {
      this.setState({ submitLoading: false }, () => message.error(err))
    })
  }
  
  render() {
    const { submitLoading, editAvatar, onPreviewAvatar } = this.state;
    const { getCountry: { loading, data } } = this.props;

    if(loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Form onFinish={this.onFinish} ref={this.formRef} layout="vertical">
          <Row style={{ position: 'sticky', zIndex: 1, width: '100%', top: 62, right: 0, marginTop: -20, marginBottom: 8, paddingBottom: 12, paddingTop: 16, backgroundColor: '#f2f2f2' }}>
            {/* Breadcrumb */}
            <Col span={24}>
              <Breadcrumb>
                <Breadcrumb.Item><DatabaseOutlined /> Master Data</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link className="link" to="/master/about-country">
                    Master Data List About Country
                  </Link> 
                </Breadcrumb.Item>
                <Breadcrumb.Item>About Country</Breadcrumb.Item>
                <Breadcrumb.Item>Create</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            { /* Page Header */}
            <Col span={24}>
              <PageHeader 
                className="site-page-header" 
                title="Create About Country"
                extra={[
                  <Button key="2" className="btn-save-primary" type="default" onClick={() => window.history.back()}>Cancel</Button>,
                  <Button key="1" className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading}>Create</Button>
                ]}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            { /* Form */}
            <Col span={24}>
              <Card>
                <Row gutter={16}>
                <Col span={24}>
                  <Form.Item 
                    label="Country" 
                    name='id' 
                    rules={[
                      { required: true, message: 'This is a required field' },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select a country"
                      optionFilterProp="children"
                      onChange={this.handleChangeCountry}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {
                        data?.map(item => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                </Col>

                  <Col span={24} >
                    <Space direction='vertical'>
                        <Form.Item label="Image Country">
                          {
                            editAvatar ?
                            <Form.Item>
                              <Image width={100} src={editAvatar ? onPreviewAvatar : null} />
                            </Form.Item>
                            :
                            null
                          }
                          <Space>
                            <Upload {...this.handleUpload()}>
                              <Button icon={<UploadOutlined />}>{editAvatar ? 'Change Image' : 'Upload a Image'}</Button>
                            </Upload>
                            {
                              editAvatar ?
                                <Button icon={<DeleteOutlined />} type="danger" ghost onClick={this.handleDeleteFile}>Delete</Button>
                              : null
                            }
                          </Space>
                        </Form.Item>
                        <Text>The recommended resolution of thumbnail image is 1260 x 400px</Text>
                    </Space>
                  </Col>

                  <Col span={24}>
                    <Form.Item 
                      label="About"
                      name="information"
                      validateFirst
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 10, message: '10 characters only' },
                        { max: 1000, message: '1000 characters only' },
                      ]}
                    >
                      <Input.TextArea rows={3} />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetCountry } = this.props;
    unmountGetCountry()
  }
}

const mapStateToProps = (state) => ({
  getCountry        : state.aboutCountries.master
})

const mapDispatchToProps = {
  actionCreate  : createAboutCountries,
  actionGetAboutCountry  : masterAboutCountries,
  unmountGetCountry : unmountMasterAboutCountries
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateMasterAboutCountry)
