import React from 'react'
import { Row, Col, Typography, Descriptions } from 'antd'

const { Text } = Typography;

export default function JobPreference(props) {
  const { data } = props
  const dataJobIndustry = data?.listJobIndustry ? data?.listJobIndustry?.map(item => item.jobIndustryName) : []
  const dataJobDestination = data?.listJobDestination ? data?.listJobDestination?.map(item => item.jobDestinationName) : []
  const dataJobPosition = data?.listJobPosition ? data?.listJobPosition?.map(item => item.jobPositionName) : []
  return (
    <Row gutter={16}>
      <Col span={24}>
        <Descriptions layout="vertical" size="small" column={3}>
          <Descriptions.Item label="Job Industries Preference">
            {dataJobIndustry.length > 0 ? dataJobIndustry.join(', ') : 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label="Job Destinations Preference">
            {dataJobDestination.length > 0 ? dataJobDestination.join(', ') : 'N/A'}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions layout="vertical" size="small" column={3}>
          <Descriptions.Item label="Job Positions Preference">
            {dataJobPosition.length > 0 ? dataJobPosition.join(', ') : 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label="Salary Expectation">
            {
              data.salaryTo ?
                <React.Fragment>
                  <Text>{data.currencyCode ? data.currencyCode : 'N/A'} {data.salaryFrom ? data.salaryFrom.toLocaleString() : 'N/A'} - {data.salaryTo ? data.salaryTo.toLocaleString() : 'N/A'}</Text>
                </React.Fragment>
              :
                <Text>
                  {data.salaryFrom ? `${data.currencyCode ? data.currencyCode : 'N/A'} ${data.salaryFrom ? data.salaryFrom.toLocaleString() : 'N/A'}` : 'N/A'}
                </Text>
            }
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  )
}
