import { API } from '../../../config';
import { errorHandler } from '../auth/errorAction';

export const unmountApplicantSummary = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_APPLICANT_SUMMARY'})
}

export const unmountPipelineHiring = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_PIPELINE_HIRING'})
}

export const unmountJobOverview = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_JOB_OVERVIEW'})
}

export const unmountJobOpeningStatus = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_JOB_OPENING_STATUS'})
}

export const applicantSummary = (url, type, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DATA_APPLICANT_SUMMARY' })
  const params = {
    'type': type
  }
  API.GET(`/${url}/applicant_summary`, params).then((response) => {
    dispatch({ 
      type: 'LOAD_DATA_APPLICANT_SUMMARY_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DATA_APPLICANT_SUMMARY_FAILED' }), 
      ))
    )
  })
}

export const pipelineHiring = (url, sort, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DATA_PIPELINE_HIRING' })
  const params = {
    'sort': sort
  }
  API.GET(`/${url}/hiring_pipeline`, params).then((response) => {
    dispatch({ 
      type: 'LOAD_DATA_PIPELINE_HIRING_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DATA_PIPELINE_HIRING_FAILED' }), 
      ))
    )
  })
}

export const jobOverview = (url, sort, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DATA_JOB_OVERVIEW' })
  const params = {
    'sort': sort
  }
  API.GET(`/${url}/job_overview`, params).then((response) => {
    dispatch({ 
      type: 'LOAD_DATA_JOB_OVERVIEW_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DATA_JOB_OVERVIEW_FAILED' }), 
      ))
    )
  })
}

export const jobOpeningStatus = (url, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DATA_JOB_OPENING_STATUS' })
  API.GET(`/${url}/job_opening`).then((response) => {
    dispatch({ 
      type: 'LOAD_DATA_JOB_OPENING_STATUS_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DATA_JOB_OPENING_STATUS_FAILED' }), 
      ))
    )
  })
}