import React, { Component } from 'react';
import FormNative from './FormNative';
import FormOther from './FormOther';
import svg from '../../../../assets/img/profile.svg';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { addCandidateLanguageSkills, deleteCandidateLanguageSkills, updateCandidateLanguageSkills, detailCandidate, unmountDetailCandidate, enableCandidate } from '../../../../redux/actions/candidate/candidateAction';
import { masterLanguageSkills, unmountMasterLanguageSkills } from '../../../../redux/actions/master/languageSkills/languageSkillsAction';
import { Skeleton, Row, Col, Breadcrumb, PageHeader, Avatar, Card, Button, Space, Typography, Divider, Tooltip, Modal, Form, Tag, message } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Menu from '../Menu';
const { Text } = Typography;
const { confirm } = Modal;
const Icon = ({ type, ...rest }) => {
  const icons = require(`@ant-design/icons`);
  const Component = icons[type];
  return <Component {...rest} />
};
class CandidateDetailLanguageSkills extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)

    this.state = {
      dataCandidateLanguageSkills: null,
      submitLoading: false,
      loading: false,
      visibleAdd: false,
      visibleEdit: false,
      visible: false,
      visibleNative: false,
      visibleOther: false
    }
  }

  componentDidMount() {
    const { actionGetDetail, actionGetLanguage, match: { params } } = this.props;
    actionGetDetail(params.id)
    actionGetLanguage()
  }

  showModalNative = (item) => {
    this.setState({
      visibleNative: true,
      dataCandidateLanguageSkills: item ? item : null,
      isEdit: true
    })
  }

  handleCancelNative = () => {
    this.setState({ visibleNative: false, dataCandidateLanguageSkills: null })
  }

  showModalOther = (item, i) => {
    this.setState({
      visibleOther: true,
      dataCandidateLanguageSkills: item ? item : null,
      isEdit: true,
      indexLanguage: i ? i : null
    })
  }

  handleCancelOther = () => {
    this.setState({ visibleOther: false, dataCandidateLanguageSkills: null })
  }

  handleDelete = (id) => {
    const { actionDelete, actionGetDetail, match: { params } } = this.props;
    confirm({
      title: 'Remove Data',
      okText: 'Yes',
      cancelText: 'No',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to remove this data?',
      onOk() {
        return actionDelete(id, () => {
          message.success('Data deleted successfully')
          return actionGetDetail(params.id)
        }, (err) => message.error(err))
      },
    });
  }

  onSubmitNative = () => {
    const { actionAdd, actionUpdate, actionGetDetail, match: { params } } = this.props;
    const { dataCandidateLanguageSkills } = this.state
    this.setState({ submitLoading: true })
    this.formRef.current.validateFields().then(values => {
      values.candidateId = Number(params.id)
      values.isNative = true
      if(dataCandidateLanguageSkills){
        values.id = dataCandidateLanguageSkills.id
        return actionUpdate(values, () => {
          return this.setState({ submitLoading: false }, () => {
            return this.setState({ visibleNative: false }, () => {
              message.success('Data update successfully')
              return actionGetDetail(params.id)
            })
          })
        }, (err) => {
          return this.setState({ submitLoading: false }, () => message.error(err))
        })
      }else{
        return actionAdd(values, () => {
          return this.setState({ submitLoading: false }, () => {
            return this.setState({ visibleNative: false }, () => {
              message.success('Data created successfully')
              return actionGetDetail(params.id)
            })
          })
        }, (err) => {
          return this.setState({ submitLoading: false }, () => message.error(err))
        })
      }
    })
  }

  onSubmitOther = () => {
    const { actionAdd, actionUpdate, actionGetDetail, match: { params } } = this.props;
    const { dataCandidateLanguageSkills } = this.state
    this.setState({ submitLoading: true })
    this.formRef.current.validateFields().then(values => {
      values.candidateId = Number(params.id)
      values.isNative = false
      if(dataCandidateLanguageSkills){
        values.id = dataCandidateLanguageSkills.id
        return actionUpdate(values, () => {
          return this.setState({ submitLoading: false }, () => {
            return this.setState({ visibleOther: false }, () => {
              message.success('Data updated successfully')
              return actionGetDetail(params.id)
            })
          })
        }, (err) => {
          return this.setState({ submitLoading: false }, () => message.error(err))
        })
      }else{
        return actionAdd(values, () => {
          return this.setState({ submitLoading: false }, () => {
            return this.setState({ visibleOther: false }, () => {
              message.success('Data created successfully')
              return actionGetDetail(params.id)
            })
          })
        }, (err) => {
          return this.setState({ submitLoading: false }, () => message.error(err))
        })
      }
    })
  }

  showConfirmActive = () => {
    const { actionEnable, actionGetDetail ,match: { params } } = this.props;
    confirm({
      title: 'Unable to edit profile due to candidate status is inactive. Do you want to change candidate status to active?',
      icon: <ExclamationCircleOutlined />,
      width: 700,
      onOk() {
        return actionEnable(params.id, () => {
          message.success('Successfully changed to active')
          return actionGetDetail(params.id)
        }, (err) => message.error(err))
      },
      okText: 'Change to Active',
      onCancel() {},
    });
  };

  render() {
    const { visibleNative, visibleOther, dataCandidateLanguageSkills } = this.state;
    const { getDetailCandidate: { loading, data } } = this.props;

    if (loading) {
      return <Skeleton />
    }

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Icon type={this.props.icon} />
                {
                  this.props.url.split('/')[1] === 'all-candidate' ?
                    ' All Candidates'
                  : this.props.url.split('/')[1] === 'new-candidate' ?
                    ' New Candidates'
                  : this.props.url.split('/')[1] === 'counseling-process' ?
                    ' Counseling Process'
                  : this.props.url.split('/')[1] === 'assigned-candidate' ?
                    ' Assigned Candidates'
                  :
                    ' Candidates'
                }
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to={`/${this.props.url.split('/')[1]}`}>
                  {
                    this.props.url.split('/')[1] === 'all-candidate' ?
                      'All Candidates List'
                    : this.props.url.split('/')[1] === 'new-candidate' ?
                      'New Candidates List'
                    : this.props.url.split('/')[1] === 'counseling-process' ?
                      'Counseling Process List'
                    : this.props.url.split('/')[1] === 'assigned-candidate' ?
                      'Assigned Candidates List'
                    :
                      'Candidates List'
                  }
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Candidates Detail</Breadcrumb.Item>
              <Breadcrumb.Item>
                {data.fullName}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Candidate Detail' />
          </Col>
          {/* Menu */}
          <Col span={7}>
            <Card style={{ border: 'none' }}>
              <Row gutter={[16, 24]}>
                <Col span={24}>
                  <Space direction="horizontal">
                    <Avatar size={100} src={data.profilePicUrl ? data.profilePicUrl : svg} />
                    <Space direction="vertical" size="small">
                      <Text style={{ color: '#4d4d4d', fontSize: 18 }} strong>{data.fullName}</Text>
                      <Text type="secondary">{data.candidateNumber}</Text>
                      {
                        data.enabled ? 
                          <Tag color="success">Active</Tag>
                        :
                          <Tag color="error">Inactive</Tag>
                      }
                    </Space>
                  </Space>
                </Col>
                <Col span={24}>
                  <Menu rowKey="languageSkills" id={data.id} email={data.email} />
                </Col>
              </Row>
            </Card>
          </Col>
          {/* Content */}
          <Col span={17}>
            <Card
              style={{ border: 'none' }}
              title="Language Skills"
              headStyle={{ color: 'ant-descriptions-item-content', fontSize: 17 }}
            >
              {
                data.listLanguage.length > 0 ?
                  <Row gutter={8}>
                    <Col span={20}>
                      <Space direction="vertical">
                        <Text strong>Native Language</Text>
                        <Text style={{ color: '#32ab6d' }}>
                          {
                            data.listLanguage.find(item => item.isNative === true) ? data.listLanguage.find(item => item.isNative === true).name : 'N/A'
                          }
                        </Text>
                      </Space>
                    </Col>
                    <Col span={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button className="btn-edit-candidate" type="primary" icon={<EditOutlined />} onClick={data.enabled ? () => this.showModalNative(data.listLanguage.find(item => item.isNative === true)) : this.showConfirmActive}>Edit</Button>
                    </Col>
                    <Col span={24}>
                      <Divider className='mt-16 mb-16' />
                    </Col>
                    <Col span={24} style={{ marginBottom: 15 }}>
                      <Text strong>Others Language</Text>
                    </Col>
                    <Col span={24} style={{ marginBottom: 15 }}>
                      <Text style={{ color: '#7B7B7B'}}>Language</Text>
                    </Col>
                    {
                      data.listLanguage.map((item, i) =>
                        !item.isNative ?
                          <React.Fragment key={i}>
                            <Col span={20} style={{ marginBottom: 16 }}>
                              <Space className='width-100' direction='vertical' size={8}>
                                <Text>
                                  {
                                    item.name ?
                                      <Text style={{ color: '#32ab6d' }}>{item.name}</Text>
                                    :
                                      'N/A'
                                  }
                                </Text>
                              </Space>
                            </Col>
                            <Col span={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <Space>
                                <Tooltip title="Edit">
                                  <EditOutlined style={{ fontSize: 20, cursor: 'pointer', color: '#32ab6d' }} onClick={data.enabled ? () => this.showModalOther(item, i) : this.showConfirmActive} />
                                </Tooltip>
                                <Tooltip title="Remove">
                                  <DeleteOutlined style={{ fontSize: 20, cursor: 'pointer', color: '#d8675a' }} onClick={data.enabled ? () => this.handleDelete(item.id) : this.showConfirmActive} />
                                </Tooltip>
                              </Space>
                            </Col>
                            <Col span={24} style={{ marginBottom: 8 , color: '#7B7B7B'}}>
                              <Text style={{ color: '#7B7B7B'}}>Understanding</Text>
                            </Col>
                            <Col span={12} style={{ marginBottom: 8 , color: '#7B7B7B'}}>
                              <Space direction='vertical' size={0}>
                                <Text style={{ color: '#7B7B7B'}}>Listening</Text>
                                <Text className='ant-descriptions-item-content'>
                                  {
                                    item.listeningLevelId ?
                                      <Text className='ant-descriptions-item-content'>{item.listeningLevelCode} {item.listeningLevelName}</Text>
                                    :
                                      'N/A'
                                  }
                                </Text>
                              </Space>
                            </Col>
                            <Col span={12} style={{ marginBottom: 8 }}>
                              <Space direction='vertical' size={0}>
                                <Text style={{ color: '#7B7B7B'}}>Reading</Text>
                                <Text className='ant-descriptions-item-content'>
                                  {
                                    item.readingLevelId ?
                                      <Text className='ant-descriptions-item-content'> {item.readingLevelCode} {item.readingLevelName}</Text>
                                    :
                                      ' N/A'
                                  }
                                </Text>
                              </Space>
                            </Col>
                            <Col span={24} style={{ marginBottom: 8 }}>
                              <Text style={{ color: '#7B7B7B'}}>Speaking</Text>
                            </Col>
                            <Col span={12} style={{ marginBottom: 8 }}>
                              <Space direction='vertical' size={0}>
                                <Text style={{ color: '#7B7B7B'}}>Speaking Interaction</Text>
                                <Text className='ant-descriptions-item-content'>
                                  {
                                    item.spokenInterLevelId ?
                                      <Text className='ant-descriptions-item-content'> {item.spokenInterLevelCode} {item.spokenInterLevelName}</Text>
                                    :
                                      ' N/A'
                                  }
                                </Text>
                              </Space>
                            </Col>
                            <Col span={12} style={{ marginBottom: 8 }}>
                              <Space direction='vertical' size={0}>
                                <Text style={{ color: '#7B7B7B'}}>Spoken Introduction</Text>
                                <Text className='ant-descriptions-item-content'>
                                  {
                                    item.spokenIntroLevelId ?
                                      <Text className='ant-descriptions-item-content'>{item.spokenIntroLevelCode} {item.spokenIntroLevelName}</Text>
                                    :
                                      ' N/A'
                                  }
                                </Text>
                              </Space>
                            </Col>
                            <Col span={24}>
                              <Text style={{ color: '#7B7B7B'}}>Writing</Text>
                            </Col>
                            <Col span={24} style={{ marginBottom: 16 }}>
                              <Text className='ant-descriptions-item-content'>
                                {
                                  item.writingLevelId ?
                                    <Text className='ant-descriptions-item-content'>{item.writingLevelCode} {item.writingLevelName}</Text>
                                  :
                                    ' N/A'
                                }
                              </Text>
                            </Col>
                          </React.Fragment>
                        : null
                      )
                    }

                    <Col span={24} style={{ marginTop: 16 }}>
                      <Form.Item >
                        <Button type="dashed" block icon={<PlusOutlined />} onClick={data.enabled ? () => this.showModalOther(null) : this.showConfirmActive}>
                          Add Other Language
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                :
                  <Row gutter={[0,32]}>
                    <Col span={20}>
                      <Space direction='vertical'>
                        <Text strong>Native Language</Text>
                        <Text>N/A</Text>
                      </Space>
                    </Col>
                    <Col span={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button className="btn-edit-candidate" type="primary" icon={<PlusOutlined />} onClick={data.enabled ? () => this.showModalNative(null) : this.showConfirmActive}>Add</Button>
                    </Col>
                    <Col span={20}>
                      <Space direction='vertical'>
                        <Text strong>Other Language</Text>
                        <Text>N/A</Text>
                      </Space>
                    </Col>
                    <Col span={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button className="btn-edit-candidate" type="primary" icon={<PlusOutlined />} onClick={data.enabled ? () => this.showModalOther(null) : this.showConfirmActive}>Add</Button>
                    </Col>
                  </Row>
              }
            </Card>
          </Col>
        </Row>

        <Modal
          title={dataCandidateLanguageSkills ? "Edit Native Language" : "Add Native Language"}
          key={1}
          visible={visibleNative}
          onCancel={this.handleCancelNative}
          footer={false}
          bodyStyle={{ paddingBottom: 4 }}
          centered
          width={1000}
          destroyOnClose
        >
          <FormNative
            {...this.props} 
            {...this.state}
            dataCandidateLanguageSkills={dataCandidateLanguageSkills}
            formRef={this.formRef}
            handleCancelNative={this.handleCancelNative}
            onSubmitNative={this.onSubmitNative}
          />
        </Modal>

        <Modal
          title={dataCandidateLanguageSkills ? "Edit Other Language" : "Add Other Language"}
          key={2}
          visible={visibleOther}
          onCancel={this.handleCancelOther}
          footer={false}
          bodyStyle={{ paddingBottom: 4 }}
          centered
          width={1000}
          destroyOnClose
        >
          <FormOther
            {...this.props} 
            {...this.state}
            dataCandidateLanguageSkills={dataCandidateLanguageSkills}
            formRef={this.formRef}
            handleCancelOther={this.handleCancelOther}
            onSubmitOther={this.onSubmitOther}
          />
        </Modal>

      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail } = this.props;
    unmountGetDetail()
    unmountMasterLanguageSkills()
  }
}

const mapStateToProps = (state) => ({
  getLanguage: state.masterLanguageSkills.list,
  getDetailCandidate: state.candidate.detail,
})

const mapDispatchToProps = {
  actionGetLanguage: masterLanguageSkills,
  actionAdd: addCandidateLanguageSkills,
  actionUpdate: updateCandidateLanguageSkills,
  actionDelete: deleteCandidateLanguageSkills,
  actionGetDetail: detailCandidate,
  actionEnable: enableCandidate,
  unmountGetDetail: unmountDetailCandidate,
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateDetailLanguageSkills)
