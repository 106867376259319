const LOAD_DETAIL_ABOUT_COUNTRIES           = 'LOAD_DETAIL_ABOUT_COUNTRIES'
const LOAD_DETAIL_ABOUT_COUNTRIES_SUCCESS   = 'LOAD_DETAIL_ABOUT_COUNTRIES_SUCCESS'
const LOAD_DETAIL_ABOUT_COUNTRIES_FAILED    = 'LOAD_DETAIL_ABOUT_COUNTRIES_FAILED'
const UNMOUNT_DETAIL_ABOUT_COUNTRIES        = 'UNMOUNT_DETAIL_ABOUT_COUNTRIES'

const initialState = {
  loading: true,
  data: null,
  message: null,
}

const aboutCountriesDetailReducer = (state = initialState, action) => {
  switch(action.type){
    case LOAD_DETAIL_ABOUT_COUNTRIES:
      return {
        ...state,
        loading: true,
        data: null,
      }
    case LOAD_DETAIL_ABOUT_COUNTRIES_SUCCESS:
      return { 
        ...state, 
        loading: false,
        data: action.payload.data,
        message: 'SUCCESS',
      }
    case LOAD_DETAIL_ABOUT_COUNTRIES_FAILED:
      return { 
        ...state, 
        loading: true,
        data: null,
        message: 'FAILED',
      }
    case UNMOUNT_DETAIL_ABOUT_COUNTRIES:
      return { 
        ...state, 
        loading: true,
        data: null,
      }
    default:
      return state
  }
}

export default aboutCountriesDetailReducer;