import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Row, Col, Typography, Space, Descriptions, Divider, Upload, Button, Modal, message } from 'antd'
import { DownloadOutlined, EditOutlined, FileAddOutlined, UploadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { masterDocumentTypes, unmountMasterDocumentType } from '../../../../redux/actions/master/documentTypes/documentTypesAction'
import { addDocument, updateDocument, deleteDocument } from '../../../../redux/actions/candidate/candidateAction'
import AddDocument from './AddDocument'
import EditDocument from './EditDocument'

const { Text } = Typography
const { confirm } = Modal

export class Document extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      dataDocuments: null,
      submitLoading: false,
      visibleAdd: false,
      visibleEdit: false,
      startDate: null,
      endDate: null,
      current: false,
      isDate: false,
      documentFile: null,
      showFileEdit: false,
      deleteLoading: false,
      selectedOption: null,
      id: null
    }
  }

  componentDidMount() {
    const { actionGetDocumentTypes } = this.props;
    actionGetDocumentTypes()
  }

  showModal = (type, item) => {
    if(type === 'add'){
      return this.setState({ visibleAdd: true, documentFile: null, selectedOption: null, documentTypeId: null })
    }
    if(type === 'edit'){
      return this.setState({ 
        visibleEdit: true, 
        dataDocuments: item, 
        current: item.current,
        showFileEdit: true,
        selectedOption: item.documentTypeId,
        id: item.id
      })
    }
  }

  onCancel = (type) => {
    if(type === 'add'){
      return this.setState({ visibleAdd: false, documentFile: null })
    }
    if(type === 'edit'){
      return this.setState({ 
        visibleEdit: false,
        dataDocuments: null,
        current: false,
        showFileEdit: false
      })
    }
  }

  onChangeStartDate = (date, dateString) => {
    const { endDate } = this.state
    this.setState({ startDate: dateString })
    if(endDate <= dateString){
      this.setState({ isDate: true })
    }else{
      this.setState({ isDate: false })
    }
  }

  onChangeEndDate = (date, dateString) => {
    const { startDate } = this.state
    this.setState({ endDate: dateString })
    if(dateString <= startDate){
      this.setState({ isDate: true })
    }else{
      this.setState({ isDate: false })
    }
  }

  handleChecked = (e) => {
    this.setState({ current: e.target.checked })
    if(e.target.checked){
      this.setState({ endDate: null })
      this.formRef.current.setFieldsValue({
        endDate: null
      })
    }
  }

  handleUpload(){
    const { selectedOption } = this.state
    return {
      withCredentials: true,
      // accept: selectedOption === 1 || selectedOption === 4 || selectedOption === 8 ? ".pdf" : selectedOption === 29 ? ".jpg,.jpeg" : ".png,.jpg,.jpeg,.pdf",
      beforeUpload: file => {
        const validateSize = file.size >= (selectedOption === 1 || selectedOption === 4 || selectedOption === 8 || selectedOption === 29 ? 3000000 : 500000);
        if (validateSize) {
          message.error('Max file size is 3 MB!');
          return false
        }
        if((selectedOption === 1 || selectedOption === 4 || selectedOption === 8) && file.type !== "application/pdf"){
          message.error('Upload Failed, PDF only!');
          return false
        }
        if(selectedOption === 29 && file.type !== "image/jpeg"){
          message.error('Upload Failed, JPG/JPEG Only!')
          return false
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.setState({ 
            documentFile: file,
          })
          message.info('Upload Success!');
        }
        return false;
      },
      onChange: (e) => {
        const validateSize = e.file.size >= 3000000;
        if (validateSize) {
          this.formRef.current.setFieldsValue({ documentFile: null })
        }
        if((selectedOption === 1 || selectedOption === 4 || selectedOption === 8) && e.file.type !== "application/pdf"){
          this.formRef.current.setFieldsValue({ documentFile: null })
        }
        if(selectedOption === 29 && e.file.type !== "image/jpeg"){
          this.formRef.current.setFieldsValue({ documentFile: null })
        }
      },
    }
  }

  onChangeInput = (id) => {
    this.setState({ selectedOption: id, documentFile: null })
    this.formRef.current.resetFields(['documentNumber', 'issueDate', 'expirationDate', 'documentFile'])
  }

  handleRemove = (file) => {
    this.setState({ 
      documentFile: null,
      showFileEdit: false
    })
    this.formRef.current.setFieldsValue({
      documentFile: null
    })
    message.success(`${file.name} file removed successfully`);
  }

  onSubmitAdd = () => {
    const { current, documentFile } = this.state;
    const { actionAdd, actionGetProfile, getProfile } = this.props;
    this.setState({ submitLoading: true })
    this.formRef.current.validateFields().then(values => {
      values.documentNumber = values.documentNumber ? values.documentNumber : ""
      values.current = current
      values.issueDate = values.issueDate ? moment(values.issueDate).format('MM/YYYY') : ""
      values.expirationDate = values.expirationDate ? moment(values.expirationDate).format('MM/YYYY') : ""
      values.documentFile = documentFile 
      values.remark = values.remark ? values.remark : ""
      values.candidateId = getProfile.data.id
      return actionAdd(values, () => {
        return this.setState({ submitLoading: false }, () => {
          return this.setState({ visibleAdd: false }, () => {
            message.success('Data document created successfully')
            return actionGetProfile(getProfile.data.id)
          })
        })
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err))
      })
    })
  }

  onSubmitEdit = () => {
    const { current, dataDocuments, documentFile } = this.state;
    const { actionUpdate, actionGetProfile, getProfile } = this.props;
    this.setState({ submitLoading: true })
    this.formRef.current.validateFields().then(values => {
      values.documentNumber = values.documentNumber ? values.documentNumber : ""
      values.current = current 
      values.issueDate = values.issueDate ? moment(values.issueDate).format('MM/YYYY') : dataDocuments.issueDate ? dataDocuments.issueDate : ""
      values.expirationDate = values.expirationDate ? moment(values.expirationDate).format('MM/YYYY') : dataDocuments.expirationDate ? dataDocuments.expirationDate : ""
      
      if(dataDocuments.id){
        values.id = dataDocuments.id
        if(documentFile){
          values.documentFile = documentFile
        }else{
          delete values.documentFile
        }
        values.candidateId = getProfile.data.id
        return actionUpdate(values, () => {
          return this.setState({ submitLoading: false }, () => {
            return this.setState({ visibleEdit: false, showFileEdit: false }, () => {
              message.success('Data document updated successfully')
              return actionGetProfile(getProfile.data.id)
            })
          })
        }, (err) => {
          return this.setState({ submitLoading: false, showFileEdit: false }, () => message.error(err))
        })
      }else{
        message.error('Data document cannot updated')
      }
    })
  }

  handleDelete = () => {
    const { dataDocuments } = this.state;
    const { actionDelete, actionGetProfile, getProfile } = this.props;
    const self = this
    confirm({
      title: 'Remove Data',
      okText: 'Yes',
      cancelText: 'No',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to remove this data?',
      onOk() {
        self.setState({ deleteLoading: true })
        return actionDelete(dataDocuments.id, () => {
          return self.setState({ deleteLoading: false }, () => {
            return self.setState({ visibleEdit: false, showFileEdit: false }, () => {
              message.success('Data deleted successfully')
              return actionGetProfile(getProfile.data.id)
            })
          })
        }, (err) => {
          return self.setState({ deleteLoading: false }, () => message.error(err))
        })
      },
    });
  }

  onChangeInput = (id) => {
    this.setState({ selectedOption: id, documentFile: null })
    this.formRef.current.resetFields(['documentNumber', 'issueDate', 'expirationDate', 'documentFile'])
  }

  handlePreview = (info) => {
    const { documentFile } = this.state
    
    if(documentFile){
      const file = new Blob([documentFile], {
        type: documentFile.type,
      });
  
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank', 'fullscreen=yes');
    }else{
      window.open(info.url, '_blank', 'noreferrer, noopener')
    }
  }

  render() {
    const { getProfile: { data } } = this.props
    const { visibleAdd, visibleEdit, documentFile, dataDocuments, showFileEdit, deleteLoading, selectedOption, id } = this.state;

    const addFileList = [
      {
        uid: '-1',
        name: documentFile?.name,
        status: 'done'
      }
    ]

    const editFileList = [
      {
        uid: '-1',
        name: dataDocuments?.documentPic,
        status: 'done',
        url: dataDocuments?.documentUrl,
        thumbUrl: dataDocuments?.documentUrl,
      }
    ]

    const uploadDocumentAdd = (
      <Upload 
        {...this.handleUpload()} 
        onRemove={this.handleRemove}
        onPreview={this.handlePreview}
        listType="picture" 
        maxCount={1} 
        showUploadList={documentFile}
        fileList={documentFile ? addFileList : []}
      >
        <Button icon={<UploadOutlined />}>
          <Text className="fs-12">
            <Text className="fs-12" type="danger">* </Text>
              Upload Document
          </Text>
        </Button>
      </Upload>
    )

    const uploadDocumentEdit = (
      <Upload 
        {...this.handleUpload()} 
        onRemove={this.handleRemove}
        onPreview={this.handlePreview}
        listType="picture" 
        maxCount={1} 
        showUploadList={documentFile ? documentFile : showFileEdit}
        fileList={documentFile ? addFileList : showFileEdit ? editFileList : []}
      >
        <Button icon={<UploadOutlined />}>
          <Text className="fs-12">
            <Text className="fs-12" type="danger">* </Text>
              Upload Document
          </Text>
        </Button>
      </Upload>
    )

    const disabledDatePassport = (current) => {
      const { startDate } = this.state
      return current && current < moment(startDate)
    }

    const disabledNextMonth = (current) => {
      return current && current > moment();
    }

    return (
      <React.Fragment>
        <Row gutter={16}>
          <Col span={24}>
            <Descriptions title="Documents" />
            {
              data.listDocument.length > 0 ?
              <React.Fragment>
                {
                  data.listDocument.map((item, i) => (
                    <React.Fragment key={i}>
                      <div style={{ marginBottom: 11, display: 'flex', justifyContent: 'space-between' }}>
                        <Space direction='vertical' size={24}>
                          <Row>
                            <Col span={12}>
                              <Text style={{ color: '#32ab6d', fontSize: 14 }}>
                                {item.documentName}
                              </Text>
                            </Col>
                            <Col span={12}>
                              <Space size={16} style={{ float: 'right' }}>
                                <a href={item.documentUrl} target="_blank" rel='noreferrer'>
                                  <DownloadOutlined style={{ fontSize: 20, cursor: 'pointer', color: '#32ab6d' }} /> Download Document
                                </a>
                                <Text onClick={() => this.showModal('edit', item)} style={{ cursor: 'pointer', color: '#32ab6d' }}>
                                  <EditOutlined style={{ fontSize: 20, cursor: 'pointer', color: '#32ab6d' }} /> Edit
                                </Text>
                              </Space>
                            </Col>
                          </Row>
                          <Descriptions layout="vertical" size="small" column={4}>
                            <Descriptions.Item label="Document Number">
                              {item.documentNumber ? item.documentNumber : 'N/A'}
                            </Descriptions.Item>
                            <Descriptions.Item label="Issue Date">
                              {
                                item.issueDate ?
                                  moment(item.issueDate, 'MM/YYYY').format('MMM YYYY')
                                : 'N/A'
                              }
                            </Descriptions.Item>
                            <Descriptions.Item label="Expiration Date">
                              {
                                item.expirationDate ?
                                  moment(item.expirationDate, 'MM/YYYY').format('MMM YYYY')
                                : item.current ?
                                  'No Expiration Date'
                                : 'N/A'
                              }
                            </Descriptions.Item>
                            <Descriptions.Item label="Remarks">
                              {item.remark ? item.remark : 'N/A'}
                            </Descriptions.Item>
                          </Descriptions>
                        </Space>
                      </div>
                      <Divider />
                    </React.Fragment>
                  ))
                }
              </React.Fragment>
              :
              <React.Fragment>
                <Text>N/A</Text>
                <Divider />
              </React.Fragment>
            }
          </Col>
          
          <Col span={24} style={{marginBottom: 30}}>
            <Button type="dashed" icon={<FileAddOutlined />} block onClick={() => this.showModal('add')}>
              Upload New Document
            </Button>
          </Col>
        </Row>
        <Modal
          key="1"
          centered
          width={1000}
          destroyOnClose
          visible={visibleAdd}
          onCancel={() => this.onCancel('add')}
          title={<Text strong>Add Document</Text>}
          footer={[
            <React.Fragment key="1">
              <Button key="back" className="btn-save-primary" onClick={() => this.onCancel('add')}>Cancel</Button>
              <Button key="submit" className="btn-save-primary" type="primary" onClick={this.onSubmitAdd}>Save</Button>
            </React.Fragment>
          ]}
        >
          <AddDocument 
            {...this.props} 
            {...this.state}
            formRef={this.formRef} 
            onSubmitAdd={this.onSubmitAdd}
            handleChecked={this.handleChecked}
            onChangeEndDate={this.onChangeEndDate}
            onChangeStartDate={this.onChangeStartDate}
            handleUpload={this.handleUpload}
            uploadDocument={uploadDocumentAdd}
            onChangeInput={this.onChangeInput}
            disabledDatePassport={disabledDatePassport}
            selectedOption={selectedOption}
            disabledNextMonth={disabledNextMonth}
          />
        </Modal>
        <Modal
          key="2"
          centered
          width={1000}
          destroyOnClose
          visible={visibleEdit}
          onCancel={() => this.onCancel('edit')}
          title={<Text strong>Edit Document</Text>}
          footer={[
            <React.Fragment key="1">
              <Button onClick={this.handleDelete} loading={deleteLoading} type="link" danger style={{ float: 'left' }}>Delete</Button>
              <Button key="back" className="btn-save-primary" onClick={() => this.onCancel('edit')}>Cancel</Button>
              <Button key="submit" className="btn-save-primary" type="primary" onClick={this.onSubmitEdit}>Update</Button>
            </React.Fragment>
          ]}
        >
          <EditDocument 
            {...this.props} 
            {...this.state}
            formRef={this.formRef}
            onSubmitEdit={this.onSubmitEdit} 
            handleChecked={this.handleChecked}
            onChangeEndDate={this.onChangeEndDate}
            onChangeStartDate={this.onChangeStartDate}
            handleUpload={this.handleUpload}
            uploadDocument={uploadDocumentEdit}
            onChangeInput={this.onChangeInput}
            disabledDatePassport={disabledDatePassport}
            selectedOption={selectedOption}
            disabledNextMonth={disabledNextMonth}
            id={id}
          />
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  getDocumentTypes : state.documentTypes.master
})

const mapDispatchToProps = {
  actionAdd               : addDocument,
  actionUpdate            : updateDocument,
  actionDelete            : deleteDocument,
  actionGetDocumentTypes  : masterDocumentTypes,
  unmountGetDocumentTypes : unmountMasterDocumentType
}

export default connect(mapStateToProps, mapDispatchToProps)(Document)