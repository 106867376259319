import React, { Component } from 'react';
import { Row, Col, Descriptions, Skeleton } from 'antd';

export default class DetailLocation extends Component {

  componentDidMount() {
    const { actionGetLocation } = this.props;
    return actionGetLocation()
  }
  
  render() {
    const { getDetailLocation: { loading, data } } = this.props;
    if(loading){
      return <Skeleton />
    }
    return (
      <React.Fragment>
        <Row gutter={16}>
          <Col span={24}>
            <Descriptions layout="vertical" column={2}>
              <Descriptions.Item label="Country">
                {data.countryName ? data.countryName : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Province">
                {data.provinceName ? data.provinceName : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="City">
                {data.cityName ? data.cityName : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Postal Code">
                {data.zipCode ? data.zipCode : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Street Address" span={2}>
                {data.address ? data.address : 'N/A'}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}
