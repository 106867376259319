import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { columns } from './columns';
import { connect } from 'react-redux';
import { HeaderTable } from '../../../components';
import { SoundOutlined } from '@ant-design/icons';
import { Row, Col, Breadcrumb, PageHeader, Table, Pagination, message } from 'antd';
import { inactiveJob, activeJob, listJobs, unmountListJobs } from '../../../redux/actions/jobs/jobsAction';

class AdminJobs extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      selectedRowKeys: [],
      url: 'job_admin',
      meta: {
        page: 1,
        perpage: 10,
        field: null,
        sort: null,
        search: null,
        accountType: null,
        status: null, 
        startDate: null,
        endDate: null
      }
    }
    this.onSearch = debounce(this.onSearch.bind(this), 900)
  }

  componentDidMount() {
    const { meta, url } = this.state;
    const { actionGetData } = this.props;
    return actionGetData(meta, url)
  }

  onRegistered = value => {
    const { meta, url } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.startDate = value.startDate
    meta.endDate = value.endDate
    return actionGetData(meta, url)
  }

  onTypeAccount = value => {
    const { meta, url } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.accountType = value
    return actionGetData(meta, url)
  }

  onStatus = value => {
    const { meta, url } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.status = value
    return actionGetData(meta, url)
  }

  onSearch = value => {
    const { meta, url } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.search = value === '' ? null : value
    return actionGetData(meta, url)
  }

  pagination = (page, perpage) => {
    const { meta, url } = this.state;
    const { actionGetData } = this.props;
    meta.page = page
    meta.perpage = perpage
    return actionGetData(meta, url)
  }

  handleActive = (value) => {
    const { meta, url } = this.state;
    const { actionActive, actionGetData } = this.props;
    return actionActive(value, url, () => {
      message.success('Successfully changed to active')
      return actionGetData(meta, url)
    })
  }

  handleInactive = (value) => {
    const { meta, url } = this.state;
    const { actionInactive, actionGetData } = this.props;
    return actionInactive(value, url, () => {
      message.success('Successfully changed to inactive')
      return actionGetData(meta, url)
    })
  }

  onClickApproval = (id) => {
    this.props.history.push(`/jobs/approval/${id}`)
  }

  onClickRow = (record) => {
    this.props.history.push(`/jobs/detail/${record.id}`)
  }

  onClickEdit = (id) => {
    this.props.history.push(`/jobs/edit/${id}`)
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  render() {
    const { selectedRowKeys } = this.state;
    const { getData: { data, loading, pagination } } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const defaultStatusFilter = [
      { value: null, name: 'All' },
      { value: 'DRAFT', name: 'Draft' },
      { value: 'REQUEST', name: 'Waiting for approval' },
      { value: 'ACTIVE', name: 'Active' },
      { value: 'INACTIVE', name: 'Inactive' },
      { value: 'REJECT', name: 'Reject' },
    ]

    const defaultStatusTypeAccount = [
      { value: null, name: 'All' },
      { value: 'AGENCY', name: 'Agency' },
      { value: 'EMPLOYER', name: 'Employer' },
    ]

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><SoundOutlined /> Job Posting</Breadcrumb.Item>
              <Breadcrumb.Item>Job Posting List</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          { /* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Job Posting List' />
          </Col>
          {/* Filter */}
          <Col span={24}>
            <HeaderTable 
              fase1={{ key: 'type', value: defaultStatusFilter}}
              fase2={{ key: 'type', value: defaultStatusTypeAccount}}
              onSearch={this.onSearch} 
              onStatus={this.onStatus} 
              onTypeAccount={this.onTypeAccount}
              onRegistered={this.onRegistered}
              tooltipDate="Filter by Close Application Date"
              tooltipStatus="Filter by Status"
              placeholder="Search by Number, Title, Requestor"
            />
          </Col>
          {/* Table */}
          <Col span={24}>
            <Table 
              size="small" 
              rowKey={(i) => i.id} 
              pagination={false}
              dataSource={data} 
              loading={loading} 
              scroll={{ x: 1500 }}
              rowSelection={rowSelection}  
              // onRow={(r) => ({ onClick: () => this.onClickRow(r) })}
              rowClassName={(record) => record.id ? 'row-pointer' : null} 
              columns={columns(this.onClickApproval, this.onClickEdit, this.handleActive, this.handleInactive, this.onClickRow)} 
            />
          </Col>
          {/* Pagination */}
          <Col span={24}>
            <Pagination
              total={pagination.total}
              onChange={this.pagination}
              current={pagination.page}
              pageSize={pagination.perpage}
              showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total} Data`}
            />
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetData } = this.props;
    return unmountGetData()
  }
}

const mapStateToProps = (state) => ({
  getData: state.jobs.list
})

const mapDispatchToProps = {
  actionGetData     : listJobs,
  actionActive      : activeJob,
  actionInactive    : inactiveJob,
  unmountGetData    : unmountListJobs
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminJobs)
