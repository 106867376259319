import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Tag } from 'antd';

const { Text } = Typography;

export const columns = (handleRoute) => {
  return [
    {
      title: 'Number',
      key: 'number',
      width: 180,
      fixed: 'left',
      render: (record) => (
        <React.Fragment>
          <Link className="link" to='#' onClick={() => handleRoute(record)}>
            {record.candidateNumber}
          </Link>
        </React.Fragment>
      )
    },
    {
      title: 'Name',
      key: 'name',
      width: 200,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.fullName}
        </Text>
      )
    },
    {
      title: 'Register Date',
      key: 'registrationDate',
      width: 150,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.registrationDate ? record.registrationDate : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Email',
      key: 'email',
      width: 200,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.email ? record.email : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Phone',
      key: 'phone',
      width: 200,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {/* {`+${record.callingCode}${record.mobilePhone}`} */}
          {`+62${record.mobilePhone}`}
        </Text>
      )
    },
    {
      title: 'Last Update',
      key: 'lastUpdate',
      width: 150,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.lastUpdate ? record.lastUpdate : 'N/A'}
        </Text>
      )
    },
    {
      title: 'Status',
      key: 'status',
      width: 60,
      fixed: 'right',
      render: (record) => (
        <React.Fragment>
          {
            record.enabled ? 
              <Tag color="green">Active</Tag>
            : 
              <Tag color="orange">Inactive</Tag>
          }
        </React.Fragment>
      )
    }
  ]
}