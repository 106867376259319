import React from 'react'
import { Card, Space, Row, Col, Typography, Image } from 'antd';
import Visa1 from '../../assets/img/cowo visa.jpg';
import Visa2 from '../../assets/img/hijab visa.jpg';
import Visa3 from '../../assets/img/no hijab visa.jpg';

const { Text } = Typography;


export default function InfoPhotoVisa() {
    return (
        <Card className='border-radius-6'>
            <Row>
                <Col className='mb-16' span={24}>
                    <Text className='fs-12'>Persyaratan: <Text className='dark-color' strong>Background abu-abu atau putih; Tanpa Aksesoris; Close Up standar visa; Format File JPG/JPEG.</Text></Text>
                </Col>
                <Col span={24}>
                    <Row gutter={[0,16]}>
                        <Col className='text-align-center' xs={24} md={8}>
                            <Space direction="vertical" align="center">
                                <Text className='dark-color' strong>Laki-laki</Text>
                                <Image src={Visa1} width="100%" height={200} preview={false} style={{ objectFit: 'contain' }} />
                                <Space className='text-align-left' direction='vertical' size={0}>
                                    <Text className="fs-12" type="secondary">• Kedua telinga terlihat</Text>
                                    <Text className="fs-12" type="secondary">• Rambut rapi</Text>
                                    <Text className="fs-12" type="secondary">• Tidak (tersenyum) terlihat gigi</Text>
                                </Space>
                            </Space>
                        </Col>
                        <Col className='text-align-center' xs={24} md={8}>
                            <Space direction="vertical" align="center">
                                <Text className='dark-color' strong>Perempuan Berhijab</Text>
                                <Image src={Visa2} width="100%" height={200} preview={false} style={{ objectFit: 'contain' }} />
                                <Space className='text-align-left' direction='vertical' size={0}>
                                    <Text className="fs-12" type="secondary">• Posisi kerudung tidak menutupi kedua alis</Text>
                                    <Text className="fs-12" type="secondary">• Tidak (tersenyum) terlihat gigi </Text>
                                    <Text className="fs-12" type="secondary">• Warna kerudung <Text className='dark-color' strong>BUKAN</Text> putih atau abu-abu</Text>
                                </Space>
                            </Space>
                        </Col>
                        <Col className='text-align-center' xs={24} md={8}>
                            <Space direction="vertical" align="center">
                                <Text className='dark-color' strong>Perempuan Tidak Berhijab</Text>
                                <Image src={Visa3} width="100%" height={200} preview={false} style={{ objectFit: 'contain' }} />
                                <Space className='text-align-left' direction='vertical' size={0}>
                                    <Text className="fs-12" type="secondary">• Kedua telinga terlihat</Text>
                                    <Text className="fs-12" type="secondary">• Rambut rapi</Text>
                                    <Text className="fs-12" type="secondary">• Tidak (tersenyum) terlihat gigi</Text>
                                </Space>
                            </Space>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    )
}
