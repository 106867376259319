import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Loading } from '../../../../components';
import { BankOutlined } from '@ant-design/icons';
import { PageHeader, Card, Row, Col, Descriptions, Typography, Image, Breadcrumb } from 'antd';
import { detailEmployerInquiries, unmountDetailEmployersInquiries } from '../../../../redux/actions/employersInquiries/employersInquiriesAction';
import { Link } from 'react-router-dom';

const { Text } = Typography;

class DetailEmployerInquiries extends Component {
  componentDidMount() {
    const { actionGetDetail, match: { params } } = this.props;
    return actionGetDetail(params.id)
  }

  render() {
    const { getData: { data, loading } } = this.props;

    if(loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><BankOutlined /> Inquiries</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/employers-inquiries">Employers inquiry List</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{data.employersRegisterNumber ? data.employersRegisterNumber : '-'}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Employer Inquiry Details' />
          </Col>
          {/* Descriptions */}
          <Col span={24}>
            <Card>
              <Row gutter={16}>
                <Col span={24}>
                  <Descriptions layout="vertical" column={2} size="small">
                    <Descriptions.Item label={<Text type="secondary">Employer Name</Text>}>
                      {data.name ? data.name : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Employer Email</Text>}>
                      {data.email ? data.email : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Contact Person</Text>}>
                      {data.picName ? data.picName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Contact Person Mobile Number</Text>}>
                      {data.picMobilePhone ? `+${data.callingCode}${data.picMobilePhone}` : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Employer Type</Text>}>
                      {data.employersTypeName ? data.employersTypeName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Industry</Text>}>
                      {data.jobIndustryTitle ? data.jobIndustryTitle : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Contact Person Email</Text>}>
                      {data.picEmail ? data.picEmail : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Specialties (niche or specific industry of the employer)</Text>}>
                      {data.specialties ? data.specialties : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Office Phone Number</Text>}>
                      {data.officePhone ? data.officePhone : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Country</Text>}>
                      {data.countryName ? data.countryName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Province</Text>}>
                      {data.provinceName ? data.provinceName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">City</Text>}>
                      {data.cityName ? data.cityName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Street Address</Text>}>
                      {data.address ? data.address : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Postal Code</Text>}>
                      {data.zipCode ? data.zipCode : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Company Size</Text>}>
                      {data.totalEmployee ? data.totalEmployee : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Website</Text>}>
                      {data.website ? data.website : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">About</Text>}>
                      {data.about ? data.about : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Logo</Text>}>
                      {data.logoUrl ? <Image width={100} src={data.logoUrl} /> : 'N/A'}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail } = this.props;
    return unmountGetDetail()
  }
}

const mapStateToProps = (state) => ({
  getData         : state.employersInquiries.detail,
})

const mapDispatchToProps = {
  actionGetDetail       : detailEmployerInquiries,
  unmountGetDetail      : unmountDetailEmployersInquiries,
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailEmployerInquiries)
