import { API } from '../../../config';
import { errorHandler } from '../auth/errorAction';

export const unmountListCandidatePathway = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_CANDIDATE_PATHWAY'})
}

export const unmountDetailCandidatePathway = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_CANDIDATE_PATHWAY'})
}

export const unmountMasterCandidatePathway = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_CANDIDATE_PATHWAY'})
}

export const unmountListBasicEnglish = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_BASIC_ENGLISH'})
}

export const unmountDetailBasicEnglish = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_BASIC_ENGLISH'})
}

export const unmountListMiniEnglish = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MINI_ENGLISH'})
}

export const unmountDetailMiniEnglish = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_MINI_ENGLISH'})
}

export const listCandidatePathway = (meta) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_CANDIDATE_PATHWAY' })
  return API.GET('/candidate_pathway/list', meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_CANDIDATE_PATHWAY_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_CANDIDATE_PATHWAY_FAILED' }), 
    ))
  })
}

export const detailCandidatePathway = (id, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DETAIL_CANDIDATE_PATHWAY' })
  const params = {
    'id': id
  }
  API.GET('/candidate_pathway/getById', params).then((response) => {
    dispatch({ 
      type: 'LOAD_DETAIL_CANDIDATE_PATHWAY_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DETAIL_CANDIDATE_PATHWAY_FAILED' }), 
      ))
    )
  })
}

export const masterCandidatePathway = (successCB, failedCB) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_CANDIDATE_PATHWAY' })
  return API.GET('/employers_type/index').then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_CANDIDATE_PATHWAY_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: null,
          total: null,
          perpage: null
        }
    }})
    return successCB && successCB(response.data)
  }).catch((err) => {
    failedCB && failedCB()
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_MASTER_CANDIDATE_PATHWAY_FAILED' }), 
    ))
  })
}

export const approveCandidatePathway = (value, successCB, failedCB) => () => {
  API.POST_WITH_PARAMS('/candidate_pathway/approve', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const editCandidatePathway = (value, type, successCB, failedCB) => () => {
  const url = type === 'add' ? '/candidate_pathway/add' : '/candidate_pathway/update'
  API.POST_FORM_DATA_ARRAY(url, value, 'pathway').then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const rejectCandidatePathway = (value, successCB, failedCB) => () => {
  const params = {
    id: value.id,
    rejectReason: value.rejectReason
  }
  API.POST_WITH_PARAMS('/candidate_pathway/reject', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const listBasicEnglish = (meta) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_BASIC_ENGLISH' })
  return API.GET('/candidate_pathway/basic_english/list', meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_BASIC_ENGLISH_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_BASIC_ENGLISH_FAILED' }), 
    ))
  })
}

export const detailBasicEnglish = (id, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DETAIL_BASIC_ENGLISH' })
  const params = {
    'id': id
  }
  API.GET('/candidate_pathway/basic_english/getById', params).then((response) => {
    dispatch({ 
      type: 'LOAD_DETAIL_BASIC_ENGLISH_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DETAIL_BASIC_ENGLISH_FAILED' }), 
      ))
    )
  })
}

export const restartBasicEnglish = (value, successCB, failedCB) => () => {
  API.POST_WITH_PARAMS('/candidate_pathway/basic_english/restart_test', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const exportDataBasicEnglish = (meta, successCB, failedCB) => () => {
  return API.GET_DOWNLOAD(`/candidate_pathway/basic_english/print`, meta).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const listMiniEnglish = (meta) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MINI_ENGLISH' })
  return API.GET('/candidate_pathway/mini_english/list', meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_MINI_ENGLISH_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_MINI_ENGLISH_FAILED' }), 
    ))
  })
}

export const detailMiniEnglish = (id, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DETAIL_MINI_ENGLISH' })
  const params = {
    'id': id
  }
  API.GET('/candidate_pathway/mini_english/getById', params).then((response) => {
    dispatch({ 
      type: 'LOAD_DETAIL_MINI_ENGLISH_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DETAIL_MINI_ENGLISH_FAILED' }), 
      ))
    )
  })
}

export const exportMiniEnglish = (meta, successCB, failedCB) => () => {
  return API.GET_DOWNLOAD(`/candidate_pathway/mini_english/print`, meta).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const restartMiniEnglish = (value, successCB, failedCB) => () => {
  API.POST_WITH_PARAMS('/candidate_pathway/mini_english/restart_test', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}