import React, { Component } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Col, Row, AutoComplete, Input } from 'antd';

export default class SearchDetailJob extends Component {

  handleChangeSearch = (value) => {
    const { onSearch } = this.props;
    return onSearch(value)
  }
  
  render() {
    return (
      <React.Fragment>
        <Row gutter={16}>
          <Col span={6}>
            <AutoComplete 
              onSearch={this.handleChangeSearch} 
              style={{ width: '100%' }} 
              allowClear
            >
              <Input prefix={<SearchOutlined />} placeholder="Search by Name. Institution or Fields of Study"  />
            </AutoComplete>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}
