import { API } from '../../../config';
import { errorHandler } from '../auth/errorAction';

export const unmountListNewCandidate = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_NEW_CANDIDATE'})
}

export const listNewCandidate = (meta) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_NEW_CANDIDATE' })
  return API.GET(`/candidate/crm/list`, meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_NEW_CANDIDATE_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_NEW_CANDIDATE_FAILED' }), 
    ))
  })
}
  
export const createNewCandidate = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS(`/candidate/crm/add`, value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const createNotes = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS(`/candidate/notes/add`, value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updateStatusNewCandidate = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS(`/candidate/crm/update_status`, value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const addCounselingProcess = (value, successCB, failedCB) => () => {
  API.POST_NO_PARAMS(`/candidate/counseling/add`, value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const exportData = (meta, successCB, failedCB) => () => {
  return API.GET_DOWNLOAD(`/candidate/crm/report/print`, meta).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updateStatusByFilter = (params, successCB, failedCB) => () => {
  API.POST_WITH_PARAMS('/candidate/crm/update_status_filter', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const addCounselingProcessByFilter = (params, successCB, failedCB) => () => {
  API.POST_WITH_PARAMS('/candidate/counseling/add_filter', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const exportDataByFilter = (meta, successCB, failedCB) => () => {
  return API.GET_DOWNLOAD('/candidate/crm/report_filter/print', meta).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}