import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Loading } from '../../../../../components';
import { BankOutlined } from '@ant-design/icons';
import { PageHeader, Card, Row, Col, Descriptions, Typography, Breadcrumb, Space, Timeline } from 'antd';
import { Link } from 'react-router-dom';
import { detailBasicEnglish, unmountDetailBasicEnglish } from '../../../../../redux/actions/candidatePathway/candidatePathwayAction';
import { detailCandidate } from '../../../../../redux/actions/candidate/candidateAction';
// import ViewProfile from '../../../../Employer/Jobs/Detail/Profile';

const { Text } = Typography;

class DetailEnglishAssessment extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      visible: false
    }
  }
  
  componentDidMount() {
    const { actionGetDetail, match: { params } } = this.props;
    return actionGetDetail(params.id)
  }

  onPriviewProfile = (id) => {
    const { actionGetProfile } = this.props;
    return actionGetProfile(id, () => {
      this.setState({ visible: true })
    })
  }

  handleCancel = () => {
    this.setState({ visible: false })
  }

  render() {
    const { getData: { data, loading } } = this.props;
    // const { visible } = this.state

    if(loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><BankOutlined />Candidate Pathway</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to="/candidate-pathway/english-assessment">English Assessment List</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{data.candidateName ? data.candidateName : '-'}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader 
              className="site-page-header" 
              title='Career Pathway'
            />
          </Col>
          {/* Descriptions */}
        </Row>

        <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
          <Col span={24}>
            <Card>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Descriptions layout="vertical" column={2} size="small">
                    <Descriptions.Item label={<Text type="secondary">Submitted Date</Text>}>
                      {data.submitDate ? data.submitDate : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Submitted Time</Text>}>
                      {data.submitTime ? data.submitTime : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Name</Text>}>
                      {data.candidateName ? data.candidateName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Latest Score</Text>}>
                      {data.score ? data.score : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Levels</Text>}>
                      {data.level ? data.level : 'N/A'}
                    </Descriptions.Item>
                  </Descriptions>

                  <Descriptions layout="vertical" size="small">
                    <Descriptions.Item label={<Text type="secondary">History Test</Text>}>
                    <Space direction='vertical'>
                      {
                        data?.listHistory?.length > 0 ?
                          data?.listHistory?.map((item, i) => (
                            <Space direction='horizontal'>
                              {/* <Badge ot offset={[-2, 2]} style={{ width: 10, height: 10 }}><HistoryOutlined />  */}
                              <Timeline.Item key={i} color="#0076de">
                                <Space direction='vertical'>
                                  <Text> {item.submitDate} | {item.submitTime} Score : {item.score}/{item.level}</Text>
                                </Space>
                              </Timeline.Item>

                            </Space>
                          ))
                        : '-'
                      }
                    </Space>
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail } = this.props;
    return unmountGetDetail()
  }
}

const mapStateToProps = (state) => ({
  getData         : state.basicEnglish.detail,
  getProfile      : state.candidate.detail
})

const mapDispatchToProps = {
  actionGetDetail       : detailBasicEnglish,
  unmountGetDetail      : unmountDetailBasicEnglish,
  actionGetProfile      : detailCandidate,
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailEnglishAssessment)
