import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Row, Col, Typography, Form, Input, DatePicker, Select, InputNumber } from 'antd';
import { masterCountries, unmountMasterCountries } from '../../../../../../../../redux/actions/master/countries/countriesAction';
import { provinceByCountry, unmountListProvinces } from '../../../../../../../../redux/actions/master/provinces/provincesAction';
import { cityByProvince, unmountListCities } from '../../../../../../../../redux/actions/master/cities/citiesAction';
import { subDistrictByCity, unmountListSubDistricts } from '../../../../../../../../redux/actions/master/subDistricts/subDistrictsAction';
import { villageBySubDistrict, unmountListVillages } from '../../../../../../../../redux/actions/master/villages/villagesAction';
import { Loading } from '../../../../../../../../components'
const { Text } = Typography;
const { Option } = Select;

export class index extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      selectedCountries: null,
      dataProvinces: [],
      dataCities: [],
      dataSubDistricts: [],
      dataVillages: []
    }
  }
  async componentDidMount() {
    const { actionGetCountries } = this.props

    return actionGetCountries()
  }

  _handleCountry = (value, option) => {
    const optionSelected = Number(option.key)
    this.setState({
      selectedCountries: optionSelected,
    })
    const { actionGetProvinces } = this.props
    actionGetProvinces(option.key, (response) => {
      this.setState({ dataProvinces: response.data })
    })
  }

  _handleProvince = (value, option) => {
    const { actionGetCities } = this.props
    actionGetCities(option.key, (response) => {
      this.setState({ dataCities: response.data })
    })
  }

  _handleCity = (value, option) => {
    const { actionGetSubDistricts } = this.props
    actionGetSubDistricts(option.key, (response) => {
      this.setState({ dataSubDistricts: response.data })
    })
  }

  _handleSubDistrict = (value, option) => {
    const { actionGetVillages } = this.props
    actionGetVillages(option.key, (response) => {
      this.setState({ dataVillages: response.data })
    })
  }

  render() {
    const { selectedCountries, dataProvinces, dataCities, dataSubDistricts, dataVillages } = this.state
    const { 
      isCandidate,
      callingCode,
      handleCheckEmail,
      handleCheckMobile,
      handleCallingCode,
      onChangeDate,
      handleNationality,
      handleCountry,
      handleProvince,
      handleCity,
      handleSubDistrict,
      handleVillage,
      getCountries,
      dataCandidate,
      loadingCheckCandidate
    } = this.props
    
    const prefixSelector = (
      <Form.Item 
        name="callingCodeId" 
        noStyle
        initialValue={
          `+${callingCode}`
        //   valuesContact && valuesContact.callingCodeId ? 
        //     valuesContact.textCode
        //   : 
        //     ""
        }
      >
        <Select onChange={(value, option) => handleCallingCode(value, option)} disabled={isCandidate}>
          {
            getCountries?.data?.map(item =>
              <Option key={item.id} text={item.callingCode} value={item.callingCode}>{`+${item.callingCode}`}</Option>
            )
          }
        </Select>
      </Form.Item>
    )

    if(getCountries.loading || loadingCheckCandidate){
      return <Loading />
    }
    
    return (
      <React.Fragment>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item 
              name="email"
              label="Email"
              rules={[
                { required: true, message: 'This filed is required!' },
                { type: 'email', message: 'Your email address is invalid' }, 
              ]}
              // initialValue={
              //   valuesPersonal && valuesPersonal?.email ?
              //     valuesPersonal.email
              //   : 
              //     ""
              // }
            >
              <Input type="email" onBlur={(e) => handleCheckEmail(e.target.value)} disabled={isCandidate} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              name="mobilePhone"
              label="Phone Number"
              rules={[
                { required: true, message: 'This filed is required!' },
                { pattern: /^[0-9]*$/, message: 'Can only use numbers' },
              ]}
              // initialValue={
              //   valuesPersonal && valuesPersonal?.mobilePhone ?
              //     valuesPersonal.mobilePhone
              //   : 
              //     ""
              // }
            >
              <Input onBlur={(e) => handleCheckMobile(e.target.value)} addonBefore={prefixSelector} disabled={isCandidate} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item 
              name="firstName"
              label="First Name"
              rules={[
                { required: true, message: 'This filed is required!' },
              ]}
              // initialValue={
              //   valuesPersonal && valuesPersonal?.firstName ?
              //     valuesPersonal.firstName
              //   : 
              //     ""
              // }
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              name="lastName"
              label="Last Name"
              rules={[
                { required: true, message: 'This filed is required!' },
              ]}
              // initialValue={
              //   valuesPersonal && valuesPersonal?.lastName ?
              //     valuesPersonal.lastName
              //   : 
              //     ""
              // }
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item 
              name="birthdate"
              label="Date of Birth"
              rules={[
                { required: true, message: 'Date of Birth is required!' },
              ]}
              // initialValue={
              //   valuesPersonal && valuesPersonal?.birthdate ?
              //     valuesPersonal.birthdate
              //   : 
              //     ""
              // }
            >
              <DatePicker inputReadOnly format="DD/MM/YYYY" onChange={onChangeDate} defaultPickerValue={moment('2000-01-01')} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              name="gender"
              label="Gender"
              rules={[
                { required: true, message: 'Gender is required!' },
              ]}
              // initialValue={
              //   valuesPersonal && valuesPersonal?.gender ?
              //     valuesPersonal.gender
              //   : 
              //     ""
              // }
            >
              <Select>
                <Option key="1" value="MALE">Male</Option>
                <Option key="2" value="FEMALE">Female</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item 
              name="placeOfBirth"
              label="Place of Birth"
              validateFirst
              rules={[
                { pattern: /^[a-zA-Z ]*$/, message: 'Alphabetical only' },
                { max: 50, message: '50 characters only' },
              ]}
              // initialValue={
              //   valuesPersonal && valuesPersonal?.placeOfBirth ?
              //     valuesPersonal.placeOfBirth
              //   : 
              //     ""
              // }
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              name="nationality"
              label="Nationality"
              initialValue={
                'Indonesian'
              }
            >
              <Select 
                showSearch
                onChange={handleNationality}
              >
                {
                  getCountries?.data?.map(item => 
                    <Option key={item.id} value={item.nationality}>{item.nationality}</Option>
                  )
                }
              </Select>
            </Form.Item>
          </Col>
        </Row>
          
        <Row gutter={8}>
          <Col span={6}>
            <Form.Item 
              name="marital"
              label="Marital Status"
              // initialValue={
              //   valuesPersonal && valuesPersonal.marital ?
              //     valuesPersonal.marital
              //   : getPersonalInfo.data.marital ?
              //     getPersonalInfo.data.marital
              //   :
              //     ""
              // }
            >
              <Select>
                <Option key="1" value="SINGLE">Single</Option>
                <Option key="2" value="MARRIED">Married</Option>
                <Option key="3" value="DIVORCE">Divorce</Option>
                <Option key="4" value="WIDOWED">Widowed</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item 
              name="bloodType"
              label="Blood Type"
              // initialValue={
              //   valuesPersonal && valuesPersonal.bloodType ?
              //     valuesPersonal.bloodType
              //   : getPersonalInfo.data.bloodType ?
              //     getPersonalInfo.data.bloodType
              //   :
              //     ""
              // }
            >
              <Select>
                <Option key="1" value="A">A</Option>
                <Option key="2" value="AB">AB</Option>
                <Option key="3" value="B">B</Option>
                <Option key="4" value="O">O</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item 
              name="height"
              label="Height (cm)"
              validateFirst
              rules={[
                { pattern: /^[0-9]*$/, message: 'Numeric only' }
              ]}
              // initialValue={
              //   valuesPersonal && valuesPersonal?.height ?
              //     valuesPersonal.height
              //   : 
              //     ""
              // }
            >
              <InputNumber maxLength={3} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item 
              name="weight"
              label="Weight (kg)"
              validateFirst
              rules={[
                { pattern: /^[0-9]*$/, message: 'Numeric only' },
              ]}
              // initialValue={
              //   valuesPersonal && valuesPersonal.weight ?
              //     valuesPersonal.weight
              //   : getPersonalInfo.data.weight ?
              //     getPersonalInfo.data.weight.toString()
              //   :
              //     ""
              // }
            >
              <InputNumber maxLength={3} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item 
              name="identificationId"
              label="ID Card Number (KTP)"
              validateFirst
              rules={[
                { pattern: /^[0-9]*$/, message: 'Numeric only' },
                { len: 16, message: 'KTP number must be 16 digits' },
              ]}
              // initialValue={
              //   valuesPersonal && valuesPersonal.identificationId ?
              //     valuesPersonal.identificationId
              //   : getPersonalInfo.data.identificationId ?
              //     getPersonalInfo.data.identificationId
              //   :
              //     ""
              // }
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              name="religion"
              label="Religion"
              // initialValue={
              //   valuesPersonal && valuesPersonal.religion ?
              //     valuesPersonal.religion
              //   : getPersonalInfo.data.religion ?
              //     getPersonalInfo.data.religion
              //   :
              //     ""
              // }
            >
              <Select>
                <Option key="1" value="ISLAM">Islam</Option>
                <Option key="2" value="CATHOLIC">Catholic</Option>
                <Option key="3" value="PROTESTAN">Protestan</Option>
                <Option key="4" value="BUDDHA">Buddha</Option>
                <Option key="5" value="HINDU">Hindu</Option>
                <Option key="6" value="OTHERS">Others</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ marginBottom: 16 }}>
          <Col span={24}>
            <Text>ID Card  (KTP) Address</Text>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item 
              name="country"
              // initialValue={
              //   valuesPersonal && valuesPersonal.country ?
              //     valuesPersonal.country
              //   : getPersonalInfo.data.countryName ?
              //     getPersonalInfo.data.countryName
              //   :
              //     null
              // }
            >
              <Select 
                showSearch
                onChange={(value, option) =>  {handleCountry(value, option); this._handleCountry(value, option)}}
                placeholder="Country"
              >
                {
                  getCountries?.data?.map(item => 
                    <Option key={item.id} value={item.name}>{item.name}</Option>
                    )
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              name="province"
              // initialValue={
              //   valuesPersonal && valuesPersonal.provinve ?
              //     valuesPersonal.provinve
              //   : getPersonalInfo.data.provinceName ?
              //     getPersonalInfo.data.provinceName
              //   :
              //     null
              // }
            >
              <Select 
                showSearch
                onChange={(value, option) => {handleProvince(value, option); this._handleProvince(value, option)}}
                placeholder="Province"
              >
                {
                  dataProvinces.map(item => 
                    <Option key={item.id} value={item.name}>{item.name}</Option>
                    )
                }
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item 
              name="city"
              // initialValue={
              //   valuesPersonal && valuesPersonal.city ?
              //     valuesPersonal.city
              //   : getPersonalInfo.data.cityName ?
              //     getPersonalInfo.data.cityName
              //   :
              //     null
              // }
            >
              <Select 
                showSearch
                onChange={(value, option) => {handleCity(value, option); this._handleCity(value, option)}}
                placeholder="City"
              >
                {
                  dataCities.map(item => 
                    <Option key={item.id} value={item.name}>{item.name}</Option>
                    )
                }
              </Select>
            </Form.Item>
          </Col>
          {
            dataCandidate?.countryId === 99 || selectedCountries === 99 ?
              <Col span={12}>
                <Form.Item 
                  name="subDistrict"
                  // initialValue={
                  //   valuesPersonal && valuesPersonal.subDistrict ?
                  //     valuesPersonal.subDistrict
                  //   : getPersonalInfo.data.subDistrictName ?
                  //     getPersonalInfo.data.subDistrictName
                  //   :
                  //     null
                  // }
                >
                  <Select 
                    showSearch
                    onChange={(value, option) => {handleSubDistrict(value, option); this._handleSubDistrict(value, option)}}
                    placeholder="Sub District (Kecamatan)"
                  >
                    {
                      dataSubDistricts.map(item => 
                        <Option key={item.id} value={item.name}>{item.name}</Option>
                        )
                    }
                  </Select>
                </Form.Item>
              </Col>
            :
              <Col span={12}>
                <Form.Item 
                  name="zipCode"
                  validateFirst
                  rules={[
                    { pattern: /^[0-9]*$/, message: 'Numeric only' },
                    { max: 10, message: '10 digits only' },
                  ]}
                  // initialValue={
                  //   valuesPersonal && valuesPersonal.zipCode ?
                  //     valuesPersonal.zipCode
                  //   : getPersonalInfo.data.zipCode ?
                  //     getPersonalInfo.data.zipCode
                  //   :
                  //     null
                  // }
                >
                  <Input placeholder="Postal Code" />
                </Form.Item>
              </Col>
          }
        </Row>
        {
          dataCandidate?.countryId === 99 || selectedCountries === 99 ?
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item 
                  name="village"
                  // initialValue={
                  //   valuesPersonal && valuesPersonal.village ?
                  //     valuesPersonal.village
                  //   : getPersonalInfo.data.villageName ?
                  //     getPersonalInfo.data.villageName
                  //   :
                  //     null
                  // }
                >
                  <Select 
                    showSearch
                    onChange={handleVillage}
                    placeholder="Village (Kelurahan)"
                  >
                    {
                      dataVillages.map(item => 
                        <Option key={item.id} value={item.name}>{item.name}</Option>
                        )
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item 
                  name="zipCode"
                  validateFirst
                  rules={[
                    { pattern: /^[0-9]*$/, message: 'Numeric only' },
                    { max: 10, message: '10 digits only' },
                  ]}
                  // initialValue={
                  //   valuesPersonal && valuesPersonal.zipCode ?
                  //     valuesPersonal.zipCode
                  //   : getPersonalInfo.data.zipCode ?
                  //     getPersonalInfo.data.zipCode
                  //   :
                  //     null
                  // }
                >
                  <Input placeholder="Postal Code" />
                </Form.Item>
              </Col>
            </Row>
          :
            null
        }
        <Row>
          <Col span={24}>
            <Form.Item 
              name="address"
              validateFirst
              rules={[
                { max: 75, message: '75 characters only' },
              ]}
              // initialValue={
              //   valuesPersonal && valuesPersonal.address ?
              //     valuesPersonal.address
              //   : getPersonalInfo.data.address ?
              //     getPersonalInfo.data.address
              //   :
              //     null
              // }
            >
              <Input placeholder="Street Address" />
            </Form.Item>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountCountries, unmountProvincesByCountries, unmountCitiesByProvinces, unmountSubDistrictsByCities, unmountVillagesBySubDistricts } = this.props
    return (unmountCountries(), unmountProvincesByCountries(), unmountCitiesByProvinces(), unmountSubDistrictsByCities(), unmountVillagesBySubDistricts())
  }
}

const mapStateToProps = (state) => ({
  getCountries        : state.countries.master,
  getProvinces        : state.provinces.master,
  getCities           : state.cities.master,
  getSubDistricts     : state.subDistricts.master,
  getVillages         : state.villages.master
})

const mapDispatchToProps = {
  actionGetCountries            : masterCountries,
  actionGetProvinces            : provinceByCountry,
  actionGetCities               : cityByProvince,
  actionGetSubDistricts         : subDistrictByCity,
  actionGetVillages             : villageBySubDistrict,
  unmountCountries              : unmountMasterCountries,
  unmountProvincesByCountries   : unmountListProvinces,
  unmountCitiesByProvinces      : unmountListCities,
  unmountSubDistrictsByCities   : unmountListSubDistricts,
  unmountVillagesBySubDistricts : unmountListVillages,
}

export default connect(mapStateToProps, mapDispatchToProps)(index)