import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Tooltip } from 'antd';
const { Text } = Typography;

export const columns = (onPriviewProfile) => {
  return [
    {
      title: 'Number',
      key: 'number',
      fixed: 'left',
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Tooltip title={record.applicantNumber ? record.applicantNumber : "N/A"}>
          <Link to="#" onClick={() => onPriviewProfile(record.candidateId)} style={{ color: '#0076de' }}>
            {record.applicantNumber ? record.applicantNumber : "N/A"}
          </Link>
        </Tooltip>
      )
    },
    {
      title: 'Name',
      key: 'name',
      fixed: 'left',
      width: 60,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Tooltip title={record.name ? record.name : "N/A"}>
          <Link to="#" onClick={() => onPriviewProfile(record.candidateId)} style={{ color: '#0076de' }}>
            {record.name ? record.name : "N/A"}
          </Link>
          <br />
          <Text>{record.age ? record.age : "N/A"}, {record.gender ? record.gender === "MALE" ? "Male" : "Female" : "N/A"}</Text>
        </Tooltip>
      )
    },
    {
      title: 'Last Education',
      key: 'lastEducation',
      width: 80,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Tooltip title={record.lastEducation ? record.lastEducation : "N/A"}>
          <Text>
            {record.lastEducation ? record.lastEducation : "N/A"}
          </Text>
        </Tooltip>
      )
    },
    {
      title: 'Institutions',
      key: 'institutions',
      width: 80,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Tooltip title={record.institution ? record.institution : "N/A"}>
          <Text>{record.institution ? record.institution : "N/A"}</Text>
        </Tooltip>
      )
    },
    {
      title: 'Length of Experience',
      key: 'lengthOfExperience',
      width: 80,
      render: (record) => (
        <Text>
          {record.lengthOfExperience ? record.lengthOfExperience : "N/A"}
        </Text>
      )
    }
  ]
}