import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { columns } from './columns';
import { connect } from 'react-redux';
import { FilterMasterData } from '../../../../components';
import { PageHeader, Row, Col, Breadcrumb, Table, Pagination, message } from 'antd';
import { listLanguageSkills, enableLanguageSkills, disableLanguageSkills, unmountListLanguageSkills } from '../../../../redux/actions/master/languageSkills/languageSkillsAction';
import { DatabaseOutlined } from '@ant-design/icons';
import Menu from '../Menu';

class MasterLanguageSkills extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      sortedInfo: null,
      meta: {
        page: 1,
        perpage: 10,
        field: null,
        sort: null,
        search: null,
        flagStatus: null,
        scope: null,
        section: null,
        code: null
      }
    }
    this.onSearch = debounce(this.onSearch.bind(this), 900)
  }

  componentDidMount() {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    return actionGetData(meta)
  }

  onSearch = value => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.search = value === '' ? null : value
    return actionGetData(meta)
  }

  onStatus = value => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.flagStatus = value
    return actionGetData(meta)
  }

  onScope = value => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.scope = value
    return actionGetData(meta)
  }

  onSection = value => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.section = value
    return actionGetData(meta)
  }

  onCode = value => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = 1
    meta.perpage = 10
    meta.code = value
    return actionGetData(meta)
  }

  pagination = (page, perpage) => {
    const { meta } = this.state;
    const { actionGetData } = this.props;
    meta.page = page
    meta.perpage = perpage
    return actionGetData(meta)
  }

  handleEnable = (value) => {
    const { meta } = this.state;
    const { actionEnable, actionGetData } = this.props;
    return actionEnable(value, () => {
      message.success('Successfully changed to active')
      return actionGetData(meta)
    }, (err) => message.error(err))
  }

  handleDisable = (value) => {
    const { meta } = this.state;
    const { actionDisable, actionGetData } = this.props;
    return actionDisable(value, () => {
      message.success('Successfully changed to inactive')
      return actionGetData(meta)
    }, (err) => message.error(err))
  }

  onClickEdit = (id) => {
    this.props.history.push(`/master/language-skills/edit/${id}`)
  }
  
  render() {
    const { getData: { loading, data, pagination } } = this.props;

    const defaultStatusFilter = [
      { value: null, name: 'All Status' },
      { value: true, name: 'Active' },
      { value: false, name: 'Inactive' },
    ]

    const defaultScopeFilter = [
      { value: null, name : 'All Scope'},
      { value: 'UNDERSTANDING', name: 'Understanding'},
      { value: 'SPEAKING', name: 'Speaking'},
      { value: 'WRITING', name: 'Writing'}
    ]

    const defaultSectionFilter = [
      { value: null, name: 'All Section'},
      { value: 'LISTENING', name: 'Listening'},
      { value: 'READING', name: 'Reading'},
      { value: 'SPOKEN_INTERACTION', name: 'Spoken Interaction'},
      { value: 'SPOKEN_PRODUCTION', name: 'Spoken Production'},
      { value: 'WRITING', name: 'Writing'}
    ]

    const defaultCodeFilter = [
      { value: null, name: 'All Level'},
      { value: 'A1', name: 'A1'},
      { value: 'A2', name: 'A2'},
      { value: 'B1', name: 'B1'},
      { value: 'B2', name: 'B2'},
      { value: 'C1', name: 'C1'},
      { value: 'C2', name: 'C2'},
    ]

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><DatabaseOutlined /> Master Data</Breadcrumb.Item>
              <Breadcrumb.Item>Master Data List Language Skills</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          { /* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Master Data List Language Skills' />
          </Col>
          { /* Filter */}
          <Col span={24}>
            <FilterMasterData 
              name="Create Language Skill" 
              link="/master/language-skills/create"
              placeholder="Search by Scope or Section"
              onSearch={this.onSearch} 
              onStatus={this.onStatus}
              onScope={this.onScope}
              onSection= {this.onSection}
              onCode={this.onCode}
              fase4={{ key: 'scope', value: defaultScopeFilter}}
              fase5={{ key: 'section', value: defaultSectionFilter}}
              fase6={{ key: 'code', value: defaultCodeFilter}}
              fase2={{ key: 'status', value: defaultStatusFilter}}
              isLanguageSkill={true}
            />
            
          </Col>
          { /* Menu Side Bar */}
          <Col span={6}>
            <Menu rowKey="languageSkills" />
          </Col>
          { /* Table */}
          <Col span={18}>
            <Row gutter={[16, 24]}>
              <Col span={24}>
                <Table 
                  size="small"
                  loading={loading} 
                  dataSource={data}
                  pagination={false} 
                  rowKey={(i) => i.id} 
                  columns={columns(this.onClickEdit, this.handleEnable, this.handleDisable)}
                />
              </Col>
              {/* Pagination */}
              <Col span={24}>
                <Pagination
                  total={pagination.total}
                  onChange={this.pagination}
                  current={pagination.page}
                  defaultPageSize={pagination.perpage}
                  showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total} Data`}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountGetData } = this.props;
    return unmountGetData()
  }
}

const mapStateToProps = (state) => ({
  getData: state.languageSkills.list,
})

const mapDispatchToProps = {
  actionGetData       : listLanguageSkills,
  actionEnable        : enableLanguageSkills,
  actionDisable       : disableLanguageSkills,
  unmountGetData      : unmountListLanguageSkills,
}

export default connect(mapStateToProps, mapDispatchToProps)(MasterLanguageSkills)
