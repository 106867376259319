import { API } from '../../../config';
import { errorHandler } from '../auth/errorAction';

export const unmountListClients = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_CLIENTS'})
}

export const unmountDetailClients = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_CLIENTS'})
}

export const unmountMasterClients = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_CLIENTS'})
}

export const listClients = (meta) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_CLIENTS' })
  return API.GET('/client/list', meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_CLIENTS_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_CLIENTS_FAILED' }), 
    ))
  })
}

export const detailClient = (id, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DETAIL_CLIENTS' })
  const params = {
    'id': id
  }
  API.GET('/client/getById', params).then((response) => {
    dispatch({ 
      type: 'LOAD_DETAIL_CLIENTS_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DETAIL_CLIENTS_FAILED' }), 
      ))
    )
  })
}

export const masterClients = (successCB, failedCB) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_CLIENTS' })
  return API.GET('/client/index').then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_CLIENTS_SUCCESS', 
      payload: { data: response.data }
    })
    return successCB && successCB(response.data)
  }).catch((err) => {
    failedCB && failedCB()
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_MASTER_CLIENTS_FAILED' }), 
    ))
  })
}

export const createClient = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA('/client/add', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const editClient = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA('/client/update', value).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const enableClient = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/client/enableClient', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const disableClient = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/client/disableClient', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}