import React, { Component } from 'react';
import { Skeleton, Form, Row, Col, Input, Select } from 'antd';
const { Option } = Select;

export default class EditAddress extends Component {

  render() {
    const { 
      getDetailCandidate: { data },
      formRef, 
      type,
      dataEdit,
      getCountry,
      dataProvince,
      dataCity,
      dataSubDistrict,
      dataVillage,
      onSubmitAddress,
      handleType,
      handleChangeCountry,
      handleChangeProvince,
      handleChangeCity,
      handleChangeSubDistrict
    } = this.props;

    if(getCountry.loading){
      return <Skeleton />
    }

    return (
      <React.Fragment>
        <Form layout="vertical" ref={formRef} onFinish={onSubmitAddress} scrollToFirstError={true}>
          <Row gutter={16}>
            {
              data.email.split("@")[1] === 'student.binawan.ac.id' ?
                <>
                  <Col span={12}>
                    <Form.Item 
                      label="Address Type" 
                      name="type"
                      rules={[
                        { required: true, message: 'This is a required field' }
                      ]}
                      initialValue={dataEdit?.type ? dataEdit?.type : null}
                    >
                      <Select 
                        placeholder="Jenis Alamat"
                        onChange={handleType}
                      >
                        <Option key="1" value="HOME">Home</Option>
                        <Option key="2" value="WORK">Work</Option>
                        <Option key="3" value="OTHER">Others</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    {
                      type === "OTHER" ?
                        <Form.Item 
                          label="Address"
                          name="typeOther"
                          rules={[
                            { required: true, message: 'This is a required field' }
                          ]}
                          initialValue={dataEdit?.typeOther ? dataEdit?.typeOther : null}
                        >
                          <Input  placeholder="e.g: School" />
                        </Form.Item>
                      : null
                    }
                  </Col>
                </>
              : null
            }
            <Col span={12}>
              <Form.Item 
                label="Country" 
                name="countryId" 
                rules={[
                  { required: true, message: 'This is a required field' },
                ]}
                initialValue={data.countryId ? data.countryId : dataEdit?.countryId ? dataEdit?.countryId : null}
              >
                <Select
                  showSearch
                  placeholder="Select a country"
                  optionFilterProp="children"
                  onChange={handleChangeCountry}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    getCountry?.data.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="Province" 
                name="provinceId"
                initialValue={data.provinceId ? data.provinceId : dataEdit?.provinceId ? dataEdit?.provinceId : null}
              >
                <Select
                  showSearch
                  placeholder="Select a province"
                  onChange={handleChangeProvince}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    dataProvince.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="City" 
                name="cityId"
                initialValue={data.cityId ? data.cityId : dataEdit?.cityId ? dataEdit?.cityId : null}
              >
                <Select
                  showSearch
                  placeholder="Select a city"
                  onChange={handleChangeCity}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    dataCity.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="Sub-district" 
                name="subDistrictId"
                initialValue={data.subDistrictId ? data.subDistrictId : dataEdit?.subDistrictId ? dataEdit?.subDistrictId : null}
              >
                <Select
                  showSearch
                  placeholder="Select a sub-district"
                  onChange={handleChangeSubDistrict}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    dataSubDistrict.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="Village" 
                name="villageId"
                initialValue={data.villageId ? data.villageId : dataEdit?.villageId ? dataEdit?.villageId : null}
              >
                <Select
                  showSearch
                  placeholder="Select a village"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    dataVillage.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="Postal Code" 
                name="zipCode"
                validateFirst
                initialValue={data.zipCode ? data.zipCode : dataEdit?.zipCode ? dataEdit?.zipCode : null}
                rules={[
                  { max: 11, message: 'Maximum postal code is 11 characters' },
                  { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers' },                
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item 
                label="Street Address" 
                name="address"
                initialValue={data.address ? data.address : dataEdit?.address ? dataEdit?.address : ''}
                rules={[
                  { max: 100, message: 'Address is too long. Maximum is 100 characters' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}
