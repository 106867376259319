import moment from 'moment';
import ReactQuill from 'react-quill';
import { BankOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Card, Col, Form, Radio, DatePicker, InputNumber, Input, message, Modal, Typography, PageHeader, Row, Select } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading } from '../../../../components';
import { approveCandidateRequest, detailCandidateRequest, editCandidateRequest, rejectCandidateRequest } from '../../../../redux/actions/candidateRequest/candidateRequestAction';
import { checkEmail, checkPhone } from '../../../../redux/actions/check/checkAction';
import { masterEmployersInquiries, unmountDetailEmployersInquiries, unmountMasterEmployersInquiries } from '../../../../redux/actions/employersInquiries/employersInquiriesAction';
import { cityByProvince } from '../../../../redux/actions/master/cities/citiesAction';
import { masterCountries, unmountMasterCountries } from '../../../../redux/actions/master/countries/countriesAction';
import { masterJobIndustries, unmountMasterJobIndustries } from '../../../../redux/actions/master/jobIndustries/jobIndustriesAction';
import { provinceByCountry } from '../../../../redux/actions/master/provinces/provincesAction';

class EditCandidateRequest extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
    
    this.state = {
      submitLoading: false,
      avatar: null,
      editAvatar: false,
      startDate: moment(),
      onPreviewAvatar: null,
      callingCode: "62",
      dataProvince: [],
      dataCity: [],
      visible: false,
      statusSubmit: null,
      isFixSalary: true
    }
  }

  componentDidMount() {
    const { 
      actionGetCountry, 
      actionGetDetail, 
      match: { params },
      history
    } = this.props;
    actionGetDetail(params.id, (response) => {
      if(response.data.status === 'APPROVE'){
          return history.push('/candidate-request')
      }else{
        this.setState({ 
          callingCode: Number(response.data.callingCode),
          isFixSalary: response.data.isFixSalary 
        })
      }
    })
    actionGetCountry()
  }

  checkEmail = (value) => {
    const { actionCheckEmail, getData: { data } } = this.props;
    const oldEmail = data.email
    if(oldEmail === value){
      return null
    }else{
      return actionCheckEmail(value, (response) => {
        if(response){
          message.error('Email already exist')
          this.formRef.current.setFieldsValue({
            email: data.email,
          });
        }
      })
    }
  }

  checkPhone = (value) => {
    const { callingCode } = this.state;
    const { actionCheckPhone, getData: { data } } = this.props;
    const valuePhone = `${callingCode}${value.replace(/^0+/, '')}`
    const oldMobilePhone = `${data.callingCode}${data.mobilePhone}`
    this.formRef.current.setFieldsValue({
      mobilePhone: value.replace(/^0+/, ''),
    });
    if(oldMobilePhone === valuePhone){
      return null
    }else{
      return actionCheckPhone(valuePhone.replace(/^0+/, ''), (response) => {
        if(response){
          message.error('Mobile phone already exist')
          this.formRef.current.setFieldsValue({
            mobilePhone: data.mobilePhone,
          });
        }
      })
    }
  }

  handleChangeFixSalary = (value) => {
    this.setState({ isFixSalary: value })
    this.formRef.current.setFieldsValue({
      salary: null,
      maxSalary: null
    });
  }

  handleChangeCallingCode = (_, index) => {
    this.setState({ callingCode: index.children.replace(/^[+]+/, '') })
    this.formRef.current.setFieldsValue({
      mobilePhone: null
    });
  }
  
  handleChangeCountry = (value) => {
    const { actionGetProvince } = this.props;
    this.formRef.current.setFieldsValue({
      provinceId: null,
      cityId: null,
    });
    return actionGetProvince(value, (response) => {
      this.setState({ dataProvince: response.data })
    })
  }

  handleChangeProvince = (value) => {
    const { actionGetCity } = this.props;
    this.formRef.current.setFieldsValue({
      cityId: null
    });
    if(value !== undefined){
      return actionGetCity(value, (response) => {
        this.setState({ dataCity: response.data })
      })
    }else {
      this.setState({ dataCity: [] })
    }
  }

  handleClick = (type) => {
    this.setState({ statusSubmit: type })
  }

  disabledDate = (current) => {
    let yesterday = moment().subtract(1, 'days');
    return !current || current.isBefore(yesterday);
  }

  disabledEndDate = (current) => {
    const { startDate } = this.state;
    return current < startDate
  }

  onChangeStartDate = (current) => {
    this.formRef.current.setFieldsValue({
      targetDate: null,
    });
    this.setState({ startDate: current })
  }

  onFinish = (values) => {
    const { callingCode, statusSubmit } = this.state;
    const { actionUpdate, history, getData: { data } } = this.props;
    values.mobilePhone = `${callingCode}${values.mobilePhone.replace(/^0+/, '')}`
    values.candidateRecommendationNumber = data.candidateRecommendationNumber
    values.dateOpened = values.dateOpened ? moment(values.dateOpened).format('DD/MM/YYYY') : null
    values.targetDate = values.targetDate ? moment(values.targetDate).format('DD/MM/YYYY') : null
    values.id = data.id
    values.status = statusSubmit
    return this.setState({ submitLoading: true }, () => {
      return actionUpdate(values, (response) => {
        if(response.code === '1000'){
          this.setState({ submitLoading: false }, () => {
            message.success(statusSubmit === 'APPROVE' ? 'Candidate Request has been approve' : 'Data updated successfully')
            history.push('/candidate-request')
          })
        }else{
          this.setState({ submitLoading: false }, () => {
            message.error(response.message)
          })
        }
      }, (err) => {
        this.setState({ submitLoading: false }, () => message.error(err))
      })
    })
  }

  handleReject = () => {
    this.setState({ visible: true })
  }

  handleCancel = () => {
    this.setState({ visible: false })
  }

  handleOk = () => {
    const { actionReject, history, getData: { data } } = this.props;
    this.formRef.current.validateFields().then(values => {
      values.id = data.id
      return actionReject(values, () => {
        message.success('Candidate Request has been rejected')
        this.setState({ visible: false }, () => {
          history.push('/candidate-request')
        })
      }, (err) => {
        return message.error(err)
      })
    })
  }
  
  render() {
    const { submitLoading, visible, isFixSalary } = this.state;
    const { getCountry, getData: { data, loading } } = this.props;

    if(loading || getCountry.loading){
      return <Loading />
    }

    const mobileNumber = (
      <Form.Item 
        noStyle
        name="callingCodeId" 
        initialValue={data.callingCodeId ? data.callingCodeId : 99} 
        rules={[ {required: true, message: 'Please input your calling code'} ]}
      >
        <Select 
          showSearch
          className="prefix-selector"
          optionFilterProp="children"
          onChange={this.handleChangeCallingCode}
        >
          {
            getCountry?.data.map(item => (
              <Select.Option key={item.id} value={item.id}>
                {`+${item.callingCode}`}
              </Select.Option>
            ))
          }
        </Select>
      </Form.Item>
    );

    return (
      <React.Fragment>
        <Form layout="vertical" ref={this.formRef} onFinish={this.onFinish} scrollToFirstError={true}>
          <Row style={{ position: 'sticky', zIndex: 1, width: '100%', top: 62, right: 0, marginTop: -20, marginBottom: 8, paddingBottom: 12, paddingTop: 16, backgroundColor: '#f2f2f2' }}>
            {/* Breadcrumb */}
            <Col span={24}>
              <Breadcrumb>
                <Breadcrumb.Item><BankOutlined /> Requests</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link className="link" to="/candidate-request">Request for Candidate List</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{data?.candidateRecommendationNumber || '-'}</Breadcrumb.Item>
                <Breadcrumb.Item>Approval</Breadcrumb.Item>
              </Breadcrumb>
            </Col>

            {/* Page Header */}
            <Col span={24}>
              <PageHeader 
                title="Request Approval" 
                className="site-page-header"
                extra={[
                  <Button key="3" className="btn-cancel-danger" onClick={() => this.handleReject()}>Reject</Button>,
                  <Button key="2" className="btn-edit-candidate" htmlType="submit" loading={submitLoading} onClick={() => this.handleClick('FOLLOW_UP')} >Save</Button>,
                  <Button key="1" style={{ width: 'auto' }} className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading} onClick={() => this.handleClick('APPROVE')}>Approve</Button>,
                ]}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            {/* Form */}
            <Col span={24}>
              <Card>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item 
                      label="Contact Person" 
                      name="name"
                      validateFirst
                      initialValue={data?.name ? data.name : ''}
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 3, message: 'Name must be at least 3 characters long' },               
                        { max: 50, message: 'Contact Person is too long. Maximum is 50 characters' },
                        { pattern: new RegExp("^[a-zA-Z ]+$"), message: 'Special Characters and Number are not allowed' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Contact Person Email" 
                      name="email" 
                      validateFirst
                      initialValue={data?.email ? data.email : ''}
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { type: 'email', message: 'The input is not valid E-mail' }, 
                      ]}
                    >
                      <Input type="email" onBlur={(e) => this.checkEmail(e.target.value)} />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Contact Person Mobile Number" 
                      name="mobilePhone"
                      validateFirst
                      initialValue={data?.mobilePhone ? data.mobilePhone : ''}
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 4, message: 'Your mobile number is invalid' },
                        { max: 13, message: 'Your mobile number is invalid' },
                        { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers!' },                
                      ]}
                    >
                      <Input addonBefore={mobileNumber} onBlur={(e) => this.checkPhone(e.target.value)} />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Company Name" 
                      name="companyName"
                      validateFirst
                      initialValue={data?.companyName ? data.companyName : ''}
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 3, message: 'Name must be at least 3 characters long' },               
                        { max: 50, message: 'Company Name is too long. Maximum is 50 characters' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Looking for role" 
                      name="role"
                      validateFirst
                      initialValue={data?.role ? data.role : ''}
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 3, message: 'Name must be at least 3 characters long' },               
                        { max: 100, message: 'Maximum is 100 characters' },
                        { pattern: new RegExp(/^([^0-9]*)$/), message: 'Number are not allowed' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Company Location" 
                      name="companyLocation"
                      validateFirst
                      initialValue={data?.companyLocation ? data.companyLocation : ''}
                      rules={[
                        { max: 50, message: 'Company Location is too long. Maximum is 50 characters' },
                        { pattern: new RegExp(/^([^0-9]*)$/), message: 'Number are not allowed' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Number of hired needed" 
                      name="numberHired" 
                      validateFirst
                      initialValue={data.numberHired ? data.numberHired : ''}
                      rules={[
                        // { max: 3, message: '3 digits only' },
                        { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers' },  
                      ]}
                    >
                      <InputNumber maxLength={3}/>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Urgency" 
                      name="urgentType"
                      initialValue={data.urgentType ? data.urgentType : null}
                    >
                      <Select placeholder='Select a type'>
                        <Select.Option value="URGENT">Need to provide right away</Select.Option>
                        <Select.Option value="NEXT_MONTH">Next Month</Select.Option>
                        <Select.Option value="TALENT_POOL">For talent pool only</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Date Opened"
                      name="dateOpened"
                      initialValue={ data.dateOpened ? moment(data.dateOpened, 'DD/MM/YYYY') : null}
                      // rules={[
                      //   { required: true, message: 'This is a required field' },
                      // ]}
                    >
                      <DatePicker 
                        style={{ width: '100%' }} 
                        format={'DD/MM/YYYY'}
                        disabledDate={this.disabledDate} 
                        onChange={this.onChangeStartDate}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Close Application Date"
                      name="targetDate"
                      initialValue={data.targetDate ? moment(data.targetDate, 'DD/MM/YYYY') : null}
                      // rules={[
                      //   { required: true, message: 'This is a required field' },
                      // ]}
                    >
                      <DatePicker 
                        format={'DD/MM/YYYY'}
                        style={{ width: '100%' }} 
                        disabledDate={this.disabledEndDate} 
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Job Type" 
                      name="jobType"
                      initialValue={data.jobType ? data.jobType : null}
                      // rules={[
                      //   { required: true, message: 'This is a required field' },
                      // ]}
                    >
                      <Select placeholder='Select a type'>
                        <Select.Option value="FULL_TIME">Full Time</Select.Option>
                        <Select.Option value="PART_TIME">Part Time</Select.Option>
                        <Select.Option value="FREELANCE">Freelance</Select.Option>
                        <Select.Option value="INTERNSHIP">Internship</Select.Option>
                        <Select.Option value="TALENT_POOL">Talent Pool</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Job Placement" 
                      name="jobPlacement"
                      validateFirst
                      initialValue={data?.jobPlacement ? data.jobPlacement : ''}
                      rules={[
                        { max: 50, message: 'Job Placement is too long. Maximum is 50 characters' },
                      ]}
                    >
                      <Input placeholder="Job Placement"/>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Is it possible to work from home (remote)?" 
                      name="isRemote" 
                      initialValue={data.isRemote}
                      // rules={[
                      //   { required: true, message: 'This is a required field' },
                      // ]}
                    >
                      <Radio.Group>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Experience Level" 
                      name="experienceLevel"
                      initialValue={data.experienceLevel ? data.experienceLevel : null}
                      // rules={[
                      //   { required: true, message: 'This is a required field' },
                      // ]}
                    >
                      <Select placeholder='Select a level'>
                        <Select.Option value="ENTRY_LEVEL">Entry Level</Select.Option>
                        <Select.Option value="ASSOCIATE">Associate</Select.Option>
                        <Select.Option value="MID_SENIOR">Mid-Senior Level</Select.Option>
                        <Select.Option value="DIRECTOR">Director</Select.Option>
                        <Select.Option value="EXECUTIVE">Executive</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Payroll Currency" 
                      name="currencyId"
                      initialValue={data.currencyId ? data.currencyId : null}
                      // rules={[
                      //   { required: isDisplaySalary, message: 'This is a required field' }
                      // ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select a currency"
                        optionFilterProp="children"
                      >
                        {
                          getCountry?.data.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.currencyCode} - {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Payment Type" 
                      name="paymentType"
                      initialValue={data.paymentType ? data.paymentType : null}
                      // rules={[
                      //   { required: isDisplaySalary, message: 'This is a required field' }
                      // ]}
                    >
                      <Select placeholder='Select a payment type'>
                        <Select.Option value="DAILY">Per Day</Select.Option>
                        <Select.Option value="WEEKLY">Per Week</Select.Option>
                        <Select.Option value="MONTHLY">Per Month</Select.Option>
                        <Select.Option value="YEARLY">Per Year</Select.Option>
                        <Select.Option value="PROJECT">Per Project</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Salary Type" 
                      name="isFixSalary" 
                      initialValue={data.isFixSalary}
                      // rules={[
                      //   { required: isDisplaySalary, message: 'This is a required field' }
                      // ]}
                    >
                      <Radio.Group onChange={(e) => this.handleChangeFixSalary(e.target.value)}>
                        <Radio value={true}>Fix Salary</Radio>
                        <Radio value={false}>Range</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>

                  {
                    isFixSalary ?
                    <Col span={12}>
                      <Form.Item 
                        label="Salary" 
                        name="salary" 
                        validateFirst
                        initialValue={data.salary ? data.salary : null}
                        // rules={[
                        //   { required: isDisplaySalary, message: 'This is a required field' },
                        // ]}
                      >
                        <InputNumber
                          maxLength={12}
                          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        />
                      </Form.Item>
                    </Col>
                    :
                    <React.Fragment>
                      <Col span={6}>
                        <Form.Item 
                          label="Min Salary" 
                          name="salary" 
                          initialValue={data.salary ? data.salary : null}
                          // rules={[
                          //   { required: isDisplaySalary, message: 'This is a required field' },
                          // ]}
                        >
                          <InputNumber
                            maxLength={12}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={6}>
                        <Form.Item 
                          label="Max Salary" 
                          name="maxSalary" 
                          initialValue={data.maxSalary ? data.maxSalary : null}
                          // rules={[
                          //   { required: isDisplaySalary, message: 'This is a required field' },
                          // ]}
                        >
                          <InputNumber
                            maxLength={12}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                          />
                        </Form.Item>
                      </Col>
                    </React.Fragment>
                  }

                  <Col span={24}>
                    <Form.Item 
                      label="Notes" 
                      name="note"
                      initialValue={data.note ? data.note : null}
                      rules={[
                        // { required: true, message: 'This is a required field' },
                        { max: 100, message: '100 characters only' },              
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item 
                      label="Hiring Needs Description" 
                      name="hiringNote" 
                      initialValue={data.hiringNote ? data.hiringNote : null}
                      // tooltip={{ 
                      //   title: 'Input job roles and responsibilities', 
                      //   icon: <InfoCircleOutlined style={{ color: '#2586eb' }} />
                      // }}
                      rules={[
                        { max: 250, message: '250 characters only' },
                      ]}
                    >
                      <ReactQuill placeholder="Hiring Needs Description" />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item 
                      label="Job Description" 
                      name="jobDescription" 
                      initialValue={data.jobDescription ? data.jobDescription : null}
                      // tooltip={{ 
                      //   title: 'Input job roles and responsibilities', 
                      //   icon: <InfoCircleOutlined style={{ color: '#2586eb' }} />
                      // }}
                      rules={[
                        { max: 8000, message: '8,000 characters only' },
                      ]}
                    >
                      <ReactQuill placeholder="Job Description" />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item 
                      label="Candidate Requirements" 
                      name="jobRequirement" 
                      initialValue={data.jobRequirement ? data.jobRequirement : null}
                      // tooltip={{ 
                      //   title: 'Input job roles and responsibilities', 
                      //   icon: <InfoCircleOutlined style={{ color: '#2586eb' }} />
                      // }}
                      rules={[
                        { max: 8000, message: '8,000 characters only' },
                      ]}
                    >
                      <ReactQuill placeholder="Candidate Requirements" />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item 
                      label="Benefits" 
                      name="benefit" 
                      initialValue={data.benefit ? data.benefit : null}
                      // tooltip={{ 
                      //   title: 'Input company benefits details', 
                      //   icon: <InfoCircleOutlined style={{ color: '#2586eb' }} />
                      // }}
                      rules={[
                        { max: 8000, message: '8,000 characters only' },
                      ]}
                    >
                      <ReactQuill placeholder="Benefits" />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
        <Modal 
          visible={visible} 
          onOk={() => this.handleOk()} 
          onCancel={() => this.handleCancel()}
        >
          <Form 
            onFinish={() => this.handleOk()} 
            layout="vertical"
            ref={this.formRef} 
          >
            <Col span={24} style={{ marginBottom: 30 }}>
              <Typography.Text style={{ fontSize: 18 }}>
                This request will be rejected. Are you sure?
              </Typography.Text>
            </Col>
            <Col span={24}>
              <Form.Item 
                label="Please state your reason" 
                name="rejectReason"
                rules={[
                  { required: true, message: 'This is a required field' },
                ]}
              >
                <Input.TextArea rows={4} />
              </Form.Item>
            </Col>
          </Form>
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail, unmountGetCountry, unmountGetEmployer, unmountGetJobFunction } = this.props;
    unmountGetDetail()
    unmountGetCountry()
    unmountGetEmployer()
    unmountGetJobFunction()
  }
}

const mapStateToProps = (state) => ({
  getData         : state.candidateRequest.detail,
  getJobFunction  : state.jobIndustries.master,
  getCountry      : state.countries.master,
  getEmployer     : state.employersInquiries.master
})

const mapDispatchToProps = {
  actionUpdate          : editCandidateRequest,
  actionApprove         : approveCandidateRequest,
  actionGetDetail       : detailCandidateRequest,
  actionCheckPhone      : checkPhone,
  actionCheckEmail      : checkEmail,
  actionGetJobFunction  : masterJobIndustries,
  actionGetCountry      : masterCountries,
  actionGetProvince     : provinceByCountry,
  actionGetCity         : cityByProvince,
  actionGetEmployer     : masterEmployersInquiries,
  actionReject          : rejectCandidateRequest,
  unmountGetDetail      : unmountDetailEmployersInquiries,
  unmountGetCountry     : unmountMasterCountries,
  unmountGetEmployer    : unmountMasterEmployersInquiries,
  unmountGetJobFunction : unmountMasterJobIndustries
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCandidateRequest)
