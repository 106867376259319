import React, { Component } from 'react';
import { Form, Row, Col, Input, DatePicker, Typography, Checkbox } from 'antd';
const { Text } = Typography;

export default class AddTraining extends Component {
  render() {
    const { 
      isExp,
      isDate,
      formRef, 
      onSubmitAdd, 
      handleChecked,
      onChangeExpDate,
      onChangeIssueDate,
    } = this.props;

    return (
      <React.Fragment>
        <Form ref={formRef} onFinish={onSubmitAdd} layout="vertical">
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item 
                label="Name"
                name="name"
                validateFirst
                initialValue={null}
                rules={[
                  { max: 50, message: '50 characters only' },
                  { min: 5, message: 'Name must be at least 5 characters' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item 
                label="Issuing Organization"
                name="organization"
                validateFirst
                initialValue={null}
                rules={[
                  { max: 50, message: '50 characters only' },
                  { min: 5, message: 'Organization must be at least 5 characters' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Issue Date" name="issueDate" initialValue={null}>
                <DatePicker 
                  picker="month" 
                  placeholder="YYYY-MM" 
                  style={{ width: '100%' }}
                  onChange={onChangeIssueDate} 
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="Expiration Date"
                name="expDate"
                initialValue={null}
                validateStatus={isDate && !isExp ? "error" : null}
                help={isDate && !isExp ? "Your expiration date can't be earlier than your issue date" : null}
              >
                {
                  isExp ?
                  <Text type="secondary">No Expiration Date</Text>
                  :
                  <DatePicker 
                    picker="month" 
                    placeholder="YYYY-MM" 
                    style={{ width: '100%' }}
                    onChange={onChangeExpDate} 
                  />
                }
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item>
                <Checkbox onChange={handleChecked} checked={isExp}>This credential does not expire</Checkbox>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item 
                label="Credential ID"
                name="credentialId"
                initialValue={null}
                rules={[
                  { max: 50, message: '50 characters only' }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item 
                label="Credential URL"
                name="credentialUrl"
                initialValue={null}
                rules={[
                  { max: 100, message: '100 characters only' }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}
