import React, { Component } from 'react';
import { Form, Row, Col, Select, Input, Button, List, Space, Typography } from 'antd';
import { LinkedinFilled, InstagramFilled, FacebookFilled, TwitterOutlined, MediumOutlined, DeleteTwoTone } from '@ant-design/icons';
const { Text } = Typography;

export default class EditSocial extends Component {

  render() {
    const { 
      getDetailCandidate: { data },
      url,
      name,
      formRef,
      socialMedia,
      onSubmitSocial,
      onChangeSelect,
      handleDelete,
      onChangeInput
    } = this.props;

    return (
      <React.Fragment>
        <Form layout="vertical" onFinish={onSubmitSocial} ref={formRef}>
          <Row gutter={16}>
            <Col span={5}>
              <Form.Item name="name">
                <Select placeholder="Choose" onChange={(e) => onChangeSelect(e)}>
                  <Select.Option key="1" value="linkedin"><LinkedinFilled /> Linkedin</Select.Option>
                  <Select.Option key="2" value="facebook"><FacebookFilled /> Facebook</Select.Option>
                  <Select.Option key="3" value="twitter"><TwitterOutlined /> Twitter</Select.Option>
                  <Select.Option key="4" value="instagram"><InstagramFilled /> Instagram</Select.Option>
                  <Select.Option key="5" value="medium"><MediumOutlined /> Medium</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={15}>
              <Form.Item 
                name="url"
                validateFirst
                rules={[
                  { pattern: /^\S*$/, message: 'No spaces allowed' },
                  { max: 50, message: '50 characters only' },
                ]}
              >
                <Input 
                  addonBefore={name !== 'medium' ? socialMedia : null} 
                  addonAfter={name === 'medium' ? '.medium.com' : null}
                  placeholder={socialMedia === 'linkedin' || socialMedia === 'medium' ? 'profile' : 'username'} 
                  onChange={(e) => onChangeInput(e.target.value)} 
                  disabled={!name} 
                />
              </Form.Item>
            </Col>
            <Col span={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button className="btn-save-primary" htmlType="submit" type="primary" disabled={!name || !url}>Add</Button>
            </Col>
            <Col span={24}>
              <List
                size="small"
                bordered={false}
                dataSource={data.listSocialMedia}
                renderItem={(item, index) => (
                  <List.Item
                    className="txt-color"
                    actions={[<DeleteTwoTone className="c-pointer" twoToneColor="#ff4646" onClick={() => handleDelete(item.id)} />]}
                  >
                    <Space>
                      <Text>{index+1}.</Text>
                      <a href={`https://${item.url}`} target="_blank" rel='noreferrer' style={{ color: '#0076de' }}>
                        {item.name}: {item.url}
                      </a>
                    </Space>
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}
