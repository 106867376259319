import React, { Component } from 'react';
import moment from 'moment';
import { columns } from './columns';
import { connect } from 'react-redux';
import { ExportOutlined } from '@ant-design/icons';
import { download } from '../../../../utils/document';
import { Row, Col, Radio, PageHeader, Button, Table, Pagination, Space, DatePicker } from 'antd';
import { downloadHiringPipeline, listReportHiringPipeline, unmountListReportHiringPipeline } from '../../../../redux/actions/report/reportAction';
const { RangePicker } = DatePicker;

class ReportHirringPipelineEmployer extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      current: 'hirringPipeline',
      meta: {
        page: 1,
        perpage: 10,
        field: null,
        sort: null,
        search: null,
        startDate: moment().clone().startOf('month').format('DD-MM-YYYY'),
        endDate: moment().clone().endOf('month').format('DD-MM-YYYY')
      }
    }
  }

  componentDidMount() {
    const { actionGetData } = this.props;
    const { meta } = this.state;
    return actionGetData(meta, 'report_employers')
  }

  pagination = (page, perpage) => {
    const { actionGetData } = this.props;
    const { meta } = this.state;
    meta.page = page
    meta.perpage = perpage
    return actionGetData(meta, 'report_employers')
  }

  handleMenu = e => {
    const { history } = this.props
    switch (e.target.value) {
      case 'applicantSummary':
        return history.push("/employer/report/applicant-summary")
      case 'hirringPipeline':
        return history.push("/employer/report/hirring-pipeline")
      case 'jobOverview':
        return history.push("/employer/report/job-overview")
      default:
        return null
    }
  }

  handleRangePicker = (dates, dateStrings) => {
    const { actionGetData } = this.props;
    const { meta } = this.state;
    meta.startDate = dateStrings[0]
    meta.endDate = dateStrings[1]
    return actionGetData(meta, 'report_employers')
  }

  handleDownload = () => {
    const { actionDownload } = this.props;
    const { meta } = this.state;
    const params = {
      startDate: meta.startDate,
      endDate: meta.endDate
    }
    return actionDownload(params, 'report_employers', (response) => {
      return download(response, 'Hiring Pipeline.xls')
    })
  }
  
  render() {
    const dateFormat = 'DD-MM-YYYY';
    const { current, meta: { startDate, endDate } } = this.state;
    const { getData: { loading, data, pagination } } = this.props;
    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title="Report" />
          </Col>
          {/* Menu */}
          <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <React.Fragment>
              <Radio.Group onChange={this.handleMenu} defaultValue={current} buttonStyle="solid">
                <Radio.Button value="applicantSummary">Applicant Summary</Radio.Button>
                <Radio.Button value="hirringPipeline">Hiring Pipeline</Radio.Button>
                <Radio.Button value="jobOverview">Job Overview</Radio.Button>
              </Radio.Group>
            </React.Fragment>
            <React.Fragment>
              <Space>
                <RangePicker
                  format={dateFormat}
                  onChange={this.handleRangePicker}
                  defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                />
                <Button 
                  className="btn-create-primary" 
                  type="primary" 
                  icon={<ExportOutlined />} 
                  onClick={this.handleDownload}
                  ghost
                >
                  Export to excel
                </Button>
              </Space>
            </React.Fragment>
          </Col>
          {/* Table */}
          <Col span={24}>
            <Table 
              size="small"
              rowKey={(i) => i.id}
              dataSource={data} 
              columns={columns()} 
              pagination={false} 
              loading={loading}
            />
          </Col>
          {/* Pagination */}
          <Col span={24}>
            <Pagination
              total={pagination.total}
              onChange={this.pagination}
              current={pagination.page}
              pageSize={pagination.perpage}
              showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total} Data`}
            />
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  getData: state.report.hiringPipeline
})

const mapDispatchToProps = {
  actionDownload  : downloadHiringPipeline,
  actionGetData   : listReportHiringPipeline,
  unmountGetData  : unmountListReportHiringPipeline
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportHirringPipelineEmployer)
