import jsPDF from 'jspdf';
import KTM from '../assets/img/KTM.png';
import BlankImage from '../assets/img/blank.png';
import { message } from 'antd';

export const downloadKTM = (data) => {
  const dataEducation = data?.listEducation?.find(item => item.name.includes('binawan') || item.name.includes('Binawan') || item.name.includes('BINAWAN'))
  if(dataEducation && dataEducation?.fieldOfStudy && dataEducation?.startYear && dataEducation?.endYear){
    const doc = new jsPDF('l','px', [536,357],true)

    doc.addImage(KTM, 'PNG', 0, 0, 536, 357)
    doc.setDrawColor(255, 255, 255)
    doc.setFillColor(255, 255, 255)
    doc.rect(48, 131, 138, 138, 'F')
    doc.setFontSize(26)
    doc.setFont('helvetica','','bold')
    doc.text(50, 130, 'KARTU TANDA MAHASISWA')
    // doc.addImage(data.profilePicUrl ? data.profilePicUrl : BlankImage, 'PNG', 48, 131, 138, 138)
    doc.addImage(data.profilePicUrl ? data.profilePicUrl : BlankImage, 'PNG', 50, 158, 110, 110)
    
    doc.setFontSize(23)
    doc.setFont('helvetica','','bold')
    doc.text(170, 170, data.fullName)

    doc.setFontSize(23)
    doc.setFont('helvetica','','bold')
    doc.setTextColor(202, 167, 47)
    doc.text(170, 205, 'PROGRAM STUDY')
    doc.text(170, 222, dataEducation?.fieldOfStudy ? dataEducation?.fieldOfStudy : '..............................')

    doc.setTextColor(0)
    doc.setFontSize(9)
    doc.setFont('helvetica','','bold')
    doc.text(170, 250, 'MEMBER')
    doc.text(170, 257, 'SINCE')
    doc.setFontSize(23)
    doc.text(205, 257, dataEducation?.startYear ? `01/08/${dataEducation?.startYear?.substring(2)}` : '..........')

    doc.setTextColor(0)
    doc.setFontSize(9)
    doc.setFont('helvetica','','bold')
    doc.text(290, 250, 'VALID')
    doc.text(290, 257, 'THRU')
    doc.setFontSize(23)
    doc.text(315, 257, dataEducation?.endYear ?`01/08/${dataEducation?.endYear?.substring(2)}` : '..........')

    doc.save(`${data.candidateNumber} - Kartu Tanda Mahasiswa.pdf`);
    message.success('Download success!')
  }else{
    message.error('Data is not complete. Please contact the candidate!')
  }
}