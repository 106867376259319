import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Typography, Dropdown, Menu, Tag } from 'antd';
import { EditOutlined, StopOutlined, CheckCircleOutlined, UnorderedListOutlined, EyeOutlined, InfoCircleOutlined } from '@ant-design/icons';
const { Text } = Typography;

const menuReason = (record, onClickReason) => (
  <Menu>
    <Menu.Item onClick={(e) => {e.domEvent.stopPropagation(); onClickReason(record)}} icon={<EyeOutlined />}>
      View Reason
    </Menu.Item>
  </Menu>
)

const menu = (record, onClickEdit, handleInactive, handleActive) => (
  <Menu>
    {
      record.status === "ACTIVE" &&
      <Menu.Item icon={<StopOutlined />} onClick={(e) => {e.domEvent.stopPropagation(); handleInactive(record.id)}}>
        Set as inactive
      </Menu.Item>
    }
    {
      record.status === "INACTIVE" &&
      <Menu.Item icon={<CheckCircleOutlined />} onClick={(e) => {e.domEvent.stopPropagation(); handleActive(record.id)}}>
        Set as active
      </Menu.Item>
    }
    <Menu.Item icon={<EditOutlined />} onClick={(e) => {e.domEvent.stopPropagation(); onClickEdit(record.id)}}>
      Edit                              
    </Menu.Item>
  </Menu>
);

export const columns = (onClickEdit, onClickReason, handleInactive, handleActive, handleRoute) => {
  return [
    {
      title: 'Number',
      key: 'number',
      fixed: 'left',
      width: 80,
      render: (record) => (
        <Link className="link" to={`/agency/jobs/detail/${record.id}/information/0`}>
          {record.jobNumber}
        </Link>
      )
    },
    {
      title: 'Title',
      key: 'title',
      width: 150,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.title}
        </Text>
      )
    },
    {
      title: 'Close Application Date',
      key: 'targetDate',
      width: 100,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.targetDate ? moment(record.targetDate, 'DD/MM/YYYY').format("DD MMMM YYYY") : '-'}
        </Text>
      )
    },
    {
      title: 'Job Position',
      key: 'job Position',
      width: 100,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.jobPosition ? record.jobPosition : '-'}
        </Text>
      )
    },
    {
      title: 'Number of Position',
      key: 'numberOfPosition',
      align: 'center',
      width: 80,
      render: (record) => (
        <Text onClick={() => handleRoute(record)}>
          {record.numberOfPosition ? record.numberOfPosition : '-'}
        </Text>
      )
    },
    {
      title: 'Status',
      key: 'status',
      width: 100,
      render: (record) => (
        <React.Fragment>
          {record.status === 'REQUEST' ?  <Tag color="blue">Waiting for approval</Tag> : null}
          {record.status === 'DRAFT' ? <Tag> Draft</Tag> : null}
          {record.status === 'ACTIVE' ? <Tag color="green"> Active</Tag> : null}
          {record.status === 'INACTIVE' ? <Tag color="orange"> Inactive</Tag> : null}
          {
            record.status === 'REJECT' ? 
            <Dropdown overlay={menuReason(record, onClickReason)} placement="bottomCenter" arrow>
              <Link to="#" onClick={e => e.preventDefault()} style={{ color: 'red', textDecoration: 'underline' }}>
                Rejected <InfoCircleOutlined style={{ fontSize: 12 }} /> 
              </Link>
            </Dropdown>
            : 
            null
          }
        </React.Fragment>
      )
    },
    {
      title: '',
      key: 'action',
      width: 30,
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <Dropdown overlay={menu(record, onClickEdit, handleInactive, handleActive)} trigger={['click']} placement="bottomCenter" arrow>
          <UnorderedListOutlined style={{ fontSize: 20 }} />
        </Dropdown>
      )
    },
  ]
}