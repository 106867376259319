import { API } from '../../../config';
import { errorHandler } from '../auth/errorAction';

export const unmountListEvent = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_EVENT'})
}

export const unmountDetailEvent = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DETAIL_EVENT'})
}

export const unmountEditEvent = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_EDIT_EVENT'})
}

export const unmountMasterEvent = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_MASTER_EVENT'})
}

export const listEvent = (meta) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_EVENT' })
  return API.GET('/event/list', meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_EVENT_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_EVENT_FAILED' }), 
    ))
  })
}

export const detailEvent = (id, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_DETAIL_EVENT' })
  const params = {
    'id': id
  }
  API.GET('/event/getDetailById', params).then((response) => {
    dispatch({ 
      type: 'LOAD_DETAIL_EVENT_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_DETAIL_EVENT_FAILED' }), 
      ))
    )
  })
}

export const editEvent = (id, successCB, failedCB) => async dispatch => {
  await dispatch({ type: 'LOAD_EDIT_EVENT' })
  const params = {
    'id': id
  }
  API.GET('/event/getById', params).then((response) => {
    dispatch({ 
      type: 'LOAD_EDIT_EVENT_SUCCESS', 
      payload : { 
        data: response.data 
      } 
    })
    return successCB && successCB(response)
  }).catch((err) => {
    return (
      failedCB && failedCB(err),
      dispatch(errorHandler(
        err, 
        dispatch({ type: 'LOAD_EDIT_EVENT_FAILED' }), 
      ))
    )
  })
}

export const masterEvent = (successCB, failedCB) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_MASTER_EVENT' })
  return API.GET('/employers_type/index').then((response) => {
    dispatch({
      type: 'LOAD_DATA_MASTER_EVENT_SUCCESS', 
      payload: {
        data: response.data,
        pagination: {
          page: null,
          total: null,
          perpage: null
        }
    }})
    return successCB && successCB(response.data)
  }).catch((err) => {
    failedCB && failedCB()
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_MASTER_EVENT_FAILED' }), 
    ))
  })
}

export const createEvent = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA_ARRAY('/event/add', value, 'createEvent').then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const updateEvent = (value, successCB, failedCB) => () => {
  API.POST_FORM_DATA_ARRAY('/event/update', value, 'createEvent').then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const enableEvent = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/event/enable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const disableEvent = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/event/disable', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const checkTicketEvent = (value, successCB, failedCB) => () => {
  const params = {
    id: value
  }
  API.POST_WITH_PARAMS('/xpert/ticket/checkTicket', params).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

