import React, { Component } from 'react';
import { Skeleton, Form, Row, Col, Input, Select } from 'antd';

export default class EditInformation extends Component {

  render() {
    const { 
      getDetailCandidate: { data },
      formRef, 
      getCountry, 
      checkEmail,
      checkPhone,
      dataProvince,
      dataCity,
      dataSubDistrict,
      dataVillage,
      onSubmitInformation,
      handleChangeCountry,
      handleChangeProvince,
      handleChangeCity,
      handleChangeSubDistrict,
      handleChangeCallingCode
    } = this.props;

    if(getCountry.loading){
      return <Skeleton />
    }

    const mobileNumber = (
      <Form.Item 
        noStyle
        name="callingCodeId" 
        initialValue={data.callingCodeId ? data.callingCodeId : 99} 
        rules={[ {required: true, message: 'Please input your calling code'} ]}
      >
        <Select 
          showSearch
          className="prefix-selector"
          optionFilterProp="children"
          onChange={handleChangeCallingCode}
        >
          {
            getCountry?.data.map(item => (
              <Select.Option key={item.id} value={item.id}>
                {`+${item.callingCode}`}
              </Select.Option>
            ))
          }
        </Select>
      </Form.Item>
    );
    return (
      <React.Fragment>
        <Form layout="vertical" ref={formRef} onFinish={onSubmitInformation} scrollToFirstError={true}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item 
                label="Email" 
                name="email" 
                validateFirst
                initialValue={data.email ? data.email : null}
                rules={[
                  { required: true, message: 'This is a required field' },
                  { type: 'email', message: 'The input is not valid E-mail' }, 
                ]}
              >
                <Input type="email" onBlur={(e) => checkEmail(e.target.value)} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="Mobile Number" 
                name="mobilePhone"
                validateFirst
                initialValue={data.mobilePhone ? data.mobilePhone : null}
                rules={[
                  { required: true, message: 'This is a required field' },
                  { min: 4, message: 'Your mobile number is invalid' },
                  { max: 13, message: 'Your mobile number is invalid' },
                  { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers' },                
                ]}
              >
                <Input addonBefore={mobileNumber} onBlur={(e) => checkPhone(e.target.value)} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="Country" 
                name="countryId" 
                initialValue={data.countryId ? data.countryId : null}
              >
                <Select
                  showSearch
                  placeholder="Select a country"
                  optionFilterProp="children"
                  onChange={handleChangeCountry}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    getCountry?.data.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="Village" 
                name="villageId"
                initialValue={data.villageId ? data.villageId : null}
              >
                <Select
                  showSearch
                  placeholder="Select a village"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    dataVillage.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="Province" 
                name="provinceId"
                initialValue={data.provinceId ? data.provinceId : null}
              >
                <Select
                  showSearch
                  placeholder="Select a province"
                  onChange={handleChangeProvince}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    dataProvince.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="Postal Code" 
                name="zipCode"
                validateFirst
                initialValue={data.zipCode ? data.zipCode : null}
                rules={[
                  { max: 11, message: 'Maximum postal code is 11 characters' },
                  { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers' },                
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="City" 
                name="cityId"
                initialValue={data.cityId ? data.cityId : null}
              >
                <Select
                  showSearch
                  placeholder="Select a city"
                  onChange={handleChangeCity}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    dataCity.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="Street Address" 
                name="address"
                initialValue={data.address ? data.address : ''}
                rules={[
                  { max: 100, message: 'Address is too long. Maximum is 100 characters' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label="Sub-district" 
                name="subDistrictId"
                initialValue={data.subDistrictId ? data.subDistrictId : null}
              >
                <Select
                  showSearch
                  placeholder="Select a sub-district"
                  onChange={handleChangeSubDistrict}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    dataSubDistrict.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}
