import { API } from '../../../config';
import { errorHandler } from '../auth/errorAction';

export const unmountListReportApplicantSummary = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_REPORT_APPLICANT_SUMMARY'})
}

export const unmountListReportHiringPipeline = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_REPORT_HIRING_PIPELINE'})
}

export const unmountListReportJobOverview = () => (dispatch) => {
  return dispatch({type: 'UNMOUNT_DATA_REPORT_JOB_OVERVIEW'})
}

export const listReportApplicantSummary = (meta, url) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_REPORT_APPLICANT_SUMMARY' })
  return API.GET(`/${url}/applicant_summary`, meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_REPORT_APPLICANT_SUMMARY_SUCCESS',
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_REPORT_APPLICANT_SUMMARY_FAILED' }), 
    ))
  })
}

export const listReportHiringPipeline = (meta, url) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_REPORT_HIRING_PIPELINE' })
  return API.GET(`/${url}/hiring_pipeline`, meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_REPORT_HIRING_PIPELINE_SUCCESS',
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_REPORT_HIRING_PIPELINE_FAILED' }), 
    ))
  })
}

export const listReportJobOverview = (meta, url) => async (dispatch) => {
  await dispatch({ type: 'LOAD_DATA_REPORT_JOB_OVERVIEW' })
  return API.GET(`/${url}/job_overview`, meta).then((response) => {
    dispatch({
      type: 'LOAD_DATA_REPORT_JOB_OVERVIEW_SUCCESS',
      payload: {
        data: response.data,
        pagination: {
          page: response.meta.page,
          total: response.meta.total,
          perpage: response.meta.perpage
        }
    }})
  }).catch((err) => {
    return dispatch(errorHandler(
      err, 
      dispatch({ type: 'LOAD_DATA_REPORT_JOB_OVERVIEW_FAILED' }), 
    ))
  })
}

export const downloadApplicantSummary = (meta, url, successCB, failedCB) => () => {
  return API.GET_DOWNLOAD(`/${url}/applicant_summary/print`, meta).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const downloadHiringPipeline = (meta, url, successCB, failedCB) => () => {
  return API.GET_DOWNLOAD(`/${url}/hiring_pipeline/print`, meta).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}

export const downloadJobOverview = (meta, url, successCB, failedCB) => () => {
  return API.GET_DOWNLOAD(`/${url}/job_overview/print`, meta).then((response) => {
    return successCB && successCB(response)
  }).catch((err) => {
    return failedCB && failedCB(err)
  })
}